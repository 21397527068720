import { CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { Fragment, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { AGGREGATE_MEMBER_ENTITLEMENT } from '../../../../../common/aggregate.types'
import { fetchAggregates } from '../../../../../common/axios-action'
import { WeeklySlot } from '../../../../../common/types'
import { selectCurrentOperatorId } from '../../../../../config/app/reducers'
import { RootState } from '../../../../../store'
import { selectCurrentOrganisationId } from '../../../../auth/types'
import { ROUTE_ASSIGNED, ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT } from '../../../../util/routes'
import { formatAMPM, formatDate, getFormattedTimeRange } from '../../../../util/util'
import {
  DisplayEntitlement,
  displayWeeklySlots,
  MemberEntitlement,
  TENANT_MEMBER_ENTITLEMENT_API_RESOURCE_PATH,
} from './types'

const useStyles = makeStyles((theme) => ({
  customPaddingTop: {
    paddingTop: '5px !important',
  },
}))

interface IProps {
  memberId: string
  refreshParking: boolean
}

const AssignedParking: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { memberId, refreshParking } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [displayEntitlement, setDisplayEntitlement] = useState<DisplayEntitlement[] | null>(null)

  const { entitlements, loadingList } = useSelector(
    (state: RootState) => ({
      entitlements: state.memberEntitlementReducer.aggregates,
      loadingList: state.memberEntitlementReducer.loadingList,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (entitlements && entitlements.values && entitlements.values.length > 0) {
      let tempDisplayEntitlementList: DisplayEntitlement[] = []
      entitlements.values.forEach((entitlement: MemberEntitlement) => {
        let displaySlots: WeeklySlot[] = []
        displayWeeklySlots.map((row) => {
          let dbSlot = entitlement.stayAgreement.weeklyStay?.slots.find((slot) => slot.dayOfWeek === row.dayOfWeek)
          if (dbSlot) {
            dbSlot.enable = true
            return displaySlots.push(dbSlot)
          } else {
            return displaySlots.push(row)
          }
        })
        let tempEntitlement: DisplayEntitlement = {
          id: entitlement.id,
          stayAgreementId: entitlement.stayAgreement.id,
          facilityName: entitlement.stayAgreement.facilityName,
          weeklyStay: entitlement.stayAgreement.weeklyStay,
          reservation:
            entitlement.stayAgreement.reservations && entitlement.stayAgreement.reservations.length > 0
              ? entitlement.stayAgreement.reservations[0]
              : null,
          displaySlots: displaySlots,
        }
        tempDisplayEntitlementList.push(tempEntitlement)
      })
      setDisplayEntitlement(tempDisplayEntitlementList)
    } else if (entitlements && entitlements.values && entitlements.values.length === 0) {
      setDisplayEntitlement([])
    }
  }, [entitlements])

  function getEntitlements(currentOperatorId: string, currentOrganisationId: string, memberId: string) {
    dispatch(
      fetchAggregates<MemberEntitlement>(
        AGGREGATE_MEMBER_ENTITLEMENT,
        TENANT_MEMBER_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':memberId', memberId),
        null,
      ),
    )
  }

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && memberId !== null && refreshParking) {
      getEntitlements(currentOperatorId, currentOrganisationId, memberId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, refreshParking])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && memberId !== null) {
      getEntitlements(currentOperatorId, currentOrganisationId, memberId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, memberId])

  const reservedLocationDetails = (entitlement: DisplayEntitlement) => {
    //George Street Office - Level 1 - Zone C - Bay 76
    let reservedString: string = ''
    if (entitlement) {
      reservedString += entitlement?.facilityName

      if (entitlement.reservation) {
        if (entitlement.reservation.layoutName) {
          reservedString += ' - ' + entitlement.reservation.layoutName
        } else if (entitlement.reservation.allocationName) {
          reservedString += ' - ' + entitlement.reservation.allocationName
        }

        if (entitlement.reservation.facilityZoneName) {
          reservedString += ' - ' + entitlement.reservation.facilityZoneName
        }

        if (entitlement.reservation.bayNo) {
          reservedString += 'Bay ' + entitlement.reservation.bayNo
        }
      }
    }
    return reservedString
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <h3 className="padding-bottom-20">Assigned parking</h3>
          {entitlements.values.length === 0 && <p>This user hasn’t been assigned parking.</p>}
        </Grid>
      </Grid>

      {loadingList ? (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <CircularProgress color="primary" size={30} />
          </Grid>
        </Grid>
      ) : (
        displayEntitlement &&
        displayEntitlement.map((entitlement: DisplayEntitlement) => {
          return (
            <Fragment key={entitlement.id}>
              <Grid container spacing={0} marginTop="12px">
                <Grid item xs={12} className="assigned-parking-details">
                  <p className="assigned-detail">
                    <strong>Reserved parking</strong>
                    {reservedLocationDetails(entitlement)}
                  </p>
                  <p
                    className="assigned-edit-link"
                    onClick={() => {
                      navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT}`, {
                        state: { entitlement: entitlement },
                      })
                    }}
                  >
                    Edit assigned parking
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="manage-assigned-wrapper">
                <Grid item xs={5}>
                  <table id="bay-use-table">
                    <tbody>
                      <tr>
                        <td>Starts</td>
                        <td>
                          <strong>{formatDate(entitlement?.weeklyStay?.startDateTime, 'd MMM yyyy')}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Expires</td>
                        <td>
                          <strong>{formatDate(entitlement?.weeklyStay?.finishDateTime, 'd MMM yyyy')}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs={7}>
                  <div className="day-usage-wrapper">
                    <ul className="day-usage-menu">
                      {entitlement.displaySlots &&
                        entitlement.displaySlots.map((row: WeeklySlot, index: number) => {
                          return (
                            <li className="day-usage-item" key={index + '1'}>
                              <i className={row.enable ? 'day-usage-icon enabled' : 'day-usage-icon'}>
                                {row.dayOfWeek[0]}
                              </i>
                              {row.enable && !row.fromMinutes && !row.toMinutes ? (
                                <p className="day-usage-text">All day</p>
                              ) : (
                                <>
                                  <p className="day-usage-text">
                                    {row.fromMinutes === 0
                                      ? getFormattedTimeRange(0)
                                      : row.fromMinutes
                                      ? getFormattedTimeRange(row.fromMinutes)
                                      : ''}
                                  </p>
                                  <p className={`day-usage-text ${classes.customPaddingTop}`}>
                                    {formatAMPM(row.toMinutes)}
                                  </p>
                                </>
                              )}
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
          )
        })
      )}
    </>
  )
}

export default AssignedParking
