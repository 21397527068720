export interface CachedAggregates<T> {
  values: T[]
  totalCount: number | null
  link: string | null
  eTag: string | null
}

export interface CachedIdNames {
  values: IdClientRefName[]
  eTag: string | null
}

export interface IdClientRefName {
  id: string
  name: string
}

export interface ShowToolTip {
  id: string | null
  show: boolean
  message: string
}

export interface AggregateState<T> {
  loadingList: boolean
  loadingOne: boolean
  inserting: boolean
  inserted: boolean
  updating: boolean
  updated: boolean
  lifeCycleOnly: null
  updatingLifeCycle: boolean
  updateLifeCycleSuccess: boolean
  applicationStatus: null
  updatingApplicationStatus: boolean
  updatedApplicationStatus: boolean
  aggregates: CachedAggregates<T>
  aggregate: T | null
  deleting: boolean
  deleted: boolean
  idOnly?: IdOnly
  data?: T | null
  idNames: CachedIdNames
  error: string | null
  verificationInProcess?: boolean
  customRequestInserted?: boolean
  getTenanciesSuccess?: boolean
}

export interface IdOnly {
  id: string | null
}

export enum OrganisationType {
  CarParkTenant = 'CarParkTenant',
  Operator = 'Operator',
}

export interface OrganisationResource {
  organisationId: string
  organisationType: OrganisationType
}

export interface ConsumerDto {
  id: string
  email: string
  mobile: MobileDto
  providerId: string
  firstName: string
  lastName: string
  consumerType: ConsumerType
  lifecycle: Lifecycle
  acceptedTermsAndPrivacy: boolean
  sendPromoAndOffers: boolean
  version: number
  operatorId: string
  tenantId: string
  firebaseTenantId: string
  timeZoneId: string
  verificationSessionId: string
}

export interface MobileDto {
  country: 'AU'
  number: string
}

export interface NameValueDTO {
  name: string
  value: string | number
}

export interface MemberDto extends ConsumerDto {
  clientRef: string
  code: string
  departmentId: string
  teamId: string
  baseFacilityId: string
  facilityIds: string[]
  memberPool: MemberPool
  employeeClass: EmployeeClass
  contractEndDate: string
}

export interface SearchCriteria {
  page: number | null
  size: number | null
  sortBy: number | null
  sortDirection: number | null
  lifecycle: Lifecycle | null
}

export interface ErrorResponse {
  code: string
  message: string
}

export enum Lifecycle {
  Draft = 'Draft',
  Active = 'Active',
  Suspended = 'Suspended',
  Deleted = 'Deleted',
}

export interface LifecycleOnly {
  lifecycle: Lifecycle
}

export enum Mode {
  Add,
  Edit,
}

export interface DisableCheck {
  index: number | null
  allowEdit: boolean
}

export enum ConsumerType {
  Guest = 'Guest',
  SignedUp = 'SignedUp',
  CarParkTenantMember = 'CarParkTenantMember',
  DistributionChannelMember = 'DistributionChannelMember',
}

export enum MemberPool {
  Staff_Executive = 'Staff_Executive',
  Staff_Management = 'Staff_Management',
  Staff_Office = 'Staff_Office',
  Staff_Mobile = 'Staff_Mobile',
}

export enum EmployeeClass {
  Permanent = 'Permanent',
  Contract = 'Contract',
}

export const employeeClassIdNames: IdClientRefName[] = [
  {
    id: 'Permanent',
    name: 'Permanent',
  },
  {
    id: 'Contract',
    name: 'Contract',
  },
]

export const memberPoolsIdNames: IdClientRefName[] = [
  {
    id: 'Staff_Executive',
    name: 'Executive',
  },
  {
    id: 'Staff_Management',
    name: 'Management',
  },
  {
    id: 'Staff_Office',
    name: 'Office',
  },
  {
    id: 'Staff_Mobile',
    name: 'Mobile',
  },
]

export enum SalesChannelType {
  Pay = 'Pay',
  Res = 'Res',
  Charge = 'Charge',
  Lease = 'Lease',
  Valido = 'Valido',
  Pass = 'Pass',
}

export enum ParkingItemRelationType {
  Extends = 'Extends',
  Amends = 'Amends',
}

export enum AgreementStayType {
  Single = 'Single',
  Weekly = 'Weekly',
  Contract = 'Contract',
}

export enum OfferClass {
  Base = 'Base',
  Promo = 'Promo',
}

export enum UserClass {
  Admin = 'Admin',
  Core = 'Core',
  Consumer = 'Consumer',
  None = 'None',
}

export interface UserRef {
  id: string
  userClass: UserClass
  firstName: string
  lastName: string
  fullName: string
}

export interface MemberVehicle {
  memberVehicleId: string
  plate: string
  makeCode: string | null
}

export enum PurchaseType {
  PrePay = 'PrePay',
  PostPay = 'PostPay',
}

export enum PaymentChannel {
  PayPal = 'PayPal',
  GooglePay = 'GooglePay',
  ApplePay = 'ApplePay',
  CreditCard = 'CreditCard',
  Goodwill = 'Goodwill',
}

export enum TransactionType {
  Payment = 'Payment',
  Refund = 'Refund',
  PreAuth = 'PreAuth',
  Completion = 'Completion',
}

export enum CreditCardType {
  Amex = 'Amex',
  Mastercard = 'Mastercard',
  Visa = 'Visa',
}

export enum TransactionStatus {
  Pending = 'Pending',
  Success = 'Success',
  Rejected = 'Rejected', // Bank error
  Failed = 'Failed', // System Error
}

export enum OrderChargeType {
  ServiceCharge = 'ServiceCharge',
  Levy = 'Levy',
  Operator = 'Operator',
  InclusiveTax = 'InclusiveTax',
  Recovery = 'Recovery',
}

export interface Marketing {
  headline: string
  details: string
}

export enum OrderStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Declined = 'Declined',
  Free = 'Free',
  Cancelled = 'Cancelled',
  Amended = 'Amended',
  Released = 'Released',
}

export interface Revision {
  id: string
  code: string
  clientRef: string
  version: number
}

export enum ReservationType {
  FacilityReserved = 'FacilityReserved',
  FacilityUnReserved = 'FacilityUnReserved',
  ZonedReserved = 'ZonedReserved',
  ZonedUnReserved = 'ZonedUnReserved',
}

export interface AgreementReservation {
  reservationType: ReservationType
  facilityZoneId: string | null
  facilityZoneName: string | null
  bayNo: string | null
  layoutId: string | null
  layoutName: string | null
  allocationId: string | null
  allocationName: string | null
}

export interface SingleStay {
  startInstant: string
  finishInstant: string
  startDateTime: string
  finishDateTime: string
  durationMinutes: number
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface WeeklySlot {
  dayOfWeek: DayOfWeek
  dayOfWeekOrdinal: number
  fromMinutes: number | null
  toMinutes: number | null
  enable: boolean
}

export interface WeeklyStay {
  startInstant: string
  finishInstant: string
  startDateTime: string
  finishDateTime: string
  slots: WeeklySlot[]
}

export interface PermanentStay {
  startInstant: string
  finishInstant: string
  startDateTime: string
  finishDateTime: string
}

export enum AgreementType {
  Single = 'Single',
  Weekly = 'Weekly',
  Permanent = 'Permanent',
  Daily = 'Daily',
  Charge = 'Charge',
}

export enum FileStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Complete = 'Complete',
}

export enum MUIDatepickerError {
  InvalidDate = 'invalidDate',
  MinDate = 'minDate',
}

export enum AgreementStatus {
  /**
   * Valid agreement. Valid agreement. For long term multiple stay
   * agreements, the status remains Valid until the entire agreement
   * expires.
   */
  Valid = 'Valid',

  /**
   * Single use agreement has been consumed
   */
  Completed = 'Completed',

  /**
   * Agreement end date has been exceeded. The end date of the
   * agreement should be checked in realtime if the expiry is time
   * critical.
   */
  Expired = 'Expired',

  /**
   * Single use agreement has expired and was not consumed.
   */
  NoShow = 'NoShow',

  /**
   * Agreement has been cancelled.
   */
  Cancelled = 'Cancelled',

  /**
   * Agreement has been Amended.
   */
  Amended = 'Amended',
  Pending = 'Pending',
}

export enum EntryStatus {
  OnSite = 'OnSite',
  OffSite = 'OffSite',
  Suspect = 'Suspect',
  TransitIn = 'TransitIn',
  TransitOut = 'TransitOut',
  Fallback = 'Fallback',
}

export enum AccessType {
  Entry = 'Entry',
  Exit = 'Exit',
  Dual = 'Dual',
}

export interface MemberParkingStayAgreement {
  id: string
  facilityId: string
  facilityName: string
  reservations: AgreementReservation[]
  charge: number | null
  unitPrice: number | null
  quantity: number
  timeZoneId: string
  agreementType: AgreementType
  weeklyStay: WeeklyStay | null
  singleStay: SingleStay | null
  permanentStay: PermanentStay | null
  agreementStatus: AgreementStatus
  entryStatus: EntryStatus
}

export const ondemandAgreementStatusList: IdClientRefName[] = [
  {
    id: 'Valid',
    name: 'Valid',
  },
  {
    id: 'Completed',
    name: 'Completed',
  },
  {
    id: 'Expired',
    name: 'Expired',
  },
  {
    id: 'Amended',
    name: 'Amended',
  },
  {
    id: 'Cancelled',
    name: 'Cancelled',
  },
  {
    id: 'NoShow',
    name: 'No Show',
  },
  {
    id: 'Pending',
    name: 'Pending',
  },
]

export const guestAgreementStatusList: IdClientRefName[] = [
  {
    id: 'Valid',
    name: 'Valid',
  },
  {
    id: 'Completed',
    name: 'Completed',
  },
  {
    id: 'Expired',
    name: 'Expired',
  },
  {
    id: 'Amended',
    name: 'Amended',
  },
  {
    id: 'Cancelled',
    name: 'Cancelled',
  },
  {
    id: 'NoShow',
    name: 'No Show',
  },
]

export interface AcknowledgeLifecycle {
  lifecycle: Lifecycle
  acknowledge: boolean
}

export enum UpdateDeleteMode {
  None,
  Update,
  Delete,
}

export interface WeekDay {
  key: string
  value: string
}

export const weekDays: WeekDay[] = [
  {
    key: 'MONDAY',
    value: 'M',
  },
  {
    key: 'TUESDAY',
    value: 'T',
  },
  {
    key: 'WEDNESDAY',
    value: 'W',
  },
  {
    key: 'THURSDAY',
    value: 'T',
  },
  {
    key: 'FRIDAY',
    value: 'F',
  },
  {
    key: 'SATURDAY',
    value: 'S',
  },
  {
    key: 'SUNDAY',
    value: 'S',
  },
]

export enum UpdateEntryStatusParkerType {
  ENTITLEMENT,
  GUEST,
  ON_DEMAND,
}

export enum ReasonType {
  MobileDeviceIssue = 'MobileDeviceIssue',
  OutOfSync = 'OutOfSync',
  AccessHardwareIssue = 'AccessHardwareIssue',
  NetworkIssue = 'NetworkIssue',
  Other = 'Other',
  Overstay = 'Overstay',
}
