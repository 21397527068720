import {
  AggregateState,
  AgreementReservation,
  DayOfWeek,
  MemberParkingStayAgreement,
  MemberVehicle,
  WeeklySlot,
  WeeklyStay,
} from '../../../../../common/types'
import { AggregateActionTypes } from '../../../../../common/aggregate.types'

export interface MemberEntitlement {
  id: string
  reference: string
  vehicles: MemberVehicle
  stayAgreement: MemberParkingStayAgreement
}

export interface DisplayEntitlement {
  id: string | null
  stayAgreementId: string | null
  facilityName: string | null
  weeklyStay: WeeklyStay | null
  reservation: AgreementReservation | null
  displaySlots: WeeklySlot[]
}

export type MemberEntitlementState = AggregateState<MemberEntitlement>
export type MemberEntitlementActionTypes = AggregateActionTypes<MemberEntitlement>

export const TENANT_MEMBER_ENTITLEMENT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/entitlements'

export const displayWeeklySlots: WeeklySlot[] = [
  {
    dayOfWeek: DayOfWeek.MONDAY,
    dayOfWeekOrdinal: 1,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
  {
    dayOfWeek: DayOfWeek.TUESDAY,
    dayOfWeekOrdinal: 2,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
  {
    dayOfWeek: DayOfWeek.WEDNESDAY,
    dayOfWeekOrdinal: 3,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
  {
    dayOfWeek: DayOfWeek.THURSDAY,
    dayOfWeekOrdinal: 4,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
  {
    dayOfWeek: DayOfWeek.FRIDAY,
    dayOfWeekOrdinal: 5,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
  {
    dayOfWeek: DayOfWeek.SATURDAY,
    dayOfWeekOrdinal: 6,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
  {
    dayOfWeek: DayOfWeek.SUNDAY,
    dayOfWeekOrdinal: 7,
    fromMinutes: null,
    toMinutes: null,
    enable: false,
  },
]
