import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface ParkingBasket {
  basket: {
    paymentAmount: number
  }
  basketId: string
  basketItemId: string
}

export type ParkingBasketState = AggregateState<ParkingBasket>
export type ParkingBasketActionTypes = AggregateActionTypes<ParkingBasket>
export const PARKING_BASKET_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/core-members/:memberId/baskets'
