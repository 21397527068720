import axios from 'axios'
import { AUTHORIZATION_HEADER, BEARER_PREFIX } from '../../../config/constants'
import { MemberForm } from '../../../pages/settings/users/active-users/types'
import { ExistsAssignments } from './types'

export const checkExistsAgreements = async (
  values: MemberForm,
  url: string,
  shuffleUrl: string,
  token: string,
): Promise<ExistsAssignments> => {
  let exists: ExistsAssignments = {
    exists: false,
    message: '',
  }
  await axios({
    method: 'POST',
    url: shuffleUrl + url,
    data: values,
    headers: { [AUTHORIZATION_HEADER]: BEARER_PREFIX + token },
  })
    .then((resp) => {
      let data = resp.data as ExistsAssignments
      if (data) {
        exists = data
      }
      return exists
    })
    .catch((err) => {
      exists = {
        exists: false,
        message: '',
      }
    })

  return exists
}
