import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  createSvgIcon,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import { addDays, format, isBefore, subDays } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import searchFieldIcon from '../../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import crossFieldIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER_LIST,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_ORDER_PARKING_AGREEMENTS,
} from '../../../common/aggregate.types'
import { fetchAggregates } from '../../../common/axios-action'
import { ondemandAgreementStatusList, SearchCriteria, memberPoolsIdNames } from '../../../common/types'
import { exportCSV, getAgreementStatusFormattedName } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { useDebounce } from '../../../hook/useDebounce'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamList,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { MEMBER_API_RESOURCE_PATH, Member } from '../../../store/common/member/types'
import { clearNotification, setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { getTableCell } from '../../util/tooltip'
import {
  ORDER_PARKING_AGREEMENTS_API_RESOURCE_PATH,
  ORDER_PARKING_AGREEMENTS_EXPORT_CSV_API_RESOURCE_PATH,
  OrderParkingAgreements,
} from './types'

const CustomCalendarIcon = createSvgIcon(
  <path
    d="M5,4H2V8H22V4H19V5a1,1,0,0,1-2,0V4H13V5a1,1,0,0,1-2,0V4H7V5A1,1,0,0,1,5,5Zm14,8a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1Zm-6,0a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1ZM7,12a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1Zm6,5a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1ZM7,17a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1Zm15-7H2V22H22ZM18,0a1,1,0,0,1,1,1V2h4a1,1,0,0,1,1,1V23a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V3A1,1,0,0,1,1,2H5V1A1,1,0,0,1,7,1V2h4V1a1,1,0,0,1,2,0V2h4V1A1,1,0,0,1,18,0Z"
    fill="#979797"
  />,
  'CustomCalendar',
)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paginationButton: {
    background: 'transparent',
    color: 'black',
    boxShadow: 'none',
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    marginTop: '8px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    width: '146px',
    height: '45px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  dateRangeWrapper: {
    display: 'flex',
    backgroundColor: '#f6f6f6',
    borderRadius: '4px',
    padding: '1px 5px 5px 8px',
    border: '1px solid #ddd',
    width: '100%',
    marginTop: '20px',
  },
  flexGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface IProps {}
interface FilterCriteria extends SearchCriteria {
  agreementStatus: string | null
  entryDateFrom: string | null
  entryDateTo: string | null
  facilityId: string | null
  memberPool: string | null
  departmentId: string | null
  teamId: string | null
  memberId: string | null
  orderReference: string | null
}

const filterInitialState: FilterCriteria = {
  agreementStatus: '',
  entryDateFrom: format(new Date(), 'yyyy-MM-dd'),
  entryDateTo: format(new Date(), 'yyyy-MM-dd'),
  facilityId: '',
  memberPool: '',
  departmentId: '',
  teamId: '',
  memberId: '',
  orderReference: '',
  page: 0,
  size: 0,
  sortBy: 0,
  sortDirection: 0,
  lifecycle: null,
}

const initialMember: Member = {
  id: '',
  firstName: '',
  lastName: '',
  name: '',
  email: '',
}

const Transactions: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { state } = useLocation()

  let stateMemberId = null
  let stateMemberName = null

  if (state) {
    const { memberId, memberName }: any = state
    stateMemberId = memberId
    stateMemberName = memberName
  }

  const [filterState, setFilterState] = useState<any>(
    stateMemberId ? { ...filterInitialState, memberId: stateMemberId } : filterInitialState,
  )

  const [userName, setUserName] = useState<string>('')

  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false)
  const [departmentsAndTeams, setDepartmentsAndTeams] = useState<DepartmentTeamList[]>([])
  const [departmentTeamDisplayValue, setDepartmentTeamDisplayValue] = useState<string | null>(null)
  const [isCustomDateVisible, setCustomDateVisible] = useState<boolean>(false)
  const [customFromDate, setCustomFromDate] = useState<any>(null)
  const [customToDate, setCustomToDate] = useState<any>(null)
  const [member, setMember] = useState<Member | null>(
    stateMemberName ? { ...initialMember, name: stateMemberName } : initialMember,
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState<string>(stateMemberName ? stateMemberName : '')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 600)
  const [entryDateState, setEntryDateState] = useState<string>('today')
  const [customDateError, setCustomError] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [nextButtonDisable, setNextButtonDisabled] = useState<boolean>(false)
  const [prevButtonDisable, setPrevButtonDisabled] = useState<boolean>(true)
  const [initialLoadFlag, setInitialLoadFlag] = useState<number>(0)

  const {
    orderParkingAgreementsList,
    tenancies,
    departmentTeamsList,
    userList,
    userLoadingList,
    loadingList,
    user,
    shuffleApiConfig,
  } = useSelector((state: RootState) => ({
    orderParkingAgreementsList: state.orderParkingAgreementsStateReducer.aggregates,
    tenancies: state.myListTenancyReducer.aggregates.values,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates,
    userList: state.memberReducer.aggregates,
    userLoadingList: state.memberReducer.loadingList,
    loadingList: state.orderParkingAgreementsStateReducer.loadingList,
    user: state.authReducer.user,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
  }))

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  // This useEffect use to get order parking agreements form API for the table
  useEffect(() => {
    loadResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, filterState, page])

  const generateRequestParams = () => {
    const payload = {
      page: page - 1,
      size: 10,
      sortBy: 'StartDate',
      sortDirection: 'asc',
      ...(filterState.agreementStatus && {
        agreementStatus: filterState.agreementStatus,
      }),
      ...(filterState.entryDateFrom && {
        entryDateFrom: filterState.entryDateFrom,
      }),
      ...(filterState.entryDateTo && {
        entryDateTo: filterState.entryDateTo,
      }),
      ...(filterState.facilityId && {
        facilityId: filterState.facilityId,
      }),
      ...(filterState.memberPool && {
        memberPool: filterState.memberPool,
      }),
      ...(filterState.departmentId && {
        departmentId: filterState.departmentId,
      }),
      ...(filterState.teamId && {
        teamId: filterState.teamId,
      }),
      ...(filterState.teamId && {
        teamId: filterState.teamId,
      }),
      ...(filterState.orderReference && {
        orderReference: filterState.orderReference,
      }),
      ...(filterState.memberId && {
        memberId: filterState.memberId,
      }),
    }
    return payload
  }

  const loadResult = () => {
    const payload = generateRequestParams()
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<OrderParkingAgreements>(
          AGGREGATE_ORDER_PARKING_AGREEMENTS,
          ORDER_PARKING_AGREEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '?salesChannel=Res',
          payload,
        ),
      )
    }
  }

  // This useEffect use to get tenancie data from API for location dropdown
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // This useEffect use to get department and team from API for department/team dropdown
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // This useEffect use for helper function of onChangeSelect function
  useEffect(() => {
    let departmentsAndTeams: DepartmentTeamList[] = []
    if (departmentTeamsList) {
      departmentTeamsList.values.map((row) => {
        departmentsAndTeams.push({
          id: row.id,
          name: row.name,
          isDepartment: true,
          departmentId: null,
        })
        row.teams.map((team) => {
          departmentsAndTeams.push({
            id: team.id,
            name: team.name,
            isDepartment: false,
            departmentId: row.id,
          })
          return ''
        })
        return ''
      })
    }
    setDepartmentsAndTeams(departmentsAndTeams)
  }, [departmentTeamsList])

  // use for open error alert
  useEffect(() => {
    if (initialLoadFlag !== 0) {
      dispatch(setNotification(NotificationType.ERROR, ['Start Date must be before End Date']))
    } else {
      dispatch(clearNotification())
    }
  }, [dispatch, initialLoadFlag])

  // this use effect call base on debouncedSearchTerm
  useEffect(() => {
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    if (debouncedSearchTerm && debouncedSearchTerm.trim().length > 1) {
      searchUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedSearchTerm])

  useEffect(() => {
    if (!userName) {
      dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    }
    // eslint-disable-next-line
  }, [userName])

  // this use effect set state of next and previous button
  useEffect(() => {
    const link = orderParkingAgreementsList.link ? orderParkingAgreementsList.link : ''
    if (orderParkingAgreementsList.values.length === 0) {
      setNextButtonDisabled(true)
      setPrevButtonDisabled(true)
      setPage(1)
    } else if (link) {
      if (link.includes('next') && link.includes('prev')) {
        setNextButtonDisabled(false)
        setPrevButtonDisabled(false)
      } else if (link.includes('next')) {
        setPrevButtonDisabled(true)
        setNextButtonDisabled(false)
      } else if (link.includes('prev')) {
        setPrevButtonDisabled(false)
        setNextButtonDisabled(true)
      }
    } else {
      setNextButtonDisabled(true)
      setPrevButtonDisabled(true)
    }
  }, [page, orderParkingAgreementsList])

  // this function call user api when search by user name
  const searchUser = useCallback(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Member>(
          AGGREGATE_MEMBER_LIST,
          MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .concat(`?name=${searchTerm}&size=100`),
          null,
        ),
      )
    }
  }, [dispatch, searchTerm, currentOperatorId, currentOrganisationId])

  // This function use for handel onchange of status, role, location and entry date dropdown
  const handleChange = (event: SelectChangeEvent) => {
    const controlName = event.target.name
    const tempState = { ...filterState }
    tempState[controlName] = event.target.value
    setPage(1)
    setFilterState(tempState)
  }

  // This function use for handle onchange of department/team dropdown
  const onChangeSelect = (event: any) => {
    let { value } = event.target
    let selected: DepartmentTeamList | undefined = departmentsAndTeams.find((row) => row.id === value)
    setFilterState({
      ...filterState,
      teamId: selected && !selected.isDepartment ? selected.id : null,
      departmentId: selected && selected.isDepartment ? selected.id : null,
    })
    setPage(1)

    let displayValue = ''
    if (selected) {
      if (selected.departmentId) {
        let department: DepartmentTeamList | undefined = departmentsAndTeams.find(
          (row) => row.id === selected?.departmentId,
        )
        if (department) {
          displayValue = department.name + ' -> ' + selected.name
        } else {
          displayValue = selected.name
        }
      } else {
        displayValue = selected.name
      }
    }
    setDepartmentTeamDisplayValue(displayValue)
  }

  // This function use for handle onchange of search by transaction id and search by user name
  const handleSearchByIdAndName = (event: any) => {
    const { value } = event.target
    const controlName = event.target.name
    const tempState = { ...filterState }
    tempState[controlName] = value
    setFilterState(tempState)
    setPage(1)
  }

  // this function use for handel on change custom from date
  const customFromDateHandle = (value: any) => {
    setCustomFromDate(value)
  }

  // this function use for handel on change custom to date
  const customToDateHandle = (value: any) => {
    setCustomToDate(value)
  }

  // this function use for apply custom date filter
  const applyCustomDate = () => {
    let start = new Date(customFromDate).setHours(0, 0, 0, 0)
    let end = new Date(customToDate).setHours(0, 0, 0, 0)

    if (start && end) {
      if (isBefore(customToDate, customFromDate)) {
        if (initialLoadFlag === 0) {
          setInitialLoadFlag(1)
        } else if (initialLoadFlag === 1) {
          setInitialLoadFlag(2)
        } else if (initialLoadFlag === 2) {
          setInitialLoadFlag(1)
        }
        return
      } else {
        setInitialLoadFlag(0)
      }
    }
    setFilterState((prevState: FilterCriteria) => ({
      ...prevState,
      entryDateFrom: customFromDate ? format(Date.parse(customFromDate), 'yyyy-MM-dd') : null,
      entryDateTo: customToDate ? format(Date.parse(customToDate), 'yyyy-MM-dd') : null,
    }))
    setPage(1)
  }

  // this function use for get member id
  const getMemberId = (event: any, value: any) => {
    if (value) {
      setMember(value)
      setFilterState((prevState: FilterCriteria) => ({
        ...prevState,
        memberId: value.id,
      }))
      setPage(1)
    }
  }
  // this function use to reset all filter
  const resetAllFilters = () => {
    setFilterState(filterInitialState)
    setCustomFromDate(null)
    setCustomToDate(null)
    setDepartmentTeamDisplayValue('Any Department/Team')
    setMember(initialMember)
    setEntryDateState('today')
    setCustomDateVisible(false)
    setPage(1)
  }

  const onChangeName = (event: any) => {
    let { value } = event.target
    setSearchTerm(value)
    if (!value) {
      setMember(initialMember)
      if (orderParkingAgreementsList.values.length === 0) {
        setFilterState((prevState: FilterCriteria) => ({
          ...prevState,
          memberId: '',
        }))
      }
    }
  }

  // this function use for handle date dropdown and from/to date state
  const entryDateHandleChange = (event: SelectChangeEvent) => {
    const controlName = event.target.name
    let entryDateTo = null
    let entryDateFrom = null
    if (event.target.value === 'customDate') {
      setCustomDateVisible(true)
      setEntryDateState(event.target.value)
      return
    } else if (event.target.value === '') {
      setCustomFromDate(null)
      setCustomToDate(null)
      setEntryDateState('')
      setCustomDateVisible(false)
    } else {
      setCustomDateVisible(false)
      let value = event.target.value
      setEntryDateState(value)
      if (value === 'today' || value === 'next7days') {
        entryDateFrom = new Date()
        entryDateTo = value === 'today' ? new Date() : value === 'next7days' ? addDays(new Date(), 7) : new Date()
      } else if (value === 'last7days' || value === 'last30days') {
        entryDateFrom =
          value === 'last7days' ? subDays(new Date(), 7) : value === 'last30days' ? subDays(new Date(), 30) : new Date()
        entryDateTo = new Date()
      }
    }
    const tempState = { ...filterState }
    tempState[controlName] = event.target.value
    tempState['entryDateFrom'] = entryDateFrom ? format(entryDateFrom, 'yyyy-MM-dd') : ''
    tempState['entryDateTo'] = entryDateTo ? format(entryDateTo, 'yyyy-MM-dd') : ''
    setPage(1)
    setFilterState(tempState)
  }

  // this function use for handle date error if user enter invalid date
  const handleError = (value: string | null) => {
    if (value === null) {
      setCustomError(false)
    } else {
      setCustomError(true)
    }
  }

  // this function use for set page for pagination
  const nextPrevClick = (direction: string) => {
    setPage((prevState) => (direction === 'next' ? prevState + 1 : Math.max(prevState - 1, 1)))
  }

  const clearOrderReference = (event: any) => {
    const tempState = { ...filterState }
    tempState['orderReference'] = ''
    setFilterState(tempState)
    setPage(1)
  }

  const onClickClearUerFileds = () => {
    setUserName('')
    setMember(null)
    const tempState = { ...filterState }
    tempState['memberId'] = ''
    setFilterState(tempState)
    setPage(1)
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
  }

  const exportData = async () => {
    if (currentOperatorId && currentOrganisationId && user && shuffleApiConfig) {
      let resourcePath = ORDER_PARKING_AGREEMENTS_EXPORT_CSV_API_RESOURCE_PATH.replace(
        ':operatorId',
        currentOperatorId,
      ).replace(':carParkTenantId', currentOrganisationId)
      let requestParams = generateRequestParams()
      requestParams.salesChannel = 'Res'
      delete requestParams.page
      delete requestParams.size
      exportCSV(user, resourcePath, shuffleApiConfig, requestParams, dispatch)
    }
  }

  return (
    <>
      <div className="page-content-wrapper">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <Grid container spacing={0} className="responsive-title-wrapper">
                <Grid item xs={12}>
                  <h2 className="on-demand-h2">
                    <a className="back-icon" href="/on-demand">
                      <img className="icon-back-arrow" src={backIcon} alt="img" />
                      Transactions
                    </a>
                  </h2>
                </Grid>
              </Grid>

              <Grid container spacing={0} className="responsive-content-wrapper full-width ">
                <Grid item xs={12}>
                  <TextField
                    className="search-transaction-id"
                    value={filterState.orderReference}
                    onChange={handleSearchByIdAndName}
                    id="outlined-basic"
                    name="orderReference"
                    placeholder="Search by transaction ID"
                    InputLabelProps={{ className: 'shuffleLabel' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      className: 'shuffleInput search-input-text-field',
                      startAdornment: (
                        <InputAdornment position="start">
                          <img className="search-field-icon" src={searchFieldIcon} alt="img" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start" className="cross-icon">
                          {filterState.orderReference && (
                            <img
                              className="cross-field-icon"
                              src={crossFieldIcon}
                              alt="img"
                              onClick={(event) => clearOrderReference(event)}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Autocomplete
                    className="autocomplete header-wrapper-autocomplete"
                    clearIcon={null}
                    options={userList.values}
                    loading={userLoadingList}
                    loadingText="Loading..."
                    noOptionsText="No results"
                    onChange={(event: any, newValue: Member | null) => {
                      getMemberId(event, newValue)
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (!newInputValue) {
                        setMember(initialMember)
                      }
                      setUserName(newInputValue)
                    }}
                    inputValue={userName}
                    value={member}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={searchTerm}
                        className="shuffleInput"
                        onChange={onChangeName}
                        placeholder="Search by user"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start" className="autocomplete-search-icon">
                              <img className="search-field-icon" src={searchFieldIcon} alt="img" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              {userName && (
                                <img
                                  className="cross-field-icon"
                                  src={crossFieldIcon}
                                  alt="img"
                                  onClick={() => onClickClearUerFileds()}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              <Grid item xs={12} className="content-panel filter-content-panel">
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <InputLabel className="select-heading">Status</InputLabel>
                        <Select
                          className="shuffle-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          onChange={handleChange}
                          value={filterState.agreementStatus}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          name="agreementStatus"
                          displayEmpty
                        >
                          <MenuItem value="" className="selectBold">
                            Any Status
                          </MenuItem>
                          {ondemandAgreementStatusList.map((agreementStatus) => (
                            <MenuItem key={agreementStatus.id} value={agreementStatus.id}>
                              {agreementStatus.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel className="select-heading">Entry Date</InputLabel>
                        <Select
                          className="shuffle-select"
                          IconComponent={ExpandMoreIcon}
                          onChange={entryDateHandleChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          value={entryDateState}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          name="entryDate"
                          displayEmpty
                        >
                          <MenuItem value="" className="selectBold">
                            Any Date
                          </MenuItem>
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value="next7days">Next 7 Days</MenuItem>
                          <MenuItem value="last7days">Last 7 Days</MenuItem>
                          <MenuItem value="last30days">Last 30 Days</MenuItem>
                          <MenuItem value="customDate" className="selectLink">
                            Custom date range
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel className="select-heading">Location</InputLabel>
                        <Select
                          className="shuffle-select"
                          IconComponent={ExpandMoreIcon}
                          onChange={handleChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          value={filterState.facilityId}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          name="facilityId"
                          displayEmpty
                        >
                          <MenuItem value="" className="selectBold">
                            Any Location
                          </MenuItem>
                          {tenancies.map((tenancy) => (
                            <MenuItem key={tenancy.id} value={tenancy.id}>
                              {tenancy.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <p
                        className={!showMoreFilters ? 'show-details' : 'hide-details'}
                        onClick={() => setShowMoreFilters(!showMoreFilters)}
                      >
                        {!showMoreFilters ? 'More filters' : 'Less filters'}
                      </p>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <p
                        style={{ cursor: 'pointer', color: '#ff0000' }}
                        className="reset-all-filters"
                        onClick={resetAllFilters}
                      >
                        Reset filters
                      </p>
                    </Box>
                  </Grid>
                </Grid>
                {isCustomDateVisible && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" className={classes.dateRangeWrapper}>
                        <Grid item xs={10} className={classes.flexGridItem} style={{ marginRight: '20px' }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={customFromDate}
                              onChange={customFromDateHandle}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              onError={handleError}
                              renderInput={(params: any) => (
                                <TextField
                                  className="shuffleInputIcon"
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  name="entryDateFrom"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <p className="date-range-divider">to</p>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={customToDate}
                              onChange={customToDateHandle}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              onError={handleError}
                              renderInput={(params: any) => (
                                <TextField
                                  className="shuffleInputIcon"
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  name="entryDateTo"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} className={classes.flexGridItem}>
                          <Button
                            type="submit"
                            className={classes.shuffleButton}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={applyCustomDate}
                            disabled={customDateError}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                )}

                {showMoreFilters && (
                  <Grid container spacing={2} style={{ paddingTop: '10px' }}>
                    <Grid item xs={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <InputLabel className="select-heading">Role</InputLabel>
                          <Select
                            className="shuffle-select"
                            IconComponent={ExpandMoreIcon}
                            onChange={handleChange}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                            value={filterState.memberPool}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="memberPool"
                            displayEmpty
                          >
                            <MenuItem value="" className="selectBold">
                              Any Role
                            </MenuItem>
                            {memberPoolsIdNames.map((member) => (
                              <MenuItem key={member.id} value={member.id}>
                                {member.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel className="select-heading">Department/team</InputLabel>
                          <Select
                            className="shuffle-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              disableScrollLock: true,
                            }}
                            onChange={onChangeSelect}
                            placeholder="Any Department/Team"
                            defaultValue=""
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            renderValue={(value) =>
                              departmentTeamDisplayValue ? departmentTeamDisplayValue : 'Any Department/Team'
                            }
                            displayEmpty
                          >
                            <MenuItem value="" className="selectBold">
                              Any department/team
                            </MenuItem>
                            {departmentsAndTeams.map((row) => (
                              <MenuItem
                                key={row.id}
                                value={row.id}
                                className={row.isDepartment ? 'selectTitle' : 'selectBullet'}
                              >
                                {!row.isDepartment ? '• ' : ''}
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.gridTopMargin}>
                    {loadingList ? (
                      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                          <CircularProgress color="primary" size={30} />
                        </Grid>
                      </Grid>
                    ) : (
                      <table id="transactions">
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Parker</th>
                            <th>Booking entry</th>
                            <th>Booking exit</th>
                            <th>Location</th>
                            <th>Lic. Plate</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderParkingAgreementsList.values.map((agreement: OrderParkingAgreements) => (
                            <tr key={agreement.orderId}>
                              <td className="transaction-id">
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className="color-red"
                                  onClick={() =>
                                    navigate('/on-demand/transactions/transaction-detail', {
                                      state: {
                                        orderId: agreement.orderId,
                                      },
                                    })
                                  }
                                >
                                  {agreement.orderReference}
                                </p>
                              </td>
                              <td className="pr-20" style={{ maxWidth: '130px' }}>
                                {getTableCell(agreement.memberName, 23)}
                              </td>
                              <td>{format(Date.parse(agreement.entryDateTime), 'dd MMM hh:mmaaa')}</td>
                              <td>{format(Date.parse(agreement.exitDateTime), 'dd MMM hh:mmaaa')}</td>
                              <td className="pr-20" style={{ maxWidth: '200px' }}>
                                {getTableCell(agreement.facilityName, 38)}
                              </td>
                              <td style={{ minWidth: '90px' }}>{agreement?.vehiclePlate}</td>
                              <td>{getAgreementStatusFormattedName(agreement.agreementStatus)}</td>
                            </tr>
                          ))}
                          {orderParkingAgreementsList.values.length === 0 && (
                            <tr>
                              <td colSpan={7} align="center">
                                No Transactions Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={'4rem'}
                  className="position-relative"
                >
                  <span
                    className={prevButtonDisable || loadingList ? 'pagination-icon-disabled' : ''}
                    onClick={() => nextPrevClick('prev')}
                  >
                    <ArrowBackIosNewRoundedIcon
                      className={
                        prevButtonDisable || loadingList
                          ? 'pagination-icon-button pagination-icon-disabled'
                          : 'pagination-icon-button'
                      }
                    />
                  </span>
                  <span className="pagination-page-number">{page}</span>
                  <span
                    className={nextButtonDisable || loadingList ? 'pagination-icon-disabled' : ''}
                    onClick={() => nextPrevClick('next')}
                  >
                    <ArrowForwardIosRoundedIcon
                      className={
                        nextButtonDisable || loadingList
                          ? 'pagination-icon-button pagination-icon-disabled'
                          : 'pagination-icon-button'
                      }
                    />
                  </span>
                  {orderParkingAgreementsList.values.length > 0 && (
                    <span
                      className="on-demand-transaction-export transaction-export-to-csv-box"
                      onClick={() => exportData()}
                    >
                      <span>Export to CSV</span>
                    </span>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Transactions
