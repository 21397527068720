import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Box, Button, CircularProgress, Container, Grid, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import shuffleLogo from '../../../assets/svg/Shuffle-Logo.svg'
import { OrganisationType } from '../../../common/types'
import { resetLoading, setLoading } from '../../../store/common/loading/action'
import { selectLoading } from '../../../store/common/loading/types'
import { clearNotification, setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { ROUTE_HOME, ROUTE_TENANT_SELECTOR } from '../../util/routes'
import { isCompletedLogin, selectCurrentUser } from '../types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
}))

interface IProps {}

const Login: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const loginCompleted = useSelector(isCompletedLogin)
  const currentUser = useSelector(selectCurrentUser)
  const loading = useSelector(selectLoading)
  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { t } = useTranslation()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const signInWithEmailAndPasswordHandler = (event: any) => {
    event.preventDefault()
    dispatch(clearNotification())

    if (!email || !password) {
      if (!email) {
        setEmailError('Email Address Required')
      }
      if (!password) {
        setPasswordError('Password Required')
      }
      return
    }
    dispatch(setLoading())
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        dispatch(resetLoading())
      })
      .catch((error) => {
        dispatch(resetLoading())
        let message = t('login:error.loginError')
        dispatch(setNotification(NotificationType.ERROR, [message]))
      })
  }

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'email') {
      setEmail(value)
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  if (loginCompleted) {
    if (currentUser && currentUser.organisationType === OrganisationType.Operator) {
      return <Navigate to={ROUTE_TENANT_SELECTOR} />
    } else {
      return <Navigate to={ROUTE_HOME} />
    }
  } else {
    return (
      <Box className={classes.loginModal}>
        <Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <h3 className="login-h3 desktop-only">Welcome to</h3>
              <h3 className="login-h3 mobile-only">Welcome to Shuffle</h3>
              <img className="shuffle-login-logo desktop-only" src={shuffleLogo} alt="Shuffle" />
            </Grid>
            <Grid item xs={12}>
              <p className="input-label">Email address</p>
              <TextField
                inputProps={{ className: 'shuffleInput' }}
                id="email"
                name="email"
                InputLabelProps={{ className: 'shuffleLabel' }}
                variant="outlined"
                margin="dense"
                error={emailError ? true : false}
                size={'small'}
                fullWidth
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="input-label">Password</p>
              <TextField
                id="password"
                name="password"
                InputLabelProps={{ className: 'shuffleLabel' }}
                error={passwordError ? true : false}
                variant="outlined"
                margin="dense"
                size={'small'}
                fullWidth
                onChange={onChangeHandler}
                type={showPassword ? 'text' : 'password'}
                inputProps={{
                  className: 'shuffleInput',
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseDown={handleMouseDownPassword}
                      style={{ width: 31, height: 15 }}
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize={'medium'} onClick={handleClickShowPassword} />
                      ) : (
                        <Visibility fontSize={'medium'} onClick={handleClickShowPassword} />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={signInWithEmailAndPasswordHandler}
                disabled={email === null || password === null}
              >
                {loading && <CircularProgress style={{ color: '#FFFFFF', width: 30, height: 30 }} />}
                {!loading && <>{t('login:buttons.login')}</>}
              </Button>
              <a className="password-link" href="/reset-password">
                I forgot my password
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }
}
export default Login
