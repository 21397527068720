import {
  AGGREGATE_GATE_LIST_ITEM,
  isAggregateActionType,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
  UPDATE_AGGREGATE_FAILURE,
  UPDATE_AGGREGATE_REQUEST,
  UPDATE_AGGREGATE_SUCCESS,
} from '../../../common/aggregate.types'
import { GateListItemActionTypes, GateListItemState } from './types'

const initialState: GateListItemState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function gateListItemReducer(state = initialState, action: GateListItemActionTypes): GateListItemState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_GATE_LIST_ITEM) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
        updated: false,
        error: null,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
        error: null,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case UPDATE_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        error: null,
      }
    case UPDATE_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: false,
        updated: true,
        error: null,
      }
    case UPDATE_AGGREGATE_FAILURE:
      return {
        ...state,
        inserting: false,
        error: action.payload.error,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}
