import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ReactComponent as SuccessIcon } from '../../../assets/svg/ws-sml-icon-tick-2-wht.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/svg/ws-ui-icon-warning-critical-wht.svg'
import { RootState } from '../../../store'
import { clearNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'

interface IProps {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const DisplayNotification: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>(null)

  const dispatch = useDispatch()

  const { type, messages } = useSelector(
    (state: RootState) => ({
      type: state.notificationsReducer.type,
      messages: state.notificationsReducer.messages,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (messages && messages.length > 0) {
      setMessage(messages[0])
      setOpen(true)
    } else {
      dispatch(clearNotification())
    }
  }, [type, messages, dispatch])

  const handleClose = (event: any) => {
    setOpen(false)
  }

  const getNotificationType = (type: string) => {
    switch (type) {
      case NotificationType.ERROR:
        return 'error'
      case NotificationType.INFO:
        return 'success'
      case NotificationType.WARN:
        return 'warning'
    }
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Alert
        onClose={handleClose}
        severity={getNotificationType(type ? type : '')}
        iconMapping={{
          error: <ErrorIcon />,
          success: <SuccessIcon style={{ marginTop: 6 }} />,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
export default DisplayNotification
