import { Box, Button, CircularProgress, Container, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ErrorResponse, OrganisationType } from '../../../common/types'
import { selectCurrentOperatorId, selectShuffleUrl } from '../../../config/app/reducers'
import { setError } from '../../../store/common/error/action'
import { resetLoading, setLoading } from '../../../store/common/loading/action'
import { selectLoading } from '../../../store/common/loading/types'
import { clearNotification, setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { ROUTE_RESET_PASSWORD_EMAIL } from '../../util/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
}))

interface ResetPasswordRequest {
  email: string | null
  organisationType: OrganisationType
}

export const RESET_PASSWORD_API_RESOURCE_PATH =
  '/public/operators/:operatorId/organisation-users:send-password-reset-link'

interface IProps {}

const ResetPassword: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const shuffleUrl = useSelector(selectShuffleUrl)

  const loading = useSelector(selectLoading)

  const [resetPasswordRequest, setResetPasswordRequest] = useState<ResetPasswordRequest>({
    email: null,
    organisationType: OrganisationType.CarParkTenant,
  })

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'email') {
      setResetPasswordRequest({ ...resetPasswordRequest, [name]: value })
    }
  }

  const onClickResetPassword = (event: any) => {
    event.preventDefault()
    dispatch(clearNotification())
    if (!resetPasswordRequest.email) {
      dispatch(setError('Email Address Required'))
      return
    } else {
      if (currentOperatorId && shuffleUrl) {
        dispatch(setLoading())
        axios({
          method: 'POST',
          url: shuffleUrl + RESET_PASSWORD_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId),
          data: resetPasswordRequest,
        })
          .then((resp) => {
            dispatch(resetLoading())
            navigate(ROUTE_RESET_PASSWORD_EMAIL)
          })
          .catch((err) => {
            dispatch(resetLoading())

            if (err.response.data && err.response.data.errors) {
              let error: ErrorResponse[] = err.response.data.errors
              if (error && error.length > 0) {
                dispatch(setNotification(NotificationType.ERROR, [error[0].message]))
              }
            }
          })
      }
    }
  }

  return (
    <Box className={classes.loginModal}>
      <Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <h3 className="login-h3">{t('reset-password:title')}</h3>
          </Grid>
          <Grid item xs={12}>
            <p className="input-label">{t('reset-password:labels.email')}</p>
            <TextField
              inputProps={{ className: 'shuffleInput' }}
              id="email"
              name="email"
              defaultValue=""
              InputLabelProps={{ className: 'shuffleLabel' }}
              variant="outlined"
              margin="dense"
              size={'small'}
              fullWidth
              onChange={onChangeHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.shuffleButton}
              variant="contained"
              color="primary"
              size="large"
              disabled={resetPasswordRequest.email === null}
              onClick={(event) => onClickResetPassword(event)}
            >
              {loading && <CircularProgress style={{ color: '#FFFFFF', width: 30, height: 30 }} />}
              {!loading && <>{t('reset-password:buttons.resetPassword')}</>}
            </Button>
            <a className="password-link login-back" href="/login">
              {t('reset-password:link.backToLogin')}
            </a>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default ResetPassword
