import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { format, subDays } from 'date-fns'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import guestIcon from '../../assets/svg/ws-b2b-icon-consumer_cust.svg'
import mobileIcon from '../../assets/svg/ws-b2b-icon-mobile-2.svg'
import operationsIcon from '../../assets/svg/ws-b2b-icon-operations.svg'
import assignIcon from '../../assets/svg/ws-b2b-icon-reserved-2.svg'
import usersIcon from '../../assets/svg/ws-b2b-icon-staff.svg'
import {
  AGGREGATE_MY_LIST_TENANCIES,
  ALLOCATION_BY_TYPE_AGGREGATE_REQUEST,
  ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST,
  DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST,
  PEAK_ALLOCATION_AGGREGATE_REQUEST,
} from '../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../common/axios-action'
import { selectCurrentOperatorId } from '../../config/app/reducers'
import { RootState } from '../../store'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../auth/types'
import { TabType } from '../settings/users/active-users/types'
import { ROUTE_OPERATIONS, ROUTE_OPERATIONS_CONTROL_CENTRE } from '../util/routes'
import { getWelcomeMessage } from '../util/util'
import {
  ALLOCATION_BY_TYPE_API_RESOURCE_PATH,
  ALLOCATION_MEMBER_COUNT_API_RESOURCE_PATH,
  AgreementAuthorityType,
  AllocationByType,
  AllocationMemberCount,
  AllocationStatistic,
  AuthorityTypeCountAllocation,
  DAILY_PEAK_ALLOCATION_API_RESOURCE_PATH,
  DailyPeakAllocation,
  PEAK_ALLOCATION_API_RESOURCE_PATH,
  PeakAllocation,
} from './types'

interface IProps {}

const HomePage: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [location, setLocation] = useState<string>('')
  const [lastRefreshed, setLastRefreshed] = useState<string>('')
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const [allocationStatistic, setAllocationStatistic] = useState<AllocationStatistic>({
    utilisationToday: '-',
    averageUtilisation: '-',
    totalBaysAllocated: '-',
    assignments: '-',
    onDemandBookings: '-',
    guestBookings: '-',
    availableBays: '-',
    registeredUsers: '-',
    pendingRegistrations: '-',
  })
  const [isAllRequestCompleted, setIsAllRequestCompleted] = useState<boolean>(false)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const {
    tenancies,
    currentUser,
    allocationMemberCount,
    allocationMemberCountError,
    allocationByType,
    allocationByTypeError,
    dailyPeakAllocation,
    dailyPeakAllocationError,
    peakAllocation,
    peakAllocationError,
    allocationMemberCountRequestCompleted,
    allocationByTypeRequestCompleted,
    dailyPeakAllocationRequestCompleted,
    peakAllocationRequestCompleted,
  } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
    currentUser: state.authReducer.currentUser,
    allocationMemberCount: state.allocationMemberCountReducer.aggregate,
    allocationMemberCountError: state.allocationMemberCountReducer.error,
    allocationMemberCountRequestCompleted: state.allocationMemberCountReducer.updatedApplicationStatus,
    allocationByType: state.allocationByTypeReducer.aggregate,
    allocationByTypeError: state.allocationByTypeReducer.error,
    allocationByTypeRequestCompleted: state.allocationByTypeReducer.updatedApplicationStatus,
    dailyPeakAllocation: state.dailyPeakAllocationReducer.aggregate,
    dailyPeakAllocationError: state.dailyPeakAllocationReducer.error,
    dailyPeakAllocationRequestCompleted: state.dailyPeakAllocationReducer.updatedApplicationStatus,
    peakAllocation: state.peakAllocationReducer.aggregate,
    peakAllocationError: state.peakAllocationReducer.error,
    peakAllocationRequestCompleted: state.peakAllocationReducer.updatedApplicationStatus,
  }))

  // Fetch initial data
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      getTenancies()
      getAllocationMemberCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId])

  // Update state value from --> allocation member count
  useEffect(() => {
    if (allocationMemberCount) {
      setAllocationStatistic({
        ...allocationStatistic,
        registeredUsers: allocationMemberCount.members ? allocationMemberCount.members : 0,
        pendingRegistrations: allocationMemberCount.pendingApplications ? allocationMemberCount.pendingApplications : 0,
      })
    }
    if (allocationMemberCountError) {
      setAllocationStatistic({
        ...allocationStatistic,
        registeredUsers: '-',
        pendingRegistrations: '-',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationMemberCount, allocationMemberCountError])

  // Update state value from --> allocation by type
  useEffect(() => {
    if (allocationByType) {
      let entitlementGuest: AuthorityTypeCountAllocation | undefined =
        allocationByType.authorityTypeCountAllocations.find(
          (authority: AuthorityTypeCountAllocation) =>
            authority.agreementAuthorityType === AgreementAuthorityType.EntitlementGuest,
        )
      let entitlementMember: AuthorityTypeCountAllocation | undefined =
        allocationByType.authorityTypeCountAllocations.find(
          (authority: AuthorityTypeCountAllocation) =>
            authority.agreementAuthorityType === AgreementAuthorityType.EntitlementAssignment,
        )
      let salesChannelRes: AuthorityTypeCountAllocation | undefined =
        allocationByType.authorityTypeCountAllocations.find(
          (authority: AuthorityTypeCountAllocation) =>
            authority.agreementAuthorityType === AgreementAuthorityType.SalesChannelRes,
        )
      setAllocationStatistic({
        ...allocationStatistic,
        totalBaysAllocated: allocationByType.capacity,
        assignments: entitlementMember ? entitlementMember.count : 0,
        onDemandBookings: salesChannelRes ? salesChannelRes.count : 0,
        guestBookings: entitlementGuest ? entitlementGuest.count : 0,
      })
    }
    if (allocationByTypeError) {
      setAllocationStatistic({
        ...allocationStatistic,
        totalBaysAllocated: '-',
        assignments: '-',
        onDemandBookings: '-',
        guestBookings: '-',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationByType, allocationByTypeError])

  // Update state value from --> daily peak allocation
  useEffect(() => {
    let xAxisData: string[] = []
    let seriesData: number[] = []

    if (dailyPeakAllocation) {
      setAllocationStatistic({
        ...allocationStatistic,
        averageUtilisation: dailyPeakAllocation.averageAllocationPercent
          ? dailyPeakAllocation.averageAllocationPercent
          : 0,
      })
      xAxisData = dailyPeakAllocation.peakAllocations.map((e) => format(new Date(e.date), 'dd-MMM'))
      seriesData = dailyPeakAllocation.peakAllocations.map((e) =>
        e.peakAllocationPercent ? e.peakAllocationPercent : 0,
      )
    }
    if (dailyPeakAllocationError) {
      setAllocationStatistic({
        ...allocationStatistic,
        averageUtilisation: '-',
      })

      seriesData = Array(30).fill(0)
      let tempAxisData: Date[] = []
      seriesData.forEach((n: number, i: number) => {
        const result: Date = subDays(new Date(), i)
        tempAxisData.push(result)
      })
      xAxisData = tempAxisData.reverse().map((e: Date) => format(new Date(e), 'dd-MMM'))
    }
    let option: EChartsOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        formatter: function formatTooltip(params: any) {
          let formattedTooltip: string = ''
          params.forEach((item: any) => {
            formattedTooltip += `<div class="display-flex">
          <p style="color:#525252;font-size:12px;">${item.name}</p>
          <p style="color:#525252;font-size:12px;padding-left:7px;">${item.value}%</p>
          </div>`
          })
          return formattedTooltip
        },
      },
      grid: {
        left: '0',
        right: '0',
        bottom: '4',
        top: '7',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: true,
          interval: 0,
        },
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            show: true,
            fontSize: '9px',
          },
          max: function () {
            return 100
          },
        },
      ],
      series: [
        {
          name: 'Utilisation',
          type: 'bar',
          data: seriesData,
          color: '#0085DA',
          cursor: 'auto',
          barWidth: '6px',
          emphasis: {
            itemStyle: {
              color: '#00436D',
            },
          },
        },
      ],
    }
    setChartOption(option)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyPeakAllocation, dailyPeakAllocationError])

  // Update state value from --> peak allocation
  useEffect(() => {
    if (peakAllocation) {
      setAllocationStatistic({
        ...allocationStatistic,
        utilisationToday: peakAllocation.peakAllocationPercent ? peakAllocation.peakAllocationPercent : 0,
        availableBays: peakAllocation.peakAvailableBays ? peakAllocation.peakAvailableBays : 0,
      })
    }
    if (peakAllocationError) {
      setAllocationStatistic({
        ...allocationStatistic,
        utilisationToday: '-',
        availableBays: '-',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peakAllocation, peakAllocationError])

  // Refresh data on location change
  useEffect(() => {
    if (location) {
      getAllocationByType()
      getDailyPeakAllocation()
      getPeakAllocation()
      setLastRefreshedDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Set location
  useEffect(() => {
    if (tenancies && tenancies.length > 0) {
      let facilityId = localStorage.getItem('facilityId')
      if (facilityId) {
        setLocation(facilityId)
      } else {
        let facility = tenancies[0]
        if (facility) {
          localStorage.setItem('facilityId', facility.id)
          setLocation(facility.id)
        }
      }
    }
  }, [tenancies])

  // Manage loading
  useEffect(() => {
    if (
      allocationMemberCountRequestCompleted &&
      allocationByTypeRequestCompleted &&
      dailyPeakAllocationRequestCompleted &&
      peakAllocationRequestCompleted
    ) {
      setIsAllRequestCompleted(true)
    } else {
      setIsAllRequestCompleted(false)
    }
  }, [
    allocationMemberCountRequestCompleted,
    allocationByTypeRequestCompleted,
    dailyPeakAllocationRequestCompleted,
    peakAllocationRequestCompleted,
  ])

  // Fetch report data
  const getTenancies = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }
  const getAllocationMemberCount = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<AllocationMemberCount>(
          ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST,
          ALLOCATION_MEMBER_COUNT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }
  const getAllocationByType = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<AllocationByType>(
          ALLOCATION_BY_TYPE_AGGREGATE_REQUEST,
          ALLOCATION_BY_TYPE_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + (location ? `?facilityId=${location}` : ''),
          null,
        ),
      )
    }
  }
  const getDailyPeakAllocation = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<DailyPeakAllocation>(
          DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST,
          DAILY_PEAK_ALLOCATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + (location ? `?facilityId=${location}` : ''),
          null,
        ),
      )
    }
  }
  const getPeakAllocation = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<PeakAllocation>(
          PEAK_ALLOCATION_AGGREGATE_REQUEST,
          PEAK_ALLOCATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + (location ? `?facilityId=${location}` : ''),
          null,
        ),
      )
    }
  }

  // Handlers
  const handleChangeLocation = (event: SelectChangeEvent) => {
    let { value } = event.target
    localStorage.setItem('facilityId', value)
    setLocation(value)
  }
  const goToManageUser = (tabType: string) => {
    navigate('/users/manage-users', {
      state: { tabType: tabType },
    })
  }
  const onRefresh = () => {
    getAllocationByType()
    getDailyPeakAllocation()
    getPeakAllocation()
    setLastRefreshedDetail()
  }
  const setLastRefreshedDetail = () => {
    let lastRefreshedDate = format(new Date(), 'dd MMM yy')
    let lastRefreshedTime = format(new Date(), 'hh:mmaaa')
    setLastRefreshed(lastRefreshedDate + ' at ' + lastRefreshedTime)
  }

  return (
    <div className="page-content-wrapper dashboard-section">
      <Container className="page-content-header-wrapper dashboard-section">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="full-width top-section">
            <h1 className="dashboard-h1">{getWelcomeMessage(currentUser ? currentUser?.name : '')} </h1>
          </Grid>
        </Grid>
      </Container>
      <Container className="page-content-body-wrapper dashboard-section">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="full-width bottom-section">
            <Grid item xs={12} className="content-panel">
              <Grid container spacing={2} style={{ paddingBottom: '32px' }}>
                <Grid item xs={4} style={{ paddingRight: '0px' }}>
                  <h2>Your parking</h2>
                  <p className="last-refreshed">
                    Last refreshed {lastRefreshed}{' '}
                    <span className="refresh" onClick={() => onRefresh()}>
                      Refresh
                    </span>
                  </p>
                </Grid>
                <Grid item xs={2} style={{ paddingRight: '0px' }}>
                  <p className="dashboard-link">
                    <a className="dash-link" href="/utilisation/dashboard">
                      Utilisation dashboard
                    </a>
                  </p>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '64px' }}>
                  <InputLabel className="select-heading">Location</InputLabel>
                  <Select
                    className="shuffle-select"
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    value={location}
                    onChange={handleChangeLocation}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name="location"
                    displayEmpty
                  >
                    {tenancies.map((tenancy: Tenancy) => (
                      <MenuItem key={tenancy.id} value={tenancy.id}>
                        {tenancy.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              {isAllRequestCompleted ? (
                <Grid container style={{ height: 235 }}>
                  <Grid container spacing={2} style={{ paddingBottom: '29px' }}>
                    <Grid item xs={6} style={{ paddingRight: '0px' }}>
                      <Grid container spacing={2} style={{ alignItems: 'flex-end' }} className="mobile-columns">
                        <Grid item xs={6}>
                          <h3 className="dash-data-h3">{allocationStatistic.utilisationToday}%</h3>
                          <p className="dash-data-text">
                            <a href="/utilisation/dashboard" className="dash-more">
                              Utilisation today
                            </a>
                          </p>
                        </Grid>
                        <Grid item xs={6}>
                          <h4 className="dash-data-h4" style={{ textAlign: 'right' }}>
                            {allocationStatistic.averageUtilisation}%
                          </h4>
                          <p className="dash-data-text align-right">Average utilisation past 30 days</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '64px' }} className="desktop-only">
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <h3 className="dash-data-h3">{allocationStatistic.totalBaysAllocated}</h3>
                          <p className="dash-data-text">Total bays allocated</p>
                        </Grid>
                        <Grid item xs={5}>
                          <h3 className="dash-data-h3">{allocationStatistic.registeredUsers}</h3>
                          <p className="dash-data-text">
                            <span className="dash-more" onClick={() => goToManageUser(TabType.Active_Users)}>
                              Registered users
                            </span>
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ paddingRight: '0px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {chartOption && (
                            <ReactEcharts option={chartOption} style={{ height: '118px', width: '100%' }} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '64px' }} className="desktop-only">
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <Grid container spacing={2} style={{ paddingBottom: '32px' }}>
                            <Grid item xs={6}>
                              <h5 className="dash-data-h5">{allocationStatistic.assignments}</h5>
                              <p className="dash-data-sml-text">Assignments</p>
                            </Grid>
                            <Grid item xs={6}>
                              <h5 className="dash-data-h5">{allocationStatistic.onDemandBookings}</h5>
                              <p className="dash-data-sml-text">On Demand bookings</p>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <h5 className="dash-data-h5">{allocationStatistic.guestBookings}</h5>
                              <p className="dash-data-sml-text">Guest bookings</p>
                            </Grid>
                            <Grid item xs={6}>
                              <h5 className="dash-data-h5">{allocationStatistic.availableBays}</h5>
                              <p className="dash-data-sml-text">Available bays</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                          <Grid container spacing={2} style={{ paddingBottom: '32px' }}>
                            <Grid item xs={12}>
                              <h5 className="dash-data-h5">{allocationStatistic.pendingRegistrations}</h5>
                              <p className="dash-data-sml-text">
                                <span className="dash-more" onClick={() => goToManageUser(TabType.Pending_Approval)}>
                                  Pending registrations
                                </span>
                              </p>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  style={{ height: 235, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <CircularProgress color="primary" size={30} />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} style={{ display: 'flex', paddingBottom: '20px' }}>
              <Grid item xs={2.4} className="eq-height-col desktop-only">
                <Box className="dash-section-card">
                  <div className="dash-icon-wrapper">
                    <img className="dash-section-icon dash-assign" src={assignIcon} alt="Assigned parking" />
                  </div>
                  <h3>Assigned parking</h3>
                  <p className="dash-card-text">
                    Assign parking bays to a single user or share a bay between multiple users.
                  </p>
                  <ul className="dash-card-links">
                    <li>
                      <a href="/assigned/manage-parkers" className="link-text">
                        Manage parkers
                      </a>
                    </li>
                    <li>
                      <a href="/assigned/assign-parking" className="link-text">
                        Assign parking
                      </a>
                    </li>
                    <li>
                      <a href="/assigned/manage-bay" className="link-text">
                        Manage bay
                      </a>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={2.4} className="eq-height-col desktop-only">
                <Box className="dash-section-card">
                  <div className="dash-icon-wrapper">
                    <img className="dash-section-icon dash-on-demand" src={mobileIcon} alt="On-demand parking" />
                  </div>
                  <h3>On-demand parking</h3>
                  <p className="dash-card-text">Bays for your staff to book on-demand.</p>
                  <ul className="dash-card-links">
                    <li>
                      <a href="/on-demand/transactions" className="link-text">
                        Transactions
                      </a>
                    </li>
                    <li>
                      <a href="/on-demand/book-parking" className="link-text">
                        Book parking
                      </a>
                    </li>
                    <li>
                      <a href="/on-demand/product-capacities" className="link-text">
                        Products and capacities
                      </a>
                    </li>
                    <li>
                      <a href="/on-demand/block-out-dates" className="link-text">
                        Block out dates
                      </a>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={2.4} className="eq-height-col desktop-only">
                <Box className="dash-section-card">
                  <div className="dash-icon-wrapper">
                    <img className="dash-section-icon dash-book-guest" src={guestIcon} alt="Guest parking" />
                  </div>
                  <h3>Guest parking</h3>
                  <p className="dash-card-text">Book parking for a guest, visitor or contractor.</p>
                  <ul className="dash-card-links">
                    <li>
                      <a href="/guest/book-guest-parking" className="link-text">
                        Book for a guest
                      </a>
                    </li>
                    <li>
                      <a href="/guest/transactions" className="link-text">
                        Bookings
                      </a>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={2.4} className="eq-height-col desktop-only">
                <Box className="dash-section-card">
                  <div className="dash-icon-wrapper">
                    <img className="dash-section-icon dash-users" src={usersIcon} alt="Users" />
                  </div>
                  <h3>Users</h3>
                  <p className="dash-card-text">Manage users and bulk user uploads.</p>
                  <ul className="dash-card-links">
                    <li>
                      <a href="/users/manage-users" className="link-text">
                        Manage users
                      </a>
                    </li>
                    <li>
                      <a href="/users/bulk-registrations" className="link-text">
                        Bulk registrations
                      </a>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={2.4} className="eq-height-col">
                <Box className="dash-section-card">
                  <div className="dash-icon-wrapper">
                    <img className="dash-section-icon dash-operations" src={operationsIcon} alt="Operations" />
                  </div>
                  <h3>Operations</h3>
                  <p className="dash-card-text">Help your parkers get in and out of the car park.</p>
                  <ul className="dash-card-links">
                    <li>
                      <a href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE} className="link-text">
                        Control Centre
                      </a>
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <style>{`
        #footer {
          background-color: #f6f6f6;
        }
      `}</style>
    </div>
  )
}

export default HomePage
