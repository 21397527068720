import {
  AuthActionTypes,
  AuthState,
  GET_WHO_AM_I_FAILURE,
  GET_WHO_AM_I_REQUEST,
  GET_WHO_AM_I_SUCCESS,
  SET_LOGGED_OFF,
  SET_LOGGED_ON,
} from './types'

const initialState: AuthState = {
  initialized: false,
  loading: false,
  loggedIn: false,
  user: null,
  error: null,
  currentUser: null,
  authoritiesLoaded: false,
}

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SET_LOGGED_OFF: {
      return {
        currentUser: null,
        initialized: true,
        loading: false,
        loggedIn: false,
        authoritiesLoaded: false,
        user: null,
        error: null,
      }
    }
    case SET_LOGGED_ON: {
      return {
        ...state,
        initialized: true,
        loggedIn: true,
        user: action.payload.user,
        authoritiesLoaded: false,
        loading: false,
      }
    }
    case GET_WHO_AM_I_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        currentUser: null,
        authoritiesLoaded: false,
      }
    }
    case GET_WHO_AM_I_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        currentUser: action.payload.currentUser,
        authoritiesLoaded: true,
      }
    }
    case GET_WHO_AM_I_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentUser: null,
        authoritiesLoaded: false,
      }
    }

    default:
      return state
  }
}
