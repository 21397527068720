export interface FilterState {
  status: string
  entryDate: string
  location: string
}
export interface EntryDateErrorState {
  entryDateFrom: string
  entryDateTo: string
}
export interface EntryDateState {
  entryDateFrom: Date | null
  entryDateTo: Date | null
}

export interface RequestParams {
  page?: number
  size?: number
  sortBy?: string
  sortDirection?: string
  agreementStatus?: string
  facilityId?: string
  fromStartDate?: string
  toStartDate?: string
  reference?: string
  name?: string
}

export const DATE_IS_INVALID = 'Date is invalid'
export const END_DATE_MUST_BE_LATER_THAN_START_DATE = 'End date must be later than start date'
