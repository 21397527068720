import axios, { AxiosRequestConfig } from 'axios'
import { format } from 'date-fns'
import firebase from 'firebase/compat/app'
import { ShuffleApiConfig } from '../config/app/types'
import { authorizationHeaderConfig } from '../config/axios/axios'
import { AUTHORIZATION_HEADER, BEARER_PREFIX, SERVER_API_KEY_HEADER, SERVER_FILE_TIMEOUT } from '../config/constants'
import { AgreementAuthorityType } from '../pages/home/types'
import { AgreementsDetail } from '../pages/on-demand/transactions/types'
import { RootState } from '../store'
import { DepartmentTeamListItemDto, IdNameDto } from '../store/common/departments-teams/types'
import { setNotification } from '../store/common/notifications/action'
import { NotificationType } from '../store/common/notifications/types'
import { chooseUrl } from './axios-action'
import { AgreementStatus, EntryStatus, FileStatus, ReasonType } from './types'

export function extractTenant(hostname: string): string {
  const pieces = hostname.split('.')
  if (pieces.length > 1) {
    return pieces[0]
  } else {
    return hostname
  }
}

export const findConfiguration = async (state: RootState) => {
  const appConfig = state.appConfigReducer
  const shuffleApiConfig = appConfig.shuffleApiConfig
  const tenantConfig = appConfig.tenantConfig
  const operatorId = tenantConfig?.config.operatorId
  const shuffleUrl = shuffleApiConfig?.shuffleApiUrl
  const tenantId = tenantConfig?.tenant.id
  const apiKey = tenantConfig?.tenant.apiKey
  const currentUser = state.authReducer.user
  const carParkTenantId = state.authReducer.currentUser?.organisationId
  const firebaseUser = state.authReducer.user
  const token = await state.authReducer.user?.getIdToken()
  // Check for mandatory config
  if (shuffleUrl == null) throw Error('url missing from shuffleApiConfig')
  if (tenantId == null) throw Error('tenantId missing from tenantConfig')
  if (apiKey == null) throw Error('apiKey missing from tenantConfig')
  if (operatorId == null) throw Error('operatorId missing from tenantConfig')
  if (carParkTenantId == null) throw Error('carParkTenantId missing from tenantConfig')
  return { shuffleUrl, carParkTenantId, tenantId, apiKey, operatorId, firebaseUser, currentUser, token }
}

export const buildAxiosConfig = async (state: RootState, params: any): Promise<AxiosRequestConfig> => {
  const appConfig = state.appConfigReducer
  const shuffleApiConfig = appConfig.shuffleApiConfig
  const tenantConfig = appConfig.tenantConfig
  const shuffleUrl = shuffleApiConfig?.shuffleApiUrl
  const apiKey = tenantConfig?.tenant.apiKey
  const firebaseUser = state.authReducer.user
  if (shuffleUrl == null) throw Error('url missing from shuffleApiConfig')
  if (apiKey == null) throw Error('apiKey missing from tenantConfig')
  if (firebaseUser != null) {
    const idToken = await firebaseUser.getIdToken()
    return {
      baseURL: shuffleUrl,
      headers: {
        [SERVER_API_KEY_HEADER]: apiKey,
        [AUTHORIZATION_HEADER]: BEARER_PREFIX + idToken,
      },
      params: params,
    }
  } else {
    return {
      baseURL: shuffleUrl,
      headers: {
        [SERVER_API_KEY_HEADER]: apiKey,
      },
    }
  }
}

export const getAgreementStatusFormattedName = (agreementStatus: string): string => {
  switch (agreementStatus) {
    case AgreementStatus.Valid:
      return 'Valid'
    case AgreementStatus.Completed:
      return 'Completed'
    case AgreementStatus.Expired:
      return 'Expired'
    case AgreementStatus.Amended:
      return 'Amended'
    case AgreementStatus.Cancelled:
      return 'Cancelled'
    case AgreementStatus.Pending:
      return 'Pending'
    case AgreementStatus.NoShow:
      return 'No Show'
    default:
      return ''
  }
}

export const getFileStatusFormattedName = (fileStatus: string): string => {
  switch (fileStatus) {
    case FileStatus.Complete:
      return 'Completed'
    case FileStatus.Pending:
      return 'Pending'
    case FileStatus.Processing:
      return 'Processing'
    default:
      return ''
  }
}

// One time slot every 30 minutes.
export const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) => `${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}${index < 24 ? 'am' : 'pm'}`,
)

export const exportCSV = async (
  user: firebase.User | null,
  resourcePath: string,
  shuffleApiConfig: ShuffleApiConfig,
  requestParams: any,
  dispatch: any,
) => {
  try {
    let authorization = user!.getIdToken()
    await authorization
      .then((token: any) => {
        let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
        config.timeout = SERVER_FILE_TIMEOUT
        config.params = requestParams
        axios
          .get<any>(resourcePath, config)
          .then(function (response) {
            if (response) {
              var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
              var url = URL.createObjectURL(blob)
              var pom = document.createElement('a')
              pom.href = url
              pom.setAttribute('download', 'records.csv')
              pom.click()
              pom.remove()
              let message = 'File downloaded successfully.'
              dispatch(setNotification(NotificationType.INFO, [message]))
            }
          })
          .catch(function (error) {
            console.error(error)
            if (error.response.data.errors) {
              dispatch(setNotification(NotificationType.ERROR, [error.response.data.errors[0].message]))
            } else {
              dispatch(
                setNotification(NotificationType.ERROR, ['File did not download. Please refresh and try again.']),
              )
            }
          })
      })
      .catch((error: any) => {
        console.error(error)
        dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
      })
  } catch (err) {
    console.error(err)
    dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
  }
}

export const getEntryStatusFormattedName = (entryStatus: EntryStatus): string => {
  switch (entryStatus) {
    case EntryStatus.OffSite:
      return 'Outside'
    case EntryStatus.TransitIn:
      return 'Transiting in'
    case EntryStatus.OnSite:
      return 'Inside'
    case EntryStatus.TransitOut:
      return 'Transiting out'
    case EntryStatus.Fallback:
      return 'Fallback'
    default:
      return ''
  }
}

export const formatNumberWithDecimalPlace = (amount: number | null, decimalPoint: number): string => {
  if (amount) {
    return (Math.round(amount * 100) / 100).toFixed(decimalPoint)
  } else {
    return '0.00'
  }
}

export const customSorting = (a: any, b: any) => {
  if (typeof a.name === 'number' && typeof b.name === 'number') {
    return a.name - b.name // Numeric comparison
  } else if (typeof a.name === 'string' && typeof b.name === 'string') {
    return a.name.localeCompare(b.name) // Alphabetical comparison
  } else if (typeof a.name === 'number') {
    return -1 // Numeric elements should come before alphabetical elements
  } else {
    return 1 // Alphabetical elements should come after numeric elements
  }
}

export const getCapitalizeString = (text: string) => {
  const arr = text.split(' ')
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr.join(' ')
}

export const getCurrentInstant = (): number => {
  const currentInstant = (Date.now() / 1000).toFixed(0)
  return parseInt(currentInstant)
}

export const getAgreementAuthorityTypeFormattedName = (type: AgreementAuthorityType): string => {
  switch (type) {
    case AgreementAuthorityType.EntitlementAssignment:
      return 'Assigned'
    case AgreementAuthorityType.EntitlementGuest:
      return 'Guest'
    case AgreementAuthorityType.SalesChannelRes:
      return 'On-demand'
    default:
      return ''
  }
}

export const getDepartmentName = (departmentId: string, departmentTeamsList: DepartmentTeamListItemDto[]) => {
  let departmentName: string = ''
  let department = departmentTeamsList.find((d: DepartmentTeamListItemDto) => d.id === departmentId)
  if (department) {
    departmentName = department.name
  }
  return departmentName
}

export const getTeamName = (departmentId: string, teamId: string, departmentTeamsList: DepartmentTeamListItemDto[]) => {
  let teamName: string = ''
  let department = departmentTeamsList.find((d: DepartmentTeamListItemDto) => d.id === departmentId)
  if (department) {
    let team = department.teams.find((t: IdNameDto) => t.id === teamId)
    if (team) {
      teamName = team.name
    }
  }
  return teamName
}

export const getAgreementStayDuration = (agreementDetail: AgreementsDetail) => {
  if (agreementDetail) {
    let agreement = agreementDetail.stayAgreements[0]
    let startDateTime = format(Date.parse(agreement.singleStay.startDateTime), 'E dd MMMM hh:mmaaa')
    let finishDateTime = format(Date.parse(agreement.singleStay.finishDateTime), 'E dd MMMM hh:mmaaa')
    return startDateTime + ' - ' + finishDateTime
  } else {
    return ''
  }
}

export const getReasonTypeDisplayValue = (reasonType: ReasonType): string => {
  switch (reasonType) {
    case ReasonType.AccessHardwareIssue:
      return 'Access Hardware Issue'
    case ReasonType.MobileDeviceIssue:
      return 'Mobile Device Issue'
    case ReasonType.NetworkIssue:
      return 'Network Issue'
    case ReasonType.Other:
      return 'Other'
    case ReasonType.OutOfSync:
      return 'Out of Sync'
    case ReasonType.Overstay:
      return 'Overstay'
    default:
      return ''
  }
}
