import { Box, Button, Container, Grid, LinearProgress } from '@mui/material'
import TextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import csvIcon from '../../assets/svg/ws-b2b-icon-file-csv.svg'
import { chooseUrl } from '../../common/axios-action'
import { selectCurrentOperatorId } from '../../config/app/reducers'
import { authorizationHeaderConfig } from '../../config/axios/axios'
import { selectCurrentOrganisationId } from '../../pages/auth/types'
import { MEMBER_BULK_UPLOAD_SUMMARY_API_RESOURCE_PATH } from '../../pages/users/bulk-uploads/types'
import { RootState } from '../../store'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    width: '590px',
    maxWidth: '590px',
  },
}))
const SlowLinearProgress = withStyles({
  bar: {
    animationDuration: '2s',
  },
})(LinearProgress)

interface IProps {
  display: boolean
  cancelModel: () => void
  confirmModel: () => void
}

const AddBulkUserModal: React.FC<IProps> = (props) => {
  const { display, cancelModel, confirmModel }: IProps = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const fileUploadInput = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)

  const { user, shuffleApiConfig } = useSelector((state: RootState) => ({
    user: state.authReducer.user,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
  }))
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const onClickFileUpload = () => {
    if (fileUploadInput && fileUploadInput.current) {
      fileUploadInput.current.click()
    }
  }
  const onRemoveFile = () => {
    setSelectedFile(null)
    if (fileUploadInput && fileUploadInput.current) {
      fileUploadInput.current.value = ''
    }
  }
  const getFileInfo = (event: ChangeEvent<HTMLInputElement>) => {
    let fileList: FileList | null = event.target.files
    if (fileList && fileList.length > 0) {
      let file: File = fileList[0]
      if (file) {
        // 10 MB = 10485760 Bytes
        if (file.type !== 'text/csv') {
          dispatch(setNotification(NotificationType.ERROR, ['Uploaded file format must be CSV.']))
          onRemoveFile()
        } else if (file.size >= 10485760) {
          dispatch(setNotification(NotificationType.ERROR, ['Uploaded File size must be less than 10MB.']))
          onRemoveFile()
        } else {
          setSelectedFile(file)
        }
      } else {
        onRemoveFile()
      }
    } else {
      onRemoveFile()
    }
  }

  const onUpload = async () => {
    if (selectedFile) {
      setIsFileUploading(true)
      let resourcePath = MEMBER_BULK_UPLOAD_SUMMARY_API_RESOURCE_PATH
      if (currentOperatorId && currentOrganisationId) {
        resourcePath = resourcePath
          .replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
      }
      let formData = new FormData()
      formData.append('file', selectedFile)
      try {
        let authorization = user!.getIdToken()
        await authorization
          .then((token: any) => {
            let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
            config.timeout = 420000
            axios
              .post<any>(resourcePath, formData, config)
              .then(function (response) {
                let message = 'File successfully loaded for processing.'
                dispatch(setNotification(NotificationType.INFO, [message]))
                onRemoveFile()
                confirmModel()
                setIsFileUploading(false)
              })
              .catch(function (error) {
                console.error(error)
                onRemoveFile()
                setIsFileUploading(false)
                if (error.response.data.errors) {
                  dispatch(setNotification(NotificationType.ERROR, [error.response.data.errors[0].message]))
                } else {
                  dispatch(
                    setNotification(NotificationType.ERROR, [
                      'File did not upload for processing. Please refresh and resubmit for upload.',
                    ]),
                  )
                }
              })
          })
          .catch((error: any) => {
            console.error(error)
            onRemoveFile()
            setIsFileUploading(false)
            dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
          })
      } catch (err) {
        console.error(err)
        onRemoveFile()
        setIsFileUploading(false)
        dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
      }
    }
  }

  useEffect(() => {
    onRemoveFile()
  }, [display])

  const downloadSampleFile = () => {
    const csvLink = document.createElement('a')
    csvLink.href = '/files/Shuffle Bulk User Upload Sample CSV File.csv'
    csvLink.download = 'Shuffle Bulk User Upload Sample CSV File.csv'
    csvLink.click()

    const pdfLink = document.createElement('a')
    pdfLink.href = '/files/Shuffle User Bulk Upload Specifications v1.1.pdf'
    pdfLink.download = 'Shuffle User Bulk Upload Specifications v1.1.pdf'
    pdfLink.click()
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles settings-modal">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-h3 larger-text">Bulk add users</h3>
              <p>You can add users in bulk by uploading a CSV file containing the details of your users.</p>
            </Grid>
            <Grid item xs={12}>
              <Box className="download-sample">
                <img src={csvIcon} className="csv-icon" alt="CSV" />
                <div className="download-desc-text">
                  <p>
                    <strong>File Structure</strong>
                  </p>
                  <p>
                    Please download this sample CSV file and specification PDF containing the structure that your user
                    data should follow. Do not exceed 1000 user records per file upload.
                  </p>
                </div>
                <span className="download-sample-link" onClick={() => downloadSampleFile()}>
                  Download
                </span>
              </Box>
            </Grid>
            <input
              type="file"
              id="myFile"
              className="display-none"
              ref={fileUploadInput}
              onChange={(event) => getFileInfo(event)}
            />
            {selectedFile ? (
              <Grid item xs={12}>
                <p className="input-label">Upload file</p>
                <TextField
                  id="upload-field"
                  type="text"
                  InputProps={{ readOnly: true, className: 'shuffleInput' }}
                  value={selectedFile ? selectedFile.name : 'Choose file'}
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  disabled
                  margin="dense"
                />
                {isFileUploading ? (
                  <span className="remove-upload"></span>
                ) : (
                  <span className="remove-upload" onClick={() => onRemoveFile()}></span>
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={9}>
                  <div className="upload-file-wrapper">
                    <p className="input-label">Upload file</p>
                    <TextField
                      id="outlined-basic"
                      type="text"
                      onClick={() => onClickFileUpload()}
                      InputProps={{ readOnly: true, className: 'shuffleInput' }}
                      value="Choose file"
                      InputLabelProps={{ className: 'shuffleLabel' }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid item xs={3} className="vert-centered">
                  <span className="choose-file-link" onClick={() => onClickFileUpload()}>
                    Choose file
                  </span>
                </Grid>
              </>
            )}
            {isFileUploading && (
              <Grid item xs={12} style={{ paddingTop: '0px' }}>
                <SlowLinearProgress color="error" style={{ height: '7px', borderRadius: '10px' }} />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} direction="row" alignItems="center" style={{ marginTop: '15px' }}>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={isFileUploading}
                  onClick={() => cancelModel()}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center"></Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!selectedFile || isFileUploading}
                  onClick={() => onUpload()}
                >
                  Upload
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}
export default AddBulkUserModal
