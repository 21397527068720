import { Box, Container, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import axios from 'axios'
import { applyActionCode, checkActionCode, getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ErrorResponse, OrganisationType } from '../../../common/types'
import { selectCurrentOperatorId, selectShuffleUrl } from '../../../config/app/reducers'
import { useQuery } from '../../../hook/useQuery'
import { RootState } from '../../../store'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { RESET_PASSWORD_API_RESOURCE_PATH } from '../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginModal: {
      background: 'white',
      padding: '40px',
      borderRadius: '4px',
      position: 'absolute',
      top: '194px',
      width: '485px',
      textAlign: 'center',
      marginBottom: '40px',
    },
  }),
)

interface IProps {}

const VerifyEmailConfirm: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const [oobCode, setOobCode] = useState<string | null>(null)
  const [contentTitle, setContentTitle] = useState<string>('')
  const [contentBody, setContentBody] = useState<string[]>([])
  const [firebaseInitialize, setFirebaseInitialize] = useState<boolean>(false)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const shuffleUrl = useSelector(selectShuffleUrl)
  const { tenantConfig } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
    }),
    shallowEqual,
  )

  // Get query
  useEffect(() => {
    if (query) {
      setOobCode(query.get('oobCode'))
    }
  }, [query])

  // Initialize firebase app
  useEffect(() => {
    if (tenantConfig != null) {
      const firebaseConfig = tenantConfig.firebase
      firebase.initializeApp(firebaseConfig)
      firebase.auth().tenantId = tenantConfig.firebaseAuth.tenantId
      setFirebaseInitialize(true)
    }
  }, [tenantConfig])

  // Verification email against firebase
  // If successful, call the anon password reset link backend to send out password reset email
  useEffect(() => {
    if (firebaseInitialize && oobCode && currentOperatorId) {
      const auth = getAuth()
      let restoredEmail: string | null | undefined = null
      checkActionCode(auth, oobCode)
        .then((resp) => {
          restoredEmail = resp['data']['email']
          if (restoredEmail) {
            applyActionCode(auth, oobCode)
              .then((resp) => {
                axios({
                  method: 'POST',
                  url: shuffleUrl + RESET_PASSWORD_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId),
                  data: {
                    email: restoredEmail,
                    organisationType: OrganisationType.CarParkTenant,
                  },
                })
                  .then((response) => {
                    setContentTitle("Thanks, you're ready to start using Shuffle")
                    setContentBody([
                      "We've sent a link to your registered email account to reset your password.",
                      "If you don't receive the email, please check your spam folder or contact support.",
                    ])
                  })
                  .catch((err) => {
                    if (err.response.data && err.response.data.errors) {
                      let error: ErrorResponse[] = err.response.data.errors
                      if (error && error.length > 0) {
                        dispatch(setNotification(NotificationType.ERROR, [error[0].message]))
                      }
                    }
                    setContentTitle('Password Reset Failed')
                    setContentBody(['Oops something went wrong. please try again after some time.'])
                  })
              })
              .catch((err) => {
                console.error('error ===>', err)
                setContentTitle('Email Verification Failed')
                setContentBody(['This can happen if the code is malformed, expired, or has already been used.'])
              })
          } else {
            setContentTitle('Email Verification Failed')
            setContentBody(['This can happen if the code is malformed, expired, or has already been used.'])
          }
        })
        .catch((error) => {
          console.error('error ===>', error)
          setContentTitle('Email Verification Failed')
          setContentBody(['This can happen if the code is malformed, expired, or has already been used.'])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseInitialize, oobCode, currentOperatorId])

  return (
    <Box className={classes.loginModal}>
      <Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <h3 className="login-h3">{contentTitle}</h3>
          </Grid>
          <Grid item xs={12}>
            {contentBody.length > 0 &&
              contentBody.map((content: string, index: number) => {
                return (
                  <p className="email-verification-message" key={index + 'x'}>
                    {content}
                  </p>
                )
              })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default VerifyEmailConfirm
