import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import backIcon from '../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import {
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_VIEW_ENTITLEMENT,
} from '../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../common/axios-action'
import { getDepartmentName, getEntryStatusFormattedName, getTeamName } from '../../common/utility'
import ControlCentreEntryStatusModal from '../../components/model/ControlCentreEntryStatusModal'
import ControlCentreOpenGateModal from '../../components/model/ControlCentreOpenGateModal'
import { selectCurrentOperatorId } from '../../config/app/reducers'
import { RootState } from '../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../store/common/departments-teams/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../store/common/tenancies/types'
import { MEMBER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH } from '../../store/common/update-entry-status/types'
import { ENTITLEMENTS_API_RESOURCE_PATH } from '../assigned/assigned-parking/types'
import { ViewEntitlementInterface } from '../assigned/view-entitlement/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../auth/types'
import { MemberForm, TENANT_MEMBER_API_RESOURCE_PATH } from '../settings/users/active-users/types'
import { ROUTE_OPERATIONS, ROUTE_OPERATIONS_CONTROL_CENTRE } from '../util/routes'

const useStyles = makeStyles((theme) => ({
  shuffleButtonLeft: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '160px',
  },
  shuffleButtonRight: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '140px',
  },
}))

interface IProps {}

const AssignedBookingSummary: React.FC<IProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)

  const [openEntryStatusModal, setOpenEntryStatusModal] = useState<boolean>(false)
  const [openGateModal, setOpenGateModal] = useState<boolean>(false)

  const [isAllRequestCompleted, setIsAllRequestCompleted] = useState<boolean>(false)
  const [isForcedAccess, setIsForcedAccess] = useState<boolean | undefined>(undefined)

  const [entryStatusUpdateUrl, setEntryStatusUpdateUrl] = useState<string>('')

  const {
    entitlementDetail,
    memberData,
    departmentTeamsList,
    tenancies,
    isEntitlementDetailRequestCompleted,
    isManageUserRequestCompleted,
    isDepartmentTeamRequestCompleted,
  } = useSelector((state: RootState) => ({
    entitlementDetail: state.viewEntitlementReducer.aggregate,
    memberData: state.manageUserReducer.aggregate,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    tenancies: state.myListTenancyReducer.aggregates.values,
    isEntitlementDetailRequestCompleted: state.viewEntitlementReducer.updatedApplicationStatus,
    isManageUserRequestCompleted: state.manageUserReducer.updatedApplicationStatus,
    isDepartmentTeamRequestCompleted: state.departmentsTeamsReducer.updatedApplicationStatus,
  }))

  // Get entitlement detail
  // Get departments and teams
  // Get tenancies
  useEffect(() => {
    if (state && currentOperatorId && currentOrganisationId) {
      getEntitlementDetail()
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
    // eslint-disable-next-line
  }, [dispatch, state, currentOperatorId, currentOrganisationId])

  // Get members
  useEffect(() => {
    if (entitlementDetail && currentOperatorId && currentOrganisationId) {
      const memberId = entitlementDetail.memberId
      if (memberId) {
        dispatch(
          fetchAggregate<MemberForm>(
            AGGREGATE_MEMBER,
            TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            memberId,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, entitlementDetail])

  // Set entry status update URL
  useEffect(() => {
    if (entitlementDetail && currentOperatorId && currentOrganisationId) {
      setEntryStatusUpdateUrl(
        MEMBER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':entitlementId', entitlementDetail.id),
      )
    }
  }, [currentOperatorId, currentOrganisationId, entitlementDetail])

  // Check forced access
  useEffect(() => {
    if (entitlementDetail && tenancies) {
      if (isTenantUser) {
        const tenancy = tenancies.find((t: Tenancy) => t.id === entitlementDetail.bayAssignment.facilityId)
        if (tenancy && tenancy.forcedAccess === true) {
          setIsForcedAccess(true)
        } else {
          setIsForcedAccess(false)
        }
      } else {
        setIsForcedAccess(true)
      }
    }
  }, [entitlementDetail, tenancies, isTenantUser])

  // Manage loading
  useEffect(() => {
    if (isEntitlementDetailRequestCompleted && isManageUserRequestCompleted && isDepartmentTeamRequestCompleted) {
      setIsAllRequestCompleted(true)
    } else {
      setIsAllRequestCompleted(false)
    }
  }, [isEntitlementDetailRequestCompleted, isManageUserRequestCompleted, isDepartmentTeamRequestCompleted])

  const getEntitlementDetail = () => {
    if (state && currentOperatorId && currentOrganisationId) {
      const { entitlementId }: any = state
      dispatch(
        fetchAggregate<ViewEntitlementInterface>(
          AGGREGATE_VIEW_ENTITLEMENT,
          ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          entitlementId,
        ),
      )
    }
  }

  const getEntryStatus = (): string => {
    if (entitlementDetail) {
      let entryStatus = entitlementDetail.entryStatus
      return getEntryStatusFormattedName(entryStatus)
    } else {
      return ''
    }
  }

  // Entry Status Modal
  const handleEntryStatusModalOpen = () => {
    setOpenEntryStatusModal(true)
  }
  const handleEntryStatusModalClose = () => {
    setOpenEntryStatusModal(false)
  }

  // Gate Modal
  const handleGateModalOpen = () => {
    setOpenGateModal(true)
  }
  const handleGateModalClose = () => {
    setOpenGateModal(false)
  }

  return (
    <>
      <div className="page-content-wrapper ondemand-section">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section" style={{ paddingRight: 0 }}>
              {entitlementDetail && (
                <>
                  <Grid container spacing={0} className="responsive-title-wrapper">
                    <Grid item xs={12}>
                      <h2 className="on-demand-h2">
                        <a className="back-icon" href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE}>
                          <img className="icon-back-arrow" src={backIcon} alt="back" />
                          {entitlementDetail.member.name}
                        </a>
                      </h2>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="responsive-content-wrapper">
                    <Grid item xs={12}>
                      <p className="entry-status">Entry status: {getEntryStatus()}</p>
                      <p className="entry-status-detail">
                        The user is {getEntryStatus().toLowerCase()} of the car park.
                      </p>
                      {isForcedAccess === false && <p className="notifcation-text">These functions are not enabled.</p>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                    <Grid item xs={12} className="inline-buttons">
                      <Button
                        className={classes.shuffleButtonLeft}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isForcedAccess === false}
                        onClick={handleEntryStatusModalOpen}
                      >
                        Change entry status
                      </Button>
                      <Button
                        className={classes.shuffleButtonRight}
                        variant="outlined"
                        color="secondary"
                        size="large"
                        disabled={isForcedAccess === false}
                        onClick={handleGateModalOpen}
                      >
                        Open gate
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* On Demand Booking */}
        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              {isAllRequestCompleted ? (
                <Grid item xs={12} className="content-panel">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-10 padding-top-5">Assigned parking</h3>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="transaction-detail first-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>User</h5>
                        </Grid>
                        <Grid item xs={5}>
                          {entitlementDetail && memberData && (
                            <table id="transaction-detail-table">
                              <tbody>
                                <tr>
                                  <td>{entitlementDetail.member.name}</td>
                                </tr>
                                <tr>
                                  <td>{memberData?.email}</td>
                                </tr>
                                {memberData.departmentId && (
                                  <tr>
                                    <td>{getDepartmentName(memberData.departmentId, departmentTeamsList)}</td>
                                  </tr>
                                )}
                                {memberData.departmentId && memberData.teamId && (
                                  <tr>
                                    <td>
                                      {getTeamName(memberData.departmentId, memberData.teamId, departmentTeamsList)}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}
                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="transaction-detail last-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Assigned booking</h5>
                        </Grid>
                        <Grid item xs={7}>
                          {entitlementDetail && (
                            <table id="gate-transaction-detail-table">
                              <tbody>
                                <tr>
                                  <td>Type</td>
                                  <td>
                                    <strong>
                                      {entitlementDetail.bayAssignment.bayReservedType
                                        ? entitlementDetail.bayAssignment.bayReservedType + ' parking'
                                        : '-'}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Location</td>
                                  <td>
                                    <strong>
                                      {entitlementDetail.bayAssignment.facilityName
                                        ? entitlementDetail.bayAssignment.facilityName
                                        : '-'}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Level</td>
                                  <td>
                                    <strong>
                                      {entitlementDetail.bayAssignment.facilityAllocationName
                                        ? entitlementDetail.bayAssignment.facilityAllocationName
                                        : '-'}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Zone</td>
                                  <td>
                                    <strong>
                                      {entitlementDetail.bayAssignment.facilityZoneName
                                        ? entitlementDetail.bayAssignment.facilityZoneName
                                        : '-'}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bay</td>
                                  <td>
                                    <strong>
                                      {entitlementDetail.bayAssignment.fullBayNo
                                        ? entitlementDetail.bayAssignment.fullBayNo
                                        : '-'}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className="content-panel"
                  style={{ minHeight: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <CircularProgress color="primary" size={35} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      {entitlementDetail && (
        <ControlCentreEntryStatusModal
          display={openEntryStatusModal}
          handleClose={handleEntryStatusModalClose}
          facilityId={entitlementDetail.bayAssignment.facilityId}
          currentEntryStatus={entitlementDetail.entryStatus}
          entryStatusUpdateUrl={entryStatusUpdateUrl}
          afterUpdateEntryStatus={getEntitlementDetail}
        />
      )}
      {entitlementDetail && (
        <ControlCentreOpenGateModal
          display={openGateModal}
          handleClose={handleGateModalClose}
          facilityId={entitlementDetail.bayAssignment.facilityId}
          agreementId={entitlementDetail.id}
          afterGateSuccess={getEntitlementDetail}
        />
      )}
    </>
  )
}

export default AssignedBookingSummary
