import { IdNameDto } from '../../../store/common/departments-teams/types'

export interface DownloadReportState {
  report: string
  startDate: Date | null
  endDate: Date | null
  location: string
  departmentId: string
  teamId: string
  role: string
  format: string
}

export interface DateErrorState {
  startDate: string
  endDate: string
}

export const REPORT_FORMAT_CSV = 'CSV'
export const REPORT_FORMAT_PDF = 'PDF'

export const USER_DETAIL_REPORT = 'user_details'
export const UTILISATION_REPORT = 'utilisation'
export const ENTITLED_PARKING_REPORT = 'entitled_parking'
export const ON_DEMAND_PARKING_REPORT = 'on_demand_parking'
export const GUEST_BOOKINGS_REPORT = 'guest_bookings'
export const ENTITLED_ACTIVITY_REPORT = 'entitled_activity'
export const ENTITLED_MOVEMENTS_REPORT = 'entitled_movements'

export const reportList: IdNameDto[] = [
  {
    id: UTILISATION_REPORT,
    name: 'Utilisation',
  },
  {
    id: USER_DETAIL_REPORT,
    name: 'User Details',
  },
  {
    id: ENTITLED_PARKING_REPORT,
    name: 'Assigned Parking',
  },
  {
    id: ON_DEMAND_PARKING_REPORT,
    name: 'OnDemand Parking',
  },
  {
    id: GUEST_BOOKINGS_REPORT,
    name: 'Guest Bookings',
  },
  {
    id: ENTITLED_ACTIVITY_REPORT,
    name: 'Assigned Activity',
  },
  {
    id: ENTITLED_MOVEMENTS_REPORT,
    name: 'Assigned Movements',
  },
]

export const reportFormatList: IdNameDto[] = [
  {
    id: REPORT_FORMAT_CSV,
    name: 'CSV',
  },
  {
    id: REPORT_FORMAT_PDF,
    name: 'PDF',
  },
]

export const UTILISATION_REPORT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/utilisation-report'

export const USER_DETAIL_REPORT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/user-reports/members'

export const ENT_GUEST_DOWNLOAD_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/order-reports/entitlement-guest'

export const ENT_ASSIGNED_REPORT_DOWNLOAD_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/order-reports/entitlement-assigned'

export const ON_DEMAND_REPORT_DOWNLOAD_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/order-reports/on-demand-parking'

export const ENT_ACTIVITY_REPORT_DOWNLOAD_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/entitlement-assignment-utilisation'

export const ENT_MOVEMENT_REPORT_DOWNLOAD_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/entitlement-movements'
