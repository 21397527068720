import React from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '440px',
    maxHeight: '400px',
    textAlign: 'center',
  },
}))

interface IProps {
  display: boolean
  heading: string
  label: string
  hideModel: () => void
  confirmAction: () => void
  confirmButtonLabel: string
}

const ConfirmModel: React.FC<IProps> = (props) => {
  const { heading, label, display, hideModel, confirmAction, confirmButtonLabel } = props

  const classes = useStyles()
  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-h3 larger-text">{heading}</h3>
              <p>{label}</p>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '100px' }}>
            <Grid item xs={5}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={hideModel}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={confirmAction}
                >
                  {confirmButtonLabel}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default ConfirmModel
