import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Autocomplete, Box, Button, Container, Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { isBefore, isEqual, isValid } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import dateIcon from '../../../assets/svg/ws-b2b-icon-date.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import { AGGREGATE_GUEST } from '../../../common/aggregate.types'
import { insertAggregate, updateAggregate } from '../../../common/axios-action'
import { EntryStatus } from '../../../common/types'
import { timeSlots } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { CustomCalendarIcon } from '../../util/custom-icon'
import { ROUTE_GUEST, ROUTE_GUEST_TRANSACTION, ROUTE_GUEST_TRANSACTION_DETAIL } from '../../util/routes'
import { getAPTimeFormDate, getDateAndTimeFormat, getDateFormAPTime, getMinDateForEndDate } from '../../util/util'
import { BayAssignment, Contact, GuestEntitlement, GUEST_ENTITLEMENT_API_RESOURCE_PATH, Stay } from '../types'
import BookingDetail from './booking-detail'
import GuestBayType from './guest-bay-type'
import {
  DateAndTimeInterval,
  DateAndTimeIntervalError,
  END_DATE_IS_INVALID,
  END_DATE_IS_REQUIRED,
  END_DATE_MUST_BE_LATER_THAN_START_DATE,
  END_TIME_IS_INVALID,
  END_TIME_IS_REQUIRED,
  END_TIME_MUST_BE_LATER_THAN_START_TIME,
  START_DATE_IS_INVALID,
  START_DATE_IS_REQUIRED,
  START_DATE_MUST_BE_LATER_THAN_TODAY,
  START_TIME_IS_INVALID,
  START_TIME_IS_REQUIRED,
} from './types'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '200px',
    padding: '7px 22px',
  },
  shuffleButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '0.875rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '120px',
    paddingTop: '7px',
    paddingBottom: '7px',
    marginTop: '16px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
}))

interface IProps {
  isEdit: boolean
}

const BookGuestParking: React.FC<IProps> = ({ isEdit }: IProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [isValidBaySelection, setIsValidBaySelection] = useState<boolean>(false)
  const [isValidBookingDetailSelection, setIsValidBookingDetailSelection] = useState<boolean>(false)
  const [isValidDateAndTimeSelection, setIsValidDateAndTimeSelection] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isEntryStatusOnSite, setIsEntryStatusOnSite] = useState<boolean>(false)
  const [isParkingAvailableForOnSite, setIsParkingAvailableForOnSite] = useState<boolean>(true)
  const [guestEntitlementData, setGuestEntitlementData] = useState<GuestEntitlement | null>(null)
  const [bayAssignment, setBayAssignment] = useState<BayAssignment | null>(null)
  const [stayAgreementContact, setStayAgreementContact] = useState<Contact | null>(null)
  const [dateAndTimeInterval, setDateAndTimeInterval] = useState<DateAndTimeInterval>({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  })
  const [dateAndTimeIntervalError, setDateAndTimeIntervalError] = useState<DateAndTimeIntervalError>({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  })

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const { guestEntitlementsIdOnly, guestEntitlementsError, guestEntitlementsInserting, guestEntitlementsUpdated } =
    useSelector((state: RootState) => ({
      guestEntitlementsIdOnly: state.guestEntitlementsReducer.idOnly,
      guestEntitlementsError: state.guestEntitlementsReducer.error,
      guestEntitlementsInserting: state.guestEntitlementsReducer.inserting,
      guestEntitlementsUpdated: state.guestEntitlementsReducer.updated,
    }))

  useEffect(() => {
    if (isEdit) {
      if (state) {
        let { guestEntitlementData }: any = state
        setGuestEntitlementData(guestEntitlementData)
        if (guestEntitlementData?.entryStatus && guestEntitlementData?.entryStatus !== EntryStatus.OffSite) {
          setIsEntryStatusOnSite(true)
        }
        // Set date & time interval for edit mode
        let stay: Stay = guestEntitlementData.stay
        if (stay) {
          let interval: DateAndTimeInterval = {
            startDate: new Date(stay.startDateTime),
            startTime: new Date(stay.startDateTime),
            endDate: new Date(stay.finishDateTime),
            endTime: new Date(stay.finishDateTime),
          }
          setDateAndTimeInterval(interval)
        }
      } else {
        navigate(ROUTE_GUEST)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, state])

  useEffect(() => {
    if (guestEntitlementsIdOnly && guestEntitlementsIdOnly.id) {
      dispatch(resetAggregate<GuestEntitlement>(AGGREGATE_GUEST))
      navigate(ROUTE_GUEST + '/' + ROUTE_GUEST_TRANSACTION)
      dispatch(
        setNotification(NotificationType.INFO, [
          `Guest parking has been booked for ${
            stayAgreementContact?.firstName + ' ' + stayAgreementContact?.lastName
          }.  An invitation has been emailed to them.`,
        ]),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestEntitlementsIdOnly])
  useEffect(() => {
    if (guestEntitlementsError) {
      dispatch(setNotification(NotificationType.ERROR, [guestEntitlementsError]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestEntitlementsError])
  useEffect(() => {
    if (guestEntitlementsUpdated) {
      dispatch(resetAggregate<GuestEntitlement>(AGGREGATE_GUEST))
      navigate(ROUTE_GUEST + '/' + ROUTE_GUEST_TRANSACTION)
      if (isEntryStatusOnSite) {
        dispatch(setNotification(NotificationType.INFO, ['Departure successfully extended.']))
      } else {
        dispatch(setNotification(NotificationType.INFO, ['Your changes have been saved.']))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestEntitlementsUpdated])

  const handleChangeDateAndTime = (newValue: Date | null, name: string) => {
    let tempState: any = { ...dateAndTimeInterval }
    tempState[name] = newValue
    setDateAndTimeInterval(tempState)
    checkDateAndTimeError(tempState, name)
  }

  const checkDateAndTimeError = (interval: DateAndTimeInterval, name: string) => {
    let tempState: any = { ...dateAndTimeIntervalError }
    let today = new Date().setHours(0, 0, 0, 0)

    function checkValidationForEndTime() {
      if (interval.startDate && interval.endDate && interval.startTime && interval.endTime) {
        let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
        let endDate = new Date(interval.endDate).setHours(0, 0, 0, 0)
        let isStartDateValid = isValid(startDate)
        let isEndDateValid = isValid(endDate)
        tempState['endTime'] = ''
        if (isStartDateValid && isEndDateValid) {
          if (isEqual(startDate, endDate)) {
            let startTime = new Date(interval.startTime)
            let endTime = new Date(interval.endTime)
            let statTimeSlot = startTime.getHours() * 60 + startTime.getMinutes()
            let endTimeSlot = endTime.getHours() * 60 + endTime.getMinutes()
            if (endTimeSlot <= statTimeSlot) {
              tempState['endTime'] = END_TIME_MUST_BE_LATER_THAN_START_TIME
            }
          }
        }
      }
    }

    switch (name) {
      case 'startDate':
        if (interval.startDate) {
          let isStartDateValid = isValid(interval.startDate)
          if (!isStartDateValid) {
            tempState[name] = START_DATE_IS_INVALID
          } else {
            let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
            if (isBefore(startDate, today)) {
              tempState[name] = START_DATE_MUST_BE_LATER_THAN_TODAY
            } else {
              tempState[name] = ''
            }
            // For end date
            if (interval.endDate) {
              let isEndDateValid = isValid(interval.endDate)
              if (isEndDateValid) {
                let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
                let endDate = new Date(interval.endDate).setHours(0, 0, 0, 0)
                if (isBefore(endDate, startDate)) {
                  tempState['endDate'] = END_DATE_MUST_BE_LATER_THAN_START_DATE
                } else {
                  tempState['endDate'] = ''
                }
              }
            }
            // For end date
            // For end time
            checkValidationForEndTime()
            // For end time
          }
        } else {
          tempState[name] = START_DATE_IS_REQUIRED
        }
        break
      case 'startTime':
        if (interval.startTime) {
          let isStartTimeValid = isValid(interval.startTime)
          if (!isStartTimeValid) {
            tempState[name] = START_TIME_IS_INVALID
          } else {
            tempState[name] = ''
            // For end time
            checkValidationForEndTime()
            // For end time
          }
        } else {
          tempState[name] = START_TIME_IS_REQUIRED
        }
        break
      case 'endDate':
        if (interval.endDate) {
          let isEndDateValid = isValid(interval.endDate)
          if (!isEndDateValid) {
            tempState[name] = END_DATE_IS_INVALID
          } else {
            tempState[name] = ''
            if (interval.startDate) {
              let isStartDateValid = isValid(interval.startDate)
              if (isStartDateValid) {
                let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
                let endDate = new Date(interval.endDate).setHours(0, 0, 0, 0)
                if (isBefore(endDate, startDate)) {
                  tempState[name] = END_DATE_MUST_BE_LATER_THAN_START_DATE
                }
              }
            }
            // For end time
            checkValidationForEndTime()
            // For end time
          }
        } else {
          tempState[name] = END_DATE_IS_REQUIRED
        }
        break
      case 'endTime':
        if (interval.endTime) {
          let isEndTimeValid = isValid(interval.endTime)
          if (!isEndTimeValid) {
            tempState[name] = END_TIME_IS_INVALID
          } else {
            tempState[name] = ''
            // For end time
            checkValidationForEndTime()
            // For end time
          }
        } else {
          tempState[name] = END_TIME_IS_REQUIRED
        }
        break
    }
    setDateAndTimeIntervalError(tempState)
  }

  // Check next button validations
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let isValid: boolean = true
    Object.entries(dateAndTimeInterval).forEach((item) => {
      if (item[1] === null) {
        isValid = false
        return
      }
    })
    Object.entries(dateAndTimeIntervalError).forEach((item) => {
      if (item[1]) {
        isValid = false
        return
      }
    })
    setIsValidDateAndTimeSelection(isValid)
  })

  const nextPage = () => {
    setIsNextPage(true)
  }
  const prevPage = () => {
    if (isNextPage) {
      setIsNextPage(false)
    } else {
      dispatch(resetAggregate<GuestEntitlement>(AGGREGATE_GUEST))
      if (isEdit) {
        navigate(`${ROUTE_GUEST}/${ROUTE_GUEST_TRANSACTION_DETAIL}`, {
          state: { transactionId: guestEntitlementData?.id },
        })
      } else {
        navigate(ROUTE_GUEST)
      }
    }
  }
  const onBook = () => {
    let startDateTime: string = ''
    let finishDateTime: string = ''
    if (dateAndTimeInterval.startDate && dateAndTimeInterval.startTime) {
      startDateTime = getDateAndTimeFormat(dateAndTimeInterval.startDate, dateAndTimeInterval.startTime)
    }
    if (dateAndTimeInterval.endDate && dateAndTimeInterval.endTime) {
      finishDateTime = getDateAndTimeFormat(dateAndTimeInterval.endDate, dateAndTimeInterval.endTime)
    }

    let requestPayload: GuestEntitlement = {
      id: guestEntitlementData ? guestEntitlementData.id : null,
      reference: guestEntitlementData ? guestEntitlementData.reference : null,
      version: guestEntitlementData ? guestEntitlementData.version : null,
      bayAssignment: bayAssignment,
      timeZoneId: null,
      stay: {
        startDateTime: startDateTime,
        finishDateTime: finishDateTime,
        startInstant: guestEntitlementData ? guestEntitlementData.stay.startInstant : 0,
        finishInstant: guestEntitlementData ? guestEntitlementData.stay.finishInstant : 0,
        durationMinutes: guestEntitlementData ? guestEntitlementData.stay.durationMinutes : 0,
      },
      contact: stayAgreementContact,
    }

    if (currentOperatorId && currentOrganisationId) {
      if (isEdit) {
        dispatch(
          updateAggregate<GuestEntitlement>(
            AGGREGATE_GUEST,
            GUEST_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            requestPayload,
          ),
        )
      } else {
        dispatch(
          insertAggregate<GuestEntitlement>(
            AGGREGATE_GUEST,
            GUEST_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            requestPayload,
          ),
        )
      }
    }
  }

  return (
    <>
      <div className="page-content-wrapper guest-parking-wrapper">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <h2 className="on-demand-h2 no-padding">
                <p className="back-icon" onClick={() => prevPage()}>
                  <img className="icon-back-arrow" src={backIcon} alt="back" />
                  {isEdit ? 'Edit' : 'Book'} guest <br /> parking
                </p>
              </h2>
            </Grid>
          </Grid>
        </Container>

        <Container className="page-content-body-wrapper guest-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              <Grid item xs={12} className={isNextPage ? 'display-none' : 'content-panel larger-space'}>
                {/* When do you need to book? */}
                <Grid
                  container
                  spacing={0}
                  className="guest-booking-list-item"
                  style={isEntryStatusOnSite ? { borderBottom: 0, paddingBottom: 0, marginBottom: '5px' } : {}}
                >
                  <Grid item xs={4}>
                    <div className="assigned-icon-block">
                      <img className="guest-icon date-icon" src={dateIcon} alt="date icon" />
                      <h4 className="">
                        {isEntryStatusOnSite ? 'Select a new departure' : 'When do you need to book?'}
                      </h4>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    {/* Arrive date & time selection */}
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <p className="input-label">Arrive</p>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={dateAndTimeInterval.startDate}
                            onChange={(newValue) => handleChangeDateAndTime(newValue, 'startDate')}
                            inputFormat="dd MMMM yyyy"
                            disableMaskedInput
                            minDate={new Date()}
                            maxDate={dateAndTimeInterval.endDate ? new Date(dateAndTimeInterval.endDate) : null}
                            components={{
                              OpenPickerIcon: CustomCalendarIcon,
                            }}
                            disabled={isEntryStatusOnSite}
                            renderInput={({ ...params }) => (
                              <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                required
                                {...params}
                                error={dateAndTimeIntervalError.startDate.length > 0}
                                helperText={dateAndTimeIntervalError.startDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={3}>
                        <p className="input-label">&nbsp;</p>
                        <Autocomplete
                          id="startTime"
                          className="guest-timepicker-autocomplete"
                          clearIcon={null}
                          options={timeSlots}
                          value={dateAndTimeInterval.startTime ? getAPTimeFormDate(dateAndTimeInterval.startTime) : ''}
                          disableClearable
                          onChange={(_event: any, newValue: string | null) => {
                            handleChangeDateAndTime(newValue ? getDateFormAPTime(newValue) : null, 'startTime')
                          }}
                          disabled={isEntryStatusOnSite}
                          renderInput={({ inputProps, ...params }) => (
                            <TextField
                              variant="outlined"
                              margin="dense"
                              required
                              {...params}
                              inputProps={{
                                ...inputProps,
                                placeholder: 'hh:mm',
                              }}
                              error={dateAndTimeIntervalError.startTime.length > 0}
                              helperText={dateAndTimeIntervalError.startTime}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    {/* Depart date & time selection */}
                    <Grid container spacing={2} marginTop={0}>
                      <Grid item xs={9}>
                        <p className="input-label">Depart</p>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={dateAndTimeInterval.endDate}
                            onChange={(newValue) => handleChangeDateAndTime(newValue, 'endDate')}
                            inputFormat="dd MMMM yyyy"
                            disableMaskedInput
                            minDate={getMinDateForEndDate(dateAndTimeInterval.startDate)}
                            components={{
                              OpenPickerIcon: CustomCalendarIcon,
                            }}
                            renderInput={({ ...params }) => (
                              <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                required
                                {...params}
                                error={dateAndTimeIntervalError.endDate.length > 0}
                                helperText={dateAndTimeIntervalError.endDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={3}>
                        <p className="input-label">&nbsp;</p>
                        <Autocomplete
                          id="endTime"
                          className="guest-timepicker-autocomplete"
                          clearIcon={null}
                          options={timeSlots}
                          value={dateAndTimeInterval.endTime ? getAPTimeFormDate(dateAndTimeInterval.endTime) : ''}
                          disableClearable
                          onChange={(_event: any, newValue: string | null) => {
                            handleChangeDateAndTime(newValue ? getDateFormAPTime(newValue) : null, 'endTime')
                          }}
                          renderInput={({ inputProps, ...params }) => (
                            <TextField
                              variant="outlined"
                              margin="dense"
                              required
                              {...params}
                              inputProps={{
                                ...inputProps,
                                placeholder: 'hh:mm',
                              }}
                              error={dateAndTimeIntervalError.endTime.length > 0}
                              helperText={dateAndTimeIntervalError.endTime}
                            />
                          )}
                          filterOptions={(options, state) => {
                            let tempOptions: string[] = options
                            if (
                              dateAndTimeInterval.startDate &&
                              dateAndTimeInterval.endDate &&
                              dateAndTimeInterval.startTime
                            ) {
                              let startDate = new Date(dateAndTimeInterval.startDate).setHours(0, 0, 0, 0)
                              let endDate = new Date(dateAndTimeInterval.endDate).setHours(0, 0, 0, 0)
                              let startTime = dateAndTimeInterval.startTime
                              let isStartDateValid = isValid(startDate)
                              let isEndDateValid = isValid(endDate)
                              if (isStartDateValid && isEndDateValid && isEqual(startDate, endDate)) {
                                let validOptions: string[] = []
                                options.forEach((option) => {
                                  let endTime: Date | null = getDateFormAPTime(option)
                                  if (startTime && endTime) {
                                    let statTimeSlot = startTime.getHours() * 60 + startTime.getMinutes()
                                    let endTimeSlot = endTime.getHours() * 60 + endTime.getMinutes()
                                    if (endTimeSlot > statTimeSlot) {
                                      validOptions.push(option)
                                    }
                                  }
                                })
                                tempOptions = validOptions
                              }
                            }
                            return tempOptions.filter((option) => option.includes(state.inputValue))
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Choose a bay */}
                <Grid container spacing={0} className={!isEntryStatusOnSite ? '' : 'display-none'}>
                  <GuestBayType
                    dateAndTimeInterval={dateAndTimeInterval}
                    dateAndTimeIntervalError={dateAndTimeIntervalError}
                    isValidDateAndTimeSelection={isValidDateAndTimeSelection}
                    setDateAndTimeIntervalError={setDateAndTimeIntervalError}
                    setIsValidBaySelection={setIsValidBaySelection}
                    setBayAssignment={setBayAssignment}
                    isEdit={isEdit}
                    isEntryStatusOnSite={isEntryStatusOnSite}
                    setIsParkingAvailableForOnSite={setIsParkingAvailableForOnSite}
                    guestEntitlementData={guestEntitlementData}
                  />
                </Grid>
              </Grid>

              {/* Who are you booking for? */}
              <Grid item xs={12} className={isNextPage ? 'content-panel' : 'display-none'}>
                <BookingDetail
                  setIsValidBookingDetailSelection={setIsValidBookingDetailSelection}
                  setStayAgreementContact={setStayAgreementContact}
                  isEdit={isEdit}
                  isEntryStatusOnSite={isEntryStatusOnSite}
                  guestEntitlementData={guestEntitlementData}
                />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        className={classes.shuffleButton}
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={() => navigate(`${ROUTE_GUEST}/${ROUTE_GUEST_TRANSACTION}`)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {isEntryStatusOnSite ? (
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={
                            !isValidDateAndTimeSelection ||
                            !isValidBaySelection ||
                            !isValidBookingDetailSelection ||
                            !isParkingAvailableForOnSite ||
                            guestEntitlementsInserting
                          }
                          onClick={() => onBook()}
                        >
                          Save
                        </Button>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="flex-end">
                        {isNextPage ? (
                          <Button
                            className={classes.shuffleButton}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!isValidBookingDetailSelection || guestEntitlementsInserting}
                            onClick={() => onBook()}
                          >
                            {isEdit ? 'Save' : 'Book'}
                          </Button>
                        ) : (
                          <Button
                            className={classes.shuffleButton}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!isValidDateAndTimeSelection || !isValidBaySelection}
                            onClick={() => nextPage()}
                          >
                            Next
                          </Button>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default BookGuestParking
