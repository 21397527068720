import axios from 'axios'
import { AUTHORIZATION_HEADER, BEARER_PREFIX } from '../../../config/constants'
import { GateDeliveryStatus } from '../update-entry-status/types'
import { UnscheduledAccessesRequest, UnscheduledAccessesResponse } from './types'

export const assistedUnscheduledAccesses = async (
  unscheduledAccessesRequest: UnscheduledAccessesRequest,
  url: string,
  coreUrl: string,
  token: string,
): Promise<UnscheduledAccessesResponse> => {
  let response: UnscheduledAccessesResponse = {
    assistedAccessId: '',
    gateDeliveryStatus: null,
  }
  await axios({
    method: 'POST',
    url: coreUrl + url,
    data: unscheduledAccessesRequest,
    headers: { [AUTHORIZATION_HEADER]: BEARER_PREFIX + token },
  })
    .then((resp) => {
      let data = resp.data as UnscheduledAccessesResponse
      response = data
    })
    .catch((err) => {
      response = {
        assistedAccessId: '',
        gateDeliveryStatus: GateDeliveryStatus.InternalError,
      }
    })
  return response
}
