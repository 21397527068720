import { AggregateState } from '../../../common/types'
import { AggregateActionTypes } from '../../../common/aggregate.types'

export interface DepartmentTeamListItemDto {
  id: string
  name: string
  teams: IdNameDto[]
}

export interface IdNameDto {
  id: string
  name: string
}

export interface DepartmentTeamList {
  id: string
  name: string
  isDepartment: boolean
  departmentId: string | null
}

export type TeamState = AggregateState<DepartmentTeamListItemDto>
export type TeamActionTypes = AggregateActionTypes<DepartmentTeamListItemDto>

export const DEPARTMENTS_TEAMS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/departments-and-teams'
