import React, { useEffect } from 'react'
import { Grid, Pagination } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import usePagination from '../../../../hook/usePagination'
import { RootState } from '../../../../store'
import { IdClientRefName } from '../../../../common/types'
import { fetchAggregates, fetchIdNamesAggregates } from '../../../../common/axios-action'
import {
  AGGREGATE_APPLICATION,
  AGGREGATE_DEPARTMENT,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_TEAMS,
} from '../../../../common/aggregate.types'
import { selectCurrentOperatorId } from '../../../../config/app/reducers'
import { selectCurrentOrganisationId, selectLoginUserTimeZoneId } from '../../../auth/types'
import { DEPARTMENTS_API_RESOURCE_PATH } from '../../../../store/common/departments/types'
import { TEAMS_API_RESOURCE_PATH } from '../../../../store/common/teams/types'
import IdNameCell from '../../../../components/form-filed/IdNameCell'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../../store/common/departments-teams/types'
import { MemberApplicationForm, MemberApplicationStatus, TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH } from './types'
import { formatWithTimeZoneNoNever } from '../../../util/util'
import LoadingInDiv from '../../../../components/ui/Loading/LoadingInDiv'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
}))

interface IProps {
  displayApplicationModel: boolean
  onClickApplicationEdit: (row: MemberApplicationForm) => void
}

const PendingApprovalTab: React.FC<IProps> = (props) => {
  const { displayApplicationModel, onClickApplicationEdit } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const currentUserTimeZoneId = useSelector(selectLoginUserTimeZoneId)

  const [page, rowsPerPage, sortDirection, sortBy, handleChangePage] = usePagination()

  const { applications, loadingList, teamsIdNames, departmentIdNames } = useSelector(
    (state: RootState) => ({
      applications: state.applicationReducer.aggregates,
      loadingList: state.applicationReducer.loadingList,
      teamsIdNames: state.teamReducer.idNames,
      departmentIdNames: state.departmentReducer.idNames,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!displayApplicationModel) {
      searchParkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayApplicationModel])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      searchParkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, rowsPerPage, sortBy, sortDirection, currentOperatorId, currentOrganisationId])

  const searchParkers = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<MemberApplicationForm>(
          AGGREGATE_APPLICATION,
          TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            applicationStatus: MemberApplicationStatus.Pending,
            page: page - 1,
            size: rowsPerPage,
            sortBy: sortBy,
            sortDirection: sortDirection,
          },
        ),
      )
    }
  }

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchIdNamesAggregates<IdClientRefName>(
          AGGREGATE_DEPARTMENT,
          DEPARTMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/list',
          null,
        ),
      )

      dispatch(
        fetchIdNamesAggregates<IdClientRefName>(
          AGGREGATE_TEAMS,
          TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  return (
    <>
      <Grid item xs={12} className="content-panel no-border-top">
        <Grid container spacing={0}>
          <Grid item xs={9}>
            <h3 className="padding-top-20">
              {applications && applications.totalCount ? applications.totalCount : '0'} users
            </h3>
          </Grid>
        </Grid>

        {loadingList && <LoadingInDiv />}

        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.gridTopMargin}>
            <table id="transactions">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Team</th>
                  <th>Date Requested</th>
                </tr>
              </thead>
              <tbody>
                {applications.values.map((row) => {
                  return (
                    <tr key={row.id}>
                      <td className="transaction-id" onClick={() => onClickApplicationEdit(row)}>
                        {row.firstName} {row.lastName}
                      </td>
                      <td>{row.email}</td>
                      <td>
                        <IdNameCell idNames={departmentIdNames.values} id={row.departmentId} />
                      </td>
                      <td>
                        <IdNameCell idNames={teamsIdNames.values} id={row.teamId} />
                      </td>
                      <td>{formatWithTimeZoneNoNever(row.pendingInstant, 'd MMMM yyyy', currentUserTimeZoneId)}</td>
                    </tr>
                  )
                })}

                {applications.values.length === 0 && (
                  <tr>
                    <td colSpan={5}> No Pending Applications</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Pagination
              count={applications.totalCount ? Math.ceil(applications.totalCount / rowsPerPage) : 0}
              page={page}
              onChange={handleChangePage}
              className="pagination"
              color="primary"
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PendingApprovalTab
