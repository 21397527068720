import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface ParkingOrder {
  id: string
}

export type ParkingOrderState = AggregateState<ParkingOrder>
export type ParkingOrderActionTypes = AggregateActionTypes<ParkingOrder>
export const PARKING_ORDER_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders'
