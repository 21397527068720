import React, { useEffect, useState } from 'react'
import { IdClientRefName } from '../../common/types'
import { getTableCell } from '../../pages/util/tooltip'

interface IProps {
  id: string | null
  idNames: IdClientRefName[]
}

const IdNameCell: React.FC<IProps> = (props) => {
  const { id, idNames } = props

  const [value, setValue] = useState<string | undefined>('')

  useEffect(() => {
    if (id && idNames && idNames.length > 0) {
      setValue(idNames?.find((ele) => ele.id === id)?.name)
    } else {
      setValue('')
    }
  }, [idNames, id])

  return <span>{getTableCell(value ? value : '', 38)}</span>
}
export default IdNameCell
