import { EntryStatus } from '../../../common/types'

export interface UpdateEntryStatusRequest {
  accessPointId: string | null
  entryStatus: EntryStatus
}

export interface UpdateEntryStatusResponse {
  gateDeliveryStatus: GateDeliveryStatus | null
}

export enum GateDeliveryStatus {
  Success = 'Success',
  Failed = 'Failed',
  InternalError = 'InternalError',
}

export const MEMBER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/entitlements/:entitlementId'
export const GUEST_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/guest-entitlements/:entitlementId'
export const ORDER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/orders/:orderId/agreements/:agreementId'

export const UPDATE_ENTRY_STATUS_URL = ':updateEntryStatus'
