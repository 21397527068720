import {
  APP_CONFIG,
  ShuffleApiConfig,
  AppConfigActionTypes,
  AppConfigState,
  TENANT_CONFIG_FAILURE,
  TENANT_CONFIG_REQUEST,
  TENANT_CONFIG_SUCCESS,
  TenantConfig,
  UPDATE_APP_CONFIG,
} from './types'
import { RootState } from '../../store'

const initialState: AppConfigState = {
  configured: false,
  loading: false,
  error: null,
  tenantConfig: null,
  shuffleApiConfig: null,
}

export function appConfigReducer(state: AppConfigState = initialState, action: AppConfigActionTypes): AppConfigState {
  function buildShuffleConfig(tenantConfig: TenantConfig): ShuffleApiConfig | null {
    if (tenantConfig.config.type === APP_CONFIG) {
      return {
        shuffleApiUrl: tenantConfig.config.apiPath,
      }
    } else {
      return null
    }
  }

  function buildShuffleConfigUrl(url: string): ShuffleApiConfig | null {
    return {
      shuffleApiUrl: url,
    }
  }

  switch (action.type) {
    case TENANT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case TENANT_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantConfig: action.payload.data,
        configured: true,
        shuffleApiConfig: buildShuffleConfig(action.payload.data),
      }
    case TENANT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case UPDATE_APP_CONFIG:
      return {
        ...state,
        loading: false,
        configured: true,
        shuffleApiConfig: buildShuffleConfigUrl(action.payload.url),
      }
    default:
      return state
  }
}

export const selectShuffleApiConfig = (state: RootState) => state.appConfigReducer.shuffleApiConfig
export const selectTenantConfig = (state: RootState) => state.appConfigReducer.tenantConfig
export const selectCurrentOperatorId = (state: RootState) => state.appConfigReducer.tenantConfig?.config.operatorId
export const selectCurrentTenantId = (state: RootState) => state.appConfigReducer.tenantConfig?.tenant.id
export const selectShuffleUrl = (state: RootState) => state.appConfigReducer.shuffleApiConfig?.shuffleApiUrl
