import {
  AggregateState,
  EmployeeClass,
  Lifecycle,
  MemberDto,
  MemberPool,
  MobileDto,
  OrganisationResource,
  OrganisationType,
  SearchCriteria,
} from '../../../../common/types'
import { AggregateActionTypes } from '../../../../common/aggregate.types'

export const LIST_MANAGE_USER_SUCCESS = 'LIST_MANAGE_USER_SUCCESS'
export const GET_MANAGE_USER_REQUEST = 'GET_MANAGE_USER_REQUEST'

export interface MemberForm {
  id: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  mobile: MobileDto | null
  carParkTenantId: string | null
  departmentTeamId: string | null
  memberPool: MemberPool | null
  departmentId: string | null
  teamId: string | null
  employeeClass: EmployeeClass | null
  lastActivityInstant: number | null
  contractEndDate: string | null
  operatorId: string | null
  tenantId: string | null
  baseFacilityId: string | null
  facilityIds: string[] | null
  territoryIds: string[] | null
  lifecycle: Lifecycle | null
  vehicles: string[]
  emailVerified?: boolean
  mobileVerified?: boolean
  relatedAgreementConfirmation: RelatedAgreementConfirmation
}

export enum RelatedAgreementConfirmation {
  NONE = 'NONE',
  LEAVE = 'LEAVE',
  CANCEL = 'CANCEL',
}

export const memberInitialValue: MemberForm = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  mobile: null,
  carParkTenantId: null,
  departmentTeamId: null,
  memberPool: null,
  departmentId: null,
  teamId: null,
  employeeClass: null,
  contractEndDate: null,
  baseFacilityId: null,
  facilityIds: null,
  territoryIds: null,
  operatorId: null,
  tenantId: null,
  lifecycle: null,
  lastActivityInstant: null,
  vehicles: [],
  relatedAgreementConfirmation: RelatedAgreementConfirmation.NONE,
}

export interface CarParkTenantMemberDto extends MemberDto, OrganisationResource {
  carParkTenantId: string
  departmentTeamId: string
}

export interface MemberSearchCriteria extends SearchCriteria {
  carParkTenantId: string | null
  operatorId: string | null
  distributionChannelId: string | null
  code: string | null
  clientRef: string | null
  firstName: string | null
  email: string | null
  teamId: string | null
  departmentId: string | null
  organisationType: OrganisationType | null
  tenantId: string | null
}

export const memberSearchCriteria: MemberSearchCriteria = {
  carParkTenantId: null,
  operatorId: null,
  distributionChannelId: null,
  code: null,
  clientRef: null,
  firstName: null,
  email: null,
  teamId: null,
  organisationType: null,
  tenantId: null,
  page: null,
  size: null,
  sortBy: null,
  sortDirection: null,
  departmentId: null,
  lifecycle: null,
}

export enum TabType {
  Active_Users = 'Active_Users',
  Pending_Approval = 'Pending_Approval',
}

export type MemberState = AggregateState<MemberForm>
export type MemberActionTypes = AggregateActionTypes<MemberForm>

export const TENANT_MEMBER_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/members'
export const TENANT_MEMBER_PROFILE_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/member-profiles'
export const TENANT_MEMBER_LIST_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/member-last-activity'
