import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  AGGREGATE_DEPARTMENT,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER,
  AGGREGATE_TEAMS,
} from '../../../../common/aggregate.types'
import { fetchAggregates, fetchIdNamesAggregates } from '../../../../common/axios-action'
import { IdClientRefName } from '../../../../common/types'
import IdNameCell from '../../../../components/form-filed/IdNameCell'
import LoadingInDiv from '../../../../components/ui/Loading/LoadingInDiv'
import { selectCurrentOperatorId } from '../../../../config/app/reducers'
import { useDebounce } from '../../../../hook/useDebounce'
import usePagination from '../../../../hook/usePagination'
import { RootState } from '../../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamList,
  DepartmentTeamListItemDto,
} from '../../../../store/common/departments-teams/types'
import { DEPARTMENTS_API_RESOURCE_PATH } from '../../../../store/common/departments/types'
import { TEAMS_API_RESOURCE_PATH } from '../../../../store/common/teams/types'
import { selectCurrentOrganisationId, selectLoginUserTimeZoneId } from '../../../auth/types'
import { getTableCell } from '../../../util/tooltip'
import { formatWithTimeZone, searchParams } from '../../../util/util'
import {
  CarParkTenantMemberDto,
  MemberForm,
  memberSearchCriteria,
  MemberSearchCriteria,
  TENANT_MEMBER_LIST_API_RESOURCE_PATH,
} from './types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
}))

interface IProps {
  searchTerm: string
  onClickEdit: (row: MemberForm) => void
  displayAddUserModel: boolean
  refreshAfterBulkUpload: boolean
}

const ActiveUsersTab: React.FC<IProps> = (props) => {
  const { searchTerm, onClickEdit, displayAddUserModel, refreshAfterBulkUpload } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const currentUserTimeZoneId = useSelector(selectLoginUserTimeZoneId)

  const [searchCriteria, setSearchCriteria] = useState<MemberSearchCriteria>(memberSearchCriteria)

  const [page, rowsPerPage, sortDirection, sortBy, handleChangePage] = usePagination()
  const [departmentsAndTeams, setDepartmentsAndTeams] = useState<DepartmentTeamList[]>([])
  const [departmentTeamDisplayValue, setDepartmentTeamDisplayValue] = useState<string | null>(null)

  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 600)

  const { manageUsers, loadingList, teamsIdNames, departmentIdNames, departmentTeamsList } = useSelector(
    (state: RootState) => ({
      manageUsers: state.manageUserReducer.aggregates,
      loadingList: state.manageUserReducer.loadingList,
      teamsIdNames: state.teamReducer.idNames,
      departmentIdNames: state.departmentReducer.idNames,
      departmentTeamsList: state.departmentsTeamsReducer.aggregates,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!displayAddUserModel) {
      searchParkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAddUserModel])

  useEffect(() => {
    let departmentsAndTeams: DepartmentTeamList[] = []
    if (departmentTeamsList) {
      departmentTeamsList.values.map((row) => {
        departmentsAndTeams.push({
          id: row.id,
          name: row.name,
          isDepartment: true,
          departmentId: null,
        })
        row.teams.map((team) => {
          departmentsAndTeams.push({
            id: team.id,
            name: team.name,
            isDepartment: false,
            departmentId: row.id,
          })
          return ''
        })
        return ''
      })
    }
    setDepartmentsAndTeams(departmentsAndTeams)
  }, [departmentTeamsList])

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (page === 1) {
        searchParkers()
      } else {
        handleChangePage(null, 1)
      }
    } else {
      if (!searchTerm) {
        if (page === 1) {
          searchParkers()
        } else {
          handleChangePage(null, 1)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (page === 1) {
      searchParkers()
    } else {
      handleChangePage(null, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    searchCriteria,
    sortBy,
    sortDirection,
    currentOperatorId,
    currentOrganisationId,
    refreshAfterBulkUpload,
  ])

  const searchParkers = () => {
    if (currentOperatorId && currentOrganisationId) {
      const { firstName, lastName, name } = searchParams(searchTerm)
      if (searchTerm === '' || (searchTerm && searchTerm.trim().length > 1)) {
        dispatch(
          fetchAggregates<CarParkTenantMemberDto>(
            AGGREGATE_MEMBER,
            TENANT_MEMBER_LIST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            {
              firstName: firstName,
              lastName: lastName,
              name: name,
              departmentId: searchCriteria.departmentId ? searchCriteria.departmentId : null,
              teamId: searchCriteria.teamId ? searchCriteria.teamId : null,
              page: page - 1,
              size: rowsPerPage,
              sortBy: sortBy,
              sortDirection: sortDirection,
            },
          ),
        )
      }
    }
  }

  useEffect(() => {
    searchParkers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchIdNamesAggregates<IdClientRefName>(
          AGGREGATE_DEPARTMENT,
          DEPARTMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/list',
          null,
        ),
      )

      dispatch(
        fetchIdNamesAggregates<IdClientRefName>(
          AGGREGATE_TEAMS,
          TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  const onChangeSelect = (event: any) => {
    let { value } = event.target
    let selected: DepartmentTeamList | undefined = departmentsAndTeams.find((row) => row.id === value)
    setSearchCriteria({
      ...searchCriteria,
      teamId: selected && !selected.isDepartment ? selected.id : null,
      departmentId: selected && selected.isDepartment ? selected.id : null,
    })
    let displayValue = ''
    if (selected) {
      if (selected.departmentId) {
        let department: DepartmentTeamList | undefined = departmentsAndTeams.find(
          (row) => row.id === selected?.departmentId,
        )
        if (department) {
          displayValue = department.name + ' -> ' + selected.name
        } else {
          displayValue = selected.name
        }
      } else {
        displayValue = selected.name
      }
    }
    setDepartmentTeamDisplayValue(displayValue)
  }

  return (
    <>
      <Grid item xs={12} className="content-panel no-border-top">
        <Grid container spacing={0}>
          <Grid item xs={9}>
            <h3 className="padding-top-20">{manageUsers ? manageUsers.totalCount : 'No'} parkers</h3>
          </Grid>
          <Grid item xs={3}>
            <InputLabel className="select-heading">Department/team</InputLabel>
            <Select
              className="shuffle-select"
              IconComponent={ExpandMoreIcon}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                disableScrollLock: true,
              }}
              onChange={onChangeSelect}
              placeholder="Any Department/Team"
              defaultValue="Any Department/Team"
              variant="outlined"
              margin="dense"
              fullWidth
              renderValue={(value) => (departmentTeamDisplayValue ? departmentTeamDisplayValue : 'Any Department/Team')}
            >
              <MenuItem value="" className="selectBold">
                Any department/team
              </MenuItem>
              {departmentsAndTeams.map((row) => (
                <MenuItem key={row.id} value={row.id} className={row.isDepartment ? 'selectTitle' : 'selectBullet'}>
                  {!row.isDepartment ? '• ' : ''}
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        {loadingList && <LoadingInDiv />}

        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.gridTopMargin}>
            <table id="transactions">
              <thead>
                <tr>
                  <th>Parker</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Team</th>
                  <th>Last Active</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(manageUsers.values) &&
                  manageUsers.values.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td
                          className="transaction-id pr-20"
                          onClick={() => onClickEdit(row)}
                          style={{ maxWidth: '130px' }}
                        >
                          {getTableCell(row.firstName + ' ' + row.lastName, 20)}
                        </td>
                        <td>{row.email}</td>
                        <td className="pr-20" style={{ maxWidth: '150px', minWidth: '150px' }}>
                          <IdNameCell idNames={departmentIdNames.values} id={row.departmentId} />
                        </td>
                        <td className="pr-20" style={{ maxWidth: '150px', minWidth: '150px' }}>
                          <IdNameCell idNames={teamsIdNames.values} id={row.teamId} />
                        </td>
                        <td>{formatWithTimeZone(row.lastActivityInstant, 'd MMMM yyyy', currentUserTimeZoneId)}</td>
                      </tr>
                    )
                  })}

                {manageUsers.values.length === 0 && (
                  <tr>
                    <td colSpan={5}> No Active Parkers</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Pagination
              count={manageUsers.totalCount ? Math.ceil(manageUsers.totalCount / rowsPerPage) : 1}
              page={page}
              onChange={handleChangePage}
              className="pagination"
              color="primary"
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ActiveUsersTab
