import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import assignIcon from '../../../assets/svg/ws-b2b-icon-assign-parking-2.svg'
import tickIcon from '../../../assets/svg/ws-sml-icon-tick-wht.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import warningIcon from '../../../assets/svg/ws-ui-icon-warning-critical-red.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_TENANCIES,
  AGGREGATE_VERIFY_STAY_AGREEMENTS,
  ASSIGN_BAY_AGGREGATE_REQUEST,
  ASSIGN_PARKING_BAY_AGGREGATE_REQUEST,
} from '../../../common/aggregate.types'
import { fetchAggregates, insertAggregate, updateAggregate } from '../../../common/axios-action'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { ROUTE_ASSIGNED, ROUTE_ASSIGNED_MANAGE_PARKERS } from '../../util/routes'
import { BAY_TYPE_ACCESS, BAY_TYPE_RESERVED, BAY_TYPE_UNRESERVED } from '../types'
import { ViewEntitlementInterface } from '../view-entitlement/types'
import {
  ASSIGN_BAY_API_RESOURCE_PATH,
  AllocationsInterface,
  AssignParkingBay,
  AssignParkingBayForEdit,
  AssignParkingStayAgreements,
  Bay,
  ENTITLEMENTS_API_RESOURCE_PATH,
  ParkingDate,
  ReservedUnreservedZone,
  Slots,
  ZonesInterface,
} from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    maxWidth: '200px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  shuffleButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '0.875rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '120px',
  },
  reservedButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    backgroundColor: '#009273',
    fontSize: '0.875rem',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
    fontWeight: '900 !important',
    width: '120px',
    height: '38px',
  },
  reservedButtonSmlOL: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#525252',
    backgroundColor: '#ffffff',
    fontSize: '0.875rem',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
    borderColor: 'lightgrey',
    fontWeight: '900 !important',
    width: '120px',
    height: '38px',
  },
  dropDownFontWeight: {
    fontWeight: '500',
  },
  dropDownFontWeightLight: {
    fontWeight: '400',
  },
}))

interface IProps {
  goBack: () => void
  stayAgreements: AssignParkingStayAgreements[]
  parkingDate: ParkingDate
  isTimeSpecify: boolean
  assignedBayData: ViewEntitlementInterface | null
  pageLabel: string
  isAssignBayEdit: boolean | null
}

const AssignBayType: React.FC<IProps> = (props) => {
  const { goBack, stayAgreements, parkingDate, isTimeSpecify, assignedBayData, pageLabel, isAssignBayEdit } = props
  const classes = useStyles()
  const [bayType, setBayType] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [level, setLevel] = useState<string>('')
  const [zone, setZone] = useState<string>('')
  const [selectBay, setSelectBay] = useState<boolean>(false)
  const [levelList, setLevelList] = useState<AllocationsInterface[]>([])
  const [zoneList, setZoneList] = useState<ZonesInterface[]>([])
  const [bays, setBays] = useState<Bay[]>([])
  const [selectedBayId, setSelectedBayId] = useState<string>('')
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true)
  const [unZonedAvailableBayCount, setUnZonedAvailableBayCount] = useState<number>()
  const [selectedBay, setSelectedBay] = useState<Bay | null>(null)
  const [isNoBaysAvailableMessage, setIsNoBaysAvailableMessage] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const { tenancies, assignParkingBaySavedResponseId, inserted, inserting, error, updated, assignBayReducerError } =
    useSelector((state: RootState) => ({
      tenancies: state.myListTenancyReducer.aggregates.values,
      assignParkingBaySavedResponseId: state.assignParkingBayReducer.idOnly,
      inserted: state.assignParkingBayReducer.inserted,
      inserting: state.assignParkingBayReducer.inserting,
      error: state.assignParkingBayReducer.error,
      updated: state.assignParkingBayReducer.updated,
      assignBayReducerError: state.assignBayReducer.error,
    }))

  const { reservedUnreservedZoneDetail, assignBayReducerInserting }: any = useSelector((state: RootState) => ({
    reservedUnreservedZoneDetail: state.assignBayReducer.idOnly,
    assignBayReducerInserting: state.assignBayReducer.inserting,
  }))

  useEffect(() => {
    if (assignedBayData) {
      setBayType(assignedBayData.bayAssignment.bayReservedType)
      setLocation(assignedBayData.bayAssignment.facilityId)
      const facilityAllocationId = assignedBayData.bayAssignment.facilityAllocationId
        ? assignedBayData.bayAssignment.facilityAllocationId
        : 'unZoned'
      setLevel(facilityAllocationId)
      const facilityZoneId = assignedBayData.bayAssignment.facilityZoneId
        ? assignedBayData.bayAssignment.facilityZoneId
        : ''
      setZone(facilityZoneId)
      setSelectBay(true)
      renderLocationDropdownValue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedBayData])

  // this useEffect use for level dropdown and zone drop down value
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && location && parkingDate) {
      let tempSlots: Slots[] = []
      stayAgreements.forEach((agreement: AssignParkingStayAgreements) => {
        agreement.slots.forEach((slot: Slots) => {
          tempSlots.push(slot)
        })
      })
      const requestBody: ReservedUnreservedZone = {
        facilityId: location,
        reservationType: bayType,
        operatorId: currentOperatorId,
        carParkTenantId: currentOrganisationId,
        startDate: parkingDate.parkingStartDate ? format(parkingDate.parkingStartDate, 'yyyy-MM-dd') : null,
        finishDate: parkingDate.parkingEndDate ? format(parkingDate.parkingEndDate, 'yyyy-MM-dd') : null,
        slots: tempSlots,
        amendmentAllocationId: assignedBayData ? assignedBayData.allocationId : null,
      }
      dispatch(
        insertAggregate<ReservedUnreservedZone>(
          ASSIGN_BAY_AGGREGATE_REQUEST,
          ASSIGN_BAY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', location) + '/entitlement-bays',
          requestBody,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, location, bayType])

  useEffect(() => {
    if (reservedUnreservedZoneDetail && assignBayReducerError === null) {
      if (reservedUnreservedZoneDetail.allocations) {
        setLevelList(reservedUnreservedZoneDetail.allocations)
        const listOfZone: AllocationsInterface = reservedUnreservedZoneDetail.allocations.find(
          (e: AllocationsInterface) => e.allocationId === level,
        )
        if (listOfZone?.zones) {
          setZoneList(listOfZone?.zones)
        } else {
          setZoneList([])
        }
      } else {
        setLevelList([])
      }
      if (reservedUnreservedZoneDetail.unZonedAvailableBayCount) {
        setUnZonedAvailableBayCount(reservedUnreservedZoneDetail.unZonedAvailableBayCount)
      } else {
        setUnZonedAvailableBayCount(0)
      }
    } else {
      if (assignBayReducerError) {
        dispatch(setNotification(NotificationType.ERROR, ['No bays are available.']))
      }
    }
  }, [reservedUnreservedZoneDetail, level, assignBayReducerError, dispatch])

  useEffect(() => {
    if (location && !isNoBaysAvailableMessage) {
      if (level) {
        if (level === 'unZoned') {
          if (bayType === BAY_TYPE_RESERVED && selectedBay) {
            setIsNextButtonDisabled(false)
          } else {
            setIsNextButtonDisabled(true)
          }
          if (bayType === BAY_TYPE_UNRESERVED || bayType === BAY_TYPE_ACCESS) {
            setIsNextButtonDisabled(false)
          }
        } else {
          if (zone) {
            if (bayType === BAY_TYPE_RESERVED && selectedBay) {
              setIsNextButtonDisabled(false)
            } else {
              setIsNextButtonDisabled(true)
            }
            if (bayType === BAY_TYPE_UNRESERVED || bayType === BAY_TYPE_ACCESS) {
              setIsNextButtonDisabled(false)
            }
          } else {
            setIsNextButtonDisabled(true)
          }
        }
      } else {
        setIsNextButtonDisabled(true)
      }
    } else {
      setIsNextButtonDisabled(true)
    }
    // eslint-disable-next-line
  }, [selectedBayId, bayType, location, level, zone, selectedBay])

  useEffect(() => {
    if (inserted || updated) {
      const toastMessage = inserted ? 'Parking has been assigned' : 'Your changes have been saved.'
      resetReducer()
      navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS}`, {
        state: { parkingAssignMessage: toastMessage },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignParkingBaySavedResponseId, inserted, navigate, updated])

  useEffect(() => {
    if (assignedBayData && zoneList && bayType === BAY_TYPE_RESERVED && level !== 'unZoned') {
      const zoneObj: ZonesInterface | undefined = zoneList.find((row) => row.zoneId === zone)
      if (zoneObj) {
        const bayObj: Bay | undefined = zoneObj.bays.find((bay: Bay) => bay.id === assignedBayData.bayAssignment.bayId)
        if (bayObj) {
          if (bayObj.available) {
            setSelectedBay(bayObj)
            setSelectedBayId(bayObj.id)
          } else {
            setSelectedBay(null)
            setSelectedBayId('')
          }
        }
        setBays(zoneObj.bays)
      }
    } else if (assignedBayData && bayType === BAY_TYPE_RESERVED && level === 'unZoned') {
      if (reservedUnreservedZoneDetail.unZonedBays) {
        const unZonedBays: Bay[] = reservedUnreservedZoneDetail.unZonedBays
        const bayObj: Bay | undefined = unZonedBays.find((bay: Bay) => bay.id === assignedBayData.bayAssignment.bayId)
        if (bayObj) {
          if (bayObj.available) {
            setSelectedBay(bayObj)
            setSelectedBayId(bayObj.id)
          } else {
            setSelectedBay(null)
            setSelectedBayId('')
          }
        }
        setBays(unZonedBays)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedBayData, reservedUnreservedZoneDetail, zoneList])

  useEffect(() => {
    if (error) {
      dispatch(setNotification(NotificationType.ERROR, [error]))
      dispatch(resetAggregate<AssignParkingBay>(ASSIGN_PARKING_BAY_AGGREGATE_REQUEST))
    }
  }, [error, dispatch])

  // Check error message conditions
  useEffect(() => {
    if (levelList) {
      if (levelList.length > 0) {
        let unZonedAvailableBayCount: number = reservedUnreservedZoneDetail.unZonedAvailableBayCount
        let unavailableBayList: AllocationsInterface[] = levelList.filter(
          (level: AllocationsInterface) => level.availableBayCount === 0,
        )
        if (unavailableBayList.length === levelList.length && unZonedAvailableBayCount === 0) {
          if (bayType === BAY_TYPE_ACCESS) {
            setIsNoBaysAvailableMessage(false)
          } else {
            setIsNoBaysAvailableMessage(true)
          }
        } else {
          setIsNoBaysAvailableMessage(false)
        }
      } else {
        setIsNoBaysAvailableMessage(false)
      }
    } else {
      setIsNoBaysAvailableMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelList])

  const changeBayType = (type: string) => {
    if (!isAssignBayEdit) {
      setSelectBay(true)
      setBayType(type)
      reset()
      renderLocationDropdownValue()
    }
  }

  const renderLocationDropdownValue = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }

  const changeLocation = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string)
    setLevel('')
    setZone('')
  }

  const changeLevel = (event: SelectChangeEvent) => {
    setSelectedBayId('')
    setSelectedBay(null)
    setZone('')
    setLevel(event.target.value as string)
    setIsNoBaysAvailableMessage(false)
    if ((event.target.value as string) === 'unZoned' && bayType === BAY_TYPE_RESERVED) {
      if (reservedUnreservedZoneDetail.unZonedBays) {
        const unZonedBays: Bay[] = reservedUnreservedZoneDetail.unZonedBays
        setBays(unZonedBays)
      } else {
        setBays([])
      }
    }
  }

  const changeZone = (event: SelectChangeEvent) => {
    setZone(event.target.value as string)
    const zone: ZonesInterface | undefined = zoneList.find((zone) => zone.zoneId === event.target.value)
    if (zone) {
      setBays(zone.bays)
      if (zone.availableBayCount > 0) {
        setIsNoBaysAvailableMessage(false)
      } else {
        if (bayType === BAY_TYPE_ACCESS) {
          setIsNoBaysAvailableMessage(false)
        } else {
          setIsNoBaysAvailableMessage(true)
        }
      }
    } else {
      setBays([])
    }
  }

  const handleGoBack = () => {
    reset()
    goBack()
  }

  const reset = () => {
    setLocation('')
    setLevel('')
    setLevelList([])
    setZone('')
    setZoneList([])
    setSelectedBayId('')
    setIsNextButtonDisabled(true)
    setSelectedBay(null)
    dispatch(resetAggregate<AssignParkingBay>(ASSIGN_BAY_AGGREGATE_REQUEST))
  }

  const baySelect = (bay: Bay) => {
    setSelectedBayId(bay.id)
    setSelectedBay(bay)
  }

  const saveOrUpdateBay = () => {
    if (!assignedBayData) {
      if (currentOperatorId && currentOrganisationId) {
        const apiPayLoad: AssignParkingBay = {
          id: null,
          reference: null,
          version: null,
          timeZoneId: null,
          specifyTime: isTimeSpecify,
          startDate: parkingDate.parkingStartDate ? format(parkingDate.parkingStartDate, 'yyyy-MM-dd') : null,
          finishDate: parkingDate.parkingEndDate ? format(parkingDate.parkingEndDate, 'yyyy-MM-dd') : null,
          bayAssignment: {
            bayReservedType: bayType,
            facilityId: location,
            facilityAllocationId: level === 'unZoned' ? null : level,
            facilityZoneId: level === 'unZoned' ? null : zone ? zone : null,
            bayId: selectedBay ? selectedBay?.id : null,
            fullBayNo: selectedBay ? selectedBay?.fullBayNumber : null,
            entitlementBayId: null,
            entitlementBayVersion: null,
          },
          stayAgreements: stayAgreements,
        }
        dispatch(
          insertAggregate<AssignParkingBay>(
            ASSIGN_PARKING_BAY_AGGREGATE_REQUEST,
            ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            apiPayLoad,
          ),
        )
      }
    } else {
      if (currentOperatorId && currentOrganisationId) {
        const apiPayLoadForEdit: AssignParkingBayForEdit = {
          id: assignedBayData ? assignedBayData?.id : null,
          reference: assignedBayData ? assignedBayData?.reference : null,
          version: assignedBayData ? assignedBayData?.version : null,
          timeZoneId: null,
          specifyTime: isTimeSpecify,
          startDate: parkingDate.parkingStartDate ? format(parkingDate.parkingStartDate, 'yyyy-MM-dd') : null,
          finishDate: parkingDate.parkingEndDate ? format(parkingDate.parkingEndDate, 'yyyy-MM-dd') : null,
          bayAssignment: {
            bayReservedType: bayType,
            facilityId: location,
            facilityAllocationId: level === 'unZoned' ? null : level,
            facilityZoneId: level === 'unZoned' ? null : zone ? zone : null,
            bayId: selectedBay ? selectedBay?.id : null,
            fullBayNo: selectedBay ? selectedBay?.fullBayNumber : null,
            entitlementBayId: assignedBayData ? assignedBayData?.bayAssignment?.entitlementBayId : null,
            entitlementBayVersion: assignedBayData ? assignedBayData?.bayAssignment?.entitlementBayVersion : null,
          },
          memberId: assignedBayData.memberId,
          stay: stayAgreements[0],
        }
        dispatch(
          updateAggregate<AssignParkingBayForEdit>(
            ASSIGN_PARKING_BAY_AGGREGATE_REQUEST,
            ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            apiPayLoadForEdit,
          ),
        )
      }
    }
  }

  const checkLevelHasZone = (level: AllocationsInterface): boolean => {
    return level.zones.length === 0
  }

  const resetReducer = () => {
    dispatch(resetAggregate<AssignParkingBay>(ASSIGN_BAY_AGGREGATE_REQUEST))
    dispatch(resetAggregate<AssignParkingBay>(AGGREGATE_TENANCIES))
    dispatch(resetAggregate<AssignParkingBay>(AGGREGATE_VERIFY_STAY_AGREEMENTS))
  }

  return (
    <div className="page-content-wrapper assigned-bay-wrapper">
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2 no-padding">
                  <span className="back-icon cursor-pointer" onClick={handleGoBack}>
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    {pageLabel}
                  </span>
                </h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="page-content-body-wrapper assigned-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            <Grid item xs={12} className="content-panel">
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <div className="assigned-icon-block">
                    <img className="assigned-icon reserved-icon" src={assignIcon} alt="img" />
                    <h4 className="">Choose a bay</h4>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={10} style={{ paddingBottom: '40px' }} className="bay-type-button">
                      <p className="input-label">Choose a bay type</p>
                      <Button
                        className={
                          bayType === BAY_TYPE_RESERVED
                            ? isAssignBayEdit
                              ? `${classes.reservedButtonSml} cursor-pointer-none`
                              : `${classes.reservedButtonSml}`
                            : classes.reservedButtonSmlOL
                        }
                        variant={bayType === BAY_TYPE_RESERVED ? 'contained' : 'outlined'}
                        startIcon={
                          bayType === BAY_TYPE_RESERVED ? <img className="tickIcon" src={tickIcon} alt="img" /> : null
                        }
                        size="small"
                        onClick={() => changeBayType(BAY_TYPE_RESERVED)}
                        style={{ paddingLeft: '15.93px', paddingRight: '15.93px' }}
                        disabled={
                          isAssignBayEdit ? (isAssignBayEdit && bayType !== BAY_TYPE_RESERVED ? true : false) : false
                        }
                      >
                        Reserved
                      </Button>
                      <Button
                        className={
                          bayType === BAY_TYPE_UNRESERVED
                            ? isAssignBayEdit
                              ? `${classes.reservedButtonSml} cursor-pointer-none`
                              : `${classes.reservedButtonSml}`
                            : classes.reservedButtonSmlOL
                        }
                        variant={bayType === BAY_TYPE_UNRESERVED ? 'contained' : 'outlined'}
                        startIcon={
                          bayType === BAY_TYPE_UNRESERVED ? <img className="tickIcon" src={tickIcon} alt="img" /> : null
                        }
                        size="small"
                        onClick={() => changeBayType(BAY_TYPE_UNRESERVED)}
                        disabled={
                          isAssignBayEdit ? (isAssignBayEdit && bayType !== BAY_TYPE_UNRESERVED ? true : false) : false
                        }
                      >
                        Unreserved
                      </Button>
                      <Button
                        className={
                          bayType === BAY_TYPE_ACCESS
                            ? isAssignBayEdit
                              ? `${classes.reservedButtonSml} cursor-pointer-none`
                              : `${classes.reservedButtonSml}`
                            : classes.reservedButtonSmlOL
                        }
                        variant={bayType === BAY_TYPE_ACCESS ? 'contained' : 'outlined'}
                        startIcon={
                          bayType === BAY_TYPE_ACCESS ? <img className="tickIcon" src={tickIcon} alt="img" /> : null
                        }
                        size="small"
                        onClick={() => changeBayType(BAY_TYPE_ACCESS)}
                        disabled={
                          isAssignBayEdit ? (isAssignBayEdit && bayType !== BAY_TYPE_ACCESS ? true : false) : false
                        }
                      >
                        Access
                      </Button>
                    </Grid>
                  </Grid>
                  {selectBay && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel className="select-heading">Location</InputLabel>
                          <Select
                            className="shuffle-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={location}
                            onChange={changeLocation}
                            style={{ fontWeight: '500' }}
                            disabled={isAssignBayEdit ? true : false}
                          >
                            {tenancies.map((data: Tenancy) => (
                              <MenuItem key={data.id} value={data.id} className={classes.dropDownFontWeight}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel className="select-heading">Level</InputLabel>
                          <Select
                            className="shuffle-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={level}
                            onChange={changeLevel}
                            style={{ fontWeight: '500' }}
                          >
                            {(!location || location.length === 0) && (
                              <MenuItem value="" className={classes.dropDownFontWeight}>
                                no level available
                              </MenuItem>
                            )}
                            {location && unZonedAvailableBayCount !== 0 && (
                              <MenuItem
                                value="unZoned"
                                className={unZonedAvailableBayCount !== 0 ? classes.dropDownFontWeight : ''}
                                disabled={bayType !== BAY_TYPE_ACCESS && unZonedAvailableBayCount === 0}
                              >
                                Unzoned - {unZonedAvailableBayCount} bays available
                              </MenuItem>
                            )}
                            {levelList &&
                              levelList.map(
                                (level: AllocationsInterface) =>
                                  level.zones.length !== 0 && (
                                    <MenuItem
                                      key={level.allocationId}
                                      value={level.allocationId}
                                      disabled={
                                        bayType === BAY_TYPE_ACCESS
                                          ? checkLevelHasZone(level)
                                          : level.availableBayCount === 0
                                      }
                                      className={classes.dropDownFontWeight}
                                    >
                                      {bayType !== BAY_TYPE_ACCESS && level.availableBayCount === 0 ? (
                                        <span className={classes.dropDownFontWeightLight}>
                                          {level.allocationName} full
                                        </span>
                                      ) : (
                                        <span>
                                          {level.allocationName} - {level.availableBayCount} bays available
                                        </span>
                                      )}
                                    </MenuItem>
                                  ),
                              )}
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel className="select-heading">Zone</InputLabel>
                          <Select
                            className="shuffle-select assign-bay-type-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={level === 'unZoned'}
                            value={zone}
                            onChange={changeZone}
                            style={{ fontWeight: '500' }}
                          >
                            {(!zoneList || zoneList.length === 0) && (
                              <MenuItem value="" className={classes.dropDownFontWeight}>
                                no zone available
                              </MenuItem>
                            )}
                            {zoneList &&
                              zoneList.map((data: ZonesInterface) => (
                                <MenuItem
                                  key={data.zoneId}
                                  value={data.zoneId}
                                  className={classes.dropDownFontWeight}
                                  disabled={bayType !== BAY_TYPE_ACCESS && data.availableBayCount === 0}
                                >
                                  {data.zoneName}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      </Grid>
                      {isNoBaysAvailableMessage && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ paddingTop: '55px', paddingBottom: '12px' }}>
                            <Box className="unavailable-bay-alert">
                              <img className="bay-warning-icon" src={warningIcon} alt="Warning Icon" />
                              <p className="bay-warning-text">
                                <strong>There are no bays available at the date and time selected.</strong>
                              </p>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {(zone || level === 'unZoned') && bayType !== BAY_TYPE_UNRESERVED && bayType !== BAY_TYPE_ACCESS && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ paddingTop: '40px' }}>
                            <p className="select-bay-header">Select the reserved bay</p>
                            {assignBayReducerInserting ? (
                              <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Grid item xs={12}>
                                  <CircularProgress color="primary" size={30} />
                                </Grid>
                              </Grid>
                            ) : bays.length === 0 ? (
                              <p style={{ textAlign: 'center', margin: '1rem' }}>no bays available</p>
                            ) : (
                              <div className="custom-bay-usage-wrapper">
                                <div className="bay-container">
                                  {bays.map((bay: Bay) => (
                                    <div
                                      className={
                                        selectedBayId === bay.id && bay.available
                                          ? 'selected-bay'
                                          : !bay.available
                                          ? 'unavailable-bay'
                                          : 'available-bay'
                                      }
                                      key={bay.id}
                                      onClick={() => baySelect(bay)}
                                    >
                                      {bay.fullBayNumber}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            <div className="bay-usage-key-wrapper">
                              <p className="bay-usage-key">
                                <span className="key-icon selected-bay-icon"></span>Selected
                                <span className="key-icon available-bay-icon" style={{ marginLeft: '30px' }}></span>
                                Available
                                <span className="key-icon unavailable-bay-icon" style={{ marginLeft: '30px' }}></span>
                                Unavailable
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-start">
                    <Button
                      className={classes.shuffleButton}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => {
                        resetReducer()
                        navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS}`)
                        dispatch(resetAggregate<AssignParkingBay>(ASSIGN_PARKING_BAY_AGGREGATE_REQUEST))
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      className={classes.shuffleButton}
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={isNextButtonDisabled}
                      onClick={saveOrUpdateBay}
                    >
                      {!inserting && 'Save'}
                      {inserting && (
                        <CircularProgress color="primary" style={{ color: '#FFFFFF', width: 30, height: 30 }} />
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default AssignBayType
