import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface MemberBulkUploadSummary {
  id: string
  fileName: string
  submittedLocalDateTime: string
  submittedBy: string
  completedDateTime: string
  fileStatus: string
  rowCount: number
  exceptionCount: number
  successCount: number
  organisationType: string
  organisationId: string
  operatorId: string
  version: number
}

export type MemberBulkUploadSummaryState = AggregateState<MemberBulkUploadSummary>
export type MemberBulkUploadSummaryActionTypes = AggregateActionTypes<MemberBulkUploadSummary>

export const MEMBER_BULK_UPLOAD_SUMMARY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/member-bulk-uploads'

export const GET_EXCEPTIONS_RECORDS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/member-bulk-uploads/:memberBulkUploadId/exceptions'
