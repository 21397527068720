import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, CircularProgress, Container, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { format } from 'date-fns'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import firebase from 'firebase/compat/app'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { resetAggregate } from '../../../../../common/aggregate-actions'
import {
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER,
  AGGREGATE_MEMBER_ENTITLEMENT,
  AGGREGATE_MY_LIST_TENANCIES,
} from '../../../../../common/aggregate.types'
import {
  customMethodAggregateRequestAction,
  fetchAggregate,
  fetchAggregates,
  updateAggregate,
} from '../../../../../common/axios-action'
import {
  DisableCheck,
  EmployeeClass,
  ErrorResponse,
  UpdateDeleteMode,
  memberPoolsIdNames,
} from '../../../../../common/types'
import { DatePicker } from '../../../../../components/final-form/DatePicker'
import { DepartmentTeamSelect } from '../../../../../components/final-form/DepartmentTeamSelect'
import { RadioButtons } from '../../../../../components/final-form/Radios'
import { Select } from '../../../../../components/final-form/Select'
import { TextField } from '../../../../../components/final-form/TextField'
import {
  selectCurrentOperatorId,
  selectCurrentTenantId,
  selectShuffleApiConfig,
} from '../../../../../config/app/reducers'
import { RootState } from '../../../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamList,
  DepartmentTeamListItemDto,
} from '../../../../../store/common/departments-teams/types'
import { checkExistsAgreements } from '../../../../../store/common/member-assignment/check-exists'
import {
  CHECK_EXISTS_ASSIGNMENT_API_RESOURCE_PATH,
  CHECK_EXISTS_ASSIGNMENT_PAST_CONTRACT_API_RESOURCE_PATH,
  ExistsAssignments,
} from '../../../../../store/common/member-assignment/types'
import { clearNotification, setNotification } from '../../../../../store/common/notifications/action'
import { NotificationType } from '../../../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../../../auth/types'
import { Facility, TerritoryAndFacility } from '../../locations/types'
import UserTerritoryAndFacility from '../../locations/user-territory-and-facility'
import AssignedParking from '../active-entitlements/assigned-parking'
import { MemberEntitlement, TENANT_MEMBER_ENTITLEMENT_API_RESOURCE_PATH } from '../active-entitlements/types'
import ParkingItems from '../recent-park-items/parking-items'
import { MemberForm, RelatedAgreementConfirmation, TENANT_MEMBER_API_RESOURCE_PATH } from '../types'
import VehicleDetails from './vehicle-details'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '200px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },

  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
  pTagMaxWidth: {
    maxWidth: '420px',
  },
}))

interface IProps {
  memberId: string
  memberName: string
  hideShowUserDetails: () => void
  setDisplayAgreementModel: Dispatch<SetStateAction<boolean>>
  setActiveAgreementMemberModal: Dispatch<SetStateAction<boolean>>
  selectedAgreementConfirmation: RelatedAgreementConfirmation
  setUpdatedMemberForm: Dispatch<SetStateAction<MemberForm | undefined>>
  existsAssignment: ExistsAssignments | null
  setExistsAssignment: Dispatch<SetStateAction<ExistsAssignments | null>>
  activeAssignment: ExistsAssignments | null
  setActiveAssignment: Dispatch<SetStateAction<ExistsAssignments | null>>
  setUpdateDeleteMode: Dispatch<SetStateAction<UpdateDeleteMode>>
}

const UserDetails: React.FC<IProps> = (props) => {
  const {
    memberId,
    memberName,
    hideShowUserDetails,
    setDisplayAgreementModel,
    setActiveAgreementMemberModal,
    selectedAgreementConfirmation,
    setUpdatedMemberForm,
    setExistsAssignment,
    setActiveAssignment,
    setUpdateDeleteMode,
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const currentTenantId = useSelector(selectCurrentTenantId)
  const shuffleApiConfig = useSelector(selectShuffleApiConfig)

  const [initialValue, setInitialValue] = useState<MemberForm>()
  const [departmentsAndTeams, setDepartmentsAndTeams] = useState<DepartmentTeamList[]>([])

  const [disableCheck, setDisableCheck] = useState<DisableCheck>({
    index: null,
    allowEdit: true,
  })

  const [isMobileNumberChange, setIsMobileNumberChange] = useState<boolean>(false)
  const [isOtherFieldsUpdated, setIsOtherFieldsUpdated] = useState<boolean>(false)
  const [isResetPasswordTrigger, setIsResetPasswordTrigger] = useState<boolean>(false)
  const [locationSelection, setLocationSelection] = useState<TerritoryAndFacility[]>([])
  const [isLocationChange, setIsLocationChange] = useState<boolean>(false)
  const [token, setToken] = useState('')

  const {
    tenancies,
    updating,
    updated,
    member,
    error,
    loadingOne,
    departmentTeamsList,
    manageUserReducerCustomRequestInserted,
    verificationInProcess,
  } = useSelector(
    (state: RootState) => ({
      tenancies: state.myListTenancyReducer.aggregates,
      error: state.manageUserReducer.error,
      updating: state.manageUserReducer.updating,
      updated: state.manageUserReducer.updated,
      member: state.manageUserReducer.aggregate,
      loadingOne: state.manageUserReducer.loadingOne,
      departmentTeamsList: state.departmentsTeamsReducer.aggregates,
      manageUserReducerCustomRequestInserted: state.manageUserReducer.customRequestInserted,
      verificationInProcess: state.manageUserReducer.verificationInProcess,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!token) {
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const token = await firebase.auth().currentUser!.getIdToken()
    setToken(token)
  }

  useEffect(() => {
    if (manageUserReducerCustomRequestInserted && !error) {
      if (isResetPasswordTrigger) {
        dispatch(
          setNotification(NotificationType.INFO, [
            `A reset password link has been sent to ${initialValue?.firstName} ${initialValue?.lastName}.`,
          ]),
        )
      } else {
        dispatch(
          setNotification(NotificationType.INFO, [
            `A verification link has been emailed to ${initialValue?.firstName} ${initialValue?.lastName}.`,
          ]),
        )
      }
    } else if (error) {
      if (isResetPasswordTrigger) {
        dispatch(
          setNotification(NotificationType.ERROR, [
            'Resend reset password has failed. Please retry. If issue continues contact your Administrator.',
          ]),
        )
      } else {
        dispatch(
          setNotification(NotificationType.ERROR, [
            'Verification process has failed. Please retry. If issue continues contact your Administrator.',
          ]),
        )
      }
    } else {
      dispatch(clearNotification())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageUserReducerCustomRequestInserted, error, dispatch])

  useEffect(() => {
    let departmentsAndTeams: DepartmentTeamList[] = []
    if (departmentTeamsList) {
      departmentTeamsList.values.map((row) => {
        departmentsAndTeams.push({
          id: row.id,
          name: row.name,
          isDepartment: true,
          departmentId: null,
        })
        row.teams.map((team) => {
          departmentsAndTeams.push({
            id: team.id,
            name: team.name,
            isDepartment: false,
            departmentId: row.id,
          })
          return ''
        })
        return ''
      })
    }
    setDepartmentsAndTeams(departmentsAndTeams)
  }, [departmentTeamsList])

  useEffect(() => {
    if (member) {
      if (member.teamId) {
        member.departmentTeamId = member.teamId
      } else if (member.departmentId) {
        member.departmentTeamId = member.departmentId
      }
      setInitialValue(member)
    }
  }, [member])

  useEffect(() => {
    if (error) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(error))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError.message ? firstError.message.substring(0, 50) : firstError.code.substring(0, 50),
          ]),
        )
      }
    }
  }, [error, dispatch])

  useEffect(() => {
    if (memberId !== null && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<MemberForm>(
          AGGREGATE_MEMBER,
          TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          memberId,
        ),
      )
    } else {
      dispatch(resetAggregate<MemberForm>(AGGREGATE_MEMBER))
    }
  }, [dispatch, memberId, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (updated && currentOperatorId && currentOrganisationId && memberId) {
      if (member?.emailVerified) {
        if (isMobileNumberChange && isOtherFieldsUpdated) {
          dispatch(
            setNotification(NotificationType.INFO, [
              `Your changes have been saved. A verification link has been emailed to ${initialValue?.firstName} ${initialValue?.lastName}.`,
            ]),
          )
        } else if (isMobileNumberChange) {
          dispatch(
            setNotification(NotificationType.INFO, [
              `A verification link has been emailed to ${initialValue?.firstName} ${initialValue?.lastName}.`,
            ]),
          )
        } else {
          dispatch(setNotification(NotificationType.INFO, ['Your changes have been saved.']))
        }
      } else {
        dispatch(setNotification(NotificationType.INFO, ['Your changes have been saved.']))
      }
      dispatch(
        fetchAggregate<MemberForm>(
          AGGREGATE_MEMBER,
          TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          memberId,
        ),
      )
      dispatch(
        fetchAggregates<MemberEntitlement>(
          AGGREGATE_MEMBER_ENTITLEMENT,
          TENANT_MEMBER_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':memberId', memberId),
          null,
        ),
      )
      setDisableCheck({
        index: null,
        allowEdit: false,
      })
    } else {
      dispatch(clearNotification())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updated, memberId, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  const onSubmit = async (values: MemberForm) => {
    if (currentOperatorId && currentOrganisationId && currentTenantId) {
      const errors: ValidationErrors = {}

      if (validateBeforeSubmit(values, errors)) {
        return errors
      } else {
        if (values.mobile?.number !== initialValue?.mobile?.number) {
          setIsMobileNumberChange(true)
        } else {
          setIsMobileNumberChange(false)
        }
        const othersFiledUpdated = checkOtherFormFieldsUpdated(values)
        if (othersFiledUpdated) {
          setIsOtherFieldsUpdated(true)
        } else {
          setIsOtherFieldsUpdated(false)
        }

        let selected: DepartmentTeamList | undefined = departmentsAndTeams.find(
          (row) => row.id === values.departmentTeamId,
        )
        if (selected && selected.isDepartment) {
          values.departmentId = values.departmentTeamId
          values.teamId = null
        } else {
          values.teamId = values.departmentTeamId
          values.departmentId = selected ? selected.departmentId : null
        }

        if (values.employeeClass === EmployeeClass.Contract && values.contractEndDate) {
          values.contractEndDate = format(Date.parse(values.contractEndDate), 'yyyy-MM-dd')
        } else {
          values.contractEndDate = null
        }

        if (locationSelection.length > 0) {
          let territoryIds: string[] = []
          let facilityIds: string[] = []
          locationSelection.forEach((territory: TerritoryAndFacility) => {
            if (territory.checked) {
              territoryIds.push(territory.id)
            } else {
              territory.facilities.forEach((facility: Facility) => {
                if (facility.checked) {
                  facilityIds.push(facility.id)
                }
              })
            }
          })
          values.territoryIds = territoryIds
          values.facilityIds = facilityIds
        } else {
          values.territoryIds = initialValue ? initialValue.territoryIds : []
          values.facilityIds = initialValue ? initialValue.facilityIds : []
        }

        values.relatedAgreementConfirmation = selectedAgreementConfirmation

        if (values.id) {
          if (
            values.employeeClass === EmployeeClass.Contract &&
            selectedAgreementConfirmation === RelatedAgreementConfirmation.NONE
          ) {
            if (shuffleApiConfig?.shuffleApiUrl && token && currentOperatorId && currentOrganisationId) {
              let existsAssignments = await checkExistsAgreements(
                values,
                CHECK_EXISTS_ASSIGNMENT_PAST_CONTRACT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
                  .replace(':carParkTenantId', currentOrganisationId)
                  .replace(':id', values.id),
                shuffleApiConfig?.shuffleApiUrl,
                token,
              )

              if (existsAssignments.exists) {
                setUpdateDeleteMode(UpdateDeleteMode.Update)
                setExistsAssignment(existsAssignments)
                setDisplayAgreementModel(true)
              } else {
                setDisplayAgreementModel(false)
                let activeAgreements = await checkExistsAgreements(
                  values,
                  CHECK_EXISTS_ASSIGNMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
                    .replace(':carParkTenantId', currentOrganisationId)
                    .replace(':id', values.id),
                  shuffleApiConfig?.shuffleApiUrl,
                  token,
                )
                if (activeAgreements.exists) {
                  setUpdateDeleteMode(UpdateDeleteMode.Update)
                  setActiveAssignment(activeAgreements)
                  setActiveAgreementMemberModal(true)
                } else {
                  setActiveAgreementMemberModal(false)
                  dispatch(
                    updateAggregate<MemberForm>(
                      AGGREGATE_MEMBER,
                      TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                        ':carParkTenantId',
                        currentOrganisationId,
                      ),
                      values,
                    ),
                  )
                }
              }
            }
          } else {
            if (shuffleApiConfig?.shuffleApiUrl && token && currentOperatorId && currentOrganisationId) {
              let activeAgreements = await checkExistsAgreements(
                values,
                CHECK_EXISTS_ASSIGNMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
                  .replace(':carParkTenantId', currentOrganisationId)
                  .replace(':id', values.id),
                shuffleApiConfig?.shuffleApiUrl,
                token,
              )
              if (activeAgreements.exists) {
                setUpdateDeleteMode(UpdateDeleteMode.Update)
                setActiveAssignment(activeAgreements)
                setActiveAgreementMemberModal(true)
              } else {
                setActiveAgreementMemberModal(false)
                dispatch(
                  updateAggregate<MemberForm>(
                    AGGREGATE_MEMBER,
                    TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                      ':carParkTenantId',
                      currentOrganisationId,
                    ),
                    values,
                  ),
                )
              }
            }
          }
        }
        setUpdatedMemberForm(values)
      }
    }
  }

  const validateBeforeSubmit = (values: MemberForm, errors: ValidationErrors) => {
    let isError: boolean = false
    if (errors) {
      if (!values.email) {
        errors.email = 'Required'
        isError = true
      }
      if (!values.firstName) {
        errors.firstName = 'Required'
        isError = true
      }
      if (!values.lastName) {
        errors.lastName = 'Required'
        isError = true
      }
      errors.mobile = {}
      if (!values.mobile) {
        errors.mobile.number = 'Required'
        isError = true
      } else if (values.mobile && !values.mobile.number) {
        errors.mobile.number = 'Required'
        isError = true
      }
      if (!values.baseFacilityId) {
        errors.baseFacilityId = 'Required'
        isError = true
      }
      if (!values.departmentTeamId) {
        errors.departmentTeamId = 'Required'
        isError = true
      }
      if (!values.memberPool) {
        errors.memberPool = 'Required'
        isError = true
      }
      if (!values.employeeClass) {
        errors.employeeClass = 'Required'
        isError = true
      } else if (values.employeeClass === EmployeeClass.Contract) {
        if (!values.contractEndDate) {
          errors.contractEndDate = 'Required'
          isError = true
        }
      }
      errors.vehicles = []

      if (values.vehicles && values.vehicles.length > 0) {
        values.vehicles.map((row, index) => {
          if (!row) {
            errors.vehicles[index] = 'Required'
            isError = true
          }
          return ''
        })
      }
    }

    return isError
  }

  const onClickEdit = (index: number) => {
    setDisableCheck({
      index: index,
      allowEdit: true,
    })
  }

  const checkAllowEdit = (index: number) => {
    if (disableCheck) {
      if (disableCheck.index === index) {
        return disableCheck.allowEdit
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const getVerificationButton = (verificationType: string) => {
    if (initialValue) {
      if (verificationType === 'emailVerification') {
        if ((!initialValue.emailVerified && !initialValue.mobileVerified) || !initialValue.emailVerified) {
          return (
            <small
              className="verification-button"
              onClick={() => verifyEmailOrPhoneNumber(initialValue.id, verificationType)}
            >
              Not Verified (Email Verification Link)
            </small>
          )
        }
      } else if (verificationType === 'phoneVerification') {
        if (!initialValue.mobileVerified && initialValue.emailVerified) {
          return (
            <small
              className="verification-button"
              onClick={() => verifyEmailOrPhoneNumber(initialValue.id, verificationType)}
            >
              Not Verified (Email Verification Link)
            </small>
          )
        }
      }
    }
  }

  const verifyEmailOrPhoneNumber = (id: string | null, verificationType: string): void => {
    if (id && currentOperatorId && currentOrganisationId) {
      setIsResetPasswordTrigger(false)

      let endpointToken = ''
      if (verificationType === 'emailVerification') {
        endpointToken = ':resendVerificationEmail'
      } else if (verificationType === 'phoneVerification') {
        endpointToken = ':resendMobileVerificationLink'
      } else if (verificationType === 'resendResetPassword') {
        endpointToken = ':resendResetPasswordLink'
        setIsResetPasswordTrigger(true)
      }

      dispatch(
        customMethodAggregateRequestAction<MemberForm>(
          AGGREGATE_MEMBER,
          TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) +
            '/' +
            id +
            endpointToken,
          {},
        ),
      )
    }
  }

  const checkOtherFormFieldsUpdated = (values: MemberForm): boolean => {
    let isUpdated = false
    if (values.email !== initialValue?.email) {
      isUpdated = true
    }
    if (values.departmentTeamId !== initialValue?.departmentTeamId) {
      isUpdated = true
    }
    if (values.firstName !== initialValue?.firstName) {
      isUpdated = true
    }
    if (values.memberPool !== initialValue?.memberPool) {
      isUpdated = true
    }
    if (values.lastName !== initialValue?.lastName) {
      isUpdated = true
    }
    if (values.employeeClass !== initialValue?.employeeClass) {
      isUpdated = true
    }
    if (values.baseFacilityId !== initialValue?.baseFacilityId) {
      isUpdated = true
    }
    if (values.vehicles && values.vehicles.length > 0) {
      const vehicles: string[] = values.vehicles
      const initialValueVehicles: string[] | undefined = initialValue?.vehicles
      if (initialValueVehicles) {
        for (let i = 0; i < vehicles.length; i++) {
          if (vehicles[i] !== initialValueVehicles[i]) {
            isUpdated = true
          }
        }
      }
    }
    return isUpdated
  }

  return (
    <>
      <Container className="page-content-body-wrapper assigned-section">
        <Form<MemberForm>
          onSubmit={onSubmit}
          initialValues={initialValue}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, insert, pop },
            },
            initialValues,
            submitting,
            pristine,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit} id="editForm" noValidate>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item xs={9} className="right-col">
                    <Grid item xs={12} className="content-panel">
                      <AssignedParking memberId={memberId} refreshParking={updated} />
                      <ParkingItems memberId={memberId} memberName={memberName} refreshParking={updated} />
                      <Grid container spacing={0} className="assigned-sub-section">
                        <Grid item xs={6}>
                          <h3 className="padding-bottom-20">User profile</h3>
                        </Grid>
                        <Grid item xs={6}>
                          {values.mobileVerified && values.emailVerified && (
                            <h5 className={'padding-bottom-20 resend-password-link'}>
                              <span onClick={() => verifyEmailOrPhoneNumber(values.id, 'resendResetPassword')}>
                                Send reset password link
                              </span>
                            </h5>
                          )}
                        </Grid>
                        {(updating || loadingOne || verificationInProcess) && (
                          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                              <CircularProgress color="primary" size={30} />
                            </Grid>
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <p className={`input-label ${classes.pTagMaxWidth}`}>
                            Email address {getVerificationButton('emailVerification')}
                          </p>
                          <TextField
                            name="email"
                            InputProps={{ className: 'shuffleInput' }}
                            InputLabelProps={{ className: 'shuffleLabel' }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required={true}
                            disabled={true}
                          />

                          <p className="input-label">First name</p>
                          <TextField
                            name="firstName"
                            InputProps={{ className: 'shuffleInput' }}
                            InputLabelProps={{ className: 'shuffleLabel' }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required={true}
                          />

                          <p className="input-label">Surname</p>
                          <TextField
                            name="lastName"
                            InputProps={{ className: 'shuffleInput' }}
                            InputLabelProps={{ className: 'shuffleLabel' }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required={true}
                          />

                          <p className={`input-label ${classes.pTagMaxWidth}`}>
                            Phone number {getVerificationButton('phoneVerification')}
                          </p>
                          <TextField
                            name="mobile.number"
                            InputProps={{ className: 'shuffleInput' }}
                            InputLabelProps={{ className: 'shuffleLabel' }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={6} paddingLeft="9px">
                          <InputLabel className="select-heading" style={{ paddingBottom: 0 }}>
                            Department/team
                          </InputLabel>
                          <DepartmentTeamSelect
                            name="departmentTeamId"
                            className="shuffle-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              disableScrollLock: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            data={departmentsAndTeams}
                          />

                          <InputLabel className="select-heading" style={{ paddingBottom: 0 }}>
                            Role
                          </InputLabel>
                          <Select
                            className="shuffle-select-role"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              disableScrollLock: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="memberPool"
                            data={memberPoolsIdNames}
                          />

                          <p className="input-label">Office location</p>
                          <Select
                            name="baseFacilityId"
                            className="shuffle-select-location"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              disableScrollLock: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            data={tenancies.values}
                          />

                          <div style={{ display: 'flex' }}>
                            <div>
                              <p className="input-label" style={{ marginBottom: '16px' }}>
                                Expire access
                              </p>
                              <RadioButtons
                                name="employeeClass"
                                data={[
                                  {
                                    id: 'Permanent',
                                    name: 'Never',
                                  },
                                  {
                                    id: 'Contract',
                                    name: 'Set Date',
                                  },
                                ]}
                                disabled={false}
                              />
                            </div>
                            <div style={{ width: '249px', marginTop: '27px' }}>
                              {values.employeeClass === EmployeeClass.Contract && (
                                <DatePicker
                                  name="contractEndDate"
                                  format="dd MMMM yyyy"
                                  required={values.employeeClass === EmployeeClass.Contract}
                                  disabled={false}
                                />
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <VehicleDetails currentValues={values.vehicles} name="vehicles" />

                      <Grid
                        container
                        spacing={0}
                        className="assigned-sub-section"
                        style={{ border: 'none', paddingTop: 0, marginTop: '5px' }}
                      >
                        <Grid item xs={12}>
                          <p className="padding-bottom-20 input-label">Your vehicles</p>
                        </Grid>

                        <FieldArray name="vehicles">
                          {({ fields, meta: { error, invalid } }) =>
                            fields.map((name, index) => (
                              <React.Fragment key={index}>
                                <Grid item xs={6}>
                                  <div style={{ display: 'inline' }}>
                                    <TextField
                                      name={`${name}`}
                                      InputProps={{ className: 'shuffleInput' }}
                                      InputLabelProps={{ className: 'shuffleLabel' }}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                      required={true}
                                      disabled={!checkAllowEdit(index)}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <EditIcon
                                    fontSize={'medium'}
                                    style={{ paddingLeft: 8, cursor: 'pointer' }}
                                    onClick={() => onClickEdit(index)}
                                  />
                                  <div className="remove-vehicle" onClick={() => fields.remove(index)}></div>
                                </Grid>
                              </React.Fragment>
                            ))
                          }
                        </FieldArray>
                      </Grid>
                      {initialValue && (
                        <Grid container spacing={0} className="assigned-sub-section">
                          <UserTerritoryAndFacility
                            setLocationSelection={setLocationSelection}
                            territoryIds={initialValue?.territoryIds ? initialValue.territoryIds : null}
                            facilityIds={initialValue?.facilityIds ? initialValue.facilityIds : null}
                            setIsLocationChange={setIsLocationChange}
                            isAdd={false}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: '20px', paddingBottom: '40px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box display="flex" justifyContent="flex-start">
                            <Button
                              className={classes.shuffleButton}
                              variant="outlined"
                              color="primary"
                              size="large"
                              onClick={hideShowUserDetails}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" justifyContent="flex-end">
                            <Button
                              className={classes.shuffleButton}
                              variant="contained"
                              color="primary"
                              size="large"
                              disabled={pristine && !isLocationChange}
                              type="submit"
                            >
                              Save changes
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      </Container>
    </>
  )
}

export default UserDetails
