import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

// Tenancy Stuff
export interface OfferFacility {
  id: string
  allocation: number
  version: number
  memberAllocationGrouping: string
  memberAllocations: MemberAllocations[]
}

export interface MemberAllocations {
  id: string | null
  memberPool: string | null
  departmentId: string | null
  teamId: string | null
  allocationType: string | null
  allocation: number | null
  version: number | null
  enabled: boolean | null
}

export type OfferFacilityState = AggregateState<OfferFacility>
export type OfferFacilityActionTypes = AggregateActionTypes<OfferFacility>

export const ALLOCATION_TYPE_FIXED = 'Fixed'
export const ALLOCATION_TYPE_UNLIMITED = 'Unlimited'
export const ALLOCATION_TYPE_PERCENTAGE = 'Percentage'

export const MEMBER_POOL_NAME_SORTING_ARR = [
  'Staff_All',
  'Staff_Executive',
  'Staff_Mobile',
  'Staff_Management',
  'Staff_Office',
]

export const OFFER_FACILITY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId/offers/:offerId/offer-facilities/:offerFacilityId'
export const OFFER_FACILITY_PUT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId/offers/:offerId/offer-facilities'
