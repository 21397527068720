import React, { useContext } from 'react'
import { GitShaContext } from '../../App'

interface IProps {
  isPrivate?: boolean
}

const Footer: React.FC<IProps> = (props) => {
  const { isPrivate } = props
  const gitSha = useContext(GitShaContext)
  const gitShaNumber = gitSha ? `V ${gitSha.substring(0, 7)}` : ''
  return (
    <div id="footer" className={isPrivate ? 'assigned-section light-bg' : 'login-page dark-bg'}>
      <div id="footer-text">
        <p className="footer-copyright-text">
          &copy; Wilson Parking 2023. All Rights Reserved. <a href="/">Privacy Policy</a>
        </p>
        <p className="footer-version-text">{gitShaNumber ? gitShaNumber : 'V123456'}</p>
      </div>
    </div>
  )
}

export default Footer
