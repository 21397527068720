import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState, MemberPool, OrganisationType } from '../../../common/types'

// User Interface
export interface MemberRelation {
  id: string
  clientRef: string
  operatorId: string
  tenantId: string
  departmentId: string
  baseFacilityId: string
  facilityIds: string[]
  organisationType: OrganisationType
  organisationId: string
  memberPool: MemberPool
  timeZoneId: string
}

export type MemberRelationState = AggregateState<MemberRelation>
export type MemberRelationActionTypes = AggregateActionTypes<MemberRelation>
export const MEMBER_RELATION_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/relations'
