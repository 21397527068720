import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState, MemberPool } from '../../../common/types'

export interface FilterCriteria {
  departmentId: string
  teamId: string
  location: string
  role: string
  month: string
}
export interface MonthList {
  month: string
  fullMonth: string
  year: string
}

// Daily Facility Agreement Peak Allocation By Authority
export interface AuthorityTypePeakAllocation {
  agreementAuthorityType: string
  peakAllocation: number
  peakAllocationPercent: number
}
export interface PeakAllocations {
  date: string
  peakAllocation: number
  capacity: number
  peakAllocationPercent: number
  authorityTypePeakAllocations: AuthorityTypePeakAllocation[]
}
export interface DailyFacilityAgreementPeakAllocationByAuthority {
  averageAllocationPercent: number
  maxCapacity: number
  maxAllocationDate: string
  facilityId: string | null
  teamId: string | null
  departmentId: string | null
  memberPool: string | null
  peakAllocations: PeakAllocations[]
}
export type DailyFacilityAgreementPeakAllocationByAuthorityState =
  AggregateState<DailyFacilityAgreementPeakAllocationByAuthority>
export type DailyFacilityAgreementPeakAllocationByAuthorityActionTypes =
  AggregateActionTypes<DailyFacilityAgreementPeakAllocationByAuthority>
export const DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/daily-facility-agreement-peak-allocation-by-authority'

export interface DailyFacilityAgreementPeakChartSeries {
  name: string
  type: string
  stack: string
  color: string
  data: number[]
  cursor: string
  barWidth: string
}
export interface AgreementAuthorityType {
  id: string
  name: string
  color: string
}
export let agreementAuthorityTypeList: AgreementAuthorityType[] = [
  { id: 'EntitlementAssignment', name: 'Assigned', color: '#0085da' },
  { id: 'SalesChannelRes', name: 'On-demand', color: '#00436d' },
  { id: 'EntitlementGuest', name: 'Guest', color: '#55ddb4' },
]

// Daily Count Allocation By Authority By Offer
export interface AuthorityTypeCountAllocation {
  agreementAuthorityType: string
  offerId: string
  count: number
  percent: number
}
export interface DailyCountAllocationByAuthorityByOffer {
  fromDate: string
  toDate: string
  facilityId: string
  departmentId: string
  teamId: string
  memberPool: string
  authorityTypeCountAllocations: AuthorityTypeCountAllocation[]
}
export type DailyCountAllocationByAuthorityByOfferState = AggregateState<DailyCountAllocationByAuthorityByOffer>
export type DailyCountAllocationByAuthorityByOfferActionTypes =
  AggregateActionTypes<DailyCountAllocationByAuthorityByOffer>
export const DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/daily-count-allocation-by-authority-by-offer'

export let agreementAuthorityTypeForDailyCountAllocation: AgreementAuthorityType[] = [
  { id: 'EntitlementAssignment', name: 'Assignments', color: '#0085da' },
  { id: 'EntitlementGuest', name: 'Guest Bookings', color: '#55ddb4' },
]
export interface DailyCountAllocationChartData {
  id: string
  name: string
  color: string
  count: number
  percent: number
}

export let colorList = [
  '#55ddb4',
  '#0085da',
  '#fd7f6f',
  '#7eb0d5',
  '#bd7ebe',
  '#ffb55a',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
  '#b2e061',
  '#ffee65',
]

// Daily Member Financial Summary

export interface DailyMemberFinancialSummary {
  fromDate: string
  toDate: string
  facilityId: string
  teamId: string | null
  departmentId: string | null
  memberPool: MemberPool
  totalRealisedPayments: number
  totalRealisedRefunds: number
  totalGoodwillRevenue: number
  totalGoodwillRefund: number
}

export type DailyMemberFinancialSummaryState = AggregateState<DailyMemberFinancialSummary>
export type DailyMemberFinancialSummaryActionTypes = AggregateActionTypes<DailyMemberFinancialSummary>

export const DAILY_MEMBER_FINANCIAL_SUMMARY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/daily-member-financial-summary'
