import {
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_TENANCIES,
  AGGREGATE_TENANCIES_OFFERS,
  AGGREGATE_TENANT_OFFERS,
  DELETE_AGGREGATE_FAILURE,
  DELETE_AGGREGATE_REQUEST,
  DELETE_AGGREGATE_SUCCESS,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  INSERT_AGGREGATE_FAILURE,
  INSERT_AGGREGATE_REQUEST,
  INSERT_AGGREGATE_SUCCESS,
  isAggregateActionType,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_ID_NAME_FAILURE,
  LIST_AGGREGATES_ID_NAME_NOT_MODIFIED,
  LIST_AGGREGATES_ID_NAME_REQUEST,
  LIST_AGGREGATES_ID_NAME_SUCCESS,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
  UPDATE_AGGREGATE_FAILURE,
  UPDATE_AGGREGATE_REQUEST,
  UPDATE_AGGREGATE_SUCCESS,
} from '../../../common/aggregate.types'
import {
  MyTenancyState,
  TenancyActionTypes,
  TenancyOfferActionTypes,
  TenancyOfferState,
  TenancyState,
  TenantOfferActionTypes,
  TenantOfferState,
} from './types'

const initialState: TenancyState | MyTenancyState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function tenancyReducer(state = initialState, action: TenancyActionTypes): TenancyState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_TENANCIES) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
    case LIST_AGGREGATES_ID_NAME_REQUEST:
      return {
        ...state,
        inserted: false,
        updated: false,
        loadingList: true,
        error: null,
        aggregate: null,
        deleted: false,
      }
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        inserted: false,
        updated: false,
        loadingOne: true,
        loadingList: false,
        error: null,
        aggregate: null,
        deleted: false,
      }
    case UPDATE_AGGREGATE_REQUEST:
    case INSERT_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        error: null,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        error: null,
        aggregates: action.payload.aggregates,
      }

    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case LIST_AGGREGATES_ID_NAME_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingList: false,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
      }
    case LIST_AGGREGATES_ID_NAME_SUCCESS:
      return {
        ...state,
        loadingList: false,
        error: null,
        idNames: action.payload.idNames,
      }
    case LIST_AGGREGATES_ID_NAME_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case UPDATE_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: false,
        updated: true,
        error: null,
      }
    case INSERT_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: true,
        updated: false,
        error: null,
        idOnly: action.payload.idOnly,
      }

    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }

    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
      }
    case UPDATE_AGGREGATE_FAILURE:
    case INSERT_AGGREGATE_FAILURE:
      return {
        ...state,
        inserting: false,
        error: action.payload.error,
      }
    case DELETE_AGGREGATE_REQUEST:
      return {
        ...state,
        deleting: true,
        deleted: false,
        error: null,
      }
    case DELETE_AGGREGATE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        aggregate: null,
        error: null,
      }
    case DELETE_AGGREGATE_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.payload.error,
      }

    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}

const tenancyOfferInitialState: TenancyOfferState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
  getTenanciesSuccess: false,
}

export function tenancyOffersReducer(
  state = tenancyOfferInitialState,
  action: TenancyOfferActionTypes,
): TenancyOfferState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_TENANCIES_OFFERS) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case RESET_AGGREGATE:
      return tenancyOfferInitialState
    default:
      return state
  }
}

export function myListTenancyReducer(state = initialState, action: TenancyActionTypes): MyTenancyState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_MY_LIST_TENANCIES) {
    return state
  }
  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
        getTenanciesSuccess: false,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
        getTenanciesSuccess: true,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
        getTenanciesSuccess: false,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}

// Tenant Offers
const tenantOfferInitialState: TenantOfferState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}
export function tenantOffersReducer(state = tenantOfferInitialState, action: TenantOfferActionTypes): TenantOfferState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_TENANT_OFFERS) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
        error: null,
        aggregates: {
          values: [],
          totalCount: null,
          link: null,
          eTag: null,
        },
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case RESET_AGGREGATE:
      return tenantOfferInitialState
    default:
      return state
  }
}
