import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Autocomplete, CircularProgress, Popper, Radio, RadioGroup } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { format, isBefore, isValid } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import reservedIcon from '../../../assets/svg/ws-b2b-icon-reserved-2.svg'
import searchFieldIcon from '../../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import { AGGREGATE_MEMBER_LIST, AGGREGATE_VERIFY_STAY_AGREEMENTS } from '../../../common/aggregate.types'
import { AGGREGATE_MEMBER, customMethodAggregateRequestAction, fetchAggregates } from '../../../common/axios-action'
import { timeSlots } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { useDebounce } from '../../../hook/useDebounce'
import { RootState } from '../../../store'
import { Member, MEMBER_API_RESOURCE_PATH } from '../../../store/common/member/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { CustomCalendarIcon } from '../../util/custom-icon'
import {
  ROUTE_ASSIGNED,
  ROUTE_ASSIGNED_MANAGE_PARKERS,
  ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT,
} from '../../util/routes'
import {
  getAPTimeFormDate,
  getDateFormAPTime,
  getFormattedErrorMessageForDate,
  getHoursFromTotalMinutes,
  getMinuteFromTotalMinutes,
  getMinutesFromMidnight,
} from '../../util/util'
import { ViewEntitlementInterface } from '../view-entitlement/types'
import AssignBayType from './assign-bay-type'
import {
  AssignParkingBay,
  AssignParkingStayAgreements,
  ASSIGN_BAY_VERIFY_STAY_AGREEMENTS_POST_API_RESOURCE_PATH,
  DateErrorState,
  ParkingDate,
  Slots,
} from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    maxWidth: '200px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  customTooltip: {
    backgroundColor: '#000000',
    color: 'white',
    fontSize: '0.75rem',
    padding: '8px 8px 8px 8px',
    borderRadius: '3px',
    textAlign: 'center',
    maxWidth: '230px',
  },
  customArrow: {
    color: '#000000',
  },
}))

interface IProps {}

interface Week {
  label?: string
  dayOfWeek: string
  fromMinutes?: number | null
  toMinutes?: number | null
  isSelected?: boolean
  keepInSimpleMode?: boolean
  startTime?: Date | null
  endTime?: Date | null
}
interface InitialStateInterface {
  id?: string | null
  memberPosition?: number | null
  version?: number | null
  memberDetail: Member
  weekDays: Week[]
}

interface OverlappingAssignments {
  memberId: string
  dayOfWeek: string
}

const AssignParking: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [member, setMember] = useState<Member | null>(null)
  const [userNameOrEmail, setUserNameOrEmail] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(userNameOrEmail, 600)
  const [entitlementList, setEntitlementList] = useState<InitialStateInterface[]>([])
  const [timePickerVisible, setTimePickerVisible] = useState<boolean>(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)
  const [isNextPageVisible, setNextPageVisible] = useState<boolean>(false)
  const [isStartDateDisabled, setIsStartDateDisabled] = useState<boolean>(false)
  const [stayAgreements, setStayAgreements] = useState<AssignParkingStayAgreements[]>([])
  const [finalData, setFinalData] = useState<InitialStateInterface[]>([])
  const [isAdvanceModeEnable, setAdvanceModeEnable] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [timePickerOpenData, setTimePickerOpenData] = useState<any>(null)
  const [overlappingAssignmentsList, setOverlappingAssignmentsList] = useState<OverlappingAssignments[]>([])
  const [isOverlappingAssignmentsError, setIsOverlappingAssignmentsError] = useState<boolean>(false)
  const [pageLabel, setPageLabel] = useState<string>('Assign a bay')
  const [assignedBayData, setAssignedBayDate] = useState<ViewEntitlementInterface>()

  const [isValidParkingDate, setIsValidParkingDate] = useState<boolean>(false)
  const [parkingDate, setParkingDate] = useState<ParkingDate>({
    parkingStartDate: null,
    parkingEndDate: null,
    parkingDateType: '',
  })
  const [dateError, setDateError] = useState<DateErrorState>({
    parkingStartDate: '',
    parkingEndDate: '',
  })

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const { state }: any = useLocation()

  const { members, membersLoadingList, loadingOne } = useSelector((state: RootState) => ({
    members: state.memberReducer.aggregates,
    membersLoadingList: state.memberReducer.loadingList,
    loadingOne: state.memberReducer.loadingOne,
  }))

  const { verifyStayAgreementsIdOnly, verifyStayAgreementsIdError, inserting }: any = useSelector(
    (state: RootState) => ({
      verifyStayAgreementsIdOnly: state.verifyStayAgreementsReducer.inserted,
      verifyStayAgreementsIdError: state.verifyStayAgreementsReducer.error,
      inserting: state.verifyStayAgreementsReducer.inserting,
    }),
  )

  const createMemberDetailInitialObject = (): Member => {
    const memberDetail: Member = {
      id: '',
      firstName: '',
      lastName: '',
      name: '',
      email: '',
    }
    return memberDetail
  }

  const createWeekDayInitialObject = (): Week[] => {
    const weekDays: Week[] = [
      {
        label: 'M',
        dayOfWeek: 'MONDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
      {
        label: 'T',
        dayOfWeek: 'TUESDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
      {
        label: 'W',
        dayOfWeek: 'WEDNESDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
      {
        label: 'T',
        dayOfWeek: 'THURSDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
      {
        label: 'F',
        dayOfWeek: 'FRIDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
      {
        label: 'S',
        dayOfWeek: 'SATURDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
      {
        label: 'S',
        dayOfWeek: 'SUNDAY',
        startTime: null,
        endTime: null,
        isSelected: false,
        keepInSimpleMode: false,
      },
    ]

    return weekDays
  }

  const createInitialStateObject = (): InitialStateInterface => {
    const memberDetail = createMemberDetailInitialObject()
    const weekDays = createWeekDayInitialObject()
    return {
      memberDetail,
      weekDays,
    }
  }

  // this useEffect use for set data in Edit mode
  useEffect(() => {
    if (state) {
      if (state.isBayEdit) {
        setIsEditMode(true)
        setAssignedBayDate(state.entitlement)
        setPageLabel('Edit assigned bay')
        const assignedStayAgreements = state.entitlement.stay
        setTimePickerVisible(state.entitlement.specifyTime)

        if (state.entitlement.stay) {
          let today = new Date().setHours(0, 0, 0, 0)
          let startDate = new Date(state.entitlement.stay.startDate).setHours(0, 0, 0, 0)
          if (isBefore(startDate, today)) {
            setIsStartDateDisabled(true)
          }
          if (state.entitlement.stay.finishDate) {
            setParkingDate({
              parkingStartDate: new Date(state.entitlement.stay.startDate),
              parkingEndDate: new Date(state.entitlement.stay.finishDate),
              parkingDateType: 'date',
            })
          } else {
            setParkingDate({
              parkingStartDate: new Date(state.entitlement.stay.startDate),
              parkingEndDate: null,
              parkingDateType: 'never',
            })
          }
        }

        const stayAgreementsList: InitialStateInterface[] = []
        const weekArrayObj = createInitialStateObject()
        const memberDetailObj = createInitialStateObject()

        const stayAgreement: InitialStateInterface = {
          id: assignedStayAgreements.id,
          version: assignedStayAgreements.version,
          memberDetail: {
            ...memberDetailObj.memberDetail,
            id: state.entitlement.memberId,
            name: state.entitlement.member.name,
          },
          weekDays: weekArrayObj.weekDays.map((day: Week) => {
            const slotObj: Slots | undefined = assignedStayAgreements.slots.find(
              (slot: Slots) => slot.dayOfWeek === day.dayOfWeek,
            )
            if (slotObj) {
              day.isSelected = true
              day.keepInSimpleMode = state.entitlement.specifyTime
              day.startTime =
                slotObj.fromMinutes !== undefined && slotObj.fromMinutes !== null
                  ? getTimePickerDate(slotObj.fromMinutes)
                  : null
              day.endTime =
                slotObj.toMinutes !== undefined && slotObj.toMinutes !== null
                  ? getTimePickerDate(slotObj.toMinutes)
                  : null
            }
            return day
          }),
        }

        stayAgreementsList.push(stayAgreement)

        setEntitlementList(stayAgreementsList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (!debouncedSearchTerm) {
      dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members])

  useEffect(() => {
    if (!state) {
      setEntitlementList([])
      setTimePickerVisible(false)
      setAdvanceModeEnable(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (verifyStayAgreementsIdOnly) {
      setNextPageVisible(!isNextPageVisible)
      setOverlappingAssignmentsList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyStayAgreementsIdOnly])

  useEffect(() => {
    if (verifyStayAgreementsIdError) {
      dispatch(setNotification(NotificationType.ERROR, [verifyStayAgreementsIdError.message]))
      if (verifyStayAgreementsIdError.stayAgreements) {
        setOverlappingAssignmentsList(verifyStayAgreementsIdError.stayAgreements)
        setIsOverlappingAssignmentsError(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyStayAgreementsIdError])

  // this function call user api when search by user name
  const searchMember = () => {
    if (currentOperatorId && currentOrganisationId && userNameOrEmail) {
      dispatch(
        fetchAggregates<Member>(
          AGGREGATE_MEMBER_LIST,
          MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          { name: userNameOrEmail, size: 100 },
        ),
      )
    }
  }

  // this use effect call base on debouncedSearchTerm
  useEffect(() => {
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    if (debouncedSearchTerm && debouncedSearchTerm.trim().length > 1) {
      searchMember()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedSearchTerm])

  // this useEffect use for render user bay selection schedule
  useEffect(() => {
    if (member && debouncedSearchTerm) {
      if (!entitlementList.some((el: InitialStateInterface) => el.memberDetail?.id === member.id)) {
        const initialStateObj = createInitialStateObject()
        const memberObj: InitialStateInterface = {
          ...initialStateObj,
          memberDetail: member,
        }
        setEntitlementList([...entitlementList, memberObj])
        setMember(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member])

  useEffect(() => {
    gatherFinalDataForApi()
    setIsOverlappingAssignmentsError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlementList, timePickerVisible])

  // check next button validation based on parking date selection
  useEffect(() => {
    let isValidDetail: boolean = true
    Object.entries(dateError).forEach((item) => {
      if (item[1]) {
        isValidDetail = false
        return
      }
    })
    if (parkingDate.parkingStartDate) {
      if (parkingDate.parkingDateType) {
        if (parkingDate.parkingDateType === 'date') {
          if (parkingDate.parkingEndDate === null) {
            isValidDetail = false
          }
        }
      } else {
        isValidDetail = false
      }
    } else {
      isValidDetail = false
    }
    setIsValidParkingDate(isValidDetail)
  }, [parkingDate, dateError])

  // this function use for get user by name or email
  const getMemberByUsernameAndEmail = (value: Member | null) => {
    if (value) {
      setMember(value)
    }
  }

  // this function use for add or remove enabled/disabled class
  const getClassWithDisable = (weekDay: Week, memberId: string | null | undefined): string => {
    let className: string = ''
    let flag: boolean = false
    if (weekDay.isSelected) {
      className = 'day-usage-icon enabled'
    }
    entitlementList.forEach((data: InitialStateInterface, index) => {
      if (data.memberDetail?.id !== memberId) {
        data.weekDays.forEach((day: Week) => {
          if (day.dayOfWeek === weekDay.dayOfWeek && !weekDay.keepInSimpleMode) {
            if (day.isSelected) {
              flag = true
              return
            }
          }
        })
      }
    })

    if (!weekDay.isSelected && !timePickerVisible) {
      className = 'day-usage-icon'
    }
    if (flag && !timePickerVisible) {
      className = 'day-usage-icon disabled'
    } else {
      if (className === '') {
        className = 'day-usage-icon'
      }
    }
    return className
  }

  // this function use for add or remove enabled/disabled class
  const getClassWithoutDisable = (weekDay: Week, memberId: string | null | undefined): string => {
    let className: string = ''
    if (weekDay.isSelected) {
      className = 'day-usage-icon enabled'
    } else {
      className = 'day-usage-icon'
    }
    return className
  }

  // this function use for remove user from list
  const removeUser = (index: number, memberId: any) => {
    let updatedErrorsList = fromAndToTimeErrors.filter((element: FromAndToTimeErrors) => {
      if (element.id === memberId) {
        return false
      } else {
        return true
      }
    })
    setFromAndToTimeErrors(updatedErrorsList)
    entitlementList.splice(index, 1)
    setEntitlementList([...entitlementList])
    entitlementList.length === 0 && setTimePickerVisible(false)
    setMember(null)
    if (entitlementList.length === 0) {
      setAdvanceModeEnable(false)
    }
  }

  // this function use for update value of weekday
  const handleSelectWeekDay = (weekDay: Week, id: string | null | undefined) => {
    if (weekDay.isSelected) {
      let updatedErrorsList = fromAndToTimeErrors.filter((element: FromAndToTimeErrors) => {
        if (element.id === id && element.dayOfWeek === weekDay.dayOfWeek) {
          return false
        } else {
          return true
        }
      })
      setFromAndToTimeErrors(updatedErrorsList)
    }

    const updatedEntitlementList = entitlementList.map((e) => {
      if (e.memberDetail?.id === id) {
        e.weekDays = e.weekDays.map((day) => {
          if (day.dayOfWeek === weekDay.dayOfWeek) {
            if (!day.isSelected) {
              day.startTime = null
              day.endTime = null
            }
            if (day.isSelected && day.keepInSimpleMode) {
              day.keepInSimpleMode = false
            }
            day.isSelected = !day.isSelected
          }
          return day
        })
      }
      return e
    })
    setEntitlementList(updatedEntitlementList)
  }

  // this function use for set or update date value
  const handleChangeStartTimeAndEndTime = (
    value: any,
    name: string,
    weekDay: Week,
    memberId: string | null | undefined,
  ) => {
    const updatedEntitlementList = entitlementList.map((e) => {
      if (e.memberDetail?.id === memberId) {
        e.weekDays = e.weekDays.map((day) => {
          if (day.dayOfWeek === weekDay.dayOfWeek) {
            if (name === 'startTime') {
              if (value === null || value === undefined) {
                day.endTime = null
              }
              day.startTime = value
            } else if (name === 'endTime') {
              day.endTime = value
            }
          }
          return day
        })
      }
      return e
    })

    if (timePickerVisible && !weekDay.startTime) {
      setNextButtonDisabled(true)
      dispatch(setNotification(NotificationType.ERROR, ['please select start time as well as end time']))
      return
    } else {
      setNextButtonDisabled(false)
    }
    setEntitlementList(updatedEntitlementList)
  }

  // get all data and base on validate next button enabled or disabled
  const gatherFinalDataForApi = (): void => {
    const entitlementDataArray: InitialStateInterface[] = entitlementList.map((memberData: InitialStateInterface) => {
      const entitlement: InitialStateInterface = {
        id: memberData.id,
        memberDetail: { ...memberData.memberDetail, id: memberData.memberDetail?.id },
        memberPosition: memberData.memberPosition,
        version: memberData.version,
        weekDays: [],
      }
      const weekDayArray: Week[] = []
      memberData.weekDays.forEach((day: Week) => {
        if (day.isSelected) {
          const weekSlot: Week = {
            dayOfWeek: day.dayOfWeek,
            fromMinutes: timePickerVisible ? (day.startTime ? getMinutesFromMidnight(day.startTime) : null) : null,
            toMinutes: timePickerVisible ? (day.endTime ? getMinutesFromMidnight(day.endTime) : null) : null,
          }
          weekDayArray.push(weekSlot)
        }
      })
      return {
        ...entitlement,
        weekDays: weekDayArray,
      }
    })

    let isDaySelected: boolean = false
    if (entitlementDataArray.length > 0) {
      isDaySelected = entitlementDataArray.every((row) => {
        if (row.weekDays.length > 0) {
          return true
        }
        return false
      })
    }

    let timeRequired = false

    if (timePickerVisible) {
      entitlementList.forEach((data) => {
        data.weekDays.forEach((day) => {
          if (day.isSelected && !day.startTime && !day.endTime) {
            timeRequired = true
          } else if (day.isSelected && !day.startTime) {
            timeRequired = true
          } else if (day.isSelected && !day.endTime) {
            timeRequired = true
          }
        })
      })
    }

    if (timeRequired) {
      setNextButtonDisabled(true)
    } else {
      if (isDaySelected && timePickerVisible) {
        setNextButtonDisabled(false)
      } else {
        setNextButtonDisabled(true)
      }
      if (isDaySelected && (!timePickerVisible || timePickerVisible)) {
        setNextButtonDisabled(false)
      } else {
        setNextButtonDisabled(true)
      }
    }
    setFinalData(entitlementDataArray)
  }

  const goToNextPage = (): void => {
    let tempStayAgreements: AssignParkingStayAgreements[] = []
    finalData.forEach((row: InitialStateInterface) => {
      const slotsArray: Slots[] = row.weekDays.map((week: Week) => {
        return {
          dayOfWeek: week.dayOfWeek,
          fromMinutes: week.fromMinutes,
          toMinutes: week.toMinutes,
        }
      })
      const stayAgreementsObj: AssignParkingStayAgreements = {
        memberId: row.memberDetail.id,
        startDate: parkingDate.parkingStartDate ? format(parkingDate.parkingStartDate, 'yyyy-MM-dd') : null,
        finishDate: parkingDate.parkingEndDate ? format(parkingDate.parkingEndDate, 'yyyy-MM-dd') : null,
        id: row.id ? row.id : null,
        version: row.version ? row.version : null,
        slots: slotsArray,
      }
      tempStayAgreements.push(stayAgreementsObj)
    })
    setStayAgreements(tempStayAgreements)

    const apiPayLoad: any = {
      specifyTime: timePickerVisible,
      timeZoneId: 'Australia/Sydney',
      startDate: parkingDate.parkingStartDate ? format(parkingDate.parkingStartDate, 'yyyy-MM-dd') : null,
      finishDate: parkingDate.parkingEndDate ? format(parkingDate.parkingEndDate, 'yyyy-MM-dd') : null,
      stayAgreements: tempStayAgreements,
    }

    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        customMethodAggregateRequestAction<AssignParkingBay>(
          AGGREGATE_VERIFY_STAY_AGREEMENTS,
          ASSIGN_BAY_VERIFY_STAY_AGREEMENTS_POST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          apiPayLoad,
        ),
      )
    }
  }

  const componentHideShow = (): void => {
    setStayAgreements([])
    setNextPageVisible(!isNextPageVisible)
  }

  const handleUncheck = () => {
    const weekDayArray: string[] = []
    for (let i = 0; i < entitlementList.length; i++) {
      for (let j = 0; j < entitlementList.length; j++) {
        if (i !== j) {
          const daySelectedList1 = entitlementList[i].weekDays.filter((day) => day.isSelected)
          const daySelectedList2 = entitlementList[j].weekDays.filter((day) => day.isSelected)
          daySelectedList1.forEach((a1) => {
            daySelectedList2.forEach((a2) => {
              if (a1.isSelected && a2.isSelected && a1.dayOfWeek === a2.dayOfWeek) {
                if (!weekDayArray.includes(a1.dayOfWeek)) {
                  weekDayArray.push(a1.dayOfWeek)
                }
              }
            })
          })
        }
      }
    }

    const updatedEntitlementList = entitlementList.map((member) => {
      member.weekDays.map((day) => {
        if (weekDayArray.includes(day.dayOfWeek)) {
          if (day.isSelected) {
            day.keepInSimpleMode = true
          }
        }
        day.startTime = null
        day.endTime = null
        return day
      })
      return member
    })
    setEntitlementList(updatedEntitlementList)
  }

  interface FromAndToTimeErrors {
    id: string
    dayOfWeek: string
    type: string
  }

  let [fromAndToTimeErrors, setFromAndToTimeErrors] = useState<FromAndToTimeErrors[]>([])

  const checkStartTimeIsValid = (
    startTime: Date | null | undefined,
    endTime: Date | null | undefined,
    dayOfWeek: string,
    memberId: any,
  ) => {
    let tempState: any = [...fromAndToTimeErrors]
    let isValidStartTime = isValid(startTime)
    if (startTime === null) {
      isValidStartTime = true
    }
    if (!isValidStartTime) {
      let error = tempState.find(
        (element: FromAndToTimeErrors) =>
          element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'startTime',
      )
      if (error === undefined || error === null) {
        tempState.push({ id: memberId, dayOfWeek: dayOfWeek, type: 'startTime' })
        setFromAndToTimeErrors(tempState)
      }
    } else {
      let updatedErrorsList = tempState.filter((element: FromAndToTimeErrors) => {
        if (element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'startTime') {
          return false
        } else {
          return true
        }
      })

      let isValidRange = false
      let isSameDate = false
      if (startTime && endTime) {
        isValidRange = isBefore(endTime, startTime)
        isSameDate = isStartAndEndDateSame(startTime, endTime)
      }
      if (isValidRange || isSameDate) {
        let error = updatedErrorsList.find(
          (element: FromAndToTimeErrors) =>
            element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'endTime',
        )
        if (error === undefined || error === null) {
          updatedErrorsList.push({ id: memberId, dayOfWeek: dayOfWeek, type: 'endTime' })
        }
      } else {
        updatedErrorsList = updatedErrorsList.filter((element: FromAndToTimeErrors) => {
          if (element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'endTime') {
            return false
          } else {
            return true
          }
        })
      }
      setFromAndToTimeErrors(updatedErrorsList)
    }
  }

  const checkEndTimeIsValid = (
    startTime: Date | null | undefined,
    endTime: Date | null | undefined,
    dayOfWeek: string,
    memberId: any,
  ) => {
    let tempState: any = [...fromAndToTimeErrors]
    let isValidEndTime = isValid(endTime)
    if (endTime === null) {
      isValidEndTime = true
    }
    if (!isValidEndTime) {
      let error = tempState.find(
        (element: FromAndToTimeErrors) =>
          element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'endTime',
      )
      if (error === undefined || error === null) {
        tempState.push({ id: memberId, dayOfWeek: dayOfWeek, type: 'endTime' })
        setFromAndToTimeErrors(tempState)
      }
    } else {
      let isValidRange = false
      let isSameDate = false
      if (startTime && endTime) {
        isValidRange = isBefore(endTime, startTime)
        isSameDate = isStartAndEndDateSame(startTime, endTime)
      }
      if (isValidRange || isSameDate) {
        let error = tempState.find(
          (element: FromAndToTimeErrors) =>
            element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'endTime',
        )
        if (error === undefined || error === null) {
          tempState.push({ id: memberId, dayOfWeek: dayOfWeek, type: 'endTime' })
          setFromAndToTimeErrors(tempState)
        }
      } else {
        let updatedErrorsList = fromAndToTimeErrors.filter((element: FromAndToTimeErrors) => {
          if (element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === 'endTime') {
            return false
          } else {
            return true
          }
        })
        setFromAndToTimeErrors(updatedErrorsList)
      }
    }
  }

  const getHelperTextForTimePicker = (
    memberId: string,
    dayOfWeek: string,
    startTime: Date | null | undefined,
    endTime: Date | null | undefined,
  ) => {
    let fromHours = startTime ? new Date(startTime).getHours() : 0
    let fromMin = startTime ? new Date(startTime).getMinutes() : 0
    let toHours = endTime ? new Date(endTime).getHours() : 0
    let toMin = endTime ? new Date(endTime).getMinutes() : 0

    if (startTime === null || endTime === null) {
      return ''
    } else {
      if (fromHours === toHours && fromMin === toMin) {
        return 'Start and End Times can not be the same'
      } else {
        if (!isValid(startTime)) {
          return 'Invalid start time'
        }
        if (!isValid(endTime)) {
          return 'Invalid end time'
        }
        let error = fromAndToTimeErrors.find(
          (element: FromAndToTimeErrors) => element.id === memberId && element.dayOfWeek === dayOfWeek,
        )
        if (error) {
          return 'End time must be later than start time'
        } else {
          return ''
        }
      }
    }
  }

  const isStartAndEndDateSame = (startTime: Date, endTime: Date) => {
    let fromHours = new Date(startTime).getHours()
    let fromMin = new Date(startTime).getMinutes()
    let toHours = new Date(endTime).getHours()
    let toMin = new Date(endTime).getMinutes()
    if (startTime === null || endTime === null) {
      return false
    }
    if (fromHours === toHours && fromMin === toMin) {
      return true
    } else {
      return false
    }
  }

  const checkIsDateValid = (date: Date | null | undefined) => {
    if (date) {
      let result = isValid(date)
      return result
    } else {
      return false
    }
  }

  const checkTimeInError = (memberId: any, dayOfWeek: any, type: string): boolean => {
    let error = fromAndToTimeErrors.find(
      (element: FromAndToTimeErrors) =>
        element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === type,
    )
    if (error) {
      return true
    } else {
      return false
    }
  }

  const checkFieldHasError = (memberId: any, dayOfWeek: string, type: string) => {
    let error = fromAndToTimeErrors.find(
      (element: FromAndToTimeErrors) =>
        element.id === memberId && element.dayOfWeek === dayOfWeek && element.type === type,
    )
    if (error) {
      return true
    } else {
      return false
    }
  }

  const checkTimePickerOpenForField = (memberId: any, dayOfWeek: string, type: string) => {
    if (timePickerOpenData) {
      if (
        timePickerOpenData.id === memberId &&
        timePickerOpenData.dayOfWeek === dayOfWeek &&
        timePickerOpenData.type === type
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const isOverlappingAssignments = (memberId: string | null | undefined, dayOfWeek: string | null) => {
    if (dayOfWeek) {
      let isSlotAvailable = overlappingAssignmentsList.some(
        (e: OverlappingAssignments) => e.dayOfWeek === dayOfWeek && e.memberId === memberId,
      )
      return isSlotAvailable
    } else {
      let isSlotAvailable = overlappingAssignmentsList.some((e: OverlappingAssignments) => e.memberId === memberId)
      return isSlotAvailable
    }
  }

  const getTimePickerDate = (totalMinutes: number): Date => {
    let hour = getHoursFromTotalMinutes(totalMinutes)
    let minute = getMinuteFromTotalMinutes(totalMinutes)
    const date = new Date()
    date.setHours(hour)
    date.setMinutes(minute)
    return date
  }

  const onBackClick = () => {
    dispatch(resetAggregate<AssignParkingBay>(AGGREGATE_VERIFY_STAY_AGREEMENTS))
    if (state) {
      if (state.isBayEdit) {
        navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT}`, {
          state: { entitlement: state.entitlement },
        })
      } else {
        navigate(ROUTE_ASSIGNED)
      }
    } else {
      navigate(ROUTE_ASSIGNED)
    }
  }

  const PopperMy = function (props: any) {
    return <Popper {...props} style={{ fontSize: '12px' }} placement="bottom-start" />
  }

  const handleChangeForDate = (newValue: Date | null, name: string) => {
    setParkingDate({ ...parkingDate, [name]: newValue })
  }
  const handleChangeParkingDateType = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = (event.target as HTMLInputElement).value
    if (newValue === 'never') {
      setParkingDate({ ...parkingDate, parkingEndDate: null, parkingDateType: newValue })
      setDateError({ ...dateError, parkingEndDate: '' })
    } else {
      setParkingDate({ ...parkingDate, parkingDateType: newValue })
    }
  }

  const handleErrorDate = (reason: string | null, name: string) => {
    if (reason === null) {
      setDateError({ ...dateError, [name]: '' })
    } else {
      setDateError({ ...dateError, [name]: reason })
    }
  }

  const getMinDateForStartDate = (): Date => {
    if (isEditMode) {
      return new Date(state.entitlement.stay.startDate)
    } else {
      return new Date()
    }
  }

  const getMinDateForEndDate = (): Date | null => {
    if (isEditMode) {
      if (parkingDate?.parkingStartDate) {
        let today = new Date().setHours(0, 0, 0, 0)
        let startDate = new Date(parkingDate?.parkingStartDate).setHours(0, 0, 0, 0)
        if (isBefore(startDate, today)) {
          return new Date()
        } else {
          return parkingDate.parkingStartDate ? new Date(parkingDate.parkingStartDate) : null
        }
      } else {
        return new Date()
      }
    } else {
      return parkingDate.parkingStartDate ? new Date(parkingDate.parkingStartDate) : null
    }
  }

  return (
    <>
      {!isNextPageVisible ? (
        <div className="page-content-wrapper assign-parking-wrapper">
          <Container className="page-content-header-wrapper">
            <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
              <Grid item xs={3} className="left-col sidebar top-section">
                <Grid container spacing={0} className="responsive-title-wrapper">
                  <Grid item xs={12}>
                    <h2 className="on-demand-h2 no-padding">
                      <p className="back-icon" onClick={() => onBackClick()}>
                        <img className="icon-back-arrow" src={backIcon} alt="back" />
                        {pageLabel}
                      </p>
                    </h2>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container className="page-content-body-wrapper assigned-section">
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={9} className="right-col">
                <Grid item xs={12} className="content-panel">
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      <div className="assigned-icon-block">
                        <img className="assigned-icon reserved-icon" src={reservedIcon} alt="img" />
                        {!isEditMode && (
                          <>
                            <h4 className="">Choose who will use this bay</h4>
                            <p>Add one or more users </p>
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <p className="custom-input-label">Assigned parking starts</p>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={parkingDate.parkingStartDate}
                              onChange={(newValue) => handleChangeForDate(newValue, 'parkingStartDate')}
                              onError={(reason) => handleErrorDate(reason, 'parkingStartDate')}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              minDate={getMinDateForStartDate()}
                              disabled={isStartDateDisabled}
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  className="shuffleInputIcon"
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  {...params}
                                  error={dateError.parkingStartDate.length > 0}
                                  helperText={getFormattedErrorMessageForDate(dateError.parkingStartDate)}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <p className="custom-input-label" style={{ paddingLeft: '12px' }}>
                            Assigned parking ends
                          </p>
                          <RadioGroup
                            row
                            value={parkingDate.parkingDateType}
                            onChange={(event) => handleChangeParkingDateType(event)}
                          >
                            <FormControlLabel
                              className="radio-group-label"
                              value="never"
                              control={<Radio />}
                              label="Never"
                            />
                            <FormControlLabel
                              className="radio-group-label"
                              value="date"
                              control={<Radio />}
                              label="Set date"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={4}>
                          <p className="custom-input-label">&nbsp;</p>
                          {parkingDate.parkingDateType === 'date' && (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                value={parkingDate.parkingEndDate}
                                onChange={(newValue) => handleChangeForDate(newValue, 'parkingEndDate')}
                                onError={(reason) => handleErrorDate(reason, 'parkingEndDate')}
                                inputFormat="dd MMMM yyyy"
                                disableMaskedInput
                                minDate={getMinDateForEndDate()}
                                components={{
                                  OpenPickerIcon: CustomCalendarIcon,
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    className="shuffleInputIcon"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    {...params}
                                    error={dateError.parkingEndDate.length > 0}
                                    helperText={getFormattedErrorMessageForDate(dateError.parkingEndDate)}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Grid>
                      </Grid>
                      {!isEditMode && (
                        <div className="user-search-box">
                          <p className="custom-input-label">Search for a user</p>
                          <Autocomplete
                            className="assign-autocomplete"
                            value={member}
                            onChange={(event: any, newValue: Member | null) => {
                              getMemberByUsernameAndEmail(newValue)
                            }}
                            inputValue={userNameOrEmail}
                            onInputChange={(event, newInputValue) => {
                              setUserNameOrEmail(newInputValue)
                              if (!newInputValue) {
                                setMember(null)
                                setUserNameOrEmail('')
                              }
                            }}
                            options={members.values}
                            loading={membersLoadingList}
                            loadingText="Loading..."
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => {
                              return userNameOrEmail.includes('@') ? option.email : option.name
                            }}
                            renderOption={(props, option) => {
                              return (
                                <Box component="li" {...props}>
                                  <span style={{ fontWeight: '500' }}>{option.name}&nbsp;</span> (
                                  <small>{option.email}</small>)
                                </Box>
                              )
                            }}
                            noOptionsText={
                              loadingOne && userNameOrEmail && members.values.length === 0
                                ? 'No matching parker was found. Please add a parker to Shuffle first, then assign their parking.'
                                : 'No results'
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                placeholder="Search by name or email address"
                                InputLabelProps={{ className: 'shuffleLabel' }}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  className: 'shuffleInput',
                                  style: { padding: 0 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img className="search-field-icon" src={searchFieldIcon} alt="img" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                      )}

                      {entitlementList.map((member, index) => (
                        <Box
                          className={
                            (isOverlappingAssignments(member.memberDetail?.id, null)
                              ? 'red-border-box headShake'
                              : '') + ' user-assigned-bays'
                          }
                          key={member.memberDetail?.id}
                        >
                          {!isEditMode && (
                            <span
                              className="modal-close-icon cursor-pointer"
                              onClick={() => removeUser(index, member.memberDetail?.id)}
                            ></span>
                          )}
                          <h5>{member.memberDetail.name}</h5>
                          <p>Choose the days {member.memberDetail.name} will have access to parking.</p>
                          <div className="day-usage-wrapper assign-parking-timepicker-autocomplete">
                            <ul className="day-usage-menu">
                              {member.weekDays.map((day, index) => (
                                <li className="day-usage-item" key={index}>
                                  <i
                                    className={
                                      (isAdvanceModeEnable
                                        ? getClassWithoutDisable(day, member.memberDetail?.id)
                                        : getClassWithDisable(day, member.memberDetail?.id)) +
                                      (isOverlappingAssignments(member.memberDetail?.id, day.dayOfWeek)
                                        ? ' red-border-box'
                                        : '')
                                    }
                                    onClick={() => handleSelectWeekDay(day, member.memberDetail?.id)}
                                  >
                                    {day.label}
                                  </i>
                                  {timePickerVisible && day.isSelected && (
                                    <>
                                      <Autocomplete
                                        id="startTime"
                                        className="time-selection-autocomplete"
                                        PopperComponent={PopperMy}
                                        clearIcon={null}
                                        options={timeSlots}
                                        noOptionsText="No results"
                                        value={day.startTime ? getAPTimeFormDate(day.startTime) : ''}
                                        disableClearable
                                        onChange={(_event: any, newValue: string | null) => {
                                          handleChangeStartTimeAndEndTime(
                                            newValue ? getDateFormAPTime(newValue) : null,
                                            'startTime',
                                            day,
                                            member.memberDetail?.id,
                                          )
                                        }}
                                        onClose={() => {
                                          setTimePickerOpenData(null)
                                          checkStartTimeIsValid(
                                            day.startTime,
                                            day.endTime,
                                            day.dayOfWeek,
                                            member.memberDetail?.id,
                                          )
                                        }}
                                        onOpen={() => {
                                          setTimePickerOpenData({
                                            startTime: day.startTime,
                                            endTime: day.endTime,
                                            dayOfWeek: day.dayOfWeek,
                                            memberId: member.memberDetail?.id,
                                            type: 'startTime',
                                          })
                                        }}
                                        disabled={checkTimeInError(member.memberDetail?.id, day.dayOfWeek, 'endTime')}
                                        renderInput={({ inputProps, ...params }) => (
                                          <TextField
                                            className=""
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            {...params}
                                            onBlur={() => {
                                              checkStartTimeIsValid(
                                                day.startTime,
                                                day.endTime,
                                                day.dayOfWeek,
                                                member.memberDetail?.id,
                                              )
                                            }}
                                            inputProps={{
                                              ...inputProps,
                                              placeholder: 'hh:mm',
                                            }}
                                          />
                                        )}
                                      />
                                      <Autocomplete
                                        id="endTime"
                                        className="time-selection-autocomplete"
                                        PopperComponent={PopperMy}
                                        clearIcon={null}
                                        options={timeSlots}
                                        noOptionsText="No results"
                                        value={day.endTime ? getAPTimeFormDate(day.endTime) : ''}
                                        disableClearable
                                        onChange={(_event: any, newValue: string | null) => {
                                          handleChangeStartTimeAndEndTime(
                                            newValue ? getDateFormAPTime(newValue) : null,
                                            'endTime',
                                            day,
                                            member.memberDetail?.id,
                                          )
                                        }}
                                        onClose={() => {
                                          setTimePickerOpenData(null)
                                          checkEndTimeIsValid(
                                            day.startTime,
                                            day.endTime,
                                            day.dayOfWeek,
                                            member.memberDetail?.id,
                                          )
                                        }}
                                        onOpen={() => {
                                          setTimePickerOpenData({
                                            startTime: day.startTime,
                                            endTime: day.endTime,
                                            dayOfWeek: day.dayOfWeek,
                                            memberId: member.memberDetail?.id,
                                            type: 'endTime',
                                          })
                                        }}
                                        disabled={!checkIsDateValid(day.startTime)}
                                        renderInput={({ inputProps, ...params }) => (
                                          <TextField
                                            className=""
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            {...params}
                                            onBlur={() => {
                                              checkEndTimeIsValid(
                                                day.startTime,
                                                day.endTime,
                                                day.dayOfWeek,
                                                member.memberDetail?.id,
                                              )
                                            }}
                                            error={
                                              !checkTimePickerOpenForField(
                                                member.memberDetail?.id,
                                                day.dayOfWeek,
                                                'endTime',
                                              ) && checkFieldHasError(member.memberDetail?.id, day.dayOfWeek, 'endTime')
                                            }
                                            helperText={
                                              !checkTimePickerOpenForField(
                                                member.memberDetail?.id,
                                                day.dayOfWeek,
                                                'endTime',
                                              ) &&
                                              getHelperTextForTimePicker(
                                                member.memberDetail?.id,
                                                day.dayOfWeek,
                                                day.startTime,
                                                day.endTime,
                                              )
                                            }
                                            inputProps={{
                                              ...inputProps,
                                              placeholder: 'hh:mm',
                                            }}
                                          />
                                        )}
                                        filterOptions={(options, state) => {
                                          let tempOptions: string[] = options
                                          if (day.startTime) {
                                            let startTime = day.startTime
                                            let validOptions: string[] = []
                                            options.forEach((option) => {
                                              let endTime: Date | null = getDateFormAPTime(option)
                                              if (startTime && endTime) {
                                                let statTimeSlot = startTime.getHours() * 60 + startTime.getMinutes()
                                                let endTimeSlot = endTime.getHours() * 60 + endTime.getMinutes()
                                                if (endTimeSlot > statTimeSlot) {
                                                  validOptions.push(option)
                                                }
                                              }
                                            })
                                            tempOptions = validOptions
                                          }
                                          return tempOptions.filter((option) => option.includes(state.inputValue))
                                        }}
                                      />
                                    </>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="day-usage-options assign-from-to-date">
                            <p className="bay-usage-key">
                              <span className="custom-key-icon selected-bay-icon"></span>Assigned days
                            </p>
                          </div>
                        </Box>
                      ))}
                      {entitlementList.length > 0 && (
                        <FormGroup style={{ padding: '30px 0' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setTimePickerVisible(true)
                                    setAdvanceModeEnable(false)
                                  } else {
                                    setFromAndToTimeErrors([])
                                    setTimePickerVisible(false)
                                    handleUncheck()
                                  }
                                }}
                                checked={timePickerVisible}
                              />
                            }
                            className="sml-checkbox-label"
                            label="Specify the hours available on each day"
                          />
                        </FormGroup>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-start">
                        <Button
                          className={classes.shuffleButton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => {
                            dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
                            dispatch(resetAggregate<Member>(AGGREGATE_MEMBER))
                            dispatch(resetAggregate<AssignParkingBay>(AGGREGATE_VERIFY_STAY_AGREEMENTS))
                            navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS}`)
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={
                            nextButtonDisabled ||
                            fromAndToTimeErrors.length !== 0 ||
                            isOverlappingAssignmentsError ||
                            timePickerOpenData !== null ||
                            !isValidParkingDate
                          }
                          onClick={goToNextPage}
                        >
                          {!inserting && 'Next'}
                          {inserting && (
                            <CircularProgress color="primary" style={{ color: '#FFFFFF', width: 30, height: 30 }} />
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <AssignBayType
          goBack={componentHideShow}
          stayAgreements={stayAgreements}
          parkingDate={parkingDate}
          isTimeSpecify={timePickerVisible}
          assignedBayData={assignedBayData ? assignedBayData : null}
          pageLabel={pageLabel}
          isAssignBayEdit={state ? state.isBayEdit : null}
        ></AssignBayType>
      )}
    </>
  )
}

export default AssignParking
