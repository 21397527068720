import { MemberParkingItemActionTypes, MemberParkingItemState } from './types'
import {
  AGGREGATE_MEMBER_PARKING_ITEM,
  isAggregateActionType,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_ID_NAME_NOT_MODIFIED,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
} from '../../../../../common/aggregate.types'

const initialState: MemberParkingItemState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function memberParkingItemReducer(
  state = initialState,
  action: MemberParkingItemActionTypes,
): MemberParkingItemState {
  // Guard against aggregate actions for another type.  Non aggregate actions and aggregate actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_MEMBER_PARKING_ITEM) {
    return state
  }
  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        inserted: false,
        updated: false,
        loadingList: true,
        error: null,
        aggregate: null,
        deleted: false,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        error: null,
        aggregates: action.payload.aggregates,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }

    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case LIST_AGGREGATES_ID_NAME_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}
