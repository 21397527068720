import { format } from 'date-fns'
import { AggregateActionTypes } from '../../../common/aggregate.types'
import {
  AggregateState,
  AgreementStatus,
  AgreementType,
  CreditCardType,
  EntryStatus,
  MemberVehicle,
  OrderChargeType,
  OrderStatus,
  PaymentChannel,
  PurchaseType,
  SalesChannelType,
  TransactionStatus,
  TransactionType,
  UserClass,
  UserRef,
} from '../../../common/types'
import { formatNumberWithDecimalPlace } from '../../../common/utility'
import { ParkingOffer } from '../../settings/users/active-users/recent-park-items/types'

export enum RefundAmountType {
  Full = 'Full',
  Partial = 'Partial',
  Specify = 'Specify',
}

export interface PartialRefundItem {
  name: string
  value: string
}

export interface OrderParkingAgreements {
  orderId: string
  itemId: string
  agreementId: string
  facilityId: string
  orderReference: string
  memberName: string
  offerName: string
  facilityName: string
  entryDateTime: string
  exitDateTime: string
  agreementStatus: string
  orderStatus: string
  organisationId: string
  organisationType: string
  vehiclePlate: string | null
}

export type OrderParkingAgreementsState = AggregateState<OrderParkingAgreements>
export type OrderParkingAgreementsTypes = AggregateActionTypes<OrderParkingAgreements>

export const ORDER_PARKING_AGREEMENTS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/order-parking-agreements'

export const ORDER_PARKING_AGREEMENTS_EXPORT_CSV_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/order-parking-agreements/export-csv'
export interface BayAssignments {
  bayAssignmentType: string
}
export interface AccessCredentials {
  accessMode: string
  value: string
}
export interface StayAgreements {
  id: string
  facilityId: string
  facilityName: string
  bayAssignments: BayAssignments[]
  accessCredentials: AccessCredentials[]
  charge: number
  unitPrice: number
  quantity: number
  timeZoneId: string
  agreementType: AgreementType
  singleStay: {
    startInstant: number
    finishInstant: number
    startDateTime: string
    finishDateTime: string
    durationMinutes: number
  }
  agreementStatus: AgreementStatus
  entryStatus: EntryStatus
  grace: {
    preEntryMinutes: number
    postEntryMinutes: number
    postExitMinutes: number
  }
  utilised: boolean
}

export interface OrderCharge {
  orderChargeId: string
  chargeId: string
  clientRef: string
  code: string
  name: string
  revision: number
  charge: number | null
  note: string
  orderChargeType: OrderChargeType
}

export interface AgreementTransaction {
  id: string
  paymentChannel: PaymentChannel
  transactionType: TransactionType
  creditCard: {
    cardType: CreditCardType
    cardExpiryMonth: number
    cardExpiryYear: number
    cardMasked: string
  }
  responseCode: string
  responseText: string
  createdInstant: number
  transactionStatus: TransactionStatus
  amount: number | null
  createdDateTime: string
  createdBy?: UserRef
}
export interface AgreementsDetail {
  orderId: string
  salesChannelType: SalesChannelType
  orderReference: string
  memberId: string
  memberName: string
  createdUserRef?: UserRef
  lastModifiedUserRef?: UserRef
  operatorId: string
  orderCreated: number
  subTotalIncTaxes: number
  orderStatus: OrderStatus
  paymentAmount: number
  version: number
  charges: OrderCharge[]
  itemId: string
  totalCharge: number
  vehicle?: MemberVehicle
  offer: ParkingOffer
  stayAgreements: StayAgreements[]
  purchaseType: PurchaseType
  orderCancelledInstant?: number
  allowCancel: boolean
  allowAmend: boolean
  allowAmendDeadline?: number
  allowCancelDeadline?: number
  cancelledBy?: UserRef
  amendedBy?: UserRef
  paymentTransaction?: AgreementTransaction
  refundTransaction?: AgreementTransaction
  finalAmendment: boolean
}

export type AgreementsDetailState = AggregateState<AgreementsDetail>
export type AgreementsDetailTypes = AggregateActionTypes<AgreementsDetail>

export const AGREEMENTS_DETAIL_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/orders'

// For refund
export const ORDER_CALCULATE_REFUND_ADMIN_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders/:orderId:calculateRefundAdmin'
export const ORDER_CALCULATE_CANCEL_REFUND_ADMIN_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders/:orderId:calculateCancelRefundAdmin'
export const ORDER_REFUND_ADMIN_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders/:orderId:refundAdmin'
export const ORDER_CANCEL_ADMIN_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders/:orderId:cancelAdmin'
export const ORDER_CANCEL_AND_REFUND_ADMIN_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders/:orderId:cancelAndRefundAdmin'

// Error messages
export const REFUND_FAILED_MESSAGE =
  'There was a problem processing this refund. Please contact Wilson Parking for Support.'
export const CANCEL_FAILED_MESSAGE =
  'There was a problem processing this cancel. Please contact Wilson Parking for Support.'

// on-demand transaction commons
export const getMemberNameFromUserRef = (userRef: UserRef | undefined) => {
  let name: string = ''
  if (userRef) {
    if (userRef.userClass === UserClass.Consumer) {
      name = 'the employee'
    } else {
      name = userRef.fullName
    }
  }
  return name
}

export const getCardEndingNumber = (transaction: AgreementTransaction | undefined) => {
  if (transaction && transaction.creditCard) {
    let cardMasked = transaction.creditCard.cardMasked
    if (cardMasked) {
      return cardMasked.slice(cardMasked.length - 3, cardMasked.length)
    } else {
      return '***'
    }
  } else {
    return '***'
  }
}

export const getRefundLabel = (agreementDetail: AgreementsDetail) => {
  let element: any = <td></td>
  if (agreementDetail) {
    if (agreementDetail.refundTransaction) {
      if (agreementDetail.refundTransaction.paymentChannel === PaymentChannel.Goodwill) {
        let amount = agreementDetail.refundTransaction.amount
        element = (
          <td className="refund-success">
            ${formatNumberWithDecimalPlace(amount, 2)} Goodwill refund by&nbsp;
            {getMemberNameFromUserRef(agreementDetail.refundTransaction?.createdBy)} on&nbsp;
            {format(new Date(agreementDetail.refundTransaction.createdDateTime), 'dd MMM yy')}
            &nbsp;at&nbsp;
            {format(new Date(agreementDetail.refundTransaction.createdDateTime), 'hh:mmaaa')}
          </td>
        )
      } else {
        let amount = agreementDetail.refundTransaction.amount
        let cardType = agreementDetail.refundTransaction.creditCard.cardType

        if (agreementDetail.refundTransaction.transactionStatus === TransactionStatus.Success) {
          element = (
            <td className="refund-success">
              ${formatNumberWithDecimalPlace(amount, 2)} Refunded to {cardType.toUpperCase()} (ending&nbsp;
              {getCardEndingNumber(agreementDetail?.refundTransaction)}) by&nbsp;
              {getMemberNameFromUserRef(agreementDetail.refundTransaction?.createdBy)} on&nbsp;
              {format(new Date(agreementDetail.refundTransaction.createdDateTime), 'dd MMM yy')}
              &nbsp;at&nbsp;
              {format(new Date(agreementDetail.refundTransaction.createdDateTime), 'hh:mmaaa')}
            </td>
          )
        }

        if (agreementDetail.refundTransaction.transactionStatus === TransactionStatus.Failed) {
          element = (
            <td className="refund-failed">
              ${formatNumberWithDecimalPlace(amount, 2)} Refund to {cardType.toUpperCase()} (ending&nbsp;
              {getCardEndingNumber(agreementDetail?.refundTransaction)}) failed with error&nbsp;
              {agreementDetail.refundTransaction.responseCode}
              &nbsp;-&nbsp;
              {agreementDetail.refundTransaction.responseText} by&nbsp;
              {getMemberNameFromUserRef(agreementDetail.refundTransaction?.createdBy)} on&nbsp;
              {format(new Date(agreementDetail.refundTransaction.createdDateTime), 'dd MMM yy')}
              &nbsp;at&nbsp;
              {format(new Date(agreementDetail.refundTransaction.createdDateTime), 'hh:mmaaa')}
            </td>
          )
        }
      }
    } else {
      if (agreementDetail.paymentTransaction) {
        if (agreementDetail.paymentTransaction.paymentChannel === PaymentChannel.Goodwill) {
          element = (
            <td className="refund-success">
              ${formatNumberWithDecimalPlace(agreementDetail.paymentTransaction.amount, 2)} Goodwill payment
            </td>
          )
        } else {
          element = (
            <td>
              Paid using {agreementDetail?.paymentTransaction?.creditCard?.cardType?.toUpperCase()} (ending&nbsp;
              {getCardEndingNumber(agreementDetail?.paymentTransaction)})
            </td>
          )
        }
      }
    }
  }
  return element
}

export const getBookingCost = (agreementDetail: AgreementsDetail) => {
  let cost: number = 0
  if (agreementDetail) {
    if (agreementDetail.refundTransaction) {
      if (agreementDetail.refundTransaction.transactionStatus === TransactionStatus.Success) {
        cost = Number(agreementDetail.paymentAmount) - Number(agreementDetail.refundTransaction.amount)
      }
      if (agreementDetail.refundTransaction.transactionStatus === TransactionStatus.Failed) {
        cost = agreementDetail.paymentAmount
      }
    } else {
      cost = agreementDetail.paymentAmount
    }
    if (
      agreementDetail.paymentTransaction &&
      agreementDetail.paymentTransaction.paymentChannel === PaymentChannel.Goodwill
    ) {
      cost = 0
    }
  }
  return cost
}
