import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import { format, getDate, getMonth, getYear, isEqual } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import addIcon from '../../../assets/svg/ws-ui-icon-add-white.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import closeIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { resetAggregateError } from '../../../common/aggregate-actions'
import {
  AGGREGATE_ACCESS_RESTRICTIONS,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS,
} from '../../../common/aggregate.types'
import { fetchAggregates, insertAggregate, updateAggregate, updateLifeCycle } from '../../../common/axios-action'
import { Lifecycle, LifecycleOnly } from '../../../common/types'
import BlockCancelConfirmationModal from '../../../components/model/BlockCancelConfirmationModel'
import ConfirmationModal from '../../../components/model/ConfirmationModel'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import {
  ACCESS_RESTRICTIONS_API_RESOURCE_PATH,
  AccessRestrictions,
  AccessRestrictionsFacility,
} from '../../../store/common/access-restrictions/types'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import AddBlockOutDate from './add-block-out-date'
import EditBlockOutDate from './edit-block-out-date'
import { ORDERS_FOR_ACCESS_RESTRICTIONS_POST_API_RESOURCE_PATH, OrdersForAccessRestrictions } from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    maxWidth: '204px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  noDataText: {
    textAlign: 'center',
  },
}))

interface IProps {}

const BlockOutDates: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [showAddBlockOutDate, setShowAddBlockOutDate] = useState<boolean>(false)
  const [showEditBlockOutDate, setShowEditBlockOutDate] = useState<boolean>(false)
  const [displayDelete, setDisplayDelete] = useState<boolean>(false)
  const [displayBlockOutCancelModal, setDisplayBlockOutCancelModal] = useState<boolean>(false)
  const [isOrderParkingAgreementsCancel, setIsOrderParkingAgreementsCancel] = useState<boolean>(false)
  const [isOrderParkingAgreementsCancelForAdd, setIsOrderParkingAgreementsCancelForAdd] = useState<boolean>(false)
  const [selectedIdForDelete, setSelectedIdForDelete] = useState<string>('')
  const [selectedBlockOut, setSelectedBlockOut] = useState<AccessRestrictions | null>(null)
  const [requestPayload, setRequestPayload] = useState<AccessRestrictions>()
  const [ordersForAccessRestrictionsCount, setOrdersForAccessRestrictionsCount] = useState<string>('')

  const dispatch = useDispatch()
  const {
    carParkTenancyList,
    departmentTeamsList,
    accessRestrictionsList,
    loadingList,
    updateLifeCycleSuccess,
    accessRestrictionsUpdated,
    accessRestrictionsInserted,
    accessRestrictionsReducerIdOnly,
    ordersForAccessRestrictionsInserted,
    ordersForAccessRestrictionsInserting,
    ordersForAccessRestrictionsIdOnly,
    accessRestrictionsError,
  }: any = useSelector((state: RootState) => ({
    carParkTenancyList: state.myListTenancyReducer.aggregates.values,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    accessRestrictionsList: state.accessRestrictionsReducer.aggregates.values,
    loadingList: state.accessRestrictionsReducer.loadingList,
    updateLifeCycleSuccess: state.accessRestrictionsReducer.updateLifeCycleSuccess,
    accessRestrictionsUpdated: state.accessRestrictionsReducer.updated,
    accessRestrictionsInserted: state.accessRestrictionsReducer.inserted,
    accessRestrictionsReducerIdOnly: state.accessRestrictionsReducer.idOnly,
    ordersForAccessRestrictionsInserted: state.ordersForAccessRestrictionsReducer.inserted,
    ordersForAccessRestrictionsInserting: state.ordersForAccessRestrictionsReducer.inserting,
    ordersForAccessRestrictionsIdOnly: state.ordersForAccessRestrictionsReducer.idOnly,
    accessRestrictionsError: state.accessRestrictionsReducer.error,
  }))
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  // delete block out's dates API Error
  useEffect(() => {
    cancelDeleteModal()
    if (accessRestrictionsError) {
      if (accessRestrictionsError.length > 0) {
        dispatch(setNotification(NotificationType.ERROR, [accessRestrictionsError[0].message]))
      }
      dispatch(resetAggregateError(AGGREGATE_ACCESS_RESTRICTIONS))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRestrictionsError])

  // For initially get AccessRestrictions and Tenancy
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<AccessRestrictions>(
          AGGREGATE_ACCESS_RESTRICTIONS,
          ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) +
            '?sortBy=startDate&size=100&endDateFrom=' +
            format(new Date(), 'yyyy-MM-dd'),
          null,
        ),
      )
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])
  // For refresh data after delete success
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && updateLifeCycleSuccess) {
      setDisplayDelete(false)
      dispatch(setNotification(NotificationType.INFO, ['Your bookings canceled successfully']))
      dispatch(
        fetchAggregates<AccessRestrictions>(
          AGGREGATE_ACCESS_RESTRICTIONS,
          ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) +
            '?sortBy=startDate&size=100&endDateFrom=' +
            format(new Date(), 'yyyy-MM-dd'),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, updateLifeCycleSuccess])

  // Add block out date modal handlers
  const openAddBlockOutDateModal = () => {
    setShowAddBlockOutDate(true)
  }
  const closeAddBlockOutDateModal = () => {
    setShowAddBlockOutDate(false)
  }
  const onAddBlockOutDateModalSave = () => {
    setShowAddBlockOutDate(false)
    dispatch(setNotification(NotificationType.INFO, ['A new blockout date has been saved.']))
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<AccessRestrictions>(
          AGGREGATE_ACCESS_RESTRICTIONS,
          ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) +
            '?sortBy=startDate&size=100&endDateFrom=' +
            format(new Date(), 'yyyy-MM-dd'),
          null,
        ),
      )
    }
  }
  const onAddBlockOutMultiCount = (requestPayload: AccessRestrictions) => {
    setRequestPayload(requestPayload)
    setShowAddBlockOutDate(false)
    setDisplayBlockOutCancelModal(true)
    setOrdersForAccessRestrictionsCount(ordersForAccessRestrictionsIdOnly.count.toString())
    setIsOrderParkingAgreementsCancelForAdd(true)
  }

  // Edit block out date modal handlers
  const openEditBlockOutDateModal = (blockOut: AccessRestrictions) => {
    setSelectedBlockOut(blockOut)
    setShowEditBlockOutDate(true)
  }
  const closeEditBlockOutDateModal = () => {
    setShowEditBlockOutDate(false)
  }
  const onEditBlockOutDateModalSave = () => {
    setShowEditBlockOutDate(false)
    dispatch(setNotification(NotificationType.INFO, ['Your data has been updated successfully.']))
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<AccessRestrictions>(
          AGGREGATE_ACCESS_RESTRICTIONS,
          ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) +
            '?sortBy=startDate&size=100&endDateFrom=' +
            format(new Date(), 'yyyy-MM-dd'),
          null,
        ),
      )
    }
  }
  const onEditBlockOutMultiCount = (requestPayload: AccessRestrictions) => {
    setRequestPayload(requestPayload)
    setShowEditBlockOutDate(false)
    setDisplayBlockOutCancelModal(true)
    setOrdersForAccessRestrictionsCount(ordersForAccessRestrictionsIdOnly.count.toString())
    setIsOrderParkingAgreementsCancelForAdd(false)
  }

  // Handler for delete data confirmation
  const cancelDeleteModal = () => {
    setDisplayDelete(false)
  }
  const confirmDeleteModal = () => {
    if (currentOperatorId && currentOrganisationId && selectedIdForDelete) {
      let lifecycleOnly: LifecycleOnly = { lifecycle: Lifecycle.Deleted }
      dispatch(
        updateLifeCycle<AccessRestrictions>(
          AGGREGATE_ACCESS_RESTRICTIONS,
          ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          selectedIdForDelete,
          lifecycleOnly,
        ),
      )
    }
  }

  // Handler for block out cancel modal data confirmation
  const cancelDisplayBlockOutCancelModal = () => {
    setIsOrderParkingAgreementsCancel(false)
    if (isOrderParkingAgreementsCancelForAdd) {
      if (currentOperatorId && currentOrganisationId && requestPayload) {
        dispatch(
          insertAggregate<AccessRestrictions>(
            AGGREGATE_ACCESS_RESTRICTIONS,
            ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            requestPayload,
          ),
        )
      }
    } else {
      if (currentOperatorId && currentOrganisationId && requestPayload) {
        dispatch(
          updateAggregate<AccessRestrictions>(
            AGGREGATE_ACCESS_RESTRICTIONS,
            ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            requestPayload,
          ),
        )
      }
    }
  }
  const confirmDisplayBlockOutCancelModal = () => {
    setIsOrderParkingAgreementsCancel(true)
    if (isOrderParkingAgreementsCancelForAdd) {
      if (currentOperatorId && currentOrganisationId && requestPayload) {
        dispatch(
          insertAggregate<AccessRestrictions>(
            AGGREGATE_ACCESS_RESTRICTIONS,
            ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            requestPayload,
          ),
        )
      }
    } else {
      if (currentOperatorId && currentOrganisationId && requestPayload) {
        dispatch(
          updateAggregate<AccessRestrictions>(
            AGGREGATE_ACCESS_RESTRICTIONS,
            ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            requestPayload,
          ),
        )
      }
    }
  }
  const abortDisplayBlockOutCancelModal = () => {
    setDisplayBlockOutCancelModal(false)
  }

  // After Save Restrictions API Response
  useEffect(() => {
    if (accessRestrictionsInserted && !showAddBlockOutDate && !showEditBlockOutDate) {
      if (isOrderParkingAgreementsCancel) {
        if (
          ordersForAccessRestrictionsIdOnly &&
          accessRestrictionsReducerIdOnly &&
          currentOperatorId &&
          currentOrganisationId
        ) {
          dispatch(
            insertAggregate<OrdersForAccessRestrictions>(
              AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS,
              ORDERS_FOR_ACCESS_RESTRICTIONS_POST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
                .replace(':carParkTenantId', currentOrganisationId)
                .replace(':carParkTenantAccessRestrictionId', accessRestrictionsReducerIdOnly.id),
              ordersForAccessRestrictionsIdOnly,
            ),
          )
        }
      } else {
        setDisplayBlockOutCancelModal(false)
        onAddBlockOutDateModalSave()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRestrictionsInserted])

  // After Update Restrictions API Response
  useEffect(() => {
    if (accessRestrictionsUpdated && !showAddBlockOutDate && !showEditBlockOutDate) {
      if (isOrderParkingAgreementsCancel) {
        if (ordersForAccessRestrictionsIdOnly && selectedBlockOut?.id && currentOperatorId && currentOrganisationId) {
          dispatch(
            insertAggregate<OrdersForAccessRestrictions>(
              AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS,
              ORDERS_FOR_ACCESS_RESTRICTIONS_POST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
                .replace(':carParkTenantId', currentOrganisationId)
                .replace(':carParkTenantAccessRestrictionId', selectedBlockOut?.id),
              ordersForAccessRestrictionsIdOnly,
            ),
          )
        }
      } else {
        setDisplayBlockOutCancelModal(false)
        onEditBlockOutDateModalSave()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRestrictionsUpdated])

  // After Calling Cancel Booking API Response
  useEffect(() => {
    if (ordersForAccessRestrictionsInserted && !showAddBlockOutDate && !showEditBlockOutDate) {
      if (isOrderParkingAgreementsCancelForAdd) {
        setDisplayBlockOutCancelModal(false)
        onAddBlockOutDateModalSave()
      } else {
        setDisplayBlockOutCancelModal(false)
        onEditBlockOutDateModalSave()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersForAccessRestrictionsInserted])

  // For on delete
  const onDelete = (id: string | null) => {
    if (id) {
      setSelectedIdForDelete(id)
      setDisplayDelete(true)
    }
  }
  // For format data to render
  const getFormattedDateRange = (startDate: string, endDate: string) => {
    let start = Date.parse(startDate)
    let end = Date.parse(endDate)
    if (isEqual(start, end)) {
      let result = format(start, 'd MMMM yyyy')
      return result
    } else {
      const startDay = getDate(start)
      const endDay = getDate(end)
      const startMonth = getMonth(start)
      const endMonth = getMonth(end)
      const startYear = getYear(start)
      const endYear = getYear(end)
      if (startYear === endYear) {
        if (startMonth === endMonth) {
          let result = format(start, ' MMMM yyyy')
          return startDay + ' - ' + endDay + result
        } else {
          return format(start, 'dd MMMM') + ' - ' + format(end, 'dd MMMM') + ' ' + format(start, 'yyyy')
        }
      } else {
        return format(start, 'd MMMM yyyy') + ' - ' + format(end, 'd MMMM yyyy')
      }
    }
  }
  const getFormattedTimeRange = (fromMinutes: number, toMinutes: number) => {
    function getTwoDigitNumber(number: number) {
      if (number < 10) {
        return '0' + number
      } else {
        return number
      }
    }
    function getMinutes(totalMinutes: number) {
      let hours = totalMinutes / 60
      let rHours = Math.floor(hours)
      let minutes = (hours - rHours) * 60
      let rMinutes = Math.round(minutes)
      if (rHours < 12) {
        return rHours + ':' + getTwoDigitNumber(rMinutes) + 'am'
      } else if (rHours === 12) {
        return rHours + ':' + getTwoDigitNumber(rMinutes) + 'pm'
      } else {
        return rHours - 12 + ':' + getTwoDigitNumber(rMinutes) + 'pm'
      }
    }
    if ((fromMinutes === 0 && toMinutes === 1440) || (fromMinutes === 0 && toMinutes === 0)) {
      return 'All Day'
    } else {
      let fromTime = getMinutes(fromMinutes)
      let toTime = getMinutes(toMinutes)
      return fromTime + ' - ' + toTime
    }
  }
  const getFormattedDepartmentAndTeam = (departmentIds: string[], teamIds: string[]) => {
    if (departmentIds.length === 0) {
      return teamIds.length + ' Teams'
    } else {
      let isAllDepartment: boolean = true
      departmentTeamsList.forEach((department: DepartmentTeamListItemDto) => {
        if (!departmentIds.includes(department.id)) {
          isAllDepartment = false
          return
        }
      })
      if (isAllDepartment) {
        return 'All Teams'
      } else {
        let teamCount: number = 0
        departmentIds.forEach((id: string) => {
          let department = departmentTeamsList.find((department: DepartmentTeamListItemDto) => department.id === id)
          if (department) {
            teamCount = teamCount + department.teams.length
          }
        })
        teamCount = teamCount + teamIds.length
        return teamCount + ' Teams'
      }
    }
  }
  const getLocation = (facilities: AccessRestrictionsFacility[]) => {
    if (facilities && facilities.length > 0) {
      if (facilities.length === 1) {
        let tenancy = carParkTenancyList.find((tenancy: Tenancy) => tenancy.id === facilities[0].facilityId)
        return tenancy?.name
      } else {
        return facilities.length + ' Facilities'
      }
    } else {
      return 'No Facilities Found'
    }
  }

  return (
    <>
      {showAddBlockOutDate && (
        <AddBlockOutDate
          showAddBlockOutDate={showAddBlockOutDate}
          closeAddBlockOutDateModal={closeAddBlockOutDateModal}
          onAddBlockOutDateModalSave={onAddBlockOutDateModalSave}
          onAddBlockOutMultiCount={onAddBlockOutMultiCount}
        />
      )}
      {showEditBlockOutDate && (
        <EditBlockOutDate
          showEditBlockOutDate={showEditBlockOutDate}
          closeEditBlockOutDateModal={closeEditBlockOutDateModal}
          onEditBlockOutDateModalSave={onEditBlockOutDateModalSave}
          selectedBlockOut={selectedBlockOut}
          onEditBlockOutMultiCount={onEditBlockOutMultiCount}
        />
      )}
      <ConfirmationModal
        display={displayDelete}
        cancelModel={cancelDeleteModal}
        confirmModel={confirmDeleteModal}
        title="Are you sure you want to delete blockout?"
        description=""
        cancelButtonName="Cancel"
        confirmButtonName="Delete"
      />

      <BlockCancelConfirmationModal
        display={displayBlockOutCancelModal}
        cancelModal={cancelDisplayBlockOutCancelModal}
        confirmModal={confirmDisplayBlockOutCancelModal}
        abortModal={abortDisplayBlockOutCancelModal}
        title="There are bookings during this block out period"
        description={
          'There are already ' +
          ordersForAccessRestrictionsCount +
          ' bookings made during this block out period. What would you like to do with those bookings?'
        }
        cancelButtonName="Leave bookings"
        confirmButtonName="Cancel bookings"
        inserting={ordersForAccessRestrictionsInserting}
      />

      <div className="page-content-wrapper">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <Grid container spacing={0} className="responsive-title-wrapper">
                <Grid item xs={12}>
                  <h2 className="on-demand-h2">
                    <a className="back-icon" href="/on-demand">
                      <img className="icon-back-arrow" src={backIcon} alt="img" />
                      Block out dates
                    </a>
                  </h2>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="responsive-content-wrapper">
                <Grid item xs={12}>
                  <p>Schedule dates when on demand parking will be unavailable.</p>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                <Grid item xs={12}>
                  <Button
                    className={classes.shuffleButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<img className="buttonIcon" src={addIcon} alt="img" />}
                    onClick={() => openAddBlockOutDateModal()}
                  >
                    Add block out date
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              <Grid item xs={12} className="content-panel">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3 className="padding-bottom-10">Upcoming dates</h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {loadingList ? (
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <CircularProgress color="primary" size={30} />
                      </Grid>
                    </Grid>
                  ) : accessRestrictionsList.length === 0 ? (
                    <Grid item xs={12}>
                      <div className="location-card cursor-auto">
                        <p className={classes.noDataText + ' location-title'}>No Upcoming Dates</p>
                      </div>
                    </Grid>
                  ) : (
                    accessRestrictionsList.length > 0 &&
                    carParkTenancyList.length > 0 &&
                    departmentTeamsList.length > 0 &&
                    accessRestrictionsList.map((data: AccessRestrictions) => (
                      <Grid item xs={12} key={data.id}>
                        <div className="location-card cursor-auto">
                          <p className="location-title cursor-pointer" onClick={() => openEditBlockOutDateModal(data)}>
                            {getFormattedDateRange(data.startDate, data.endDate)}
                          </p>
                          <p className="location-product-count">
                            <span className="icon-time">{getFormattedTimeRange(data.fromMinutes, data.toMinutes)}</span>
                            <span className="icon-teams">
                              {getFormattedDepartmentAndTeam(data.departmentIds, data.teamIds)}
                            </span>
                            <span className="icon-location">{getLocation(data.facilities)}</span>
                          </p>
                          <p className="close-card" onClick={() => onDelete(data.id)}>
                            <img className="close-x" src={closeIcon} alt="img" />
                          </p>
                        </div>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default BlockOutDates
