export const ROUTE_LOGIN = '/login'
export const ROUTE_RESET_PASSWORD = '/reset-password'
export const ROUTE_RESET_PASSWORD_EMAIL = '/reset-password-email'
export const ROUTE_RESET_PASSWORD_CONFIRM = '/reset-password-confirm'
export const ROUTE_VERIFY_EMAIL_CONFIRM = '/verify-email-confirm'

export const ROUTE_HOME = '/home'

export const ROUTE_SETTINGS = '/settings'
export const ROUTE_SETTINGS_MANAGE_USERS = 'manage-users'
export const ROUTE_SETTINGS_SELF_REGISTRATION = 'self-registration'
export const ROUTE_SETTINGS_USER_DETAILS = 'user-details'

export const ROUTE_ASSIGNED = '/assigned'
export const ROUTE_ASSIGNED_MANAGE_PARKERS = 'manage-parkers'
export const ROUTE_ASSIGNED_ASSIGN_PARKING = 'assign-parking'
export const ROUTE_ASSIGNED_MANAGE_BAY = 'manage-bay'
export const ROUTE_ASSIGNED_ASSIGN_PARKING_BAY_TYPE = 'assign-parking/bay-type'
export const ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT = 'manage-parkers/view-entitlement'
export const ROUTE_ASSIGNED_EDIT_ASSIGN_BAY = 'edit-assign-bay'

export const ROUTE_ON_DEMAND = '/on-demand'
export const ROUTE_ON_DEMAND_BLOCK_OUT_DATES = 'block-out-dates'
export const ROUTE_ON_DEMAND_PRODUCTS_CAPACITIES = 'product-capacities'
export const ROUTE_ON_DEMAND_PRODUCT_DETAIL = 'product-capacities/product-detail'
export const ROUTE_ON_DEMAND_TRANSACTION = 'transactions'
export const ROUTE_ON_DEMAND_TRANSACTION_DETAIL = 'transactions/transaction-detail'
export const ROUTE_ON_DEMAND_REFUND_TRANSACTION_DETAIL = 'transactions/refund-transaction-detail'
export const ROUTE_ON_DEMAND_REFUND_CONFIRMATION_DETAIL = 'transactions/refund-confirmation-detail'
export const ROUTE_ON_DEMAND_BOOK_PARKING = 'book-parking'
export const ROUTE_ON_DEMAND_CHANGE_BOOKING = 'change-booking'

export const ROUTE_GUEST = '/guest'
export const ROUTE_GUEST_BOOK_GUEST_PARKING = 'book-guest-parking'
export const ROUTE_GUEST_EDIT_GUEST_PARKING = 'edit-guest-parking'
export const ROUTE_GUEST_TRANSACTION = 'transactions'
export const ROUTE_GUEST_TRANSACTION_DETAIL = 'transactions/guest-transaction-detail'

export const ROUTE_UTILISATION = '/utilisation'
export const ROUTE_UTILISATION_DASHBOARD = 'dashboard'
export const ROUTE_UTILISATION_REPORT = 'report'

export const ROUTE_OPERATIONS = '/operations'
export const ROUTE_OPERATIONS_CONTROL_CENTRE = 'control-centre'
export const ROUTE_OPERATIONS_ON_DEMAND_BOOKING_SUMMARY = 'on-demand-booking-summary'
export const ROUTE_OPERATIONS_ASSIGNED_BOOKING_SUMMARY = 'assigned-booking-summary'
export const ROUTE_OPERATIONS_GUEST_BOOKING_SUMMARY = 'guest-booking-summary'
export const ROUTE_OPERATIONS_FORCE_GATE_OPEN = 'force-gate-open'

export const ROUTE_USERS = '/users'
export const ROUTE_USERS_MANAGE_USERS = 'manage-users'
export const ROUTE_USERS_BULK_UPLOADS = 'bulk-registrations'

export const ROUTE_TENANT_SELECTOR = '/tenant-selector'
