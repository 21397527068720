import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import React, { createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import './App.css'
import { resetReduxReducer } from './common/reset-redux'
import { OrganisationType } from './common/types'
import { getTenantConfigThunk } from './config/app/thunks'
import setUpAxios from './config/axios/axios'
import { setupDatadog } from './config/datadog/datadog'
import { setUpMapBox } from './config/mapbox'
import MainNavigation from './main-navigation'
import { fetchWhoAmIUser, setLoggedOn } from './pages/auth/action'
import { RootState } from './store'
import { setNotification } from './store/common/notifications/action'
import { NotificationType } from './store/common/notifications/types'

interface IProps {
  hostName: string
}

export const GitShaContext = createContext<string | undefined>('')

const App: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch()

  const { i18n } = useTranslation('', { useSuspense: false })

  useEffect(() => {
    i18n.changeLanguage('en')
  }, [i18n])

  const { tenantConfig, organisationType, error, loading } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
      organisationType: state.authReducer.currentUser?.organisationType,
      error: state.authReducer.error,
      loading: state.authReducer.loading,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (error) {
      const auth = getAuth()
      auth.signOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (organisationType) {
      if (organisationType !== OrganisationType.CarParkTenant && organisationType !== OrganisationType.Operator) {
        dispatch(setNotification(NotificationType.ERROR, ['User does not have sufficient access permission.']))
        const auth = getAuth()
        auth.signOut()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationType, loading])

  useEffect(() => {
    console.info('calling getTenantConfig', props.hostName)
    dispatch(getTenantConfigThunk(props.hostName))
  }, [dispatch, props.hostName]) // only called once

  useEffect(() => {
    function handleStateChange(user: firebase.User | null) {
      if (user) {
        dispatch(setLoggedOn(user))
        dispatch(fetchWhoAmIUser())
      } else {
        resetReduxReducer(dispatch)
        localStorage.clear()
      }
    }

    if (tenantConfig != null) {
      console.info('Applying tenant config', tenantConfig)
      const firebaseConfig = tenantConfig.firebase
      console.info('Applying firebase config', firebaseConfig)
      firebase.initializeApp(firebaseConfig)
      firebase.auth().tenantId = tenantConfig.firebaseAuth.tenantId
      firebase.auth().onAuthStateChanged((user) => handleStateChange(user))
      setUpAxios()
      setUpMapBox(tenantConfig.mapBox)
      if (tenantConfig.datadog) {
        setupDatadog({
          tenancyCode: tenantConfig.tenant.tenancyCode,
          tenantCode: tenantConfig.tenant.code,
          environment: tenantConfig.config.environment,
          datadogConfig: tenantConfig.datadog,
          gitSha: process.env.REACT_APP_GIT_SHA,
        })
        console.debug('datadog init complete')
      }
    }
  }, [tenantConfig, dispatch])

  console.info('Git sha: ' + process.env.REACT_APP_GIT_SHA)

  return (
    <GitShaContext.Provider value={process.env.REACT_APP_GIT_SHA}>
      <MainNavigation />
    </GitShaContext.Provider>
  )
}

export default App
