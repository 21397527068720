import React from 'react'
import { Container } from '@mui/material'
import Footer from '../footer'
import { shallowEqual, useSelector } from 'react-redux'
import { selectAuthError, selectIsLoggedOn } from '../../pages/auth/types'
import { Navigate, Outlet } from 'react-router-dom'
import PrivateHeader from './nav/private-header'
import { useLocation } from 'react-router'
import Seo from '../../components/seo'
import { selectLoading } from '../../store/common/loading/types'
import Loading from '../../components/ui/Loading'
import DisplayNotification from '../../store/common/notifications/DisplayNotification'

interface IProps {
  children?: React.ReactNode
}

const PrivateLayout: React.FC<IProps> = (props) => {
  const { children } = props
  const location = useLocation()
  const [isInitialized, isLoggedIn] = useSelector(selectIsLoggedOn, shallowEqual)
  const error = useSelector(selectAuthError)
  const loading = useSelector(selectLoading)

  return (
    <>
      <Seo />

      {loading ? (
        <Loading />
      ) : !error ? (
        isInitialized ? (
          isLoggedIn ? (
            <>
              <Container className="adminBG">
                <DisplayNotification />
                <PrivateHeader isHideBrandingImage={false} />
                <Outlet />
                {children}
              </Container>
              <Footer isPrivate={true} />
            </>
          ) : (
            <Navigate to="/login" replace state={{ from: location }} />
          )
        ) : (
          <Loading />
        )
      ) : (
        <Navigate to="/error" replace state={{ from: location }} />
      )}
    </>
  )
}
export default PrivateLayout
