import React from 'react'
import { Container } from '@mui/material'
import Footer from '../footer'
import PublicHeader from './nav/public-header'
import Seo from '../../components/seo'
import { Outlet } from 'react-router-dom'
import DisplayNotification from '../../store/common/notifications/DisplayNotification'

interface IProps {
  children?: React.ReactNode
}

const PublicLayout: React.FC<IProps> = (props) => {
  const { children } = props

  return (
    <>
      <Seo />
      <Container className="loginBG">
        <DisplayNotification />
        <PublicHeader />
        <Outlet />
        {children}
        <Footer />
      </Container>
    </>
  )
}
export default PublicLayout
