import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useState } from 'react'

interface IProps {
  departmentTeamsFormattedList: any[]
  checkIsButtonDisabled: () => void
  isAll?: boolean
  isAllDisabled?: boolean
}

const MultiDepartmentCheckbox: React.FC<IProps> = ({
  departmentTeamsFormattedList,
  checkIsButtonDisabled,
  isAll,
  isAllDisabled,
}: IProps) => {
  const [allDepartmentTeams, setAllDepartmentTeams] = useState<boolean>(isAll ? isAll : false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const handleChangeAllDepartmentTeams = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      departmentTeamsFormattedList.forEach((department: any) => {
        department.checked = true
        department.teams.forEach((team: any) => {
          team.checked = true
        })
      })
    } else {
      departmentTeamsFormattedList.forEach((department: any) => {
        department.checked = false
        department.teams.forEach((team: any) => {
          team.checked = false
        })
      })
    }
    setAllDepartmentTeams(event.target.checked)
    checkIsButtonDisabled()
  }
  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    let departmentId = event.target.name
    let department = departmentTeamsFormattedList.find((department: any) => department.id === departmentId)
    department.checked = event.target.checked
    if (event.target.checked) {
      department.teams.forEach((team: any) => {
        team.checked = true
      })
    } else {
      department.teams.forEach((team: any) => {
        team.checked = false
      })
      setAllDepartmentTeams(false)
    }
    setRefresh(!refresh)
    checkForAllDepartmentTeams()
    checkIsButtonDisabled()
  }
  const handleChangeTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    let departmentId = event.target.name.split('/')[0]
    let teamId = event.target.name.split('/')[1]

    let department = departmentTeamsFormattedList.find((department: any) => department.id === departmentId)
    let team = department.teams.find((team: any) => team.id === teamId)
    team.checked = event.target.checked

    if (!event.target.checked) {
      department.checked = false
      setAllDepartmentTeams(false)
    } else {
      let teams = department.teams.filter((team: any) => team.checked === true)
      if (teams.length === department.teams.length) {
        department.checked = true
      }
    }

    setRefresh(!refresh)
    checkForAllDepartmentTeams()
    checkIsButtonDisabled()
  }
  const checkForAllDepartmentTeams = () => {
    let departments = departmentTeamsFormattedList.filter((department: any) => department.checked === true)
    if (departments.length === departmentTeamsFormattedList.length) {
      setAllDepartmentTeams(true)
    } else {
      setAllDepartmentTeams(false)
    }
  }
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox disabled={isAllDisabled} checked={allDepartmentTeams} onChange={handleChangeAllDepartmentTeams} />
        }
        label="All departments &amp; teams"
      />
      {refresh}
      {departmentTeamsFormattedList &&
        departmentTeamsFormattedList.map((department: any) => (
          <FormGroup key={department.id}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isAllDisabled}
                  name={department.id}
                  checked={department.checked}
                  onChange={handleChangeDepartment}
                />
              }
              label={department.name}
            />
            {department.teams.map((team: any) => (
              <FormControlLabel
                key={team.id}
                className="child-checkbox"
                control={
                  <Checkbox
                    disabled={isAllDisabled}
                    name={department.id + '/' + team.id}
                    checked={team.checked}
                    onChange={handleChangeTeam}
                  />
                }
                label={team.name}
              />
            ))}
          </FormGroup>
        ))}
    </>
  )
}

export default MultiDepartmentCheckbox
