import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

// User Interface
export interface Member {
  id: string
  firstName: string
  lastName: string
  name: string
  email: string
}

export type MemberState = AggregateState<Member>
export type MemberStateActionTypes = AggregateActionTypes<Member>
export const MEMBER_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/list'
