import axios from 'axios'
import { GateDeliveryStatus, UpdateEntryStatusRequest, UpdateEntryStatusResponse } from './types'
import { AUTHORIZATION_HEADER, BEARER_PREFIX } from '../../../config/constants'

export const updateEntryStatus = async (
  updateEntryStatusRequest: UpdateEntryStatusRequest,
  url: string,
  coreUrl: string,
  token: string,
): Promise<UpdateEntryStatusResponse> => {
  let response: UpdateEntryStatusResponse = {
    gateDeliveryStatus: null,
  }
  await axios({
    method: 'PATCH',
    url: coreUrl + url,
    data: updateEntryStatusRequest,
    headers: { [AUTHORIZATION_HEADER]: BEARER_PREFIX + token },
  })
    .then((resp) => {
      let data = resp.data as UpdateEntryStatusResponse
      response = data
    })
    .catch((err) => {
      response = {
        gateDeliveryStatus: GateDeliveryStatus.InternalError,
      }
    })
  return response
}
