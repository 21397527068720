import { Box, Button, Container, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router'
import assignParkingIcon from '../../assets/svg/ws-b2b-icon-assign-parking-2.svg'
import staffIcon from '../../assets/svg/ws-b2b-icon-staff.svg'
import reservedIcon from '../../assets/svg/ws-b2b-icon-reserved-2.svg'
import { ROUTE_ASSIGNED_ASSIGN_PARKING, ROUTE_ASSIGNED_MANAGE_BAY, ROUTE_ASSIGNED_MANAGE_PARKERS } from '../util/routes'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    padding: '7px 22px',
  },
}))

interface IProps {}

const Assigned: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <>
      <div id="main" className="section-entry">
        <Container className="page-header">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <h1 className="settings-h1 centered-heading">Assigned parking</h1>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container className="page-content">
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Box className="section-card" width="300px !important">
              <div className="icon-box-wrapper">
                <img className="assigned-manage-parkers" src={staffIcon} alt="Manage Users" />
              </div>
              <h2>Manage parkers</h2>
              <p>Manage your parkers, including changing or removing assignments.</p>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTE_ASSIGNED_MANAGE_PARKERS)}
              >
                Manage
              </Button>
            </Box>

            <Box className="section-card" width="300px !important">
              <div className="icon-box-wrapper">
                <img className="assigned-assign-parking" src={assignParkingIcon} alt="Self Registration" />
              </div>
              <h2>Assign parking</h2>
              <p>Assign a bay to one or more users.</p>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTE_ASSIGNED_ASSIGN_PARKING)}
              >
                Assign now
              </Button>
            </Box>

            <Box className="section-card" width="300px !important">
              <div className="icon-box-wrapper">
                <img className="assigned-assign-parking" src={reservedIcon} alt="Self Registration" />
              </div>
              <h2>Manage bay</h2>
              <p>Manage your bays, including searching bays and managing bay usage.</p>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTE_ASSIGNED_MANAGE_BAY)}
              >
                Manage bay
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Assigned
