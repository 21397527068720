import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CircularProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import closeIcon from '../../assets/svg/ws-ui-icon-close-x-black.svg'
import { AGGREGATE_GATE_LIST_ITEM } from '../../common/aggregate.types'
import { fetchAggregates } from '../../common/axios-action'
import { selectCurrentOperatorId, selectShuffleUrl } from '../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../pages/auth/types'
import { RootState } from '../../store'
import { assistedScheduledAccesses } from '../../store/common/assisted-scheduled-accesses/scheduled-accesses'
import {
  ASSISTED_SCHEDULED_ACCESSES_API_RESOURCE_PATH,
  ScheduledAccessesRequest,
} from '../../store/common/assisted-scheduled-accesses/types'
import { GateListItem, TENANCY_GATE_LIST_API_RESOURCE_PATH } from '../../store/common/gates-list/types'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { GateDeliveryStatus } from '../../store/common/update-entry-status/types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    boxShadow: 'none',
  },
  shuffleButtonModal: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ff0000',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleButtonLeft: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '160px',
  },
  shuffleButtonRight: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '140px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    width: '485px',
    maxWidth: '485px',
    textAlign: 'left',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
}))

interface IProps {
  display: boolean
  handleClose: () => void
  afterGateSuccess: () => void
  facilityId: string
  agreementId: string
}

const ControlCentreOpenGateModal: React.FC<IProps> = (props) => {
  const { display, handleClose, afterGateSuccess, facilityId, agreementId } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const shuffleUrl = useSelector(selectShuffleUrl)

  const [token, setToken] = useState<string>('')
  const [accessPoint, setAccessPoint] = useState<string>('')
  const [reasonType, setReasonType] = useState<string>('')
  const [note, setNote] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const { gateList } = useSelector((state: RootState) => ({
    gateList: state.gateListItemReducer.aggregates.values,
  }))

  // get token
  useEffect(() => {
    if (!token) {
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const token = await firebase.auth().currentUser!.getIdToken()
    setToken(token)
  }

  // Get gate list
  useEffect(() => {
    if (facilityId && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<GateListItem>(
          AGGREGATE_GATE_LIST_ITEM,
          TENANCY_GATE_LIST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', facilityId),
          null,
        ),
      )
    }
  }, [dispatch, facilityId, currentOperatorId, currentOrganisationId])

  const handleChangeAccessPoint = (event: SelectChangeEvent) => {
    setAccessPoint(event.target.value)
  }

  const handleChangeReasonType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonType((event.target as HTMLInputElement).value)
  }

  const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote((event.target as HTMLInputElement).value)
  }

  const onClickCancel = () => {
    setAccessPoint('')
    setReasonType('')
    setNote('')
    handleClose()
  }

  const onOpenGate = async () => {
    if (
      agreementId &&
      facilityId &&
      accessPoint &&
      reasonType &&
      shuffleUrl &&
      token &&
      currentOperatorId &&
      currentOrganisationId
    ) {
      let payload: ScheduledAccessesRequest = {
        agreementId: agreementId,
        accessPointId: accessPoint,
        reasonType: reasonType,
        note: note,
      }
      let assistedScheduledAccessesUrl = ASSISTED_SCHEDULED_ACCESSES_API_RESOURCE_PATH.replace(
        ':operatorId',
        currentOperatorId,
      )
        .replace(':carParkTenantId', currentOrganisationId)
        .replace(':carParkTenancyId', facilityId)
      setLoading(true)
      let response = await assistedScheduledAccesses(payload, assistedScheduledAccessesUrl, shuffleUrl, token)
      setLoading(false)
      if (response.gateDeliveryStatus === GateDeliveryStatus.Success || response.gateDeliveryStatus === null) {
        let accessPointName = gateList.find((gate: GateListItem) => gate.accessPointId === accessPoint)?.accessPointName
        onClickCancel()
        afterGateSuccess()
        if (accessPointName) {
          dispatch(setNotification(NotificationType.INFO, [accessPointName + ' has been opened.']))
        } else {
          dispatch(setNotification(NotificationType.INFO, ['Gate has been opened.']))
        }
      }
      if (
        response.gateDeliveryStatus === GateDeliveryStatus.Failed ||
        response.gateDeliveryStatus === GateDeliveryStatus.InternalError
      ) {
        onClickCancel()
        dispatch(
          setNotification(NotificationType.ERROR, [
            'There was a problem opening the gate. Please contact Wilson Parking for support.',
          ]),
        )
      }
    }
  }

  return (
    <>
      <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'} style={{ zIndex: 1001, position: 'absolute' }}>
        <Box className={classes.formModal}>
          <Container maxWidth="lg" disableGutters={true} className="form-modal-styles change-status-modal">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <img className="icon-close-modal mobile-only" src={closeIcon} alt="close" onClick={onClickCancel} />
                <h3 className="modal-h3 larger-text">Open car park gate</h3>
                <p>
                  You can open a gate remotely for this user.
                  <br />
                  Their entry status will also be updated.
                </p>
                <div className="gate-select">
                  <InputLabel className="select-heading">Which gate is the user waiting at?</InputLabel>
                  <Select
                    className="shuffle-select"
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    value={accessPoint}
                    onChange={handleChangeAccessPoint}
                    variant="outlined"
                    margin="dense"
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" className="selectBold display-none">
                      Select Gate
                    </MenuItem>
                    {gateList
                      .sort((a, b) =>
                        a.accessPointName > b.accessPointName ? 1 : b.accessPointName > a.accessPointName ? -1 : 0,
                      )
                      .map((gate: GateListItem) => {
                        return (
                          <MenuItem key={gate.id} value={gate.accessPointId}>
                            {gate.accessPointName}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </div>
                <div className="gate-radios">
                  <div className="gate-radio-wrapper">
                    <h4 className="reason-header">Select a reason</h4>
                    <FormControl component="fieldset">
                      <RadioGroup
                        className="modal-checkboxes-gate"
                        value={reasonType}
                        onChange={handleChangeReasonType}
                      >
                        <div className="left-column">
                          <FormControlLabel
                            value="AccessHardwareIssue"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Access Hardware Issue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="NetworkIssue"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Network Issue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="Overstay"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Overstay"
                            labelPlacement="end"
                          />
                        </div>
                        <div className="right-column">
                          <FormControlLabel
                            value="MobileDeviceIssue"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Mobile Device Issue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="OutOfSync"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Out of Sync"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="Other"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Other"
                            labelPlacement="end"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="gate-select" style={{ marginBottom: 0 }}>
                  <InputLabel className="select-heading">
                    Add optional notes <span className="optional-text">(optional)</span>
                  </InputLabel>
                  <TextField
                    name="note"
                    InputProps={{ className: 'shuffleInput' }}
                    InputLabelProps={{ className: 'shuffleLabel' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={note}
                    onChange={handleChangeNote}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '20px' }} className="anchored-wrapper">
              <Grid item xs={6} className="desktop-only">
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    className={classes.shuffleButtonModal}
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{ width: '160px' }}
                    onClick={onClickCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6} className="mobile-fullwidth-button">
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    className={classes.shuffleButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ width: '160px' }}
                    disabled={!accessPoint || !reasonType}
                    onClick={onOpenGate}
                  >
                    {loading ? <CircularProgress style={{ color: '#FFFFFF', width: 29, height: 29 }} /> : 'Open gate'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
      {display && (
        <style>{`
        #footer-text {
          visibility: hidden;
        }
        @media only screen and (max-width: 767px) {
          .modalOverlayBG .MuiBox-root {
            min-height: auto !important;
          }
        }
      `}</style>
      )}
    </>
  )
}
export default ControlCentreOpenGateModal
