import { isBefore, format } from 'date-fns'
import { MUIDatepickerError } from '../../common/types'
import { DATE_IS_INVALID, END_DATE_MUST_BE_LATER_THAN_START_DATE } from '../../config/constants'

export const formatDate = (date: string | null | undefined, dateFormat: string) => {
  if (date) {
    return format(Date.parse(date), dateFormat)
  } else {
    return 'Never'
  }
}

export const formatLastActiveDate = (date: string | null | undefined, dateFormat: string) => {
  if (date) {
    return format(Date.parse(date), dateFormat)
  } else {
    return 'Never'
  }
}

export const formatWithTimeZone = (timeStamp: number | null | undefined, dateFormat: string, timeZone?: string) => {
  if (timeStamp && timeZone && format) {
    return format(timeStampToDate(timeStamp), dateFormat)
  } else if (timeStamp && format) {
    return format(timeStamp, dateFormat)
  } else {
    return 'Never'
  }
}

export const formatWithTimeZoneNoNever = (
  timeStamp: number | null | undefined,
  dateFormat: string,
  timeZone?: string,
) => {
  if (timeStamp && timeZone && format) {
    return format(timeStampToDate(timeStamp), dateFormat)
  } else if (timeStamp && format) {
    return format(timeStamp, dateFormat)
  } else {
    return ''
  }
}

export const formatAMPM = (minutesIn: number | null) => {
  if (minutesIn) {
    var hours = Math.floor(minutesIn / 60)
    var minutes = minutesIn % 60
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12
    let minStr = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minStr + ' ' + ampm
    return strTime
  } else {
    return ''
  }
}

export const getFirstName = (name: string) => {
  if (name) {
    let firstName = name.split(' ')[0]
    return firstName
  } else {
    return null
  }
}

export const getLastName = (name: string) => {
  if (name) {
    let firstName = name.split(' ')[0]
    let lastName = name.substring(firstName.length).trim()
    return lastName
  } else {
    return null
  }
}

export const searchParams = (searchTerm: string) => {
  let firstName: string | null = null
  let lastName: string | null = null
  let name: string | null = null

  if (searchTerm) {
    let nameSplit = searchTerm.split(' ')
    if (nameSplit.length > 1) {
      firstName = getFirstName(searchTerm) ? getFirstName(searchTerm) : null
      lastName = getLastName(searchTerm) ? getLastName(searchTerm) : null
      if (!firstName && !lastName) {
        name = searchTerm
      } else {
        if (firstName && firstName.length > 1 && (lastName === null || (lastName && lastName.length <= 1))) {
          name = searchTerm.trim()
          firstName = null
          lastName = null
        } else if (firstName && firstName.length === 1 && lastName && lastName.length > 1) {
          name = searchTerm
          firstName = null
          lastName = null
        }
      }
    } else {
      if (searchTerm.length > 1) {
        name = searchTerm.trim()
      }
    }
  }

  return { firstName, lastName, name }
}

export const timeStampToDate = (secs: number): Date => {
  var t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs)
  return t
}

export const getDateWithoutTime = (date: string): string => {
  const newDate = new Date(date).toISOString()
  const updatedDate = newDate.slice(0, newDate.indexOf('T') + 1) + '00:00:00'
  return updatedDate
}

export const getMinutesFromMidnight = (date: Date): number => {
  const newDate = new Date(date)
  const hours = newDate.getHours()
  const minutes = newDate.getMinutes()
  return hours * 60 + minutes
}

export const getMinDateForEndDate = (startDate: string | Date | null) => {
  if (startDate) {
    let start = new Date(startDate).setHours(0, 0, 0, 0)
    let today = new Date().setHours(0, 0, 0, 0)
    if (isBefore(start, today)) {
      return new Date()
    } else {
      return new Date(startDate)
    }
  } else {
    return new Date()
  }
}

export const getHoursFromTotalMinutes = (totalMinutes: number) => {
  if (totalMinutes === 0) {
    return 0
  } else {
    let hours = totalMinutes / 60
    let rHours = Math.floor(hours)
    return rHours
  }
}

export const getMinuteFromTotalMinutes = (totalMinutes: number) => {
  if (totalMinutes === 0) {
    return 0
  } else {
    let hours = totalMinutes / 60
    let rHours = Math.floor(hours)
    let minutes = (hours - rHours) * 60
    let rMinutes = Math.round(minutes)
    return rMinutes
  }
}

// this custom function use to get AM/PM time
export const getFormattedTimeRange = (minutes: number) => {
  function getTwoDigitNumber(number: number) {
    if (number < 10) {
      return '0' + number
    } else {
      return number
    }
  }
  const getMinutes = (totalMinutes: number) => {
    let hours = totalMinutes / 60
    let rHours = Math.floor(hours)
    let minutes = (hours - rHours) * 60
    let rMinutes = Math.round(minutes)
    if (rHours < 12) {
      rHours = rHours === 0 ? 12 : rHours
      return rHours + ':' + getTwoDigitNumber(rMinutes) + ' am'
    } else if (rHours === 12) {
      return rHours + ':' + getTwoDigitNumber(rMinutes) + ' pm'
    } else {
      return rHours - 12 + ':' + getTwoDigitNumber(rMinutes) + ' pm'
    }
  }
  let fromTime = getMinutes(minutes)
  return fromTime
}

export const formatPhoneNumber = (phoneNumber: string | undefined): string | null => {
  if (phoneNumber) {
    //Filter only numbers from the input
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    //Check if the input is of correct length
    const match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/)
    if (match) {
      return match[1] + ' ' + match[2] + ' ' + match[3]
    }
  }
  return null
}

// convert 1:12am to Date
export const getDateFormAPTime = (time: string): Date | null => {
  if (time) {
    let splitTime = time.split(':')
    let hour = splitTime[0]
    let minute = splitTime[1].substring(0, 2)
    let newDate = new Date().setHours(parseFloat(hour), parseInt(minute), 0, 0)
    return new Date(newDate)
  } else {
    return null
  }
}
// convert Date to 1:12am
export const getAPTimeFormDate = (date: Date): string => {
  if (date) {
    let formattedDate = format(date, 'H:mmaaa')
    return formattedDate
  } else {
    return ''
  }
}

export const getFormattedErrorMessageForDate = (message: string): string => {
  switch (message) {
    case MUIDatepickerError.InvalidDate:
      return DATE_IS_INVALID
    case MUIDatepickerError.MinDate:
      return END_DATE_MUST_BE_LATER_THAN_START_DATE
    default:
      return ''
  }
}

export const getDateAndTimeFormat = (date: Date, time: Date): string => {
  let dateTime: string = ''
  if (date && time) {
    dateTime = format(date, 'yyyy-MM-dd') + 'T' + format(time, 'HH:mm:ss')
  }
  return dateTime
}

export const getWelcomeMessage = (userName: string): string => {
  const currentTime = new Date().getHours()
  let greetingText = ''
  if (currentTime < 12) {
    greetingText = 'Morning'
  } else if (currentTime < 18) {
    greetingText = 'Afternoon'
  } else {
    greetingText = 'Evening'
  }
  let userFirstName: string = ''
  if (userName && userName.length > 0) {
    userFirstName = userName.split(' ')[0]
  }
  return greetingText + ' ' + userFirstName
}

export const getRandomColor = (): string => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}
