import { AggregateState, Lifecycle, OrganisationType } from '../../../../../common/types'
import { AggregateActionTypes } from '../../../../../common/aggregate.types'

export interface MemberAutoInvitation {
  id: string | null
  code: string | null
  clientRef: string | null
  name: string | null
  invitationCode: string | null
  invitationUrl: string | null
  organisationType: OrganisationType | null
  operatorId: string | null
  autoApprove: boolean | null
  lifecycle: Lifecycle | null
  autoApproveStr: string | null
  carParkTenantId: string | null
}

export interface MemberAutoInvitationRequest {
  operatorId: string
  carParkTenantId: string
  lifecycle: Lifecycle
}

export type MemberAutoInvitationState = AggregateState<MemberAutoInvitation>
export type MemberAutoInvitationActionTypes = AggregateActionTypes<MemberAutoInvitation>

export const AUTO_INVITATION_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/auto-invitations'
