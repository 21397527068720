import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '440px',
    maxHeight: '400px',
    textAlign: 'center',
  },
}))

interface IProps {
  display: boolean
  cancelModel: () => void
  confirmModel: () => void
}

const UnsavedDataConfirmationModel: React.FC<IProps> = (props) => {
  const { display, cancelModel, confirmModel } = props

  const classes = useStyles()
  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-h3 larger-text">You have unsaved changes</h3>
              <p>Are you sure you want to leave without saving?</p>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '100px' }}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={cancelModel}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={confirmModel}
                >
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default UnsavedDataConfirmationModel
