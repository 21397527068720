import React, { ReactNode } from 'react'
import {
  FormControl,
  FormControlProps,
  FormHelperTextProps,
  InputLabel,
  InputLabelProps,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import { ErrorMessage, ShowErrorFunc, showErrorOnChange, useFieldForErrors } from './Util'
import { Field, FieldProps } from 'react-final-form'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DepartmentTeamList } from '../../store/common/departments-teams/types'

export interface SelectProps extends Partial<Omit<MuiSelectProps, 'onChange'>> {
  name: string
  label?: ReactNode
  required?: boolean
  multiple?: boolean
  helperText?: string
  fieldProps?: Partial<FieldProps<any, any>>
  formControlProps?: Partial<FormControlProps>
  inputLabelProps?: Partial<InputLabelProps>
  formHelperTextProps?: Partial<FormHelperTextProps>
  showError?: ShowErrorFunc
  menuItemProps?: Partial<MenuItemProps>
  data: DepartmentTeamList[]
  children?: ReactNode
}

export function DepartmentTeamSelect(props: SelectProps) {
  const {
    name,
    label,
    data,
    children,
    required,
    multiple,
    helperText,
    fieldProps,
    inputLabelProps,
    formControlProps,
    formHelperTextProps,
    menuItemProps,
    showError = showErrorOnChange,
    ...restSelectProps
  } = props

  if (!data && !children) {
    throw new Error('Please specify either children or data as an attribute.')
  }

  const { variant } = restSelectProps
  const field = useFieldForErrors(name)
  const isError = showError(field)

  return (
    <Field
      name={name}
      render={({ input: { name, value, onChange, ...restInput } }) => {
        // prevents an error that happens if you don't have initialValues defined in advance
        const finalValue = multiple && !value ? [] : value
        const labelId = `select-input-${name}`

        let selected: DepartmentTeamList | undefined = data.find((row) => row.id === value)
        let displayValue = value

        if (selected) {
          if (selected.departmentId) {
            let department: DepartmentTeamList | undefined = data.find((row) => row.id === selected?.departmentId)
            if (department) {
              displayValue = department.name + ' -> ' + selected.name
            } else {
              displayValue = selected.name
            }
          } else {
            displayValue = selected.name
          }
        } else {
          displayValue = 'Please Select'
        }

        return (
          <FormControl required={required} error={isError} fullWidth={true} variant={variant} {...formControlProps}>
            {!!label && (
              <InputLabel id={labelId} {...inputLabelProps} className="select-heading">
                {label}
              </InputLabel>
            )}
            <MuiSelect
              className="shuffle-select"
              IconComponent={ExpandMoreIcon}
              name={name}
              value={finalValue}
              onChange={onChange}
              multiple={multiple}
              labelId={labelId}
              renderValue={(value) => (displayValue ? displayValue : value)}
              inputProps={{ required, ...restInput }}
              {...restSelectProps}
            >
              {data
                ? data.map((row) => (
                    <MenuItem key={row.id} value={row.id} className={row.isDepartment ? 'selectTitle' : 'selectBullet'}>
                      {!row.isDepartment ? '• ' : ''}
                      {row.name}
                    </MenuItem>
                  ))
                : children}
            </MuiSelect>
            <ErrorMessage
              showError={isError}
              meta={field.meta}
              formHelperTextProps={formHelperTextProps}
              helperText={helperText}
            />
          </FormControl>
        )
      }}
      {...fieldProps}
    />
  )
}
