import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import reservedIcon from '../../../assets/svg/ws-b2b-icon-reserved-2.svg'
import tickIcon from '../../../assets/svg/ws-sml-icon-tick-wht.svg'
import warningIcon from '../../../assets/svg/ws-ui-icon-warning-critical-red.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import { AGGREGATE_MY_LIST_TENANCIES, ASSIGN_BAY_AGGREGATE_REQUEST } from '../../../common/aggregate.types'
import { fetchAggregates, insertAggregate } from '../../../common/axios-action'
import LoadingInDiv from '../../../components/ui/Loading/LoadingInDiv'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import {
  ASSIGN_BAY_API_RESOURCE_PATH,
  AllocationsInterface,
  AssignParkingBay,
  Bay,
  ReservedUnreservedRequestBodyInterface,
  ZonesInterface,
} from '../../assigned/assigned-parking/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { getDateAndTimeFormat } from '../../util/util'
import { BayAssignment, GuestEntitlement } from '../types'
import {
  DateAndTimeInterval,
  DateAndTimeIntervalError,
  END_DATE_IS_REQUIRED,
  END_TIME_IS_REQUIRED,
  GUEST_BAY_TYPE_ACCESS,
  GUEST_BAY_TYPE_RESERVED,
  GUEST_BAY_TYPE_UNRESERVED,
  START_DATE_IS_REQUIRED,
  START_TIME_IS_REQUIRED,
} from './types'

const useStyles = makeStyles((theme) => ({
  reservedButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    backgroundColor: '#009273',
    fontSize: '0.875rem',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
    width: '120px',
    height: '38px',
  },
  reservedButtonSmlOL: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#525252',
    backgroundColor: '#ffffff',
    fontSize: '0.875rem',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
    width: '120px',
    height: '38px',
    border: '1px solid #979797',
  },
  dropDownFontWeight: {
    fontWeight: '500',
  },
  dropDownFontWeightLight: {
    fontWeight: '400',
  },
}))

interface IProps {
  dateAndTimeInterval: DateAndTimeInterval
  dateAndTimeIntervalError: DateAndTimeIntervalError
  isValidDateAndTimeSelection: boolean
  setDateAndTimeIntervalError: Dispatch<SetStateAction<DateAndTimeIntervalError>>
  setIsValidBaySelection: Dispatch<SetStateAction<boolean>>
  setBayAssignment: Dispatch<SetStateAction<BayAssignment | null>>
  isEdit: boolean
  isEntryStatusOnSite: boolean
  setIsParkingAvailableForOnSite: Dispatch<SetStateAction<boolean>>
  guestEntitlementData: GuestEntitlement | null
}

const GuestBayType: React.FC<IProps> = ({
  dateAndTimeInterval,
  dateAndTimeIntervalError,
  isValidDateAndTimeSelection,
  setDateAndTimeIntervalError,
  setIsValidBaySelection,
  setBayAssignment,
  isEdit,
  isEntryStatusOnSite,
  setIsParkingAvailableForOnSite,
  guestEntitlementData,
}: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [bayType, setBayType] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [level, setLevel] = useState<string>('')
  const [zone, setZone] = useState<string>('')
  const [levelList, setLevelList] = useState<AllocationsInterface[]>([])
  const [zoneList, setZoneList] = useState<ZonesInterface[]>([])
  const [bays, setBays] = useState<Bay[]>([])
  const [unZonedAvailableBayCount, setUnZonedAvailableBayCount] = useState<number>()
  const [selectedBay, setSelectedBay] = useState<Bay | null>(null)
  const [lastSelectedBayId, setLastSelectedBayId] = useState<string | null>(null)
  const [isNoBaysAvailableMessage, setIsNoBaysAvailableMessage] = useState<boolean>(false)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const { tenancies } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
  }))
  const { allocationAndZoneDetail, allocationAndZoneDetailInserting }: any = useSelector((state: RootState) => ({
    allocationAndZoneDetail: state.assignBayReducer.idOnly,
    allocationAndZoneDetailInserting: state.assignBayReducer.inserting,
  }))

  // Fetch location or tenancies list
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId])

  // Fetch level and zone list on change of location
  useEffect(() => {
    if (isValidDateAndTimeSelection && currentOperatorId && currentOrganisationId && location) {
      let startDateTime: string = ''
      let finishDateTime: string = ''
      if (dateAndTimeInterval.startDate && dateAndTimeInterval.startTime) {
        startDateTime = getDateAndTimeFormat(dateAndTimeInterval.startDate, dateAndTimeInterval.startTime)
      }
      if (dateAndTimeInterval.endDate && dateAndTimeInterval.endTime) {
        finishDateTime = getDateAndTimeFormat(dateAndTimeInterval.endDate, dateAndTimeInterval.endTime)
      }
      const requestBody: ReservedUnreservedRequestBodyInterface = {
        facilityId: location,
        reservationType: bayType,
        operatorId: currentOperatorId,
        carParkTenantId: currentOrganisationId,
        startDateTime: startDateTime,
        finishDateTime: finishDateTime,
        amendmentAllocationId: guestEntitlementData ? guestEntitlementData.allocationId : null,
      }
      dispatch(
        insertAggregate<ReservedUnreservedRequestBodyInterface>(
          ASSIGN_BAY_AGGREGATE_REQUEST,
          ASSIGN_BAY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', location) + '/single-entitlement-bays',
          requestBody,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dateAndTimeInterval])

  // Set level, zone and bays list
  useEffect(() => {
    if (allocationAndZoneDetail) {
      if (allocationAndZoneDetail.allocations) {
        // Set level list
        setLevelList(allocationAndZoneDetail.allocations)
        // Set zone and bays list
        if (level) {
          if (level === 'unZoned') {
            let bays: Bay[] = allocationAndZoneDetail.unZonedBays
            setBays(bays)
          } else {
            const allocation: AllocationsInterface = allocationAndZoneDetail.allocations.find(
              (e: AllocationsInterface) => e.allocationId === level,
            )
            if (allocation) {
              let zones: ZonesInterface[] = allocation.zones
              setZoneList(zones)
              const selectedZone: ZonesInterface | undefined = zones.find((z) => z.zoneId === zone)
              if (selectedZone) {
                setBays(selectedZone.bays)
                // Set set selected bay
                if (selectedBay) {
                  let previousSelectedBay = selectedZone.bays.find((bay: Bay) => bay.id === selectedBay.id)
                  if (!previousSelectedBay?.available) {
                    if (isEntryStatusOnSite) {
                      dispatch(
                        setNotification(NotificationType.ERROR, [
                          'Sorry, parking is not available for the new departure time.',
                        ]),
                      )
                      setIsParkingAvailableForOnSite(false)
                    } else {
                      setSelectedBay(null)
                    }
                  } else {
                    setIsParkingAvailableForOnSite(true)
                  }
                } else {
                  setIsParkingAvailableForOnSite(true)
                }
                if (selectedZone.availableBayCount > 0) {
                  setIsNoBaysAvailableMessage(false)
                } else {
                  if (bayType === GUEST_BAY_TYPE_ACCESS) {
                    setIsNoBaysAvailableMessage(false)
                  } else {
                    setIsNoBaysAvailableMessage(true)
                  }
                }
              }
            } else {
              setZoneList([])
            }
          }
        } else {
          setZoneList([])
        }
      }
      setUnZonedAvailableBayCount(allocationAndZoneDetail.unZonedAvailableBayCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationAndZoneDetail, level, zone])

  // Check bay selection is valid or not for next button
  useEffect(() => {
    if (location && !isNoBaysAvailableMessage) {
      if (level) {
        if (level === 'unZoned') {
          if (bayType === GUEST_BAY_TYPE_RESERVED && selectedBay) {
            setIsValidBaySelection(true)
          } else {
            setIsValidBaySelection(false)
          }
          if (bayType === GUEST_BAY_TYPE_UNRESERVED || bayType === GUEST_BAY_TYPE_ACCESS) {
            setIsValidBaySelection(true)
          }
        } else {
          if (zone) {
            if (bayType === GUEST_BAY_TYPE_RESERVED && selectedBay) {
              setIsValidBaySelection(true)
            } else {
              setIsValidBaySelection(false)
            }
            if (bayType === GUEST_BAY_TYPE_UNRESERVED || bayType === GUEST_BAY_TYPE_ACCESS) {
              setIsValidBaySelection(true)
            }
          } else {
            setIsValidBaySelection(false)
          }
        }
      } else {
        setIsValidBaySelection(false)
      }
    } else {
      setIsValidBaySelection(false)
    }
  })

  // Prepare payload for POST call
  useEffect(() => {
    let bayAssignment: BayAssignment = {
      entitlementBayId: guestEntitlementData ? guestEntitlementData.bayAssignment?.entitlementBayId : '',
      bayReservedType: bayType,
      facilityId: location,
      facilityAllocationId: level === 'unZoned' ? null : level,
      facilityZoneId: zone,
      bayId: selectedBay ? selectedBay.id : null,
      fullBayNo: selectedBay ? selectedBay.fullBayNumber : null,
      entitlementBayVersion: guestEntitlementData ? guestEntitlementData.bayAssignment?.entitlementBayVersion : 0,
      facilityName: guestEntitlementData ? guestEntitlementData.bayAssignment?.facilityName : '',
      facilityAllocationName: guestEntitlementData ? guestEntitlementData.bayAssignment?.facilityAllocationName : '',
      facilityZoneName: guestEntitlementData ? guestEntitlementData.bayAssignment?.facilityZoneName : '',
    }
    setBayAssignment(bayAssignment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bayType, location, level, zone, selectedBay])

  // Check error message conditions
  useEffect(() => {
    if (levelList) {
      if (levelList.length > 0) {
        let unZonedAvailableBayCount: number = allocationAndZoneDetail.unZonedAvailableBayCount
        let unavailableBayList: AllocationsInterface[] = levelList.filter(
          (level: AllocationsInterface) => level.availableBayCount === 0,
        )
        if (unavailableBayList.length === levelList.length && unZonedAvailableBayCount === 0) {
          if (bayType === GUEST_BAY_TYPE_ACCESS) {
            setIsNoBaysAvailableMessage(false)
          } else {
            setIsNoBaysAvailableMessage(true)
          }
        } else {
          setIsNoBaysAvailableMessage(false)
        }
      } else {
        setIsNoBaysAvailableMessage(false)
      }
    } else {
      setIsNoBaysAvailableMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelList])

  // Patch data for edit mode
  useEffect(() => {
    if (guestEntitlementData) {
      let bayAssignment: BayAssignment | null = guestEntitlementData.bayAssignment
      if (bayAssignment) {
        let bayReservedType = bayAssignment.bayReservedType
        let facilityId = bayAssignment.facilityId
        let facilityAllocationId = bayAssignment.facilityAllocationId
        let facilityZoneId = bayAssignment.facilityZoneId
        let bayId = bayAssignment.bayId
        let fullBayNo = bayAssignment.fullBayNo
        if (currentOperatorId && currentOrganisationId) {
          if (bayReservedType) {
            setBayType(bayReservedType)
          }
          if (facilityId) {
            setLocation(facilityId)
          }
          if (facilityAllocationId) {
            setLevel(facilityAllocationId)
          } else {
            setLevel('unZoned')
          }
          if (facilityZoneId) {
            setZone(facilityZoneId)
          }
          if (bayId && fullBayNo) {
            let selectedBay: Bay = {
              id: bayId,
              bayPrefix: '',
              bayNumber: '',
              available: true,
              fullBayNumber: fullBayNo,
            }
            setSelectedBay(selectedBay)
            setLastSelectedBayId(bayId)
          }
        }
      }
    }
  }, [isEdit, guestEntitlementData, currentOperatorId, currentOrganisationId])

  const reset = () => {
    setLocation('')
    setLevel('')
    setLevelList([])
    setZone('')
    setZoneList([])
    setSelectedBay(null)
    dispatch(resetAggregate<AssignParkingBay>(ASSIGN_BAY_AGGREGATE_REQUEST))
  }

  const changeBayType = (type: string) => {
    if (!isEdit) {
      setBayType(type)
      reset()
    }
  }

  const changeLocation = (event: SelectChangeEvent) => {
    if (!isValidDateAndTimeSelection) {
      dispatch(setNotification(NotificationType.ERROR, ['Invalid date and time selection for arrive and depart']))
      let tempState: any = { ...dateAndTimeIntervalError }
      Object.entries(dateAndTimeInterval).forEach((item) => {
        if (item[1] === null) {
          switch (item[0]) {
            case 'startDate':
              tempState[item[0]] = START_DATE_IS_REQUIRED
              break
            case 'startTime':
              tempState[item[0]] = START_TIME_IS_REQUIRED
              break
            case 'endDate':
              tempState[item[0]] = END_DATE_IS_REQUIRED
              break
            case 'endTime':
              tempState[item[0]] = END_TIME_IS_REQUIRED
              break
          }
        }
      })
      setDateAndTimeIntervalError(tempState)
    } else {
      setLocation(event.target.value as string)
      setLevel('')
      setZone('')
      setSelectedBay(null)
    }
  }

  const changeLevel = (event: SelectChangeEvent) => {
    setLevel(event.target.value as string)
    setZone('')
    setSelectedBay(null)
    setIsNoBaysAvailableMessage(false)
  }

  const changeZone = (event: SelectChangeEvent) => {
    setZone(event.target.value as string)
  }

  const onSelectBay = (bay: Bay) => {
    setSelectedBay(bay)
    setLastSelectedBayId(bay.id)
  }

  const getBayItemClassName = (bay: Bay) => {
    if (isEntryStatusOnSite) {
      if (bay.id === lastSelectedBayId) {
        if (selectedBay) {
          if (bay.available) {
            return 'selected-bay'
          } else {
            return 'unavailable-bay'
          }
        } else {
          if (bay.available) {
            return 'available-bay'
          } else {
            return 'unavailable-bay'
          }
        }
      } else {
        return 'unavailable-bay'
      }
    } else {
      if (selectedBay) {
        if (selectedBay.id === bay.id) {
          return 'selected-bay'
        } else {
          if (bay.available) {
            return 'available-bay'
          } else {
            return 'unavailable-bay'
          }
        }
      } else {
        if (bay.available) {
          return 'available-bay'
        } else {
          return 'unavailable-bay'
        }
      }
    }
  }

  const checkLevelHasZone = (level: AllocationsInterface): boolean => {
    return level.zones.length === 0
  }

  return (
    <Grid container spacing={0} className="guest-bay-type-wrapper">
      <Grid item xs={4}>
        <div className="assigned-icon-block">
          <img className="guest-icon reserved-icon" src={reservedIcon} alt="reserved icon" />
          <h4 className="">Choose a bay</h4>
        </div>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ paddingBottom: '40px' }} className="bay-type-button">
            <p className="input-label">Choose a bay type</p>
            <Button
              className={
                bayType === GUEST_BAY_TYPE_RESERVED
                  ? isEdit
                    ? `${classes.reservedButtonSml} cursor-pointer-none`
                    : `${classes.reservedButtonSml}`
                  : classes.reservedButtonSmlOL
              }
              variant={bayType === GUEST_BAY_TYPE_RESERVED ? 'contained' : 'outlined'}
              startIcon={
                bayType === GUEST_BAY_TYPE_RESERVED ? <img className="tickIcon" src={tickIcon} alt="img" /> : null
              }
              size="small"
              disabled={isEdit && bayType !== GUEST_BAY_TYPE_RESERVED}
              onClick={() => changeBayType(GUEST_BAY_TYPE_RESERVED)}
            >
              Reserved
            </Button>
            <Button
              className={
                bayType === GUEST_BAY_TYPE_UNRESERVED
                  ? isEdit
                    ? `${classes.reservedButtonSml} cursor-pointer-none`
                    : `${classes.reservedButtonSml}`
                  : classes.reservedButtonSmlOL
              }
              variant={bayType === GUEST_BAY_TYPE_UNRESERVED ? 'contained' : 'outlined'}
              startIcon={
                bayType === GUEST_BAY_TYPE_UNRESERVED ? <img className="tickIcon" src={tickIcon} alt="img" /> : null
              }
              size="small"
              disabled={isEdit && bayType !== GUEST_BAY_TYPE_UNRESERVED}
              onClick={() => changeBayType(GUEST_BAY_TYPE_UNRESERVED)}
            >
              Unreserved
            </Button>
            <Button
              className={
                bayType === GUEST_BAY_TYPE_ACCESS
                  ? isEdit
                    ? `${classes.reservedButtonSml} cursor-pointer-none`
                    : `${classes.reservedButtonSml}`
                  : classes.reservedButtonSmlOL
              }
              variant={bayType === GUEST_BAY_TYPE_ACCESS ? 'contained' : 'outlined'}
              startIcon={
                bayType === GUEST_BAY_TYPE_ACCESS ? <img className="tickIcon" src={tickIcon} alt="img" /> : null
              }
              size="small"
              disabled={isEdit && bayType !== GUEST_BAY_TYPE_ACCESS}
              onClick={() => changeBayType(GUEST_BAY_TYPE_ACCESS)}
            >
              Access
            </Button>
          </Grid>
        </Grid>

        {bayType && (
          <>
            <Grid container spacing={2}>
              {allocationAndZoneDetailInserting && <LoadingInDiv />}
              <Grid item xs={6}>
                <InputLabel className="select-heading">Location</InputLabel>
                <Select
                  className="shuffle-select"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={location}
                  onChange={changeLocation}
                  disabled={isEdit}
                  style={{ fontWeight: '500' }}
                >
                  {tenancies.map((location: Tenancy) => (
                    <MenuItem
                      key={location.id}
                      value={location.id}
                      className={classes.dropDownFontWeight}
                      disabled={!location.guestAccess}
                    >
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel className="select-heading">Level</InputLabel>
                <Select
                  className="shuffle-select"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={level}
                  onChange={changeLevel}
                  disabled={isEntryStatusOnSite}
                  style={{ fontWeight: '500' }}
                >
                  {(!location || location.length === 0) && (
                    <MenuItem value="" className={classes.dropDownFontWeight}>
                      No level available
                    </MenuItem>
                  )}
                  {location && unZonedAvailableBayCount !== 0 && (
                    <MenuItem
                      value="unZoned"
                      className={unZonedAvailableBayCount !== 0 ? classes.dropDownFontWeight : ''}
                      disabled={bayType !== GUEST_BAY_TYPE_ACCESS && unZonedAvailableBayCount === 0}
                    >
                      Unzoned - {unZonedAvailableBayCount} bays available
                    </MenuItem>
                  )}
                  {levelList &&
                    levelList.map(
                      (level: AllocationsInterface) =>
                        level.zones.length !== 0 && (
                          <MenuItem
                            key={level.allocationId}
                            value={level.allocationId}
                            disabled={
                              bayType === GUEST_BAY_TYPE_ACCESS
                                ? checkLevelHasZone(level)
                                : level.availableBayCount === 0
                            }
                            className={classes.dropDownFontWeight}
                          >
                            {bayType !== GUEST_BAY_TYPE_ACCESS && level.availableBayCount === 0 ? (
                              <span className={classes.dropDownFontWeightLight}>{level.allocationName} full</span>
                            ) : (
                              <span>
                                {level.allocationName} - {level.availableBayCount} bays available
                              </span>
                            )}
                          </MenuItem>
                        ),
                    )}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel className="select-heading">Zone</InputLabel>
                <Select
                  className={
                    'shuffle-select assign-bay-type-select' + (isEntryStatusOnSite ? 'on-site-select-disabled' : '')
                  }
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled={level === 'unZoned' || isEntryStatusOnSite}
                  value={zone}
                  onChange={changeZone}
                  style={{ fontWeight: '500' }}
                >
                  {(!zoneList || zoneList.length === 0) && (
                    <MenuItem value="" className={classes.dropDownFontWeight}>
                      No zone available
                    </MenuItem>
                  )}
                  {zoneList &&
                    zoneList.map((zone: ZonesInterface) => (
                      <MenuItem
                        key={zone.zoneId}
                        value={zone.zoneId}
                        className={classes.dropDownFontWeight}
                        disabled={bayType !== GUEST_BAY_TYPE_ACCESS && zone.availableBayCount === 0}
                      >
                        {zone.zoneName}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
            {isNoBaysAvailableMessage && (
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingTop: '55px', paddingBottom: '12px' }}>
                  <Box className="unavailable-bay-alert">
                    <img className="bay-warning-icon" src={warningIcon} alt="Warning Icon" />
                    <p className="bay-warning-text">
                      <strong>There are no bays available at the date and time selected.</strong>
                    </p>
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {(zone || level === 'unZoned') &&
          bayType !== GUEST_BAY_TYPE_UNRESERVED &&
          bayType !== GUEST_BAY_TYPE_ACCESS &&
          bays.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingTop: '40px' }}>
                <p className="input-label">Select the reserved bay</p>
                <div className="bay-usage-wrapper">
                  <ul className="guest-bay-usage-menu">
                    {bays &&
                      bays.map((bay: Bay) => (
                        <li key={bay.id} className="guest-bay-usage-icon">
                          <span className={getBayItemClassName(bay)} onClick={() => onSelectBay(bay)}>
                            {bay.fullBayNumber}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="bay-usage-key-wrapper">
                  <p className="bay-usage-key">
                    <span className="key-icon selected-bay-icon"></span>Selected
                    <span className="key-icon available-bay-icon"></span>Available
                    <span className="key-icon unavailable-bay-icon"></span>Unavailable
                  </p>
                </div>
              </Grid>
            </Grid>
          )}
      </Grid>
    </Grid>
  )
}

export default GuestBayType
