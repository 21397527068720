import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { CircularProgress, Pagination } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { addDays, format, isValid, subDays } from 'date-fns'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import searchFieldIcon from '../../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import crossFieldIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { AGGREGATE_GUEST, AGGREGATE_MY_LIST_TENANCIES } from '../../../common/aggregate.types'
import { fetchAggregates } from '../../../common/axios-action'
import { guestAgreementStatusList, IdClientRefName, MUIDatepickerError } from '../../../common/types'
import { exportCSV, getAgreementStatusFormattedName } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { useDebounce } from '../../../hook/useDebounce'
import usePagination from '../../../hook/usePagination'
import { RootState } from '../../../store'
import { Tenancy, TENANCY_API_RESOURCE_PATH } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { CustomCalendarIcon } from '../../util/custom-icon'
import { ROUTE_GUEST, ROUTE_GUEST_TRANSACTION_DETAIL } from '../../util/routes'
import { getTableCell } from '../../util/tooltip'
import {
  GuestEntitlementsListItem,
  GUEST_ENTITLEMENT_API_RESOURCE_PATH,
  GUEST_ENTITLEMENT_EXPORT_CSV_API_RESOURCE_PATH,
} from '../types'
import {
  DATE_IS_INVALID,
  END_DATE_MUST_BE_LATER_THAN_START_DATE,
  EntryDateErrorState,
  EntryDateState,
  FilterState,
  RequestParams,
} from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  paginationButton: {
    background: 'transparent',
    color: 'black',
    boxShadow: 'none',
  },
  dateRangeWrapper: {
    display: 'flex',
    backgroundColor: '#f6f6f6',
    borderRadius: '4px',
    padding: '1px 5px 5px 8px',
    border: '1px solid #ddd',
    width: '100%',
    marginTop: '20px',
    alignItems: 'baseline',
  },
  flexGridItem: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: '3px',
  },
}))

interface IProps {}

const GuestTransactions: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [page, rowsPerPage, sortDirection, sortBy, handleChangePage] = usePagination()
  const filterInitialState: FilterState = {
    status: '',
    entryDate: 'today',
    location: '',
  }
  const entryDateInitialState: EntryDateState = {
    entryDateFrom: null,
    entryDateTo: null,
  }
  const entryDateErrorInitialState: EntryDateErrorState = {
    entryDateFrom: '',
    entryDateTo: '',
  }
  const { tenancies } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
  }))
  const {
    guestEntitlementsList,
    guestEntitlementsListTotalCount,
    guestEntitlementsLoadingList,
    user,
    shuffleApiConfig,
  } = useSelector((state: RootState) => ({
    guestEntitlementsList: state.guestEntitlementsListReducer.aggregates.values,
    guestEntitlementsListTotalCount: state.guestEntitlementsListReducer.aggregates.totalCount,
    guestEntitlementsLoadingList: state.guestEntitlementsListReducer.loadingList,
    user: state.authReducer.user,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
  }))

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const [filterState, setFilterState] = useState<FilterState>(filterInitialState)
  const [reference, setReference] = useState<string>('')
  const [memberName, setMemberName] = useState<string>('')
  const [entryDateState, setEntryDateState] = useState<EntryDateState>(entryDateInitialState)
  const [entryDateError, setEntryDateError] = useState<EntryDateErrorState>(entryDateErrorInitialState)
  const debouncedSearchForReference: string = useDebounce<string>(reference, 600)
  const debouncedSearchForMemberName: string = useDebounce<string>(memberName, 600)

  // To get tenancies and guestEntitlementsListItemList data from API for location dropdown
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
      filterWithRequestParams()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (page === 1) {
      if (debouncedSearchForMemberName && debouncedSearchForMemberName.trim().length > 1) {
        filterWithRequestParams()
      }
      if (debouncedSearchForMemberName === '') {
        filterWithRequestParams()
      }
    } else {
      handleChangePage(null, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchForMemberName, debouncedSearchForReference, filterState])

  useEffect(() => {
    filterWithRequestParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const onApplyDateFilter = () => {
    if (page === 1) {
      filterWithRequestParams()
    } else {
      handleChangePage(null, 1)
    }
  }

  const generateRequestParams = (): RequestParams => {
    let requestParams: RequestParams = {}
    requestParams = { ...requestParams, page: page - 1, size: rowsPerPage, sortBy: 'StartDate', sortDirection: 'asc' }
    if (filterState.status) {
      requestParams = { ...requestParams, agreementStatus: filterState.status }
    }
    if (filterState.location) {
      requestParams = { ...requestParams, facilityId: filterState.location }
    }
    if (filterState.entryDate !== 'customDate') {
      let fromStartDate: string = ''
      let toStartDate: string = ''
      switch (filterState.entryDate) {
        case 'today':
          fromStartDate = format(new Date(), 'yyyy-MM-dd')
          toStartDate = format(new Date(), 'yyyy-MM-dd')
          break
        case 'next7days':
          fromStartDate = format(new Date(), 'yyyy-MM-dd')
          toStartDate = format(addDays(new Date(), 7), 'yyyy-MM-dd')
          break
        case 'last7days':
          fromStartDate = format(subDays(new Date(), 7), 'yyyy-MM-dd')
          toStartDate = format(new Date(), 'yyyy-MM-dd')
          break
        case 'last30days':
          fromStartDate = format(subDays(new Date(), 30), 'yyyy-MM-dd')
          toStartDate = format(new Date(), 'yyyy-MM-dd')
          break
      }
      if (fromStartDate) {
        requestParams = { ...requestParams, fromStartDate: fromStartDate }
      }
      if (toStartDate) {
        requestParams = { ...requestParams, toStartDate: toStartDate }
      }
    } else {
      if (entryDateState.entryDateFrom && isValid(entryDateState.entryDateFrom)) {
        let fromStartDate = format(entryDateState.entryDateFrom, 'yyyy-MM-dd')
        requestParams = { ...requestParams, fromStartDate: fromStartDate }
      }
      if (entryDateState.entryDateTo && isValid(entryDateState.entryDateTo)) {
        let toStartDate = format(entryDateState.entryDateTo, 'yyyy-MM-dd')
        requestParams = { ...requestParams, toStartDate: toStartDate }
      }
    }
    if (reference) {
      requestParams = { ...requestParams, reference: reference }
    }
    if (memberName) {
      requestParams = { ...requestParams, name: memberName }
    }
    return requestParams
  }

  const filterWithRequestParams = () => {
    if (currentOperatorId && currentOrganisationId) {
      let requestParams: RequestParams = generateRequestParams()
      dispatch(
        fetchAggregates<GuestEntitlementsListItem>(
          AGGREGATE_GUEST,
          GUEST_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          requestParams,
        ),
      )
    }
  }

  const handleChangeForFilter = (event: SelectChangeEvent) => {
    const controlName = event.target.name
    setFilterState({ ...filterState, [controlName]: event.target.value })
  }

  const handleChangeForDate = (newValue: Date | null, name: string) => {
    setEntryDateState({ ...entryDateState, [name]: newValue })
  }

  const handleErrorDate = (reason: string | null, name: string) => {
    if (reason === null) {
      setEntryDateError({ ...entryDateError, [name]: '' })
    } else {
      setEntryDateError({ ...entryDateError, [name]: reason })
    }
  }

  const resetAllFilters = () => {
    setFilterState(filterInitialState)
    setEntryDateState(entryDateInitialState)
    setEntryDateError(entryDateErrorInitialState)
    setReference('')
    setMemberName('')
    handleChangePage(null, 1)
  }

  const getFormattedErrorMessageForDate = (message: string): string => {
    switch (message) {
      case MUIDatepickerError.InvalidDate:
        return DATE_IS_INVALID
      case MUIDatepickerError.MinDate:
        return END_DATE_MUST_BE_LATER_THAN_START_DATE
      default:
        return ''
    }
  }

  const goToGuestTransactionDetail = (transactionId: string) => {
    navigate(`${ROUTE_GUEST}/${ROUTE_GUEST_TRANSACTION_DETAIL}`, { state: { transactionId: transactionId } })
  }

  const clearOrderReference = (event: any) => {
    setReference('')
  }

  const clearMemberName = (event: any) => {
    setMemberName('')
  }

  const exportData = async () => {
    if (currentOperatorId && currentOrganisationId && user && shuffleApiConfig) {
      let resourcePath = GUEST_ENTITLEMENT_EXPORT_CSV_API_RESOURCE_PATH.replace(
        ':operatorId',
        currentOperatorId,
      ).replace(':carParkTenantId', currentOrganisationId)
      let requestParams: RequestParams = generateRequestParams()
      delete requestParams.page
      delete requestParams.size
      exportCSV(user, resourcePath, shuffleApiConfig, requestParams, dispatch)
    }
  }

  return (
    <div className="page-content-wrapper">
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href="/guest">
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Transactions
                  </a>
                </h2>
              </Grid>
            </Grid>

            <Grid container spacing={0} className="responsive-content-wrapper full-width">
              <Grid item xs={12}>
                <TextField
                  name="reference"
                  value={reference}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setReference(event.target.value)
                  }}
                  placeholder="Search by transaction ID"
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    className: 'shuffleInput search-input-text-field',
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className="search-field-icon" src={searchFieldIcon} alt="searchFieldIcon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start" className="cross-icon">
                        {reference && (
                          <img
                            className="cross-field-icon"
                            src={crossFieldIcon}
                            alt="img"
                            onClick={(event) => clearOrderReference(event)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="memberName"
                  value={memberName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setMemberName(event.target.value)
                  }}
                  placeholder="Search by user"
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    className: 'shuffleInput search-input-text-field',
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className="search-field-icon" src={searchFieldIcon} alt="searchFieldIcon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start" className="cross-icon">
                        {memberName && (
                          <img
                            className="cross-field-icon"
                            src={crossFieldIcon}
                            alt="img"
                            onClick={(event) => clearMemberName(event)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="page-content-body-wrapper ondemand-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            <Grid item xs={12} className="content-panel">
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InputLabel className="select-heading">Status</InputLabel>
                      <Select
                        className="shuffle-select"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        onChange={handleChangeForFilter}
                        value={filterState.status}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="status"
                        displayEmpty
                      >
                        <MenuItem value="" className="selectBold">
                          Any Status
                        </MenuItem>
                        {guestAgreementStatusList.map((status: IdClientRefName) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel className="select-heading">Entry Date</InputLabel>
                      <Select
                        className="shuffle-select"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        onChange={(event) => {
                          handleChangeForFilter(event)
                          setEntryDateState(entryDateInitialState)
                        }}
                        value={filterState.entryDate}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="entryDate"
                        displayEmpty
                      >
                        <MenuItem value="" className="selectBold">
                          Any Date
                        </MenuItem>
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="next7days">Next 7 Days</MenuItem>
                        <MenuItem value="last7days">Last 7 Days</MenuItem>
                        <MenuItem value="last30days">Last 30 Days</MenuItem>
                        <MenuItem value="customDate" className="selectLink">
                          Custom date range
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel className="select-heading">Location</InputLabel>
                      <Select
                        className="shuffle-select"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        onChange={handleChangeForFilter}
                        value={filterState.location}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="location"
                        displayEmpty
                      >
                        <MenuItem value="" className="selectBold">
                          Any Location
                        </MenuItem>
                        {tenancies.map((tenancy: Tenancy) => (
                          <MenuItem key={tenancy.id} value={tenancy.id}>
                            {tenancy.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <p className="guest-transaction-reset-filter reset-all-filters" onClick={resetAllFilters}>
                      Reset all filters
                    </p>
                  </Box>
                </Grid>
              </Grid>
              {filterState.entryDate === 'customDate' && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" className={classes.dateRangeWrapper}>
                      <Grid item xs={10} className={classes.flexGridItem} style={{ marginRight: '20px' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={entryDateState.entryDateFrom}
                            onChange={(newValue) => handleChangeForDate(newValue, 'entryDateFrom')}
                            onError={(reason) => handleErrorDate(reason, 'entryDateFrom')}
                            inputFormat="dd MMMM yyyy"
                            disableMaskedInput
                            maxDate={entryDateState.entryDateTo ? new Date(entryDateState.entryDateTo) : null}
                            components={{
                              OpenPickerIcon: CustomCalendarIcon,
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                className="shuffleInputIcon"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="entryDateFrom"
                                error={entryDateError.entryDateFrom.length > 0}
                                helperText={getFormattedErrorMessageForDate(entryDateError.entryDateFrom)}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <p className="date-range-divider">to</p>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={entryDateState.entryDateTo}
                            onChange={(newValue) => handleChangeForDate(newValue, 'entryDateTo')}
                            onError={(reason) => handleErrorDate(reason, 'entryDateTo')}
                            inputFormat="dd MMMM yyyy"
                            disableMaskedInput
                            minDate={entryDateState.entryDateFrom ? new Date(entryDateState.entryDateFrom) : null}
                            components={{
                              OpenPickerIcon: CustomCalendarIcon,
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                className="shuffleInputIcon"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="entryDateTo"
                                error={entryDateError.entryDateTo.length > 0}
                                helperText={getFormattedErrorMessageForDate(entryDateError.entryDateTo)}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={2} className={classes.flexGridItem}>
                        <Button
                          type="submit"
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={
                            entryDateError.entryDateFrom !== '' ||
                            entryDateError.entryDateTo !== '' ||
                            (entryDateState.entryDateFrom === null && entryDateState.entryDateTo === null)
                          }
                          onClick={() => onApplyDateFilter()}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.gridTopMargin}>
                  {guestEntitlementsLoadingList ? (
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <CircularProgress color="primary" size={30} />
                      </Grid>
                    </Grid>
                  ) : (
                    <table id="transactions">
                      <thead>
                        <tr>
                          <th>Transaction ID</th>
                          <th>User</th>
                          <th>Booking entry</th>
                          <th>Booking exit</th>
                          <th>Location</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guestEntitlementsList.map((transaction: GuestEntitlementsListItem) => {
                          return (
                            <tr key={transaction.id}>
                              <td className="transaction-id">
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className="color-red"
                                  onClick={() => goToGuestTransactionDetail(transaction.id)}
                                >
                                  {transaction.reference}
                                </p>
                              </td>
                              <td className="pr-20" style={{ maxWidth: '130px', textTransform: 'capitalize' }}>
                                {getTableCell(transaction.parker, 21)}
                              </td>
                              <td>{format(Date.parse(transaction.startDateTime), 'E dd MMM hh:mmaaa')}</td>
                              <td>{format(Date.parse(transaction.finishDateTime), 'E dd MMM hh:mmaaa')}</td>
                              <td className="pr-20" style={{ maxWidth: '200px' }}>
                                {getTableCell(transaction.facilityName, 38)}
                              </td>
                              <td>{getAgreementStatusFormattedName(transaction.agreementStatus)}</td>
                            </tr>
                          )
                        })}
                        {guestEntitlementsList.length === 0 && (
                          <tr>
                            <td colSpan={6} align="center">
                              No Transactions Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                className="position-relative"
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Pagination
                    count={
                      guestEntitlementsListTotalCount ? Math.ceil(guestEntitlementsListTotalCount / rowsPerPage) : 1
                    }
                    page={page}
                    onChange={handleChangePage}
                    className="pagination"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                  {guestEntitlementsList.length > 0 && (
                    <div
                      className="guest-transaction-export transaction-export-to-csv-box"
                      onClick={() => exportData()}
                    >
                      <FileDownloadOutlinedIcon />
                      <span>Export to CSV</span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default GuestTransactions
