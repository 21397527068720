import { AggregateActionTypes } from '../../../common/aggregate.types'
import {
  AggregateState,
  AgreementStatus,
  DayOfWeek,
  EntryStatus,
  OrganisationType,
  SalesChannelType,
} from '../../../common/types'
import { AgreementAuthorityType } from '../../../pages/home/types'

interface Slots {
  dayOfWeek: DayOfWeek
  fromMinutes: number | null
  toMinutes: number | null
}

export interface ActiveAgreement {
  id: string
  agreementSource: string
  orderId: string | null
  facilityId: string
  facilityName: string
  facilityClientRef: string
  salesChannelType: SalesChannelType | null
  lastName: string
  firstName: string
  mobileNumber: string
  licencePlate: string | null
  agreementReference: string
  slots: Slots[]
  createdDateTime: string
  startDateTime: string
  finishDateTime: string | null
  consumerClientRef: string
  agreementAuthorityType: AgreementAuthorityType
  companyName: string | null
  email: string
  consumerId: string
  timeZoneId: string
  agreementStatus: AgreementStatus
  entryStatus: EntryStatus
  allocatedStartInstant: number
  allocatedFinishInstant: number | null
  allocatedStartDateTime: string
  allocatedFinishDateTime: string | null
  organisationType: OrganisationType
  organisationId: string
}

export type ActiveAgreementState = AggregateState<ActiveAgreement>
export type ActiveAgreementActionTypes = AggregateActionTypes<ActiveAgreement>

export const ACTIVE_AGREEMENTS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/core-active-agreements'
