import { Dispatch } from 'react'
import { AssignParkingBay, ReservedUnreservedZone } from '../pages/assigned/assigned-parking/types'
import { AssignedParker } from '../pages/assigned/manage-parkers/types'
import { ViewEntitlementInterface } from '../pages/assigned/view-entitlement/types'
import { setLoggedOff } from '../pages/auth/action'
import { GuestEntitlement } from '../pages/guest/types'
import { OrdersForAccessRestrictions } from '../pages/on-demand/block-out-dates/types'
import { AgreementsDetail, OrderParkingAgreements } from '../pages/on-demand/transactions/types'
import { MemberEntitlement } from '../pages/settings/users/active-users/active-entitlements/types'
import { MemberParkingItem } from '../pages/settings/users/active-users/recent-park-items/types'
import { CarParkTenantMemberDto } from '../pages/settings/users/active-users/types'
import { MemberApplicationForm } from '../pages/settings/users/applications/types'
import { Territory } from '../pages/settings/users/locations/types'
import { MemberAutoInvitation } from '../pages/settings/users/self-registration/auto-invitation/types'
import { MemberEmailDomain } from '../pages/settings/users/self-registration/domains/types'
import { AccessRestrictions } from '../store/common/access-restrictions/types'
import { Branding } from '../store/common/branding/types'
import { DepartmentTeamListItemDto } from '../store/common/departments-teams/types'
import { Department } from '../store/common/departments/types'
import { resetError } from '../store/common/error/action'
import { resetLoading } from '../store/common/loading/action'
import { Member } from '../store/common/member/types'
import { OfferFacility } from '../store/common/offer-facilities/types'
import { Team } from '../store/common/teams/types'
import { Tenancy, TenancyOffer, TenantOffer } from '../store/common/tenancies/types'
import { resetAggregate } from './aggregate-actions'
import { BayUtilisationReport, ManageEntitlement } from '../pages/assigned/manage-bay/types'
import {
  AGGREGATE_ACCESS_RESTRICTIONS,
  AGGREGATE_AGREEMENTS_DETAIL,
  AGGREGATE_APPLICATION,
  AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST,
  AGGREGATE_AUTO_INVITATION,
  AGGREGATE_BRANDING,
  AGGREGATE_ACCESS_INFO,
  AGGREGATE_MEMBER_RELATION,
  AGGREGATE_PARKING_QUOTE,
  AGGREGATE_PARKING_BASKET,
  AGGREGATE_PARKING_ORDER,
  AGGREGATE_DEPARTMENT,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_EMAIL_DOMAIN,
  AGGREGATE_GUEST,
  AGGREGATE_MEMBER,
  AGGREGATE_MEMBER_ENTITLEMENT,
  AGGREGATE_MEMBER_BULK_UPLOAD_SUMMARY,
  AGGREGATE_MANAGE_BAY,
  AGGREGATE_BAY_UTILISATION_REPORT,
  AGGREGATE_MEMBER_LIST,
  AGGREGATE_MEMBER_PARKING_ITEM,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_OFFER_FACILITY,
  AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS,
  AGGREGATE_ORDER_PARKING_AGREEMENTS,
  AGGREGATE_TEAMS,
  AGGREGATE_TENANCIES,
  AGGREGATE_TENANT,
  AGGREGATE_TENANCIES_OFFERS,
  AGGREGATE_TENANT_OFFERS,
  AGGREGATE_TERRITORIES,
  AGGREGATE_VERIFY_STAY_AGREEMENTS,
  AGGREGATE_VIEW_ENTITLEMENT,
  ASSIGN_BAY_AGGREGATE_REQUEST,
  ASSIGN_PARKING_BAY_AGGREGATE_REQUEST,
  ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST,
  ALLOCATION_BY_TYPE_AGGREGATE_REQUEST,
  DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST,
  PEAK_ALLOCATION_AGGREGATE_REQUEST,
  DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST,
  DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST,
  DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST,
  AGGREGATE_ACTIVE_AGREEMENTS,
} from './aggregate.types'
import { AllocationByType, AllocationMemberCount, DailyPeakAllocation, PeakAllocation } from '../pages/home/types'
import {
  DailyCountAllocationByAuthorityByOffer,
  DailyFacilityAgreementPeakAllocationByAuthority,
  DailyMemberFinancialSummary,
} from '../pages/utilisation/dashboard/types'
import { MemberBulkUploadSummary } from '../pages/users/bulk-uploads/types'
import { Tenant } from '../store/common/tenant-selector/types'
import { AccessInfo } from '../store/common/access-info/types'
import { MemberRelation } from '../store/common/member-relation/types'
import { ParkingQuote } from '../store/common/parking-quote/types'
import { ParkingBasket } from '../store/common/parking-basket/types'
import { ParkingOrder } from '../store/common/parking-order/types'
import { ActiveAgreement } from '../store/common/active-agreements/types'

export function resetReduxReducer(dispatch: Dispatch<any>) {
  dispatch(setLoggedOff())
  dispatch(resetAggregate<Tenancy>(AGGREGATE_TENANCIES))
  dispatch(resetAggregate<Tenant>(AGGREGATE_TENANT))
  dispatch(resetAggregate<Territory>(AGGREGATE_TERRITORIES))
  dispatch(resetAggregate<TenancyOffer>(AGGREGATE_TENANCIES_OFFERS))
  dispatch(resetAggregate<TenantOffer>(AGGREGATE_TENANT_OFFERS))
  dispatch(resetAggregate<Tenancy>(AGGREGATE_MY_LIST_TENANCIES))
  dispatch(resetAggregate<Branding>(AGGREGATE_BRANDING))
  dispatch(resetAggregate<AccessInfo>(AGGREGATE_ACCESS_INFO))
  dispatch(resetAggregate<MemberRelation>(AGGREGATE_MEMBER_RELATION))
  dispatch(resetAggregate<ParkingQuote>(AGGREGATE_PARKING_QUOTE))
  dispatch(resetAggregate<ActiveAgreement>(AGGREGATE_ACTIVE_AGREEMENTS))
  dispatch(resetAggregate<ParkingBasket>(AGGREGATE_PARKING_BASKET))
  dispatch(resetAggregate<ParkingOrder>(AGGREGATE_PARKING_ORDER))
  dispatch(resetAggregate<ViewEntitlementInterface>(AGGREGATE_VIEW_ENTITLEMENT))
  dispatch(resetAggregate<ReservedUnreservedZone>(ASSIGN_BAY_AGGREGATE_REQUEST))
  dispatch(resetAggregate<AssignParkingBay>(ASSIGN_PARKING_BAY_AGGREGATE_REQUEST))
  dispatch(resetAggregate<AssignParkingBay>(AGGREGATE_VERIFY_STAY_AGREEMENTS))
  dispatch(resetAggregate<AssignedParker>(AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST))
  dispatch(resetAggregate<OrdersForAccessRestrictions>(AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS))
  dispatch(resetAggregate<AccessRestrictions>(AGGREGATE_ACCESS_RESTRICTIONS))
  dispatch(resetAggregate<OrderParkingAgreements>(AGGREGATE_ORDER_PARKING_AGREEMENTS))
  dispatch(resetAggregate<AgreementsDetail>(AGGREGATE_AGREEMENTS_DETAIL))
  dispatch(resetAggregate<ManageEntitlement>(AGGREGATE_MANAGE_BAY))
  dispatch(resetAggregate<BayUtilisationReport>(AGGREGATE_BAY_UTILISATION_REPORT))
  dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
  dispatch(resetAggregate<MemberEmailDomain>(AGGREGATE_EMAIL_DOMAIN))
  dispatch(resetAggregate<MemberAutoInvitation>(AGGREGATE_AUTO_INVITATION))
  dispatch(resetAggregate<MemberEntitlement>(AGGREGATE_MEMBER_ENTITLEMENT))
  dispatch(resetAggregate<MemberBulkUploadSummary>(AGGREGATE_MEMBER_BULK_UPLOAD_SUMMARY))
  dispatch(resetAggregate<MemberParkingItem>(AGGREGATE_MEMBER_PARKING_ITEM))
  dispatch(resetAggregate<DepartmentTeamListItemDto>(AGGREGATE_DEPARTMENTS_TEAMS))
  dispatch(resetAggregate<OfferFacility>(AGGREGATE_OFFER_FACILITY))
  dispatch(resetAggregate<GuestEntitlement>(AGGREGATE_GUEST))
  dispatch(resetAggregate<Department>(AGGREGATE_DEPARTMENT))
  dispatch(resetAggregate<Team>(AGGREGATE_TEAMS))
  dispatch(resetAggregate<MemberApplicationForm>(AGGREGATE_APPLICATION))
  dispatch(resetAggregate<CarParkTenantMemberDto>(AGGREGATE_MEMBER))
  dispatch(resetAggregate<AllocationMemberCount>(ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST))
  dispatch(resetAggregate<AllocationByType>(ALLOCATION_BY_TYPE_AGGREGATE_REQUEST))
  dispatch(resetAggregate<DailyPeakAllocation>(DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST))
  dispatch(resetAggregate<PeakAllocation>(PEAK_ALLOCATION_AGGREGATE_REQUEST))
  dispatch(
    resetAggregate<DailyFacilityAgreementPeakAllocationByAuthority>(
      DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST,
    ),
  )
  dispatch(
    resetAggregate<DailyCountAllocationByAuthorityByOffer>(
      DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST,
    ),
  )
  dispatch(resetAggregate<DailyMemberFinancialSummary>(DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST))
  dispatch(resetLoading())
  dispatch(resetError())
}
