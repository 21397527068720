import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface BayAssignmentType {
  facilityId: string
  bayAssignmentType: string
  zoneId: string
  bayId: string
  bayNo: string
}

export interface Slots {
  dayOfWeek: string
  fromMinutes?: number | null
  toMinutes?: number | null
  startTime?: Date | null
  endTime?: Date | null
}
export interface AssignParkingStayAgreements {
  memberId: string
  id: string | null
  startDate: string | null
  finishDate: string | null
  slots: Slots[]
  version?: number | null
}

export interface AssignParkingBay {
  id: null | string
  reference: null | string
  version: null | number
  timeZoneId: string | null
  specifyTime: boolean
  startDate: string | null
  finishDate: string | null
  bayAssignment: {
    bayReservedType: string
    facilityId: string
    facilityAllocationId: string | null
    facilityZoneId: string | null
    bayId: string | null
    fullBayNo: string | null
    entitlementBayId: string | null
    entitlementBayVersion: number | null
  }
  stayAgreements: AssignParkingStayAgreements[]
}
export interface AssignParkingBayForEdit {
  id: null | string
  reference: null | string
  version: null | number
  timeZoneId: string | null
  specifyTime: boolean
  startDate: string | null
  finishDate: string | null
  bayAssignment: {
    bayReservedType: string
    facilityId: string
    facilityAllocationId: string | null
    facilityZoneId: string | null
    bayId: string | null
    fullBayNo: string | null
    entitlementBayId: string | null
    entitlementBayVersion: number | null
  }
  memberId: string
  stay: AssignParkingStayAgreements
}

export interface Bay {
  available: boolean
  bayNumber: string
  bayPrefix: string
  fullBayNumber: string
  id: string
}
export interface ZonesInterface {
  availableBayCount: number
  bays: Bay[]
  zoneId: string
  zoneName: string
}
export interface AllocationsInterface {
  allocationId: string
  allocationName: string
  availableBayCount: number
  zones: ZonesInterface[]
}

export interface UnZonedBays {
  available: boolean
  bayNumber: string
  bayPrefix: string
  fullBayNumber: string
  id: string
}
export interface ReservedUnreservedZone {
  allocations?: AllocationsInterface[]
  carParkTenantId: string
  facilityId: string
  operatorId: string
  reservationType: string
  startDate: string | null
  finishDate: string | null
  slots: Slots[]
  unZonedAvailableBayCount?: number
  unZonedBays?: UnZonedBays[]
  amendmentAllocationId?: string | null
}

export interface ReservedUnreservedRequestBodyInterface {
  facilityId: string
  reservationType: string
  operatorId: string
  carParkTenantId: string
  startDateTime: string
  finishDateTime: string
  amendmentAllocationId?: string | null
}

export interface DateErrorState {
  parkingStartDate: string
  parkingEndDate: string
}

export interface ParkingDate {
  parkingStartDate: Date | null
  parkingEndDate: Date | null
  parkingDateType: string
}

export type AssignBayState = AggregateState<ReservedUnreservedZone>
export type AssignBayTypes = AggregateActionTypes<ReservedUnreservedZone>

export const ASSIGN_BAY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId'

export type AssignParkingBayState = AggregateState<AssignParkingBay>
export type AssignParkingBayTypes = AggregateActionTypes<AssignParkingBay>

export const ENTITLEMENTS_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/entitlements'

// For Verify Stay Agreements

export const ASSIGN_BAY_VERIFY_STAY_AGREEMENTS_POST_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/entitlements:verifyStayAgreements'
