import { Grid } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { chooseUrl } from '../../../common/axios-action'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { authorizationHeaderConfig } from '../../../config/axios/axios'
import { RootState } from '../../../store'
import { Tenancy, TENANT_CARPARK_TENANCIES_OFFERS_API_RESOURCE_PATH } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'

interface IProps {
  tenancy: Tenancy
}

const CarParkTenancy: React.FC<IProps> = ({ tenancy }: IProps) => {
  const [counts, setCounts] = useState(0)
  const navigate = useNavigate()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  let shuffleApiConfig = useSelector((state: RootState) => state.appConfigReducer.shuffleApiConfig)
  let user = useSelector((state: RootState) => state.authReducer.user)

  useEffect(() => {
    async function fetchData() {
      try {
        let authorization = user!.getIdToken()
        await authorization
          .then((token: any) => {
            let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
            let resourcePath = TENANT_CARPARK_TENANCIES_OFFERS_API_RESOURCE_PATH
            if (currentOperatorId && currentOrganisationId) {
              resourcePath = resourcePath
                .replace(':operatorId', currentOperatorId)
                .replace(':carParkTenantId', currentOrganisationId)
                .replace(':tenanciesId', tenancy.id)
            }
            axios
              .get<any>(resourcePath, config)
              .then(function (response) {
                setCounts(response.data.length)
              })
              .catch(function (error) {
                console.debug(error)
              })
          })
          .catch((error: any) => {
            console.debug(error)
          })
      } catch (err) {
        console.debug(err)
      }
    }
    fetchData()
  })
  return (
    <Grid item xs={12} key={tenancy.id}>
      <div className="location-card">
        <p
          className="location-title"
          onClick={() =>
            navigate('/on-demand/product-capacities/product-detail', {
              state: { tenancyId: tenancy.id, tenancyName: tenancy.name },
            })
          }
        >
          {tenancy.name}
        </p>
        <p className="location-product-count">{counts + ' products'}</p>
      </div>
    </Grid>
  )
}

export default CarParkTenancy
