import Grid from '@mui/material/Grid'
import { format } from 'date-fns'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React, { useEffect, useState } from 'react'
import {
  AgreementAuthorityType,
  AuthorityTypePeakAllocation,
  DailyFacilityAgreementPeakAllocationByAuthority,
  DailyFacilityAgreementPeakChartSeries,
  DailyMemberFinancialSummary,
  PeakAllocations,
  agreementAuthorityTypeList,
} from './types'

interface IProps {
  dailyFacilityAgreementPeakChartDetail: DailyFacilityAgreementPeakAllocationByAuthority | null
  dailyMemberFinancialSummaryData: DailyMemberFinancialSummary | null
}

const DailyUtilisation: React.FC<IProps> = ({
  dailyFacilityAgreementPeakChartDetail,
  dailyMemberFinancialSummaryData,
}: IProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)

  useEffect(() => {
    let xAxisData: string[] = []
    let seriesData: DailyFacilityAgreementPeakChartSeries[] = []

    if (dailyFacilityAgreementPeakChartDetail) {
      xAxisData = dailyFacilityAgreementPeakChartDetail.peakAllocations.map((e) => format(new Date(e.date), 'dd-MMM'))
      agreementAuthorityTypeList.forEach((authority: AgreementAuthorityType) => {
        let tempSeriesData: number[] = []
        dailyFacilityAgreementPeakChartDetail.peakAllocations.forEach((peakAllocation: PeakAllocations) => {
          let authorityTypePeakAllocations: AuthorityTypePeakAllocation[] = peakAllocation.authorityTypePeakAllocations
          let agreementAuthorityType: AuthorityTypePeakAllocation | undefined = authorityTypePeakAllocations.find(
            (a: AuthorityTypePeakAllocation) => a.agreementAuthorityType === authority.id,
          )
          if (agreementAuthorityType) {
            tempSeriesData.push(
              agreementAuthorityType.peakAllocationPercent ? agreementAuthorityType.peakAllocationPercent : 0,
            )
          } else {
            tempSeriesData.push(0)
          }
        })
        seriesData.push({
          name: authority.name,
          type: 'bar',
          stack: 'Ad',
          color: authority.color,
          data: tempSeriesData,
          cursor: 'auto',
          barWidth: '19px',
        })
      })
    } else {
      xAxisData = []
      agreementAuthorityTypeList.forEach((authority: AgreementAuthorityType) => {
        seriesData.push({
          name: authority.name,
          type: 'bar',
          stack: 'Ad',
          color: authority.color,
          data: Array(30).fill(0),
          cursor: 'auto',
          barWidth: '19px',
        })
      })
    }

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        valueFormatter: (value: any) => value + '%',
        formatter: function formatTooltip(params: any) {
          let formattedTooltip: string = ''
          params.forEach((item: any) => {
            formattedTooltip += `<div class="display-flex">
            <p style="min-width:100px;color:#525252;font-size:12px;">${item.marker}${item.seriesName}</p>
            <p style="color:#525252;font-size:12px;">${item.value}%</p>
            </div>`
          })
          return formattedTooltip
        },
      },
      grid: {
        left: '0',
        right: '0',
        bottom: '4px',
        top: '6px',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            fontSize: 11,
            fontWeight: 'normal',
            fontFamily: 'Arial',
            color: '#525252',
            rotate: 45,
            interval: 0,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: '{value}%',
            fontSize: 11,
            fontWeight: 'normal',
            fontFamily: 'Arial',
            color: '#525252',
          },
          max: function () {
            return 100
          },
        },
      ],
      series: seriesData,
    }
    setChartOption(option)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyFacilityAgreementPeakChartDetail])

  const getHighestUtilisationDay = () => {
    if (dailyFacilityAgreementPeakChartDetail) {
      return format(new Date(dailyFacilityAgreementPeakChartDetail.maxAllocationDate), 'iii dd MMM')
    } else {
      return '-'
    }
  }

  return (
    <Grid item xs={12} className="content-panel">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <h3>Daily utilisation</h3>
          <p className="summary-data-text">Past 30 days</p>
        </Grid>
        <Grid item xs={4}>
          <h3>{getHighestUtilisationDay()}</h3>
          <p className="summary-data-text">Highest utilisation day</p>
        </Grid>
        <Grid item xs={4}>
          <p className="summary-utilisation-key">
            {agreementAuthorityTypeList.map((authority: AgreementAuthorityType) => (
              <React.Fragment key={authority.id}>
                <span
                  className="key-icon"
                  style={{ backgroundColor: authority.color, border: `1px solid ${authority.color}`, marginLeft: 10 }}
                ></span>
                {authority.name}
              </React.Fragment>
            ))}
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: '50px' }}>
        <Grid item xs={2}>
          <h4 className="summary-data-h4 highlighted-h4">
            {dailyFacilityAgreementPeakChartDetail ? dailyFacilityAgreementPeakChartDetail.maxCapacity : '-'}
          </h4>
          <p className="summary-data-text">Total bays</p>
        </Grid>
        <Grid item xs={2}>
          <h4 className="summary-data-h4">
            {dailyFacilityAgreementPeakChartDetail
              ? dailyFacilityAgreementPeakChartDetail.averageAllocationPercent + '%'
              : '-'}
          </h4>
          <p className="summary-data-text">Bay utilisation</p>
        </Grid>
        <Grid item xs={2}>
          <h4 className="summary-data-h4">
            ${dailyMemberFinancialSummaryData ? dailyMemberFinancialSummaryData.totalRealisedPayments : '-'}
          </h4>
          <p className="summary-data-text">Realised revenue</p>
        </Grid>
        <Grid item xs={2}>
          <h4 className="summary-data-h4">
            ${dailyMemberFinancialSummaryData ? dailyMemberFinancialSummaryData.totalRealisedRefunds : '-'}
          </h4>
          <p className="summary-data-text">Realised refund</p>
        </Grid>
        <Grid item xs={2}>
          <h4 className="summary-data-h4">
            ${dailyMemberFinancialSummaryData ? dailyMemberFinancialSummaryData.totalGoodwillRevenue : '-'}
          </h4>
          <p className="summary-data-text">Goodwill revenue</p>
        </Grid>
        <Grid item xs={2}>
          <h4 className="summary-data-h4">
            ${dailyMemberFinancialSummaryData ? dailyMemberFinancialSummaryData.totalGoodwillRefund : '-'}
          </h4>
          <p className="summary-data-text">Goodwill refunds</p>
        </Grid>
      </Grid>
      {chartOption && (
        <ReactEcharts option={chartOption} style={{ height: '265px', width: '100%', marginTop: '35px' }} />
      )}
    </Grid>
  )
}

export default DailyUtilisation
