import { Button, CircularProgress, Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import editIcon from '../../../assets/svg/ws-ui-icon-edit-white.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import { AGGREGATE_GUEST, AGGREGATE_MY_LIST_TENANCIES } from '../../../common/aggregate.types'
import { customMethodAggregateRequestAction, fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { AgreementStatus } from '../../../common/types'
import { getEntryStatusFormattedName } from '../../../common/utility'
import ConfirmationModel from '../../../components/model/ConfirmationModel'
import ControlCentreEntryStatusModal from '../../../components/model/ControlCentreEntryStatusModal'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { GUEST_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH } from '../../../store/common/update-entry-status/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../../auth/types'
import { ROUTE_GUEST, ROUTE_GUEST_EDIT_GUEST_PARKING, ROUTE_GUEST_TRANSACTION } from '../../util/routes'
import { formatPhoneNumber } from '../../util/util'
import { BayAssignment, Contact, GUEST_ENTITLEMENT_API_RESOURCE_PATH, GuestEntitlement, Stay } from '../types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
}))

interface IProps {}
const GuestTransactionDetail: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [guestDetail, setGuestDetail] = useState<Contact>()
  const [transactionId, setTransactionId] = useState<string>()
  const [guestBayAssignmentDetail, setGuestBayAssignmentDetail] = useState<BayAssignment>()
  const [guestStayDetail, setGuestStayDetail] = useState<Stay>()
  const [cancelBookingModalOpen, setCancelBookingModalOpen] = useState<boolean>(false)
  const [isEntitlementAgreementStatusValid, setIsEntitlementAgreementStatusValid] = useState<boolean>(false)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)

  const [openEntryStatusModal, setOpenEntryStatusModal] = useState<boolean>(false)
  const [entryStatusUpdateUrl, setEntryStatusUpdateUrl] = useState<string | null>(null)
  const [isForcedAccess, setIsForcedAccess] = useState<boolean | undefined>(undefined)

  const {
    guestEntitlementsItemData,
    guestEntitlementsItemError,
    guestEntitlementsItemLoading,
    guestEntitlementsDeleted,
    tenancies,
    tenanciesSuccess,
  } = useSelector((state: RootState) => ({
    guestEntitlementsItemData: state.guestEntitlementsItemReducer.aggregate,
    guestEntitlementsItemError: state.guestEntitlementsItemReducer.error,
    guestEntitlementsItemLoading: state.guestEntitlementsItemReducer.loadingOne,
    guestEntitlementsDeleted: state.guestEntitlementsReducer.deleted,
    tenancies: state.myListTenancyReducer.aggregates.values,
    tenanciesSuccess: state.myListTenancyReducer.getTenanciesSuccess,
  }))

  // Get tenancies
  useEffect(() => {
    if (!tenanciesSuccess) {
      if (currentOperatorId && currentOrganisationId) {
        dispatch(
          fetchAggregates<Tenancy>(
            AGGREGATE_MY_LIST_TENANCIES,
            TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + '/my-list',
            null,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, tenanciesSuccess])

  // Get guest entitlement detail
  useEffect(() => {
    if (state) {
      if (currentOperatorId && currentOrganisationId) {
        getGuestEntitlementDetail()
      }
    } else {
      navigate(`${ROUTE_GUEST}/${ROUTE_GUEST_TRANSACTION}`)
    }
    // eslint-disable-next-line
  }, [dispatch, state, currentOperatorId, currentOrganisationId])

  // Check forced access
  useEffect(() => {
    if (guestEntitlementsItemData && tenancies) {
      if (isTenantUser) {
        const tenancy = tenancies.find((t: Tenancy) => t.id === guestEntitlementsItemData.bayAssignment?.facilityId)
        if (tenancy && tenancy.forcedAccess === true) {
          setIsForcedAccess(true)
        } else {
          setIsForcedAccess(false)
        }
      } else {
        setIsForcedAccess(true)
      }
    }
  }, [guestEntitlementsItemData, tenancies, isTenantUser])

  useEffect(() => {
    if (guestEntitlementsItemData && !guestEntitlementsItemError) {
      if (guestEntitlementsItemData.agreementStatus) {
        if (guestEntitlementsItemData.agreementStatus === AgreementStatus.Valid) {
          setIsEntitlementAgreementStatusValid(true)
        } else {
          setIsEntitlementAgreementStatusValid(false)
        }
      }
      const guestContactDetail: Contact | null = guestEntitlementsItemData.contact
      if (guestContactDetail) {
        setGuestDetail(guestContactDetail)
      }
      const stay: Stay | null = guestEntitlementsItemData.stay
      if (stay) {
        setGuestStayDetail(stay)
      }
      const reference: string | null = guestEntitlementsItemData.reference
      if (reference) {
        setTransactionId(reference)
      }
      const bayAssignments: BayAssignment | null = guestEntitlementsItemData.bayAssignment
      if (bayAssignments) {
        setGuestBayAssignmentDetail(bayAssignments)
      }
    } else {
      if (guestEntitlementsItemError) {
        dispatch(setNotification(NotificationType.ERROR, [guestEntitlementsItemError]))
      }
    }
  }, [dispatch, guestEntitlementsItemData, guestEntitlementsItemError])

  useEffect(() => {
    if (guestEntitlementsDeleted) {
      dispatch(resetAggregate<GuestEntitlement>(AGGREGATE_GUEST))
      navigate(ROUTE_GUEST + '/' + ROUTE_GUEST_TRANSACTION)
      dispatch(setNotification(NotificationType.INFO, ['Your booking has been cancelled.']))
    }
    // eslint-disable-next-line
  }, [guestEntitlementsDeleted])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && guestEntitlementsItemData && guestEntitlementsItemData.id) {
      setEntryStatusUpdateUrl(
        GUEST_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':entitlementId', guestEntitlementsItemData.id),
      )
    }
  }, [currentOperatorId, currentOrganisationId, guestEntitlementsItemData])

  const getGuestEntitlementDetail = () => {
    if (state && currentOperatorId && currentOrganisationId) {
      const { transactionId }: any = state
      dispatch(
        fetchAggregate<GuestEntitlement>(
          AGGREGATE_GUEST,
          GUEST_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          transactionId,
        ),
      )
    }
  }

  const getEntryStatus = (): string => {
    if (guestEntitlementsItemData && guestEntitlementsItemData.entryStatus) {
      let entryStatus = guestEntitlementsItemData.entryStatus
      return getEntryStatusFormattedName(entryStatus)
    } else {
      return ''
    }
  }

  const getDateAndTime = (date: string | undefined): string => {
    let formattedDate: string = ''
    if (date) {
      formattedDate = format(Date.parse(date), 'E dd MMMM hh:mmaaa')
    }
    return formattedDate
  }

  const cancelBookingModal = (): void => {
    setCancelBookingModalOpen(false)
  }

  const confirmBookingModal = (): void => {
    if (currentOperatorId && currentOrganisationId && guestEntitlementsItemData) {
      dispatch(
        customMethodAggregateRequestAction<GuestEntitlement>(
          AGGREGATE_GUEST,
          GUEST_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + `/${guestEntitlementsItemData.id}:cancel`,
          {},
        ),
      )
    }
  }

  const editGuestParking = (): void => {
    if (guestEntitlementsItemData && guestEntitlementsItemData.id) {
      navigate(`${ROUTE_GUEST}/${ROUTE_GUEST_EDIT_GUEST_PARKING}`, {
        state: { guestEntitlementData: guestEntitlementsItemData },
      })
    }
  }

  // Entry Status Modal
  const handleEntryStatusModalOpen = () => {
    setOpenEntryStatusModal(true)
  }
  const handleEntryStatusModalClose = () => {
    setOpenEntryStatusModal(false)
  }

  return (
    <>
      <div className="page-content-wrapper">
        {/* Left Section */}
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <Grid container spacing={0} className="responsive-title-wrapper">
                <Grid item xs={12}>
                  <h2 className="on-demand-h2">
                    <a className="back-icon" href="/guest/transactions">
                      <img className="icon-back-arrow" src={backIcon} alt="back" />
                      Upcoming parking
                    </a>
                  </h2>
                </Grid>
              </Grid>
              {guestEntitlementsItemLoading ? (
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <CircularProgress color="primary" size={30} />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container spacing={0} className="responsive-content-wrapper">
                    <Grid item xs={12}>
                      <p style={{ marginBottom: '0px' }}>Transaction ID: {transactionId}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p style={{ marginBottom: '20px' }}>Entry Status: {getEntryStatus()}</p>
                    </Grid>
                  </Grid>
                  {isEntitlementAgreementStatusValid && (
                    <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                      <Grid item xs={6}>
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<img className="editIcon" src={editIcon} alt="" />}
                          onClick={editGuestParking}
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          className="outlined-mono"
                          variant="outlined"
                          color="secondary"
                          size="large"
                          style={{ width: '137px', height: '42px' }}
                          onClick={() => setCancelBookingModalOpen(true)}
                        >
                          Cancel booking
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>

        <Container className="page-content-body-wrapper ondemand-section">
          {/* About this transaction */}
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              <Grid item xs={12} className="content-panel">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3 className="padding-bottom-10">About this transaction</h3>
                  </Grid>
                </Grid>
                {/* Guest */}
                <Grid container spacing={2} className="transaction-detail first-item">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <h5>Guest</h5>
                      </Grid>
                      {guestEntitlementsItemLoading ? (
                        <Grid item xs={5} alignItems="center" justifyContent="center">
                          <CircularProgress color="primary" size={30} />
                        </Grid>
                      ) : (
                        <Grid item xs={5}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>
                                  {guestDetail?.firstName} {guestDetail?.lastName}
                                </td>
                              </tr>
                              <tr>
                                <td>{guestDetail?.email}</td>
                              </tr>
                              <tr>
                                <td>{formatPhoneNumber(guestDetail?.mobile)}</td>
                              </tr>
                              {guestDetail?.companyName ? (
                                <tr>
                                  <td>{guestDetail?.companyName}</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td></td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* Parking */}
                <Grid container spacing={2} className="transaction-detail last-item">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <h5>Parking</h5>
                      </Grid>
                      {guestEntitlementsItemLoading ? (
                        <Grid item xs={8} alignItems="center" justifyContent="center">
                          <CircularProgress color="primary" size={30} />
                        </Grid>
                      ) : (
                        <Grid item xs={8}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{guestBayAssignmentDetail?.facilityName}</td>
                              </tr>
                              {guestBayAssignmentDetail?.facilityAllocationName && (
                                <tr>
                                  <td>{guestBayAssignmentDetail?.facilityAllocationName}</td>
                                </tr>
                              )}
                              {guestBayAssignmentDetail?.facilityZoneName && (
                                <tr>
                                  <td>{guestBayAssignmentDetail?.facilityZoneName}</td>
                                </tr>
                              )}
                              {guestBayAssignmentDetail?.fullBayNo && (
                                <tr>
                                  <td>{guestBayAssignmentDetail?.fullBayNo}</td>
                                </tr>
                              )}
                              <tr>
                                <td>
                                  {`${getDateAndTime(guestStayDetail?.startDateTime)} - ${getDateAndTime(
                                    guestStayDetail?.finishDateTime,
                                  )}`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isEntitlementAgreementStatusValid && (
            <Grid container spacing={2}>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  {isForcedAccess ? (
                    <p
                      className="guest-transaction-reset-filter reset-all-filters"
                      onClick={handleEntryStatusModalOpen}
                    >
                      Update Entry Status
                    </p>
                  ) : (
                    <p
                      className="guest-transaction-reset-filter reset-all-filters"
                      style={{ opacity: 0.5, cursor: 'not-allowed' }}
                    >
                      Update Entry Status
                    </p>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      <ConfirmationModel
        display={cancelBookingModalOpen}
        cancelModel={cancelBookingModal}
        confirmModel={confirmBookingModal}
        title="Are you sure you want to cancel this booking?"
        description=""
        cancelButtonName="Keep booking"
        confirmButtonName="Cancel booking"
      />
      {guestEntitlementsItemData && guestEntitlementsItemData.bayAssignment && entryStatusUpdateUrl && (
        <ControlCentreEntryStatusModal
          display={openEntryStatusModal}
          handleClose={handleEntryStatusModalClose}
          facilityId={guestEntitlementsItemData.bayAssignment.facilityId}
          currentEntryStatus={guestEntitlementsItemData.entryStatus}
          entryStatusUpdateUrl={entryStatusUpdateUrl}
          afterUpdateEntryStatus={getGuestEntitlementDetail}
        />
      )}
    </>
  )
}

export default GuestTransactionDetail
