import { AggregateState, Lifecycle } from '../../../common/types'
import { AggregateActionTypes } from '../../../common/aggregate.types'
import { RootState } from '../../index'

export interface Branding {
  id: string
  code: string
  name: string
  clientRef: string
  lifecycle: Lifecycle
  elements: BrandingElement[]
}

export interface BrandingElement {
  id: string | null
  name: BrandingElementName | null
  elementType: BrandingElementType | null
  colourValue: string | null
  imageValue: FileInfo | null
  version: number | null
}

export interface FileInfo {
  id: string
  url: string
  contentType: string
  fileName: string
}

export enum BrandingElementType {
  Colour = 'Colour',
  Image = 'Image',
}

export enum BrandingElementName {
  HeaderLogo = 'HeaderLogo',
  TileLogo = 'TileLogo',
  HeaderColour = 'HeaderColour',
  AccentColour = 'AccentColour',
  ButtonColour = 'ButtonColour',
  ButtonTextColour = 'ButtonTextColour',
  TileColour = 'TileColour',
  TextColour = 'HeaderColour',
}

export type BrandingState = AggregateState<Branding>
export type BrandingActionTypes = AggregateActionTypes<Branding>

export const BRANDING_TENANT_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/branding'

export const selectCurrentBranding = (state: RootState) => {
  let branding: Branding | null = state.brandingReducer.aggregate
  return branding
}

export const selectCurrentBrandingImageUrl = (state: RootState) => {
  let branding: Branding | null = state.brandingReducer.aggregate
  if (branding) {
    let headerImageElement: BrandingElement | undefined = branding.elements.find(
      (row) => row.name === BrandingElementName.HeaderLogo,
    )
    return headerImageElement?.imageValue?.url
  } else {
    return null
  }
}
