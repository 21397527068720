import * as React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Backdrop, CircularProgress } from '@mui/material'

const useStyle = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100%',
  },
}))

interface IProps {}

const LoadingInDiv: React.FC<IProps> = (props) => {
  const classes = useStyle()
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="primary" size={35} />
      </Backdrop>
    </div>
  )
}

export default LoadingInDiv
