import { Button, CircularProgress, Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { format } from 'date-fns/esm'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import editIcon from '../../../assets/svg/ws-ui-icon-edit-white.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_MEMBER_LIST,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_VIEW_ENTITLEMENT,
} from '../../../common/aggregate.types'
import { customMethodAggregateRequestAction, fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { AgreementStatus } from '../../../common/types'
import { getEntryStatusFormattedName } from '../../../common/utility'
import ConfirmationModel from '../../../components/model/ConfirmationModel'
import ControlCentreEntryStatusModal from '../../../components/model/ControlCentreEntryStatusModal'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { MEMBER_API_RESOURCE_PATH, Member } from '../../../store/common/member/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { MEMBER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH } from '../../../store/common/update-entry-status/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../../auth/types'
import { ROUTE_ASSIGNED, ROUTE_ASSIGNED_EDIT_ASSIGN_BAY, ROUTE_ASSIGNED_MANAGE_PARKERS } from '../../util/routes'
import { getFormattedTimeRange } from '../../util/util'
import { ENTITLEMENTS_API_RESOURCE_PATH, Slots } from '../assigned-parking/types'
import ParkingUtilisation from '../manage-bay/parking-utilisation'
import { BAY_TYPE_UNRESERVED } from '../types'
import { ViewEntitlementInterface, ViewEntitlementState } from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '440px',
    textAlign: 'center',
  },
  customPaddingTop: {
    paddingTop: '5px !important',
  },
}))
interface WeekDay {
  key: string
  value: string
}

const weekDayArray = [
  {
    key: 'MONDAY',
    value: 'M',
  },
  {
    key: 'TUESDAY',
    value: 'T',
  },
  {
    key: 'WEDNESDAY',
    value: 'W',
  },
  {
    key: 'THURSDAY',
    value: 'T',
  },
  {
    key: 'FRIDAY',
    value: 'F',
  },
  {
    key: 'SATURDAY',
    value: 'S',
  },
  {
    key: 'SUNDAY',
    value: 'S',
  },
]
interface IProps {}

const ViewEntitlement: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [memberId, setMemberId] = useState<string>('')
  const [memberName, setMemberName] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [modalDescription, setModalDescription] = useState<string>('')

  const [openEntryStatusModal, setOpenEntryStatusModal] = useState<boolean>(false)
  const [entryStatusUpdateUrl, setEntryStatusUpdateUrl] = useState<string>('')
  const [isForcedAccess, setIsForcedAccess] = useState<boolean | undefined>(undefined)

  const navigate = useNavigate()
  const { state }: any = useLocation()

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)

  const { entitlementDetail, loadingOne, inserted, entitlementError, tenancies, tenanciesSuccess } = useSelector(
    (state: RootState) => ({
      entitlementDetail: state.viewEntitlementReducer.aggregate,
      loadingOne: state.viewEntitlementReducer.loadingOne,
      inserted: state.viewEntitlementReducer.inserted,
      entitlementError: state.viewEntitlementReducer.error,
      tenancies: state.myListTenancyReducer.aggregates.values,
      tenanciesSuccess: state.myListTenancyReducer.getTenanciesSuccess,
    }),
  )

  // Get tenancies
  useEffect(() => {
    if (!tenanciesSuccess) {
      if (currentOperatorId && currentOrganisationId) {
        dispatch(
          fetchAggregates<Tenancy>(
            AGGREGATE_MY_LIST_TENANCIES,
            TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + '/my-list',
            null,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, tenanciesSuccess])

  // Get entitlement detail
  // Get member list
  useEffect(() => {
    if (state && currentOperatorId && currentOrganisationId) {
      getEntitlementDetail()
      dispatch(
        fetchAggregates<Member>(
          AGGREGATE_MEMBER_LIST,
          MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state, currentOperatorId, currentOrganisationId])

  // Check forced access
  useEffect(() => {
    if (entitlementDetail && tenancies) {
      if (isTenantUser) {
        const tenancy = tenancies.find((t) => t.id === entitlementDetail.bayAssignment.facilityId)
        if (tenancy && tenancy.forcedAccess === true) {
          setIsForcedAccess(true)
        } else {
          setIsForcedAccess(false)
        }
      } else {
        setIsForcedAccess(true)
      }
    }
  }, [entitlementDetail, tenancies, isTenantUser])

  useEffect(() => {
    if (entitlementDetail) {
      const id = entitlementDetail.memberId
      setMemberName(entitlementDetail.member.name)
      setMemberId(id)
    }
  }, [entitlementDetail, memberId])

  // Get entry status update URL
  useEffect(() => {
    if (entitlementDetail && currentOperatorId && currentOrganisationId) {
      setEntryStatusUpdateUrl(
        MEMBER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':entitlementId', entitlementDetail.id),
      )
    }
  }, [currentOperatorId, currentOrganisationId, entitlementDetail])

  // unassignBay success
  useEffect(() => {
    if (inserted) {
      navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS}`, {
        state: {
          bayUnassignSuccess: `${memberName} has been unassigned`,
        },
      })
    }
    // eslint-disable-next-line
  }, [inserted])

  // unassignBay error
  useEffect(() => {
    if (entitlementError) {
      dispatch(
        setNotification(NotificationType.ERROR, [
          'Unable to unassign bay as currently not Off-Site. Please contact Wilson Parking for support.',
        ]),
      )
    }
    // eslint-disable-next-line
  }, [entitlementError])

  const getEntitlementDetail = () => {
    if (state && currentOperatorId && currentOrganisationId) {
      const { entitlement } = state
      dispatch(
        fetchAggregate<ViewEntitlementInterface>(
          AGGREGATE_VIEW_ENTITLEMENT,
          ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          entitlement.id,
        ),
      )
    }
  }

  const getEntryStatus = (): string => {
    if (entitlementDetail) {
      let entryStatus = entitlementDetail.entryStatus
      return getEntryStatusFormattedName(entryStatus)
    } else {
      return ''
    }
  }

  const openModal = () => {
    if (entitlementDetail) {
      const dialogBoxTitle = `Are you sure you want to unassign ${memberName}?`
      const dialogBoxDescription = `If ${memberName} is currently in the car park, they will be able to exit, but not enter again.`

      setModalTitle(dialogBoxTitle)
      setModalDescription(dialogBoxDescription)
    }
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const unassignBay = () => {
    if (currentOperatorId && currentOrganisationId && entitlementDetail) {
      dispatch(
        customMethodAggregateRequestAction<ViewEntitlementState>(
          AGGREGATE_VIEW_ENTITLEMENT,
          ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + `/${entitlementDetail.id}:cancel`,
          {},
        ),
      )
    }
    setModalOpen(false)
  }

  const getFromAndToTime = (slots: Slots[], day: string) => {
    let selectedDay: Slots | undefined = slots.find((e: Slots) => e.dayOfWeek === day)
    if (selectedDay) {
      if (!selectedDay?.fromMinutes && !selectedDay?.toMinutes) {
        return <p className="day-usage-text">All day</p>
      } else {
        return (
          <>
            <p className="day-usage-text">
              {selectedDay.fromMinutes === 0
                ? getFormattedTimeRange(0)
                : selectedDay.fromMinutes
                ? getFormattedTimeRange(selectedDay.fromMinutes)
                : ''}
            </p>
            <p className={`day-usage-text ${classes.customPaddingTop}`}>
              {selectedDay.toMinutes ? getFormattedTimeRange(selectedDay.toMinutes) : ''}
            </p>
          </>
        )
      }
    }
  }

  const editAssignBay = () => {
    dispatch(resetAggregate<ViewEntitlementState>(AGGREGATE_VIEW_ENTITLEMENT))
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_EDIT_ASSIGN_BAY}`, {
      state: { isBayEdit: true, entitlement: entitlementDetail },
    })
  }

  // Entry Status Modal
  const handleEntryStatusModalOpen = () => {
    setOpenEntryStatusModal(true)
  }
  const handleEntryStatusModalClose = () => {
    setOpenEntryStatusModal(false)
  }

  return (
    <>
      {/* Left Section */}
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href={`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS}`}>
                    <img className="icon-back-arrow" src={backIcon} alt="back" style={{ margin: '7px 10px 30px 0' }} />
                    {memberName}
                  </a>
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="responsive-content-wrapper">
              {loadingOne ? (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginBottom: '20px' }}
                >
                  <Grid item xs={12}>
                    <CircularProgress color="primary" size={30} />
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div className="responsive-table-wrapper">
                    {entitlementDetail && (
                      <table className="assigned-parking-table table-section-one">
                        <tbody>
                          <tr className="table-section">
                            <td className="table-cell-left">Type</td>
                            <td className="table-cell-right">
                              <strong>
                                {entitlementDetail ? `${entitlementDetail?.bayAssignment.bayReservedType} parking` : ''}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Location</td>
                            <td>
                              <strong>{entitlementDetail?.bayAssignment.facilityName}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    <table className="assigned-parking-table table-section-two">
                      <tbody>
                        {entitlementDetail?.bayAssignment.facilityAllocationName && (
                          <tr className="table-section">
                            <td className="table-cell-left">Level</td>
                            <td className="table-cell-right">
                              <strong>{entitlementDetail?.bayAssignment.facilityAllocationName}</strong>
                            </td>
                          </tr>
                        )}
                        {entitlementDetail?.bayAssignment.facilityZoneName && (
                          <tr>
                            <td>Zone</td>
                            <td>
                              <strong>{entitlementDetail?.bayAssignment.facilityZoneName}</strong>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {entitlementDetail?.bayAssignment.bayReservedType !== BAY_TYPE_UNRESERVED &&
                      entitlementDetail?.bayAssignment.fullBayNo && (
                        <table className="assigned-parking-table table-section-three">
                          <tbody>
                            <tr className="table-section">
                              <td className="table-cell-left">Bay</td>
                              <td className="table-cell-right">
                                <strong>{entitlementDetail?.bayAssignment.fullBayNo}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                    {entitlementDetail && (
                      <table className="assigned-parking-table table-section-three">
                        <tbody>
                          <tr className="table-section">
                            <td className="table-cell-left">Entry Status</td>
                            <td className="table-cell-right">
                              <strong>{getEntryStatus()}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
              <Grid item xs={6}>
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<img className="editIcon" src={editIcon} alt="" />}
                  onClick={editAssignBay}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className="outlined-mono"
                  variant="outlined"
                  color="secondary"
                  size="large"
                  style={{ width: '137px', height: '42px' }}
                  onClick={openModal}
                >
                  Unassign
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* Right Section */}
      <Container className="page-content-body-wrapper assigned-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            {/* Bay use */}
            <Grid item xs={12} className="content-panel" style={{ minHeight: 'auto' }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <h3 className="padding-bottom-20">Bay use</h3>
                </Grid>
              </Grid>
              {loadingOne ? (
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <CircularProgress color="primary" size={30} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <table id="bay-use-table">
                      <tbody>
                        <tr>
                          <td>Starts</td>
                          <td>
                            <strong>
                              {entitlementDetail?.stay.startDate
                                ? format(Date.parse(entitlementDetail?.stay.startDate), 'd MMM yyyy')
                                : 'Today'}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Expires</td>
                          <td>
                            <strong>
                              {entitlementDetail?.stay.finishDate
                                ? format(Date.parse(entitlementDetail?.stay.finishDate), 'd MMM yyyy')
                                : 'Never'}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item xs={7}>
                    <div className="day-usage-wrapper">
                      <ul className="day-usage-menu">
                        {entitlementDetail &&
                          entitlementDetail.stay &&
                          weekDayArray.map((day: WeekDay, index) => (
                            <li className="day-usage-item" key={index + 1}>
                              <i
                                className={
                                  entitlementDetail.stay.slots.some((slot) => slot.dayOfWeek === day.key)
                                    ? 'day-usage-icon enabled'
                                    : 'day-usage-icon disabled'
                                }
                              >
                                {day.value}
                              </i>
                              {getFromAndToTime(entitlementDetail.stay.slots, day.key)}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* Parking Activity */}
            <Grid item xs={12} className="content-panel" style={{ minHeight: 'auto' }}>
              <Grid item xs={12}>
                {entitlementDetail && (
                  <ParkingUtilisation
                    bayId={''}
                    facilityId={entitlementDetail?.bayAssignment.facilityId}
                    agreementIds={[entitlementDetail?.id]}
                  />
                )}
              </Grid>
            </Grid>
            {entitlementDetail?.agreementStatus === AgreementStatus.Valid && (
              <Grid container spacing={2}>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {isForcedAccess ? (
                      <p
                        className="guest-transaction-reset-filter reset-all-filters"
                        onClick={handleEntryStatusModalOpen}
                      >
                        Update Entry Status
                      </p>
                    ) : (
                      <p
                        className="guest-transaction-reset-filter reset-all-filters"
                        style={{ opacity: 0.5, cursor: 'not-allowed' }}
                      >
                        Update Entry Status
                      </p>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <ConfirmationModel
        display={modalOpen}
        cancelModel={closeModal}
        confirmModel={unassignBay}
        title={modalTitle}
        description={modalDescription}
        cancelButtonName="Cancel"
        confirmButtonName="Unassign bay"
      />
      {entitlementDetail && (
        <ControlCentreEntryStatusModal
          display={openEntryStatusModal}
          handleClose={handleEntryStatusModalClose}
          facilityId={entitlementDetail.bayAssignment.facilityId}
          currentEntryStatus={entitlementDetail.entryStatus}
          entryStatusUpdateUrl={entryStatusUpdateUrl}
          afterUpdateEntryStatus={getEntitlementDetail}
        />
      )}
    </>
  )
}

export default ViewEntitlement
