import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Dispatch, SetStateAction } from 'react'
import { RelatedAgreementConfirmation } from '../../pages/settings/users/active-users/types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '600px',
    maxHeight: '400px',
    textAlign: 'center',
  },
  descLabel: {
    marginTop: '35px',
    color: '#ff0000 !important',
    fontWeight: '500',
    cursor: 'pointer',
  },
}))

interface IProps {
  display: boolean
  cancelModel: () => void
  confirmModel: (selectedAgreementConfirmation: RelatedAgreementConfirmation) => void
  title: string | undefined
  cancelButtonName: string
  confirmButtonName: string
  selectedAgreementConfirmation: RelatedAgreementConfirmation
  setSelectedAgreementConfirmation: Dispatch<SetStateAction<RelatedAgreementConfirmation>>
}

const ModifyMemberConfirmationModel: React.FC<IProps> = (props) => {
  const { display, cancelModel, confirmModel, cancelButtonName, confirmButtonName } = props
  const classes = useStyles()

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-h3 larger-text">
                The Employee has Assigned Parking or Bookings that expire after the employment end date.
              </h3>
              <p>
                Select&nbsp;<strong>Cancel</strong>
                &nbsp;to review, or <strong>Proceed</strong> to keep the Assigned Parking or Bookings.
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '60px' }}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={cancelModel}
                >
                  {cancelButtonName}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => confirmModel(RelatedAgreementConfirmation.LEAVE)}
                >
                  {confirmButtonName}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default ModifyMemberConfirmationModel
