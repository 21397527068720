import { ApplicationStatusReason } from '../pages/settings/users/applications/types'
import { CachedAggregates, CachedIdNames, IdOnly, LifecycleOnly } from './types'

export const LIST_AGGREGATES_REQUEST = 'LIST_AGGREGATES_REQUEST'
export const LIST_AGGREGATES_SUCCESS = 'LIST_AGGREGATES_SUCCESS'
export const LIST_AGGREGATES_FAILURE = 'LIST_AGGREGATES_FAILURE'
export const LIST_AGGREGATES_NOT_MODIFIED = 'LIST_AGGREGATES_NOT_MODIFIED'

export const GET_AGGREGATE_REQUEST = 'GET_AGGREGATE_REQUEST'
export const GET_AGGREGATE_SUCCESS = 'GET_AGGREGATE_SUCCESS'
export const GET_AGGREGATE_FAILURE = 'GET_AGGREGATE_FAILURE'

export const UPDATE_AGGREGATE_REQUEST = 'UPDATE_AGGREGATE_REQUEST'
export const UPDATE_AGGREGATE_SUCCESS = 'UPDATE_AGGREGATE_SUCCESS'
export const UPDATE_AGGREGATE_FAILURE = 'UPDATE_AGGREGATE_FAILURE'

export const INSERT_AGGREGATE_REQUEST = 'INSERT_AGGREGATE_REQUEST'
export const INSERT_AGGREGATE_SUCCESS = 'INSERT_AGGREGATE_SUCCESS'
export const INSERT_AGGREGATE_FAILURE = 'INSERT_AGGREGATE_FAILURE'

export const POST_AGGREGATE_REQUEST = 'POST_AGGREGATE_REQUEST'
export const POST_AGGREGATE_SUCCESS = 'POST_AGGREGATE_SUCCESS'
export const POST_AGGREGATE_FAILURE = 'POST_AGGREGATE_FAILURE'

export const DELETE_AGGREGATE_REQUEST = 'DELETE_AGGREGATE_REQUEST'
export const DELETE_AGGREGATE_SUCCESS = 'DELETE_AGGREGATE_SUCCESS'
export const DELETE_AGGREGATE_FAILURE = 'DELETE_AGGREGATE_FAILURE'

export const LIST_AGGREGATES_ID_NAME_REQUEST = 'LIST_AGGREGATES_ID_NAME_REQUEST'
export const LIST_AGGREGATES_ID_NAME_SUCCESS = 'LIST_AGGREGATES_ID_NAME_SUCCESS'
export const LIST_AGGREGATES_ID_NAME_FAILURE = 'LIST_AGGREGATES_ID_NAME_FAILURE'
export const LIST_AGGREGATES_ID_NAME_NOT_MODIFIED = 'LIST_AGGREGATES_ID_NAME_NOT_MODIFIED'

export const UPDATE_LIFECYCLE_REQUEST = 'UPDATE_LIFECYCLE_REQUEST'
export const UPDATE_LIFECYCLE_SUCCESS = 'UPDATE_LIFECYCLE_SUCCESS'
export const UPDATE_LIFECYCLE_FAILURE = 'UPDATE_LIFECYCLE_FAILURE'

export const UPDATE_APPLICATION_STATUS_REQUEST = 'UPDATE_APPLICATION_STATUS_REQUEST'
export const UPDATE_APPLICATION_STATUS_SUCCESS = 'UPDATE_APPLICATION_STATUS_SUCCESS'
export const UPDATE_APPLICATION_STATUS_FAILURE = 'UPDATE_APPLICATION_STATUS_FAILURE'

export const RESET_AGGREGATE = 'RESET_AGGREGATE'

export const AGGREGATE_MEMBER = 'MEMBER'
export const AGGREGATE_APPLICATION = 'APPLICATION'
export const AGGREGATE_TEAMS = 'TEAMS'
export const AGGREGATE_DEPARTMENT = 'DEPARTMENT'
export const AGGREGATE_TENANCIES = 'TENANCIES'
export const AGGREGATE_TENANT = 'TENANT'
export const AGGREGATE_TERRITORIES = 'TERRITORIES'
export const AGGREGATE_GUEST = 'GUEST'
export const AGGREGATE_MY_LIST_TENANCIES = 'MY_LIST_TENANCIES'
export const AGGREGATE_TENANCIES_OFFERS = 'TENANCIES_OFFERS'
export const AGGREGATE_TENANT_OFFERS = 'TENANT_OFFERS'
export const AGGREGATE_OFFER_FACILITY = 'OFFER_FACILITY'

export const AGGREGATE_ORDER_PARKING_AGREEMENTS = 'ORDER_PARKING_AGREEMENTS'
export const AGGREGATE_DEPARTMENTS_TEAMS = 'DEPARTMENTS_TEAMS'
export const AGGREGATE_MEMBER_ENTITLEMENT = 'MEMBER_MEMBER_ENTITLEMENT'
export const AGGREGATE_MEMBER_BULK_UPLOAD_SUMMARY = 'MEMBER_BULK_UPLOAD_SUMMARY'
export const AGGREGATE_MEMBER_PARKING_ITEM = 'MEMBER_PARKING_ITEM'
export const AGGREGATE_NOTIFICATIONS = 'NOTIFICATIONS'
export const AGGREGATE_AUTO_INVITATION = 'AUTO_INVITATION'
export const AGGREGATE_EMAIL_DOMAIN = 'EMAIL_DOMAIN'
export const AGGREGATE_MEMBER_LAST_ACTIVITY = 'MEMBER_LAST_ACTIVITY'
export const AGGREGATE_ACCESS_RESTRICTIONS = 'ACCESS_RESTRICTIONS'
export const AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS = 'ORDERS_FOR_ACCESS_RESTRICTIONS'
export const AGGREGATE_MANAGE_BAY = 'MANAGE_BAY'
export const AGGREGATE_BAY_UTILISATION_REPORT = 'BAY_UTILISATION_REPORT'
export const AGGREGATE_MEMBER_LIST = 'MEMBER_LIST'
export const AGGREGATE_AGREEMENTS_DETAIL = 'AGREEMENTS_DETAIL'
export const AGGREGATE_LIST_TRANSACTION_SUMMARY = 'AGGREGATE_LIST_TRANSACTION_SUMMARY'

// Operations

export const AGGREGATE_ACTIVE_AGREEMENTS = 'ACTIVE_AGREEMENTS'

// Dashboard reports
export const ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST = 'ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST'
export const ALLOCATION_BY_TYPE_AGGREGATE_REQUEST = 'ALLOCATION_BY_TYPE_AGGREGATE_REQUEST'
export const DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST = 'DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST'
export const PEAK_ALLOCATION_AGGREGATE_REQUEST = 'PEAK_ALLOCATION_AGGREGATE_REQUEST'

// Utilisation

export const DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST =
  'DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST'
export const DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST =
  'DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST'
export const DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST = 'DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST'

export const CUSTOM_AGGREGATE_REQUEST = 'CUSTOM_AGGREGATE_REQUEST'
export const CUSTOM_AGGREGATE_SUCCESS = 'CUSTOM_AGGREGATE_SUCCESS'
export const CUSTOM_AGGREGATE_FAILURE = 'CUSTOM_AGGREGATE_FAILURE'

export const ASSIGN_BAY_AGGREGATE_REQUEST = 'ASSIGN_BAY_AGGREGATE_REQUEST'
export const ASSIGN_PARKING_BAY_AGGREGATE_REQUEST = 'ASSIGN_PARKING_BAY_AGGREGATE_REQUEST'
export const AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST = 'ASSIGNED_PARKER_AGGREGATE_REQUEST'
export const AGGREGATE_VERIFY_STAY_AGREEMENTS = 'AGGREGATE_VERIFY_STAY_AGREEMENTS'
export const AGGREGATE_VIEW_ENTITLEMENT = 'VIEW_ENTITLEMENT'
export const AGGREGATE_BRANDING = 'BRANDING'
export const AGGREGATE_ACCESS_INFO = 'ACCESS_INFO'
export const AGGREGATE_MEMBER_RELATION = 'MEMBER_RELATION'
export const AGGREGATE_PARKING_QUOTE = 'PARKING_QUOTE'
export const AGGREGATE_PARKING_BASKET = 'PARKING_BASKET'
export const AGGREGATE_PARKING_ORDER = 'PARKING_ORDER'
export const AGGREGATE_TENANT_SETTINGS = 'TENANT_SETTINGS'
export const RESET_AGGREGATE_ERROR = 'RESET_ERROR'

export const AGGREGATE_GATE_LIST_ITEM = 'GATE_LIST_ITEM'

export type AggregateTypes =
  | typeof AGGREGATE_MEMBER
  | typeof AGGREGATE_APPLICATION
  | typeof AGGREGATE_TEAMS
  | typeof AGGREGATE_DEPARTMENT
  | typeof AGGREGATE_TENANCIES
  | typeof AGGREGATE_TENANT
  | typeof AGGREGATE_TERRITORIES
  | typeof AGGREGATE_GUEST
  | typeof AGGREGATE_TENANCIES_OFFERS
  | typeof AGGREGATE_TENANT_OFFERS
  | typeof AGGREGATE_OFFER_FACILITY
  | typeof AGGREGATE_ORDER_PARKING_AGREEMENTS
  | typeof AGGREGATE_DEPARTMENTS_TEAMS
  | typeof AGGREGATE_MEMBER_ENTITLEMENT
  | typeof AGGREGATE_MEMBER_BULK_UPLOAD_SUMMARY
  | typeof AGGREGATE_MEMBER_PARKING_ITEM
  | typeof AGGREGATE_NOTIFICATIONS
  | typeof AGGREGATE_AUTO_INVITATION
  | typeof AGGREGATE_EMAIL_DOMAIN
  | typeof AGGREGATE_MEMBER_LAST_ACTIVITY
  | typeof AGGREGATE_MANAGE_BAY
  | typeof AGGREGATE_BAY_UTILISATION_REPORT
  | typeof AGGREGATE_MEMBER_LIST
  | typeof AGGREGATE_AGREEMENTS_DETAIL
  | typeof AGGREGATE_LIST_TRANSACTION_SUMMARY
  | typeof AGGREGATE_ACTIVE_AGREEMENTS
  | typeof AGGREGATE_ACCESS_RESTRICTIONS
  | typeof AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS
  | typeof ASSIGN_BAY_AGGREGATE_REQUEST
  | typeof ASSIGN_PARKING_BAY_AGGREGATE_REQUEST
  | typeof AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST
  | typeof AGGREGATE_VERIFY_STAY_AGREEMENTS
  | typeof AGGREGATE_VIEW_ENTITLEMENT
  | typeof AGGREGATE_BRANDING
  | typeof AGGREGATE_ACCESS_INFO
  | typeof AGGREGATE_MEMBER_RELATION
  | typeof AGGREGATE_PARKING_QUOTE
  | typeof AGGREGATE_PARKING_BASKET
  | typeof AGGREGATE_PARKING_ORDER
  | typeof AGGREGATE_MY_LIST_TENANCIES
  | typeof AGGREGATE_TENANT_SETTINGS
  | typeof ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST
  | typeof ALLOCATION_BY_TYPE_AGGREGATE_REQUEST
  | typeof DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST
  | typeof PEAK_ALLOCATION_AGGREGATE_REQUEST
  | typeof DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST
  | typeof DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST
  | typeof DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST
  | typeof AGGREGATE_GATE_LIST_ITEM

export interface ListAggregatesRequestAction {
  type: typeof LIST_AGGREGATES_REQUEST
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface ListAggregatesSuccessAction<T> {
  type: typeof LIST_AGGREGATES_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    aggregates: CachedAggregates<T>
  }
}

export interface ListAggregatesFailureAction {
  type: typeof LIST_AGGREGATES_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ListAggregatesNotModified {
  type: typeof LIST_AGGREGATES_NOT_MODIFIED
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface GetAggregateRequestAction {
  type: typeof GET_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    id: string | null
  }
}

export interface GetAggregateSuccessAction<T> {
  type: typeof GET_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface GetAggregateFailureAction {
  type: typeof GET_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface UpdateAggregateRequestAction<T> {
  type: typeof UPDATE_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface UpdateAggregateSuccessAction<T> {
  type: typeof UPDATE_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface UpdateAggregateFailureAction {
  type: typeof UPDATE_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ResetAggregateAction {
  type: typeof RESET_AGGREGATE
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface InsertAggregateRequestAction<T> {
  type: typeof INSERT_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface InsertAggregateSuccessAction<IdOnly> {
  type: typeof INSERT_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    idOnly: IdOnly
  }
}

export interface InsertAggregateFailureAction {
  type: typeof INSERT_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface PostAggregateRequestAction {
  type: typeof POST_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface PostAggregateSuccessAction<T> {
  type: typeof POST_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    data: T
  }
}

export interface PostAggregateFailureAction {
  type: typeof POST_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface DeleteAggregateRequestAction {
  type: typeof DELETE_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    id: string
  }
}

export interface DeleteAggregateSuccessAction {
  type: typeof DELETE_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface DeleteAggregateFailureAction {
  type: typeof DELETE_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ListAggregatesIdNamesRequestAction {
  type: typeof LIST_AGGREGATES_ID_NAME_REQUEST
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface ListAggregatesIdNamesSuccessAction {
  type: typeof LIST_AGGREGATES_ID_NAME_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    idNames: CachedIdNames
  }
}

export interface ListAggregatesIdNamesFailureAction {
  type: typeof LIST_AGGREGATES_ID_NAME_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ListAggregatesIdNamesNotModifiedAction {
  type: typeof LIST_AGGREGATES_ID_NAME_NOT_MODIFIED
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface ResetAggregateAction {
  type: typeof RESET_AGGREGATE
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface UpdateLifeCycleRequestAction<LifeCycleOnly> {
  type: typeof UPDATE_LIFECYCLE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    lifeCycleOnly: LifeCycleOnly
  }
}

export interface UpdateLifeCycleSuccessAction {
  type: typeof UPDATE_LIFECYCLE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface UpdateLifeCycleFailureAction {
  type: typeof UPDATE_LIFECYCLE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface UpdateApplicationStatusRequestAction<ApplicationStatusReason> {
  type: typeof UPDATE_APPLICATION_STATUS_REQUEST
  payload: {
    aggregateType: AggregateTypes
    applicationStatusReason: ApplicationStatusReason
  }
}

export interface UpdateApplicationStatusSuccessAction {
  type: typeof UPDATE_APPLICATION_STATUS_SUCCESS
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface UpdateApplicationStatusFailureAction {
  type: typeof UPDATE_APPLICATION_STATUS_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface CustomMethodRequestAction {
  type: typeof CUSTOM_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface CustomMethodSuccessAction {
  type: typeof CUSTOM_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface CustomMethodFailureAction {
  type: typeof CUSTOM_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ResetErrorAction {
  type: typeof RESET_AGGREGATE_ERROR
  payload: {
    aggregateType: AggregateTypes
  }
}

export type AggregateActionTypes<T> =
  | ListAggregatesRequestAction
  | ListAggregatesSuccessAction<T>
  | ListAggregatesFailureAction
  | ListAggregatesNotModified
  | GetAggregateRequestAction
  | GetAggregateSuccessAction<T>
  | GetAggregateFailureAction
  | UpdateAggregateRequestAction<T>
  | UpdateAggregateSuccessAction<T>
  | UpdateAggregateFailureAction
  | InsertAggregateRequestAction<T>
  | InsertAggregateSuccessAction<IdOnly>
  | InsertAggregateFailureAction
  | PostAggregateRequestAction
  | PostAggregateSuccessAction<T>
  | PostAggregateFailureAction
  | DeleteAggregateRequestAction
  | DeleteAggregateSuccessAction
  | DeleteAggregateFailureAction
  | ListAggregatesIdNamesRequestAction
  | ListAggregatesIdNamesSuccessAction
  | ListAggregatesIdNamesFailureAction
  | ListAggregatesIdNamesNotModifiedAction
  | UpdateLifeCycleRequestAction<LifecycleOnly>
  | UpdateLifeCycleSuccessAction
  | UpdateLifeCycleFailureAction
  | UpdateApplicationStatusRequestAction<ApplicationStatusReason>
  | UpdateApplicationStatusSuccessAction
  | UpdateApplicationStatusFailureAction
  | ResetAggregateAction
  | CustomMethodRequestAction
  | CustomMethodSuccessAction
  | CustomMethodFailureAction
  | ResetErrorAction

/**
 * Used to guard for entity actions meant for a different reducer.  Since action.type are the same
 * for all entity actions, this is a convenience function short circuit in entity reducers
 * eg
 *
 if (isAggregateActionType(action.type) &&  (action.payload.aggregateType !== AGGREGATE_MEMBER)){
        console.info("carParkReducer: Not for me - skipping");
        return state;
    }
 * @param actionType action.type supplied to reducer
 */
export const isAggregateActionType: (actionType: any) => Boolean = (actionType) =>
  actionType === LIST_AGGREGATES_REQUEST ||
  actionType === LIST_AGGREGATES_SUCCESS ||
  actionType === LIST_AGGREGATES_FAILURE ||
  actionType === LIST_AGGREGATES_ID_NAME_NOT_MODIFIED ||
  actionType === GET_AGGREGATE_REQUEST ||
  actionType === GET_AGGREGATE_SUCCESS ||
  actionType === GET_AGGREGATE_FAILURE ||
  actionType === UPDATE_AGGREGATE_REQUEST ||
  actionType === UPDATE_AGGREGATE_SUCCESS ||
  actionType === UPDATE_AGGREGATE_FAILURE ||
  actionType === INSERT_AGGREGATE_REQUEST ||
  actionType === INSERT_AGGREGATE_SUCCESS ||
  actionType === INSERT_AGGREGATE_FAILURE ||
  actionType === POST_AGGREGATE_REQUEST ||
  actionType === POST_AGGREGATE_SUCCESS ||
  actionType === POST_AGGREGATE_FAILURE ||
  actionType === DELETE_AGGREGATE_REQUEST ||
  actionType === DELETE_AGGREGATE_SUCCESS ||
  actionType === DELETE_AGGREGATE_FAILURE ||
  actionType === LIST_AGGREGATES_ID_NAME_REQUEST ||
  actionType === LIST_AGGREGATES_ID_NAME_SUCCESS ||
  actionType === LIST_AGGREGATES_ID_NAME_FAILURE ||
  actionType === LIST_AGGREGATES_ID_NAME_NOT_MODIFIED ||
  actionType === UPDATE_LIFECYCLE_REQUEST ||
  actionType === UPDATE_LIFECYCLE_SUCCESS ||
  actionType === UPDATE_LIFECYCLE_FAILURE ||
  actionType === UPDATE_APPLICATION_STATUS_FAILURE ||
  actionType === UPDATE_APPLICATION_STATUS_REQUEST ||
  actionType === UPDATE_APPLICATION_STATUS_SUCCESS ||
  actionType === UPDATE_APPLICATION_STATUS_SUCCESS ||
  actionType === RESET_AGGREGATE ||
  actionType === CUSTOM_AGGREGATE_REQUEST ||
  actionType === CUSTOM_AGGREGATE_SUCCESS ||
  actionType === CUSTOM_AGGREGATE_FAILURE
