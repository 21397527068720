import {
  AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST,
  isAggregateActionType,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
} from '../../../common/aggregate.types'
import { AssignedParkerState, AssignedParkerTypes } from './types'

const initialState: AssignedParkerState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function assignedParkerReducer(state = initialState, action: AssignedParkerTypes): AssignedParkerState {
  if (
    isAggregateActionType(action.type) &&
    action.payload.aggregateType !== AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST
  ) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
        inserted: false,
        updated: false,
        error: null,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
        error: null,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}
