import {
  AGGREGATE_PARKING_QUOTE,
  POST_AGGREGATE_FAILURE,
  POST_AGGREGATE_REQUEST,
  POST_AGGREGATE_SUCCESS,
  RESET_AGGREGATE,
  isAggregateActionType,
} from '../../../common/aggregate.types'
import { ParkingQuoteActionTypes, ParkingQuoteState } from './types'

const initialState: ParkingQuoteState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  data: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function parkingQuoteReducer(state = initialState, action: ParkingQuoteActionTypes): ParkingQuoteState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_PARKING_QUOTE) {
    return state
  }
  switch (action.type) {
    case POST_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        data: null,
      }
    case POST_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        data: action.payload.data,
      }
    case POST_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        data: null,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}
