import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface AccessRestrictionsFacility {
  id: string | null
  zoneIds: string[] | null
  facilityId: string
  version: number | null
}

export interface AccessRestrictions {
  id: string | null
  clientRef: string | null
  code?: string
  lifecycle: string
  name: string | null
  version?: number
  accessRestrictionType: string
  operatorId: string
  organisationType: string
  organisationId: string
  startDate: string
  endDate: string
  restrictDaysOfWeek: {
    sunday: boolean
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
  }
  fromMinutes: number
  toMinutes: number
  facilities: AccessRestrictionsFacility[]
  departmentIds: string[]
  teamIds: string[]
  memberPools: string[]
  organisation: {
    organisationType: string
    organisationId: string
  }
  fromHourMinutes: string | null
  toHourMinutes: string | null
  continuousTime: true
}

export type AccessRestrictionsState = AggregateState<AccessRestrictions>
export type AccessRestrictionsActionTypes = AggregateActionTypes<AccessRestrictions>

export const ACCESS_RESTRICTIONS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/access-restrictions'
