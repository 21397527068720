import {
  AuthActionTypes,
  GET_WHO_AM_I_FAILURE,
  GET_WHO_AM_I_REQUEST,
  GET_WHO_AM_I_SUCCESS,
  LogOnUser,
  SET_LOGGED_OFF,
  SET_LOGGED_ON,
} from './types'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { AppThunk } from '../../store'
import axios from 'axios'
import { authorizationHeaderConfig } from '../../config/axios/axios'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'

export function setLoggedOn(user: firebase.User): AuthActionTypes {
  return {
    type: SET_LOGGED_ON,
    payload: {
      user: user,
    },
  }
}

export function setLoggedOff(): AuthActionTypes {
  return {
    type: SET_LOGGED_OFF,
  }
}

export function getWhoAmIRequestAction(): AuthActionTypes {
  return {
    type: GET_WHO_AM_I_REQUEST,
  }
}

export function getWhoAmISuccessAction(logonUser: LogOnUser): AuthActionTypes {
  return {
    type: GET_WHO_AM_I_SUCCESS,
    payload: {
      currentUser: logonUser,
    },
  }
}

export function getWhoAmIFailureAction(error: string): AuthActionTypes {
  return {
    type: GET_WHO_AM_I_FAILURE,
    payload: {
      error: error,
    },
  }
}

export const fetchWhoAmIUser = (): AppThunk<any> => (dispatch, getState) => {
  console.debug('fetchWhoAmIUser')
  const state = getState()
  const user = state.authReducer.user

  const appConfig = getState().appConfigReducer

  const shuffleApiConfig = appConfig.shuffleApiConfig
  const tenantConfig = appConfig.tenantConfig

  if (shuffleApiConfig == null || tenantConfig == null) return dispatch(getWhoAmIFailureAction('No config supplied'))

  const shuffleApiUrl = shuffleApiConfig.shuffleApiUrl
  const tenantId = tenantConfig.tenant.id

  dispatch(getWhoAmIRequestAction())

  return user!
    .getIdToken()
    .then((token: string) => {
      return axios
        .get<LogOnUser>('/tenants/' + tenantId + '/users/whoami', authorizationHeaderConfig(shuffleApiUrl, token))
        .then((response) => {
          console.debug('fetchWhoAmIUser: have response')
          const logOnUser: LogOnUser = response.data
          logOnUser.token = token
          console.debug('fetchOperatorUser: dispatching success')
          return dispatch(getWhoAmISuccessAction(logOnUser))
        })
        .catch((_) => {
          dispatch(getWhoAmIFailureAction('User is not valid'))
          return dispatch(setNotification(NotificationType.ERROR, ['User is not valid']))
        })
    })
    .catch((_) => {
      dispatch(getWhoAmIFailureAction('User is not valid'))
      return dispatch(setNotification(NotificationType.ERROR, ['User is not valid']))
    })
}
