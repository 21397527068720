import { Button, Container, Grid, InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import firebase from 'firebase/compat/app'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import usersIcon from '../../../assets/svg/ws-b2b-icon-active-users.svg'
import pendingIcon from '../../../assets/svg/ws-b2b-icon-upcoming-gry.svg'
import searchFieldIcon from '../../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import crossFieldIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_APPLICATION,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER,
  AGGREGATE_MEMBER_ENTITLEMENT,
  AGGREGATE_TERRITORIES,
} from '../../../common/aggregate.types'
import { fetchAggregates, updateAggregate, updateLifeCycle } from '../../../common/axios-action'
import { AcknowledgeLifecycle, Lifecycle, UpdateDeleteMode } from '../../../common/types'
import ActiveAgreementMemberConfirmationModal from '../../../components/model/ActiveAgreementMemberConfirmationModal'
import AddBulkUserModal from '../../../components/model/AddBulkUserModal'
import AddUserModelForm from '../../../components/model/AddUserModelForm'
import ApplicationModelForm from '../../../components/model/ApplicationModelForm'
import DeleteModel from '../../../components/model/DeleteModel'
import ModifyMemberConfirmationModel from '../../../components/model/ModifyMemberConfirmationModel'
import { selectCurrentOperatorId, selectShuffleApiConfig } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamList,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { checkExistsAgreements } from '../../../store/common/member-assignment/check-exists'
import {
  CHECK_EXISTS_ASSIGNMENT_API_RESOURCE_PATH,
  ExistsAssignments,
} from '../../../store/common/member-assignment/types'
import { clearNotification } from '../../../store/common/notifications/action'
import { selectCurrentOrganisationId, selectLoginUserTimeZoneId } from '../../auth/types'
import { formatWithTimeZone } from '../../util/util'
import { MemberEntitlement } from './active-users/active-entitlements/types'
import ActiveUsersTab from './active-users/active-users-tab'
import {
  CarParkTenantMemberDto,
  MemberForm,
  memberSearchCriteria,
  MemberSearchCriteria,
  RelatedAgreementConfirmation,
  TabType,
  TENANT_MEMBER_API_RESOURCE_PATH,
  TENANT_MEMBER_LIST_API_RESOURCE_PATH,
  TENANT_MEMBER_PROFILE_API_RESOURCE_PATH,
} from './active-users/types'
import UserDetails from './active-users/user-details/user-details'
import PendingApprovalTab from './applications/pending-approval-tab'
import {
  MemberApplicationForm,
  MemberApplicationStatus,
  TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH,
} from './applications/types'
import { Territory } from './locations/types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
}))

interface IProps {}

const ManageUsers: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()

  const shuffleApiConfig = useSelector(selectShuffleApiConfig)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const currentUserTimeZoneId = useSelector(selectLoginUserTimeZoneId)

  const [displayAddUserModel, setDisplayAddUserModel] = useState<boolean>(false)
  const [displayDeleteModel, setDisplayDeleteModel] = useState<boolean>(false)
  const [displayApplicationModel, setDisplayApplicationModel] = useState<boolean>(false)

  const [refreshAfterBulkUpload, setRefreshAfterBulkUpload] = useState<boolean>(false)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchCriteria, setSearchCriteria] = useState<MemberSearchCriteria>(memberSearchCriteria)

  const [memberId, setMemberId] = useState<string | null>(null)
  const [memberName, setMemberName] = useState<string>('')
  const [applicationId, setApplicationId] = useState<string | null>(null)

  const [currentActiveTab, setCurrentActiveTab] = useState<TabType>(TabType.Active_Users)
  const [departmentsAndTeams, setDepartmentsAndTeams] = useState<DepartmentTeamList[]>([])
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false)

  const [currentMember, setCurrentMember] = useState<MemberForm | null>(null)

  const [addBulkModalOpen, setAddBulkModalOpen] = useState<boolean>(false)

  const [acknowledgeDelete, setAcknowledgeDelete] = useState<boolean>(false)
  const [updateDeleteMode, setUpdateDeleteMode] = useState<UpdateDeleteMode>(UpdateDeleteMode.None)
  const [displayAgreementModel, setDisplayAgreementModel] = useState<boolean>(false)
  const [activeAgreementMemberModal, setActiveAgreementMemberModal] = useState<boolean>(false)
  const [selectedAgreementConfirmation, setSelectedAgreementConfirmation] = useState<RelatedAgreementConfirmation>(
    RelatedAgreementConfirmation.NONE,
  )
  const [existsAssignment, setExistsAssignment] = useState<ExistsAssignments | null>(null)
  const [activeAssignment, setActiveAssignment] = useState<ExistsAssignments | null>(null)

  const [updatedMemberForm, setUpdatedMemberForm] = useState<MemberForm>()

  const [token, setToken] = useState('')

  const { manageUsers, departmentTeamsList, applications, member } = useSelector(
    (state: RootState) => ({
      manageUsers: state.manageUserReducer.aggregates,
      applications: state.applicationReducer.aggregates,
      departmentTeamsList: state.departmentsTeamsReducer.aggregates,
      member: state.manageUserReducer.aggregate,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!token) {
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const token = await firebase.auth().currentUser!.getIdToken()
    setToken(token)
  }

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      refreshTabs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId])

  const refreshTabs = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<CarParkTenantMemberDto>(
          AGGREGATE_MEMBER,
          TENANT_MEMBER_LIST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
      dispatch(
        fetchAggregates<MemberApplicationForm>(
          AGGREGATE_APPLICATION,
          TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            applicationStatus: MemberApplicationStatus.Pending,
          },
        ),
      )
    }
  }

  useEffect(() => {
    if (manageUsers) {
      if (state) {
        const { memberData, tabType }: any = state
        if (memberData) {
          setMemberId(memberData.id)
          setMemberName(memberData.firstName + ' ' + memberData.lastName)
          setCurrentMember(memberData)
          setShowUserDetails(true)
        }
        if (tabType) {
          setCurrentActiveTab(tabType)
        }
      }
    }
  }, [manageUsers, state])

  useEffect(() => {
    let departmentsAndTeams: DepartmentTeamList[] = []
    if (departmentTeamsList) {
      departmentTeamsList.values.map((row) => {
        departmentsAndTeams.push({
          id: row.id,
          name: row.name,
          isDepartment: true,
          departmentId: null,
        })
        row.teams.map((team) => {
          departmentsAndTeams.push({
            id: team.id,
            name: team.name,
            isDepartment: false,
            departmentId: row.id,
          })
          return ''
        })
        return ''
      })
    }
    setDepartmentsAndTeams(departmentsAndTeams)
  }, [departmentTeamsList])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  const onChangeName = (event: any) => {
    let { value } = event.target
    setSearchTerm(value)
  }

  const onClickAddUserModel = (event: any) => {
    setDisplayAddUserModel(true)
  }

  const hideAddUserModel = () => {
    dispatch(resetAggregate<Territory>(AGGREGATE_TERRITORIES))
    setDisplayAddUserModel(false)
  }

  const onClickDeleteUserModel = async (event: any) => {
    if (token && member && member.id && currentOperatorId && currentOrganisationId && shuffleApiConfig?.shuffleApiUrl) {
      let existsAssignments = await checkExistsAgreements(
        member,
        CHECK_EXISTS_ASSIGNMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':id', member.id),
        shuffleApiConfig?.shuffleApiUrl,
        token,
      )
      if (existsAssignments.exists) {
        setExistsAssignment(existsAssignments)
      } else {
        setExistsAssignment(null)
      }
      setDisplayDeleteModel(true)
    }
  }

  const hideDeleteUserModel = () => {
    setDisplayDeleteModel(false)
    setExistsAssignment(null)
  }

  const hideWhenDeleteSuccess = () => {
    setDisplayDeleteModel(false)
    setShowUserDetails(false)
    setCurrentActiveTab(TabType.Active_Users)
    navigate('/users/manage-users')
    refreshTabs()
  }

  const hideWhenApplicationStatus = () => {
    setApplicationId(null)
    setDisplayApplicationModel(false)
    setShowUserDetails(false)
    setCurrentActiveTab(TabType.Pending_Approval)
    navigate('/users/manage-users')
    refreshTabs()
  }

  const hideApplicationModel = () => {
    setDisplayApplicationModel(false)
  }

  const confirmMemberDelete = (acknowledge: boolean) => {
    if (memberId && currentOperatorId && currentOrganisationId && memberId) {
      let lifecycleOnly: AcknowledgeLifecycle = { lifecycle: Lifecycle.Deleted, acknowledge: acknowledge }
      dispatch(
        updateLifeCycle<MemberForm>(
          AGGREGATE_MEMBER,
          TENANT_MEMBER_PROFILE_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          memberId,
          lifecycleOnly,
        ),
      )
    }
    hideDeleteUserModel()
  }

  const onClickEdit = (row: MemberForm) => {
    setMemberId(row.id)
    setMemberName(row.firstName + ' ' + row.lastName)
    setCurrentMember(row)
    setShowUserDetails(true)
  }

  const onClickApplicationEdit = (row: MemberApplicationForm) => {
    setApplicationId(row.id)
    setDisplayApplicationModel(true)
  }

  const onClickFilterByName = () => {
    setSearchCriteria({
      ...searchCriteria,
      firstName: searchTerm ? searchTerm : null,
    })
  }

  const onClickTab = (tabType: TabType) => {
    setCurrentActiveTab(tabType)
  }

  const hideShowUserDetails = () => {
    dispatch(clearNotification())
    setShowUserDetails(false)
    dispatch(resetAggregate<MemberEntitlement>(AGGREGATE_MEMBER_ENTITLEMENT))
    dispatch(resetAggregate<Territory>(AGGREGATE_TERRITORIES))
  }

  const cancelAddBulkModal = () => {
    setAddBulkModalOpen(false)
  }
  const confirmAddBulkModal = () => {
    setAddBulkModalOpen(false)
    setRefreshAfterBulkUpload(!refreshAfterBulkUpload)
  }
  const onClickAddBulkUsers = () => {
    setAddBulkModalOpen(true)
  }

  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  const closeModal = () => {
    setDisplayAgreementModel(false)
    setUpdateDeleteMode(UpdateDeleteMode.None)
    setSelectedAgreementConfirmation(RelatedAgreementConfirmation.NONE)
  }

  const updateMemberMember = (cancelAgreement: RelatedAgreementConfirmation) => {
    if (updatedMemberForm && currentOperatorId && currentOrganisationId) {
      updatedMemberForm.relatedAgreementConfirmation = cancelAgreement
      dispatch(
        updateAggregate<MemberForm>(
          AGGREGATE_MEMBER,
          TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          updatedMemberForm,
        ),
      )
    }
    setDisplayAgreementModel(false)
    setActiveAgreementMemberModal(false)
    setSelectedAgreementConfirmation(RelatedAgreementConfirmation.NONE)
  }

  const closeActiveAgreementMemberModal = () => {
    setActiveAgreementMemberModal(false)
    setUpdateDeleteMode(UpdateDeleteMode.None)
    setSelectedAgreementConfirmation(RelatedAgreementConfirmation.NONE)
  }

  return (
    <>
      {!showUserDetails && (
        <>
          <AddUserModelForm
            display={displayAddUserModel}
            hideAddUserModel={hideAddUserModel}
            departmentsAndTeams={departmentsAndTeams}
          />

          <AddBulkUserModal
            display={addBulkModalOpen}
            cancelModel={cancelAddBulkModal}
            confirmModel={confirmAddBulkModal}
          />

          <ApplicationModelForm
            applicationId={applicationId}
            display={displayApplicationModel}
            hideApplicationModel={hideApplicationModel}
            hideWhenApplicationStatus={hideWhenApplicationStatus}
            departmentsAndTeams={departmentsAndTeams}
          />

          <div className="page-content-wrapper">
            <Container className="page-content-header-wrapper">
              <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
                <Grid item xs={3} className="left-col sidebar top-section">
                  <Grid container spacing={0} className="responsive-title-wrapper">
                    <Grid item xs={12}>
                      <h2 className="on-demand-h2">
                        <a className="back-icon" href="/users">
                          <img className="icon-back-arrow" src={backIcon} alt="back" />
                          Manage all <br /> Shuffle users
                        </a>
                      </h2>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} className="responsive-content-wrapper">
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Search Name"
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        value={searchTerm}
                        onChange={onChangeName}
                        fullWidth
                        InputProps={{
                          className: 'shuffleInput search-input-text-field',
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="search-field-icon"
                                src={searchFieldIcon}
                                alt="Search"
                                onClick={() => onClickFilterByName()}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start" className="cross-icon">
                              {searchTerm && (
                                <img
                                  className="cross-field-icon"
                                  src={crossFieldIcon}
                                  alt="img"
                                  onClick={() => clearSearchTerm()}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                    <Grid item xs={6}>
                      <Button
                        className={classes.shuffleButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ marginTop: '27px' }}
                        onClick={(event) => onClickAddUserModel(event)}
                      >
                        Add new user
                      </Button>
                    </Grid>
                    <Grid item xs={6} textAlign="end">
                      <Button
                        className="outlined-mono"
                        variant="outlined"
                        color="secondary"
                        style={{ marginTop: '27px' }}
                        size="large"
                        onClick={() => onClickAddBulkUsers()}
                      >
                        Bulk add users
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>

            <Container className="page-content-body-wrapper settings-section">
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={9} className="right-col">
                  <Grid item xs={12} className="tabs-panel">
                    <div
                      className={
                        currentActiveTab === TabType.Active_Users
                          ? 'tab selected-tab active-users-tab'
                          : 'tab active-users-tab'
                      }
                      onClick={() => onClickTab(TabType.Active_Users)}
                    >
                      <img className="tab-icon icon-active-users" src={usersIcon} alt="Active Users" />
                      <h4>Active Users</h4>
                      <p>{manageUsers && manageUsers.totalCount ? manageUsers.totalCount : '0'} parkers</p>
                    </div>

                    <div
                      className={
                        currentActiveTab === TabType.Pending_Approval
                          ? 'tab selected-tab pending-approval-tab'
                          : 'tab pending-approval-tab'
                      }
                      onClick={() => onClickTab(TabType.Pending_Approval)}
                    >
                      <img className="tab-icon icon-pending-approval" src={pendingIcon} alt="Pending approval" />
                      <h4>Pending approval</h4>
                      <p>{applications && applications.totalCount ? applications.totalCount : '0'} users</p>
                    </div>
                  </Grid>

                  {currentActiveTab === TabType.Active_Users && (
                    <ActiveUsersTab
                      onClickEdit={onClickEdit}
                      searchTerm={searchTerm}
                      displayAddUserModel={displayAddUserModel}
                      refreshAfterBulkUpload={refreshAfterBulkUpload}
                    />
                  )}

                  {currentActiveTab === TabType.Pending_Approval && (
                    <PendingApprovalTab
                      displayApplicationModel={displayApplicationModel}
                      onClickApplicationEdit={onClickApplicationEdit}
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      )}
      {showUserDetails && memberId && (
        <>
          <DeleteModel
            display={displayDeleteModel}
            hideModel={hideDeleteUserModel}
            hideWhenDeleteSuccess={hideWhenDeleteSuccess}
            member={member}
            confirmMemberDelete={confirmMemberDelete}
            existsAssignment={existsAssignment}
            activeAssignment={activeAssignment}
            setAcknowledgeDelete={setAcknowledgeDelete}
            mode={updateDeleteMode}
            acknowledgeDelete={acknowledgeDelete}
          />

          <ModifyMemberConfirmationModel
            display={displayAgreementModel}
            cancelModel={closeModal}
            confirmModel={updateMemberMember}
            title={existsAssignment?.message}
            cancelButtonName="Cancel"
            confirmButtonName="Proceed"
            selectedAgreementConfirmation={selectedAgreementConfirmation}
            setSelectedAgreementConfirmation={setSelectedAgreementConfirmation}
          />

          <ActiveAgreementMemberConfirmationModal
            display={activeAgreementMemberModal}
            cancelModel={closeActiveAgreementMemberModal}
            confirmModel={updateMemberMember}
            title={activeAssignment?.message}
            cancelButtonName="Cancel"
            confirmButtonName="Proceed"
            selectedAgreementConfirmation={selectedAgreementConfirmation}
            setSelectedAgreementConfirmation={setSelectedAgreementConfirmation}
          />

          <div className="page-content-wrapper">
            <Container className="page-content-header-wrapper">
              <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
                <Grid item xs={3} className="left-col sidebar top-section">
                  <Grid container spacing={0} className="responsive-title-wrapper">
                    <Grid item xs={12}>
                      <h2 className="on-demand-h2">
                        <div className="back-icon" onClick={hideShowUserDetails}>
                          <img className="icon-back-arrow" src={backIcon} alt="back" style={{ cursor: 'pointer' }} />
                          {currentMember ? currentMember.firstName + ' ' + currentMember.lastName : ''}
                        </div>
                      </h2>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} className="responsive-content-wrapper">
                    <Grid item xs={12}>
                      <p>
                        {currentMember && currentMember.lastActivityInstant
                          ? 'Last active ' +
                            formatWithTimeZone(currentMember.lastActivityInstant, 'd MMM yyyy', currentUserTimeZoneId)
                          : ''}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                    <Grid item xs={12}>
                      <Button
                        className="outlined-mono"
                        variant="outlined"
                        color="secondary"
                        size="large"
                        style={{ width: '140px' }}
                        onClick={onClickDeleteUserModel}
                      >
                        Delete User
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>

            <UserDetails
              memberId={memberId}
              memberName={memberName}
              hideShowUserDetails={hideShowUserDetails}
              setUpdateDeleteMode={setUpdateDeleteMode}
              existsAssignment={existsAssignment}
              setExistsAssignment={setExistsAssignment}
              activeAssignment={activeAssignment}
              setActiveAssignment={setActiveAssignment}
              setDisplayAgreementModel={setDisplayAgreementModel}
              setActiveAgreementMemberModal={setActiveAgreementMemberModal}
              selectedAgreementConfirmation={selectedAgreementConfirmation}
              setUpdatedMemberForm={setUpdatedMemberForm}
            />
          </div>
        </>
      )}
    </>
  )
}

export default ManageUsers
