import {
  AggregateState,
  EmployeeClass,
  IdClientRefName,
  MemberPool,
  MobileDto,
  OrganisationType,
  SearchCriteria,
} from '../../../../common/types'
import { AggregateActionTypes } from '../../../../common/aggregate.types'

export interface MemberApplicationForm {
  id: string | null
  code: string | null
  email: string | null
  mobile: MobileDto | null
  firstName: string | null
  lastName: string | null
  memberId: string | null
  departmentId: string | null
  teamId: string | null
  baseFacilityId: string | null
  memberPool: MemberPool | null
  carParkTenantId: string | null
  employeeClass: EmployeeClass | null
  contractEndDate: string | null
  applicationStatus: MemberApplicationStatus | null
  organisationType: OrganisationType | null
  operatorId: string | null
  tenantId: string | null
  pendingInstant: number | null
  processedInstant: number | null
  declinedReason: string | null
  departmentTeamId: string | null // frontend only
}

export enum MemberApplicationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
}

export const memberApplicationInitialValue: MemberApplicationForm = {
  id: null,
  code: null,
  email: null,
  mobile: null,
  firstName: null,
  lastName: null,
  memberId: null,
  departmentId: null,
  teamId: null,
  baseFacilityId: null,
  memberPool: null,
  carParkTenantId: null,
  employeeClass: null,
  contractEndDate: null,
  applicationStatus: null,
  organisationType: null,
  operatorId: null,
  tenantId: null,
  pendingInstant: null,
  processedInstant: null,
  declinedReason: null,
  departmentTeamId: null, // frontend only
}

export interface MemberApplicationSearchCriteria extends SearchCriteria {
  carParkTenantId: string | null
  operatorId: string | null
  distributionChannelId: string | null
  code: string | null
  clientRef: string | null
  firstName: string | null
  email: string | null
  teamId: string | null
  departmentId: string | null
  organisationType: OrganisationType | null
  tenantId: string | null
}

export const memberApplicationSearchCriteria: MemberApplicationSearchCriteria = {
  carParkTenantId: null,
  operatorId: null,
  distributionChannelId: null,
  code: null,
  clientRef: null,
  firstName: null,
  email: null,
  teamId: null,
  organisationType: null,
  tenantId: null,
  page: null,
  size: null,
  sortBy: null,
  sortDirection: null,
  departmentId: null,
  lifecycle: null,
}

export const memberApplicationStatus: IdClientRefName[] = [
  {
    name: 'Pending',
    id: 'Pending',
  },
  {
    name: 'Approved',
    id: 'Approved',
  },
  {
    name: 'Declined',
    id: 'Declined',
  },
]

export enum TabType {
  Active_Users = 'Active_Users',
  Pending_Approval = 'Pending_Approval',
}

export interface ApplicationStatusReason {
  status: MemberApplicationStatus
  reason: string | null
}

export type ApplicationState = AggregateState<MemberApplicationForm>
export type ApplicationActionTypes = AggregateActionTypes<MemberApplicationForm>

export const TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/member-applications'

export const TENANT_MEMBER_APPLICATIONS_COMPLETE_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/member-applications/:id'
