export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 16

export const validEmail = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(value) ? 'Email is not wel-formed' : null

export const isValidEmail = (value: string) =>
  value && !/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}/.test(value) ? false : true

export const isValidPhone = (value: string) => (value && !/^04\d{8}$/.test(value) ? false : true)

export const isValidAll = (password: string, repeatPassword: string) => {
  if (
    validPasswordLength(password) &&
    validateHasOneUpper(password) &&
    validateHasOneLower(password) &&
    validateHasOneDigit(password) &&
    validateHasOneSpecialCharacter(password) &&
    passwordMatch(password, repeatPassword)
  ) {
    return true
  } else {
    return false
  }
}

export const validPasswordLength = (str: string) => {
  if (str && str.length >= PASSWORD_MIN_LENGTH && str.length <= PASSWORD_MAX_LENGTH) {
    return true
  }
  return false
}

export const validateHasOneUpper = (str: string) => {
  return /(?=.*[A-Z])/.test(str)
}

export const validateHasOneLower = (str: string) => {
  return /(?=.*[a-z])/.test(str)
}

export const validateHasOneDigit = (str: string) => {
  return /(?=.*\d)/.test(str)
}

export const validateHasOneSpecialCharacter = (str: string) => {
  return /[_!@#$%^&*.,?]/.test(str)
}

export const passwordMatch = (password: string, repeatPassword: string) => {
  return password === repeatPassword
}
