import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface Tenant {
  id: string
  name: string
}

export type TenantState = AggregateState<Tenant>
export type TenantActionTypes = AggregateActionTypes<Tenant>

export const TENANT_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants'
