import { ErrorActionTypes, RESET_ERROR, SET_ERROR } from './types'

export function setError(error: string): ErrorActionTypes {
  return {
    type: SET_ERROR,
    payload: {
      error: error,
    },
  }
}

export function resetError(): ErrorActionTypes {
  return {
    type: RESET_ERROR,
  }
}
