import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { tenantConfigFailure, tenantConfigRequest, tenantConfigSuccess } from './actions'
import { RootState } from '../../store'

export const getTenantConfigThunk =
  (hostName: string): ThunkAction<void, RootState, unknown, Action> =>
  async (dispatch, _) => {
    dispatch(tenantConfigRequest())
    const tenantPath = '/_tenant/tenant_admin/' + hostName + '.json'
    console.debug('Loading tenant config from ' + tenantPath)
    fetch(tenantPath)
      .then((response) => response.json())
      .then((json) => dispatch(tenantConfigSuccess(json)))
      .catch((error) => dispatch(tenantConfigFailure(error)))
  }
