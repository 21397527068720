import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { getAuth } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import yourLogo from '../../../assets/images/your-logo.png'
import shuffleLogo from '../../../assets/svg/Shuffle-Logo.svg'
import hamburgerIcon from '../../../assets/svg/hamburger.svg'
import utilisationIcon from '../../../assets/svg/utilisation-dashboard-icon.svg'
import assignParkingIcon from '../../../assets/svg/ws-b2b-icon-assign-parking-2.svg'
import blockDatesIcon from '../../../assets/svg/ws-b2b-icon-block-out-dates.svg'
import bookParkingIcon from '../../../assets/svg/ws-b2b-icon-book_parking.svg'
import prodsCapacitiesIcon from '../../../assets/svg/ws-b2b-icon-car_park.svg'
import guestIcon from '../../../assets/svg/ws-b2b-icon-consumer_cust.svg'
import controlCentreIcon from '../../../assets/svg/ws-b2b-icon-operations.svg'
import transactionsIcon from '../../../assets/svg/ws-b2b-icon-receipt-2.svg'
import reportIcon from '../../../assets/svg/ws-b2b-icon-report_bar_chart-2.svg'
import reservedIcon from '../../../assets/svg/ws-b2b-icon-reserved-2.svg'
import manageParkersIcon from '../../../assets/svg/ws-b2b-icon-staff.svg'
import selfRegIcon from '../../../assets/svg/ws-b2b-icon-toggle.svg'
import closeIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { AGGREGATE_BRANDING, AGGREGATE_TENANT } from '../../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { OrganisationType } from '../../../common/types'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { LOCAL_STORAGE_SELECTED_TENANT_ID } from '../../../config/constants'
import {
  selectAvatarName,
  selectCurrentFirebaseUser,
  selectCurrentOrganisationId,
  selectCurrentUser,
  selectLoginName,
} from '../../../pages/auth/types'
import {
  ROUTE_HOME,
  ROUTE_OPERATIONS,
  ROUTE_OPERATIONS_CONTROL_CENTRE,
  ROUTE_SETTINGS,
  ROUTE_TENANT_SELECTOR,
} from '../../../pages/util/routes'
import { RootState } from '../../../store'
import {
  BRANDING_TENANT_API_RESOURCE_PATH,
  Branding,
  selectCurrentBranding,
  selectCurrentBrandingImageUrl,
} from '../../../store/common/branding/types'
import { TENANT_API_RESOURCE_PATH, Tenant } from '../../../store/common/tenant-selector/types'

interface IProps {
  isHideBrandingImage: boolean
}

const PrivateHeader: React.FC<IProps> = (props) => {
  const { isHideBrandingImage } = props

  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const user = useSelector(selectCurrentFirebaseUser)
  const avatarName = useSelector(selectAvatarName)
  const loginUserName = useSelector(selectLoginName)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const currentBranding = useSelector(selectCurrentBranding)
  const currentBrandingImageUrl = useSelector(selectCurrentBrandingImageUrl)
  const currentUser = useSelector(selectCurrentUser)

  const { tenantList } = useSelector((state: RootState) => ({
    tenantList: state.tenantSelectorReducer.aggregates.values,
  }))

  const [tenant, setTenant] = useState<string>('')
  const [isUserOperator, setIsUserOperator] = useState<boolean>(false)

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  // Fetch branding
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && !isHideBrandingImage && !currentBranding) {
      dispatch(
        fetchAggregate<Branding>(
          AGGREGATE_BRANDING,
          BRANDING_TENANT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, isHideBrandingImage, currentBranding])

  // Fetch tenant list
  useEffect(() => {
    if (currentOperatorId && currentUser && currentUser.organisationType === OrganisationType.Operator) {
      dispatch(
        fetchAggregates<Tenant>(
          AGGREGATE_TENANT,
          TENANT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId) + '/list',
          null,
        ),
      )
      setIsUserOperator(true)
      let tenantId = localStorage.getItem(LOCAL_STORAGE_SELECTED_TENANT_ID)
      if (!tenantId) {
        navigate(ROUTE_TENANT_SELECTOR)
      }
    } else {
      setIsUserOperator(false)
    }
    // eslint-disable-next-line
  }, [dispatch, currentOperatorId, currentUser])

  // Set tenant if present is local storage
  useEffect(() => {
    let tenantId = localStorage.getItem(LOCAL_STORAGE_SELECTED_TENANT_ID)
    if (tenantId) {
      setTenant(tenantId)
    }
  }, [])

  const onClickLogOut = () => {
    localStorage.clear()
    const auth = getAuth()
    auth.signOut()
  }

  const isSelected = (link: string) => {
    if (pathname) {
      return pathname.includes(link)
    }
  }

  const handleChangeTenant = (event: SelectChangeEvent<typeof tenant>) => {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_TENANT_ID, event.target.value)
    setTenant(event.target.value)
    if (pathname === ROUTE_HOME) {
      window.location.reload()
    } else {
      navigate(ROUTE_HOME)
    }
  }

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <div id="header-wrapper" className="login-header">
        <div id="top-nav-header">
          <div className="top-nav-holder">
            <ul id="shuffle-home-nav">
              <li className="selected">
                <a href={!isHideBrandingImage ? '/home' : '/tenant-selector'} className="top-nav" id="shuffle-tab">
                  Shuffle
                </a>
              </li>
            </ul>
            <ul id="shuffle-top-nav">
              {isUserOperator && (
                <li>
                  <div className="header-tenant-selector-wrapper">
                    <span className="selector-label">Tenant:</span>
                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                      <Select
                        value={tenant}
                        onChange={handleChangeTenant}
                        variant="outlined"
                        fullWidth
                        name="tenant"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        displayEmpty
                      >
                        {tenant === '' && (
                          <MenuItem disabled value="">
                            Select Tenant
                          </MenuItem>
                        )}
                        {tenantList
                          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                          .map((tenant: Tenant) => (
                            <MenuItem key={tenant.id} value={tenant.id}>
                              {' '}
                              {tenant.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </li>
              )}
              <li>
                <a
                  href="https://shufflehelp.wilsonparking.com.au/hc/en-au"
                  target="_blank"
                  rel="noreferrer"
                  className="top-nav"
                  id="support-tab"
                >
                  Support
                </a>
              </li>
              <li>
                <a
                  href="https://www.wilsonparking.com.au/Business/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                  className="top-nav"
                  id="contact-tab"
                >
                  Contact Wilson Parking
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="header" className="main-nav">
          <div className="logo-holder row">
            <a id="shuffle-logo" href={!isHideBrandingImage ? '/home' : '/tenant-selector'}>
              <img src={shuffleLogo} alt="Shuffle" />
            </a>
            {!isHideBrandingImage && (
              <a id="your-logo" href="/home">
                <img src={currentBrandingImageUrl ? currentBrandingImageUrl : yourLogo} alt="Your Logo" />
              </a>
            )}
          </div>
          <div className="nav-holder row">
            {!isHideBrandingImage && (
              <ul id="shuffle-main-nav">
                <li className={isSelected('home') ? 'selected' : ''}>
                  <a href="/home" className="main-nav-item">
                    Home
                  </a>
                </li>
                <li className={(isSelected('assigned') ? 'selected' : '') + ' has-children'}>
                  <a href="/assigned" className="main-nav-item">
                    Assigned
                  </a>
                  <ul className="dropdown">
                    <li>
                      <a href="/assigned/manage-parkers">
                        <img className="menu-icon manage-parkers-icon" src={manageParkersIcon} alt="Manage Parkers" />
                        Manage parkers
                      </a>
                    </li>
                    <li>
                      <a href="/assigned/assign-parking">
                        <img className="menu-icon assign-parking-icon" src={assignParkingIcon} alt=" Assign Parking" />
                        Assign parking
                      </a>
                    </li>
                    <li>
                      <a href="/assigned/manage-bay">
                        <img className="menu-icon assign-parking-icon" src={reservedIcon} alt=" Manage Bay" />
                        Manage bay
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={(isSelected('on-demand') ? 'selected' : '') + ' has-children'}>
                  <a href="/on-demand" className="main-nav-item">
                    On-demand
                  </a>
                  <ul className="dropdown">
                    <li>
                      <a href="/on-demand/transactions">
                        <img className="menu-icon transactions-icon" src={transactionsIcon} alt="Transactions" />
                        Transactions
                      </a>
                    </li>
                    <li>
                      <a href="/on-demand/book-parking">
                        <img className="menu-icon book-parking-icon" src={bookParkingIcon} alt="Book parking" />
                        Book parking
                      </a>
                    </li>
                    <li>
                      <a href="/on-demand/product-capacities">
                        <img
                          className="menu-icon products-capacities-icon"
                          src={prodsCapacitiesIcon}
                          alt="Products and capacities"
                        />
                        Products and capacities
                      </a>
                    </li>
                    <li>
                      <a href="/on-demand/block-out-dates">
                        <img className="menu-icon block-out-dates-icon" src={blockDatesIcon} alt="Block out dates" />
                        Block out dates
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={(isSelected('guest') ? 'selected' : '') + ' has-children'}>
                  <a href="/guest" className="main-nav-item">
                    Guest
                  </a>
                  <ul className="dropdown">
                    <li>
                      <a href="/guest/book-guest-parking">
                        <img className="menu-icon book-for-guest-icon" src={guestIcon} alt="Book for a guest" />
                        Book for a guest
                      </a>
                    </li>
                    <li>
                      <a href="/guest/transactions">
                        <img className="menu-icon transactions-icon" src={transactionsIcon} alt="Transactions" />
                        Transactions
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={(isSelected('users') ? 'selected' : '') + ' has-children'}>
                  <a href="/users" className="main-nav-item">
                    Users
                  </a>
                  <ul className="dropdown">
                    <li>
                      <a href="/users/manage-users">
                        <img className="menu-icon book-for-guest-icon" src={guestIcon} alt="Manage all users" />
                        Manage users
                      </a>
                    </li>
                    <li>
                      <a href="/users/bulk-registrations">
                        <img
                          className="menu-icon book-for-guest-icon"
                          src={manageParkersIcon}
                          alt="Bulk registrations"
                        />
                        Bulk registrations
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={(isSelected('operations') ? 'selected' : '') + ' has-children'}>
                  <a href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE} className="main-nav-item">
                    Operations
                  </a>
                  <ul className="dropdown">
                    <li>
                      <a href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE}>
                        <img
                          className="menu-icon control-centre-icon"
                          src={controlCentreIcon}
                          alt="controlCentreIcon"
                        />
                        Control centre
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={(isSelected('utilisation') ? 'selected' : '') + ' has-children'}>
                  <a href="/utilisation" className="main-nav-item">
                    Utilisation
                  </a>
                  <ul className="dropdown">
                    <li>
                      <a href="/utilisation/dashboard">
                        <img className="menu-icon utilisation-dashboard-icon" src={utilisationIcon} alt="dashboard" />
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a href="/utilisation/report">
                        <img className="menu-icon transactions-icon" src={reportIcon} alt="Reports" />
                        Reports
                      </a>
                    </li>
                  </ul>
                </li>
                <li className={(isSelected('settings') ? 'selected' : '') + ' has-children'}>
                  <a href={ROUTE_SETTINGS} className="main-nav-item">
                    Settings
                  </a>
                  <ul className="dropdown pull-left">
                    <li>
                      <a href="/settings/self-registration">
                        <img className="menu-icon self-reg-menu-icon" src={selfRegIcon} alt="Self registration link" />
                        Self registration link
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </div>
          <div className="user">
            <a id="user-loggedin" href="/">
              {avatarName}
            </a>
            <ul className="dropdown pull-left">
              <li>
                <a href="/">
                  {loginUserName} <span className="user-email">{user?.email}</span>
                </a>
              </li>
              <li>
                <a href="/login" onClick={() => onClickLogOut()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
          <div className="mobile-menu">
            {isMenuOpen ? (
              <img className="mobile-menu-close-icon" src={closeIcon} alt="closeIcon" onClick={menuToggle} />
            ) : (
              <img className="mobile-menu-icon" src={hamburgerIcon} alt="hamburgerIcon" onClick={menuToggle} />
            )}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <>
          <div className="modal-mobile-wrapper mobile-only">
            <Box className="mobile-menu-modal">
              <div className="nav-holder-mobile row">
                <ul id="shuffle-main-nav-mobile">
                  {isUserOperator && (
                    <li>
                      <div className="header-tenant-selector-wrapper-mob">
                        <span className="selector-label">Tenant:</span>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={tenant}
                            onChange={handleChangeTenant}
                            variant="outlined"
                            fullWidth
                            name="tenant"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            displayEmpty
                          >
                            {tenant === '' && (
                              <MenuItem disabled value="">
                                Select Tenant
                              </MenuItem>
                            )}
                            {tenantList
                              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                              .map((tenant: Tenant) => (
                                <MenuItem key={tenant.id} value={tenant.id}>
                                  {' '}
                                  {tenant.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </li>
                  )}
                  <li className="selected">
                    <a href="/home" className="main-nav-item">
                      Home
                    </a>
                  </li>
                  <li className="has-children">
                    <a href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE} className="main-nav-item">
                      Operations
                    </a>
                    <a href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE} className="child-nav-item">
                      <img
                        className="mobile-menu-li-icon operations-icon"
                        src={controlCentreIcon}
                        alt="controlCentreIcon"
                      />
                      Control centre
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile-menu-footer">
                <div className="user-mobile">
                  <a id="user-loggedin" href="/">
                    {avatarName}
                  </a>
                  <p className="user-details">
                    <a href="/">
                      <strong>{loginUserName}</strong>
                      <br />
                      <span className="user-email">{user?.email}</span>
                    </a>
                  </p>
                </div>
                <ul className="user-logout">
                  <li>
                    <a href="/login" onClick={() => onClickLogOut()}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </Box>
          </div>
          <style>{`
            #footer-text {
              display: none;
            }
            body {
              overflow: hidden;
            }
          `}</style>
        </>
      )}
    </>
  )
}

export default PrivateHeader
