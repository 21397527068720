export const GUEST_BAY_TYPE_RESERVED = 'Reserved'
export const GUEST_BAY_TYPE_UNRESERVED = 'Unreserved'
export const GUEST_BAY_TYPE_ACCESS = 'Access'

export interface GuestBookingDetail {
  firstName: string
  lastName: string
  email: string
  companyName: string
  phone: string
}

export interface DateAndTimeInterval {
  startDate: Date | null
  startTime: Date | null
  endDate: Date | null
  endTime: Date | null
}
export interface DateAndTimeIntervalError {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}

export const START_DATE_IS_REQUIRED = 'Arrive date is required'
export const START_TIME_IS_REQUIRED = 'Arrive time is required'
export const END_DATE_IS_REQUIRED = 'Depart date is required'
export const END_TIME_IS_REQUIRED = 'Depart time is required'

export const START_DATE_IS_INVALID = 'Arrive date is invalid'
export const START_TIME_IS_INVALID = 'Arrive time is invalid'
export const END_DATE_IS_INVALID = 'Depart date is invalid'
export const END_TIME_IS_INVALID = 'Depart time is invalid'

export const START_DATE_MUST_BE_LATER_THAN_TODAY = 'Arrive date must be later than today'
export const END_DATE_MUST_BE_LATER_THAN_START_DATE = 'Depart date must be later than arrive date'
export const END_TIME_MUST_BE_LATER_THAN_START_TIME = 'Depart time must be later than arrive time'

export const THIS_IS_REQUIRED_FIELD = 'This field is required'
export const INVALID_EMAIL = 'Invalid email address'
export const INVALID_PHONE = 'Invalid mobile phone number'
