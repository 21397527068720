import { GateDeliveryStatus } from '../update-entry-status/types'

export interface ScheduledAccessesRequest {
  agreementId: string
  accessPointId: string
  reasonType: string
  note: string
}

export interface ScheduledAccessesResponse {
  assistedAccessId: string
  gateDeliveryStatus: GateDeliveryStatus | null
}

export const ASSISTED_SCHEDULED_ACCESSES_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId/assisted-scheduled-accesses'

export interface UnscheduledAccessesRequest {
  facilityId: string
  accessPointId: string
  reasonType: string
  note: string | null
  firstName: string | null
  lastName: string | null
  mobileNumber: string | null
  vehiclePlate: string | null
  companyName: string | null
  category: string
  email: string | null
}

export interface UnscheduledAccessesResponse {
  assistedAccessId: string
  gateDeliveryStatus: GateDeliveryStatus | null
}

export const ASSISTED_UNSCHEDULED_ACCESSES_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId/assisted-unscheduled-accesses'
