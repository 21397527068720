import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Autocomplete } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { format, getHours, getMinutes, isBefore, isEqual, isValid, setHours, setMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetAggregateError } from '../../../common/aggregate-actions'
import {
  AGGREGATE_ACCESS_RESTRICTIONS,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS,
} from '../../../common/aggregate.types'
import { fetchAggregates, insertAggregate, updateAggregate } from '../../../common/axios-action'
import { ErrorResponse } from '../../../common/types'
import { timeSlots } from '../../../common/utility'
import Loading from '../../../components/ui/Loading'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import {
  ACCESS_RESTRICTIONS_API_RESOURCE_PATH,
  AccessRestrictions,
  AccessRestrictionsFacility,
} from '../../../store/common/access-restrictions/types'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { CustomCalendarIcon } from '../../util/custom-icon'
import { getAPTimeFormDate, getDateFormAPTime } from '../../util/util'
import MultiDepartmentCheckbox from './multi-department-checkbox'
import SingleDepartmentCheckbox from './single-department-checkbox'
import {
  ORDERS_FOR_ACCESS_RESTRICTIONS_COUNT_POST_API_RESOURCE_PATH,
  StartAndEndBlockOut,
  StartAndEndBlockOutError,
} from './types'
import { checkDateAndTimeError } from './util'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    maxWidth: '204px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
    paddingLeft: '0px',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '705px',
  },
}))
interface IProps {
  showEditBlockOutDate: boolean
  closeEditBlockOutDateModal: () => void
  onEditBlockOutDateModalSave: () => void
  onEditBlockOutMultiCount: (requestPayload: AccessRestrictions) => void
  selectedBlockOut: AccessRestrictions | null
}

const EditBlockOutDate: React.FC<IProps> = ({
  showEditBlockOutDate,
  closeEditBlockOutDateModal,
  onEditBlockOutDateModalSave,
  onEditBlockOutMultiCount,
  selectedBlockOut,
}: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isAllDay, setIsAllDay] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [isStartDateBeforeToday, setIsStartDateBeforeToday] = useState<boolean>(false)
  const [isEndDateBeforeToday, setIsEndDateBeforeToday] = useState<boolean>(false)
  const [carParkTenancyFormattedList, setCarParkTenancyFormattedList] = useState<any>(null)
  const [departmentTeamsFormattedList, setDepartmentTeamsFormattedList] = useState<any>(null)
  const [allDepartmentTeams, setAllDepartmentTeams] = useState<boolean>(false)
  const [requestPayload, setRequestPayload] = useState<AccessRestrictions>()
  const [refresh, setRefresh] = useState<boolean>(false)
  const {
    carParkTenancyList,
    departmentTeamsList,
    accessRestrictionsUpdated,
    accessRestrictionsUpdating,
    accessRestrictionsError,
    ordersForAccessRestrictionsInserted,
    ordersForAccessRestrictionsInserting,
    ordersForAccessRestrictionsIdOnly,
    ordersForAccessRestrictionsError,
  }: any = useSelector((state: RootState) => ({
    carParkTenancyList: state.myListTenancyReducer.aggregates.values,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    accessRestrictionsUpdated: state.accessRestrictionsReducer.updated,
    accessRestrictionsUpdating: state.accessRestrictionsReducer.updating,
    accessRestrictionsError: state.accessRestrictionsReducer.error,
    ordersForAccessRestrictionsInserted: state.ordersForAccessRestrictionsReducer.inserted,
    ordersForAccessRestrictionsInserting: state.ordersForAccessRestrictionsReducer.inserting,
    ordersForAccessRestrictionsIdOnly: state.ordersForAccessRestrictionsReducer.idOnly,
    ordersForAccessRestrictionsError: state.ordersForAccessRestrictionsReducer.error,
  }))
  const [startAndEndBlockOut, setStartAndEndBlockOut] = useState<StartAndEndBlockOut>({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  })
  const [startAndEndBlockOutError, setStartAndEndBlockOutError] = useState<StartAndEndBlockOutError>({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  })
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  // For Formatted Car Park Tenancy List
  useEffect(() => {
    let carParkTenancyListDeepCopy: any[] = JSON.parse(JSON.stringify(carParkTenancyList))
    carParkTenancyListDeepCopy.forEach((tenancy: any) => {
      if (selectedBlockOut) {
        let facilities: AccessRestrictionsFacility[] = selectedBlockOut.facilities
        let facilitiy = facilities.find((facilitiy: AccessRestrictionsFacility) => facilitiy.facilityId === tenancy.id)
        if (facilitiy) {
          tenancy.checked = true
        }
      } else {
        tenancy.checked = false
      }
    })
    setCarParkTenancyFormattedList(carParkTenancyListDeepCopy)
  }, [carParkTenancyList, selectedBlockOut])
  // For Formatted Department Teams List
  useEffect(() => {
    let departmentTeamsListDeepCopy: any[] = JSON.parse(JSON.stringify(departmentTeamsList))
    if (selectedBlockOut) {
      let isAllDepartment: boolean = true
      let departmentIds: string[] = selectedBlockOut?.departmentIds
      let teamIds: string[] = selectedBlockOut?.teamIds
      departmentTeamsList.forEach((department: DepartmentTeamListItemDto) => {
        if (!departmentIds.includes(department.id)) {
          isAllDepartment = false
          return
        }
      })
      if (isAllDepartment) {
        departmentTeamsListDeepCopy.forEach((department: any) => {
          department.checked = true
          department.teams.forEach((team: any) => {
            team.checked = true
          })
        })
        setAllDepartmentTeams(true)
      } else {
        departmentTeamsListDeepCopy.forEach((department: any) => {
          if (departmentIds.includes(department.id)) {
            department.checked = true
            department.teams.forEach((team: any) => {
              if (teamIds.includes(team.id)) {
                team.checked = true
              } else {
                team.checked = true
              }
            })
          } else {
            department.checked = false
            department.teams.forEach((team: any) => {
              if (teamIds.includes(team.id)) {
                team.checked = true
              } else {
                team.checked = false
              }
            })
          }
        })
      }
    } else {
      departmentTeamsListDeepCopy.forEach((department: any) => {
        department.checked = false
        department.teams.forEach((team: any) => {
          team.checked = false
        })
      })
    }
    setDepartmentTeamsFormattedList(departmentTeamsListDeepCopy)
  }, [departmentTeamsList, selectedBlockOut])
  // For Fetch Tenancy & Department Team List
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // After Call Orders For Access Restrictions Count API Response
  useEffect(() => {
    if (ordersForAccessRestrictionsInserted) {
      if (ordersForAccessRestrictionsIdOnly.count > 0 && requestPayload) {
        onEditBlockOutMultiCount(requestPayload)
      } else {
        if (currentOperatorId && currentOrganisationId && requestPayload) {
          dispatch(
            updateAggregate<AccessRestrictions>(
              AGGREGATE_ACCESS_RESTRICTIONS,
              ACCESS_RESTRICTIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                ':carParkTenantId',
                currentOrganisationId,
              ),
              requestPayload,
            ),
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersForAccessRestrictionsInserted])
  // After Call Orders For Access Restrictions Count API Error
  useEffect(() => {
    if (ordersForAccessRestrictionsError) {
      ordersForAccessRestrictionsError.forEach((error: ErrorResponse) => {
        dispatch(setNotification(NotificationType.ERROR, [error.message]))
      })
      dispatch(resetAggregateError(AGGREGATE_ACCESS_RESTRICTIONS))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersForAccessRestrictionsError])
  // After Update Restrictions API Response
  useEffect(() => {
    if (accessRestrictionsUpdated) {
      if (ordersForAccessRestrictionsIdOnly.count === 0) {
        onEditBlockOutDateModalSave()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRestrictionsUpdated])
  // After Update Restrictions API Error
  useEffect(() => {
    if (accessRestrictionsError) {
      accessRestrictionsError.forEach((error: ErrorResponse) => {
        dispatch(setNotification(NotificationType.ERROR, [error.message]))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessRestrictionsError])

  // For Check Button Disabled Condition
  useEffect(() => {
    checkIsButtonDisabled()
  })
  useEffect(() => {
    if (selectedBlockOut) {
      let startDate = selectedBlockOut.startDate
      let endDate = selectedBlockOut.endDate
      let fromMinutes = selectedBlockOut.fromMinutes
      let toMinutes = selectedBlockOut.toMinutes

      if (fromMinutes === 0 && toMinutes === 1440) {
        setIsAllDay(true)
        let startTime = new Date()
        startTime = setHours(startTime, 0)
        startTime = setMinutes(startTime, 0)
        let endTime = new Date()
        endTime = setHours(endTime, 23)
        endTime = setMinutes(endTime, 59)
        let tempState: any = {
          startDate: new Date(startDate),
          startTime: startTime,
          endDate: new Date(endDate),
          endTime: endTime,
        }
        setStartAndEndBlockOut(tempState)
      } else {
        // For start time
        let startTimeHour = Math.floor(fromMinutes / 60)
        let startTimeMinute = fromMinutes % 60
        let startTime = new Date()
        startTime = setHours(startTime, startTimeHour)
        startTime = setMinutes(startTime, startTimeMinute)
        // For end time
        let endTimeHour = Math.floor(toMinutes / 60)
        let endTimeMinute = toMinutes % 60
        let endTime = new Date()
        endTime = setHours(endTime, endTimeHour)
        endTime = setMinutes(endTime, endTimeMinute)
        // For update date and time state
        let tempState: any = {
          startDate: new Date(startDate),
          startTime: startTime,
          endDate: new Date(endDate),
          endTime: endTime,
        }
        setStartAndEndBlockOut(tempState)
      }

      let start = new Date(startDate).setHours(0, 0, 0, 0)
      let end = new Date(endDate).setHours(0, 0, 0, 0)
      let today = new Date().setHours(0, 0, 0, 0)
      if (isBefore(start, today)) {
        setIsStartDateBeforeToday(true)
        if (isBefore(end, today)) {
          setIsEndDateBeforeToday(true)
        }
      }
    }
  }, [selectedBlockOut])

  const handleChangeIsAllDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked)
  }
  const handleChangeTenancy = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tenancyId = event.target.name
    carParkTenancyFormattedList.forEach((tenancy: any) => {
      if (tenancy.id === tenancyId) {
        tenancy.checked = event.target.checked
      }
    })
    checkIsButtonDisabled()
    setRefresh(!refresh)
  }
  const onSubmit = () => {
    if (isButtonDisabled || isEndDateBeforeToday) {
      return
    }

    let startDate: string = ''
    let endDate: string = ''
    let startTime: number = 0
    let endTime: number = 0

    // Prepare startDate & endDate for payload
    if (startAndEndBlockOut.startDate) {
      startDate = format(startAndEndBlockOut.startDate, 'yyyy-MM-dd')
    }
    if (startAndEndBlockOut.endDate) {
      endDate = format(startAndEndBlockOut.endDate, 'yyyy-MM-dd')
    }

    // Prepare facilities for payload
    let facilities: AccessRestrictionsFacility[] = []
    carParkTenancyFormattedList.forEach((tenancy: any) => {
      if (tenancy.checked) {
        let tempObj: AccessRestrictionsFacility = {
          id: null,
          zoneIds: null,
          facilityId: tenancy.id,
          version: null,
        }
        facilities.push(tempObj)
      }
    })

    // Prepare startTime & endTime for payload
    if (isAllDay) {
      startTime = 0
      endTime = 1440
    } else {
      let start = startAndEndBlockOut.startTime ? startAndEndBlockOut.startTime : null
      if (start) {
        let hours = getHours(start)
        let minutes = getMinutes(start)
        startTime = hours * 60 + minutes
      }
      let end = startAndEndBlockOut.endTime ? startAndEndBlockOut.endTime : null
      if (end) {
        let hours = getHours(end)
        let minutes = getMinutes(end)
        endTime = hours * 60 + minutes
      }
    }

    // Prepare departmentIds & teamIds for payload
    let departmentIds: string[] = []
    let teamIds: string[] = []
    if (departmentTeamsFormattedList) {
      departmentTeamsFormattedList.forEach((department: any) => {
        if (department.checked) {
          departmentIds.push(department.id)
        } else {
          department.teams.forEach((team: any) => {
            if (team.checked) {
              teamIds.push(team.id)
            }
          })
        }
      })
    }

    if (currentOperatorId && currentOrganisationId && selectedBlockOut) {
      let payload: AccessRestrictions = {
        id: selectedBlockOut.id,
        clientRef: selectedBlockOut.clientRef,
        code: selectedBlockOut.code,
        lifecycle: selectedBlockOut.lifecycle,
        name: selectedBlockOut.name,
        version: selectedBlockOut.version,
        accessRestrictionType: selectedBlockOut.accessRestrictionType,
        operatorId: selectedBlockOut.operatorId,
        organisationType: selectedBlockOut.organisationType,
        organisationId: selectedBlockOut.organisationId,
        startDate: startDate,
        endDate: endDate,
        restrictDaysOfWeek: selectedBlockOut.restrictDaysOfWeek,
        fromMinutes: startTime,
        toMinutes: endTime,
        facilities: facilities,
        departmentIds: departmentIds,
        teamIds: teamIds,
        memberPools: selectedBlockOut.memberPools,
        organisation: selectedBlockOut.organisation,
        fromHourMinutes: selectedBlockOut.fromHourMinutes,
        toHourMinutes: selectedBlockOut.toHourMinutes,
        continuousTime: true,
      }
      setRequestPayload(payload)
      dispatch(
        insertAggregate<AccessRestrictions>(
          AGGREGATE_ORDERS_FOR_ACCESS_RESTRICTIONS,
          ORDERS_FOR_ACCESS_RESTRICTIONS_COUNT_POST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          payload,
        ),
      )
    }
  }
  const handleChangeDateAndTime = (newValue: any, name: string) => {
    let tempState: any = { ...startAndEndBlockOut }
    tempState[name] = newValue
    setStartAndEndBlockOut(tempState)
    setStartAndEndBlockOutError(checkDateAndTimeError(tempState, name, startAndEndBlockOutError))
  }

  const checkIsButtonDisabled = () => {
    let isAnyTenancySelected: boolean = false
    let isAnyDepartmentOrTeamSelected: boolean = false
    if (carParkTenancyFormattedList && departmentTeamsFormattedList) {
      let tenancy = carParkTenancyFormattedList.find((tenancy: any) => tenancy.checked === true)
      if (tenancy) {
        isAnyTenancySelected = true
      }
      departmentTeamsFormattedList.forEach((department: any) => {
        if (department.checked) {
          isAnyDepartmentOrTeamSelected = true
          return
        }
        department.teams.forEach((team: any) => {
          if (team.checked) {
            isAnyDepartmentOrTeamSelected = true
            return
          }
        })
      })
    }
    if (isAllDay) {
      if (startAndEndBlockOut.startDate && startAndEndBlockOut.endDate) {
        if (startAndEndBlockOutError.startDate.length === 0 && startAndEndBlockOutError.endDate.length === 0) {
          if (isAnyTenancySelected && isAnyDepartmentOrTeamSelected) {
            setIsButtonDisabled(false)
          } else {
            setIsButtonDisabled(true)
          }
        } else {
          setIsButtonDisabled(true)
        }
      } else {
        setIsButtonDisabled(true)
      }
    } else {
      if (
        startAndEndBlockOut.startDate &&
        startAndEndBlockOut.startTime &&
        startAndEndBlockOut.endDate &&
        startAndEndBlockOut.endTime
      ) {
        if (
          startAndEndBlockOutError.startDate.length === 0 &&
          startAndEndBlockOutError.startTime.length === 0 &&
          startAndEndBlockOutError.endDate.length === 0 &&
          startAndEndBlockOutError.endTime.length === 0
        ) {
          if (isAnyTenancySelected && isAnyDepartmentOrTeamSelected) {
            setIsButtonDisabled(false)
          } else {
            setIsButtonDisabled(true)
          }
        } else {
          setIsButtonDisabled(true)
        }
      } else {
        setIsButtonDisabled(true)
      }
    }
  }
  const departmentAndTeamSelector = () => {
    if (departmentTeamsFormattedList && departmentTeamsFormattedList.length > 0) {
      if (departmentTeamsFormattedList.length === 1) {
        return (
          <SingleDepartmentCheckbox
            departmentTeamsFormattedList={departmentTeamsFormattedList}
            checkIsButtonDisabled={checkIsButtonDisabled}
            isAll={allDepartmentTeams}
            isAllDisabled={isStartDateBeforeToday}
          />
        )
      } else {
        return (
          <MultiDepartmentCheckbox
            departmentTeamsFormattedList={departmentTeamsFormattedList}
            checkIsButtonDisabled={checkIsButtonDisabled}
            isAll={allDepartmentTeams}
            isAllDisabled={isStartDateBeforeToday}
          />
        )
      }
    }
  }
  const checkStartDateDisabled = (startDate: Date | null) => {
    if (startDate) {
      let start = new Date(startDate).setHours(0, 0, 0, 0)
      let today = new Date().setHours(0, 0, 0, 0)
      if (isBefore(start, today)) {
        return new Date(startDate)
      } else {
        return new Date()
      }
    } else {
      return new Date()
    }
  }
  const checkMinDateForEndDate = (startDate: Date | null) => {
    if (startDate) {
      let start = new Date(startDate).setHours(0, 0, 0, 0)
      let today = new Date().setHours(0, 0, 0, 0)
      if (isBefore(start, today)) {
        return new Date()
      } else {
        return new Date(startDate)
      }
    } else {
      return new Date()
    }
  }

  return (
    <div className={showEditBlockOutDate ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      {(accessRestrictionsUpdating || ordersForAccessRestrictionsInserting) && <Loading />}
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <h3 className="modal-h3 larger-text">Edit block out date</h3>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: '0px' }}>
              <p className="input-label">Start block out</p>
              <Grid container spacing={1}>
                <Grid item xs={isAllDay ? 12 : 7}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startAndEndBlockOut.startDate}
                      onChange={(newValue: any) => handleChangeDateAndTime(newValue, 'startDate')}
                      inputFormat="dd MMMM yyyy"
                      disabled={isStartDateBeforeToday}
                      disableMaskedInput
                      components={{
                        OpenPickerIcon: CustomCalendarIcon,
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          className="shuffleInputIcon"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          {...params}
                          error={startAndEndBlockOutError.startDate.length > 0}
                          helperText={startAndEndBlockOutError.startDate}
                        />
                      )}
                      minDate={checkStartDateDisabled(startAndEndBlockOut.startDate)}
                      maxDate={startAndEndBlockOut.endDate ? new Date(startAndEndBlockOut.endDate) : null}
                    />
                  </LocalizationProvider>
                </Grid>
                {!isAllDay && (
                  <Grid item xs={5}>
                    <Autocomplete
                      id="startTime"
                      className="block-out-timepicker-autocomplete"
                      clearIcon={null}
                      options={timeSlots}
                      value={startAndEndBlockOut.startTime ? getAPTimeFormDate(startAndEndBlockOut.startTime) : ''}
                      disableClearable
                      onChange={(_event: any, newValue: string | null) => {
                        handleChangeDateAndTime(newValue ? getDateFormAPTime(newValue) : null, 'startTime')
                      }}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          variant="outlined"
                          margin="dense"
                          required
                          {...params}
                          inputProps={{
                            ...inputProps,
                            placeholder: 'hh:mm',
                          }}
                          error={startAndEndBlockOutError.startTime.length > 0}
                          helperText={startAndEndBlockOutError.startTime}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: '0px' }}>
              <p className="input-label">End block out</p>
              <Grid container spacing={1}>
                <Grid item xs={isAllDay ? 12 : 7}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startAndEndBlockOut.endDate}
                      onChange={(newValue: any) => handleChangeDateAndTime(newValue, 'endDate')}
                      inputFormat="dd MMMM yyyy"
                      disabled={isEndDateBeforeToday}
                      disableMaskedInput
                      components={{
                        OpenPickerIcon: CustomCalendarIcon,
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          className="shuffleInputIcon"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          {...params}
                          error={startAndEndBlockOutError.endDate.length > 0}
                          helperText={startAndEndBlockOutError.endDate}
                        />
                      )}
                      minDate={checkMinDateForEndDate(startAndEndBlockOut.startDate)}
                    />
                  </LocalizationProvider>
                </Grid>
                {!isAllDay && (
                  <Grid item xs={5}>
                    <Autocomplete
                      id="endTime"
                      className="block-out-timepicker-autocomplete"
                      clearIcon={null}
                      options={timeSlots}
                      value={startAndEndBlockOut.endTime ? getAPTimeFormDate(startAndEndBlockOut.endTime) : ''}
                      disableClearable
                      onChange={(_event: any, newValue: string | null) => {
                        handleChangeDateAndTime(newValue ? getDateFormAPTime(newValue) : null, 'endTime')
                      }}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          variant="outlined"
                          margin="dense"
                          required
                          {...params}
                          inputProps={{
                            ...inputProps,
                            placeholder: 'hh:mm',
                          }}
                          error={startAndEndBlockOutError.endTime.length > 0}
                          helperText={startAndEndBlockOutError.endTime}
                        />
                      )}
                      filterOptions={(options, state) => {
                        let tempOptions: string[] = options
                        if (
                          startAndEndBlockOut.startDate &&
                          startAndEndBlockOut.endDate &&
                          startAndEndBlockOut.startTime
                        ) {
                          let startDate = new Date(startAndEndBlockOut.startDate).setHours(0, 0, 0, 0)
                          let endDate = new Date(startAndEndBlockOut.endDate).setHours(0, 0, 0, 0)
                          let startTime = startAndEndBlockOut.startTime
                          let isStartDateValid = isValid(startDate)
                          let isEndDateValid = isValid(endDate)
                          if (isStartDateValid && isEndDateValid && isEqual(startDate, endDate)) {
                            let validOptions: string[] = []
                            options.forEach((option) => {
                              let endTime: Date | null = getDateFormAPTime(option)
                              if (startTime && endTime) {
                                let statTimeSlot = startTime.getHours() * 60 + startTime.getMinutes()
                                let endTimeSlot = endTime.getHours() * 60 + endTime.getMinutes()
                                if (endTimeSlot > statTimeSlot) {
                                  validOptions.push(option)
                                }
                              }
                            })
                            tempOptions = validOptions
                          }
                        }
                        return tempOptions.filter((option) => option.includes(state.inputValue))
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
              {/* <FormGroup> */}
              <FormControlLabel
                control={
                  <Checkbox disabled={isStartDateBeforeToday} checked={isAllDay} onChange={handleChangeIsAllDay} />
                }
                label="All Day"
              />
              {/* </FormGroup> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '40px' }}>
            <Grid item xs={6}>
              <p className="checkbox-label">Which locations does this block out apply to?{refresh}</p>
              <FormGroup>
                {carParkTenancyFormattedList &&
                  carParkTenancyFormattedList.length > 0 &&
                  carParkTenancyFormattedList.map((tenancy: any) => (
                    <FormControlLabel
                      key={tenancy.id}
                      disabled={isStartDateBeforeToday}
                      control={<Checkbox name={tenancy.id} checked={tenancy.checked} onChange={handleChangeTenancy} />}
                      label={tenancy.name}
                    />
                  ))}
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <p className="checkbox-label">Which users does this block out apply to?</p>
              {departmentAndTeamSelector()}
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="row" alignItems="center" style={{ marginTop: '50px' }}>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => closeEditBlockOutDateModal()}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center">
                <p className="modal-disclaimer-text">
                  This block out won’t impact assigned parkers, <br /> including those who share their bay.
                </p>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={
                    isButtonDisabled ||
                    isEndDateBeforeToday ||
                    accessRestrictionsUpdating ||
                    ordersForAccessRestrictionsInserting
                  }
                  onClick={() => onSubmit()}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default EditBlockOutDate
