import React, { useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import addIcon from '../../../../../assets/svg/ws-ui-icon-add-white.svg'
import closeIcon from '../../../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../store'
import { selectCurrentOperatorId } from '../../../../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../../../auth/types'
import { fetchAggregates, updateLifeCycle } from '../../../../../common/axios-action'
import { MemberAutoInvitationRequest } from '../auto-invitation/types'
import { AGGREGATE_EMAIL_DOMAIN } from '../../../../../common/aggregate.types'
import { EMAIL_DOMAIN_API_RESOURCE_PATH, MemberEmailDomain } from './types'
import { Lifecycle, LifecycleOnly } from '../../../../../common/types'
import LoadingInDiv from '../../../../../components/ui/Loading/LoadingInDiv'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    fontWeight: 'bold',
    height: 50,
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '705px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  labelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
}))

interface IProps {
  display: boolean
  hideModel: () => void
  showModel: (id: string | null) => void
}

const DomainForm: React.FC<IProps> = (props) => {
  const classes = useStyle()
  const { showModel } = props

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const { invitations, aggregates, loadingList, deleting, deleteSuccess } = useSelector(
    (state: RootState) => ({
      invitations: state.autoInvitationReducer.aggregates,
      aggregates: state.emailDomainReducer.aggregates,
      loadingList: state.emailDomainReducer.loadingList,
      deleting: state.emailDomainReducer.deleting,
      deleteSuccess: state.emailDomainReducer.deleted,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<MemberEmailDomain>(
          AGGREGATE_EMAIL_DOMAIN,
          EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            page: 0,
            size: 1000,
          },
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, deleteSuccess])

  const onClickDelete = (event: any, id: string | null) => {
    event.stopPropagation()
    if (currentOperatorId && currentOrganisationId && id) {
      let lifecycleOnly: LifecycleOnly = { lifecycle: Lifecycle.Deleted }
      dispatch(
        updateLifeCycle<MemberAutoInvitationRequest>(
          AGGREGATE_EMAIL_DOMAIN,
          EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          id,
          lifecycleOnly,
        ),
      )
    }
  }

  const editModel = (event: any, id: string | null) => {
    event.stopPropagation()
    showModel(id)
  }

  return (
    <>
      <Grid item xs={12} className="content-panel">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <h3 className="padding-bottom-20">Approved domains</h3>
          </Grid>
          <Grid item xs={4} style={{ marginTop: -8, textAlign: 'right' }}>
            <Button
              className={classes.shuffleButton}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<img className="buttonIcon" src={addIcon} alt="add-icon" />}
              style={{ maxWidth: '152px' }}
              onClick={() => showModel(null)}
            >
              Add domain
            </Button>
          </Grid>
        </Grid>

        {aggregates.values.length === 0 && (
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <p className="select-label">You haven’t added any approved domains</p>
              {invitations.values.length > 0 && (
                <p className="select-label-red">
                  Please add at least one domain to ensure your self registration links are usable by your staff.
                </p>
              )}
            </Grid>
          </Grid>
        )}

        {(deleting || loadingList) && <LoadingInDiv />}
        <Grid container spacing={2} className={classes.gridTopMargin}>
          {aggregates.values.map((row) => {
            return (
              <Grid item xs={12} key={row.id}>
                <div className="location-card" onClick={(e) => editModel(e, row.id)}>
                  <p className="domain-title">{row.name}</p>
                  <p className="location-product-count">
                    <span className="domain-url">{row.domain}</span>
                  </p>
                  <div className="close-card">
                    <img
                      className="close-x"
                      src={closeIcon}
                      alt="close-icon"
                      onClick={(e) => onClickDelete(e, row.id)}
                    />
                  </div>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default DomainForm
