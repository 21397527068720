import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Container, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { format } from 'date-fns'
import { ValidationErrors } from 'final-form'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { resetAggregate } from '../../common/aggregate-actions'
import { AGGREGATE_APPLICATION, AGGREGATE_MY_LIST_TENANCIES } from '../../common/aggregate.types'
import { fetchAggregate, fetchAggregates, updateAggregate, updateApplicationStatus } from '../../common/axios-action'
import { EmployeeClass, ErrorResponse, memberPoolsIdNames } from '../../common/types'
import { selectCurrentOperatorId, selectCurrentTenantId } from '../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../pages/auth/types'
import {
  ApplicationStatusReason,
  MemberApplicationForm,
  MemberApplicationStatus,
  TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH,
  TENANT_MEMBER_APPLICATIONS_COMPLETE_API_RESOURCE_PATH,
  memberApplicationInitialValue,
} from '../../pages/settings/users/applications/types'
import { RootState } from '../../store'
import { DepartmentTeamList } from '../../store/common/departments-teams/types'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../store/common/tenancies/types'
import { DatePicker } from '../final-form/DatePicker'
import { DepartmentTeamSelect } from '../final-form/DepartmentTeamSelect'
import { RadioButtons } from '../final-form/Radios'
import { Select } from '../final-form/Select'
import { TextField } from '../final-form/TextField'
import LoadingInDiv from '../ui/Loading/LoadingInDiv'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    backgroundColor: '#EA3323',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleButtonApprove: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    backgroundColor: '#409075',
    width: '200px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    width: '705px',
    maxWidth: '705px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
  closeIcon: {
    marginTop: -36,
    textAlign: 'right',
    marginRight: -36,
    cursor: 'pointer',
  },
}))

interface IProps {
  applicationId: string | null
  display: boolean
  hideApplicationModel: () => void
  hideWhenApplicationStatus: () => void
  departmentsAndTeams: DepartmentTeamList[]
}

const ApplicationModelForm: React.FC<IProps> = (props) => {
  const { applicationId, display, departmentsAndTeams, hideWhenApplicationStatus } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const currentTenantId = useSelector(selectCurrentTenantId)

  const [initialValue, setInitialValue] = useState<MemberApplicationForm>()
  const [formChange, setFormChange] = useState<boolean>(false)

  const [displayName, setDisplayName] = useState<string | null>(null)
  const [memberApplicationStatus, setMemberApplicationStatus] = useState<MemberApplicationStatus>()

  const {
    tenancies,
    updating,
    updated,
    application,
    error,
    loadingOne,
    updatedApplicationStatus,
    updatingApplicationStatus,
  } = useSelector(
    (state: RootState) => ({
      tenancies: state.myListTenancyReducer.aggregates,
      error: state.applicationReducer.error,
      updating: state.applicationReducer.updating,
      updated: state.applicationReducer.updated,
      application: state.applicationReducer.aggregate,
      loadingOne: state.applicationReducer.loadingOne,
      updatedApplicationStatus: state.applicationReducer.updatedApplicationStatus,
      updatingApplicationStatus: state.applicationReducer.updatingApplicationStatus,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (applicationId !== null && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<MemberApplicationForm>(
          AGGREGATE_APPLICATION,
          TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          applicationId,
        ),
      )
    } else {
      dispatch(resetAggregate<MemberApplicationForm>(AGGREGATE_APPLICATION))
    }
  }, [dispatch, applicationId, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (application) {
      if (application.teamId) {
        application.departmentTeamId = application.teamId
      } else if (application.departmentId) {
        application.departmentTeamId = application.departmentId
      }
      setInitialValue(application)
    }
  }, [application])

  useEffect(() => {
    if (error) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(error))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        let message: string | null = null
        if (firstError.message.includes('EMAIL_EXISTS') || firstError.code.includes('EMAIL_EXISTS')) {
          message = 'Email already exists. Please change the email address and try again'
        }

        dispatch(
          setNotification(NotificationType.ERROR, [
            message ? message : firstError.message ? firstError.message : firstError.code.substring(0, 41),
          ]),
        )
      }
    }
  }, [error, dispatch])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (updated && currentOperatorId && currentOrganisationId && applicationId && memberApplicationStatus) {
      completeApplication(memberApplicationStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updated, applicationId, currentOperatorId, currentOrganisationId, memberApplicationStatus])

  useEffect(() => {
    if (updatedApplicationStatus && memberApplicationStatus) {
      dispatch(
        setNotification(NotificationType.INFO, [
          displayName + ' has been ' + memberApplicationStatus?.toString().toLocaleLowerCase(),
        ]),
      )
      hideModel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatedApplicationStatus, displayName, memberApplicationStatus])

  const onSubmit = (values: MemberApplicationForm) => {
    if (currentOperatorId && currentOrganisationId && currentTenantId) {
      const errors: ValidationErrors = {}

      if (validateBeforeSubmit(values, errors)) {
        return errors
      } else {
        if (values.id && memberApplicationStatus) {
          if (formChange) {
            let selected: DepartmentTeamList | undefined = departmentsAndTeams.find(
              (row) => row.id === values.departmentTeamId,
            )
            if (selected && selected.isDepartment) {
              values.departmentId = values.departmentTeamId
              values.teamId = null
            } else {
              values.teamId = values.departmentTeamId
              values.departmentId = selected ? selected.departmentId : null
            }
            if (values.employeeClass === EmployeeClass.Contract && values.contractEndDate) {
              values.contractEndDate = format(Date.parse(values.contractEndDate), 'yyyy-MM-dd')
            } else {
              values.contractEndDate = null
            }

            values.operatorId = currentOperatorId
            values.carParkTenantId = currentOrganisationId
            if (values.mobile) {
              values.mobile.country = 'AU'
            }
            values.tenantId = currentTenantId

            dispatch(
              updateAggregate<MemberApplicationForm>(
                AGGREGATE_APPLICATION,
                TENANT_MEMBER_APPLICATIONS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                  ':carParkTenantId',
                  currentOrganisationId,
                ),
                values,
              ),
            )
          } else {
            completeApplication(memberApplicationStatus)
          }
          setDisplayName(values.firstName + ' ' + values.lastName)
          setInitialValue(values)
        }
      }
    }
  }

  const completeApplication = (memberApplicationStatus: MemberApplicationStatus) => {
    if (applicationId && currentOperatorId && currentOrganisationId && memberApplicationStatus) {
      let applicationStatusReason: ApplicationStatusReason = {
        status: memberApplicationStatus,
        reason: memberApplicationStatus === MemberApplicationStatus.Declined ? 'No Longer used' : '',
      }
      dispatch(
        updateApplicationStatus<ApplicationStatusReason>(
          AGGREGATE_APPLICATION,
          TENANT_MEMBER_APPLICATIONS_COMPLETE_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':id', applicationId) + ':Complete',
          applicationStatusReason,
        ),
      )
    }
  }

  const validateBeforeSubmit = (values: MemberApplicationForm, errors: ValidationErrors) => {
    let isError = false

    if (errors) {
      if (!values.email) {
        errors.email = 'Required'
        isError = true
      }
      if (!values.firstName) {
        errors.firstName = 'Required'
        isError = true
      }
      if (!values.lastName) {
        errors.lastName = 'Required'
        isError = true
      }
      errors.mobile = {}
      if (!values.mobile) {
        errors.mobile.number = 'Required'
        isError = true
      } else if (values.mobile && !values.mobile.number) {
        errors.mobile.number = 'Required'
        isError = true
      }
      if (!values.baseFacilityId) {
        errors.baseFacilityId = 'Required'
        isError = true
      }
      if (!values.departmentTeamId) {
        errors.departmentTeamId = 'Required'
        isError = true
      }
      if (!values.memberPool) {
        errors.memberPool = 'Required'
        isError = true
      }
      if (!values.employeeClass) {
        errors.employeeClass = 'Required'
        isError = true
      } else if (values.employeeClass === EmployeeClass.Contract) {
        if (!values.contractEndDate) {
          errors.contractEndDate = 'Required'
          isError = true
        }
      }
    }
    return isError
  }

  const hideModel = () => {
    setInitialValue(memberApplicationInitialValue)
    onResetForm()
    hideWhenApplicationStatus()
  }

  let resetForm: () => void

  const onResetForm = () => {
    if (resetForm != null) {
      resetForm()
    }
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <div className={classes.closeIcon} onClick={() => hideModel()}>
          <CloseIcon style={{ color: '#525252' }} fontSize={'small'} />
        </div>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles settings-modal">
          <Form<MemberApplicationForm>
            onSubmit={onSubmit}
            initialValues={initialValue}
            render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
              resetForm = form.reset
              return (
                <form onSubmit={handleSubmit} id="editForm" noValidate>
                  {!pristine ? setFormChange(true) : setFormChange(false)}
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: '32px' }}>
                      <h3 className="modal-h3 larger-text">Approve new user</h3>
                      <p className="modal-h6">
                        Please review the details for this use. you can approve or decline their request for access to
                        your parking platform. You can also make changes to their details
                      </p>
                    </Grid>

                    {(updating || updatingApplicationStatus || loadingOne) && <LoadingInDiv />}

                    <Grid item xs={6} style={{ paddingTop: '0px', paddingRight: '40px' }}>
                      <p className="input-label">Email address</p>
                      <TextField
                        name="email"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                      />

                      <p className="input-label">First name</p>
                      <TextField
                        name="firstName"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                      />

                      <p className="input-label">Surname</p>
                      <TextField
                        name="lastName"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                      />

                      <p className="input-label">Phone number</p>

                      <TextField
                        name="mobile.number"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                      />

                      <p className="input-label">Office location</p>
                      <Select
                        name="baseFacilityId"
                        className="shuffle-select-location"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          disableScrollLock: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        data={tenancies.values}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: '0px', paddingLeft: '40px' }}>
                      <p className="input-label">Department/team</p>
                      <DepartmentTeamSelect
                        name="departmentTeamId"
                        className="shuffle-select"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          disableScrollLock: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        data={departmentsAndTeams}
                      />
                      <p className="input-label" style={{ marginTop: '4px' }}>
                        Role
                      </p>
                      <Select
                        className="shuffle-select-role"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          disableScrollLock: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="memberPool"
                        data={memberPoolsIdNames}
                      />

                      <p className="input-label" style={{ marginBottom: '7px' }}>
                        Expire access
                      </p>
                      <RadioButtons
                        name="employeeClass"
                        data={[
                          {
                            id: 'Permanent',
                            name: 'Never',
                          },
                          {
                            id: 'Contract',
                            name: 'Set Date',
                          },
                        ]}
                        disabled={false}
                      />

                      {values.employeeClass === EmployeeClass.Contract && (
                        <DatePicker
                          name="contractEndDate"
                          format="dd MMMM yyyy"
                          required={values.employeeClass === EmployeeClass.Contract}
                          disabled={false}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="row" alignItems="center" style={{ marginTop: '17px' }}>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="flex-start">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          type={'submit'}
                          onClick={() => setMemberApplicationStatus(MemberApplicationStatus.Declined)}
                        >
                          Decline
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center"></Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButtonApprove}
                          variant="contained"
                          size="large"
                          type={'submit'}
                          onClick={() => setMemberApplicationStatus(MemberApplicationStatus.Approved)}
                        >
                          Approve
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          />
        </Container>
      </Box>
    </div>
  )
}
export default ApplicationModelForm
