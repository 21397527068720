import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Autocomplete, CircularProgress, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { format, isEqual, isValid } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import productIconCar from '../../../assets/svg/product-icon-car.svg'
import prodsCapacitiesIcon from '../../../assets/svg/ws-b2b-icon-car_park.svg'
import dateIcon from '../../../assets/svg/ws-b2b-icon-date.svg'
import manageParkersIcon from '../../../assets/svg/ws-b2b-icon-staff.svg'
import searchFieldIcon from '../../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import crossFieldIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import warningIcon from '../../../assets/svg/ws-ui-icon-warning-critical-red.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_MEMBER_LIST,
  AGGREGATE_MEMBER_RELATION,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_PARKING_BASKET,
  AGGREGATE_PARKING_ORDER,
  AGGREGATE_PARKING_QUOTE,
} from '../../../common/aggregate.types'
import { fetchAggregate, fetchAggregates, postAggregate } from '../../../common/axios-action'
import { putBasketAggregate } from '../../../common/custom-axios-action'
import { AgreementStayType, SalesChannelType } from '../../../common/types'
import { formatNumberWithDecimalPlace, timeSlots } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { useDebounce } from '../../../hook/useDebounce'
import { RootState } from '../../../store'
import { MEMBER_RELATION_API_RESOURCE_PATH, MemberRelation } from '../../../store/common/member-relation/types'
import { MEMBER_API_RESOURCE_PATH, Member } from '../../../store/common/member/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { PARKING_BASKET_API_RESOURCE_PATH, ParkingBasket } from '../../../store/common/parking-basket/types'
import { PARKING_ORDER_API_RESOURCE_PATH, ParkingOrder } from '../../../store/common/parking-order/types'
import {
  EXISTING_BOOKING_ERROR,
  PARKING_QUOTE_API_RESOURCE_PATH,
  ParkingQuote,
  ParkingQuoteItemList,
} from '../../../store/common/parking-quote/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { DateAndTimeInterval, DateAndTimeIntervalError } from '../../guest/book-guest-parking/types'
import { CustomCalendarIcon } from '../../util/custom-icon'
import { ROUTE_ON_DEMAND } from '../../util/routes'
import { getAPTimeFormDate, getDateFormAPTime, getMinDateForEndDate } from '../../util/util'
import { checkDateAndTimeError } from '../block-out-dates/util'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0px',
    maxWidth: '200px',
  },
  shuffleSearchButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '0.875rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '120px',
    padding: '7px 0',
    boxShadow: 'none',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: 5,
    height: 14,
    width: 14,
  },
}))

interface IProps {}

const BookParking: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [member, setMember] = useState<Member | null>(null)
  const [memberInput, setMemberInput] = useState<string>('')
  const debouncedMemberInput: string = useDebounce<string>(memberInput, 600)

  const [isTenantHasGuestAccess, setIsTenantHasGuestAccess] = useState<boolean>(false)

  const [dateAndTimeInterval, setDateAndTimeInterval] = useState<DateAndTimeInterval>({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  })

  const [dateAndTimeIntervalError, setDateAndTimeIntervalError] = useState<DateAndTimeIntervalError>({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  })

  const [location, setLocation] = useState<string>('')
  const [isSearchDisable, setIsSearchDisable] = useState<boolean>(true)
  const [memberLocationList, setMemberLocationList] = useState<Tenancy[]>([])
  const [selectedParkingItem, setSelectedParkingItem] = useState<ParkingQuoteItemList | null>(null)
  const [isBookDisable, setIsBookDisable] = useState<boolean>(true)

  const {
    tenancies,
    memberList,
    memberLoadingList,
    memberLoadingOne,
    memberRelation,
    parkingQuote,
    parkingQuoteError,
    parkingQuoteLoading,
    parkingBasket,
    parkingBasketError,
    parkingOrder,
    parkingOrderError,
    parkingOrderLoading,
  } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
    memberList: state.memberReducer.aggregates.values,
    memberLoadingList: state.memberReducer.loadingList,
    memberLoadingOne: state.memberReducer.loadingOne,
    memberRelation: state.memberRelationReducer.aggregate,
    parkingQuote: state.parkingQuoteReducer.data,
    parkingQuoteError: state.parkingQuoteReducer.error,
    parkingQuoteLoading: state.parkingQuoteReducer.loadingOne,
    parkingBasket: state.parkingBasketReducer.data,
    parkingBasketError: state.parkingBasketReducer.error,
    parkingOrder: state.parkingOrderReducer.data,
    parkingOrderError: state.parkingOrderReducer.error,
    parkingOrderLoading: state.parkingOrderReducer.loadingOne,
  }))

  // Fetch tenancy list (dispatch)
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
    // eslint-disable-next-line
  }, [currentOperatorId, currentOrganisationId])

  // Check guest access
  useEffect(() => {
    if (tenancies) {
      let tenancy = tenancies.find((t: Tenancy) => t.guestAccess === true)
      setIsTenantHasGuestAccess(tenancy ? true : false)
    }
  }, [tenancies])

  // Fetch member list, on member input change (dispatch)
  useEffect(() => {
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    if (debouncedMemberInput && debouncedMemberInput.trim().length > 1) {
      if (currentOperatorId && currentOrganisationId && memberInput) {
        dispatch(
          fetchAggregates<Member>(
            AGGREGATE_MEMBER_LIST,
            MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            { name: memberInput, size: 100 },
          ),
        )
      }
    }
    // eslint-disable-next-line
  }, [debouncedMemberInput])

  // Fetch member relation (dispatch)
  useEffect(() => {
    if (member && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<MemberRelation>(
          AGGREGATE_MEMBER_RELATION,
          MEMBER_RELATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':memberId', member.id),
          null,
        ),
      )
    }
    // eslint-disable-next-line
  }, [member, currentOperatorId, currentOrganisationId])

  // Member Relation success
  useEffect(() => {
    if (memberRelation) {
      let locationList: Tenancy[] = tenancies.filter((tenancy: Tenancy) =>
        memberRelation.facilityIds.includes(tenancy.id),
      )
      setMemberLocationList(locationList)
    }
  }, [memberRelation, tenancies])

  // Parking Basket error
  useEffect(() => {
    if (parkingBasketError) {
      dispatch(setNotification(NotificationType.ERROR, [parkingBasketError]))
    }
    // eslint-disable-next-line
  }, [parkingBasketError])

  // Check book button validation
  useEffect(() => {
    if (parkingQuote && parkingBasket) {
      setIsBookDisable(false)
    } else {
      setIsBookDisable(true)
    }
  }, [parkingQuote, parkingBasket])

  // Check search button validation
  useEffect(() => {
    if (
      member &&
      location &&
      dateAndTimeInterval.startDate &&
      dateAndTimeInterval.startTime &&
      dateAndTimeInterval.endDate &&
      dateAndTimeInterval.endTime &&
      dateAndTimeIntervalError.startDate.length === 0 &&
      dateAndTimeIntervalError.startTime.length === 0 &&
      dateAndTimeIntervalError.endDate.length === 0 &&
      dateAndTimeIntervalError.endTime.length === 0
    ) {
      setIsSearchDisable(false)
    } else {
      setIsSearchDisable(true)
    }
    // eslint-disable-next-line
  }, [member, location, dateAndTimeIntervalError])

  // Parking Order success
  useEffect(() => {
    if (parkingOrder && member) {
      dispatch(
        setNotification(NotificationType.INFO, [
          `Parking has been booked for ${member.name}. A confirmation has been emailed to them.`,
        ]),
      )
      onClearMember()
    }
    // eslint-disable-next-line
  }, [parkingOrder])

  // Parking Order error
  useEffect(() => {
    if (parkingOrderError) {
      dispatch(setNotification(NotificationType.ERROR, [parkingOrderError]))
    }
    // eslint-disable-next-line
  }, [parkingOrderError])

  // On unmount
  useEffect(() => {
    return () => {
      onClearMember()
    }
    // eslint-disable-next-line
  }, [])

  const getSelectedLocationName = (): string => {
    let tenancy = memberLocationList.find((t: Tenancy) => t.id === location)
    return tenancy ? tenancy.name : ' '
  }

  const onChangeMember = (value: Member | null) => {
    setMember(value)
    setDateAndTimeInterval({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
    })
    setDateAndTimeIntervalError({
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    })
    setLocation('')
    setIsSearchDisable(true)
    setMemberLocationList([])
    resetParkingData()
  }

  const onClearMember = () => {
    setMember(null)
    setMemberInput('')
    setDateAndTimeInterval({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
    })
    setDateAndTimeIntervalError({
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    })
    setLocation('')
    setIsSearchDisable(true)
    setMemberLocationList([])
    resetParkingData()
  }

  const handleLocationOnchange = (event: SelectChangeEvent) => {
    setLocation(event.target.value)
    resetParkingData()
  }

  const handleChangeDateAndTime = (newValue: Date | null, name: string) => {
    let tempState: any = { ...dateAndTimeInterval }
    tempState[name] = newValue
    setDateAndTimeInterval(tempState)
    setDateAndTimeIntervalError(checkDateAndTimeError(tempState, name, dateAndTimeIntervalError))
    resetParkingData()
  }

  const resetParkingData = () => {
    setSelectedParkingItem(null)
    setIsBookDisable(true)
    dispatch(resetAggregate<ParkingQuote>(AGGREGATE_PARKING_QUOTE))
    dispatch(resetAggregate<ParkingBasket>(AGGREGATE_PARKING_BASKET))
    dispatch(resetAggregate<ParkingOrder>(AGGREGATE_PARKING_ORDER))
  }

  const onSearch = () => {
    resetParkingData()
    let requestedStartDateTime: string | null = null
    if (dateAndTimeInterval.startDate && dateAndTimeInterval.startTime) {
      requestedStartDateTime =
        format(dateAndTimeInterval.startDate, 'yyyy-MM-dd') + 'T' + format(dateAndTimeInterval.startTime, 'HH:mm:ss')
    }

    let requestedFinishDateTime: string | null = null
    if (dateAndTimeInterval.endDate && dateAndTimeInterval.endTime) {
      requestedFinishDateTime =
        format(dateAndTimeInterval.endDate, 'yyyy-MM-dd') + 'T' + format(dateAndTimeInterval.endTime, 'HH:mm:ss')
    }

    if (
      location &&
      requestedStartDateTime &&
      requestedFinishDateTime &&
      currentOperatorId &&
      currentOrganisationId &&
      member
    ) {
      let payload: any = {
        salesChannel: SalesChannelType.Res,
        facilityIds: [location],
        stayRequest: {
          agreementStayType: AgreementStayType.Single,
          requestedStartDateTime: requestedStartDateTime,
          requestedFinishDateTime: requestedFinishDateTime,
        },
      }
      dispatch(
        postAggregate<ParkingQuote>(
          AGGREGATE_PARKING_QUOTE,
          PARKING_QUOTE_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':memberId', member.id),
          payload,
        ),
      )
    }
  }

  const onSelectParkingItem = (parkingItem: ParkingQuoteItemList) => {
    setSelectedParkingItem(parkingItem)
    if (selectedParkingItem && selectedParkingItem.id === parkingItem.id) {
      return
    }
    if (parkingQuote && currentOperatorId && currentOrganisationId && member) {
      let payload: any = {
        salesChannel: SalesChannelType.Res,
        parkingItem: {
          parkingQuoteId: parkingQuote.id,
          parkingQuoteItemId: parkingItem.id,
        },
      }
      if (parkingBasket) {
        dispatch(
          putBasketAggregate<ParkingBasket>(
            AGGREGATE_PARKING_BASKET,
            PARKING_BASKET_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
              .replace(':carParkTenantId', currentOrganisationId)
              .replace(':memberId', member.id),
            payload,
            parkingBasket.basketId,
          ),
        )
      } else {
        dispatch(
          postAggregate<ParkingBasket>(
            AGGREGATE_PARKING_BASKET,
            PARKING_BASKET_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
              .replace(':carParkTenantId', currentOrganisationId)
              .replace(':memberId', member.id),
            payload,
          ),
        )
      }
    }
  }

  const onBook = () => {
    if (parkingBasket && currentOperatorId && currentOrganisationId && member) {
      let payload: any = {
        salesChannelType: SalesChannelType.Res,
        basketId: parkingBasket.basketId,
      }
      dispatch(
        postAggregate<ParkingOrder>(
          AGGREGATE_PARKING_ORDER,
          PARKING_ORDER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':memberId', member.id),
          payload,
        ),
      )
    }
  }

  return (
    <div className="page-content-wrapper">
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2 no-padding">
                  <a className="back-icon" href="/on-demand">
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Book on-demand parking
                  </a>
                </h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="page-content-body-wrapper assigned-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            <Grid item xs={12} className="content-panel">
              {/* Who are you booking for section */}
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <div className="on-demand-icon-block">
                    <img className="assigned-icon who-booking-for" src={manageParkersIcon} alt="manageParkersIcon" />
                    <h4 className="list-item-header">Who are you booking for?</h4>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <p className="search-users-title">
                    <strong>Select a staff member</strong>
                  </p>

                  <Autocomplete
                    disablePortal
                    clearIcon={null}
                    popupIcon={null}
                    className="book-parking-autocomplete"
                    value={member}
                    inputValue={memberInput}
                    onChange={(_event: any, newValue: Member | null) => {
                      onChangeMember(newValue)
                    }}
                    onInputChange={(_event, newInputValue) => {
                      if (newInputValue) {
                        setMemberInput(newInputValue)
                      } else {
                        setMember(null)
                        setMemberInput('')
                      }
                    }}
                    options={memberList}
                    loading={memberLoadingList}
                    loadingText="Loading..."
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => {
                      return memberInput.includes('@') ? option.email : option.name
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Box component="li" {...props}>
                          <span style={{ fontWeight: '500' }}>{option.name}&nbsp;</span> (<small>{option.email}</small>)
                        </Box>
                      )
                    }}
                    noOptionsText={
                      memberLoadingOne && memberInput && memberList.length === 0 ? (
                        isTenantHasGuestAccess ? (
                          <p className="search-users-text">
                            No matching staff members found. if this staff member isn't registered for Shuffle,
                            <a href="/users/manage-users"> add them now</a> or
                            <a href="/guest/book-guest-parking"> book guest parking</a> instead.
                          </p>
                        ) : (
                          <p className="search-users-text">
                            No matching staff members found. if this staff member isn't registered for Shuffle,
                            <a href="/users/manage-users"> add them now</a>.
                          </p>
                        )
                      ) : (
                        'No results'
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="outlined-basic"
                        placeholder="Search by name or email address"
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          className: 'shuffleInput',
                          style: { padding: 0 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="search-field-icon" src={searchFieldIcon} alt="img" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {member && (
                                <img
                                  className={classes.closeIcon}
                                  src={crossFieldIcon}
                                  alt="closeIcon"
                                  onClick={onClearMember}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  {isTenantHasGuestAccess ? (
                    <p className="search-users-text">
                      If this staff member isn’t registered for Shuffle, <a href="/users/manage-users">add them now</a>{' '}
                      or
                      <a href="/guest/book-guest-parking"> book guest parking</a> instead.
                    </p>
                  ) : (
                    <p className="search-users-text">
                      If this staff member isn’t registered for Shuffle, <a href="/users/manage-users">add them now</a>.
                    </p>
                  )}
                </Grid>
              </Grid>
              {member && (
                <>
                  {/* Where and when section */}
                  <Grid container spacing={0} className="book-parking-list-item">
                    <Grid item xs={4}>
                      <div className="on-demand-icon-block">
                        <img className="guest-icon date-icon" src={dateIcon} alt="dateIcon" />
                        <h4 className="list-item-header">Where and when?</h4>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputLabel className="select-heading">Location</InputLabel>
                          <Select
                            className="shuffle-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                            value={location}
                            onChange={handleLocationOnchange}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="" className="selectBold">
                              Any Location
                            </MenuItem>
                            {memberLocationList.map((tenancy: Tenancy) => (
                              <MenuItem key={tenancy.id} value={tenancy.id}>
                                {tenancy.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={3.7}>
                          <p className="input-label">Arrive</p>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={dateAndTimeInterval.startDate}
                              onChange={(newValue) => handleChangeDateAndTime(newValue, 'startDate')}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              minDate={new Date()}
                              maxDate={dateAndTimeInterval.endDate ? new Date(dateAndTimeInterval.endDate) : null}
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              renderInput={({ ...params }) => (
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  required
                                  {...params}
                                  error={dateAndTimeIntervalError.startDate.length > 0}
                                  helperText={dateAndTimeIntervalError.startDate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2.3}>
                          <InputLabel className="select-heading">&nbsp;</InputLabel>
                          <Autocomplete
                            id="startTime"
                            className="book-parking-autocomplete"
                            clearIcon={null}
                            options={timeSlots}
                            value={
                              dateAndTimeInterval.startTime ? getAPTimeFormDate(dateAndTimeInterval.startTime) : ''
                            }
                            disableClearable
                            onChange={(_event: any, newValue: string | null) => {
                              handleChangeDateAndTime(newValue ? getDateFormAPTime(newValue) : null, 'startTime')
                            }}
                            renderInput={({ inputProps, ...params }) => (
                              <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                {...params}
                                inputProps={{
                                  ...inputProps,
                                  placeholder: 'hh:mm',
                                }}
                                error={dateAndTimeIntervalError.startTime.length > 0}
                                helperText={dateAndTimeIntervalError.startTime}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3.7}>
                          <p className="input-label">Depart</p>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={dateAndTimeInterval.endDate}
                              onChange={(newValue) => handleChangeDateAndTime(newValue, 'endDate')}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              minDate={getMinDateForEndDate(dateAndTimeInterval.startDate)}
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              renderInput={({ ...params }) => (
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  required
                                  {...params}
                                  error={dateAndTimeIntervalError.endDate.length > 0}
                                  helperText={dateAndTimeIntervalError.endDate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2.3}>
                          <InputLabel className="select-heading">&nbsp;</InputLabel>
                          <Autocomplete
                            id="endTime"
                            className="book-parking-autocomplete"
                            clearIcon={null}
                            options={timeSlots}
                            value={dateAndTimeInterval.endTime ? getAPTimeFormDate(dateAndTimeInterval.endTime) : ''}
                            disableClearable
                            onChange={(_event: any, newValue: string | null) => {
                              handleChangeDateAndTime(newValue ? getDateFormAPTime(newValue) : null, 'endTime')
                            }}
                            renderInput={({ inputProps, ...params }) => (
                              <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                {...params}
                                inputProps={{
                                  ...inputProps,
                                  placeholder: 'hh:mm',
                                }}
                                error={dateAndTimeIntervalError.endTime.length > 0}
                                helperText={dateAndTimeIntervalError.endTime}
                              />
                            )}
                            filterOptions={(options, state) => {
                              let tempOptions: string[] = options
                              if (
                                dateAndTimeInterval.startDate &&
                                dateAndTimeInterval.endDate &&
                                dateAndTimeInterval.startTime
                              ) {
                                let startDate = new Date(dateAndTimeInterval.startDate).setHours(0, 0, 0, 0)
                                let endDate = new Date(dateAndTimeInterval.endDate).setHours(0, 0, 0, 0)
                                let startTime = dateAndTimeInterval.startTime
                                let isStartDateValid = isValid(startDate)
                                let isEndDateValid = isValid(endDate)
                                if (isStartDateValid && isEndDateValid && isEqual(startDate, endDate)) {
                                  let validOptions: string[] = []
                                  options.forEach((option) => {
                                    let endTime: Date | null = getDateFormAPTime(option)
                                    if (startTime && endTime) {
                                      let statTimeSlot = startTime.getHours() * 60 + startTime.getMinutes()
                                      let endTimeSlot = endTime.getHours() * 60 + endTime.getMinutes()
                                      if (endTimeSlot > statTimeSlot) {
                                        validOptions.push(option)
                                      }
                                    }
                                  })
                                  tempOptions = validOptions
                                }
                              }
                              return tempOptions.filter((option) => option.includes(state.inputValue))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '15px' }}>
                          <Button
                            className={classes.shuffleSearchButtonSml}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={isSearchDisable}
                            onClick={onSearch}
                          >
                            {parkingQuoteLoading ? (
                              <CircularProgress style={{ color: '#FFFFFF', width: 25, height: 25 }} />
                            ) : (
                              'Search'
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Choose a parking product with success section*/}
                  {parkingQuote && (
                    <>
                      {/* Things to know about Staff Parking section*/}
                      <Grid container spacing={0} className="book-parking-list-item">
                        <Grid item xs={4}>
                          <div className="on-demand-icon-block">
                            <img className="guest-icon date-icon" src={prodsCapacitiesIcon} alt="prodsCapacitiesIcon" />
                            <h4 className="list-item-header">
                              Choose a <br />
                              parking product
                            </h4>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container spacing={2}>
                            {parkingQuote.itemList.length > 0 ? (
                              <Grid item xs={12} className="parking-products">
                                {parkingQuote.itemList.map((item: ParkingQuoteItemList) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className={
                                        (selectedParkingItem?.id === item.id ? 'selected' : ' ') +
                                        ' parking-product-wrapper'
                                      }
                                      onClick={() => onSelectParkingItem(item)}
                                    >
                                      <div className="product-icon-wrapper">
                                        {item.offer.imageUrl ? (
                                          <img
                                            className="product-icon car-icon"
                                            src={item.offer.imageUrl}
                                            alt="productIconCar"
                                          />
                                        ) : (
                                          <img
                                            className="product-icon car-icon"
                                            src={productIconCar}
                                            alt="productIconCar"
                                          />
                                        )}
                                      </div>
                                      <h4 className="parking-product-title">{item?.offer?.name}</h4>
                                      <p className="parking-product-price">
                                        {item?.stayAgreements[0]?.charge > 0
                                          ? '$' + formatNumberWithDecimalPlace(item?.stayAgreements[0]?.charge, 2)
                                          : ''}
                                      </p>
                                    </div>
                                  )
                                })}
                              </Grid>
                            ) : (
                              <Grid item xs={12} className="parking-products">
                                <p className="inline-alert">
                                  <img className="warning-icon" src={warningIcon} alt="warningIcon" />
                                  Sorry there is no parking available at {getSelectedLocationName()} at the selected
                                  time.
                                </p>
                              </Grid>
                            )}

                            {parkingBasket && (
                              <Grid item xs={12}>
                                <h4 className="product-desc-title">Things to know about Staff Parking</h4>
                                <p className="product-desc-body">{selectedParkingItem?.offer?.marketing?.details}</p>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* This is an administrator booking section*/}
                      {parkingBasket && selectedParkingItem && selectedParkingItem?.stayAgreements[0].charge > 0 && (
                        <Grid container spacing={0} className="book-parking-list-item">
                          <Grid item xs={4}></Grid>
                          <Grid item xs={8}>
                            <h4 className="product-desc-title">This is an administrator booking</h4>
                            <p className="product-desc-body">
                              The price of this booking ($
                              {formatNumberWithDecimalPlace(parkingBasket.basket.paymentAmount, 2)}){' '}
                              <strong>will not be charged</strong>.
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  {/* Choose a parking product with error section */}
                  {parkingQuoteError && (
                    <Grid container spacing={0} className="book-parking-list-item">
                      <Grid item xs={4}>
                        <div className="on-demand-icon-block">
                          <img className="guest-icon date-icon" src={prodsCapacitiesIcon} alt="prodsCapacitiesIcon" />
                          <h4 className="list-item-header">
                            Choose a <br />
                            parking product
                          </h4>
                        </div>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} className="parking-products">
                            <p className="inline-alert">
                              <img className="warning-icon" src={warningIcon} alt="warningIcon" />
                              {parkingQuoteError === EXISTING_BOOKING_ERROR ? (
                                <>
                                  <span>Sorry this user already has a booking at the same time and location.</span>
                                  <span
                                    className="a-span-link"
                                    onClick={() =>
                                      navigate('/users/manage-users', {
                                        state: { memberData: member },
                                      })
                                    }
                                  >
                                    &nbsp;Manage user account
                                  </span>
                                  .
                                </>
                              ) : (
                                parkingQuoteError
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {/* Button Section */}
            <Grid item xs={12} style={{ paddingTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-start">
                    <Button
                      className={classes.shuffleButton}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => navigate(ROUTE_ON_DEMAND)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      className={classes.shuffleButton}
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={isBookDisable}
                      onClick={onBook}
                    >
                      {parkingOrderLoading ? (
                        <CircularProgress style={{ color: '#FFFFFF', width: 29, height: 29 }} />
                      ) : (
                        'Book'
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default BookParking
