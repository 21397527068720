import { Box, Container, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { OrganisationType } from '../../../common/types'
import Seo from '../../../components/seo'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { LOCAL_STORAGE_SELECTED_TENANT_ID } from '../../../config/constants'
import Footer from '../../../layout/footer'
import PrivateHeader from '../../../layout/main/nav/private-header'
import DisplayNotification from '../../../store/common/notifications/DisplayNotification'
import { ROUTE_HOME } from '../../util/routes'
import { selectCurrentUser } from '../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginModal: {
      background: 'white',
      padding: '30px',
      borderRadius: '4px',
      position: 'absolute',
      top: '194px',
      width: '367px',
      textAlign: 'center',
    },
  }),
)

interface IProps {}

const TenantSelector: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentUser = useSelector(selectCurrentUser)

  // Check operator & tenant
  useEffect(() => {
    if (currentOperatorId && currentUser && currentUser.organisationType === OrganisationType.Operator) {
      // if present is local storage
      let tenantId = localStorage.getItem(LOCAL_STORAGE_SELECTED_TENANT_ID)
      if (tenantId) {
        navigate(ROUTE_HOME)
      }
    } else {
      navigate(ROUTE_HOME)
    }
    // eslint-disable-next-line
  }, [dispatch, currentOperatorId, currentUser])

  return (
    <>
      <Seo />
      <Container className="loginBG">
        <DisplayNotification />
        <PrivateHeader isHideBrandingImage={true} />
        <Box className={classes.loginModal}>
          <Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <h4 className="login-h4">Select your Tenant</h4>
                <p className="reset-pass-message mt-12 mb-0 desktop-only">
                  To proceed, please select a Tenant from the dropdown selector at the top of this page.
                </p>
                <p className="reset-pass-message mt-12 mb-0 mobile-only">
                  To proceed, please select a Tenant from the dropdown selector from top menu item.
                </p>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer />
      </Container>
    </>
  )
}
export default TenantSelector
