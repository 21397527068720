import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AccessType, AggregateState } from '../../../common/types'

export enum PhysicalFacilityType {
  PrimaryCarPark = 'PrimaryCarPark',
  NestedCarPark = 'NestedCarPark',
}

export interface AccessPointInfo {
  id: string
  accessType: AccessType
  name: string
}

export interface PublicFacilityDto {
  id: string
  publicName: string
  publicId: string
}

export interface AccessInfo {
  primaryAccessPoints: AccessPointInfo[]
  nestedAccessPoints: AccessPointInfo[]
  physicalFacilityType: PhysicalFacilityType
  primaryFacilityInfo: PublicFacilityDto
  nestedFacilityInfo: PublicFacilityDto
}

export type AccessInfoState = AggregateState<AccessInfo>
export type AccessInfoActionTypes = AggregateActionTypes<AccessInfo>

export const ACCESS_INFO_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId/access-info'
