import React from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { selectCurrentFirebaseUser } from '../types'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginModal: {
      background: 'white',
      padding: '40px',
      borderRadius: '4px',
      position: 'absolute',
      top: '194px',
      width: '367px',
      textAlign: 'center',
    },
  }),
)

interface IProps {}

const ResetPasswordEmail: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const user = useSelector(selectCurrentFirebaseUser)
  const { t } = useTranslation()

  if (!!user) {
    return <Navigate to="/home" />
  } else {
    return (
      <Box className={classes.loginModal}>
        <Container maxWidth="lg" disableGutters={true} className="login-modal-styles">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <h3 className="login-h3">{t('reset-password:title')}</h3>
            </Grid>
            <Grid item xs={12}>
              <h4 className="login-h4">{t('reset-password:subTitle')}</h4>
              <p className="reset-pass-message">{t('reset-password:labels.successText')}</p>
            </Grid>
            <Grid item xs={12}>
              <a className="password-link login-back" href="/login">
                {t('reset-password:link.backToLogin')}
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }
}
export default ResetPasswordEmail
