import axios from 'axios'
import { ExistsAttribute, ExitsDto } from './types'
import { AUTHORIZATION_HEADER, BEARER_PREFIX } from '../../../config/constants'

export const checkExists = async (
  existsAttribute: ExistsAttribute,
  url: string,
  coreUrl: string,
  token: string,
): Promise<boolean> => {
  let exists: boolean = false
  await axios({
    method: 'POST',
    url: coreUrl + url,
    data: {
      id: existsAttribute.id,
      value: existsAttribute.value,
    },
    headers: { [AUTHORIZATION_HEADER]: BEARER_PREFIX + token },
  })
    .then((resp) => {
      let data = resp.data as ExitsDto
      if (data) {
        exists = data.exists
      }
      return false
    })
    .catch((err) => {
      exists = false
    })

  return exists
}
