import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import reservedIcon from '../../../assets/svg/ws-b2b-icon-reserved-2.svg'
import warningIcon from '../../../assets/svg/ws-ui-icon-warning-critical-red.svg'
import { AGGREGATE_MANAGE_BAY, AGGREGATE_MY_LIST_TENANCIES } from '../../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import LoadingInDiv from '../../../components/ui/Loading/LoadingInDiv'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { Tenancy, TENANCY_API_RESOURCE_PATH } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { BayAssignment } from '../../guest/types'
import {
  ManageEntitlement,
  ManageEntitlementAllocation,
  ManageEntitlementBay,
  ManageEntitlementZone,
  MANAGE_BAY_API_RESOURCE_PATH,
} from './types'

const useStyles = makeStyles((theme) => ({
  reservedButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    backgroundColor: '#009273',
    fontSize: '0.875rem',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
    width: '120px',
    height: '38px',
  },
  reservedButtonSmlOL: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#525252',
    backgroundColor: '#ffffff',
    fontSize: '0.875rem',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
    width: '120px',
    height: '38px',
    border: '1px solid #979797',
  },
  dropDownFontWeight: {
    fontWeight: '500',
  },
  dropDownFontWeightLight: {
    fontWeight: '400',
  },
}))

interface IProps {
  setIsValidBaySelection: Dispatch<SetStateAction<boolean>>
  setBayAssignment: Dispatch<SetStateAction<BayAssignment | null>>
  isNextPage: boolean
}

const FindBay: React.FC<IProps> = ({ setIsValidBaySelection, setBayAssignment, isNextPage }: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [location, setLocation] = useState<string>('')
  const [level, setLevel] = useState<string>('')
  const [zone, setZone] = useState<string>('')
  const [levelList, setLevelList] = useState<ManageEntitlementAllocation[]>([])
  const [zoneList, setZoneList] = useState<ManageEntitlementZone[]>([])
  const [bayList, setBayList] = useState<ManageEntitlementBay[]>([])
  const [selectedBay, setSelectedBay] = useState<ManageEntitlementBay | null>(null)
  const [isNoBaysAvailableMessage, setIsNoBaysAvailableMessage] = useState<boolean>(false)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const { tenancies, entitlementDetails, entitlementDetailLoading } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
    entitlementDetails: state.manageBayReducer.aggregate,
    entitlementDetailLoading: state.manageBayReducer.loadingOne,
  }))

  // Fetch location or tenancies list
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId])

  // Fetch level and zone list on change of location
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && location) {
      dispatch(
        fetchAggregate<ManageEntitlement>(
          AGGREGATE_MANAGE_BAY,
          MANAGE_BAY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', location) + '/bays',
          null,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Set level list
  useEffect(() => {
    if (entitlementDetails) {
      if (entitlementDetails.allocations) {
        setLevelList(entitlementDetails.allocations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlementDetails])

  // Set zone list
  useEffect(() => {
    if (level) {
      let selectedLevel: ManageEntitlementAllocation | undefined = levelList.find(
        (l: ManageEntitlementAllocation) => l.allocationId === level,
      )
      if (selectedLevel) {
        setZoneList(selectedLevel.zones)
      }
    } else {
      setZoneList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level])

  // Set bay list
  useEffect(() => {
    if (zone) {
      let selectedZone: ManageEntitlementZone | undefined = zoneList.find(
        (z: ManageEntitlementZone) => z.zoneId === zone,
      )
      if (selectedZone) {
        setBayList(selectedZone.bays)
      }
    } else {
      setBayList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone])

  // Check error message conditions
  useEffect(() => {
    if (levelList) {
      if (levelList.length > 0) {
        let unavailableBayList: ManageEntitlementAllocation[] = levelList.filter(
          (level: ManageEntitlementAllocation) => level.bayCount === 0,
        )
        if (unavailableBayList.length === levelList.length) {
          setIsNoBaysAvailableMessage(true)
        } else {
          setIsNoBaysAvailableMessage(false)
        }
      } else {
        setIsNoBaysAvailableMessage(false)
      }
    } else {
      setIsNoBaysAvailableMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelList])

  // Check bay selection is valid or not for next button
  useEffect(() => {
    if (location && !isNoBaysAvailableMessage && level && zone && selectedBay) {
      setIsValidBaySelection(true)
    } else {
      setIsValidBaySelection(false)
    }
  })

  // Prepare bay assignment payload
  useEffect(() => {
    let selectedLocation = tenancies.find((l: Tenancy) => l.id === location)
    let selectedLevel = levelList.find((l: ManageEntitlementAllocation) => l.allocationId === level)
    let selectedZone = zoneList.find((z: ManageEntitlementZone) => z.zoneId === zone)
    let bayAssignment: BayAssignment = {
      entitlementBayId: '',
      bayReservedType: '',
      facilityId: location,
      facilityAllocationId: level,
      facilityZoneId: zone,
      bayId: selectedBay ? selectedBay.id : null,
      fullBayNo: selectedBay ? selectedBay.fullBayNumber : null,
      entitlementBayVersion: 0,
      facilityName: selectedLocation ? selectedLocation.name : '',
      facilityAllocationName: selectedLevel ? selectedLevel.allocationName : '',
      facilityZoneName: selectedZone ? selectedZone.zoneName : '',
    }
    setBayAssignment(bayAssignment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, level, zone, selectedBay])

  const changeLocation = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string)
    setLevel('')
    setZone('')
    setSelectedBay(null)
  }

  const changeLevel = (event: SelectChangeEvent) => {
    setLevel(event.target.value as string)
    setZone('')
    setSelectedBay(null)
    setIsNoBaysAvailableMessage(false)
  }

  const changeZone = (event: SelectChangeEvent) => {
    setZone(event.target.value as string)
    setSelectedBay(null)
  }

  const onSelectBay = (bay: ManageEntitlementBay) => {
    setSelectedBay(bay)
  }

  const getBayItemClassName = (bay: ManageEntitlementBay) => {
    if (selectedBay) {
      if (selectedBay.id === bay.id) {
        return 'selected-bay'
      } else {
        return 'available-bay'
      }
    } else {
      return 'available-bay'
    }
  }

  return (
    <Grid item xs={12} className={isNextPage ? 'display-none' : 'content-panel larger-space'}>
      <Grid container spacing={0} className="guest-bay-type-wrapper">
        <Grid item xs={4}>
          <div className="assigned-icon-block">
            <img className="guest-icon reserved-icon" src={reservedIcon} alt="reserved icon" />
            <h4 className="">Find a bay</h4>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            {entitlementDetailLoading && <LoadingInDiv />}
            <Grid item xs={6}>
              <InputLabel className="select-heading">Location</InputLabel>
              <Select
                className="shuffle-select"
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                variant="outlined"
                margin="dense"
                fullWidth
                value={location}
                onChange={changeLocation}
                style={{ fontWeight: '500' }}
              >
                {tenancies.map((location: Tenancy) => (
                  <MenuItem key={location.id} value={location.id} className={classes.dropDownFontWeight}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className="select-heading">Level</InputLabel>
              <Select
                className="shuffle-select"
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                variant="outlined"
                margin="dense"
                fullWidth
                value={level}
                onChange={changeLevel}
                style={{ fontWeight: '500' }}
              >
                {(!levelList || levelList.length === 0) && (
                  <MenuItem value="" className={classes.dropDownFontWeight}>
                    No level available
                  </MenuItem>
                )}
                {levelList &&
                  levelList.map(
                    (level: ManageEntitlementAllocation) =>
                      level.zones.length !== 0 && (
                        <MenuItem
                          key={level.allocationId}
                          value={level.allocationId}
                          disabled={level.bayCount === 0}
                          className={classes.dropDownFontWeight}
                        >
                          {level.bayCount === 0 ? (
                            <span className={classes.dropDownFontWeightLight}>{level.allocationName} full</span>
                          ) : (
                            <span>
                              {level.allocationName} - {level.bayCount} bays available
                            </span>
                          )}
                        </MenuItem>
                      ),
                  )}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className="select-heading">Zone</InputLabel>
              <Select
                className="shuffle-select assign-bay-type-select"
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                variant="outlined"
                margin="dense"
                fullWidth
                disabled={level === 'unZoned'}
                value={zone}
                onChange={changeZone}
                style={{ fontWeight: '500' }}
              >
                {(!zoneList || zoneList.length === 0) && (
                  <MenuItem value="" className={classes.dropDownFontWeight}>
                    No zone available
                  </MenuItem>
                )}
                {zoneList &&
                  zoneList.map((zone: ManageEntitlementZone) => (
                    <MenuItem
                      key={zone.zoneId}
                      value={zone.zoneId}
                      className={classes.dropDownFontWeight}
                      disabled={zone.bayCount === 0}
                    >
                      {zone.zoneName}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          {isNoBaysAvailableMessage && (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingTop: '55px', paddingBottom: '12px' }}>
                <Box className="unavailable-bay-alert">
                  <img className="bay-warning-icon" src={warningIcon} alt="Warning Icon" />
                  <p className="bay-warning-text">
                    <strong>There are no reserved bays at this location.</strong>
                  </p>
                </Box>
              </Grid>
            </Grid>
          )}
          {bayList.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingTop: '40px' }}>
                <p className="input-label">Select the reserved bay</p>
                <div className="bay-usage-wrapper">
                  <ul className="guest-bay-usage-menu">
                    {bayList &&
                      bayList.map((bay: ManageEntitlementBay) => (
                        <li key={bay.id} className="guest-bay-usage-icon">
                          <span className={getBayItemClassName(bay)} onClick={() => onSelectBay(bay)}>
                            {bay.fullBayNumber}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="bay-usage-key-wrapper">
                  <p className="bay-usage-key">
                    <span className="key-icon selected-bay-icon"></span>Selected
                    <span className="key-icon available-bay-icon"></span>Available
                    <span className="key-icon unavailable-bay-icon"></span>Unavailable
                  </p>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FindBay
