import React from 'react'
import wilsonLogo from '../../../assets/svg/Wilson-Parking-Logo.svg'

interface IProps {}

const PublicHeader: React.FC<IProps> = (props) => {
  return (
    <>
      <div id="header-wrapper" className="login-header">
        <div id="top-nav-header">
          <div className="top-nav-holder">
            <ul id="shuffle-home-nav">
              <li className="selected">
                <a href="/" className="top-nav" id="shuffle-tab">
                  Shuffle
                </a>
              </li>
            </ul>
            <ul id="shuffle-top-nav">
              <li>
                <a
                  href="https://shufflehelp.wilsonparking.com.au/hc/en-au"
                  target="_blank"
                  rel="noreferrer"
                  className="top-nav"
                  id="support-tab"
                >
                  Support
                </a>
              </li>
              <li>
                <a
                  href="https://www.wilsonparking.com.au/Business/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                  className="top-nav"
                  id="contact-tab"
                >
                  Contact Wilson Parking
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div id="header" className="login-nav">
          <a id="wilson-parking-logo" href="/">
            <img src={wilsonLogo} alt="Wilson parking" />
          </a>
        </div>
      </div>
    </>
  )
}

export default PublicHeader
