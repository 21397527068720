import { createSvgIcon } from '@mui/material'

export const CustomCalendarIcon = createSvgIcon(
  <path
    d="M5,4H2V8H22V4H19V5a1,1,0,0,1-2,0V4H13V5a1,1,0,0,1-2,0V4H7V5A1,1,0,0,1,5,5Zm14,8a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1Zm-6,0a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1ZM7,12a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1Zm6,5a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1ZM7,17a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1Zm15-7H2V22H22ZM18,0a1,1,0,0,1,1,1V2h4a1,1,0,0,1,1,1V23a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V3A1,1,0,0,1,1,2H5V1A1,1,0,0,1,7,1V2h4V1a1,1,0,0,1,2,0V2h4V1A1,1,0,0,1,18,0Z"
    fill="#979797"
  />,
  'CustomCalendar',
)
export const CustomClockIcon = createSvgIcon(
  <>
    <path
      d="M12,2A10,10,0,0,1,22,12h2A12,12,0,0,0,12,0ZM2,12A10,10,0,0,1,12,2V0A12,12,0,0,0,0,12ZM12,22A10,10,0,0,1,2,12H0A12,12,0,0,0,12,24ZM22,12A10,10,0,0,1,12,22v2A12,12,0,0,0,24,12Z"
      fill="#979797"
    />
    <path d="M12,13h6V11H12ZM11,6v6h2V6Zm7,7h1V11H18Zm-6-1H11v1h1Zm1-6V5H11V6Z" fill="#979797" />
  </>,
  'CustomClock',
)
