import React, { useState } from 'react'
import { Container, Grid } from '@mui/material'
import backIcon from '../../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import AddDomainModelForm from '../../../../components/model/AddDomainModelForm'
import AutoInvitationForm from './auto-invitation/auto-invitation-form'
import DomainForm from './domains/domain-form'
import AddSelfRegistrationModelForm from '../../../../components/model/AddSelfRegistrationModelForm'
import ConfirmModel from './auto-invitation/model/ConfirmModel'
import { Lifecycle, LifecycleOnly } from '../../../../common/types'
import { updateLifeCycle } from '../../../../common/axios-action'
import { AUTO_INVITATION_API_RESOURCE_PATH, MemberAutoInvitationRequest } from './auto-invitation/types'
import { AGGREGATE_AUTO_INVITATION } from '../../../../common/aggregate.types'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId } from '../../../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../../auth/types'

interface IProps {}

const SelfRegistration: React.FC<IProps> = (props) => {
  const [displayDomainModel, setDisplayDomainModel] = useState<boolean>(false)
  const [displaySelfRegistrationModel, setDisplaySelfRegistrationModel] = useState<boolean>(false)

  const [domainId, setDomainId] = useState<string | null>(null)
  const [registrationId, setRegistrationId] = useState<string | null>(null)

  const [displayDeactivateModel, setDisplayDeactivateModel] = useState<boolean>(false)
  const [deactivateId, setDeactivateId] = useState<string | null>(null)
  const [displayName, setDisplayName] = useState<string>('')

  const [displayDeleteModel, setDisplayDeleteModel] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string | null>(null)

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const hideDomainModel = () => {
    setDomainId(null)
    setDisplayDomainModel(false)
  }

  const showDomainModel = (id: string | null) => {
    setDomainId(id)
    setDisplayDomainModel(true)
  }

  const hideSelfRegistrationModel = () => {
    setRegistrationId(null)
    setDisplaySelfRegistrationModel(false)
  }

  const showSelfRegistrationModel = (id: string | null) => {
    setRegistrationId(id)
    setDisplaySelfRegistrationModel(true)
  }

  const hideDeleteConfirmModel = () => {
    setDisplayDeleteModel(false)
  }

  const confirmDeactivateConfirmModel = () => {
    if (currentOperatorId && currentOrganisationId && deactivateId) {
      let lifecycleOnly: LifecycleOnly = { lifecycle: Lifecycle.Suspended }
      dispatch(
        updateLifeCycle<MemberAutoInvitationRequest>(
          AGGREGATE_AUTO_INVITATION,
          AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          deactivateId,
          lifecycleOnly,
        ),
      )
    }
  }

  const hideDeactivateConfirmModel = () => {
    setDisplayDeactivateModel(false)
  }

  const confirmDeleteConfirmModel = () => {
    if (currentOperatorId && currentOrganisationId && deleteId) {
      let lifecycleOnly: LifecycleOnly = { lifecycle: Lifecycle.Deleted }
      dispatch(
        updateLifeCycle<MemberAutoInvitationRequest>(
          AGGREGATE_AUTO_INVITATION,
          AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          deleteId,
          lifecycleOnly,
        ),
      )
    }
  }

  return (
    <>
      <ConfirmModel
        display={displayDeactivateModel}
        heading={'Are you sure want to deactivate ' + displayName + '?'}
        label="Once deactivated, users won't be able to access this link to complete their self registration. You can reactivate this link at any time."
        hideModel={hideDeactivateConfirmModel}
        confirmAction={confirmDeactivateConfirmModel}
        confirmButtonLabel="Deactivate"
      />
      <ConfirmModel
        display={displayDeleteModel}
        heading={'Are you sure you want to permanently delete ' + displayName + '?'}
        label="Once deleted, users won't be able to access this link to complete their self registration. This link can't be reinstated once deleted."
        hideModel={hideDeleteConfirmModel}
        confirmAction={confirmDeleteConfirmModel}
        confirmButtonLabel="Delete"
      />

      <AddDomainModelForm display={displayDomainModel} hideModel={hideDomainModel} id={domainId} />
      <AddSelfRegistrationModelForm
        display={displaySelfRegistrationModel}
        hideModel={hideSelfRegistrationModel}
        id={registrationId}
      />

      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href="/settings">
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Self registration link
                  </a>
                </h2>
              </Grid>
            </Grid>

            <Grid container spacing={0} className="responsive-content-wrapper full-width">
              <Grid item xs={12}>
                <p>
                  You can allow your staff to register for on-demand parking via a link on your intranet or other
                  company channels.
                </p>
                <p>Choose to manually review users, or automatically approve them based on verified email addresses.</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="page-content-body-wrapper settings-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            <AutoInvitationForm
              display={displaySelfRegistrationModel}
              hideModel={hideSelfRegistrationModel}
              showModel={showSelfRegistrationModel}
              setDeactivateDialog={setDisplayDeactivateModel}
              setDeleteDialog={setDisplayDeleteModel}
              setDeactivateId={setDeactivateId}
              setDeleteId={setDeleteId}
              setDisplayName={setDisplayName}
              displayName={displayName}
            />
            <DomainForm display={displayDomainModel} hideModel={hideDomainModel} showModel={showDomainModel} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default SelfRegistration
