import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import prodsCapacitiesIcon from '../../../assets/svg/ws-b2b-icon-car_park.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import {
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_OFFER_FACILITY,
  AGGREGATE_TENANCIES_OFFERS,
} from '../../../common/aggregate.types'
import { fetchAggregates, updateAggregate } from '../../../common/axios-action'
import { ErrorResponse } from '../../../common/types'
import UnsavedDataConfirmationModel from '../../../components/model/UnsavedDataConfirmationModel'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import {
  MemberAllocations,
  MEMBER_POOL_NAME_SORTING_ARR,
  OfferFacility,
  OFFER_FACILITY_API_RESOURCE_PATH,
  OFFER_FACILITY_PUT_API_RESOURCE_PATH,
} from '../../../store/common/offer-facilities/types'
import {
  Tenancy,
  TenancyOffer,
  TENANT_CARPARK_TENANCIES_OFFERS_API_RESOURCE_PATH,
} from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import ProductDepartment from './product-department'
import ProductDepartmentMemberPool from './product-department-member-pool'

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '200px',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  customAccordionSummary: {
    height: '128px',
    alignItems: 'center',
    padding: '40px',
  },
  customAccordionSummaryExpanded: {
    height: '100px',
    alignItems: 'center',
    padding: '20px 40px 10px 40px',
  },
  customAccordionDetails: {
    padding: '0px 40px 40px 40px',
  },
  notInUse: {
    backgroundColor: '#f6f6f6',
    boxShadow: 'none',
  },
}))

interface IProps {}

const ProductDetail: React.FC<IProps> = () => {
  const [expanded, setExpanded] = useState<string | false>('')
  const [selectedOffer, setSelectedOffer] = useState<TenancyOffer>()
  const [departmentList, setDepartmentList] = useState<any>({})
  const [selectedTenancy, setSelectedTenancy] = useState<Tenancy>({ id: '', name: '' })
  const { state } = useLocation()
  const [isProductDisable, setIsProductDisable] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenancyOffersList, loadingList, departmentTeamsList } = useSelector(
    (state: RootState) => ({
      tenancyOffersList: state.tenancyOffersReducer.aggregates.values,
      loadingList: state.tenancyOffersReducer.loadingList,
      departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    }),
    shallowEqual,
  )
  const [offerFacilityData, setOfferFacilityData] = useState<any>({})
  const { offerFacilityStateData, offerFacilityUpdated, offerFacilityLoadingList, offerFacilityError }: any =
    useSelector(
      (state: RootState) => ({
        offerFacilityStateData: state.offerFacilityReducer.aggregates.values,
        offerFacilityUpdated: state.offerFacilityReducer.updated,
        offerFacilityLoadingList: state.offerFacilityReducer.loadingList,
        offerFacilityError: state.offerFacilityReducer.error,
      }),
      shallowEqual,
    )
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const classes = useStyles()
  const disableProductNumber: number = 0
  // Handler for unsaved data confirmation
  const [displayUnsaved, setDisplayUnsaved] = useState<boolean>(false)
  const cancelUnsavedModel = () => {
    setDisplayUnsaved(false)
  }
  const confirmUnsavedModel = () => {
    setDisplayUnsaved(false)
    setExpanded(false)
  }

  // For fetch tenancy offers & department and team
  useEffect(() => {
    if (state) {
      const { tenancyId, tenancyName }: any = state
      setSelectedTenancy({ id: tenancyId, name: tenancyName })
      if (currentOperatorId && currentOrganisationId && tenancyId) {
        dispatch(
          fetchAggregates<Tenancy>(
            AGGREGATE_TENANCIES_OFFERS,
            TENANT_CARPARK_TENANCIES_OFFERS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
              .replace(':carParkTenantId', currentOrganisationId)
              .replace(':tenanciesId', tenancyId),
            null,
          ),
        )
        dispatch(
          fetchAggregates<DepartmentTeamListItemDto>(
            AGGREGATE_DEPARTMENTS_TEAMS,
            DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            null,
          ),
        )
      }
    } else {
      navigate('/on-demand/product-capacities')
    }
  }, [state, navigate, currentOperatorId, currentOrganisationId, dispatch])
  // For grouping department and team data
  useEffect(() => {
    if (Object.keys(offerFacilityStateData).length > 0) {
      let offerFacilityDeepCopy: any = JSON.parse(JSON.stringify(offerFacilityStateData))
      formattedDepartmentList(offerFacilityDeepCopy)
      setOfferFacilityData(offerFacilityDeepCopy)
    }
  }, [offerFacilityStateData])
  // For fetch offers facilitys
  const fetchOfferFacility = (
    currentOperatorId: any,
    currentOrganisationId: any,
    tenancyId: any,
    offer: TenancyOffer,
  ) => {
    dispatch(
      fetchAggregates<OfferFacility>(
        AGGREGATE_OFFER_FACILITY,
        OFFER_FACILITY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':carParkTenancyId', tenancyId)
          .replace(':offerId', offer?.offerId)
          .replace(':offerFacilityId', offer.offerFacilityId),
        null,
      ),
    )
  }
  // For re-rendering UI after save data for department and team
  useEffect(() => {
    const { tenancyId }: any = state
    if (offerFacilityUpdated && currentOperatorId && currentOrganisationId && tenancyId) {
      dispatch(setNotification(NotificationType.INFO, ['Your changes has been saved']))
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_TENANCIES_OFFERS,
          TENANT_CARPARK_TENANCIES_OFFERS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':tenanciesId', tenancyId),
          null,
        ),
      )
      dispatch(
        fetchAggregates<OfferFacility>(
          AGGREGATE_OFFER_FACILITY,
          OFFER_FACILITY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', tenancyId)
            .replace(':offerId', selectedOffer ? selectedOffer?.offerId : '')
            .replace(':offerFacilityId', selectedOffer ? selectedOffer?.offerFacilityId : ''),
          null,
        ),
      )
    }
  }, [dispatch, selectedOffer, state, offerFacilityUpdated, currentOperatorId, currentOrganisationId])
  // For display error message
  useEffect(() => {
    if (offerFacilityError) {
      offerFacilityError.forEach((error: ErrorResponse) => {
        dispatch(setNotification(NotificationType.ERROR, [error.message]))
      })
    }
  }, [offerFacilityError, dispatch])
  const formattedDepartmentList = (offerFacility: OfferFacility) => {
    // Check product is disable or not based on allocation
    if (offerFacility.allocation === disableProductNumber) {
      setIsProductDisable(true)
    } else {
      setIsProductDisable(false)
    }
    let groupBy = (array: any, key: any) => {
      return array.reduce((result: any, obj: any) => {
        ;(result[obj[key]] = result[obj[key]] || []).push(obj)
        return result
      }, {})
    }
    let departmentListFromMemberAllocations = groupBy(offerFacility?.memberAllocations, 'departmentId')
    setDepartmentList(departmentListFromMemberAllocations)
  }
  const handleChangeAccordion =
    (panel: string, offer: TenancyOffer) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      const checkForUnsavedChanges = () => {
        let isUnsaved: boolean = true
        if (offerFacilityData.memberAllocationGrouping === 'Team') {
          isUnsaved = JSON.stringify(offerFacilityData) === JSON.stringify(offerFacilityStateData)
        } else {
          offerFacilityStateData.memberAllocations.sort((a: any, b: any) => {
            return (
              MEMBER_POOL_NAME_SORTING_ARR.indexOf(a.memberPool) - MEMBER_POOL_NAME_SORTING_ARR.indexOf(b.memberPool)
            )
          })
          isUnsaved = JSON.stringify(offerFacilityData) === JSON.stringify(offerFacilityStateData)
        }
        if (!isUnsaved) {
          setDisplayUnsaved(true)
        } else {
          setExpanded(false)
        }
      }
      if (!newExpanded) {
        checkForUnsavedChanges()
      } else {
        if (expanded) {
          checkForUnsavedChanges()
        } else {
          setExpanded(panel)
          setSelectedOffer(offer)
          window.scrollTo({ top: 0 })
          const { tenancyId }: any = state
          if (newExpanded && currentOperatorId && currentOrganisationId && tenancyId) {
            fetchOfferFacility(currentOperatorId, currentOrganisationId, tenancyId, offer)
          }
        }
      }
    }
  const handleChangeAutocomplete = (_event: any, value: string | null) => {
    if (value) {
      offerFacilityData.allocation = parseInt(value)
      if (parseInt(value) === disableProductNumber) {
        offerFacilityData.memberAllocations.forEach((member: MemberAllocations) => {
          member.enabled = false
          member.allocation = null
          member.allocationType = null
        })
      }
      formattedDepartmentList(offerFacilityData)
    }
  }
  const generateBaysForTotalCapacity = (maxAllocation: number) => {
    // This will create [0 to maxCapacityLimitForProduct(500)] for capacity selection
    const capacityArr = Array.from({ length: maxAllocation + 1 }, (_, index) => index.toString())
    return capacityArr
  }
  const onSubmit = (offer: TenancyOffer) => {
    console.table(offerFacilityData.memberAllocations)
    const { tenancyId }: any = state
    if (currentOperatorId && currentOrganisationId && tenancyId) {
      dispatch(
        updateAggregate<OfferFacility>(
          AGGREGATE_OFFER_FACILITY,
          OFFER_FACILITY_PUT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', tenancyId)
            .replace(':offerId', offer?.offerId),
          offerFacilityData,
        ),
      )
    }
  }
  const onCancel = () => {
    let offerFacilityDeepCopy: any = JSON.parse(JSON.stringify(offerFacilityStateData))
    formattedDepartmentList(offerFacilityDeepCopy)
    setOfferFacilityData(offerFacilityDeepCopy)
  }
  const getHostName = () => {
    let domain = new URL(window.location.href)
    let origin = domain.origin
    return origin
  }
  return (
    <>
      <div className="page-content-wrapper">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <Grid container spacing={0} className="responsive-title-wrapper">
                <Grid item xs={12}>
                  <h2 className="on-demand-h2">
                    <a className="back-icon" href="/on-demand/product-capacities">
                      <img className="icon-back-arrow" src={backIcon} alt="back" />
                      {selectedTenancy?.name}
                    </a>
                  </h2>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="responsive-content-wrapper full-wdith">
                <Grid item xs={12}>
                  <p>
                    Set capacity limits at this car park <br /> based on teams.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              {loadingList ? (
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <CircularProgress color="primary" size={30} />
                  </Grid>
                </Grid>
              ) : (
                tenancyOffersList.length > 0 &&
                tenancyOffersList.map((offer: TenancyOffer, index: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      className={
                        offer.offerFacilityAllocation === disableProductNumber
                          ? 'offer-panel not-in-use'
                          : 'offer-panel'
                      }
                      key={offer.offerId}
                    >
                      <Accordion
                        expanded={expanded === 'panel_' + index}
                        onChange={handleChangeAccordion('panel_' + index, offer)}
                      >
                        <AccordionSummary
                          className={
                            expanded === 'panel_' + index
                              ? classes.customAccordionSummaryExpanded
                              : classes.customAccordionSummary
                          }
                        >
                          <Grid container>
                            <Grid item xs={12} className="show-hide-wrapper">
                              <img
                                className="parking-icon earlybird-icon"
                                alt="parking-icon"
                                src={offer.offerImageUrl ? getHostName() + offer.offerImageUrl : prodsCapacitiesIcon}
                              />
                              <div className="heading-wrapper">
                                <h3 className="accordion-title">{offer.offerName}</h3>
                                {expanded !== 'panel_' + index && (
                                  <p className="show-hide-subhead">
                                    {offer.offerFacilityAllocation === disableProductNumber
                                      ? 'Not in use'
                                      : offer.offerFacilityAllocation + ' bays maximum'}
                                  </p>
                                )}
                              </div>
                              <p className={expanded === 'panel_' + index ? 'hide-details' : 'show-details'}>
                                {expanded === 'panel_' + index ? 'Hide details' : 'Show details'}
                              </p>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails className={classes.customAccordionDetails}>
                          {offerFacilityLoadingList ? (
                            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                              <Grid item xs={12}>
                                <CircularProgress color="primary" size={30} />
                              </Grid>
                            </Grid>
                          ) : (
                            Object.keys(offerFacilityData).length > 0 &&
                            expanded === 'panel_' + index && (
                              <>
                                {/* Capacity Section*/}
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <InputLabel className="select-heading">Total capacity limit for product</InputLabel>
                                    <Autocomplete
                                      clearIcon={null}
                                      value={offerFacilityData.allocation.toString()}
                                      onChange={(event, value) => handleChangeAutocomplete(event, value)}
                                      // onInputChange={(event, value) => handleChangeAutocomplete(event, value)}
                                      id={'autocomplete-panel' + index}
                                      getOptionLabel={(option) => {
                                        if (option === disableProductNumber.toString()) {
                                          return 'None'
                                        } else {
                                          return option + ' bays'
                                        }
                                      }}
                                      renderOption={(props, option) => {
                                        let tempOption: string = ''
                                        if (option === disableProductNumber.toString()) {
                                          tempOption = 'None'
                                        } else {
                                          tempOption = option + ' bays'
                                        }
                                        return (
                                          <Box component="li" {...props}>
                                            {tempOption}
                                          </Box>
                                        )
                                      }}
                                      options={generateBaysForTotalCapacity(offerFacilityData.maxAllocation)}
                                      sx={{ width: 300 }}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                  </Grid>
                                  <Grid item xs={6}></Grid>
                                </Grid>

                                {/* Departments & Teams Section*/}
                                {offerFacilityData.memberAllocationGrouping === 'MemberPool' ? (
                                  <ProductDepartmentMemberPool
                                    offerFacilityData={offerFacilityData}
                                    formattedDepartmentList={formattedDepartmentList}
                                    departmentList={offerFacilityData.memberAllocations}
                                    isProductDisable={isProductDisable}
                                  />
                                ) : (
                                  Object.keys(departmentList).length > 0 &&
                                  Object.entries(departmentList).map(([departmentId, members]: any) => (
                                    <ProductDepartment
                                      key={departmentId}
                                      departmentId={departmentId}
                                      members={members}
                                      offerFacilityData={offerFacilityData}
                                      formattedDepartmentList={formattedDepartmentList}
                                      departmentTeamsList={departmentTeamsList}
                                      isProductDisable={isProductDisable}
                                    />
                                  ))
                                )}
                              </>
                            )
                          )}

                          {/* Cancel & Save Button Section */}
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box display="flex" justifyContent="flex-start">
                                <Button
                                  className={classes.shuffleButton}
                                  variant="outlined"
                                  color="primary"
                                  size="large"
                                  onClick={() => onCancel()}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  className={classes.shuffleButton}
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={() => onSubmit(offer)}
                                >
                                  Save changes
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )
                })
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <UnsavedDataConfirmationModel
        display={displayUnsaved}
        cancelModel={cancelUnsavedModel}
        confirmModel={confirmUnsavedModel}
      />
    </>
  )
}

export default ProductDetail
