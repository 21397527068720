import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import gateIcon from '../../assets/svg/icon-gate.svg'
import backIcon from '../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { AGGREGATE_GATE_LIST_ITEM, AGGREGATE_MY_LIST_TENANCIES } from '../../common/aggregate.types'
import { fetchAggregates } from '../../common/axios-action'
import { getCapitalizeString } from '../../common/utility'
import { selectCurrentOperatorId, selectShuffleUrl } from '../../config/app/reducers'
import { RootState } from '../../store'
import {
  ASSISTED_UNSCHEDULED_ACCESSES_API_RESOURCE_PATH,
  UnscheduledAccessesRequest,
} from '../../store/common/assisted-scheduled-accesses/types'
import { assistedUnscheduledAccesses } from '../../store/common/assisted-scheduled-accesses/unscheduled-accesses'
import { GateListItem, TENANCY_GATE_LIST_API_RESOURCE_PATH } from '../../store/common/gates-list/types'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../store/common/tenancies/types'
import { GateDeliveryStatus } from '../../store/common/update-entry-status/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../auth/types'
import { ROUTE_OPERATIONS, ROUTE_OPERATIONS_CONTROL_CENTRE } from '../util/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '200px',
    padding: '7px 22px',
    boxShadow: 'none',
  },
  shuffleButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '0.875rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '120px',
    paddingBottom: '7px',
  },
  reservedButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ffffff',
    backgroundColor: '#009273',
    fontSize: '0.875rem',
    width: 'auto',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
  },
  reservedButtonSmlOL: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#525252',
    backgroundColor: '#ffffff',
    fontSize: '0.875rem',
    width: 'auto',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '10px',
    marginTop: '8px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
}))

interface IProps {}

const ForceGateOpen: React.FC<IProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)
  const shuffleUrl = useSelector(selectShuffleUrl)
  const [token, setToken] = useState<string>('')

  const [location, setLocation] = useState<string>('')
  const [accessPoint, setAccessPoint] = useState<string>('')
  const [reasonType, setReasonType] = useState<string>('')
  const [note, setNote] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [licencePlate, setLicencePlate] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')

  const [isValidDataSelection, setIsValidDataSelection] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { tenancies, gateList } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
    gateList: state.gateListItemReducer.aggregates.values,
  }))

  // get token
  useEffect(() => {
    if (!token) {
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const token = await firebase.auth().currentUser!.getIdToken()
    setToken(token)
  }

  // Get tenancies
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // Get gate list
  useEffect(() => {
    if (location && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<GateListItem>(
          AGGREGATE_GATE_LIST_ITEM,
          TENANCY_GATE_LIST_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', location),
          null,
        ),
      )
    }
  }, [dispatch, location, currentOperatorId, currentOrganisationId])

  // Check button validation
  useEffect(() => {
    if (location && accessPoint && reasonType && name && mobile) {
      setIsValidDataSelection(true)
    } else {
      setIsValidDataSelection(false)
    }
  }, [location, accessPoint, reasonType, name, mobile])

  const handleChangeLocation = (event: SelectChangeEvent) => {
    setLocation(event.target.value)
    setAccessPoint('')
  }

  const handleChangeAccessPoint = (event: SelectChangeEvent) => {
    setAccessPoint(event.target.value)
  }

  const handleChangeReasonType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonType((event.target as HTMLInputElement).value)
  }

  const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote((event.target as HTMLInputElement).value)
  }

  const resetData = () => {
    setLocation('')
    setAccessPoint('')
    setReasonType('')
    setNote('')
    setName('')
    setMobile('')
    setLicencePlate('')
    setCompanyName('')
  }

  const onClickCancel = () => {
    navigate(ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE)
  }

  const onOpenGate = async () => {
    if (
      location &&
      accessPoint &&
      reasonType &&
      name &&
      mobile &&
      currentOperatorId &&
      currentOrganisationId &&
      shuffleUrl &&
      token
    ) {
      let names = name.trim().split(' ')
      let fName = names[0]
      let lName = names[1]
      let payload: UnscheduledAccessesRequest = {
        facilityId: location,
        accessPointId: accessPoint,
        reasonType: reasonType,
        note: note,
        firstName: fName ? getCapitalizeString(fName) : '',
        lastName: lName ? getCapitalizeString(lName) : '',
        mobileNumber: mobile ? mobile : null,
        vehiclePlate: licencePlate ? licencePlate : null,
        companyName: companyName ? companyName : null,
        category: 'Member',
        email: null,
      }
      let assistedUnscheduledAccessesUrl = ASSISTED_UNSCHEDULED_ACCESSES_API_RESOURCE_PATH.replace(
        ':operatorId',
        currentOperatorId,
      )
        .replace(':carParkTenantId', currentOrganisationId)
        .replace(':carParkTenancyId', location)
      setLoading(true)
      let response = await assistedUnscheduledAccesses(payload, assistedUnscheduledAccessesUrl, shuffleUrl, token)
      setLoading(false)
      if (response.gateDeliveryStatus === GateDeliveryStatus.Success || response.gateDeliveryStatus === null) {
        let accessPointName = gateList.find((gate: GateListItem) => gate.accessPointId === accessPoint)?.accessPointName
        if (accessPointName) {
          dispatch(setNotification(NotificationType.INFO, [accessPointName + ' has been opened.']))
        } else {
          dispatch(setNotification(NotificationType.INFO, ['Gate has been opened.']))
        }
        resetData()
      }
      if (
        response.gateDeliveryStatus === GateDeliveryStatus.Failed ||
        response.gateDeliveryStatus === GateDeliveryStatus.InternalError
      ) {
        dispatch(
          setNotification(NotificationType.ERROR, [
            'There was a problem opening the gate. Please contact Wilson Parking for support.',
          ]),
        )
      }
    }
  }

  return (
    <div className="page-content-wrapper">
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE}>
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Force gate open
                  </a>
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="responsive-content-wrapper">
              <Grid item xs={12}>
                <p>Force a car park gate to open remotely.</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="page-content-body-wrapper guest-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            {/* Select a gate to open */}
            <Grid item xs={12} className="content-panel larger-space">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div className="operations-icon-block">
                    <img className="force-gate-icon-lrg" src={gateIcon} alt="gateIcon" />
                    <h4 className="">Select a gate to open</h4>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  {/* Location */}
                  <div className="row-wrapper">
                    <InputLabel className="select-heading">Location</InputLabel>
                    <Select
                      className="shuffle-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={location}
                      onChange={handleChangeLocation}
                      displayEmpty
                    >
                      <MenuItem value="" className="selectBold display-none">
                        Select Location
                      </MenuItem>
                      {tenancies.map((tenancy: Tenancy) => (
                        <MenuItem
                          key={tenancy.id}
                          value={tenancy.id}
                          disabled={isTenantUser && tenancy.forcedAccess === false}
                        >
                          {tenancy.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {/* Gate */}
                  <div className="row-wrapper">
                    <InputLabel className="select-heading">Select a gate</InputLabel>
                    <Select
                      className="shuffle-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={accessPoint}
                      onChange={handleChangeAccessPoint}
                      displayEmpty
                    >
                      <MenuItem value="" className="selectBold display-none">
                        Select Gate
                      </MenuItem>
                      {gateList
                        .sort((a, b) =>
                          a.accessPointName > b.accessPointName ? 1 : b.accessPointName > a.accessPointName ? -1 : 0,
                        )
                        .map((gate: GateListItem) => (
                          <MenuItem key={gate.id} value={gate.accessPointId}>
                            {gate.accessPointName}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                  {/* Reason */}
                  <div className="row-wrapper">
                    <h5 className="select-heading">Select a reason</h5>
                    <FormControl component="fieldset">
                      <RadioGroup
                        className="modal-checkboxes-gate"
                        value={reasonType}
                        onChange={handleChangeReasonType}
                      >
                        <div className="left-column">
                          <FormControlLabel
                            value="AccessHardwareIssue"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Access Hardware Issue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="NetworkIssue"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Network Issue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="Overstay"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Overstay"
                            labelPlacement="end"
                          />
                        </div>
                        <div className="right-column">
                          <FormControlLabel
                            value="MobileDeviceIssue"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Mobile Device Issue"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="OutOfSync"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Out of Sync"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="Other"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            label="Other"
                            labelPlacement="end"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/* Note */}
                  <div className="row-wrapper-last">
                    <p className="input-label">
                      Add optional notes <span className="optional-text">(optional)</span>
                    </p>
                    <TextField
                      id="outlined-basic"
                      InputProps={{ className: 'shuffleInput' }}
                      InputLabelProps={{ className: 'shuffleLabel' }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={note}
                      onChange={handleChangeNote}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* Who are you opening the gate for? */}
            <Grid item xs={12} className="content-panel tight">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>Who are you opening the gate for?</h3>
                </Grid>
                <Grid item xs={5} id="mobile-col-01">
                  <p className="input-label">Name</p>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ className: 'shuffleInput' }}
                    defaultValue="Sam McMillan"
                    InputLabelProps={{ className: 'shuffleLabel' }}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="desktop-only" id="mobile-col-02"></Grid>
                <Grid item xs={5} id="mobile-col-04">
                  <p className="input-label">
                    Licence plate <span className="optional-text">(optional)</span>
                  </p>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ className: 'shuffleInput' }}
                    defaultValue=""
                    InputLabelProps={{ className: 'shuffleLabel' }}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 8 }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={licencePlate}
                    onChange={(event) => {
                      setLicencePlate(event.target.value.replace(/[^a-zA-Z0-9]/g, ''))
                    }}
                  />
                </Grid>
                <Grid item xs={5} id="mobile-col-03">
                  <p className="input-label">Mobile phone number</p>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ className: 'shuffleInput' }}
                    defaultValue="0412345678"
                    InputLabelProps={{ className: 'shuffleLabel' }}
                    inputProps={{ maxLength: 10 }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={mobile}
                    onChange={(event) => {
                      setMobile(event.target.value.replace(/[^0-9]/g, ''))
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="desktop-only" id="mobile-col-05"></Grid>
                <Grid item xs={5} id="mobile-col-06">
                  <p className="input-label">
                    Company name <span className="optional-text">(optional)</span>
                  </p>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ className: 'shuffleInput' }}
                    defaultValue=""
                    InputLabelProps={{ className: 'shuffleLabel' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={companyName}
                    onChange={(event) => {
                      setCompanyName(event.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Buttons */}
            <Grid item xs={12} style={{ paddingTop: '20px', paddingBottom: '40px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} className="desktop-only">
                  <Box display="flex" justifyContent="flex-start">
                    <Button
                      className={classes.shuffleButton}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={onClickCancel}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6} className="mobile-fullwidth-button">
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      className={classes.shuffleButton}
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={onOpenGate}
                      disabled={!isValidDataSelection}
                    >
                      {loading ? <CircularProgress style={{ color: '#FFFFFF', width: 29, height: 29 }} /> : 'Open gate'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ForceGateOpen
