import {
  AGGREGATE_AGREEMENTS_DETAIL,
  AGGREGATE_ORDER_PARKING_AGREEMENTS,
  CUSTOM_AGGREGATE_FAILURE,
  CUSTOM_AGGREGATE_REQUEST,
  CUSTOM_AGGREGATE_SUCCESS,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  isAggregateActionType,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
} from '../../../common/aggregate.types'
import {
  AgreementsDetailState,
  AgreementsDetailTypes,
  OrderParkingAgreementsState,
  OrderParkingAgreementsTypes,
} from './types'

const initialState: OrderParkingAgreementsState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function orderParkingAgreementsStateReducer(
  state = initialState,
  action: OrderParkingAgreementsTypes,
): OrderParkingAgreementsState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_ORDER_PARKING_AGREEMENTS) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
        updated: false,
        error: null,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
        error: null,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}

const agreementsDetailInitialState: AgreementsDetailState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function agreementsDetailReducer(
  state = agreementsDetailInitialState,
  action: AgreementsDetailTypes,
): AgreementsDetailState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_AGREEMENTS_DETAIL) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        aggregate: null,
        error: null,
        updatedApplicationStatus: false,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        aggregate: action.payload.aggregate,
        error: null,
        updatedApplicationStatus: true,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        aggregate: null,
        error: action.payload.error,
        updatedApplicationStatus: true,
      }
    case CUSTOM_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        inserted: false,
        error: null,
      }
    case CUSTOM_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: true,
        updated: false,
        error: null,
      }
    case CUSTOM_AGGREGATE_FAILURE:
      return {
        ...state,
        inserting: false,
        inserted: false,
        error: action.payload.error,
      }
    case RESET_AGGREGATE:
      return agreementsDetailInitialState
    default:
      return state
  }
}
