import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState, AgreementStatus, OrderStatus } from '../../../common/types'

export interface TransactionSummaryList {
  id: string
  reference: string
  stayFinishDateTime: string
  stayStartDateTime: string
  orderStatus: OrderStatus
  facilityName: string
  productName: string
  facilityId: string
  parkingItemId: string
  agreementStatus: AgreementStatus
  agreementId: string
}

export type TransactionSummaryState = AggregateState<TransactionSummaryList>
export type TransactionSummaryActionTypes = AggregateActionTypes<TransactionSummaryList>

export const TRANSACTION_SUMMARY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:memberId/orders/:orderId/amends-amended-orders'
