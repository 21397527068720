import {
  AGGREGATE_GUEST,
  CUSTOM_AGGREGATE_FAILURE,
  CUSTOM_AGGREGATE_REQUEST,
  CUSTOM_AGGREGATE_SUCCESS,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  INSERT_AGGREGATE_FAILURE,
  INSERT_AGGREGATE_REQUEST,
  INSERT_AGGREGATE_SUCCESS,
  isAggregateActionType,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
  UPDATE_AGGREGATE_FAILURE,
  UPDATE_AGGREGATE_REQUEST,
  UPDATE_AGGREGATE_SUCCESS,
} from '../../common/aggregate.types'
import {
  GuestEntitlementActionTypes,
  GuestEntitlementsListItemActionTypes,
  GuestEntitlementsListItemState,
  GuestEntitlementState,
} from './types'

/**************************** Book Entitlements List ****************************/

const initialState: GuestEntitlementState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function guestEntitlementsReducer(
  state = initialState,
  action: GuestEntitlementActionTypes,
): GuestEntitlementState {
  // Guard against aggregate actions for another type.  Non aggregate actions and aggregate actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_GUEST) {
    return state
  }

  switch (action.type) {
    case UPDATE_AGGREGATE_REQUEST:
    case INSERT_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        inserted: false,
        updated: false,
        error: null,
        idOnly: {
          id: null,
        },
      }
    case UPDATE_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: false,
        updated: true,
        error: null,
      }
    case INSERT_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: true,
        updated: false,
        error: null,
        idOnly: action.payload.idOnly,
      }
    case UPDATE_AGGREGATE_FAILURE:
    case INSERT_AGGREGATE_FAILURE:
      let errorMessage: string = ''
      let errorResp: any = action.payload.error
      if (typeof errorResp === 'object') {
        errorMessage = errorResp[0].message
      }
      if (typeof errorResp === 'string') {
        errorMessage = errorResp
      }
      return {
        ...state,
        inserting: false,
        inserted: false,
        updated: false,
        error: errorMessage,
        idOnly: {
          id: null,
        },
      }
    case CUSTOM_AGGREGATE_REQUEST:
      return {
        ...state,
        deleting: true,
        deleted: false,
        error: null,
      }
    case CUSTOM_AGGREGATE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        error: null,
      }
    case CUSTOM_AGGREGATE_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.payload.error,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}

/**************************** Guest Entitlements List ****************************/

const initialStateForGuestEntitlementsListItem: GuestEntitlementsListItemState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function guestEntitlementsListReducer(
  state = initialStateForGuestEntitlementsListItem,
  action: GuestEntitlementsListItemActionTypes,
): GuestEntitlementsListItemState {
  // Guard against aggregate actions for another type.  Non aggregate actions and aggregate actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_GUEST) {
    return state
  }

  switch (action.type) {
    case LIST_AGGREGATES_REQUEST:
      return {
        ...state,
        loadingList: true,
        error: null,
      }
    case LIST_AGGREGATES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        aggregates: action.payload.aggregates,
        error: null,
      }
    case LIST_AGGREGATES_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.payload.error,
      }
    case LIST_AGGREGATES_NOT_MODIFIED:
      return {
        ...state,
        loadingList: false,
        error: null,
      }
    default:
      return state
  }
}

/**************************** Get Guest Entitlements ****************************/

export function guestEntitlementsItemReducer(
  state = initialState,
  action: GuestEntitlementActionTypes,
): GuestEntitlementState {
  // Guard against aggregate actions for another type.  Non aggregate actions and aggregate actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_GUEST) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        error: null,
        aggregate: null,
        loadingOne: true,
        updatedApplicationStatus: false,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        error: null,
        aggregate: action.payload.aggregate,
        loadingOne: false,
        updatedApplicationStatus: true,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        aggregate: null,
        loadingOne: false,
        updatedApplicationStatus: true,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}
