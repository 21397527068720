import { useEffect, useState } from 'react'
import { AxiosRequestConfig } from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { authorizationHeaderConfig } from '../config/axios/axios'
import { chooseUrl } from '../common/axios-action'

export const useAxiosConfig = () => {
  let shuffleApiConfig = useSelector((state: RootState) => state.appConfigReducer.shuffleApiConfig)
  let user = useSelector((state: RootState) => state.authReducer.user)
  let [axiosConfig, setAxiosConfig] = useState<AxiosRequestConfig>()

  useEffect(() => {
    try {
      if (user && shuffleApiConfig) {
        let authorization = user!.getIdToken()
        authorization
          .then((token: any) => {
            let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
            setAxiosConfig(config)
          })
          .catch((error: any) => {
            console.debug(error)
          })
      }
    } catch (err) {
      console.debug(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, shuffleApiConfig])

  return [axiosConfig] as const
}
