import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_TENANT_OFFERS,
  DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST,
  DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST,
  DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST,
} from '../../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { IdClientRefName, memberPoolsIdNames } from '../../../common/types'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamList,
  DepartmentTeamListItemDto,
  IdNameDto,
} from '../../../store/common/departments-teams/types'
import {
  TENANCY_API_RESOURCE_PATH,
  TENANT_CARPARK_OFFERS_API_RESOURCE_PATH,
  Tenancy,
  TenantOffer,
} from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import DailyUtilisation from './daily-utilisation'
import MonthlySummary from './monthly-summary'
import {
  DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_API_RESOURCE_PATH,
  DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_API_RESOURCE_PATH,
  DAILY_MEMBER_FINANCIAL_SUMMARY_API_RESOURCE_PATH,
  DailyCountAllocationByAuthorityByOffer,
  DailyFacilityAgreementPeakAllocationByAuthority,
  DailyMemberFinancialSummary,
  FilterCriteria,
  MonthList,
} from './types'

interface IProps {}

const Dashboard: React.FC<IProps> = () => {
  const dispatch = useDispatch()
  const [formattedDepartmentsAndTeamList, setFormattedDepartmentsAndTeamList] = useState<DepartmentTeamList[]>([])
  const [departmentAndTeamDisplayValue, setDepartmentAndTeamDisplayValue] = useState<string>('Any Department/Team')
  const [monthList, setMonthList] = useState<MonthList[]>([])
  const [dailyFacilityAgreementPeakChartDetail, setDailyFacilityAgreementPeakChartDetail] =
    useState<DailyFacilityAgreementPeakAllocationByAuthority | null>(null)
  const [dailyCountAllocationChartDetail, setDailyCountAllocationChartDetail] =
    useState<DailyCountAllocationByAuthorityByOffer | null>(null)
  const [dailyMemberFinancialSummaryData, setDailyMemberFinancialSummaryData] =
    useState<DailyMemberFinancialSummary | null>(null)
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    departmentId: '',
    teamId: '',
    location: '',
    role: '',
    month: '',
  })
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const {
    tenancies,
    departmentAndTeamList,
    tenantOffersList,
    dailyFacilityAgreementPeak,
    dailyFacilityAgreementPeakError,
    dailyCountAllocation,
    dailyCountAllocationError,
    dailyMemberFinancialSummary,
    dailyMemberFinancialSummaryError,
  } = useSelector((state: RootState) => ({
    tenancies: state.myListTenancyReducer.aggregates.values,
    departmentAndTeamList: state.departmentsTeamsReducer.aggregates.values,
    tenantOffersList: state.tenantOffersReducer.aggregates.values,
    dailyFacilityAgreementPeak: state.dailyFacilityAgreementPeakAllocationByAuthorityReducer.aggregate,
    dailyFacilityAgreementPeakError: state.dailyFacilityAgreementPeakAllocationByAuthorityReducer.error,
    dailyCountAllocation: state.dailyCountAllocationByAuthorityByOfferReducer.aggregate,
    dailyCountAllocationError: state.dailyCountAllocationByAuthorityByOfferReducer.error,
    dailyMemberFinancialSummary: state.dailyMemberFinancialSummaryReducer.aggregate,
    dailyMemberFinancialSummaryError: state.dailyMemberFinancialSummaryReducer.error,
  }))

  // To fetch tenancies, team and department
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      getTenancies()
      getDepartmentAndTeam()
      getTenantOffers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId])

  // To format team and department
  useEffect(() => {
    let tempDepartmentAndTeamList: DepartmentTeamList[] = []
    if (departmentAndTeamList) {
      departmentAndTeamList.map((row: DepartmentTeamListItemDto) => {
        tempDepartmentAndTeamList.push({
          id: row.id,
          name: row.name,
          isDepartment: true,
          departmentId: null,
        })
        row.teams.map((team: IdNameDto) => {
          tempDepartmentAndTeamList.push({
            id: team.id,
            name: team.name,
            isDepartment: false,
            departmentId: row.id,
          })
          return ''
        })
        return ''
      })
    }
    setFormattedDepartmentsAndTeamList(tempDepartmentAndTeamList)
  }, [departmentAndTeamList])

  // on success daily facility agreement peak
  useEffect(() => {
    if (dailyFacilityAgreementPeak) {
      setDailyFacilityAgreementPeakChartDetail(dailyFacilityAgreementPeak)
    }
    if (dailyFacilityAgreementPeakError) {
      setDailyFacilityAgreementPeakChartDetail(null)
    }
  }, [dailyFacilityAgreementPeak, dailyFacilityAgreementPeakError])

  // on success daily count allocation
  useEffect(() => {
    if (dailyCountAllocation) {
      setDailyCountAllocationChartDetail(dailyCountAllocation)
    }
    if (dailyCountAllocationError) {
      setDailyCountAllocationChartDetail(null)
    }
  }, [dailyCountAllocation, dailyCountAllocationError])

  // on success daily member financial summary
  useEffect(() => {
    if (dailyMemberFinancialSummary) {
      setDailyMemberFinancialSummaryData(dailyMemberFinancialSummary)
    }
    if (dailyMemberFinancialSummaryError) {
      setDailyMemberFinancialSummaryData(null)
    }
  }, [dailyMemberFinancialSummary, dailyMemberFinancialSummaryError])

  // To format month list
  useEffect(() => {
    let tempMonthList: MonthList[] = []
    let today = new Date()
    today.setDate(1)
    today.setMonth(today.getMonth() - 1)
    for (let i = 0; i < 13; i++) {
      let firstDay = format(today, 'yyyy-MM-dd')
      let lastDay = format(new Date(today.getFullYear(), today.getMonth() + 1, 0), 'yyyy-MM-dd')
      let month: string = firstDay + '/' + lastDay
      let fullMonth: string = format(today, 'MMMM')
      let year = format(today, 'yyyy')
      tempMonthList.push({ month, fullMonth, year })
      today.setMonth(today.getMonth() - 1)
    }
    setMonthList(tempMonthList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // To set facility(location) from local storage
  useEffect(() => {
    if (tenancies && tenancies.length > 0) {
      let facilityId = localStorage.getItem('facilityId')
      if (facilityId) {
        setFilterCriteria({ ...filterCriteria, location: facilityId })
      } else {
        let facility = tenancies[0]
        if (facility) {
          localStorage.setItem('facilityId', facility.id)
          setFilterCriteria({ ...filterCriteria, location: facility.id })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenancies])

  useEffect(() => {
    if (filterCriteria.location) {
      getDailyFacilityAgreementPeakAllocationByAuthority()
      getDailyCountAllocationByAuthorityByOffer()
      getDailyMemberFinancialSummary()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCriteria])

  // Fetch report data
  const getDailyFacilityAgreementPeakAllocationByAuthority = () => {
    if (currentOperatorId && currentOrganisationId) {
      let queryString: string = getQueryString()
      dispatch(
        fetchAggregate<DailyFacilityAgreementPeakAllocationByAuthority>(
          DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST,
          DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_API_RESOURCE_PATH.replace(
            ':operatorId',
            currentOperatorId,
          ).replace(':carParkTenantId', currentOrganisationId) + queryString,
          null,
        ),
      )
    }
  }
  const getDailyCountAllocationByAuthorityByOffer = () => {
    if (currentOperatorId && currentOrganisationId) {
      let queryString: string = getQueryString()
      dispatch(
        fetchAggregate<DailyCountAllocationByAuthorityByOffer>(
          DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST,
          DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_API_RESOURCE_PATH.replace(
            ':operatorId',
            currentOperatorId,
          ).replace(':carParkTenantId', currentOrganisationId) + queryString,
          null,
        ),
      )
    }
  }
  const getDailyMemberFinancialSummary = () => {
    if (currentOperatorId && currentOrganisationId) {
      let queryString: string = getQueryString()
      dispatch(
        fetchAggregate<DailyMemberFinancialSummary>(
          DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST,
          DAILY_MEMBER_FINANCIAL_SUMMARY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + queryString,
          null,
        ),
      )
    }
  }

  const getTenancies = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }
  const getDepartmentAndTeam = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }
  const getTenantOffers = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<TenantOffer>(
          AGGREGATE_TENANT_OFFERS,
          TENANT_CARPARK_OFFERS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }

  // Handlers
  const handleChangeFilter = (event: SelectChangeEvent) => {
    let { name, value } = event.target
    setFilterCriteria({ ...filterCriteria, [name]: value })
    if (name === 'location') {
      localStorage.setItem('facilityId', value)
    }
  }
  const handleChangeDepartmentAndTeam = (event: SelectChangeEvent) => {
    let { value } = event.target
    let selected: DepartmentTeamList | undefined = formattedDepartmentsAndTeamList.find((row) => row.id === value)
    let displayValue: string = ''
    if (selected) {
      setFilterCriteria({
        ...filterCriteria,
        teamId: selected && !selected.isDepartment ? selected.id : '',
        departmentId: selected && selected.isDepartment ? selected.id : '',
      })
      if (!selected.isDepartment) {
        let department: DepartmentTeamList | undefined = formattedDepartmentsAndTeamList.find(
          (row) => row.id === selected?.departmentId,
        )
        if (department) {
          displayValue = department.name + ' -> ' + selected.name
        } else {
          displayValue = selected.name
        }
      } else {
        displayValue = selected.name
      }
    } else {
      setFilterCriteria({ ...filterCriteria, teamId: '', departmentId: '' })
    }
    setDepartmentAndTeamDisplayValue(displayValue)
  }
  const getQueryString = (): string => {
    let queryString: string = ''
    if (filterCriteria.location) {
      queryString = queryString + `?facilityId=${filterCriteria.location}`
    }
    if (filterCriteria.month) {
      let days: string[] = filterCriteria.month.split('/')
      queryString = queryString + `&fromDate=${days[0]}&toDate=${days[1]}`
    }
    if (filterCriteria.teamId) {
      queryString = queryString + `&teamId=${filterCriteria.teamId}`
    }
    if (filterCriteria.departmentId) {
      queryString = queryString + `&departmentId=${filterCriteria.departmentId}`
    }
    if (filterCriteria.role) {
      queryString = queryString + `&memberPool=${filterCriteria.role}`
    }
    return queryString
  }

  return (
    <div className="page-content-wrapper utilisation-wrapper">
      <Container className="page-content-header-wrapper utilisation-section">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="full-width top-section">
            <h1 className="utilisation-h1">Utilisation</h1>
            <Grid container spacing={2} className="filter-section">
              <Grid item xs={3}>
                <InputLabel className="select-heading white-text">Location</InputLabel>
                <Select
                  className="shuffle-select"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  value={filterCriteria.location}
                  onChange={handleChangeFilter}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  name="location"
                  displayEmpty
                >
                  {tenancies.map((tenancy: Tenancy) => (
                    <MenuItem key={tenancy.id} value={tenancy.id}>
                      {tenancy.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel className="select-heading white-text">Month</InputLabel>
                <Select
                  className="shuffle-select"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  value={filterCriteria.month}
                  onChange={handleChangeFilter}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  name="month"
                  displayEmpty
                >
                  <MenuItem value="" className="selectBold">
                    Past 30 Days
                  </MenuItem>
                  {monthList.map((month: MonthList, index: number) => (
                    <MenuItem key={index + month.month} value={month.month}>
                      {month.fullMonth} {month.year}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel className="select-heading white-text">Teams</InputLabel>
                <Select
                  className="shuffle-select"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  defaultValue=""
                  onChange={handleChangeDepartmentAndTeam}
                  disabled={filterCriteria.role !== ''}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  renderValue={() =>
                    departmentAndTeamDisplayValue ? departmentAndTeamDisplayValue : 'Any Department/Team'
                  }
                  displayEmpty
                >
                  <MenuItem value="" className="selectBold">
                    Any department/team
                  </MenuItem>
                  {formattedDepartmentsAndTeamList.map((row: DepartmentTeamList) => (
                    <MenuItem key={row.id} value={row.id} className={row.isDepartment ? 'selectTitle' : 'selectBullet'}>
                      {!row.isDepartment ? '• ' : ''}
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel className="select-heading white-text">Roles</InputLabel>
                <Select
                  className="shuffle-select"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  value={filterCriteria.role}
                  onChange={handleChangeFilter}
                  disabled={filterCriteria.teamId !== '' || filterCriteria.departmentId !== ''}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  name="role"
                  displayEmpty
                >
                  <MenuItem value="" className="selectBold">
                    Any Role
                  </MenuItem>
                  {memberPoolsIdNames.map((member: IdClientRefName) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="page-content-body-wrapper utilisation-section">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="full-width bottom-section">
            {/* utilisation summary section */}
            <DailyUtilisation
              dailyFacilityAgreementPeakChartDetail={dailyFacilityAgreementPeakChartDetail}
              dailyMemberFinancialSummaryData={dailyMemberFinancialSummaryData}
            />
            {/* utilisation on demand parking section */}
            <MonthlySummary
              dailyCountAllocationChartDetail={dailyCountAllocationChartDetail}
              tenantOffersList={tenantOffersList}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Dashboard
