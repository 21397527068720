import { Box, Button, CircularProgress, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_AGREEMENTS_DETAIL,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER,
} from '../../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { AgreementStatus, OrderStatus } from '../../../common/types'
import { formatNumberWithDecimalPlace, getAgreementStatusFormattedName } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { MemberForm, TENANT_MEMBER_API_RESOURCE_PATH } from '../../settings/users/active-users/types'
import { timeStampToDate } from '../../util/util'
import {
  AGREEMENTS_DETAIL_API_RESOURCE_PATH,
  AgreementsDetailState,
  getBookingCost,
  getMemberNameFromUserRef,
  getRefundLabel,
} from './types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0px',
    maxWidth: '250px  ',
  },
}))

interface IProps {}
const RefundConfirmationDetail: React.FC<IProps> = (props) => {
  const { state } = useLocation()
  const classes = useStyles()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [oldBookingOrderId, setOldBookingOrderId] = useState<string>('')
  const [oldOrderReference, setOldOrderReference] = useState<string>('')
  const [oldBookingCost, setOldBookingCost] = useState<number>(0)

  const [isAllRequestCompleted, setIsAllRequestCompleted] = useState<boolean>(false)

  const {
    agreementDetail,
    memberData,
    departmentTeamsList,
    isAgreementsDetailRequestCompleted,
    isManageUserRequestCompleted,
    isDepartmentTeamRequestCompleted,
  } = useSelector((state: RootState) => ({
    agreementDetail: state.agreementsDetailReducer.aggregate,
    isAgreementsDetailRequestCompleted: state.agreementsDetailReducer.updatedApplicationStatus,
    memberData: state.manageUserReducer.aggregate,
    isManageUserRequestCompleted: state.manageUserReducer.updatedApplicationStatus,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    isDepartmentTeamRequestCompleted: state.departmentsTeamsReducer.updatedApplicationStatus,
  }))

  // Get agreement details or parking items details
  useEffect(() => {
    if (state) {
      if (currentOperatorId && currentOrganisationId) {
        const { orderId, oldBookingOrderId, orderReference, bookingCost }: any = state
        setOldBookingOrderId(oldBookingOrderId)
        setOldOrderReference(orderReference)
        setOldBookingCost(bookingCost)
        dispatch(
          fetchAggregate<AgreementsDetailState>(
            AGGREGATE_AGREEMENTS_DETAIL,
            AGREEMENTS_DETAIL_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + `/${orderId}`,
            null,
          ),
        )
      }
    } else {
      navigate('/on-demand/transactions')
    }
    // eslint-disable-next-line
  }, [dispatch, currentOperatorId, currentOrganisationId, navigate, state])

  // Get members
  useEffect(() => {
    if (agreementDetail && currentOperatorId && currentOrganisationId) {
      const memberId = agreementDetail.memberId
      if (memberId) {
        dispatch(
          fetchAggregate<MemberForm>(
            AGGREGATE_MEMBER,
            TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            memberId,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, agreementDetail])

  // Get departments and teams
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // Manage loading
  useEffect(() => {
    if (isAgreementsDetailRequestCompleted && isManageUserRequestCompleted && isDepartmentTeamRequestCompleted) {
      setIsAllRequestCompleted(true)
    } else {
      setIsAllRequestCompleted(false)
    }
  }, [isAgreementsDetailRequestCompleted, isManageUserRequestCompleted, isDepartmentTeamRequestCompleted])

  // On unmount
  useEffect(() => {
    return () => {
      resetData()
    }
    // eslint-disable-next-line
  }, [])

  // User section
  const getDepartmentNameAndTeamName = (departmentId: string, teamId: string | null): string => {
    const department: DepartmentTeamListItemDto | undefined = departmentTeamsList.find(
      (dept: DepartmentTeamListItemDto) => dept.id === departmentId,
    )
    let name: string
    if (department) {
      if (teamId === null) {
        name = department.name
      } else {
        const team: any = department.teams.find((team) => team.id === teamId)
        if (team) {
          name = team.name
        } else {
          name = ''
        }
      }
      return name
    }
    return ''
  }

  // Parking section
  const getAgreementStayDuration = () => {
    if (agreementDetail) {
      let agreement = agreementDetail.stayAgreements[0]
      let startDateTime = format(Date.parse(agreement.singleStay.startDateTime), 'E dd MMMM hh:mmaaa')
      let finishDateTime = format(Date.parse(agreement.singleStay.finishDateTime), 'E dd MMMM hh:mmaaa')
      return startDateTime + ' - ' + finishDateTime
    } else {
      return ''
    }
  }

  const onBack = () => {
    resetData()
    if (agreementDetail) {
      navigate('/on-demand/transactions/transaction-detail', {
        state: {
          orderId: agreementDetail.orderId,
        },
      })
    }
  }

  const onNoRefundBooking = () => {
    dispatch(setNotification(NotificationType.INFO, ['The booking has not been refunded']))
    onBack()
  }

  const onRefundBooking = () => {
    resetData()
    if (agreementDetail) {
      let payload: any = null
      if (oldBookingOrderId) {
        payload = {
          orderId: oldBookingOrderId,
          newBookingOrderId: agreementDetail.orderId,
        }
      } else {
        payload = {
          orderId: agreementDetail.orderId,
        }
      }
      navigate('/on-demand/transactions/refund-transaction-detail', {
        state: payload,
      })
    }
  }

  const resetData = () => {
    dispatch(resetAggregate<AgreementsDetailState>(AGGREGATE_AGREEMENTS_DETAIL))
    dispatch(resetAggregate<MemberForm>(AGGREGATE_MEMBER))
  }

  return (
    <div className="page-content-wrapper ondemand-section">
      {/* Left side section */}
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <span className="back-icon" onClick={onBack}>
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    On-demand parking
                  </span>
                </h2>
              </Grid>
            </Grid>
            {agreementDetail && (
              <Grid container spacing={0} className="responsive-content-wrapper">
                <Grid item xs={12}>
                  <p style={{ marginBottom: '10px' }}>
                    Transaction ID: {agreementDetail.orderReference}
                    <br />
                    Booking status: {getAgreementStatusFormattedName(agreementDetail.stayAgreements[0].agreementStatus)}
                  </p>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      {/* Right side section */}
      <Container className="page-content-body-wrapper ondemand-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            {isAllRequestCompleted && agreementDetail ? (
              <>
                <Grid item xs={12} className="content-panel" style={{ minHeight: 410 }}>
                  {/* About this transaction */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-10 padding-top-5">About this transaction</h3>
                      {agreementDetail.orderStatus === OrderStatus.Cancelled &&
                        agreementDetail.stayAgreements[0].agreementStatus === AgreementStatus.Cancelled &&
                        agreementDetail?.orderCancelledInstant && (
                          <p className="cancelled-alert-text">
                            Cancelled by {getMemberNameFromUserRef(agreementDetail?.cancelledBy)} on{' '}
                            {format(timeStampToDate(agreementDetail?.orderCancelledInstant), 'dd MMM yy')}
                          </p>
                        )}
                    </Grid>
                  </Grid>
                  {/* User Section */}
                  <Grid container spacing={2} className="transaction-detail first-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>User</h5>
                        </Grid>
                        <Grid item xs={5}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{agreementDetail.memberName}</td>
                              </tr>
                              {memberData && (
                                <>
                                  <tr>
                                    <td>{memberData.email}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {memberData.departmentId
                                        ? getDepartmentNameAndTeamName(memberData.departmentId, null)
                                        : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {memberData.departmentId
                                        ? getDepartmentNameAndTeamName(memberData.departmentId, memberData.teamId)
                                        : ''}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        {memberData && (
                          <Grid item xs={3}>
                            <p
                              className="transaction-link"
                              onClick={() =>
                                navigate('/users/manage-users', {
                                  state: { memberData: memberData },
                                })
                              }
                            >
                              Manage user account
                            </p>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Parking Section */}
                  <Grid container spacing={2} className="transaction-detail">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Parking</h5>
                        </Grid>
                        <Grid item xs={8}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{agreementDetail.stayAgreements[0].facilityName}</td>
                              </tr>
                              <tr>
                                <td>{agreementDetail?.offer?.name}</td>
                              </tr>
                              <tr>
                                <td>{getAgreementStayDuration()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Cost Section */}
                  <Grid container spacing={2} className={`transaction-detail ${oldBookingOrderId ? '' : 'last-item'}`}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Cost</h5>
                        </Grid>
                        <Grid item xs={8}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>${formatNumberWithDecimalPlace(getBookingCost(agreementDetail), 2)}</td>
                              </tr>
                              <tr>{getRefundLabel(agreementDetail)}</tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Amended Booking */}
                  {oldBookingOrderId && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <h3 className="padding-bottom-10 padding-top-20">Amended booking</h3>
                        </Grid>
                      </Grid>

                      {/* User Section */}
                      <Grid container spacing={2} className="transaction-detail first-item" style={{ border: 'none' }}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <h5>Transaction ID</h5>
                            </Grid>
                            <Grid item xs={8}>
                              <table id="transaction-detail-table">
                                <tbody>
                                  <tr>
                                    <td>{oldOrderReference}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <h5>Cost</h5>
                            </Grid>
                            <Grid item xs={8}>
                              <table id="transaction-detail-table">
                                <tbody>
                                  <tr>
                                    <td>${formatNumberWithDecimalPlace(oldBookingCost, 2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                {/* Button Section */}
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-start">
                        <Button
                          className={classes.shuffleButton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={onNoRefundBooking}
                        >
                          {oldBookingOrderId ? "Don't Refund Old Booking" : "Don't Refund The Booking"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={onRefundBooking}
                        >
                          {oldBookingOrderId ? 'Refund Old Booking' : 'Refund The Booking'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                className="content-panel"
                style={{ minHeight: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <CircularProgress color="primary" size={35} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default RefundConfirmationDetail
