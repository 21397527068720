import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useState } from 'react'
import { DepartmentTeamListItemDto } from '../../../store/common/departments-teams/types'
interface IProps {
  departmentTeamsFormattedList: any[]
  checkIsButtonDisabled: () => void
  isAll?: boolean
  isAllDisabled?: boolean
}

const SingleDepartmentCheckbox: React.FC<IProps> = ({
  departmentTeamsFormattedList,
  checkIsButtonDisabled,
  isAll,
  isAllDisabled,
}: IProps) => {
  const [allTeams, setAllTeams] = useState<boolean>(isAll ? isAll : false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const handleChangeAllTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      departmentTeamsFormattedList[0].checked = true
      departmentTeamsFormattedList[0].teams.forEach((team: any) => {
        team.checked = true
      })
    } else {
      departmentTeamsFormattedList[0].checked = false
      departmentTeamsFormattedList[0].teams.forEach((team: any) => {
        team.checked = false
      })
    }
    setAllTeams(event.target.checked)
    checkIsButtonDisabled()
  }
  const handleChangeTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    let teamId = event.target.name

    let team = departmentTeamsFormattedList[0].teams.find((team: any) => team.id === teamId)
    team.checked = event.target.checked

    if (!event.target.checked) {
      departmentTeamsFormattedList[0].checked = false
      setAllTeams(false)
    } else {
      let teams = departmentTeamsFormattedList[0].teams.filter((team: any) => team.checked === true)
      if (teams.length === departmentTeamsFormattedList[0].teams.length) {
        departmentTeamsFormattedList[0].checked = true
        setAllTeams(true)
      }
    }
    setRefresh(!refresh)
    checkIsButtonDisabled()
  }
  return (
    <>
      <FormControlLabel
        control={<Checkbox disabled={isAllDisabled} checked={allTeams} onChange={handleChangeAllTeam} />}
        label="All teams"
      />
      {refresh}
      {departmentTeamsFormattedList.map((department: DepartmentTeamListItemDto) => (
        <FormGroup key={department.id}>
          {department.teams.map((team: any) => (
            <FormControlLabel
              key={team.id}
              control={
                <Checkbox disabled={isAllDisabled} name={team.id} checked={team.checked} onChange={handleChangeTeam} />
              }
              label={team.name}
            />
          ))}
        </FormGroup>
      ))}
    </>
  )
}

export default SingleDepartmentCheckbox
