import { AggregateActionTypes } from '../../../../common/aggregate.types'
import { AggregateState } from '../../../../common/types'
export interface Territory {
  id: string
  selfLink: string
  kind: string
  name: string
  version: number
}

export interface Facility {
  id: string
  name: string
  checked: boolean
  disabled: boolean
}
export interface TerritoryAndFacility {
  id: string
  name: string
  checked: boolean
  disabled: boolean
  facilities: Facility[]
}

export type TerritoryState = AggregateState<Territory>
export type TerritoryActionTypes = AggregateActionTypes<Territory>

export const TERRITORIES_API_RESOURCE_PATH = '/geo-feature-api/jurisdictions/aus/territories/list'
export const TENANCIES_BY_TERRITORY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/search'
