import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useNavigate } from 'react-router'
import toggleIcon from '../../assets/svg/ws-b2b-icon-toggle.svg'
import { ROUTE_SETTINGS_SELF_REGISTRATION } from '../util/routes'

const useStyle = makeStyles((theme) => ({
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    fontWeight: 'bold',
    height: 50,
  },
}))

interface IProps {}

const Settings: React.FC<IProps> = (props) => {
  const classes = useStyle()
  const navigate = useNavigate()

  return (
    <>
      <div id="main" className="section-entry">
        <Container className="page-header">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <h1 className="settings-h1 centered-heading">Settings</h1>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container className="page-content">
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Box className="section-card">
              <div className="icon-box-wrapper">
                <img className="settings-self-reg" src={toggleIcon} alt="Self Registration" />
              </div>
              <h2>Self registration link</h2>
              <p>Allow your staff to register for on-demand parking via a link.</p>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTE_SETTINGS_SELF_REGISTRATION)}
              >
                Manage
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Settings
