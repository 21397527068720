import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Autocomplete,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import searchFieldIcon from '../../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import crossFieldIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST,
  AGGREGATE_MEMBER_LIST,
  AGGREGATE_MY_LIST_TENANCIES,
  AGGREGATE_VIEW_ENTITLEMENT,
  ASSIGN_PARKING_BAY_AGGREGATE_REQUEST,
} from '../../../common/aggregate.types'
import { fetchAggregates } from '../../../common/axios-action'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { useDebounce } from '../../../hook/useDebounce'
import usePagination from '../../../hook/usePagination'
import { RootState } from '../../../store'
import { MEMBER_API_RESOURCE_PATH, Member } from '../../../store/common/member/types'
import { clearNotification, setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { ROUTE_ASSIGNED, ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT } from '../../util/routes'
import { getTableCell } from '../../util/tooltip'
import { AssignParkingBay, ENTITLEMENTS_API_RESOURCE_PATH } from '../assigned-parking/types'
import { BAY_TYPE_ACCESS, BAY_TYPE_RESERVED, BAY_TYPE_UNRESERVED } from '../types'
import { ViewEntitlementInterface } from '../view-entitlement/types'
import { AssignedParker } from './types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  shuffleAlert: {
    backgroundColor: '#009273',
    color: 'white',
    borderRadius: '8px',
    top: '10px',
    position: 'absolute',
    padding: '14px 16px',
  },
}))

const initialMember: Member = {
  id: '',
  firstName: '',
  lastName: '',
  name: '',
  email: '',
}

interface IProps {}

const ManageParkers: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { state }: any = useLocation()
  const dispatch = useDispatch()
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [page, rowsPerPage, sortDirection, sortBy, handleChangePage] = usePagination()
  const [facilityId, setFacilityId] = useState<string>('')
  const [bayType, setBayType] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [memberId, setMemberId] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(username, 600)
  const [member, setMember] = useState<any>(null)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const navigate = useNavigate()

  const {
    inserted,
    assignedParkersList,
    loadingList,
    tenancies,
    parkers,
    parkersLoadingList,
    unassignSuccess,
    updated,
  }: any = useSelector((state: RootState) => ({
    inserted: state.assignParkingBayReducer.inserted,
    assignedParkersList: state.assignedParkerReducer.aggregates,
    loadingList: state.assignedParkerReducer.loadingList,
    tenancies: state.myListTenancyReducer.aggregates.values,
    parkers: state.memberReducer.aggregates.values,
    parkersLoadingList: state.memberReducer.loadingList,
    unassignSuccess: state.viewEntitlementReducer.inserted,
    updated: state.assignParkingBayReducer.updated,
  }))

  useEffect(() => {
    if (state) {
      const message = state.parkingAssignMessage
      if (inserted || updated) {
        dispatch(setNotification(NotificationType.INFO, [message]))
      } else {
        dispatch(clearNotification())
      }
      if (state.bayUnassignSuccess) {
        if (unassignSuccess) {
          dispatch(setNotification(NotificationType.INFO, [state.bayUnassignSuccess]))
          getAssignedParkers()
        } else {
          dispatch(clearNotification())
        }
      }
      dispatch(resetAggregate<AssignParkingBay>(ASSIGN_PARKING_BAY_AGGREGATE_REQUEST))
      dispatch(resetAggregate<ViewEntitlementInterface>(AGGREGATE_VIEW_ENTITLEMENT))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, dispatch, inserted, unassignSuccess])

  useEffect(() => {
    if (page === 1) {
      getAssignedParkers()
    } else {
      handleChangePage(null, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, facilityId, bayType, memberId])

  useEffect(() => {
    getAssignedParkers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    if (debouncedSearchTerm && debouncedSearchTerm.trim().length > 1) {
      getMembersByMemberName()
    } else {
      setMemberId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  const getAssignedParkers = () => {
    if (currentOperatorId && currentOrganisationId) {
      const payload = {
        page: page - 1,
        size: rowsPerPage,
        ...(facilityId && {
          facilityId: facilityId,
        }),
        ...(bayType && {
          bayReservedType: bayType,
        }),
        ...(memberId && {
          memberId: memberId,
        }),
      }
      dispatch(
        fetchAggregates<AssignedParker>(
          AGGREGATE_ASSIGNED_PARKER_AGGREGATE_REQUEST,
          ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          payload,
        ),
      )
    }
  }

  const handleLocationOnchange = (event: SelectChangeEvent) => {
    setFacilityId(event.target.value)
  }

  const handleBayReservedTypeChange = (event: SelectChangeEvent) => {
    setBayType(event.target.value)
  }

  const getMemberId = (member: Member | null) => {
    if (member) {
      setMember(member)
      setMemberId(member?.id)
    }
  }

  const getMembersByMemberName = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Member>(
          AGGREGATE_MEMBER_LIST,
          MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .concat(`?name=${username}`)
            .concat(`&size=100`),
          null,
        ),
      )
    }
  }

  const clearUserName = () => {
    setUsername('')
    setMember(null)
  }

  return (
    <>
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href="/assigned">
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Manage parkers
                  </a>
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="responsive-content-wrapper full-width">
              <Grid item xs={12}>
                <Autocomplete
                  className="autocomplete"
                  clearIcon={null}
                  value={member}
                  onChange={(event: any, newValue: Member | null) => {
                    getMemberId(newValue)
                  }}
                  inputValue={username}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setMember(initialMember)
                    }
                    setUsername(newInputValue)
                  }}
                  options={parkers}
                  loading={parkersLoadingList}
                  loadingText="Loading..."
                  noOptionsText="No results"
                  getOptionLabel={(option: Member) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      placeholder="Search name"
                      InputLabelProps={{ className: 'shuffleLabel' }}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        className: 'shuffleInput',
                        startAdornment: (
                          <InputAdornment position="start" className="autocomplete-search-icon">
                            <img className="search-field-icon" src={searchFieldIcon} alt="search-icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            {username && (
                              <img
                                className="cross-field-icon"
                                src={crossFieldIcon}
                                alt="img"
                                onClick={() => clearUserName()}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="page-content-body-wrapper assigned-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            <Grid item xs={12} className="content-panel">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <h3 className="padding-top-20">
                    {!loadingList && `${assignedParkersList.totalCount ? assignedParkersList.totalCount : 0} parkers`}
                  </h3>
                </Grid>
                <Grid item xs={3} style={{ paddingLeft: 73 }}>
                  <InputLabel className="select-heading">Type</InputLabel>
                  <Select
                    className="shuffle-select"
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    value={bayType}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={handleBayReservedTypeChange}
                    displayEmpty
                  >
                    <MenuItem value="" className="selectBold">
                      Any Type
                    </MenuItem>
                    <MenuItem value={BAY_TYPE_RESERVED} className="selectBold">
                      {BAY_TYPE_RESERVED}
                    </MenuItem>
                    <MenuItem value={BAY_TYPE_UNRESERVED} className="selectBold">
                      {BAY_TYPE_UNRESERVED}
                    </MenuItem>
                    <MenuItem value={BAY_TYPE_ACCESS} className="selectBold">
                      {BAY_TYPE_ACCESS}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={5}>
                  <InputLabel className="select-heading">Location</InputLabel>
                  <Select
                    className="shuffle-select"
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    value={facilityId}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={handleLocationOnchange}
                    displayEmpty
                  >
                    <MenuItem value="" className="selectBold">
                      Any Location
                    </MenuItem>
                    {tenancies.map((tenancy: Tenancy) => (
                      <MenuItem key={tenancy.id} value={tenancy.id}>
                        {tenancy.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.gridTopMargin}>
                  {loadingList ? (
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <CircularProgress color="primary" size={30} />
                      </Grid>
                    </Grid>
                  ) : (
                    <table id="transactions">
                      <thead>
                        <tr>
                          <th>Parker(s)</th>
                          <th>Location</th>
                          <th style={{ width: '10%' }}>Type</th>
                          <th>Bay</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assignedParkersList.values.length > 0 ? (
                          assignedParkersList.values.map((row: AssignedParker) => (
                            <tr key={row.id}>
                              <td className="transaction-id pr-20" style={{ maxWidth: '120px', minWidth: '120px' }}>
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className="color-red content-wrapping-parker"
                                  onClick={() => {
                                    navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT}`, {
                                      state: { entitlement: row },
                                    })
                                  }}
                                >
                                  {getTableCell(row.member, 20)}
                                </p>
                              </td>
                              <td className="pr-20" style={{ maxWidth: '120px', minWidth: '120px' }}>
                                {getTableCell(row.facilityName, 26)}
                              </td>
                              <td style={{ width: '10%' }}>{row.bayReserveType}</td>
                              <td className="" style={{ maxWidth: '350px', minWidth: '350px' }}>
                                {getTableCell(row.bayInfo, 79)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} align="center">
                              No Parkers Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <Pagination
                    count={assignedParkersList.totalCount ? Math.ceil(assignedParkersList.totalCount / rowsPerPage) : 1}
                    page={page}
                    onChange={handleChangePage}
                    className="pagination"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ManageParkers
