import {
  ALLOCATION_BY_TYPE_AGGREGATE_REQUEST,
  ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST,
  DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  isAggregateActionType,
  PEAK_ALLOCATION_AGGREGATE_REQUEST,
  RESET_AGGREGATE,
} from '../../common/aggregate.types'
import {
  AllocationByTypeActionTypes,
  AllocationByTypeState,
  AllocationMemberCountActionTypes,
  AllocationMemberCountState,
  DailyPeakAllocationActionTypes,
  DailyPeakAllocationState,
  PeakAllocationActionTypes,
  PeakAllocationState,
} from './types'

// Allocation Member Count Reducer
const allocationMemberCountInitialState: AllocationMemberCountState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}
export function allocationMemberCountReducer(
  state = allocationMemberCountInitialState,
  action: AllocationMemberCountActionTypes,
): AllocationMemberCountState {
  if (
    isAggregateActionType(action.type) &&
    action.payload.aggregateType !== ALLOCATION_MEMBER_COUNT_AGGREGATE_REQUEST
  ) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
        updatedApplicationStatus: false,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
        updatedApplicationStatus: true,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
        updatedApplicationStatus: true,
      }
    case RESET_AGGREGATE:
      return allocationMemberCountInitialState
    default:
      return state
  }
}

// Allocation By Type Reducer
const allocationByTypeInitialState: AllocationByTypeState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}
export function allocationByTypeReducer(
  state = allocationByTypeInitialState,
  action: AllocationByTypeActionTypes,
): AllocationByTypeState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== ALLOCATION_BY_TYPE_AGGREGATE_REQUEST) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
        updatedApplicationStatus: false,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
        updatedApplicationStatus: true,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
        updatedApplicationStatus: true,
      }
    case RESET_AGGREGATE:
      return allocationByTypeInitialState
    default:
      return state
  }
}

// Daily Peak Allocation Reducer
const dailyPeakAllocationInitialState: DailyPeakAllocationState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}
export function dailyPeakAllocationReducer(
  state = dailyPeakAllocationInitialState,
  action: DailyPeakAllocationActionTypes,
): DailyPeakAllocationState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== DAILY_PEAK_ALLOCATION_AGGREGATE_REQUEST) {
    return state
  }
  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
        updatedApplicationStatus: false,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
        updatedApplicationStatus: true,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
        updatedApplicationStatus: true,
      }
    case RESET_AGGREGATE:
      return dailyPeakAllocationInitialState
    default:
      return state
  }
}

// Peak Allocation Reducer
const peakAllocationInitialState: PeakAllocationState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}
export function peakAllocationReducer(
  state = peakAllocationInitialState,
  action: PeakAllocationActionTypes,
): PeakAllocationState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== PEAK_ALLOCATION_AGGREGATE_REQUEST) {
    return state
  }
  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
        updatedApplicationStatus: false,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
        updatedApplicationStatus: true,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
        updatedApplicationStatus: true,
      }
    case RESET_AGGREGATE:
      return peakAllocationInitialState
    default:
      return state
  }
}
