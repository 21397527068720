import { AggregateState, Lifecycle, OrganisationType } from '../../../../../common/types'
import { AggregateActionTypes } from '../../../../../common/aggregate.types'

export interface MemberEmailDomain {
  id: string | null
  code: string | null
  clientRef: string | null
  name: string | null
  domain: string | null
  organisationType: OrganisationType | null
  operatorId: string | null
  carParkTenantId: string | null
  lifecycle: Lifecycle | null
}

export type MemberEmailDomainState = AggregateState<MemberEmailDomain>
export type MemberEmailDomainActionTypes = AggregateActionTypes<MemberEmailDomain>

export const EMAIL_DOMAIN_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/email-domains'
