import axios from 'axios'
import { AUTHORIZATION_HEADER, BEARER_PREFIX } from '../../../config/constants'
import { GateDeliveryStatus } from '../update-entry-status/types'
import { ScheduledAccessesRequest, ScheduledAccessesResponse } from './types'

export const assistedScheduledAccesses = async (
  scheduledAccessesRequest: ScheduledAccessesRequest,
  url: string,
  coreUrl: string,
  token: string,
): Promise<ScheduledAccessesResponse> => {
  let response: ScheduledAccessesResponse = {
    assistedAccessId: '',
    gateDeliveryStatus: null,
  }
  await axios({
    method: 'POST',
    url: coreUrl + url,
    data: scheduledAccessesRequest,
    headers: { [AUTHORIZATION_HEADER]: BEARER_PREFIX + token },
  })
    .then((resp) => {
      let data = resp.data as ScheduledAccessesResponse
      response = data
    })
    .catch((err) => {
      response = {
        assistedAccessId: '',
        gateDeliveryStatus: GateDeliveryStatus.InternalError,
      }
    })
  return response
}
