import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import { format, isAfter, isValid } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import downloadIcon from '../../../assets/svg/ws-b2b-icon-report_bar_chart-2.svg'
import { AGGREGATE_DEPARTMENTS_TEAMS, AGGREGATE_MY_LIST_TENANCIES } from '../../../common/aggregate.types'
import { chooseUrl, fetchAggregates } from '../../../common/axios-action'
import { IdClientRefName, memberPoolsIdNames } from '../../../common/types'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { authorizationHeaderConfig } from '../../../config/axios/axios'
import { DATE_IS_INVALID, END_DATE_MUST_BE_LATER_THAN_START_DATE, SERVER_FILE_TIMEOUT } from '../../../config/constants'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamList,
  DepartmentTeamListItemDto,
  IdNameDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { Tenancy, TENANCY_API_RESOURCE_PATH } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { CustomCalendarIcon } from '../../util/custom-icon'
import {
  DateErrorState,
  DownloadReportState,
  ENT_ACTIVITY_REPORT_DOWNLOAD_API_RESOURCE_PATH,
  ENT_ASSIGNED_REPORT_DOWNLOAD_API_RESOURCE_PATH,
  ENT_GUEST_DOWNLOAD_API_RESOURCE_PATH,
  ENT_MOVEMENT_REPORT_DOWNLOAD_API_RESOURCE_PATH,
  ENTITLED_PARKING_REPORT,
  GUEST_BOOKINGS_REPORT,
  ON_DEMAND_REPORT_DOWNLOAD_API_RESOURCE_PATH,
  ON_DEMAND_PARKING_REPORT,
  reportFormatList,
  reportList,
  USER_DETAIL_REPORT,
  USER_DETAIL_REPORT_API_RESOURCE_PATH,
  ENTITLED_ACTIVITY_REPORT,
  ENTITLED_MOVEMENTS_REPORT,
  UTILISATION_REPORT,
  UTILISATION_REPORT_API_RESOURCE_PATH,
} from './types'

const useStyles = makeStyles((_) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '200px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    paddingTop: '7px',
    paddingBottom: '7px',
  },
}))

interface IProps {}

const Report: React.FC<IProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [formattedDepartmentsAndTeamList, setFormattedDepartmentsAndTeamList] = useState<DepartmentTeamList[]>([])
  const [departmentAndTeamDisplayValue, setDepartmentAndTeamDisplayValue] = useState<string>('Any Department/Team')
  const [isValidSelection, setIsValidSelection] = useState<boolean>(false)
  const [dateErrorState, setDateErrorState] = useState<DateErrorState>({
    startDate: '',
    endDate: '',
  })
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const [downloadReportState, setDownloadReportState] = useState<DownloadReportState>({
    report: '',
    startDate: null,
    endDate: null,
    location: '',
    departmentId: '',
    teamId: '',
    role: '',
    format: '',
  })
  const { departmentAndTeamList, tenancies, user, shuffleApiConfig } = useSelector((state: RootState) => ({
    departmentAndTeamList: state.departmentsTeamsReducer.aggregates.values,
    tenancies: state.myListTenancyReducer.aggregates.values,
    user: state.authReducer.user,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
  }))

  // To format team and department
  useEffect(() => {
    let tempDepartmentAndTeamList: DepartmentTeamList[] = []
    if (departmentAndTeamList) {
      departmentAndTeamList.map((row: DepartmentTeamListItemDto) => {
        tempDepartmentAndTeamList.push({
          id: row.id,
          name: row.name,
          isDepartment: true,
          departmentId: null,
        })
        row.teams.map((team: IdNameDto) => {
          tempDepartmentAndTeamList.push({
            id: team.id,
            name: team.name,
            isDepartment: false,
            departmentId: row.id,
          })
          return ''
        })
        return ''
      })
    }
    setFormattedDepartmentsAndTeamList(tempDepartmentAndTeamList)
  }, [departmentAndTeamList])

  // Fetch tenancies & departmentAndTeamList
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    let isValidData: boolean = true
    if (downloadReportState.report === '' || downloadReportState.location === '' || downloadReportState.format === '') {
      isValidData = false
    }
    if (downloadReportState.report !== 'user_details') {
      let dateSelection = isValidDateSelection()
      if (!dateSelection) {
        isValidData = false
      }
    }
    setIsValidSelection(isValidData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportState])

  const isValidDateSelection = (): boolean => {
    let isValidDate: boolean
    let startDateErrorMessage: string = ''
    let endDateErrorMessage: string = ''
    // Check Start & End Date
    if (downloadReportState.startDate && downloadReportState.endDate) {
      if (isValid(downloadReportState.startDate) && isValid(downloadReportState.endDate)) {
        let sDate = downloadReportState.startDate.setHours(0, 0, 0, 0)
        let eDate = downloadReportState.endDate.setHours(0, 0, 0, 0)
        if (isAfter(sDate, eDate)) {
          startDateErrorMessage = END_DATE_MUST_BE_LATER_THAN_START_DATE
          endDateErrorMessage = END_DATE_MUST_BE_LATER_THAN_START_DATE
          isValidDate = false
        } else {
          startDateErrorMessage = ''
          endDateErrorMessage = ''
          isValidDate = true
        }
      } else {
        let isStartDateValid = isValid(downloadReportState.startDate)
        if (!isStartDateValid) {
          startDateErrorMessage = DATE_IS_INVALID
        }
        let isEndDateValid = isValid(downloadReportState.endDate)
        if (!isEndDateValid) {
          endDateErrorMessage = DATE_IS_INVALID
        }
        isValidDate = false
      }
    } else {
      if (downloadReportState.startDate) {
        let result = isValid(downloadReportState.startDate)
        if (!result) {
          startDateErrorMessage = DATE_IS_INVALID
        }
      }
      if (downloadReportState.endDate) {
        let result = isValid(downloadReportState.endDate)
        if (!result) {
          endDateErrorMessage = DATE_IS_INVALID
        }
      }
      isValidDate = false
    }
    setDateErrorState({ ...dateErrorState, startDate: startDateErrorMessage, endDate: endDateErrorMessage })
    return isValidDate
  }

  const handleChangeDownloadReportState = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target
    if (name === 'report' && value === 'user_details') {
      setDownloadReportState({ ...downloadReportState, [name]: value, startDate: null, endDate: null })
    } else if (name === 'report' && value === 'guest_bookings') {
      setDepartmentAndTeamDisplayValue('Any Department/Team')
      setDownloadReportState({ ...downloadReportState, [name]: value, departmentId: '', teamId: '', role: '' })
    } else {
      setDownloadReportState({ ...downloadReportState, [name]: value })
    }
  }
  const handleChangeDepartmentAndTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target
    let selected: DepartmentTeamList | undefined = formattedDepartmentsAndTeamList.find((row) => row.id === value)
    let displayValue: string = ''
    if (selected) {
      setDownloadReportState({
        ...downloadReportState,
        teamId: selected && !selected.isDepartment ? selected.id : '',
        departmentId: selected && selected.isDepartment ? selected.id : '',
      })
      if (!selected.isDepartment) {
        let department: DepartmentTeamList | undefined = formattedDepartmentsAndTeamList.find(
          (row) => row.id === selected?.departmentId,
        )
        if (department) {
          displayValue = department.name + ' -> ' + selected.name
        } else {
          displayValue = selected.name
        }
      } else {
        displayValue = selected.name
      }
    } else {
      setDownloadReportState({ ...downloadReportState, teamId: '', departmentId: '' })
    }
    setDepartmentAndTeamDisplayValue(displayValue)
  }
  const handleChangeDate = (value: Date | null, name: string) => {
    setDownloadReportState({ ...downloadReportState, [name]: value })
  }
  const onExport = async () => {
    console.table(downloadReportState)
    let resourcePath = ''
    if (downloadReportState.report === UTILISATION_REPORT) {
      resourcePath = UTILISATION_REPORT_API_RESOURCE_PATH
    } else if (downloadReportState.report === USER_DETAIL_REPORT) {
      resourcePath = USER_DETAIL_REPORT_API_RESOURCE_PATH
    } else if (downloadReportState.report === ENTITLED_PARKING_REPORT) {
      resourcePath = ENT_ASSIGNED_REPORT_DOWNLOAD_API_RESOURCE_PATH
    } else if (downloadReportState.report === GUEST_BOOKINGS_REPORT) {
      resourcePath = ENT_GUEST_DOWNLOAD_API_RESOURCE_PATH
    } else if (downloadReportState.report === ON_DEMAND_PARKING_REPORT) {
      resourcePath = ON_DEMAND_REPORT_DOWNLOAD_API_RESOURCE_PATH
    } else if (downloadReportState.report === ENTITLED_ACTIVITY_REPORT) {
      resourcePath = ENT_ACTIVITY_REPORT_DOWNLOAD_API_RESOURCE_PATH
    } else if (downloadReportState.report === ENTITLED_MOVEMENTS_REPORT) {
      resourcePath = ENT_MOVEMENT_REPORT_DOWNLOAD_API_RESOURCE_PATH
    } else {
      console.error('Unknown report ' + downloadReportState.report)
      dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
      return
    }

    if (currentOperatorId && currentOrganisationId) {
      resourcePath = resourcePath
        .replace(':operatorId', currentOperatorId)
        .replace(':carParkTenantId', currentOrganisationId)
    }

    try {
      let authorization = user!.getIdToken()
      await authorization
        .then((token: any) => {
          let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
          config.timeout = SERVER_FILE_TIMEOUT
          config.params = {
            facilityId: downloadReportState.location !== 'all' ? downloadReportState.location : null,
            departmentId: downloadReportState.departmentId ? downloadReportState.departmentId : null,
            teamId: downloadReportState.teamId ? downloadReportState.teamId : null,
            memberPool: downloadReportState.role ? downloadReportState.role : null,
            format: downloadReportState.format,
            startDate: downloadReportState.startDate ? format(downloadReportState.startDate, 'yyyy-MM-dd') : null,
            endDate: downloadReportState.endDate ? format(downloadReportState.endDate, 'yyyy-MM-dd') : null,
          }
          config.responseType = 'blob'
          axios
            .get<any>(resourcePath, config)
            .then(function (response) {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.setAttribute('href', url)
              link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1])
              link.click()
              link.remove()
            })
            .catch(function (error) {
              console.error(error)
              dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
            })
        })
        .catch((error: any) => {
          console.error(error)
          dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
        })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="page-content-wrapper utilisation-wrapper">
      <Container className="page-content-header-wrapper utilisation-section">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="full-width top-section">
            <h1 className="utilisation-h1">Reports</h1>
          </Grid>
        </Grid>
      </Container>

      <Container className="page-content-body-wrapper utilisation-section utilisation-section-report">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="full-width bottom-section">
            <Grid item xs={12} className="content-panel">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>Download reports</h3>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: '12px' }}>
                <Grid item xs={4}>
                  <p className="download-instructions">
                    Download a detailed report of all parking transactions. Use the filters to customise <br /> your
                    data, then click export.
                  </p>
                  <img className="download-icon" src={downloadIcon} alt="downloadIcon" />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <p className="select-heading">Choose Report</p>
                      <TextField
                        select
                        label={downloadReportState.report === '' ? 'Select Report' : ''}
                        value={downloadReportState.report}
                        onChange={handleChangeDownloadReportState}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="report"
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          },
                          IconComponent: ExpandMoreIcon,
                        }}
                      >
                        {reportList.map((report: IdNameDto) => (
                          <MenuItem key={report.id} value={report.id}>
                            {report.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {downloadReportState.report !== 'user_details' && (
                      <>
                        <Grid item xs={6} style={{ paddingTop: '18px' }}>
                          <p className="select-heading">Start Date</p>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={downloadReportState.startDate}
                              onChange={(newValue) => handleChangeDate(newValue, 'startDate')}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              maxDate={downloadReportState.endDate ? downloadReportState.endDate : null}
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              renderInput={({ ...params }) => (
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  required
                                  error={dateErrorState.startDate.length > 0}
                                  helperText={dateErrorState.startDate}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: '18px' }}>
                          <p className="select-heading">End Date</p>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={downloadReportState.endDate}
                              onChange={(newValue) => handleChangeDate(newValue, 'endDate')}
                              inputFormat="dd MMMM yyyy"
                              disableMaskedInput
                              minDate={downloadReportState.startDate ? downloadReportState.startDate : null}
                              components={{
                                OpenPickerIcon: CustomCalendarIcon,
                              }}
                              renderInput={({ ...params }) => (
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  required
                                  error={dateErrorState.endDate.length > 0}
                                  helperText={dateErrorState.endDate}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} style={{ paddingTop: '18px' }}>
                      <p className="select-heading">Location</p>
                      <TextField
                        select
                        label={downloadReportState.location === '' ? 'Select Location' : ''}
                        value={downloadReportState.location}
                        onChange={handleChangeDownloadReportState}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="location"
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          },
                          IconComponent: ExpandMoreIcon,
                        }}
                      >
                        <MenuItem value="all" className="selectBold">
                          All Locations
                        </MenuItem>
                        {tenancies.map((tenancy: Tenancy) => (
                          <MenuItem key={tenancy.id} value={tenancy.id}>
                            {tenancy.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {downloadReportState.report !== 'guest_bookings' && (
                      <>
                        <Grid item xs={6} style={{ paddingTop: '18px' }}>
                          <p className="select-heading">Department/Team</p>
                          <TextField
                            select
                            onChange={handleChangeDepartmentAndTeam}
                            defaultValue=""
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={downloadReportState.role ? true : false}
                            InputLabelProps={{ shrink: false }}
                            SelectProps={{
                              MenuProps: {
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              },
                              IconComponent: ExpandMoreIcon,
                              displayEmpty: true,
                              renderValue: () =>
                                departmentAndTeamDisplayValue ? departmentAndTeamDisplayValue : 'Any Department/Team',
                            }}
                          >
                            <MenuItem value="" className="selectBold">
                              Any department/team
                            </MenuItem>
                            {formattedDepartmentsAndTeamList.map((row: DepartmentTeamList) => (
                              <MenuItem
                                key={row.id}
                                value={row.id}
                                className={row.isDepartment ? 'selectTitle' : 'selectBullet'}
                              >
                                {!row.isDepartment ? '• ' : ''}
                                {row.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: '18px' }}>
                          <p className="select-heading">Role</p>
                          <TextField
                            select
                            value={downloadReportState.role}
                            disabled={downloadReportState.teamId || downloadReportState.departmentId ? true : false}
                            onChange={handleChangeDownloadReportState}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="role"
                            InputLabelProps={{ shrink: false }}
                            SelectProps={{
                              MenuProps: {
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              },
                              IconComponent: ExpandMoreIcon,
                              displayEmpty: true,
                            }}
                          >
                            <MenuItem value="" className="selectBold">
                              Any Role
                            </MenuItem>
                            {memberPoolsIdNames.map((member: IdClientRefName) => (
                              <MenuItem key={member.id} value={member.id}>
                                {member.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6} style={{ paddingTop: '18px' }}>
                      <p className="select-heading">Format</p>
                      <TextField
                        select
                        label={downloadReportState.format === '' ? 'Select Format' : ''}
                        value={downloadReportState.format}
                        onChange={handleChangeDownloadReportState}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name="format"
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          },
                          IconComponent: ExpandMoreIcon,
                        }}
                      >
                        {reportFormatList.map((report: IdNameDto) => (
                          <MenuItem key={report.id} value={report.id}>
                            {report.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: '18px' }}></Grid>
                    <Grid item xs={6} style={{ paddingTop: '18px' }}></Grid>
                    <Grid item xs={6} style={{ paddingTop: '18px', textAlign: 'right' }}>
                      <Button
                        className={classes.shuffleButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!isValidSelection}
                        onClick={() => onExport()}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Report
