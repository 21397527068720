import { Box, Button, CircularProgress, Container, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import firebase from 'firebase/compat/app'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import longArrow from '../../assets/svg/modal-arrow-long.svg'
import shortArrow from '../../assets/svg/modal-arrow-short.svg'
import vertArrow from '../../assets/svg/modal-arrow-vertical.svg'
import closeIcon from '../../assets/svg/ws-ui-icon-close-x-black.svg'
import { AGGREGATE_ACCESS_INFO } from '../../common/aggregate.types'
import { fetchAggregate } from '../../common/axios-action'
import { EntryStatus } from '../../common/types'
import { selectCurrentOperatorId, selectShuffleUrl } from '../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../pages/auth/types'
import { RootState } from '../../store'
import { ACCESS_INFO_API_RESOURCE_PATH, AccessInfo, PhysicalFacilityType } from '../../store/common/access-info/types'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { updateEntryStatus } from '../../store/common/update-entry-status/check-exists'
import {
  GateDeliveryStatus,
  UPDATE_ENTRY_STATUS_URL,
  UpdateEntryStatusRequest,
} from '../../store/common/update-entry-status/types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    boxShadow: 'none',
  },
  shuffleButtonModal: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#ff0000',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleButtonLeft: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '160px',
  },
  shuffleButtonRight: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '140px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    width: '590px',
    maxWidth: '590px',
    textAlign: 'left',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
}))

interface IProps {
  display: boolean
  handleClose: () => void
  facilityId: string
  currentEntryStatus?: EntryStatus
  entryStatusUpdateUrl: string
  afterUpdateEntryStatus: () => void
}

const ControlCentreEntryStatusModal: React.FC<IProps> = (props) => {
  const { display, handleClose, facilityId, currentEntryStatus, entryStatusUpdateUrl, afterUpdateEntryStatus } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const shuffleUrl = useSelector(selectShuffleUrl)

  const [token, setToken] = useState<string>('')
  const [newEntryStatus, setNewEntryStatus] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const { accessInfo } = useSelector((state: RootState) => ({
    accessInfo: state.accessInfoReducer.aggregate,
  }))

  // get token
  useEffect(() => {
    if (!token) {
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const token = await firebase.auth().currentUser!.getIdToken()
    setToken(token)
  }

  // get access info
  useEffect(() => {
    if (facilityId && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<AccessInfo>(
          AGGREGATE_ACCESS_INFO,
          ACCESS_INFO_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
            .replace(':carParkTenantId', currentOrganisationId)
            .replace(':carParkTenancyId', facilityId),
          null,
        ),
      )
    }
  }, [dispatch, facilityId, currentOperatorId, currentOrganisationId])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEntryStatus((event.target as HTMLInputElement).value)
  }

  const getEntryStatusText = (): string => {
    switch (newEntryStatus) {
      case 'OffSiteStart':
        return 'The user is outside of the car park.'
      case 'TransitIn':
        return 'The user has entered the car park and is heading to the private parking zone.'
      case 'OnSite':
        return 'The user is inside the correct parking area.'
      case 'TransitOut':
        return 'The user has exited the private parking zone but is still inside the car park.'
      case 'OffSiteEnd':
        return 'The user is outside of the car park.'
      case 'Fallback':
        return 'The user is fallback of the car park.'
      default:
        return ''
    }
  }

  const onClickCancel = () => {
    setNewEntryStatus('')
    handleClose()
  }

  const getEntryStatus = () => {
    switch (newEntryStatus) {
      case 'OffSiteStart':
        return EntryStatus.OffSite
      case 'TransitIn':
        return EntryStatus.TransitIn
      case 'OnSite':
        return EntryStatus.OnSite
      case 'TransitOut':
        return EntryStatus.TransitOut
      case 'OffSiteEnd':
        return EntryStatus.OffSite
      case 'Fallback':
        return EntryStatus.Fallback
      default:
        return EntryStatus.OffSite
    }
  }

  const onChangeEntryStatus = async () => {
    if (entryStatusUpdateUrl && shuffleUrl && token) {
      let updateEntryStatusRequest: UpdateEntryStatusRequest = {
        entryStatus: getEntryStatus(),
        accessPointId: null,
      }
      setLoading(true)
      let response = await updateEntryStatus(
        updateEntryStatusRequest,
        entryStatusUpdateUrl + UPDATE_ENTRY_STATUS_URL,
        shuffleUrl,
        token,
      )
      setLoading(false)
      if (response.gateDeliveryStatus === GateDeliveryStatus.Success || response.gateDeliveryStatus === null) {
        onClickCancel()
        afterUpdateEntryStatus()
        dispatch(setNotification(NotificationType.INFO, ['Entry status has been changed.']))
      }
      if (
        response.gateDeliveryStatus === GateDeliveryStatus.Failed ||
        response.gateDeliveryStatus === GateDeliveryStatus.InternalError
      ) {
        onClickCancel()
        dispatch(
          setNotification(NotificationType.ERROR, [
            'There was a problem changing entry status. Please contact Wilson Parking for support.',
          ]),
        )
      }
    }
  }

  return (
    <>
      <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'} style={{ zIndex: 1001 }}>
        <Box className={classes.formModal}>
          <Container maxWidth="lg" disableGutters={true} className="form-modal-styles change-status-modal">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <img className="icon-close-modal mobile-only" src={closeIcon} alt="close" onClick={onClickCancel} />
                <h3 className="modal-h3 larger-text">Change entry status</h3>
                <p>
                  If a user is unable to access the car park,
                  <br />
                  change their status to match their location.
                </p>
                <div className="status-radios">
                  <div className="status-radio-wrapper">
                    <FormControl component="fieldset">
                      <RadioGroup
                        className="modal-checkboxes-entry five-columns"
                        value={newEntryStatus}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="OffSiteStart"
                          control={
                            <Radio
                              className={classes.root}
                              disableRipple
                              color="default"
                              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                              icon={<span className={classes.icon} />}
                            />
                          }
                          className={newEntryStatus === 'OffSiteStart' ? 'radio-checked' : ''}
                          label="Outside"
                          disabled={currentEntryStatus === EntryStatus.OffSite}
                          labelPlacement="bottom"
                        />
                        {accessInfo && accessInfo.physicalFacilityType === PhysicalFacilityType.NestedCarPark && (
                          <FormControlLabel
                            value="TransitIn"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            className={newEntryStatus === 'TransitIn' ? 'radio-checked' : ''}
                            label="Transiting in"
                            disabled={currentEntryStatus === EntryStatus.TransitIn}
                            labelPlacement="bottom"
                          />
                        )}
                        <FormControlLabel
                          value="OnSite"
                          control={
                            <Radio
                              className={classes.root}
                              disableRipple
                              color="default"
                              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                              icon={<span className={classes.icon} />}
                            />
                          }
                          className={newEntryStatus === 'OnSite' ? 'radio-checked' : ''}
                          label="Inside"
                          disabled={currentEntryStatus === EntryStatus.OnSite}
                          labelPlacement="bottom"
                        />
                        {accessInfo && accessInfo.physicalFacilityType === PhysicalFacilityType.NestedCarPark && (
                          <FormControlLabel
                            value="TransitOut"
                            control={
                              <Radio
                                className={classes.root}
                                disableRipple
                                color="default"
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                              />
                            }
                            className={newEntryStatus === 'TransitOut' ? 'radio-checked' : ''}
                            label="Transiting out"
                            disabled={currentEntryStatus === EntryStatus.TransitOut}
                            labelPlacement="bottom"
                          />
                        )}
                        <FormControlLabel
                          value="OffSiteEnd"
                          control={
                            <Radio
                              className={classes.root}
                              disableRipple
                              color="default"
                              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                              icon={<span className={classes.icon} />}
                            />
                          }
                          className={newEntryStatus === 'OffSiteEnd' ? 'radio-checked' : ''}
                          label="Outside"
                          disabled={currentEntryStatus === EntryStatus.OffSite}
                          labelPlacement="bottom"
                        />
                      </RadioGroup>
                    </FormControl>
                    {accessInfo && accessInfo.physicalFacilityType === PhysicalFacilityType.NestedCarPark ? (
                      <>
                        <div className="arrow-wrapper short-arrows">
                          <img className="modal-arrow-short left-arrow" src={shortArrow} alt="shortArrow" />
                          <img className="modal-arrow-short mid-left-arrow" src={shortArrow} alt="shortArrow" />
                          <img className="modal-arrow-short mid-right-arrow" src={shortArrow} alt="shortArrow" />
                          <img className="modal-arrow-short right-arrow" src={shortArrow} alt="shortArrow" />
                        </div>
                        <div className="mob-arrow-wrapper vert-arrows mobile-only">
                          <img className="modal-arrow-vert arrow-one" src={vertArrow} alt="vertArrow" />
                          <img className="modal-arrow-vert arrow-two" src={vertArrow} alt="vertArrow" />
                          <img className="modal-arrow-vert arrow-three" src={vertArrow} alt="vertArrow" />
                          <img className="modal-arrow-vert arrow-four" src={vertArrow} alt="vertArrow" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="arrow-wrapper long-arrows">
                          <img className="modal-arrow-long left-arrow" src={longArrow} alt="longArrow" />
                          <img className="modal-arrow-long right-arrow" src={longArrow} alt="longArrow" />
                        </div>
                        <div className="mob-arrow-wrapper vert-arrows mobile-only">
                          <img className="modal-arrow-vert arrow-one" src={vertArrow} alt="vertArrow" />
                          <img className="modal-arrow-vert arrow-two" src={vertArrow} alt="vertArrow" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <p className="entry-status-text">{getEntryStatusText()}</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '20px' }} className="anchored-wrapper">
              <Grid item xs={6} className="desktop-only">
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    className={classes.shuffleButtonModal}
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{ width: '160px' }}
                    onClick={onClickCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6} className="mobile-fullwidth-button">
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    className={classes.shuffleButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ width: '160px' }}
                    disabled={!newEntryStatus}
                    onClick={onChangeEntryStatus}
                  >
                    {loading ? <CircularProgress style={{ color: '#FFFFFF', width: 29, height: 29 }} /> : 'Change'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
      {display && (
        <style>{`
        #footer-text {
          visibility: hidden;
        }
        body {
          overflow: hidden;
        }
      `}</style>
      )}
    </>
  )
}
export default ControlCentreEntryStatusModal
