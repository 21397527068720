import React from 'react'
import { Box, Container, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorModel: {
      background: 'white',
      padding: '40px',
      borderRadius: '4px',
      position: 'absolute',
      top: '194px',
      width: '446px',
      textAlign: 'center',
    },
  }),
)

interface IProps {}

const RouteNotFound: React.FC<IProps> = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.errorModel}>
      <Container maxWidth="lg" disableGutters={true} className="error-modal-styles">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <h3 className="error-h3">404</h3>
          </Grid>
          <Grid item xs={12}>
            <h3 className="error-h3">Page Not Found</h3>
          </Grid>
          <Grid item xs={12}>
            <h4 className="error-h4">The resource requested could not be found </h4>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <a className="password-link login-back" href="/login">
            Back to login
          </a>
        </Grid>
      </Container>
    </Box>
  )
}

export default RouteNotFound
