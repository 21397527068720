import {
  AGGREGATE_BAY_UTILISATION_REPORT,
  AGGREGATE_MANAGE_BAY,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  isAggregateActionType,
  RESET_AGGREGATE,
} from '../../../common/aggregate.types'
import {
  BayUtilisationReportActionTypes,
  BayUtilisationReportState,
  ManageEntitlementState,
  ManageEntitlementTypes,
} from './types'

const initialState: ManageEntitlementState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function manageBayReducer(state = initialState, action: ManageEntitlementTypes): ManageEntitlementState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_MANAGE_BAY) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}

// Bay Utilisation Report

const initialStateBayUtilisationReport: BayUtilisationReportState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function bayUtilisationReportReducer(
  state = initialStateBayUtilisationReport,
  action: BayUtilisationReportActionTypes,
): BayUtilisationReportState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_BAY_UTILISATION_REPORT) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
      }
    case RESET_AGGREGATE:
      return initialStateBayUtilisationReport
    default:
      return state
  }
}
