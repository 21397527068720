import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Loading from '../ui/Loading'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '440px',
    maxHeight: '400px',
    textAlign: 'center',
  },
  descLabel: {
    marginTop: '35px',
    color: '#ff0000 !important',
    fontWeight: '500',
    cursor: 'pointer',
  },
}))

interface IProps {
  display: boolean
  cancelModal: () => void
  confirmModal: () => void
  abortModal: () => void
  title: string
  description: string
  cancelButtonName: string
  confirmButtonName: string
  inserting?: boolean
}

const BlockCancelConfirmationModal: React.FC<IProps> = (props) => {
  const {
    display,
    cancelModal,
    confirmModal,
    abortModal,
    title,
    description,
    cancelButtonName,
    confirmButtonName,
    inserting,
  } = props

  const classes = useStyles()
  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      {inserting && <Loading />}
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-h3 larger-text">{title}</h3>
              <p>{description}</p>
              <p className={classes.descLabel} onClick={abortModal}>
                Don't add block out date
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '40px' }}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={cancelModal}
                >
                  {cancelButtonName}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={confirmModal}
                >
                  {confirmButtonName}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default BlockCancelConfirmationModal
