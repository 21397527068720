import {
  AGGREGATE_VERIFY_STAY_AGREEMENTS,
  ASSIGN_BAY_AGGREGATE_REQUEST,
  ASSIGN_PARKING_BAY_AGGREGATE_REQUEST,
  CUSTOM_AGGREGATE_FAILURE,
  CUSTOM_AGGREGATE_REQUEST,
  CUSTOM_AGGREGATE_SUCCESS,
  INSERT_AGGREGATE_FAILURE,
  INSERT_AGGREGATE_REQUEST,
  INSERT_AGGREGATE_SUCCESS,
  isAggregateActionType,
  RESET_AGGREGATE,
  UPDATE_AGGREGATE_FAILURE,
  UPDATE_AGGREGATE_REQUEST,
  UPDATE_AGGREGATE_SUCCESS,
} from '../../../common/aggregate.types'
import { AssignBayState, AssignBayTypes, AssignParkingBayState, AssignParkingBayTypes } from './types'

const initialState: AssignBayState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function assignBayReducer(state = initialState, action: AssignBayTypes): AssignBayState {
  // Guard against entity actions for another type.  Non entity actions and entity actions for another type we must ignore.
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== ASSIGN_BAY_AGGREGATE_REQUEST) {
    return state
  }

  switch (action.type) {
    case INSERT_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        error: null,
      }
    case INSERT_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: true,
        updated: false,
        error: null,
        idOnly: action.payload.idOnly,
      }
    case INSERT_AGGREGATE_FAILURE:
      return {
        ...state,
        inserting: false,
        error: action.payload.error,
      }
    case RESET_AGGREGATE:
      return initialState
    default:
      return state
  }
}

const initialStateAssignParkingBay: AssignParkingBayState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function assignParkingBayReducer(
  state = initialStateAssignParkingBay,
  action: AssignParkingBayTypes,
): AssignParkingBayState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== ASSIGN_PARKING_BAY_AGGREGATE_REQUEST) {
    return state
  }
  switch (action.type) {
    case UPDATE_AGGREGATE_REQUEST:
    case INSERT_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        error: null,
        inserted: false,
      }
    case INSERT_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: true,
        updated: false,
        error: null,
        idOnly: action.payload.idOnly,
      }
    case UPDATE_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: false,
        updated: true,
        error: null,
      }
    case UPDATE_AGGREGATE_FAILURE:
    case INSERT_AGGREGATE_FAILURE:
      let errorMessage: string = ''
      let errorResp: any = action.payload.error
      if (typeof errorResp === 'object') {
        errorMessage = errorResp[0].message
      }
      if (typeof errorResp === 'string') {
        errorMessage = errorResp
      }
      return {
        ...state,
        inserting: false,
        error: errorMessage,
      }
    case RESET_AGGREGATE:
      return initialStateAssignParkingBay
    default:
      return state
  }
}

// For Verify Stay Agreements

export function verifyStayAgreementsReducer(
  state = initialStateAssignParkingBay,
  action: AssignParkingBayTypes,
): AssignParkingBayState {
  if (isAggregateActionType(action.type) && action.payload.aggregateType !== AGGREGATE_VERIFY_STAY_AGREEMENTS) {
    return state
  }
  switch (action.type) {
    case CUSTOM_AGGREGATE_REQUEST:
      return {
        ...state,
        inserting: true,
        inserted: false,
        error: null,
        idOnly: {
          id: null,
        },
      }
    case CUSTOM_AGGREGATE_SUCCESS:
      return {
        ...state,
        inserting: false,
        inserted: true,
        error: null,
      }
    case CUSTOM_AGGREGATE_FAILURE:
      let errorResponseData: any = action?.payload?.error
      let errorMessage: any
      if (typeof errorResponseData === 'object') {
        if (
          errorResponseData?.response?.data &&
          errorResponseData?.response?.data?.errors &&
          errorResponseData?.response?.data?.errors[0]
        ) {
          errorMessage = errorResponseData?.response?.data.errors[0]
        } else {
          errorMessage = errorResponseData?.response?.data
        }
      }
      if (typeof errorResponseData === 'string') {
        errorMessage = errorResponseData
      }
      return {
        ...state,
        inserting: false,
        inserted: false,
        error: errorMessage,
        idOnly: {
          id: null,
        },
      }
    case RESET_AGGREGATE:
      return initialStateAssignParkingBay
    default:
      return state
  }
}
