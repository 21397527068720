import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Button, CircularProgress, Container, Grid, Pagination, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { AGGREGATE_MEMBER_BULK_UPLOAD_SUMMARY } from '../../../common/aggregate.types'
import { chooseUrl, fetchAggregates } from '../../../common/axios-action'
import { ErrorResponse, FileStatus } from '../../../common/types'
import { getFileStatusFormattedName } from '../../../common/utility'
import AddBulkUserModal from '../../../components/model/AddBulkUserModal'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { authorizationHeaderConfig } from '../../../config/axios/axios'
import usePagination from '../../../hook/usePagination'
import { RootState } from '../../../store'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { getTableCell } from '../../util/tooltip'
import {
  GET_EXCEPTIONS_RECORDS_API_RESOURCE_PATH,
  MemberBulkUploadSummary,
  MEMBER_BULK_UPLOAD_SUMMARY_API_RESOURCE_PATH,
} from './types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  shuffleAlert: {
    backgroundColor: '#009273',
    color: 'white',
    borderRadius: '8px',
    top: '10px',
    position: 'absolute',
    padding: '14px 16px',
  },
}))

interface IProps {}

const BulkUploads: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [page, rowsPerPage, sortDirection, sortBy, handleChangePage] = usePagination()
  const [addBulkModalOpen, setAddBulkModalOpen] = useState<boolean>(false)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const { loadingList, memberBulkUploadSummaryList, memberBulkUploadSummaryError }: any = useSelector(
    (state: RootState) => ({
      loadingList: state.memberBulkUploadSummaryReducer.loadingList,
      memberBulkUploadSummaryList: state.memberBulkUploadSummaryReducer.aggregates,
      memberBulkUploadSummaryError: state.memberBulkUploadSummaryReducer.error,
    }),
  )

  const { user, shuffleApiConfig } = useSelector((state: RootState) => ({
    user: state.authReducer.user,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
  }))

  useEffect(() => {
    getMemberBulkUploadSummaryList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId, page])

  useEffect(() => {
    if (memberBulkUploadSummaryError) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(memberBulkUploadSummaryError))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError.message ? firstError.message.substring(0, 50) : firstError.code.substring(0, 50),
          ]),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberBulkUploadSummaryError])

  const getMemberBulkUploadSummaryList = () => {
    if (currentOperatorId && currentOrganisationId) {
      const payload = {
        page: page - 1,
        size: rowsPerPage,
      }
      dispatch(
        fetchAggregates<MemberBulkUploadSummary>(
          AGGREGATE_MEMBER_BULK_UPLOAD_SUMMARY,
          MEMBER_BULK_UPLOAD_SUMMARY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          payload,
        ),
      )
    }
  }

  const onClickAddBulkUsers = () => {
    setAddBulkModalOpen(true)
  }
  const cancelAddBulkModal = () => {
    setAddBulkModalOpen(false)
  }
  const confirmAddBulkModal = () => {
    if (page === 1) {
      getMemberBulkUploadSummaryList()
    } else {
      handleChangePage(null, 1)
    }
    setAddBulkModalOpen(false)
  }

  const onRefresh = () => {
    if (page === 1) {
      getMemberBulkUploadSummaryList()
    } else {
      handleChangePage(null, 1)
    }
  }

  const downloadExceptionsFile = async (id: string) => {
    if (currentOperatorId && currentOrganisationId && id) {
      let resourcePath = GET_EXCEPTIONS_RECORDS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
        .replace(':carParkTenantId', currentOrganisationId)
        .replace(':memberBulkUploadId', id)
      try {
        let authorization = user!.getIdToken()
        await authorization
          .then((token: any) => {
            let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
            config.timeout = 420000
            axios
              .get<any>(resourcePath, config)
              .then(function (response) {
                if (response) {
                  var blob = new Blob([response.data.join('\n')], { type: 'text/csv;charset=utf-8;' })
                  var url = URL.createObjectURL(blob)
                  var pom = document.createElement('a')
                  pom.href = url
                  pom.setAttribute('download', 'invalid-records-list.csv')
                  pom.click()
                  pom.remove()
                  let message = 'File downloaded successfully.'
                  dispatch(setNotification(NotificationType.INFO, [message]))
                }
              })
              .catch(function (error) {
                console.error(error)
                if (error.response.data.errors) {
                  dispatch(setNotification(NotificationType.ERROR, [error.response.data.errors[0].message]))
                } else {
                  dispatch(
                    setNotification(NotificationType.ERROR, [
                      'File did not download, Please refresh and download again.',
                    ]),
                  )
                }
              })
          })
          .catch((error: any) => {
            console.error(error)
            dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
          })
      } catch (err) {
        console.error(err)
        dispatch(setNotification(NotificationType.ERROR, ['Oops something went wrong please try again.']))
      }
    }
  }

  const formattedName = (name: string): string => {
    let nameArray = name.split(' ')
    let fName = nameArray[0]
    let lName = nameArray[1]
    return fName.charAt(0) + ' ' + lName
  }

  return (
    <>
      <AddBulkUserModal
        display={addBulkModalOpen}
        cancelModel={cancelAddBulkModal}
        confirmModel={confirmAddBulkModal}
      />
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href="/users">
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Bulk registrations
                  </a>
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
              <Grid item xs={12} textAlign="end">
                <Button
                  className="outlined-mono"
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: '27px' }}
                  size="large"
                  onClick={() => onClickAddBulkUsers()}
                >
                  Bulk add users
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="page-content-body-wrapper bulk-uploads-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            <Grid item xs={12} className="content-panel">
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ paddingTop: '0px' }}>
                  <h3>{memberBulkUploadSummaryList.totalCount ? memberBulkUploadSummaryList.totalCount : 0} Files</h3>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: '5px', textAlign: 'end' }}>
                  <span className="refresh-label" style={{ fontWeight: '500' }} onClick={() => onRefresh()}>
                    Refresh
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.gridTopMargin}>
                  {loadingList ? (
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <CircularProgress color="primary" size={30} />
                      </Grid>
                    </Grid>
                  ) : (
                    <table id="transactions">
                      <thead>
                        <tr>
                          <th>Submitted</th>
                          <th>Filename</th>
                          <th>Submitted By</th>
                          <th>Completed</th>
                          <th>Status</th>
                          <th style={{ textAlign: 'right' }}>Success</th>
                          <th style={{ textAlign: 'right' }}>Exceptions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberBulkUploadSummaryList.values.length > 0 ? (
                          memberBulkUploadSummaryList.values.map((row: MemberBulkUploadSummary) => (
                            <tr key={row.id}>
                              <td>{format(Date.parse(row.submittedLocalDateTime), 'E dd MMM hh:mmaaa')}</td>
                              <td className="pr-20" style={{ maxWidth: '200px', minWidth: '200px' }}>
                                {getTableCell(row.fileName, 43)}
                              </td>
                              <td>{formattedName(row.submittedBy)}</td>
                              <td>
                                {row.fileStatus === FileStatus.Complete
                                  ? format(Date.parse(row.completedDateTime), 'E dd MMM hh:mmaaa')
                                  : '-'}
                              </td>
                              <td>{getFileStatusFormattedName(row.fileStatus)}</td>
                              <td style={{ textAlign: 'right' }}>{row.successCount}</td>
                              <td style={{ textAlign: 'right' }}>
                                {row.exceptionCount === 0 ? (
                                  <div className="download-exceptions-label">{row.exceptionCount}</div>
                                ) : (
                                  <div className="download-exceptions-files">
                                    <span className="exceptions-record-count">{row.exceptionCount}</span>
                                    <Tooltip title="Invalid Records" placement="top" arrow>
                                      <FileDownloadOutlinedIcon
                                        className="exceptions-record-file"
                                        color="error"
                                        onClick={() => downloadExceptionsFile(row.id)}
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} align="center">
                              No Records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <Pagination
                    count={
                      memberBulkUploadSummaryList.totalCount
                        ? Math.ceil(memberBulkUploadSummaryList.totalCount / rowsPerPage)
                        : 1
                    }
                    page={page}
                    onChange={handleChangePage}
                    className="pagination"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default BulkUploads
