import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import backIcon from '../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { AGGREGATE_GUEST, AGGREGATE_MY_LIST_TENANCIES } from '../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../common/axios-action'
import { getEntryStatusFormattedName } from '../../common/utility'
import ControlCentreEntryStatusModal from '../../components/model/ControlCentreEntryStatusModal'
import ControlCentreOpenGateModal from '../../components/model/ControlCentreOpenGateModal'
import { selectCurrentOperatorId } from '../../config/app/reducers'
import { RootState } from '../../store'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../store/common/tenancies/types'
import { GUEST_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH } from '../../store/common/update-entry-status/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../auth/types'
import { GUEST_ENTITLEMENT_API_RESOURCE_PATH, GuestEntitlement } from '../guest/types'
import { ROUTE_OPERATIONS, ROUTE_OPERATIONS_CONTROL_CENTRE } from '../util/routes'

const useStyles = makeStyles((theme) => ({
  shuffleButtonLeft: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '160px',
  },
  shuffleButtonRight: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    minWidth: '140px',
  },
}))

interface IProps {}

const GuestBookingSummary: React.FC<IProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)

  const [openEntryStatusModal, setOpenEntryStatusModal] = useState<boolean>(false)
  const [openGateModal, setOpenGateModal] = useState<boolean>(false)
  const [isForcedAccess, setIsForcedAccess] = useState<boolean | undefined>(undefined)

  const [entryStatusUpdateUrl, setEntryStatusUpdateUrl] = useState<string>('')

  const { guestEntitlementDetail, isGuestEntitlementDetailRequestCompleted, tenancies } = useSelector(
    (state: RootState) => ({
      guestEntitlementDetail: state.guestEntitlementsItemReducer.aggregate,
      isGuestEntitlementDetailRequestCompleted: state.guestEntitlementsItemReducer.updatedApplicationStatus,
      tenancies: state.myListTenancyReducer.aggregates.values,
    }),
  )

  // Get guest entitlement detail
  useEffect(() => {
    if (state && currentOperatorId && currentOrganisationId) {
      getGuestEntitlementDetail()
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
    // eslint-disable-next-line
  }, [dispatch, state, currentOperatorId, currentOrganisationId])

  // Set entry status update URL
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && guestEntitlementDetail && guestEntitlementDetail.id) {
      setEntryStatusUpdateUrl(
        GUEST_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':entitlementId', guestEntitlementDetail.id),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, guestEntitlementDetail])

  // Check forced access
  useEffect(() => {
    if (tenancies && guestEntitlementDetail && guestEntitlementDetail.bayAssignment) {
      if (isTenantUser) {
        const tenancy = tenancies.find((t: Tenancy) => t.id === guestEntitlementDetail.bayAssignment?.facilityId)
        if (tenancy && tenancy.forcedAccess === true) {
          setIsForcedAccess(true)
        } else {
          setIsForcedAccess(false)
        }
      } else {
        setIsForcedAccess(true)
      }
    }
  }, [guestEntitlementDetail, tenancies, isTenantUser])

  const getGuestEntitlementDetail = () => {
    if (state && currentOperatorId && currentOrganisationId) {
      const { entitlementId }: any = state
      dispatch(
        fetchAggregate<GuestEntitlement>(
          AGGREGATE_GUEST,
          GUEST_ENTITLEMENT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          entitlementId,
        ),
      )
    }
  }

  const getEntryStatus = (): string => {
    if (guestEntitlementDetail && guestEntitlementDetail.entryStatus) {
      let entryStatus = guestEntitlementDetail.entryStatus
      return getEntryStatusFormattedName(entryStatus)
    } else {
      return ''
    }
  }

  // Entry Status Modal
  const handleEntryStatusModalOpen = () => {
    setOpenEntryStatusModal(true)
  }
  const handleEntryStatusModalClose = () => {
    setOpenEntryStatusModal(false)
  }

  // Gate Modal
  const handleGateModalOpen = () => {
    setOpenGateModal(true)
  }
  const handleGateModalClose = () => {
    setOpenGateModal(false)
  }

  const getDuration = () => {
    if (guestEntitlementDetail && guestEntitlementDetail.stay) {
      let startDateTime = format(Date.parse(guestEntitlementDetail.stay.startDateTime), 'E dd MMMM hh:mmaaa')
      let finishDateTime = format(Date.parse(guestEntitlementDetail.stay.finishDateTime), 'E dd MMMM hh:mmaaa')
      return startDateTime + ' - ' + finishDateTime
    } else {
      return ''
    }
  }

  return (
    <>
      <div className="page-content-wrapper ondemand-section">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section" style={{ paddingRight: 0 }}>
              {guestEntitlementDetail && (
                <>
                  <Grid container spacing={0} className="responsive-title-wrapper">
                    <Grid item xs={12}>
                      <h2 className="on-demand-h2">
                        <a className="back-icon" href={ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_CONTROL_CENTRE}>
                          <img className="icon-back-arrow" src={backIcon} alt="back" />
                          {guestEntitlementDetail.contact?.firstName + ' ' + guestEntitlementDetail.contact?.lastName}
                        </a>
                      </h2>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="responsive-content-wrapper">
                    <Grid item xs={12}>
                      <p className="entry-status">Entry status: {getEntryStatus()}</p>
                      <p className="entry-status-detail">
                        The user is {getEntryStatus().toLowerCase()} of the car park.
                      </p>
                      {isForcedAccess === false && <p className="notifcation-text">These functions are not enabled.</p>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                    <Grid item xs={12} className="inline-buttons">
                      <Button
                        className={classes.shuffleButtonLeft}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isForcedAccess === false}
                        onClick={handleEntryStatusModalOpen}
                      >
                        Change entry status
                      </Button>
                      <Button
                        className={classes.shuffleButtonRight}
                        variant="outlined"
                        color="secondary"
                        size="large"
                        disabled={isForcedAccess === false}
                        onClick={handleGateModalOpen}
                      >
                        Open gate
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* On Demand Booking */}
        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              {isGuestEntitlementDetailRequestCompleted ? (
                <Grid item xs={12} className="content-panel">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-10 padding-top-5">Guest booking</h3>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="transaction-detail first-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>User</h5>
                        </Grid>
                        <Grid item xs={5}>
                          {guestEntitlementDetail && guestEntitlementDetail.contact && (
                            <table id="transaction-detail-table">
                              <tbody>
                                <tr>
                                  <td>
                                    {guestEntitlementDetail.contact.firstName +
                                      ' ' +
                                      guestEntitlementDetail.contact.lastName}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{guestEntitlementDetail.contact.email}</td>
                                </tr>
                                <tr>
                                  <td>{guestEntitlementDetail.contact.mobile}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="transaction-detail last-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Guest booking</h5>
                        </Grid>
                        <Grid item xs={7}>
                          {guestEntitlementDetail && guestEntitlementDetail.bayAssignment && (
                            <table id="transaction-detail-table">
                              <tbody>
                                <tr>
                                  <td>{guestEntitlementDetail.bayAssignment.facilityName}</td>
                                </tr>
                                <tr>
                                  <td>{guestEntitlementDetail.bayAssignment.facilityAllocationName}</td>
                                </tr>
                                <tr>
                                  <td>{guestEntitlementDetail.bayAssignment.facilityZoneName}</td>
                                </tr>
                                <tr>
                                  <td>{guestEntitlementDetail.bayAssignment.fullBayNo}</td>
                                </tr>
                                <tr>
                                  <td>{getDuration()}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className="content-panel"
                  style={{ minHeight: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <CircularProgress color="primary" size={35} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      {guestEntitlementDetail && guestEntitlementDetail.bayAssignment && (
        <ControlCentreEntryStatusModal
          display={openEntryStatusModal}
          handleClose={handleEntryStatusModalClose}
          facilityId={guestEntitlementDetail.bayAssignment.facilityId}
          currentEntryStatus={guestEntitlementDetail.entryStatus}
          entryStatusUpdateUrl={entryStatusUpdateUrl}
          afterUpdateEntryStatus={getGuestEntitlementDetail}
        />
      )}
      {guestEntitlementDetail && guestEntitlementDetail.bayAssignment && guestEntitlementDetail.id && (
        <ControlCentreOpenGateModal
          display={openGateModal}
          handleClose={handleGateModalClose}
          facilityId={guestEntitlementDetail.bayAssignment.facilityId}
          agreementId={guestEntitlementDetail.id}
          afterGateSuccess={getGuestEntitlementDetail}
        />
      )}
    </>
  )
}

export default GuestBookingSummary
