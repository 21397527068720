import { Grid } from '@mui/material'
import { format } from 'date-fns'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AGGREGATE_BAY_UTILISATION_REPORT } from '../../../common/aggregate.types'
import { fetchAggregateWithParams } from '../../../common/axios-action'
import { NameValueDTO } from '../../../common/types'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { selectCurrentOrganisationId } from '../../auth/types'
import { BayUtilisationReport, BAY_UTILISATION_REPORT_API_RESOURCE_PATH } from './types'

interface IProps {
  bayId: string
  facilityId: string
  agreementIds: string[]
}

const ParkingUtilisation: React.FC<IProps> = (props) => {
  const { bayId, facilityId, agreementIds } = props
  const dispatch = useDispatch()
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const { bayUtilisationReport, bayUtilisationReportError } = useSelector((state: RootState) => ({
    bayUtilisationReport: state.bayUtilisationReportReducer.aggregate,
    bayUtilisationReportError: state.bayUtilisationReportReducer.error,
  }))

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && facilityId) {
      dispatch(
        fetchAggregateWithParams<BayUtilisationReport>(
          AGGREGATE_BAY_UTILISATION_REPORT,
          BAY_UTILISATION_REPORT_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
          {
            endDate: format(new Date(), 'yyyy-MM-dd'),
            bayId: bayId ? bayId : null,
            facilityId: facilityId,
            agreementIds: agreementIds.join(','),
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId, bayId, facilityId])

  useEffect(() => {
    let chartData: NameValueDTO[] = []
    if (bayUtilisationReport) {
      chartData = [
        { value: bayUtilisationReport.utilised.percentage, name: 'Used' },
        { value: bayUtilisationReport.notUtilised.percentage, name: 'Not Used' },
      ]
    }
    const colorPalette = ['#0085DA', '#000000']
    const option = {
      tooltip: {
        trigger: 'item',
        valueFormatter: (value: any) => value + '%',
        formatter: function formatTooltip(params: any) {
          let formattedTooltip: string = ''
          formattedTooltip += `<div class="display-flex">
            <p style="color:#525252;font-size:12px;">${params.marker}${params.data.name}</p>
            <p style="color:#525252;font-size:12px;margin-left:10px;">${params.data.value}%</p>
            </div>`
          return formattedTooltip
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['80%', '97%'],
          label: {
            show: false,
          },
          color: colorPalette,
          data: chartData,
          emphasis: {
            disabled: true,
          },
          cursor: 'auto',
        },
      ],
    }
    setChartOption(option)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bayUtilisationReport, bayUtilisationReportError])

  const getBayLabel = (day: number | undefined) => {
    if (day !== undefined) {
      if (day === 1) {
        return 'Day'
      } else {
        return 'Days'
      }
    } else {
      return 'Day'
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <h3 className="padding-bottom-10">Parking Activity</h3>
        </Grid>
        <Grid item xs={2}>
          <p className="heading-subtitle">Past 30 days</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ paddingTop: '20px' }}>
        <Grid item xs={2}>
          {chartOption && <ReactEcharts option={chartOption} style={{ height: '120px', width: '120px' }} />}
        </Grid>
        <Grid item xs={4}>
          <h3 className="percentage-used">
            {bayUtilisationReport?.utilised.percentage}% ({bayUtilisationReport?.utilised.days}
            {getBayLabel(bayUtilisationReport?.utilised.days)})
          </h3>
          <p className="percentage-used-by">Bay Usage</p>
        </Grid>
        <Grid item xs={4}>
          <h3 className="percentage-remaining">
            {bayUtilisationReport?.notUtilised.percentage}% ({bayUtilisationReport?.notUtilised.days}
            {getBayLabel(bayUtilisationReport?.notUtilised.days)})
          </h3>
          <p className="percentage-used-by">Not Used</p>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  )
}

export default ParkingUtilisation
