import React from 'react'
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/lab'
import TextField from '@mui/material/TextField'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { ShowErrorFunc, showErrorOnChange } from './Util'
import pickerProviderWrapper from './PickerProvider'
import { ReactComponent as MoreTimeIcon } from '../../assets/svg/ws-ui-icon-calendar-grey.svg'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red  !important',
    },
  },
}))

export interface DatePickerProps extends Partial<Omit<MuiDatePickerProps, 'onChange'>> {
  name: string
  locale?: any
  fieldProps?: Partial<FieldProps<any, any>>
  required?: boolean
  showError?: ShowErrorFunc
  format: string
}

export function DatePicker(props: DatePickerProps) {
  const { name, fieldProps, required, format, ...rest } = props

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => (
        <DatePickerWrapper required={required} format={format} {...fieldRenderProps} {...rest} />
      )}
      {...fieldProps}
    />
  )
}

interface DatePickerWrapperProps extends FieldRenderProps<MuiDatePickerProps> {
  required?: boolean
  locale?: any
  format: string
}

function DatePickerWrapper(props: DatePickerWrapperProps) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    locale,
    required,
    format,
    showError = showErrorOnChange,
    ...rest
  } = props

  const classes = useStyles()
  const isError = showError({ meta })

  const { helperText, ...lessrest } = rest

  return pickerProviderWrapper(
    <MuiDatePicker
      onChange={onChange}
      value={(value as any) === '' ? null : value}
      {...lessrest}
      renderInput={(props) => (
        <TextField
          fullWidth={true}
          error={isError}
          name={name}
          className={isError ? classes.root : ''}
          required={required}
          {...restInput}
          {...props}
          InputLabelProps={{ className: 'shuffleLabel' }}
        />
      )}
      components={{
        OpenPickerIcon: MoreTimeIcon,
      }}
      disablePast
      inputFormat={format}
      DialogProps={{
        disableScrollLock: true,
      }}
    />,
    locale,
  )
}
