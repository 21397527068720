import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Box, Button, CircularProgress, Container, Grid, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { confirmPasswordReset, getAuth } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useQuery } from '../../../hook/useQuery'
import { resetLoading, setLoading } from '../../../store/common/loading/action'
import { selectLoading } from '../../../store/common/loading/types'
import { clearNotification, setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { ROUTE_LOGIN } from '../../util/routes'
import {
  isValidAll,
  passwordMatch,
  validPasswordLength,
  validateHasOneDigit,
  validateHasOneLower,
  validateHasOneSpecialCharacter,
  validateHasOneUpper,
} from '../../util/validator'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
}))

interface IProps {}

const ResetPasswordConfirm: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const query = useQuery()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)
  const navigate = useNavigate()

  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [repeatPassword, setRepeatPassword] = useState<string | null>(null)
  const [oobCode, setOobCode] = useState<string | null>(null)

  const [hasCharacterLength, setHasCharacterLength] = useState<boolean>(false)
  const [hasOneLower, setHasOneLower] = useState<boolean>(false)
  const [hasOneUpper, setHasOneUpper] = useState<boolean>(false)
  const [hasOneNumber, setHasOneNumber] = useState<boolean>(false)
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false)
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(false)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword)
  }

  const handleMouseDownRepeatPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'newPassword') {
      setNewPassword(value)
      if (validPasswordLength(value)) {
        setHasCharacterLength(true)
      } else {
        setHasCharacterLength(false)
      }
      if (validateHasOneUpper(value)) {
        setHasOneUpper(true)
      } else {
        setHasOneUpper(false)
      }
      if (validateHasOneLower(value)) {
        setHasOneLower(true)
      } else {
        setHasOneLower(false)
      }

      if (validateHasOneDigit(value)) {
        setHasOneNumber(true)
      } else {
        setHasOneNumber(false)
      }
      if (validateHasOneSpecialCharacter(value)) {
        setHasSpecialChar(true)
      } else {
        setHasSpecialChar(false)
      }
      if (value && repeatPassword) {
        if (passwordMatch(value, repeatPassword)) {
          setIsPasswordMatch(true)
        } else {
          setIsPasswordMatch(false)
        }
      } else {
        setIsPasswordMatch(false)
      }
    } else if (name === 'repeatPassword') {
      setRepeatPassword(value)
      if (newPassword && value) {
        if (passwordMatch(newPassword, value)) {
          setIsPasswordMatch(true)
        } else {
          setIsPasswordMatch(false)
        }
      } else {
        setIsPasswordMatch(false)
      }
    }
  }

  useEffect(() => {
    if (query) {
      setOobCode(query.get('oobCode'))
    }
  }, [query])

  const onClickResetPasswordConfirm = (event: any) => {
    event.preventDefault()
    if (oobCode && newPassword && repeatPassword && isValidAll(newPassword, repeatPassword)) {
      dispatch(clearNotification())
      dispatch(setLoading())
      const auth = getAuth()
      auth
        .signOut()
        .then(() => {
          console.debug('sign out existing user')
        })
        .catch((error) => {
          console.debug('error when signing existing user')
        })

      confirmPasswordReset(auth, oobCode, newPassword)
        .then(() => {
          dispatch(resetLoading())
          navigate(ROUTE_LOGIN)
        })
        .catch((error) => {
          dispatch(resetLoading())
          dispatch(
            setNotification(NotificationType.ERROR, ['Reset Password link is expired, or has already been used']),
          )
        })
    }
  }

  return (
    <Box className={classes.loginModal}>
      <Container maxWidth="lg" disableGutters={true} className="login-modal-styles base-button">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <h3 className="login-h3">{t('reset-password:titleChoosePassword')}</h3>
          </Grid>
          <Grid item xs={12}>
            <p className="input-label">{t('reset-password:labels.newPassword')}</p>
            <TextField
              inputProps={{ className: 'shuffleInput' }}
              id="newPassword"
              name="newPassword"
              InputLabelProps={{ className: 'shuffleLabel' }}
              variant="outlined"
              margin="dense"
              size={'small'}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              onChange={onChangeHandler}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleMouseDownPassword}
                    style={{ width: 31, height: 15 }}
                  >
                    {showPassword ? (
                      <VisibilityOff fontSize={'medium'} onClick={handleClickShowPassword} />
                    ) : (
                      <Visibility fontSize={'medium'} onClick={handleClickShowPassword} />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="input-label">{t('reset-password:labels.repeatPassword')}</p>
            <TextField
              inputProps={{ className: 'shuffleInput' }}
              id="repeatPassword"
              name="repeatPassword"
              InputLabelProps={{ className: 'shuffleLabel' }}
              variant="outlined"
              size={'small'}
              margin="dense"
              type={showRepeatPassword ? 'text' : 'password'}
              fullWidth
              onChange={onChangeHandler}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleMouseDownRepeatPassword}
                    style={{ width: 31, height: 15 }}
                  >
                    {showRepeatPassword ? (
                      <VisibilityOff fontSize={'medium'} onClick={handleClickShowRepeatPassword} />
                    ) : (
                      <Visibility fontSize={'medium'} onClick={handleClickShowRepeatPassword} />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="password-tips">{t('reset-password:labels.choosePasswordHeading')}</p>
            <ul className="password-tips-list">
              <li className={hasCharacterLength ? 'password-accepted' : ''}>
                {t('reset-password:labels.choosePasswordTips.characterLength')}
              </li>
              <li className={hasOneLower ? 'password-accepted' : ''}>
                {t('reset-password:labels.choosePasswordTips.oneLowercase')}
              </li>
              <li className={hasOneUpper ? 'password-accepted' : ''}>
                {t('reset-password:labels.choosePasswordTips.oneUppercase')}
              </li>
              <li className={hasOneNumber ? 'password-accepted' : ''}>
                {t('reset-password:labels.choosePasswordTips.oneNumber')}
              </li>
              <li className={hasSpecialChar ? 'password-accepted' : ''}>
                {t('reset-password:labels.choosePasswordTips.oneSpecial')}
              </li>
              <li className={isPasswordMatch ? 'password-accepted' : ''}>
                {t('reset-password:labels.choosePasswordTips.samePassword')}
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.shuffleButton}
              variant="contained"
              color="primary"
              size="large"
              disabled={newPassword && repeatPassword && isValidAll(newPassword, repeatPassword) ? false : true}
              onClick={(event) => onClickResetPasswordConfirm(event)}
            >
              {loading && <CircularProgress style={{ color: '#FFFFFF', width: 30, height: 30 }} />}
              {!loading && <>{t('reset-password:buttons.setPassword')}</>}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default ResetPasswordConfirm
