import { CircularProgress, Container, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { AGGREGATE_MY_LIST_TENANCIES } from '../../../common/aggregate.types'
import { fetchAggregates } from '../../../common/axios-action'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { Tenancy, TENANCY_API_RESOURCE_PATH } from '../../../store/common/tenancies/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import CarParkTenancy from './carpark-tenancy'

interface IProps {}

const ProductsCapacities: React.FC<IProps> = () => {
  const dispatch = useDispatch()

  const { carParkTenancyList, loadingList } = useSelector((state: RootState) => ({
    carParkTenancyList: state.myListTenancyReducer.aggregates.values,
    loadingList: state.myListTenancyReducer.loadingList,
  }))

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  return (
    <>
      <div className="page-content-wrapper">
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <Grid container spacing={0} className="responsive-title-wrapper">
                <Grid item xs={12}>
                  <h2 className="on-demand-h2">
                    <a className="back-icon" href="/on-demand">
                      <img className="icon-back-arrow" src={backIcon} alt="back" />
                      Products and capacities
                    </a>
                  </h2>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="responsive-content-wrapper full-wdith">
                <Grid item xs={12}>
                  <p>Manage the products and capacities at your car parks.</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              <Grid item xs={12} className="content-panel">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3 className="padding-bottom-10">Choose a location</h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {loadingList ? (
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <CircularProgress color="primary" size={30} />
                      </Grid>
                    </Grid>
                  ) : (
                    carParkTenancyList.length > 0 &&
                    carParkTenancyList.map((tenancy: any) => {
                      return <CarParkTenancy key={tenancy.id} tenancy={tenancy} />
                    })
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default ProductsCapacities
