/**
 * Template method for traced AXIOS operations which retrieves token from the Firebase user in s
 * @param aggregateType
 * @param getState Return RootState to retrieve user from authReducer
 * @param requestCallback
 * @param successCallback
 * @param errorCallback Called back after if the axios returns an error
 * @param ioCallback Execute the axios operation in this callback returning the promise
 */
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ShuffleApiConfig } from '../config/app/types'
import { authorizationHeaderConfig, isSuccessWithNotModified } from '../config/axios/axios'
import { ETAG_HEADER, HEADER_LINK_FORMAT, HEADER_X_TOTAL_COUNT, IF_NONE_MATCH_HEADER } from '../config/constants'
import { AppThunk, RootState } from '../store'
import {
  customMethodAggregateFailure,
  customMethodAggregateRequest,
  customMethodAggregateSuccess,
  deleteAggregateFailure,
  deleteAggregateRequest,
  deleteAggregateSuccess,
  getAggregateFailure,
  getAggregateRequest,
  getAggregateSuccess,
  IdentifiableAggregate,
  insertAggregateFailure,
  insertAggregateRequest,
  insertAggregateSuccess,
  listAggregatesFailure,
  listAggregatesIdNamesFailure,
  listAggregatesIdNamesNotModified,
  listAggregatesIdNamesRequest,
  listAggregatesIdNamesSuccess,
  listAggregatesNotModified,
  listAggregatesRequest,
  listAggregatesSuccess,
  postAggregateFailure,
  postAggregateRequest,
  postAggregateSuccess,
  updateAggregateFailure,
  updateAggregateRequest,
  updateAggregateSuccess,
  updateApplicationStatusFailure,
  updateApplicationStatusRequest,
  updateApplicationStatusSuccess,
  updateLifeCycleFailure,
  updateLifeCycleRequest,
  updateLifeCycleSuccess,
} from './aggregate-actions'
import { AggregateActionTypes, AggregateTypes } from './aggregate.types'
import { CachedAggregates, CachedIdNames, IdOnly, Lifecycle, LifecycleOnly } from './types'

export const tracedAxiosOperation = <T>(
  aggregateType: AggregateTypes,
  getState: () => RootState,
  requestCallback: () => AggregateActionTypes<T>,
  successCallback: (result: AxiosResponse) => void,
  errorCallback: (error: any) => AggregateActionTypes<T>,
  ioCallback: (config: AxiosRequestConfig) => AxiosPromise,
) => {
  const state = getState()
  const user = state.authReducer.user
  const shuffleApiConfig = state.appConfigReducer.shuffleApiConfig
  requestCallback()

  return user!
    .getIdToken()
    .then((token) => {
      return ioCallback(authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token))
        .then((value) => {
          return successCallback(value)
        })
        .catch((error) => {
          return errorCallback(error)
        })
    })
    .catch((error) => {
      errorCallback(error)
    })
}

export const chooseUrl = (shuffleApiConfig: ShuffleApiConfig | null) => {
  return shuffleApiConfig ? shuffleApiConfig.shuffleApiUrl : ''
}

export const AGGREGATE_MEMBER = 'MEMBER'
export const AGGREGATE_APPLICATION = 'APPLICATION'
export const AGGREGATE_TEAMS = 'TEAMS'
export const AGGREGATE_DEPARTMENT = 'DEPARTMENT'
export const AGGREGATE_TENANCIES = 'TENANCIES'
export const AGGREGATE_TENANCIES_OFFERS = 'TENANCIES_OFFERS'
export const AGGREGATE_OFFER_FACILITY = 'OFFER_FACILITY'
export const AGGREGATE_DEPARTMENTS_TEAMS = 'DEPARTMENTS_TEAMS'
export const AGGREGATE_MEMBER_ENTITLEMENT = 'MEMBER_MEMBER_ENTITLEMENT'
export const AGGREGATE_MEMBER_PARKING_ITEM = 'MEMBER_PARKING_ITEM'
export const AGGREGATE_NOTIFICATIONS = 'NOTIFICATIONS'
export const AGGREGATE_AUTO_INVITATION = 'AUTO_INVITATION'
export const AGGREGATE_EMAIL_DOMAIN = 'EMAIL_DOMAIN'

export const fetchAggregates =
  <T>(aggregateType: AggregateTypes, resourcePath: string, params: any | null): AppThunk<any> =>
  (dispatch, getState) => {
    console.debug('fetchAggregates params:', params)
    const eTagForAggregates = () => {
      switch (aggregateType) {
        case AGGREGATE_MEMBER:
          return getState().manageUserReducer.aggregates.eTag
        case AGGREGATE_APPLICATION:
          return getState().applicationReducer.aggregates.eTag
        case AGGREGATE_TEAMS:
          return getState().teamReducer.aggregates.eTag
        case AGGREGATE_DEPARTMENT:
          return getState().departmentReducer.aggregates.eTag
        case AGGREGATE_TENANCIES:
          return getState().tenancyReducer.aggregates.eTag
        case AGGREGATE_TENANCIES_OFFERS:
          return getState().tenancyOffersReducer.aggregates.eTag
        case AGGREGATE_DEPARTMENTS_TEAMS:
          return getState().departmentsTeamsReducer.aggregates.eTag
        case AGGREGATE_MEMBER_ENTITLEMENT:
          return getState().memberEntitlementReducer.aggregates.eTag
        case AGGREGATE_MEMBER_PARKING_ITEM:
          return getState().memberParkingItemReducer.aggregates.eTag
        case AGGREGATE_AUTO_INVITATION:
          return getState().autoInvitationReducer.aggregates.eTag
        case AGGREGATE_EMAIL_DOMAIN:
          return getState().emailDomainReducer.aggregates.eTag
        default:
          return null
      }
    }
    console.debug('fetchAggregates params:', params)

    const eTag = eTagForAggregates()
    console.debug('fetchAggregates eTag:', eTag)

    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(listAggregatesRequest<T>(aggregateType))
      },
      (response) => {
        if (response.status === 304) {
          console.debug('fetchAggregates 304 returned calling listAggregatesNotModified')
          return dispatch(listAggregatesNotModified<T>(aggregateType))
          // Otherwise, replace data and store ETag response
        } else {
          console.debug('fetchAggregates success:', response.status)
          console.debug('headers', response.headers)
          const aggregates: CachedAggregates<T> = {
            values: response.data,
            eTag: response.headers[ETAG_HEADER],
            totalCount: Number(response.headers[HEADER_X_TOTAL_COUNT]),
            link: response.headers[HEADER_LINK_FORMAT],
          }

          console.debug('aggregates', aggregates)
          return dispatch(listAggregatesSuccess<T>(aggregateType, aggregates))
        }
      },
      (error) => {
        return dispatch(listAggregatesFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        if (eTag != null) {
          config.headers = { [IF_NONE_MATCH_HEADER]: eTag, ...config.headers }
        }
        // 200 | 304 is success
        config.validateStatus = isSuccessWithNotModified
        if (params != null) {
          config.params = params
          console.debug('request params: ', config.params)
        }
        console.debug('request headers: ', config.headers)
        return axios.get<T[]>(resourcePath, config)
      },
    )
  }

/**
 *
 * @param aggregateType
 * @param resourcePath Without trailing slash eg /carparks
 * @param id
 */
export const fetchAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, id: string | null): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(getAggregateRequest<T>(aggregateType, id))
      },
      (response) => {
        const aggregate: T = response.data
        return dispatch(getAggregateSuccess<T>(aggregateType, aggregate))
      },
      (error) => {
        return dispatch(getAggregateFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        return axios.get<T>(resourcePath + (id ? '/' : '') + (id ? id : ''), config)
      },
    )
  }

export const fetchAggregateWithParams =
  <T>(aggregateType: AggregateTypes, resourcePath: string, id: string | null, params: any | null): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(getAggregateRequest<T>(aggregateType, id))
      },
      (response) => {
        const aggregate: T = response.data
        return dispatch(getAggregateSuccess<T>(aggregateType, aggregate))
      },
      (error) => {
        return dispatch(getAggregateFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        if (params != null) {
          config.params = params
        }
        return axios.get<T>(resourcePath + (id ? '/' : '') + (id ? id : ''), config)
      },
    )
  }

/**
 *
 * @param aggregateType
 * @param resourcePath without training slash
 * @param aggregate
 */
export const updateAggregate =
  <T extends IdentifiableAggregate>(aggregateType: AggregateTypes, resourcePath: string, aggregate: T): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'updateAggregate.' +
      getState,
      () => {
        return dispatch(updateAggregateRequest<T>(aggregateType, aggregate))
      },
      (response) => {
        const aggregate: T = response.data
        return dispatch(updateAggregateSuccess<T>(aggregateType, aggregate))
      },
      (error) => {
        return dispatch(updateAggregateFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        return axios.put<T>(resourcePath + '/' + aggregate.id, aggregate, config)
      },
    )
  }

/**
 * Insert an aggregate.  T is the aggregate type eg CarPark
 * @param aggregateType
 * @param resourcePath without the trailing slash  eg /carparks
 * @param aggregate  Aggregate to insert
 */
export const insertAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, aggregate: T): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'insertAggregate.' +
      getState,
      () => {
        return dispatch(insertAggregateRequest<T>(aggregateType, aggregate))
      },
      (response) => {
        const idOnly: IdOnly = response.data
        return dispatch(insertAggregateSuccess<T>(aggregateType, idOnly))
      },
      (error) => {
        return dispatch(insertAggregateFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        return axios.post<T>(resourcePath, aggregate, config)
      },
    )
  }

export const postAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, payload: T): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'insertAggregate.' +
      getState,
      () => {
        return dispatch(postAggregateRequest<T>(aggregateType))
      },
      (response) => {
        const data: T = response.data
        return dispatch(postAggregateSuccess<T>(aggregateType, data))
      },
      (error) => {
        let errorMessage = error.response.data.errors[0].message
        return dispatch(postAggregateFailure<T>(aggregateType, errorMessage))
      },
      (config) => {
        return axios.post<T>(resourcePath, payload, config)
      },
    )
  }

export const deleteAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, id: string): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'deleteAggregate.' +
      getState,
      () => {
        return dispatch(deleteAggregateRequest<T>(aggregateType, id))
      },
      (_) => {
        return dispatch(deleteAggregateSuccess<T>(aggregateType))
      },
      (error) => {
        return dispatch(deleteAggregateFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        return axios.delete<T>(resourcePath + '/' + id, config)
      },
    )
  }

export const fetchIdNamesAggregates =
  <T>(aggregateType: AggregateTypes, resourcePath: string, params: any | null = null): AppThunk<any> =>
  (dispatch, getState) => {
    const eTagForAggregates = () => {
      switch (aggregateType) {
        case AGGREGATE_MEMBER:
          return getState().manageUserReducer.idNames.eTag
        case AGGREGATE_APPLICATION:
          return getState().applicationReducer.idNames.eTag
        case AGGREGATE_TEAMS:
          return getState().teamReducer.idNames.eTag
        case AGGREGATE_DEPARTMENT:
          return getState().departmentReducer.idNames.eTag
        case AGGREGATE_TENANCIES:
          return getState().tenancyReducer.idNames.eTag
        case AGGREGATE_DEPARTMENTS_TEAMS:
          return getState().departmentsTeamsReducer.idNames.eTag
        case AGGREGATE_MEMBER_ENTITLEMENT:
          return getState().memberEntitlementReducer.idNames.eTag
        case AGGREGATE_MEMBER_PARKING_ITEM:
          return getState().memberParkingItemReducer.idNames.eTag
        case AGGREGATE_AUTO_INVITATION:
          return getState().autoInvitationReducer.idNames.eTag
        case AGGREGATE_EMAIL_DOMAIN:
          return getState().emailDomainReducer.idNames.eTag
        default:
          return null
      }
    }
    console.debug('fetchAggregates params:', params)

    const eTag = eTagForAggregates()
    console.debug('fetchAggregates eTag:', eTag)

    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(listAggregatesIdNamesRequest<T>(aggregateType))
      },
      (response) => {
        // Handle no change repsonse code where our existing cache matches server content
        if (response.status === 304) {
          console.debug('fetchIdNamesAggregates 304 returned calling listAggregatesIdNamesNotModified')
          return dispatch(listAggregatesIdNamesNotModified<T>(aggregateType))

          // Otherwise, replace data and store ETag response
        } else {
          console.debug('fetchIdNamesAggregates success:', response.status)
          console.debug('headers', response.headers)
          const idNames: CachedIdNames = {
            values: response.data,
            eTag: response.headers[ETAG_HEADER],
          }

          console.debug('idNames', idNames)
          return dispatch(listAggregatesIdNamesSuccess<T>(aggregateType, idNames))
        }
      },
      (error) => {
        return dispatch(
          listAggregatesIdNamesFailure<T>(aggregateType, error.response ? error.response.data.errors : error),
        )
      },
      (config) => {
        if (eTag != null) {
          config.headers = { [IF_NONE_MATCH_HEADER]: eTag, ...config.headers }
        }

        // 200 | 304 is success
        config.validateStatus = isSuccessWithNotModified
        if (params != null) {
          config.params = params
          console.debug('request params: ', config.params)
        }
        console.debug('request headers: ', config.headers)
        return axios.get<T[]>(resourcePath, config)
      },
    )
  }

export const updateLifeCycle =
  <T>(aggregateType: AggregateTypes, resourcePath: string, id: string, lifeCycleOnly: LifecycleOnly): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(updateLifeCycleRequest<T>(aggregateType, lifeCycleOnly))
      },
      (_) => {
        if (lifeCycleOnly.lifecycle === Lifecycle.Deleted) {
          return dispatch(deleteAggregateSuccess<T>(aggregateType))
        } else {
          return dispatch(updateLifeCycleSuccess<T>(aggregateType))
        }
      },
      (error) => {
        return dispatch(updateLifeCycleFailure<T>(aggregateType, error.response ? error.response.data.errors : error))
      },
      (config) => {
        return axios.patch<T>(resourcePath + '/' + id + '/lifecycle', lifeCycleOnly, config)
      },
    )
  }

export const updateApplicationStatus =
  <T>(aggregateType: AggregateTypes, resourcePath: string, values: any): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(updateApplicationStatusRequest<T>(aggregateType, values))
      },
      (_) => {
        return dispatch(updateApplicationStatusSuccess<T>(aggregateType))
      },
      (error) => {
        return dispatch(
          updateApplicationStatusFailure<T>(aggregateType, error.response ? error.response.data.errors : error),
        )
      },
      (config) => {
        return axios.post<T>(resourcePath, values, config)
      },
    )
  }

/**
 * custom an aggregate.  T is the aggregate type eg CarPark
 * @param aggregateType
 * @param resourcePath without the trailing slash  eg /carparks
 */
export const customMethodAggregateRequestAction =
  <T>(aggregateType: AggregateTypes, resourcePath: string, aggregate: any): AppThunk<any> =>
  (dispatch, getState) => {
    let apiPayload: any = ''
    if (aggregate) {
      apiPayload = aggregate
    }
    return tracedAxiosOperation<T>(
      aggregateType, // 'insertAggregate.' +
      getState,
      () => {
        return dispatch(customMethodAggregateRequest<T>(aggregateType))
      },
      () => {
        return dispatch(customMethodAggregateSuccess<T>(aggregateType))
      },
      (error) => {
        return dispatch(customMethodAggregateFailure<T>(aggregateType, getResponseError(error)))
      },
      (config) => {
        return axios.post<T>(resourcePath, apiPayload, config)
      },
    )
  }

const getResponseError = (error: any) => {
  return error.response
    ? error.response.data && error.response.data.error
      ? error.response.data.error
      : error.response.data && error.response.data.errors
      ? error.response.data.errors
      : error.response.data && error.response.data.message
      ? error.response.data.message
      : error.response.data.message
    : error
}
