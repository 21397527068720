import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Switch,
  SwitchProps,
  TextField,
} from '@mui/material'
import { Fragment } from 'react'
import tickIcon from '../../../assets/svg/ws-sml-icon-tick-wht-50.svg'
import closeIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import {
  ALLOCATION_TYPE_FIXED,
  ALLOCATION_TYPE_PERCENTAGE,
  ALLOCATION_TYPE_UNLIMITED,
  MemberAllocations,
  MEMBER_POOL_NAME_SORTING_ARR,
  OfferFacility,
} from '../../../store/common/offer-facilities/types'

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#009273',
        backgroundImage: `url('${tickIcon}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
        backgroundSize: '23%',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'black',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: '#BBBABA',
    backgroundImage: `url('${closeIcon}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '75% center',
    backgroundSize: '23%',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

interface IProps {
  offerFacilityData: OfferFacility
  formattedDepartmentList: any
  departmentList: MemberAllocations[]
  isProductDisable: boolean
}

const ProductDepartmentMemberPool: React.FC<IProps> = ({
  offerFacilityData,
  formattedDepartmentList,
  departmentList,
  isProductDisable,
}: IProps) => {
  const handleChangeSwitch = (member: MemberAllocations) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let changedMember: MemberAllocations | undefined = offerFacilityData.memberAllocations.find(
      (currentMember: MemberAllocations) => currentMember.memberPool === member.memberPool,
    )
    if (changedMember) {
      if (event.target.checked) {
        if (member.allocationType === null) {
          changedMember.allocationType = ALLOCATION_TYPE_UNLIMITED
          changedMember.allocation = null
        }
      } else {
        changedMember.allocationType = null
        changedMember.allocation = null
      }
      changedMember.enabled = event.target.checked
    }
    if (member.memberPool === 'Staff_All') {
      offerFacilityData.memberAllocations.forEach((data: MemberAllocations) => {
        if (data.memberPool !== 'Staff_All') {
          data.enabled = false
          data.allocation = null
          data.allocationType = null
        }
      })
    } else {
      let staffAllMember: MemberAllocations | undefined = offerFacilityData.memberAllocations.find(
        (staffMember: MemberAllocations) => staffMember.memberPool === 'Staff_All',
      )
      if (staffAllMember) {
        staffAllMember.enabled = false
        staffAllMember.allocation = null
        staffAllMember.allocationType = null
      }
    }
    formattedDepartmentList(offerFacilityData)
  }
  const disableAll = () => {
    offerFacilityData.memberAllocations.forEach((member: MemberAllocations) => {
      member.enabled = false
      member.allocation = null
      member.allocationType = null
    })
    formattedDepartmentList(offerFacilityData)
  }
  const generateBaysForTotalCapacity = (allocationType: string | null) => {
    let capacityArr = []
    if (allocationType === ALLOCATION_TYPE_FIXED || allocationType === ALLOCATION_TYPE_UNLIMITED) {
      capacityArr = Array.from({ length: offerFacilityData.allocation }, (_, index) => (index + 1).toString())
      capacityArr = ['No Limit'].concat(capacityArr)
    } else {
      capacityArr = Array.from({ length: 100 }, (_, index) => (index + 1).toString()) // For Percentage Type
      capacityArr = ['No Limit'].concat(capacityArr)
    }
    return capacityArr
  }
  const generateValueForTotalCapacity = (allocationType: string | null, allocation: number | null) => {
    if (allocationType === ALLOCATION_TYPE_FIXED || allocationType === ALLOCATION_TYPE_PERCENTAGE) {
      return allocation?.toString()
    } else if (allocationType === ALLOCATION_TYPE_UNLIMITED) {
      return 'No Limit'
    }
    return 'No Limit'
  }
  const handleChangeAutocompleteForAllocationType = (_event: any, value: string | null, member: MemberAllocations) => {
    if (value) {
      offerFacilityData.memberAllocations.forEach((m: any) => {
        if (member.memberPool === m.memberPool) {
          if (value === 'No Limit') {
            m.allocationType = ALLOCATION_TYPE_UNLIMITED
            m.allocation = null
          } else {
            if (member.allocationType === ALLOCATION_TYPE_UNLIMITED) {
              let fixedTypeAllocation = offerFacilityData.memberAllocations.find(
                (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_FIXED,
              )
              let percentageTypeAllocation = offerFacilityData.memberAllocations.find(
                (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_PERCENTAGE,
              )
              if (fixedTypeAllocation) {
                m.allocationType = ALLOCATION_TYPE_FIXED
              } else if (percentageTypeAllocation) {
                m.allocationType = ALLOCATION_TYPE_PERCENTAGE
              } else {
                m.allocationType = ALLOCATION_TYPE_FIXED
              }
            } else {
              m.allocationType = member.allocationType
            }
            m.allocation = parseInt(value)
          }
          formattedDepartmentList(offerFacilityData)
        }
      })
    }
  }
  const identifyTypeSymbol = () => {
    let fixedTypeAllocation = offerFacilityData.memberAllocations.find(
      (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_FIXED,
    )
    let percentageTypeAllocation = offerFacilityData.memberAllocations.find(
      (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_PERCENTAGE,
    )
    if (fixedTypeAllocation) {
      return ' bays'
    } else if (percentageTypeAllocation) {
      return '%'
    } else {
      return ' bays'
    }
  }
  const getMemberPoolFormattedName = (memberPool: string) => {
    switch (memberPool) {
      case 'Staff_All':
        return 'All Staff'
      case 'Staff_Executive':
        return 'Executive'
      case 'Staff_Mobile':
        return 'Mobile'
      case 'Staff_Management':
        return 'Management'
      case 'Staff_Office':
        return 'Office'
      default:
        return ''
    }
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="enable-options multi-dept" marginTop="20px">
          {isProductDisable ? (
            <p className="inactive-enable-all"> Disable All </p>
          ) : (
            <p className="enable-all" onClick={() => disableAll()}>
              Disable All
            </p>
          )}
          <p className="optional-limit">Optional limit per member pool</p>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {departmentList.length > 0 &&
            departmentList
              .sort((a: any, b: any) => {
                return (
                  MEMBER_POOL_NAME_SORTING_ARR.indexOf(a.memberPool) -
                  MEMBER_POOL_NAME_SORTING_ARR.indexOf(b.memberPool)
                )
              })
              .map((member: MemberAllocations, index: any) => {
                return (
                  <Box className="switch-card" key={'index' + index}>
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <FormGroup>
                          <FormControlLabel
                            className="parking-switch"
                            disabled={isProductDisable}
                            control={
                              <IOSSwitch
                                checked={member.enabled ? member.enabled : false}
                                onChange={handleChangeSwitch(member)}
                              />
                            }
                            label={member.memberPool ? getMemberPoolFormattedName(member.memberPool) : ''}
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </Grid>
                      {member.enabled && (
                        <Grid item xs={3} className="flex-aligned capacity-limit-drop-down">
                          <Autocomplete
                            clearIcon={null}
                            disabled={isProductDisable}
                            value={generateValueForTotalCapacity(member.allocationType, member.allocation)}
                            onChange={(event, value) => handleChangeAutocompleteForAllocationType(event, value, member)}
                            id={'autocomplete-panel' + index}
                            getOptionLabel={(option) => {
                              if (member.allocationType === ALLOCATION_TYPE_FIXED) {
                                if (option === 'No Limit') {
                                  return option
                                } else {
                                  return option + ' bays'
                                }
                              }
                              if (member.allocationType === ALLOCATION_TYPE_PERCENTAGE) {
                                if (option === 'No Limit') {
                                  return option
                                } else {
                                  return option + '%'
                                }
                              }
                              if (member.allocationType === ALLOCATION_TYPE_UNLIMITED) {
                                if (option === 'No Limit') {
                                  return option
                                } else {
                                  return option
                                }
                              }
                              return option
                            }}
                            renderOption={(props, option) => {
                              let tempOption: string = ''
                              if (member.allocationType === ALLOCATION_TYPE_FIXED) {
                                if (option === 'No Limit') {
                                  tempOption = option
                                } else {
                                  tempOption = option + ' bays'
                                }
                              }
                              if (member.allocationType === ALLOCATION_TYPE_PERCENTAGE) {
                                if (option === 'No Limit') {
                                  tempOption = option
                                } else {
                                  tempOption = option + '%'
                                }
                              }
                              if (member.allocationType === ALLOCATION_TYPE_UNLIMITED) {
                                if (option === 'No Limit') {
                                  tempOption = option
                                } else {
                                  tempOption = option + identifyTypeSymbol()
                                }
                              }
                              return (
                                <Box component="li" {...props}>
                                  {tempOption}
                                </Box>
                              )
                            }}
                            options={generateBaysForTotalCapacity(member.allocationType)}
                            sx={{ width: 140 }}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )
              })}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default ProductDepartmentMemberPool
