import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId } from '../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../pages/auth/types'
import { setNotification } from '../../store/common/notifications/action'
import { Form } from 'react-final-form'
import { RadioButtons } from '../final-form/Radios'
import { TextField } from '../final-form/TextField'
import { ValidationErrors } from 'final-form'
import { ErrorResponse, Lifecycle } from '../../common/types'
import { fetchAggregate, fetchAggregates, insertAggregate, updateAggregate } from '../../common/axios-action'
import { AGGREGATE_AUTO_INVITATION } from '../../common/aggregate.types'
import { RootState } from '../../store'
import { NotificationType } from '../../store/common/notifications/types'
import LoadingInDiv from '../ui/Loading/LoadingInDiv'
import {
  AUTO_INVITATION_API_RESOURCE_PATH,
  MemberAutoInvitation,
} from '../../pages/settings/users/self-registration/auto-invitation/types'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    fontWeight: 'bold',
    height: 50,
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '800px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
  closeIcon: {
    marginTop: -36,
    textAlign: 'right',
    marginRight: -36,
    cursor: 'pointer',
  },
}))

export const resetValue: MemberAutoInvitation = {
  id: null,
  code: null,
  clientRef: null,
  name: null,
  invitationCode: null,
  invitationUrl: null,
  organisationType: null,
  operatorId: null,
  autoApprove: null,
  lifecycle: null,
  autoApproveStr: null,
  carParkTenantId: null,
}

interface IProps {
  display: boolean
  hideModel: () => void
  id: string | null
}

const AddSelfRegistrationModelForm: React.FC<IProps> = (props) => {
  const classes = useStyle()
  const { display, hideModel, id } = props

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [initialValue, setInitialValue] = useState<MemberAutoInvitation>()

  const { inserting, invitation, inserted, idOnly, error, loadingOne, updating, updated, domains } = useSelector(
    (state: RootState) => ({
      error: state.autoInvitationReducer.error,
      invitation: state.autoInvitationReducer.aggregate,
      inserting: state.autoInvitationReducer.inserting,
      inserted: state.autoInvitationReducer.inserted,
      updating: state.autoInvitationReducer.updating,
      updated: state.autoInvitationReducer.updated,
      idOnly: state.autoInvitationReducer.idOnly,
      loadingOne: state.autoInvitationReducer.loadingOne,
      domains: state.emailDomainReducer.aggregates,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (id !== null && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<MemberAutoInvitation>(
          AGGREGATE_AUTO_INVITATION,
          AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          id,
        ),
      )
    }
  }, [dispatch, id, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (error) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(error))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError.message ? firstError.message : firstError.code.substring(0, 41),
          ]),
        )
      }
    }
  }, [error, dispatch])

  useEffect(() => {
    if (invitation) {
      if (invitation.autoApprove) {
        invitation.autoApproveStr = 'true'
      } else {
        invitation.autoApproveStr = 'false'
      }
      setInitialValue(invitation)
    }
  }, [invitation])

  useEffect(() => {
    if (inserted && idOnly && currentOperatorId && currentOrganisationId) {
      dispatch(setNotification(NotificationType.INFO, [' Your self registration link has been added']))
      dispatch(
        fetchAggregates<MemberAutoInvitation>(
          AGGREGATE_AUTO_INVITATION,
          AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            page: 0,
            size: 1000,
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inserted, idOnly, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (updated && currentOperatorId && currentOrganisationId) {
      dispatch(setNotification(NotificationType.INFO, [' Your self registration link has been updated']))
      dispatch(
        fetchAggregates<MemberAutoInvitation>(
          AGGREGATE_AUTO_INVITATION,
          AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            page: 0,
            size: 1000,
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updated, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (inserted || updated) {
      onClickHideModel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inserted, updated])

  const onClickHideModel = () => {
    setInitialValue(resetValue)
    onResetForm()
    hideModel()
  }

  const onSubmit = (values: MemberAutoInvitation) => {
    const errors: ValidationErrors = {}

    if (validateBeforeSubmit(values, errors)) {
      return errors
    } else {
      if (currentOperatorId && currentOrganisationId) {
        values.operatorId = currentOperatorId
        values.autoApprove = values.autoApproveStr === 'true' ? true : false
        values.carParkTenantId = currentOrganisationId

        if (id) {
          dispatch(
            updateAggregate<MemberAutoInvitation>(
              AGGREGATE_AUTO_INVITATION,
              AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                ':carParkTenantId',
                currentOrganisationId,
              ),
              values,
            ),
          )
        } else {
          values.lifecycle = Lifecycle.Active
          dispatch(
            insertAggregate<MemberAutoInvitation>(
              AGGREGATE_AUTO_INVITATION,
              AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                ':carParkTenantId',
                currentOrganisationId,
              ),
              values,
            ),
          )
        }

        setInitialValue(values)
      }
    }
  }

  const validateBeforeSubmit = (values: MemberAutoInvitation, errors: ValidationErrors) => {
    let isError = false

    if (errors) {
      if (!values.name) {
        errors.name = 'Required'
        isError = true
      }
      if (!values.autoApproveStr) {
        errors.autoApproveStr = 'Required'
        isError = true
      }
    }
    return isError
  }

  let resetForm: () => void

  const onResetForm = () => {
    if (resetForm != null) {
      resetForm()
    }
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <div className={classes.closeIcon} onClick={() => onClickHideModel()}>
          <CloseIcon style={{ color: '#525252' }} fontSize={'small'} />
        </div>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          {(inserting || loadingOne || updating) && <LoadingInDiv />}
          <Form<MemberAutoInvitation>
            onSubmit={onSubmit}
            initialValues={initialValue}
            render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
              resetForm = form.reset
              return (
                <form onSubmit={handleSubmit} id="editForm" noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="modal-h3 no-margin">Add self registration link</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="input-label">Name of link</p>
                      <TextField
                        name="name"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                        helperText={'Only visible to you, not visible to link users.'}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <p className="small-heading" style={{ color: '#000000' }}>
                        How should users of this link be approved to access Shuffle?
                      </p>
                      <RadioButtons
                        name="autoApproveStr"
                        data={[
                          {
                            id: 'true',
                            name: 'Automatically approve users with a verified email address matching this self registration link',
                          },
                          {
                            id: 'false',
                            name: 'Administrator to manually approve all users with a verified email address matching this self registration link',
                          },
                        ]}
                        disabled={false}
                      />
                    </Grid>

                    {domains.values.length === 0 && (
                      <Grid item xs={12}>
                        <p className="small-heading-not-bold" style={{ color: '#000000' }}>
                          After adding this link,&nbsp;
                          <b>
                            please add at least one domain to ensure your self registration links are usable by your
                            staff.
                          </b>
                        </p>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="flex-start">
                        <Button
                          className={classes.shuffleButton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => onClickHideModel()}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          disabled={pristine}
                        >
                          {id ? 'Save' : 'Add'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          />
        </Container>
      </Box>
    </div>
  )
}
export default AddSelfRegistrationModelForm
