import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId, selectShuffleUrl } from '../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../pages/auth/types'
import {
  EMAIL_DOMAIN_API_RESOURCE_PATH,
  MemberEmailDomain,
} from '../../pages/settings/users/self-registration/domains/types'
import { setNotification } from '../../store/common/notifications/action'
import { Form } from 'react-final-form'
import { TextField } from '../final-form/TextField'
import { ValidationErrors } from 'final-form'
import { ErrorResponse, Lifecycle } from '../../common/types'
import { fetchAggregate, fetchAggregates, insertAggregate, updateAggregate } from '../../common/axios-action'
import { AGGREGATE_EMAIL_DOMAIN } from '../../common/aggregate.types'
import { RootState } from '../../store'
import { NotificationType } from '../../store/common/notifications/types'
import LoadingInDiv from '../ui/Loading/LoadingInDiv'
import {
  CHECK_DOMAIN_EXITS_API_RESOURCE_PATH,
  CHECK_NAME_EXITS_API_RESOURCE_PATH,
  ExistsAttribute,
} from '../../store/common/check-exists/types'
import { checkExists } from '../../store/common/check-exists/check-exists'
import firebase from 'firebase/compat/app'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    fontWeight: 'bold',
    height: 50,
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '705px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
  closeIcon: {
    marginTop: -36,
    textAlign: 'right',
    marginRight: -36,
    cursor: 'pointer',
  },
}))

export const resetValue: MemberEmailDomain = {
  id: null,
  code: null,
  clientRef: null,
  name: null,
  domain: null,
  organisationType: null,
  operatorId: null,
  carParkTenantId: null,
  lifecycle: null,
}

interface IProps {
  display: boolean
  hideModel: () => void
  id: string | null
}

const AddDomainModelForm: React.FC<IProps> = (props) => {
  const classes = useStyle()
  const { display, hideModel, id } = props

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [initialValue, setInitialValue] = useState<MemberEmailDomain>()

  const [token, setToken] = useState<string>()

  useEffect(() => {
    if (!token) {
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const token = await firebase.auth().currentUser?.getIdToken()
    setToken(token)
  }

  const shuffleUrl = useSelector(selectShuffleUrl)

  const { inserting, emailDomain, inserted, idOnly, error, loadingOne, updated, updating } = useSelector(
    (state: RootState) => ({
      error: state.emailDomainReducer.error,
      emailDomain: state.emailDomainReducer.aggregate,
      inserting: state.emailDomainReducer.inserting,
      inserted: state.emailDomainReducer.inserted,
      updating: state.emailDomainReducer.updating,
      updated: state.emailDomainReducer.updated,
      idOnly: state.emailDomainReducer.idOnly,
      loadingOne: state.emailDomainReducer.loadingOne,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (id !== null && currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<MemberEmailDomain>(
          AGGREGATE_EMAIL_DOMAIN,
          EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          id,
        ),
      )
    }
  }, [dispatch, id, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (emailDomain) {
      setInitialValue(emailDomain)
    }
  }, [emailDomain])

  useEffect(() => {
    if (error) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(error))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError.message ? firstError.message : firstError.code.substring(0, 41),
          ]),
        )
      }
    }
  }, [error, dispatch])

  useEffect(() => {
    if (inserted && idOnly && currentOperatorId && currentOrganisationId) {
      dispatch(setNotification(NotificationType.INFO, [' Your domain has been added']))
      dispatch(
        fetchAggregates<MemberEmailDomain>(
          AGGREGATE_EMAIL_DOMAIN,
          EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            page: 0,
            size: 1000,
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inserted, idOnly, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (updated && currentOperatorId && currentOrganisationId) {
      dispatch(setNotification(NotificationType.INFO, [' Your domain has been updated']))
      dispatch(
        fetchAggregates<MemberEmailDomain>(
          AGGREGATE_EMAIL_DOMAIN,
          EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            page: 0,
            size: 1000,
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updated, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (inserted || updated) {
      onClickHideModel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inserted, updated])

  const onClickHideModel = () => {
    setInitialValue(resetValue)
    onResetForm()
    hideModel()
  }

  const onSubmit = async (values: MemberEmailDomain) => {
    const errors: ValidationErrors = {}

    if (await validateBeforeSubmit(values, errors)) {
      return errors
    } else {
      if (currentOperatorId && currentOrganisationId) {
        values.operatorId = currentOperatorId
        values.carParkTenantId = currentOrganisationId

        if (id) {
          dispatch(
            updateAggregate<MemberEmailDomain>(
              AGGREGATE_EMAIL_DOMAIN,
              EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                ':carParkTenantId',
                currentOrganisationId,
              ),
              values,
            ),
          )
        } else {
          values.lifecycle = Lifecycle.Active
          dispatch(
            insertAggregate<MemberEmailDomain>(
              AGGREGATE_EMAIL_DOMAIN,
              EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                ':carParkTenantId',
                currentOrganisationId,
              ),
              values,
            ),
          )
        }

        setInitialValue(values)
      }
    }
  }

  const validateBeforeSubmit = async (values: MemberEmailDomain, errors: ValidationErrors) => {
    let isError = false

    if (errors) {
      if (!values.name) {
        errors.name = 'Required'
        isError = true
      }
      if (!values.domain) {
        errors.domain = 'Required'
        isError = true
      } else if (!/^[a-zA-Z0-9.-]+$/.test(values.domain)) {
        errors.domain = 'Not Valid'
        isError = true
      }

      if (currentOperatorId && currentOrganisationId && shuffleUrl && token) {
        if (values.name) {
          let checkNameExists: ExistsAttribute = {
            id: values.id,
            value: values.name,
          }

          let exists = await checkExists(
            checkNameExists,
            EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + CHECK_NAME_EXITS_API_RESOURCE_PATH,
            shuffleUrl,
            token,
          )
          if (exists) {
            errors.name = 'Name already exists'
            isError = true
          }
        }
        if (values.domain) {
          let checkDomainExists: ExistsAttribute = {
            id: values.id,
            value: values.domain,
          }

          let exists = await checkExists(
            checkDomainExists,
            EMAIL_DOMAIN_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + CHECK_DOMAIN_EXITS_API_RESOURCE_PATH,
            shuffleUrl,
            token,
          )
          if (exists) {
            errors.domain = 'Domain already exists'
            isError = true
          }
        }
      }
    }
    return isError
  }

  let resetForm: () => void

  const onResetForm = () => {
    if (resetForm != null) {
      resetForm()
    }
  }

  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <div className={classes.closeIcon} onClick={() => onClickHideModel()}>
          <CloseIcon style={{ color: '#525252' }} fontSize={'small'} />
        </div>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          {(inserting || updating || loadingOne) && <LoadingInDiv />}
          <Form<MemberEmailDomain>
            onSubmit={onSubmit}
            initialValues={initialValue}
            render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
              resetForm = form.reset
              return (
                <form onSubmit={handleSubmit} id="editForm" noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="modal-h3 no-margin">Add domain</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="input-label">Name of domain</p>
                      <TextField
                        name="name"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p className="input-label">Domain</p>
                      <TextField
                        name="domain"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                        inputProps={{ maxLength: 61 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="flex-start">
                        <Button
                          className={classes.shuffleButton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => onClickHideModel()}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          disabled={pristine}
                        >
                          {id ? 'Save' : 'Add'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          />
        </Container>
      </Box>
    </div>
  )
}
export default AddDomainModelForm
