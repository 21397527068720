import { AggregateState } from '../../../common/types'
import { AggregateActionTypes } from '../../../common/aggregate.types'

export interface Team {
  id: string
  name: string
}

export type TeamState = AggregateState<Team>
export type TeamActionTypes = AggregateActionTypes<Team>

export const TEAMS_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/teams'
