import { AggregateActionTypes } from '../../common/aggregate.types'
import { AggregateState } from '../../common/types'

// PeakAllocation
export interface PeakAllocation {
  date: string
  capacity: number
  facilityId: string
  peakAllocation: number
  peakAllocationPercent: number
  peakAvailableBays: number
}
export type PeakAllocationState = AggregateState<PeakAllocation>
export type PeakAllocationActionTypes = AggregateActionTypes<PeakAllocation>
export const PEAK_ALLOCATION_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/peak-allocation'

// Daily Peak Allocation
export interface DailyPeakAllocation {
  averageAllocationPercent: number
  facilityId: string
  peakAllocations: PeakAllocation[]
}
export type DailyPeakAllocationState = AggregateState<DailyPeakAllocation>
export type DailyPeakAllocationActionTypes = AggregateActionTypes<DailyPeakAllocation>
export const DAILY_PEAK_ALLOCATION_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/daily-peak-allocation'

// Allocation By Type
export interface AuthorityTypeCountAllocation {
  agreementAuthorityType: string
  count: number
}
export interface AllocationByType {
  date: string
  capacity: number
  authorityTypeCountAllocations: AuthorityTypeCountAllocation[]
}
export enum AgreementAuthorityType {
  EntitlementGuest = 'EntitlementGuest',
  EntitlementAssignment = 'EntitlementAssignment',
  SalesChannelRes = 'SalesChannelRes',
}
export type AllocationByTypeState = AggregateState<AllocationByType>
export type AllocationByTypeActionTypes = AggregateActionTypes<AllocationByType>
export const ALLOCATION_BY_TYPE_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/count-allocation-by-type'

// Allocation Member Count
export interface AllocationMemberCount {
  date: string
  members: number
  pendingApplications: number
}
export type AllocationMemberCountState = AggregateState<AllocationMemberCount>
export type AllocationMemberCountActionTypes = AggregateActionTypes<AllocationMemberCount>
export const ALLOCATION_MEMBER_COUNT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/member-counts'

// Component interfaces
export interface AllocationStatistic {
  utilisationToday: number | string
  averageUtilisation: number | string
  totalBaysAllocated: number | string
  assignments: number | string
  onDemandBookings: number | string
  guestBookings: number | string
  availableBays: number | string
  registeredUsers: number | string
  pendingRegistrations: number | string
}
