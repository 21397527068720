import axios from 'axios'
import { AppThunk } from '../store'
import { postAggregateFailure, postAggregateRequest, postAggregateSuccess } from './aggregate-actions'
import { AggregateTypes } from './aggregate.types'
import { tracedAxiosOperation } from './axios-action'

export const putBasketAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, payload: T, basketId: string): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'insertAggregate.' +
      getState,
      () => {
        return dispatch(postAggregateRequest<T>(aggregateType))
      },
      (response) => {
        const data: T = response.data
        return dispatch(postAggregateSuccess<T>(aggregateType, data))
      },
      (error) => {
        let errorMessage = error.response.data.errors[0].message
        return dispatch(postAggregateFailure<T>(aggregateType, errorMessage))
      },
      (config) => {
        config.headers = { 'basket-id': basketId, ...config.headers }
        return axios.put<T>(resourcePath, payload, config)
      },
    )
  }
