import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/styles'

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'initial',
    textAlign: 'left',
    marginBottom: '-20px !important',
    marginLeft: '-9px',
    marginRight: '-9px',
    background: 'white',
    color: '#525252',
    fontWeight: '400',
    border: '1px solid #e3e3e3',
    fontSize: '0.75rem',
  },
})

export const getTableCell = (data: string, length: number) => {
  if (data.length > length) {
    return (
      <CustomWidthTooltip title={data}>
        <span>{data.substring(0, length)}...</span>
      </CustomWidthTooltip>
    )
  } else {
    return data.substring(0, length)
  }
}
