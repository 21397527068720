import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CircularProgress, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import { addDays, format, isSameDay } from 'date-fns'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import gateIcon from '../../assets/svg/icon-gate.svg'
import usersIcon from '../../assets/svg/ws-b2b-icon-staff.svg'
import searchFieldIcon from '../../assets/svg/ws-sml-icon-search-field.svg'
import backIcon from '../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import crossFieldIcon from '../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { resetAggregate } from '../../common/aggregate-actions'
import { AGGREGATE_ACTIVE_AGREEMENTS, AGGREGATE_MY_LIST_TENANCIES } from '../../common/aggregate.types'
import { fetchAggregates } from '../../common/axios-action'
import { getAgreementAuthorityTypeFormattedName, getEntryStatusFormattedName } from '../../common/utility'
import { selectCurrentOperatorId } from '../../config/app/reducers'
import { useDebounce } from '../../hook/useDebounce'
import usePagination from '../../hook/usePagination'
import { RootState } from '../../store'
import { ACTIVE_AGREEMENTS_API_RESOURCE_PATH, ActiveAgreement } from '../../store/common/active-agreements/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../store/common/tenancies/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../auth/types'
import { AgreementAuthorityType } from '../home/types'
import {
  ROUTE_OPERATIONS,
  ROUTE_OPERATIONS_ASSIGNED_BOOKING_SUMMARY,
  ROUTE_OPERATIONS_FORCE_GATE_OPEN,
  ROUTE_OPERATIONS_GUEST_BOOKING_SUMMARY,
  ROUTE_OPERATIONS_ON_DEMAND_BOOKING_SUMMARY,
} from '../util/routes'

const useStyles = makeStyles((theme) => ({
  gridTopMargin: {
    paddingTop: '17px',
  },
}))

enum DisplayMode {
  Initial = 'Initial',
  Table = 'Table',
  Message = 'Message',
}

interface IProps {}

const ControlCentre: React.FC<IProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [page, rowsPerPage, sortDirection, sortBy, handleChangePage] = usePagination()

  const [userName, setUserName] = useState<string>('')
  const [authorityType, setAuthorityType] = useState<string>('')
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.Initial)

  const debouncedSearchForUserName: string = useDebounce<string>(userName, 600)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)

  const [isForceGateOpenAccess, setIsForceGateOpenAccess] = useState<boolean>(false)

  const { activeAgreements, activeAgreementsTotalCount, loadingList, tenancies } = useSelector((state: RootState) => ({
    activeAgreements: state.activeAgreementsReducer.aggregates.values,
    activeAgreementsTotalCount: state.activeAgreementsReducer.aggregates.totalCount,
    loadingList: state.activeAgreementsReducer.loadingList,
    tenancies: state.myListTenancyReducer.aggregates.values,
  }))

  useEffect(() => {
    setAuthorityType('')
    handleChangePage(null, 1)
    // eslint-disable-next-line
  }, [debouncedSearchForUserName])

  useEffect(() => {
    handleChangePage(null, 1)
    // eslint-disable-next-line
  }, [authorityType])

  useEffect(() => {
    if (debouncedSearchForUserName) {
      getActiveAgreements()
    } else {
      dispatch(resetAggregate<ActiveAgreement>(AGGREGATE_ACTIVE_AGREEMENTS))
    }
    // eslint-disable-next-line
  }, [debouncedSearchForUserName, authorityType, page])

  // Get tenancies
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<Tenancy>(
          AGGREGATE_MY_LIST_TENANCIES,
          TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/my-list',
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // Check force gate open permission
  useEffect(() => {
    if (isTenantUser) {
      let forcedAccessTenancyList = tenancies.filter((t: Tenancy) => t.forcedAccess === true)
      if (forcedAccessTenancyList.length > 0) {
        setIsForceGateOpenAccess(true)
      } else {
        setIsForceGateOpenAccess(false)
      }
    } else {
      setIsForceGateOpenAccess(true)
    }
  }, [isTenantUser, tenancies])

  // Check display mode
  useEffect(() => {
    if (debouncedSearchForUserName) {
      if (authorityType) {
        setDisplayMode(DisplayMode.Table)
      } else {
        if (activeAgreements.length > 0) {
          setDisplayMode(DisplayMode.Table)
        } else {
          setDisplayMode(DisplayMode.Message)
        }
      }
    } else {
      setDisplayMode(DisplayMode.Initial)
    }
    // eslint-disable-next-line
  }, [debouncedSearchForUserName, authorityType, activeAgreements])

  // Get active agreements
  const getActiveAgreements = () => {
    if (currentOperatorId && currentOrganisationId) {
      let params: any = {
        name: debouncedSearchForUserName,
        page: page - 1,
        size: rowsPerPage,
        sortBy: 'stay_start_date_time',
        sortDirection: 'asc',
      }
      if (authorityType) {
        params = { ...params, agreementAuthorityType: authorityType }
      }
      dispatch(
        fetchAggregates<ActiveAgreement>(
          AGGREGATE_ACTIVE_AGREEMENTS,
          ACTIVE_AGREEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          params,
        ),
      )
    }
  }

  const handleChangeAuthorityType = (event: SelectChangeEvent) => {
    setAuthorityType(event.target.value)
  }

  const getFormattedDateTime = (dateTime: string) => {
    let text: string = ''
    let today = new Date()
    let tomorrow = addDays(today, 1)
    let startDate = new Date(dateTime)
    const isToday = isSameDay(today, startDate)
    const isTomorrow = isSameDay(tomorrow, startDate)
    if (isToday) {
      text = 'Today ' + format(startDate, 'hh:mmaaa')
    } else if (isTomorrow) {
      text = 'Tomorrow ' + format(startDate, 'hh:mmaaa')
    } else {
      text = format(Date.parse(dateTime), 'E dd MMM hh:mmaaa')
    }
    return text
  }

  const onClickTransactionRow = (agreement: ActiveAgreement) => {
    if (agreement.agreementAuthorityType === AgreementAuthorityType.SalesChannelRes) {
      navigate(ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_ON_DEMAND_BOOKING_SUMMARY, {
        state: { orderId: agreement.orderId },
      })
    }
    if (agreement.agreementAuthorityType === AgreementAuthorityType.EntitlementAssignment) {
      navigate(ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_ASSIGNED_BOOKING_SUMMARY, {
        state: { entitlementId: agreement.id },
      })
    }
    if (agreement.agreementAuthorityType === AgreementAuthorityType.EntitlementGuest) {
      navigate(ROUTE_OPERATIONS + '/' + ROUTE_OPERATIONS_GUEST_BOOKING_SUMMARY, {
        state: { entitlementId: agreement.id },
      })
    }
  }

  return (
    <div className="page-content-wrapper">
      {/* Left Section */}
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <a className="back-icon" href="/home">
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Control centre
                  </a>
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="responsive-content-wrapper full-width">
              <Grid item xs={12}>
                <TextField
                  name="memberName"
                  value={userName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setUserName(event.target.value)
                  }}
                  placeholder="Search by name or email address"
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    className: 'shuffleInput search-input-text-field',
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className="search-field-icon" src={searchFieldIcon} alt="searchFieldIcon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start" className="cross-icon">
                        {userName && (
                          <img
                            className="cross-field-icon"
                            src={crossFieldIcon}
                            alt="img"
                            onClick={(event) => setUserName('')}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* Right Section */}
      <Container className="page-content-body-wrapper ondemand-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            {loadingList ? (
              <Grid item xs={12} className="content-panel">
                <Grid container spacing={0}>
                  <Grid item xs={12} className="landing">
                    <CircularProgress color="primary" size={30} />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                {displayMode === DisplayMode.Initial && (
                  <Grid item xs={12} className="content-panel">
                    <Grid container spacing={0}>
                      <Grid item xs={12} className="landing">
                        <img className="control-users-icon" src={usersIcon} alt="usersIcon" />
                        <p className="no-users">
                          Search for a user to update their entry
                          <br />
                          status or open a gate for them.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {displayMode === DisplayMode.Table && (
                  <Grid item xs={12} className="content-panel">
                    <Grid container spacing={2} className="desktop-only">
                      <Grid item xs={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <InputLabel className="select-heading">Parker type</InputLabel>
                            <Select
                              className="shuffle-select"
                              IconComponent={ExpandMoreIcon}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              }}
                              value={authorityType}
                              name="authorityType"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              displayEmpty
                              onChange={handleChangeAuthorityType}
                            >
                              <MenuItem value="" className="selectBold">
                                Any parker type
                              </MenuItem>
                              <MenuItem value="EntitlementAssignment">Assigned</MenuItem>
                              <MenuItem value="EntitlementGuest">Guest</MenuItem>
                              <MenuItem value="SalesChannelRes">On-demand</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={12} className={classes.gridTopMargin}>
                        <table id="transactions">
                          <thead>
                            <tr>
                              <th>Parker</th>
                              <th>Type</th>
                              <th>Entry</th>
                              <th className="desktop-only">Location</th>
                              <th className="desktop-only">Entry status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeAgreements.map((agreement: ActiveAgreement) => {
                              return (
                                <tr key={agreement.id}>
                                  <td className="transaction-id">
                                    <span onClick={() => onClickTransactionRow(agreement)}>
                                      {agreement.firstName + ' ' + agreement.lastName}
                                    </span>
                                  </td>
                                  <td>{getAgreementAuthorityTypeFormattedName(agreement.agreementAuthorityType)}</td>
                                  <td>{getFormattedDateTime(agreement.startDateTime)}</td>
                                  <td className="desktop-only">{agreement.facilityName}</td>
                                  <td className="desktop-only">{getEntryStatusFormattedName(agreement.entryStatus)}</td>
                                </tr>
                              )
                            })}
                            {activeAgreements.length === 0 && (
                              <tr>
                                <td colSpan={6} align="center">
                                  No Records Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Pagination
                        count={activeAgreementsTotalCount ? Math.ceil(activeAgreementsTotalCount / rowsPerPage) : 1}
                        page={page}
                        onChange={handleChangePage}
                        className="pagination"
                        color="primary"
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </Grid>
                )}
                {displayMode === DisplayMode.Message && (
                  <Grid item xs={12} className="content-panel">
                    <Grid container spacing={0}>
                      <Grid item xs={12} className="no-results-found">
                        <p className="no-users">
                          No users matching that name were found.
                          <br />
                          Try your search again or you can force the gate open.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <div className="force-gate-open-wrapper">
              {isForceGateOpenAccess ? (
                <img className="force-gate-icon" src={gateIcon} alt="gateIcon" />
              ) : (
                <img className="force-gate-icon disable" src={gateIcon} alt="gateIcon" />
              )}
              <p>
                If you are unable to locate a specific user you can force a gate open.
                <br />
                {isForceGateOpenAccess ? (
                  <a href={ROUTE_OPERATIONS_FORCE_GATE_OPEN}>Force gate open</a>
                ) : (
                  <span className="disable">Force gate open</span>
                )}
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ControlCentre
