import { isBefore, isEqual, isValid } from 'date-fns'
import { StartAndEndBlockOut, StartAndEndBlockOutError } from './types'

export const checkDateAndTimeError = (
  interval: StartAndEndBlockOut,
  name: string,
  startAndEndBlockOutError: StartAndEndBlockOutError,
) => {
  let tempState: any = { ...startAndEndBlockOutError }
  let today = new Date().setHours(0, 0, 0, 0)
  function checkValidationForEndTime() {
    if (interval.startDate && interval.endDate && interval.startTime && interval.endTime) {
      let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
      let endDate = new Date(interval.endDate).setHours(0, 0, 0, 0)
      let isStartDateValid = isValid(startDate)
      let isEndDateValid = isValid(endDate)
      tempState['endTime'] = ''
      if (isStartDateValid && isEndDateValid) {
        if (isEqual(startDate, endDate)) {
          let startTime = new Date(interval.startTime)
          let endTime = new Date(interval.endTime)
          let statTimeSlot = startTime.getHours() * 60 + startTime.getMinutes()
          let endTimeSlot = endTime.getHours() * 60 + endTime.getMinutes()
          if (endTimeSlot <= statTimeSlot) {
            tempState['endTime'] = 'End time must be later than start time'
          }
        }
      }
    }
  }
  switch (name) {
    case 'startDate':
      if (interval.startDate) {
        let isStartDateValid = isValid(interval.startDate)
        if (!isStartDateValid) {
          tempState[name] = 'Start date is invalid'
        } else {
          let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
          if (isBefore(startDate, today)) {
            tempState[name] = 'Start date must be later than today'
          } else {
            tempState[name] = ''
          }
          // For end date
          if (interval.endDate) {
            let isEndDateValid = isValid(interval.endDate)
            if (isEndDateValid) {
              let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
              let endDate = new Date(interval.endDate).setHours(0, 0, 0, 0)
              if (isBefore(endDate, startDate)) {
                tempState['endDate'] = 'End date must be later than start date'
              } else {
                tempState['endDate'] = ''
              }
            }
          }
          // For end date
          // For end time
          checkValidationForEndTime()
          // For end time
        }
      } else {
        tempState[name] = 'Start date is required'
      }
      break
    case 'startTime':
      if (interval.startTime) {
        let isStartTimeValid = isValid(interval.startTime)
        if (!isStartTimeValid) {
          tempState[name] = 'Start time is invalid'
        } else {
          tempState[name] = ''
          // For end time
          checkValidationForEndTime()
          // For end time
        }
      } else {
        tempState[name] = 'Start time is required'
      }
      break
    case 'endDate':
      if (interval.endDate) {
        let isEndDateValid = isValid(interval.endDate)
        if (!isEndDateValid) {
          tempState[name] = 'End date is invalid'
        } else {
          tempState[name] = ''
          if (interval.startDate) {
            let isStartDateValid = isValid(interval.startDate)
            if (isStartDateValid) {
              let startDate = new Date(interval.startDate).setHours(0, 0, 0, 0)
              let endDate = new Date(interval.endDate).setHours(0, 0, 0, 0)
              if (isBefore(endDate, startDate)) {
                tempState[name] = 'End date must be later than start date'
              }
            }
          }
          // For end time
          checkValidationForEndTime()
          // For end time
        }
      } else {
        tempState[name] = 'End date is required'
      }
      break
    case 'endTime':
      if (interval.endTime) {
        let isEndTimeValid = isValid(interval.endTime)
        if (!isEndTimeValid) {
          tempState[name] = 'End time is invalid'
        } else {
          tempState[name] = ''
          // For end time
          checkValidationForEndTime()
          // For end time
        }
      } else {
        tempState[name] = 'End time is required'
      }
      break
  }
  return tempState
}
