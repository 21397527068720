import { Button, CircularProgress, Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_AGREEMENTS_DETAIL,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_LIST_TRANSACTION_SUMMARY,
  AGGREGATE_MEMBER,
  AGGREGATE_MY_LIST_TENANCIES,
} from '../../../common/aggregate.types'
import { chooseUrl, fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { AgreementStatus, OrderStatus, PaymentChannel } from '../../../common/types'
import {
  formatNumberWithDecimalPlace,
  getAgreementStatusFormattedName,
  getCurrentInstant,
  getEntryStatusFormattedName,
} from '../../../common/utility'
import ConfirmationModel from '../../../components/model/ConfirmationModel'
import ControlCentreEntryStatusModal from '../../../components/model/ControlCentreEntryStatusModal'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { authorizationHeaderConfig } from '../../../config/axios/axios'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { TENANCY_API_RESOURCE_PATH, Tenancy } from '../../../store/common/tenancies/types'
import {
  TRANSACTION_SUMMARY_API_RESOURCE_PATH,
  TransactionSummaryList,
} from '../../../store/common/transaction-summary/types'
import { ORDER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH } from '../../../store/common/update-entry-status/types'
import { isCarParkTenantUser, selectCurrentOrganisationId } from '../../auth/types'
import { MemberForm, TENANT_MEMBER_API_RESOURCE_PATH } from '../../settings/users/active-users/types'
import { getTableCell } from '../../util/tooltip'
import { timeStampToDate } from '../../util/util'
import { BookingData } from '../book-parking/types'
import {
  AGREEMENTS_DETAIL_API_RESOURCE_PATH,
  AgreementsDetailState,
  CANCEL_FAILED_MESSAGE,
  ORDER_CANCEL_ADMIN_API_RESOURCE_PATH,
  ORDER_CANCEL_AND_REFUND_ADMIN_API_RESOURCE_PATH,
  getBookingCost,
  getMemberNameFromUserRef,
  getRefundLabel,
} from './types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
    maxWidth: '145px',
  },
}))

interface IProps {}
const TransactionDetail: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const isTenantUser = useSelector(isCarParkTenantUser)

  const [openEntryStatusModal, setOpenEntryStatusModal] = useState<boolean>(false)
  const [entryStatusUpdateUrl, setEntryStatusUpdateUrl] = useState<string | null>(null)
  const [isForcedAccess, setIsForcedAccess] = useState<boolean | undefined>(undefined)

  const [isAllRequestCompleted, setIsAllRequestCompleted] = useState<boolean>(false)

  // Modal
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
  const [isChangeDisabled, setIsChangeDisabled] = useState<boolean>(true)
  const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(true)

  const {
    agreementDetail,
    agreementDetailLoading,
    memberData,
    departmentTeamsList,
    tenancies,
    tenanciesSuccess,
    user,
    shuffleApiConfig,
    isAgreementsDetailRequestCompleted,
    isManageUserRequestCompleted,
    isDepartmentTeamRequestCompleted,
    transactionSummaryList,
  } = useSelector((state: RootState) => ({
    agreementDetail: state.agreementsDetailReducer.aggregate,
    agreementDetailLoading: state.agreementsDetailReducer.loadingOne,
    memberData: state.manageUserReducer.aggregate,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    tenancies: state.myListTenancyReducer.aggregates.values,
    tenanciesSuccess: state.myListTenancyReducer.getTenanciesSuccess,
    user: state.authReducer.user,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
    isAgreementsDetailRequestCompleted: state.agreementsDetailReducer.updatedApplicationStatus,
    isManageUserRequestCompleted: state.manageUserReducer.updatedApplicationStatus,
    isDepartmentTeamRequestCompleted: state.departmentsTeamsReducer.updatedApplicationStatus,
    transactionSummaryList: state.transactionSummaryReducer.aggregates.values,
  }))

  // Get carpark tenancies
  useEffect(() => {
    if (!tenanciesSuccess) {
      if (currentOperatorId && currentOrganisationId) {
        dispatch(
          fetchAggregates<Tenancy>(
            AGGREGATE_MY_LIST_TENANCIES,
            TENANCY_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + '/my-list',
            null,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, tenanciesSuccess])

  // Set forced access
  useEffect(() => {
    if (agreementDetail && tenancies) {
      if (isTenantUser) {
        const tenancy = tenancies.find((t: Tenancy) => t.id === agreementDetail.stayAgreements[0].facilityId)
        if (tenancy && tenancy.forcedAccess === true) {
          setIsForcedAccess(true)
        } else {
          setIsForcedAccess(false)
        }
      } else {
        setIsForcedAccess(true)
      }
    }
  }, [agreementDetail, tenancies, isTenantUser])

  // Get agreement details or parking items details
  useEffect(() => {
    if (state) {
      getAgreementDetails()
    } else {
      navigate('/on-demand/transactions')
    }
    // eslint-disable-next-line
  }, [dispatch, currentOperatorId, currentOrganisationId, navigate, state])

  // Set entry status update URL
  useEffect(() => {
    if (state && currentOperatorId && currentOrganisationId && agreementDetail) {
      const { orderId }: any = state
      setEntryStatusUpdateUrl(
        ORDER_UPDATE_ENTRY_STATUS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
          .replace(':orderId', orderId)
          .replace(':agreementId', agreementDetail.stayAgreements[0].id),
      )
    }
  }, [state, currentOperatorId, currentOrganisationId, agreementDetail])

  // Get members
  useEffect(() => {
    if (agreementDetail && currentOperatorId && currentOrganisationId) {
      const memberId = agreementDetail.memberId
      if (memberId) {
        dispatch(
          fetchAggregate<MemberForm>(
            AGGREGATE_MEMBER,
            TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            memberId,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, agreementDetail])

  // Get departments and teams
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // Get transaction summary
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && agreementDetail) {
      let resourcePath: string = TRANSACTION_SUMMARY_API_RESOURCE_PATH
      resourcePath = resourcePath
        .replace(':operatorId', currentOperatorId)
        .replace(':carParkTenantId', currentOrganisationId)
        .replace(':memberId', agreementDetail.memberId)
        .replace(':orderId', agreementDetail.orderId)

      dispatch(fetchAggregates<TransactionSummaryList>(AGGREGATE_LIST_TRANSACTION_SUMMARY, resourcePath, null))
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, agreementDetail])

  // Enable-disable change & cancel buttons
  useEffect(() => {
    if (agreementDetail) {
      if (
        agreementDetail.finalAmendment ||
        agreementDetail.stayAgreements[0].utilised ||
        getCurrentInstant() > agreementDetail.stayAgreements[0].singleStay.finishInstant
      ) {
        setIsChangeDisabled(true)
      } else {
        setIsChangeDisabled(false)
      }
      if (
        agreementDetail.stayAgreements[0].utilised ||
        getCurrentInstant() > agreementDetail.stayAgreements[0].singleStay.finishInstant
      ) {
        setIsCancelDisabled(true)
      } else {
        setIsCancelDisabled(false)
      }
    }
  }, [agreementDetail])

  // Manage loading
  useEffect(() => {
    if (isAgreementsDetailRequestCompleted && isManageUserRequestCompleted && isDepartmentTeamRequestCompleted) {
      setIsAllRequestCompleted(true)
    } else {
      setIsAllRequestCompleted(false)
    }
  }, [isAgreementsDetailRequestCompleted, isManageUserRequestCompleted, isDepartmentTeamRequestCompleted])

  // On unmount
  useEffect(() => {
    return () => {
      resetData()
    }
    // eslint-disable-next-line
  }, [])

  const getAgreementDetails = () => {
    const { orderId }: any = state
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregate<AgreementsDetailState>(
          AGGREGATE_AGREEMENTS_DETAIL,
          AGREEMENTS_DETAIL_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + `/${orderId}`,
          null,
        ),
      )
    }
  }

  const getEntryStatus = (): string => {
    if (agreementDetail) {
      let entryStatus = agreementDetail.stayAgreements[0].entryStatus
      return getEntryStatusFormattedName(entryStatus)
    } else {
      return ''
    }
  }

  const getButtonMessage = (): string => {
    let agreementStatus = agreementDetail ? agreementDetail.stayAgreements[0].agreementStatus : ''
    return getAgreementStatusFormattedName(agreementStatus) + " bookings can't be changed or cancelled."
  }

  // User section
  const getDepartmentNameAndTeamName = (departmentId: string, teamId: string | null): string => {
    const department: DepartmentTeamListItemDto | undefined = departmentTeamsList.find(
      (dept: DepartmentTeamListItemDto) => dept.id === departmentId,
    )
    let name: string
    if (department) {
      if (teamId === null) {
        name = department.name
      } else {
        const team: any = department.teams.find((team) => team.id === teamId)
        if (team) {
          name = team.name
        } else {
          name = ''
        }
      }
      return name
    }
    return ''
  }

  // Parking section
  const getAgreementFacilityName = () => {
    return agreementDetail ? agreementDetail.stayAgreements[0].facilityName : ''
  }

  const getAgreementStayDuration = () => {
    if (agreementDetail) {
      let agreement = agreementDetail.stayAgreements[0]
      let startDateTime = format(Date.parse(agreement.singleStay.startDateTime), 'E dd MMMM hh:mmaaa')
      let finishDateTime = format(Date.parse(agreement.singleStay.finishDateTime), 'E dd MMMM hh:mmaaa')
      return startDateTime + ' - ' + finishDateTime
    } else {
      return ''
    }
  }

  // Entry Status Modal
  const handleEntryStatusModalOpen = () => {
    setOpenEntryStatusModal(true)
  }

  const handleEntryStatusModalClose = () => {
    setOpenEntryStatusModal(false)
  }

  // Manage entry status section

  const getOrderStatus = (): string => {
    return agreementDetail ? agreementDetail.orderStatus : ''
  }

  const getAgreementStatus = (): string => {
    return agreementDetail ? agreementDetail.stayAgreements[0].agreementStatus : ''
  }

  const getValidBookingAmendCancelButtonMessage = () => {
    let message: string = ''
    if (agreementDetail) {
      if (agreementDetail.finalAmendment) {
        message = 'This booking has already been changed via the Portal.'
      } else if (agreementDetail.stayAgreements[0].utilised) {
        message = 'Booking can’t be cancelled or changed as parking has started.'
      } else if (getCurrentInstant() > agreementDetail.stayAgreements[0].singleStay.finishInstant) {
        message = 'Booking can’t be cancelled or changed as the booking has ended.'
      }
    }
    return message
  }

  const getRefundLink = () => {
    if (agreementDetail) {
      if (agreementDetail.refundTransaction) {
        return <p className="transaction-link disabled">Refund user</p>
      } else {
        if (
          agreementDetail.paymentTransaction &&
          agreementDetail.paymentTransaction.paymentChannel === PaymentChannel.Goodwill
        ) {
          return <p className="transaction-link disabled">Refund user</p>
        } else {
          if (getBookingCost(agreementDetail) > 0) {
            return (
              <p className="transaction-link" onClick={onClickRefundLink}>
                Refund user
              </p>
            )
          } else {
            return <p className="transaction-link disabled">Refund user</p>
          }
        }
      }
    } else {
      return <p className="transaction-link disabled">Refund user</p>
    }
  }

  const getPaymentChannel = (): PaymentChannel | null => {
    let channel: PaymentChannel | null = null
    if (agreementDetail) {
      if (agreementDetail.paymentTransaction) {
        channel = agreementDetail.paymentTransaction.paymentChannel
      }
    }
    return channel
  }

  const checkIsBookingRefundable = (): boolean => {
    if (agreementDetail) {
      if (agreementDetail.refundTransaction) {
        return false
      } else {
        if (getBookingCost(agreementDetail) > 0) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  // Cancel modal handler

  const onClickCancel = () => {
    setCancelModalOpen(true)
  }

  const onClickChange = () => {
    if (agreementDetail && memberData) {
      let payload: BookingData = {
        memberId: agreementDetail.memberId,
        firstName: memberData.firstName ? memberData.firstName : '',
        lastName: memberData.lastName ? memberData.lastName : '',
        orderReference: agreementDetail.orderReference,
        memberName: agreementDetail.memberName,
        startDateTime: agreementDetail.stayAgreements[0].singleStay.startDateTime,
        finishDateTime: agreementDetail.stayAgreements[0].singleStay.finishDateTime,
        facilityId: agreementDetail.stayAgreements[0].facilityId,
        relatedParkingItemId: agreementDetail.itemId,
        bookingCost: getBookingCost(agreementDetail),
        isBookingRefundable: checkIsBookingRefundable(),
        orderId: agreementDetail.orderId,
        offerName: agreementDetail.offer.name,
        paymentChannel: getPaymentChannel(),
        createdUserRef: agreementDetail.createdUserRef,
      }
      navigate('/on-demand/change-booking', {
        state: { paramData: payload },
      })
    }
  }

  // API handler

  const onClickRefundLink = () => {
    resetData()
    if (agreementDetail) {
      navigate('/on-demand/transactions/refund-transaction-detail', {
        state: {
          orderId: agreementDetail.orderId,
        },
      })
    }
  }

  const cancelAdmin = async () => {
    if (currentOperatorId && currentOrganisationId && agreementDetail) {
      let resourcePath: string = ''
      if (agreementDetail?.paymentTransaction?.paymentChannel === PaymentChannel.Goodwill) {
        resourcePath = ORDER_CANCEL_AND_REFUND_ADMIN_API_RESOURCE_PATH
      } else {
        resourcePath = ORDER_CANCEL_ADMIN_API_RESOURCE_PATH
      }
      resourcePath = resourcePath
        .replace(':operatorId', currentOperatorId)
        .replace(':carParkTenantId', currentOrganisationId)
        .replace(':memberId', agreementDetail?.memberId)
        .replace(':orderId', agreementDetail?.orderId)
      try {
        let authorization = user!.getIdToken()
        await authorization
          .then((token: any) => {
            let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
            axios
              .post<any>(resourcePath, {}, config)
              .then((response) => {
                dispatch(
                  setNotification(NotificationType.INFO, [
                    `Booking cancelled. An updated confirmation has been emailed to ${agreementDetail?.memberName}.`,
                  ]),
                )
                let isRefundable = checkIsBookingRefundable()
                if (isRefundable) {
                  resetData()
                  if (agreementDetail) {
                    navigate('/on-demand/transactions/refund-confirmation-detail', {
                      state: { orderId: agreementDetail.orderId },
                    })
                  }
                } else {
                  // Get agreement details to refresh on screen
                  getAgreementDetails()
                }
                setCancelModalOpen(false)
              })
              .catch((error: any) => {
                console.error(error)
                setCancelModalOpen(false)
                dispatch(setNotification(NotificationType.ERROR, [CANCEL_FAILED_MESSAGE]))
              })
          })
          .catch((error: any) => {
            console.error(error)
            setCancelModalOpen(false)
            dispatch(setNotification(NotificationType.ERROR, [CANCEL_FAILED_MESSAGE]))
          })
      } catch (err) {
        console.error(err)
        setCancelModalOpen(false)
        dispatch(setNotification(NotificationType.ERROR, [CANCEL_FAILED_MESSAGE]))
      }
    }
  }

  const resetData = () => {
    dispatch(resetAggregate<AgreementsDetailState>(AGGREGATE_AGREEMENTS_DETAIL))
    dispatch(resetAggregate<MemberForm>(AGGREGATE_MEMBER))
    dispatch(resetAggregate<TransactionSummaryList>(AGGREGATE_LIST_TRANSACTION_SUMMARY))
  }

  return (
    <>
      <div className="page-content-wrapper ondemand-section">
        {/* Left side section */}
        <Container className="page-content-header-wrapper">
          <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
            <Grid item xs={3} className="left-col sidebar top-section">
              <Grid container spacing={0} className="responsive-title-wrapper">
                <Grid item xs={12}>
                  <h2 className="on-demand-h2">
                    <a className="back-icon" href="/on-demand/transactions">
                      <img className="icon-back-arrow" src={backIcon} alt="back" />
                      On-demand parking
                    </a>
                  </h2>
                </Grid>
              </Grid>
              {agreementDetail && (
                <Grid container spacing={0} className="responsive-content-wrapper">
                  <Grid item xs={12}>
                    <p style={{ marginBottom: '10px' }}>
                      Transaction ID: {agreementDetail?.orderReference}
                      <br />
                      Booking status: {getAgreementStatusFormattedName(getAgreementStatus())}
                      <br />
                      Entry status: {getEntryStatus()}
                    </p>
                    {getAgreementStatus() &&
                      getAgreementStatus() !== AgreementStatus.Valid &&
                      getAgreementStatus() !== AgreementStatus.Cancelled && (
                        <p className="notifcation-text">{getButtonMessage()}</p>
                      )}
                    {getAgreementStatus() === AgreementStatus.Valid && (
                      <p className="notifcation-text">{getValidBookingAmendCancelButtonMessage()}</p>
                    )}
                  </Grid>
                </Grid>
              )}
              {getAgreementStatus() &&
                getAgreementStatus() !== AgreementStatus.Valid &&
                getAgreementStatus() !== AgreementStatus.Cancelled && (
                  <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                    <Grid item xs={12} className="inline-buttons">
                      <Button
                        className={classes.shuffleButton}
                        disabled
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Change
                      </Button>
                      <Button
                        className={classes.shuffleButton}
                        disabled
                        variant="outlined"
                        color="secondary"
                        size="large"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                )}

              {getAgreementStatus() === AgreementStatus.Valid && (
                <Grid container spacing={1} className="responsive-button-wrapper right-aligned">
                  <Grid item xs={12} className="inline-buttons">
                    <Button
                      className={classes.shuffleButton}
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={isChangeDisabled}
                      onClick={onClickChange}
                    >
                      Change
                    </Button>
                    <Button
                      className={classes.shuffleButton}
                      variant="outlined"
                      color="secondary"
                      size="large"
                      disabled={isCancelDisabled}
                      onClick={onClickCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* Right side section */}
        <Container className="page-content-body-wrapper ondemand-section">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={9} className="right-col">
              {isAllRequestCompleted && agreementDetail ? (
                <Grid item xs={12} className="content-panel" style={{ minHeight: 410 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-10 padding-top-5">About this transaction</h3>
                      {getOrderStatus() === OrderStatus.Cancelled &&
                        getAgreementStatus() === AgreementStatus.Cancelled &&
                        agreementDetail?.orderCancelledInstant && (
                          <p className="cancelled-alert-text">
                            Cancelled by {getMemberNameFromUserRef(agreementDetail?.cancelledBy)} on{' '}
                            {format(timeStampToDate(agreementDetail?.orderCancelledInstant), 'dd MMM yy')}
                          </p>
                        )}
                    </Grid>
                  </Grid>
                  {/* User Section */}
                  <Grid container spacing={2} className="transaction-detail first-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>User</h5>
                        </Grid>
                        <Grid item xs={5}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{agreementDetail?.memberName}</td>
                              </tr>
                              {memberData && (
                                <>
                                  <tr>
                                    <td>{memberData.email}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {memberData.departmentId
                                        ? getDepartmentNameAndTeamName(memberData.departmentId, null)
                                        : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {memberData.departmentId
                                        ? getDepartmentNameAndTeamName(memberData.departmentId, memberData.teamId)
                                        : ''}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        {memberData && (
                          <Grid item xs={3}>
                            <p
                              className="transaction-link"
                              onClick={() =>
                                navigate('/users/manage-users', {
                                  state: { memberData: memberData },
                                })
                              }
                            >
                              Manage user account
                            </p>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Parking Section */}
                  <Grid container spacing={2} className="transaction-detail">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Parking</h5>
                        </Grid>
                        <Grid item xs={8}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{getAgreementFacilityName()}</td>
                              </tr>
                              <tr>
                                <td>{agreementDetail?.offer?.name}</td>
                              </tr>
                              <tr>
                                <td>{getAgreementStayDuration()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Cost Section */}
                  <Grid container spacing={2} className="transaction-detail last-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Cost</h5>
                        </Grid>
                        {!agreementDetailLoading && (
                          <>
                            <Grid item xs={6}>
                              <table id="transaction-detail-table">
                                <tbody>
                                  <tr>
                                    <td>${formatNumberWithDecimalPlace(getBookingCost(agreementDetail), 2)}</td>
                                  </tr>
                                  <tr>{getRefundLabel(agreementDetail)}</tr>
                                </tbody>
                              </table>
                            </Grid>
                            <Grid item xs={2}>
                              {getRefundLink()}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className="content-panel"
                  style={{ minHeight: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <CircularProgress color="primary" size={35} />
                </Grid>
              )}
              {transactionSummaryList.length > 0 && (
                <Grid item xs={12} className="content-panel" style={{ marginTop: '40px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-10 padding-top-5">Transaction Summary</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <table id="transactions">
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Booking entry</th>
                            <th>Booking exit</th>
                            <th>Product</th>
                            <th>Location</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionSummaryList.map((transaction: TransactionSummaryList) => (
                            <tr key={transaction.id}>
                              <td className="transaction-id">
                                <p
                                  className="color-red"
                                  onClick={() => {
                                    resetData()
                                    navigate('/on-demand/transactions/transaction-detail', {
                                      state: {
                                        orderId: transaction.id,
                                      },
                                    })
                                  }}
                                >
                                  {transaction.reference}
                                </p>
                              </td>
                              <td>{format(Date.parse(transaction.stayStartDateTime), 'E dd MMMM hh:mmaaa')}</td>
                              <td>{format(Date.parse(transaction.stayFinishDateTime), 'E dd MMMM hh:mmaaa')}</td>
                              <td>{transaction.productName}</td>
                              <td>{getTableCell(transaction.facilityName, 38)}</td>
                              <td>{getAgreementStatusFormattedName(transaction.agreementStatus)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {getAgreementStatus() === AgreementStatus.Valid && (
            <Grid container spacing={2}>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  {isForcedAccess ? (
                    <p
                      className="guest-transaction-reset-filter reset-all-filters"
                      onClick={handleEntryStatusModalOpen}
                    >
                      Update Entry Status
                    </p>
                  ) : (
                    <p
                      className="guest-transaction-reset-filter reset-all-filters"
                      style={{ opacity: 0.5, cursor: 'not-allowed' }}
                    >
                      Update Entry Status
                    </p>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      {/* Cancel modal */}
      <ConfirmationModel
        display={cancelModalOpen}
        cancelModel={() => setCancelModalOpen(false)}
        confirmModel={cancelAdmin}
        title="Are you sure you want to cancel this booking?"
        description={`An email will be sent to ${agreementDetail?.memberName} to confirm that their booking has been cancelled.`}
        cancelButtonName="Don't cancel"
        confirmButtonName="Cancel"
      ></ConfirmationModel>
      {/* Cancel modal */}
      {agreementDetail && entryStatusUpdateUrl && (
        <ControlCentreEntryStatusModal
          display={openEntryStatusModal}
          handleClose={handleEntryStatusModalClose}
          facilityId={agreementDetail.stayAgreements[0].facilityId}
          currentEntryStatus={agreementDetail.stayAgreements[0].entryStatus}
          entryStatusUpdateUrl={entryStatusUpdateUrl}
          afterUpdateEntryStatus={getAgreementDetails}
        />
      )}
    </>
  )
}

export default TransactionDetail
