import { AggregateState } from '../../../common/types'
import { AggregateActionTypes } from '../../../common/aggregate.types'

export interface Department {
  id: string
  name: string
}

export type DepartmentState = AggregateState<Department>
export type DepartmentActionTypes = AggregateActionTypes<Department>

export const DEPARTMENTS_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/departments'
