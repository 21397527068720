import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

// Tenancy Stuff
export interface Tenancy {
  id: string
  name: string
  forcedAccess?: boolean
  guestAccess?: boolean
}

export type TenancyState = AggregateState<Tenancy>
export type MyTenancyState = AggregateState<Tenancy>
export type TenancyActionTypes = AggregateActionTypes<Tenancy>
export const TENANCY_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies'

// Tenancy Offers Stuff
export interface TenancyOffer {
  offerId: string
  offerName: string
  facilityId: string
  operatorId: string
  offerFacilityId: string
  offerFacilityAllocation: number
  offerFacilityVersion: number
  offerMarketingHeadline: string
  offerImageUrl: string
}

export type TenancyOfferState = AggregateState<TenancyOffer>
export type TenancyOfferActionTypes = AggregateActionTypes<TenancyOffer>
export const TENANT_CARPARK_TENANCIES_OFFERS_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:tenanciesId/offers'

// Tenancy Offers Stuff
export interface TenantOffer {
  id: string
  code: string
  name: string
  clientRef: string
  lifecycle: string
  version: number
  operatorId: string
  salesChannelType: string
  offerClass: string
  carParkTenantId: string
  distributionChannelId: string | null
  startDate: string
  endDate: string
}
export type TenantOfferState = AggregateState<TenantOffer>
export type TenantOfferActionTypes = AggregateActionTypes<TenantOffer>
export const TENANT_CARPARK_OFFERS_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/offers'
