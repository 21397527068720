import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface ManageEntitlementBay {
  id: string
  bayPrefix: string
  bayNumber: number
  fullBayNumber: string
}
export interface ManageEntitlementZone {
  zoneId: string
  zoneName: string
  bayCount: number
  bays: ManageEntitlementBay[]
}
export interface ManageEntitlementAllocation {
  allocationId: string
  allocationName: string
  bayCount: number
  zones: ManageEntitlementZone[]
}
export interface ManageEntitlement {
  operatorId: string
  carParkTenantId: string
  facilityId: string
  allocations: ManageEntitlementAllocation[]
}

export type ManageEntitlementState = AggregateState<ManageEntitlement>
export type ManageEntitlementTypes = AggregateActionTypes<ManageEntitlement>

export const MANAGE_BAY_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId'

// Bay Utilisation Report

export interface BayUtilisationReport {
  startDate: string
  endDate: string
  bayId: string
  facilityId: string
  carParkTenantId: string
  operatorId: string
  dayAllocated: number
  utilised: {
    percentage: number
    days: number
  }
  notUtilised: {
    percentage: number
    days: number
  }
}

export type BayUtilisationReportState = AggregateState<BayUtilisationReport>
export type BayUtilisationReportActionTypes = AggregateActionTypes<BayUtilisationReport>

export const BAY_UTILISATION_REPORT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/reports/agreement-utilisation'
