import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import { AGGREGATE_VIEW_ENTITLEMENT } from '../../../common/aggregate.types'
import { fetchAggregates } from '../../../common/axios-action'
import AlertModal from '../../../components/model/AlertModal'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { selectCurrentOrganisationId } from '../../auth/types'
import { BayAssignment } from '../../guest/types'
import { ROUTE_ASSIGNED } from '../../util/routes'
import { ENTITLEMENTS_API_RESOURCE_PATH } from '../assigned-parking/types'
import { ViewEntitlementInterface } from '../view-entitlement/types'
import BayUse from './bay-use'
import FindBay from './find-bay'
import ParkingUtilisation from './parking-utilisation'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '200px',
    padding: '7px 22px',
  },
  shuffleButtonSml: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '0.875rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '120px',
    paddingTop: '7px',
    paddingBottom: '7px',
    marginTop: '16px',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  customPaddingTop: {
    paddingTop: '5px !important',
  },
}))

interface IProps {}

const ManageBay: React.FC<IProps> = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isValidBaySelection, setIsValidBaySelection] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [agreementIdList, setAgreementIdList] = useState<string[]>([])
  const [bayAssignment, setBayAssignment] = useState<BayAssignment | null>(null)
  const [alertModalDisplay, setAlertModalDisplay] = useState<boolean>(false)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const { entitlementList, isLoadingOne } = useSelector((state: RootState) => ({
    entitlementList: state.viewEntitlementReducer.aggregates.values,
    isLoadingOne: state.viewEntitlementReducer.loadingOne,
  }))

  useEffect(() => {
    if (isLoadingOne) {
      if (entitlementList.length === 0) {
        if (!isNextPage) {
          setAlertModalDisplay(true)
        }
      } else {
        setAgreementIdList(entitlementList.map((e) => e.id))
        setIsNextPage(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlementList])
  const getBayAssignments = () => {
    if (currentOperatorId && currentOrganisationId && bayAssignment) {
      dispatch(
        fetchAggregates<ViewEntitlementInterface>(
          AGGREGATE_VIEW_ENTITLEMENT,
          ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + '/search-by-bay',
          {
            carParkTenancyId: bayAssignment?.facilityId,
            bayId: bayAssignment?.bayId,
          },
        ),
      )
    }
  }
  const prevPage = () => {
    if (isNextPage) {
      setIsNextPage(false)
    } else {
      navigate(ROUTE_ASSIGNED)
      dispatch(resetAggregate<ViewEntitlementInterface>(AGGREGATE_VIEW_ENTITLEMENT))
    }
  }
  const alertModalClose = () => {
    setAlertModalDisplay(false)
  }

  return (
    <div className="page-content-wrapper guest-parking-wrapper">
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2 no-padding">
                  <p className="back-icon" onClick={() => prevPage()}>
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    Manage Bay
                  </p>
                </h2>
              </Grid>
            </Grid>
            {isNextPage && (
              <Grid container spacing={0} className="responsive-content-wrapper">
                <Grid item xs={12}>
                  <div className="responsive-table-wrapper">
                    <table className="assigned-parking-table table-section-one">
                      <tbody>
                        <tr className="table-section">
                          <td className="table-cell-left">Type</td>
                          <td className="table-cell-right">
                            <strong>Reserved Parking</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td>
                            <strong>{bayAssignment ? bayAssignment.facilityName : ''}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="assigned-parking-table table-section-two">
                      <tbody>
                        <tr className="table-section">
                          <td className="table-cell-left">Level</td>
                          <td className="table-cell-right">
                            <strong>{bayAssignment ? bayAssignment.facilityAllocationName : ''}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Zone</td>
                          <td>
                            <strong>{bayAssignment ? bayAssignment.facilityZoneName : ''}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="assigned-parking-table table-section-three">
                      <tbody>
                        <tr className="table-section">
                          <td className="table-cell-left">Bay</td>
                          <td className="table-cell-right">
                            <strong>{bayAssignment ? bayAssignment.fullBayNo : ''}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <Container className="page-content-body-wrapper guest-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            {/* Find a bay */}
            <FindBay
              setIsValidBaySelection={setIsValidBaySelection}
              setBayAssignment={setBayAssignment}
              isNextPage={isNextPage}
            />

            {/* Bay use list */}
            <BayUse isNextPage={isNextPage} setIsNextPage={setIsNextPage} getBayAssignments={getBayAssignments} />

            {/* Parking utilisation chart */}
            {isNextPage && (
              <Grid item xs={12} className={'content-panel'}>
                <ParkingUtilisation
                  bayId={bayAssignment?.bayId ? bayAssignment?.bayId : ''}
                  facilityId={bayAssignment?.facilityId ? bayAssignment?.facilityId : ''}
                  agreementIds={agreementIdList}
                />
              </Grid>
            )}

            {/* Button section */}
            {!isNextPage && (
              <Grid item xs={12} style={{ paddingTop: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        className={classes.shuffleButton}
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={() => {
                          dispatch(resetAggregate<ViewEntitlementInterface>(AGGREGATE_VIEW_ENTITLEMENT))
                          navigate(ROUTE_ASSIGNED)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        className={classes.shuffleButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!isValidBaySelection}
                        onClick={() => getBayAssignments()}
                      >
                        Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <AlertModal
        display={alertModalDisplay}
        closeModel={alertModalClose}
        title="There are no assignments for this bay."
        description=""
        closeButtonName="Ok"
      />
    </div>
  )
}

export default ManageBay
