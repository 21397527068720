import { CircularProgress, Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { isValidEmail, isValidPhone } from '../../util/validator'
import { Contact, GuestEntitlement } from '../types'
import { GuestBookingDetail, INVALID_EMAIL, INVALID_PHONE, THIS_IS_REQUIRED_FIELD } from './types'
import { getCapitalizeString } from '../../../common/utility'

interface IProps {
  setIsValidBookingDetailSelection: Dispatch<SetStateAction<boolean>>
  setStayAgreementContact: Dispatch<SetStateAction<Contact | null>>
  isEdit: boolean
  isEntryStatusOnSite: boolean
  guestEntitlementData: GuestEntitlement | null
}

const BookingDetail: React.FC<IProps> = ({
  setIsValidBookingDetailSelection,
  setStayAgreementContact,
  isEdit,
  isEntryStatusOnSite,
  guestEntitlementData,
}: IProps) => {
  const [bookingDetail, setBookingDetail] = useState<GuestBookingDetail>({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
  })

  const [bookingDetailError, setBookingDetailError] = useState<GuestBookingDetail>({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
  })

  const { guestEntitlementsInserting } = useSelector((state: RootState) => ({
    guestEntitlementsInserting: state.guestEntitlementsReducer.inserting,
  }))

  const handleChange = (value: string, name: string) => {
    setBookingDetail({ ...bookingDetail, [name]: value })
  }

  // Check validation for book button
  useEffect(() => {
    let isValidDetail: boolean = true
    Object.entries(bookingDetail).forEach((item) => {
      if (item[0] !== 'companyName' && (item[1] === '' || item[1] === null)) {
        isValidDetail = false
        return
      }
    })
    Object.entries(bookingDetailError).forEach((item) => {
      if (item[1]) {
        isValidDetail = false
        return
      }
    })
    if (bookingDetail.phone) {
      if (bookingDetail.phone.length < 10) {
        isValidDetail = false
      } else {
        if (!isValidPhone(bookingDetail.phone)) {
          isValidDetail = false
        }
      }
    }
    if (bookingDetail.email) {
      if (!isValidEmail(bookingDetail.email)) {
        isValidDetail = false
      }
    }
    setIsValidBookingDetailSelection(isValidDetail)
  })

  // Prepare payload for POST call
  useEffect(() => {
    let stayAgreementContact: Contact = {
      firstName: getCapitalizeString(bookingDetail.firstName),
      lastName: getCapitalizeString(bookingDetail.lastName),
      email: bookingDetail.email,
      mobile: bookingDetail.phone,
      companyName: bookingDetail.companyName,
      country: guestEntitlementData ? guestEntitlementData.contact?.country : 'AU',
    }
    setStayAgreementContact(stayAgreementContact)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDetail])

  // Patch data on edit mode
  useEffect(() => {
    if (isEdit && guestEntitlementData) {
      let contact: Contact | null = guestEntitlementData.contact
      if (contact) {
        let bookingDetail: GuestBookingDetail = {
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          companyName: contact.companyName,
          phone: contact.mobile,
        }
        setBookingDetail(bookingDetail)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, guestEntitlementData])

  const checkFieldValidation = (name: string) => {
    let tempErrorState: GuestBookingDetail = { ...bookingDetailError }
    tempErrorState = { ...bookingDetailError, [name]: THIS_IS_REQUIRED_FIELD }
    switch (name) {
      case 'firstName':
        if (bookingDetail.firstName) {
          tempErrorState = { ...bookingDetailError, [name]: '' }
        }
        break
      case 'lastName':
        if (bookingDetail.lastName) {
          tempErrorState = { ...bookingDetailError, [name]: '' }
        }
        break
      case 'email':
        if (bookingDetail.email) {
          if (isValidEmail(bookingDetail.email)) {
            tempErrorState = { ...bookingDetailError, [name]: '' }
          } else {
            tempErrorState = { ...bookingDetailError, [name]: INVALID_EMAIL }
          }
        }
        break
      case 'phone':
        if (bookingDetail.phone) {
          if (isValidPhone(bookingDetail.phone)) {
            tempErrorState = { ...bookingDetailError, [name]: '' }
          } else {
            tempErrorState = { ...bookingDetailError, [name]: INVALID_PHONE }
          }
        }
        break
    }
    setBookingDetailError(tempErrorState)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>Who are you booking for?</h3>
      </Grid>
      <Grid item xs={5}>
        <p className="input-label">First name</p>
        <TextField
          InputProps={{ className: 'shuffleInput' }}
          InputLabelProps={{ className: 'shuffleLabel' }}
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={isEntryStatusOnSite}
          value={bookingDetail.firstName}
          onChange={(e) => handleChange(e.target.value, 'firstName')}
          onBlur={() => checkFieldValidation('firstName')}
          error={bookingDetailError.firstName.length > 0}
          helperText={bookingDetailError.firstName}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={5}>
        <p className="input-label">Last name</p>
        <TextField
          InputProps={{ className: 'shuffleInput' }}
          InputLabelProps={{ className: 'shuffleLabel' }}
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={isEntryStatusOnSite}
          value={bookingDetail.lastName}
          onChange={(e) => handleChange(e.target.value, 'lastName')}
          onBlur={() => checkFieldValidation('lastName')}
          error={bookingDetailError.lastName.length > 0}
          helperText={bookingDetailError.lastName}
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      </Grid>
      <Grid item xs={5}>
        <p className="input-label">Email address</p>
        <TextField
          InputProps={{ className: 'shuffleInput' }}
          InputLabelProps={{ className: 'shuffleLabel' }}
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={isEntryStatusOnSite}
          value={bookingDetail.email}
          onChange={(e) => handleChange(e.target.value, 'email')}
          onBlur={() => checkFieldValidation('email')}
          error={bookingDetailError.email.length > 0}
          helperText={bookingDetailError.email}
        />
      </Grid>
      <Grid item xs={2} textAlign="center">
        {guestEntitlementsInserting && <CircularProgress color="primary" size={30} />}
      </Grid>
      <Grid item xs={5}>
        <p className="input-label">Mobile phone number</p>
        <TextField
          InputProps={{ className: 'shuffleInput' }}
          InputLabelProps={{ className: 'shuffleLabel' }}
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={isEntryStatusOnSite}
          inputProps={{
            maxLength: 10,
          }}
          value={bookingDetail.phone}
          onChange={(e) => handleChange(e.target.value.replace(/[^0-9]/g, ''), 'phone')}
          onBlur={() => checkFieldValidation('phone')}
          error={bookingDetailError.phone.length > 0}
          helperText={bookingDetailError.phone}
        />
      </Grid>
      <Grid item xs={5}>
        <p className="input-label">Company name (optional)</p>
        <TextField
          InputProps={{ className: 'shuffleInput' }}
          InputLabelProps={{ className: 'shuffleLabel' }}
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={isEntryStatusOnSite}
          value={bookingDetail.companyName}
          onChange={(e) => handleChange(e.target.value, 'companyName')}
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={5}></Grid>
    </Grid>
  )
}

export default BookingDetail
