import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Switch,
  SwitchProps,
  TextField,
} from '@mui/material'
import { Fragment } from 'react'
import tickIcon from '../../../assets/svg/ws-sml-icon-tick-wht-50.svg'
import closeIcon from '../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { DepartmentTeamListItemDto, IdNameDto } from '../../../store/common/departments-teams/types'
import {
  ALLOCATION_TYPE_FIXED,
  ALLOCATION_TYPE_PERCENTAGE,
  ALLOCATION_TYPE_UNLIMITED,
  MemberAllocations,
  OfferFacility,
} from '../../../store/common/offer-facilities/types'

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#009273',
        backgroundImage: `url('${tickIcon}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
        backgroundSize: '23%',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'black',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: '#BBBABA',
    backgroundImage: `url('${closeIcon}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '75% center',
    backgroundSize: '23%',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

interface IProps {
  departmentId: string
  members: MemberAllocations[]
  offerFacilityData: OfferFacility
  formattedDepartmentList: any
  departmentTeamsList: DepartmentTeamListItemDto[]
  isProductDisable: boolean
}

const ProductDepartment: React.FC<IProps> = ({
  departmentId,
  members,
  offerFacilityData,
  formattedDepartmentList,
  departmentTeamsList,
  isProductDisable,
}: IProps) => {
  const handleChangeSwitch = (member: MemberAllocations) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let changedMember: MemberAllocations | undefined = offerFacilityData.memberAllocations.find(
      (currentMember: MemberAllocations) =>
        currentMember.departmentId === member.departmentId && currentMember.teamId === member.teamId,
    )
    if (changedMember) {
      if (event.target.checked) {
        if (member.allocationType === null) {
          changedMember.allocationType = ALLOCATION_TYPE_UNLIMITED
          changedMember.allocation = null
        }
      } else {
        changedMember.allocationType = null
        changedMember.allocation = null
      }
      changedMember.enabled = event.target.checked
    }
    formattedDepartmentList(offerFacilityData)
  }
  const enableAll = (departmentId: string) => {
    offerFacilityData.memberAllocations.forEach((member: any) => {
      if (member.departmentId === departmentId) {
        if (!member.enabled) {
          member.allocationType = ALLOCATION_TYPE_UNLIMITED
          member.allocation = null
          member.enabled = true
        }
      }
    })
    formattedDepartmentList(offerFacilityData)
  }
  const disableAll = (departmentId: string) => {
    offerFacilityData.memberAllocations.forEach((member: MemberAllocations) => {
      if (member.departmentId === departmentId) {
        member.enabled = false
        member.allocation = null
        member.allocationType = null
      }
    })
    formattedDepartmentList(offerFacilityData)
  }
  const generateBaysForTotalCapacity = (allocationType: string | null) => {
    let capacityArr = []
    if (allocationType === ALLOCATION_TYPE_FIXED || allocationType === ALLOCATION_TYPE_UNLIMITED) {
      capacityArr = Array.from({ length: offerFacilityData.allocation }, (_, index) => (index + 1).toString())
      capacityArr = ['No Limit'].concat(capacityArr)
    } else {
      capacityArr = Array.from({ length: 100 }, (_, index) => (index + 1).toString()) // For Percentage Type
      capacityArr = ['No Limit'].concat(capacityArr)
    }
    return capacityArr
  }
  const generateValueForTotalCapacity = (allocationType: string | null, allocation: number | null) => {
    if (allocationType === ALLOCATION_TYPE_FIXED || allocationType === ALLOCATION_TYPE_PERCENTAGE) {
      return allocation?.toString()
    } else if (allocationType === ALLOCATION_TYPE_UNLIMITED) {
      return 'No Limit'
    }
    return 'No Limit'
  }
  const handleChangeAutocompleteForAllocationType = (_event: any, value: string | null, member: MemberAllocations) => {
    if (value) {
      offerFacilityData.memberAllocations.forEach((m: any) => {
        if (member.departmentId === m.departmentId && member.teamId === m.teamId) {
          if (value === 'No Limit') {
            m.allocationType = ALLOCATION_TYPE_UNLIMITED
            m.allocation = null
          } else {
            if (member.allocationType === ALLOCATION_TYPE_UNLIMITED) {
              let fixedTypeAllocation = offerFacilityData.memberAllocations.find(
                (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_FIXED,
              )
              let percentageTypeAllocation = offerFacilityData.memberAllocations.find(
                (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_PERCENTAGE,
              )
              if (fixedTypeAllocation) {
                m.allocationType = ALLOCATION_TYPE_FIXED
              } else if (percentageTypeAllocation) {
                m.allocationType = ALLOCATION_TYPE_PERCENTAGE
              } else {
                m.allocationType = ALLOCATION_TYPE_FIXED
              }
            } else {
              m.allocationType = member.allocationType
            }
            m.allocation = parseInt(value)
          }
          formattedDepartmentList(offerFacilityData)
        }
      })
    }
  }
  const getDepartmentNameById = () => {
    const department = departmentTeamsList.find(
      (department: DepartmentTeamListItemDto) => department.id === departmentId,
    )
    if (department) {
      return department?.name
    } else {
      return ''
    }
  }
  const getTeamNameById = (teamId: any) => {
    const department = departmentTeamsList.find(
      (department: DepartmentTeamListItemDto) => department.id === departmentId,
    )
    if (department) {
      const team = department.teams.find((team: IdNameDto) => team.id === teamId)
      if (team) {
        return team.name
      } else {
        return ''
      }
    } else {
      return ''
    }
  }
  const identifyTypeSymbol = () => {
    let fixedTypeAllocation = offerFacilityData.memberAllocations.find(
      (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_FIXED,
    )
    let percentageTypeAllocation = offerFacilityData.memberAllocations.find(
      (e: MemberAllocations) => e.enabled && e.allocationType === ALLOCATION_TYPE_PERCENTAGE,
    )
    if (fixedTypeAllocation) {
      return ' bays'
    } else if (percentageTypeAllocation) {
      return '%'
    } else {
      return ' bays'
    }
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="department-header">
          <h4>{getDepartmentNameById()}</h4>
        </Grid>
        <Grid item xs={12} className="enable-options multi-dept ">
          {isProductDisable ? (
            <p className="inactive-enable-all"> Enable All </p>
          ) : (
            <p className="enable-all" onClick={() => enableAll(departmentId)}>
              Enable All
            </p>
          )}
          {isProductDisable ? (
            <p className="inactive-enable-all"> Disable All </p>
          ) : (
            <p className="enable-all" onClick={() => disableAll(departmentId)}>
              Disable All
            </p>
          )}
          <p className="optional-limit">Optional limit per team</p>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {members.length > 0 &&
            members.map((member: MemberAllocations, index: any) => {
              return (
                <Box className="switch-card" key={'index' + index}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <FormGroup>
                        <FormControlLabel
                          className="parking-switch"
                          disabled={isProductDisable}
                          control={
                            <IOSSwitch
                              checked={member.enabled ? member.enabled : false}
                              onChange={handleChangeSwitch(member)}
                            />
                          }
                          label={getTeamNameById(member.teamId)}
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </Grid>
                    {member.enabled && (
                      <Grid item xs={3} className="flex-aligned capacity-limit-drop-down">
                        <Autocomplete
                          clearIcon={null}
                          disabled={isProductDisable}
                          value={generateValueForTotalCapacity(member.allocationType, member.allocation)}
                          onChange={(event, value) => handleChangeAutocompleteForAllocationType(event, value, member)}
                          id={'autocomplete-panel' + index}
                          getOptionLabel={(option) => {
                            if (member.allocationType === ALLOCATION_TYPE_FIXED) {
                              if (option === 'No Limit') {
                                return option
                              } else {
                                return option + ' bays'
                              }
                            }
                            if (member.allocationType === ALLOCATION_TYPE_PERCENTAGE) {
                              if (option === 'No Limit') {
                                return option
                              } else {
                                return option + '%'
                              }
                            }
                            if (member.allocationType === ALLOCATION_TYPE_UNLIMITED) {
                              if (option === 'No Limit') {
                                return option
                              } else {
                                return option
                              }
                            }
                            return option
                          }}
                          renderOption={(props, option) => {
                            let tempOption: string = ''
                            if (member.allocationType === ALLOCATION_TYPE_FIXED) {
                              if (option === 'No Limit') {
                                tempOption = option
                              } else {
                                tempOption = option + ' bays'
                              }
                            }
                            if (member.allocationType === ALLOCATION_TYPE_PERCENTAGE) {
                              if (option === 'No Limit') {
                                tempOption = option
                              } else {
                                tempOption = option + '%'
                              }
                            }
                            if (member.allocationType === ALLOCATION_TYPE_UNLIMITED) {
                              if (option === 'No Limit') {
                                tempOption = option
                              } else {
                                tempOption = option + identifyTypeSymbol()
                              }
                            }
                            return (
                              <Box component="li" {...props}>
                                {tempOption}
                              </Box>
                            )
                          }}
                          options={generateBaysForTotalCapacity(member.allocationType)}
                          sx={{ width: 140 }}
                          renderInput={(params) => <TextField {...params} variant="standard" />}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )
            })}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default ProductDepartment
