import React, { ReactNode } from 'react'
import clsx from 'clsx'

import {
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  RadioGroup,
  RadioGroupProps,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
} from '@mui/material'

import { Field, FieldProps } from 'react-final-form'
import { showErrorOnChange, useFieldForErrors, ShowErrorFunc } from './Util'
import { IdClientRefName } from '../../common/types'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  error: {
    color: '#d32f2f',
  },

  root: {
    flexDirection: 'row',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
}))

export interface RadiosProps extends Partial<Omit<MuiRadioProps, 'onChange'>> {
  name: string
  data: IdClientRefName[]
  label?: ReactNode
  required?: boolean
  helperText?: string
  formLabelProps?: Partial<FormLabelProps>
  formControlLabelProps?: Partial<FormControlLabelProps>
  fieldProps?: Partial<FieldProps<any, any>>
  formControlProps?: Partial<FormControlProps>
  radioGroupProps?: Partial<RadioGroupProps>
  formHelperTextProps?: Partial<FormHelperTextProps>
  showError?: ShowErrorFunc
  disabled: boolean
}

export function RadioButtons(props: RadiosProps) {
  const {
    name,
    data,
    label,
    required,
    helperText,
    formLabelProps,
    formControlLabelProps,
    fieldProps,
    formControlProps,
    radioGroupProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    disabled,
    ...restRadios
  } = props

  const classes = useStyles()
  const field = useFieldForErrors(name)
  const isError = showError(field)

  return (
    <FormControl
      required={required}
      error={isError}
      {...formControlProps}
      fullWidth
      className={isError ? classes.error : ''}
    >
      {!!label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <RadioGroup {...radioGroupProps} className={classes.root}>
        {data.map((item: IdClientRefName, idx: number) => (
          <FormControlLabel
            key={idx}
            name={name}
            label={item.name}
            value={item.id}
            disabled={disabled}
            control={
              <Field
                name={name}
                type="radio"
                render={({ input: { name, value, onChange, checked, ...restInput } }) => (
                  <MuiRadio
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    required={required}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    className={checked ? 'radio-checked' : ''}
                    inputProps={{ required, ...restInput }}
                    {...restRadios}
                  />
                )}
                {...fieldProps}
              />
            }
            {...formControlLabelProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
