import {
  DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST,
  DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST,
  DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  isAggregateActionType,
  RESET_AGGREGATE,
} from '../../../common/aggregate.types'
import {
  DailyCountAllocationByAuthorityByOfferActionTypes,
  DailyCountAllocationByAuthorityByOfferState,
  DailyFacilityAgreementPeakAllocationByAuthorityActionTypes,
  DailyFacilityAgreementPeakAllocationByAuthorityState,
  DailyMemberFinancialSummaryActionTypes,
  DailyMemberFinancialSummaryState,
} from './types'

// Daily Facility Agreement Peak Allocation By Authority
const dailyFacilityAgreementPeakAllocationByAuthorityInitialState: DailyFacilityAgreementPeakAllocationByAuthorityState =
  {
    loadingList: false,
    loadingOne: false,
    inserting: false,
    inserted: false,
    updating: false,
    updated: false,
    error: null,
    aggregates: {
      values: [],
      totalCount: null,
      link: null,
      eTag: null,
    },
    idNames: {
      values: [],
      eTag: null,
    },
    aggregate: null,
    deleting: false,
    deleted: false,
    lifeCycleOnly: null,
    updatingLifeCycle: false,
    updateLifeCycleSuccess: false,
    updatedApplicationStatus: false,
    updatingApplicationStatus: false,
    applicationStatus: null,
    idOnly: {
      id: null,
    },
  }
export function dailyFacilityAgreementPeakAllocationByAuthorityReducer(
  state = dailyFacilityAgreementPeakAllocationByAuthorityInitialState,
  action: DailyFacilityAgreementPeakAllocationByAuthorityActionTypes,
): DailyFacilityAgreementPeakAllocationByAuthorityState {
  if (
    isAggregateActionType(action.type) &&
    action.payload.aggregateType !== DAILY_FACILITY_AGREEMENT_PEAK_ALLOCATION_BY_AUTHORITY_AGGREGATE_REQUEST
  ) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
      }
    case RESET_AGGREGATE:
      return dailyFacilityAgreementPeakAllocationByAuthorityInitialState
    default:
      return state
  }
}

// Daily Count Allocation By Authority By Offer
const dailyCountAllocationByAuthorityByOfferInitialState: DailyCountAllocationByAuthorityByOfferState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}
export function dailyCountAllocationByAuthorityByOfferReducer(
  state = dailyCountAllocationByAuthorityByOfferInitialState,
  action: DailyCountAllocationByAuthorityByOfferActionTypes,
): DailyCountAllocationByAuthorityByOfferState {
  if (
    isAggregateActionType(action.type) &&
    action.payload.aggregateType !== DAILY_COUNT_ALLOCATION_BY_AUTHORITY_BY_OFFER_AGGREGATE_REQUEST
  ) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
      }
    case RESET_AGGREGATE:
      return dailyCountAllocationByAuthorityByOfferInitialState
    default:
      return state
  }
}

// Daily Member Financial Summary

const dailyMemberFinancialSummaryInitialState: DailyMemberFinancialSummaryState = {
  loadingList: false,
  loadingOne: false,
  inserting: false,
  inserted: false,
  updating: false,
  updated: false,
  error: null,
  aggregates: {
    values: [],
    totalCount: null,
    link: null,
    eTag: null,
  },
  idNames: {
    values: [],
    eTag: null,
  },
  aggregate: null,
  deleting: false,
  deleted: false,
  lifeCycleOnly: null,
  updatingLifeCycle: false,
  updateLifeCycleSuccess: false,
  updatedApplicationStatus: false,
  updatingApplicationStatus: false,
  applicationStatus: null,
  idOnly: {
    id: null,
  },
}

export function dailyMemberFinancialSummaryReducer(
  state = dailyMemberFinancialSummaryInitialState,
  action: DailyMemberFinancialSummaryActionTypes,
): DailyMemberFinancialSummaryState {
  if (
    isAggregateActionType(action.type) &&
    action.payload.aggregateType !== DAILY_MEMBER_FINANCIAL_SUMMARY_AGGREGATE_REQUEST
  ) {
    return state
  }

  switch (action.type) {
    case GET_AGGREGATE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        error: null,
        aggregate: null,
      }
    case GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        loadingOne: false,
        error: null,
        aggregate: action.payload.aggregate,
      }
    case GET_AGGREGATE_FAILURE:
      return {
        ...state,
        loadingOne: false,
        error: action.payload.error,
        aggregate: null,
      }
    case RESET_AGGREGATE:
      return dailyMemberFinancialSummaryInitialState
    default:
      return state
  }
}
