import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState, AgreementStayType, OfferClass, PurchaseType, SalesChannelType } from '../../../common/types'

export interface StayRequest {
  agreementStayType: AgreementStayType
  requestedStartDateTime: string
  requestedFinishDateTime: string
}

export interface ParkingQuoteItemList {
  id: string
  offer: {
    id: string
    name: string
    offerClass: OfferClass
    marketing: {
      headline: string
      details: string
    }
    terms: string | null
    imageUrl: string | null
    termsUrl: string | null
  }
  sortOrder: number
  stayAgreements: [
    {
      charge: number
      facilityId: string
      timeZoneId: string
      stayType: AgreementStayType
      singleStay: {
        startInstant: number
        finishInstant: number
        startDateTime: string
        finishDateTime: string
        durationMinutes: number
      }
    },
  ]
  notifications: any[]
  purchaseType: PurchaseType
}

export interface ParkingQuote {
  id: string
  quote: {
    salesChannelType: SalesChannelType
    memberId: string
  }
  requestedFacilityIds: string[]
  stayRequest: StayRequest
  itemList: ParkingQuoteItemList[]
}

export type ParkingQuoteState = AggregateState<ParkingQuote>
export type ParkingQuoteActionTypes = AggregateActionTypes<ParkingQuote>
export const PARKING_QUOTE_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/core-members/:memberId/parking-quotes'

export const EXISTING_BOOKING_ERROR = 'You have an existing booking at this time. Please try another time.'
