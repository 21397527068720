import { CircularProgress, Grid } from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { AGGREGATE_ORDER_PARKING_AGREEMENTS } from '../../../../../common/aggregate.types'
import { fetchAggregates } from '../../../../../common/axios-action'
import { getAgreementStatusFormattedName } from '../../../../../common/utility'
import { selectCurrentOperatorId } from '../../../../../config/app/reducers'
import { RootState } from '../../../../../store'
import { selectCurrentOrganisationId } from '../../../../auth/types'
import {
  OrderParkingAgreements,
  ORDER_PARKING_AGREEMENTS_API_RESOURCE_PATH,
} from '../../../../on-demand/transactions/types'
import { getTableCell } from '../../../../util/tooltip'

interface IProps {
  memberId: string
  memberName: string
  refreshParking: boolean
}

const ParkingItems: React.FC<IProps> = (props) => {
  const { memberId, memberName, refreshParking } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const { orderParkingAgreementsList, loadingList } = useSelector(
    (state: RootState) => ({
      orderParkingAgreementsList: state.orderParkingAgreementsStateReducer.aggregates.values,
      loadingList: state.orderParkingAgreementsStateReducer.loadingList,
    }),
    shallowEqual,
  )

  function getOrders(currentOperatorId: string, currentOrganisationId: string, memberId: string) {
    let payload = {
      salesChannel: 'Res',
      memberId: memberId,
      size: 10,
      sortBy: 'StartDate',
      sortDirection: 'desc',
    }
    dispatch(
      fetchAggregates<OrderParkingAgreements>(
        AGGREGATE_ORDER_PARKING_AGREEMENTS,
        ORDER_PARKING_AGREEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
          ':carParkTenantId',
          currentOrganisationId,
        ),
        payload,
      ),
    )
  }

  // For initially get order parking agreements data
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && memberId) {
      getOrders(currentOperatorId, currentOrganisationId, memberId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, memberId])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && refreshParking && memberId) {
      getOrders(currentOperatorId, currentOrganisationId, memberId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, memberId, refreshParking])

  return (
    <>
      <Grid container spacing={0} className="assigned-sub-section">
        <Grid item xs={12}>
          <h3 className="padding-bottom-20">Recent bookings</h3>
          {loadingList ? (
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <CircularProgress color="primary" size={30} />
              </Grid>
            </Grid>
          ) : orderParkingAgreementsList.length > 0 ? (
            <>
              <table className="recent-bookings-table" id="transactions">
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Parker</th>
                    <th>Booking entry</th>
                    <th>Booking exit</th>
                    <th>Location</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orderParkingAgreementsList.map((agreement: OrderParkingAgreements) => (
                    <tr key={agreement.orderId}>
                      <td className="transaction-id">
                        <p
                          style={{ cursor: 'pointer' }}
                          className="color-red"
                          onClick={() =>
                            navigate('/on-demand/transactions/transaction-detail', {
                              state: {
                                orderId: agreement.orderId,
                              },
                            })
                          }
                        >
                          {agreement.orderReference}
                        </p>
                      </td>
                      <td className="pr-20" style={{ maxWidth: '130px' }}>
                        {getTableCell(agreement.memberName, 23)}
                      </td>
                      <td>{format(Date.parse(agreement.entryDateTime), 'E dd MMM hh:mmaaa')}</td>
                      <td>{format(Date.parse(agreement.exitDateTime), 'E dd MMM hh:mmaaa')}</td>
                      <td className="pr-20" style={{ maxWidth: '200px' }}>
                        {getTableCell(agreement.facilityName, 38)}
                      </td>
                      <td>{getAgreementStatusFormattedName(agreement.agreementStatus)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p
                className="search-bookings-link"
                onClick={() =>
                  navigate('/on-demand/transactions', {
                    state: { memberId: memberId, memberName: memberName },
                  })
                }
              >
                Search for all bookings by this user
              </p>
            </>
          ) : (
            <p>This user doesn’t have any booking history.</p>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ParkingItems
