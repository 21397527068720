import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, FormControlLabel, FormGroup, Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import tickIcon from '../../../../../assets/svg/ws-sml-icon-tick-wht-50.svg'
import addIcon from '../../../../../assets/svg/ws-ui-icon-add-white.svg'
import closeIcon from '../../../../../assets/svg/ws-ui-icon-close-x-grey.svg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId } from '../../../../../config/app/reducers'
import { selectCurrentOrganisationId } from '../../../../auth/types'
import { AUTO_INVITATION_API_RESOURCE_PATH, MemberAutoInvitation, MemberAutoInvitationRequest } from './types'
import { ErrorResponse, Lifecycle, LifecycleOnly, ShowToolTip } from '../../../../../common/types'
import { RootState } from '../../../../../store'
import { setNotification } from '../../../../../store/common/notifications/action'
import { NotificationType } from '../../../../../store/common/notifications/types'
import { fetchAggregates, updateLifeCycle } from '../../../../../common/axios-action'
import { AGGREGATE_AUTO_INVITATION } from '../../../../../common/aggregate.types'
import LoadingInDiv from '../../../../../components/ui/Loading/LoadingInDiv'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    fontWeight: 'bold',
    height: 50,
  },
  gridTopMargin: {
    paddingTop: '15px',
  },
  gridTopMarginLess: {
    paddingTop: '0px',
  },
  gridBottomMargin: {
    paddingTop: '20px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '705px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(151,151,151,1), inset 0 -1px 0 rgba(151,151,151,1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  labelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  checkedIcon: {
    boxShadow: 'inset 0 0 0 1px rgba(255,0,0,1), inset 0 -1px 0 rgba(255,0,0,1)',
    backgroundColor: '#ff0000',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ff0000',
    },
  },
}))

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#009273',
        backgroundImage: `url('${tickIcon}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
        backgroundSize: '23%',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'black',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: '#BBBABA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

interface IProps {
  display: boolean
  hideModel: () => void
  showModel: (id: string | null) => void
  setDeactivateId: Dispatch<SetStateAction<string | null>>
  setDeleteId: Dispatch<SetStateAction<string | null>>
  setDeactivateDialog: Dispatch<SetStateAction<boolean>>
  setDeleteDialog: Dispatch<SetStateAction<boolean>>
  setDisplayName: Dispatch<SetStateAction<string>>
  displayName: string
}
const AutoInvitationForm: React.FC<IProps> = (props) => {
  const { showModel, setDeactivateId, setDeleteId, setDeactivateDialog, setDeleteDialog, setDisplayName, displayName } =
    props
  const classes = useStyle()

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [activeInvitations, setActiveInvitations] = useState<MemberAutoInvitation[]>([])
  const [inactiveInvitations, setInactiveInvitations] = useState<MemberAutoInvitation[]>([])
  const [showToolTip, setShowToolTip] = useState<ShowToolTip>()
  const [clickedOnDeactivate, setClickedOnDeactivate] = useState<boolean>(false)

  const { error, invitations, loadingList, deleteSuccess, deleting, updateLifeCycleSuccess, updatingLifeCycle } =
    useSelector(
      (state: RootState) => ({
        error: state.autoInvitationReducer.error,
        invitations: state.autoInvitationReducer.aggregates,
        loadingList: state.autoInvitationReducer.loadingList,
        deleteSuccess: state.autoInvitationReducer.deleted,
        deleting: state.autoInvitationReducer.deleting,
        updateLifeCycleSuccess: state.autoInvitationReducer.updateLifeCycleSuccess,
        updatingLifeCycle: state.autoInvitationReducer.updatingLifeCycle,
      }),
      shallowEqual,
    )

  const getAllRegistrationLink = () => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<MemberAutoInvitation>(
          AGGREGATE_AUTO_INVITATION,
          AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          {
            page: 0,
            size: 1000,
          },
        ),
      )
    }
  }

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      getAllRegistrationLink()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId])

  useEffect(() => {
    if (invitations) {
      setActiveInvitations(invitations.values.filter((row) => row.lifecycle === Lifecycle.Active))
      setInactiveInvitations(invitations.values.filter((row) => row.lifecycle !== Lifecycle.Active))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations])

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId && (deleteSuccess || updateLifeCycleSuccess)) {
      getAllRegistrationLink()
      if (deleteSuccess) {
        dispatch(setNotification(NotificationType.INFO, [displayName + ' has been deleted']))
        setDeleteDialog(false)
        setDeleteId(null)
        setDisplayName('')
      }
      if (updateLifeCycleSuccess) {
        if (clickedOnDeactivate) {
          dispatch(setNotification(NotificationType.INFO, [displayName + ' has been deactivated']))
        } else {
          dispatch(setNotification(NotificationType.INFO, [displayName + ' has been activated']))
        }
        setClickedOnDeactivate(false)
        setDeactivateDialog(false)
        setDeactivateId(null)
        setDisplayName('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentOperatorId, currentOrganisationId, deleteSuccess, updateLifeCycleSuccess])

  useEffect(() => {
    if (error) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(error))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError.message ? firstError.message : firstError.code.substring(0, 41),
          ]),
        )
      }
    }
  }, [error, dispatch])

  const onClickDelete = (event: any, id: string | null, name: string | null) => {
    event.stopPropagation()
    setDeleteDialog(true)
    setDeleteId(id)
    setDisplayName(name ? name : '')
  }

  const onClickSuspendedOrActive = (event: any, id: string | null, name: string | null) => {
    event.stopPropagation()
    if (currentOperatorId && currentOrganisationId && id) {
      let lifecycleOnly: LifecycleOnly = { lifecycle: Lifecycle.Suspended }
      if (event.target.checked) {
        lifecycleOnly = { lifecycle: Lifecycle.Active }
      }
      setDisplayName(name ? name : '')
      if (lifecycleOnly.lifecycle === Lifecycle.Suspended) {
        setDeactivateDialog(true)
        setDeactivateId(id)

        setClickedOnDeactivate(true)
      } else {
        dispatch(
          updateLifeCycle<MemberAutoInvitationRequest>(
            AGGREGATE_AUTO_INVITATION,
            AUTO_INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            id,
            lifecycleOnly,
          ),
        )
      }
    }
  }

  const copyToClipBoard = async (textToCopy: string | null, index: string | null) => {
    try {
      if (textToCopy) {
        await navigator.clipboard.writeText(textToCopy)
        setShowToolTip({
          id: index,
          show: true,
          message: 'Copied',
        })
      }
    } catch (err) {}
  }

  const editModel = (event: any, id: string | null) => {
    event.stopPropagation()
    showModel(id)
  }

  return (
    <>
      <Grid item xs={12} className="content-panel">
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <h3 className="padding-bottom-20">Self registration links</h3>
          </Grid>
          <Grid item xs={4} style={{ marginTop: -24, textAlign: 'right' }}>
            <Button
              className={classes.shuffleButton}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<img className="buttonIcon" src={addIcon} alt="add-icon" />}
              style={invitations.values.length === 0 ? { maxWidth: '152px' } : { maxWidth: '200px' }}
              onClick={() => showModel(null)}
            >
              {invitations.values.length === 0 ? 'Add link' : 'Add another link'}
            </Button>
          </Grid>
        </Grid>
        {invitations.values.length === 0 && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <p className="select-label">You haven’t added any self registration links.</p>
            </Grid>
          </Grid>
        )}

        {(loadingList || deleting || updatingLifeCycle) && <LoadingInDiv />}

        {activeInvitations.length > 0 && (
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <p className="select-heading">Active Links</p>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={0} className={classes.gridTopMarginLess}>
          {activeInvitations.map((row, index) => {
            return (
              <>
                <Grid item xs={12} md={12} style={{ display: 'flex' }} className={classes.gridTopMargin}>
                  <div className="invitation-card">
                    <Grid container spacing={2}>
                      <Grid item xs={1} md={1} style={{ paddingLeft: 0 }}>
                        <FormGroup style={{ marginTop: 8 }}>
                          <FormControlLabel
                            classes={{ root: classes.labelRoot }}
                            className="custom-switch"
                            control={
                              <IOSSwitch
                                checked={row.lifecycle === Lifecycle.Active ? true : false}
                                onClick={(e) => onClickSuspendedOrActive(e, row.id, row.name)}
                              />
                            }
                            label={''}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={9} md={9}>
                        <p className="registration-title" onClick={(e) => editModel(e, row.id)}>
                          {row.name}
                          <span className="icon-approval">
                            ({row.autoApprove ? 'Automatic approval' : 'Manual approval'})
                          </span>
                        </p>
                        <p className="registration-product-count">
                          <span className="registration-url">{row.invitationUrl}</span>
                        </p>
                      </Grid>

                      <Grid item xs={2} md={2} style={{ display: 'flex' }}>
                        <Tooltip
                          title={
                            showToolTip && showToolTip.id === row.id && showToolTip.show ? showToolTip.message : ''
                          }
                          arrow
                        >
                          <div className="copy-link" onClick={() => copyToClipBoard(row.invitationUrl, row.id)}>
                            Copy Link
                          </div>
                        </Tooltip>
                        <div className="close-card">
                          <img
                            className="close-x"
                            src={closeIcon}
                            alt="close-icon"
                            onClick={(e) => onClickDelete(e, row.id, row.name)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </>
            )
          })}
        </Grid>

        {inactiveInvitations.length > 0 && (
          <Grid container spacing={2} className={classes.gridTopMargin}>
            <Grid item xs={11}>
              <p className="select-heading">Inactive Links</p>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={0} className={classes.gridTopMarginLess}>
          {inactiveInvitations.map((row, index) => {
            return (
              <>
                <Grid item xs={12} md={12} style={{ display: 'flex' }} className={classes.gridTopMargin}>
                  <div className="invitation-card">
                    <Grid container spacing={2}>
                      <Grid item xs={1} md={1} style={{ paddingLeft: 0 }}>
                        <FormGroup style={{ marginTop: 8 }}>
                          <FormControlLabel
                            classes={{ root: classes.labelRoot }}
                            className="custom-switch"
                            control={
                              <IOSSwitch
                                checked={row.lifecycle === Lifecycle.Active ? true : false}
                                onClick={(e) => onClickSuspendedOrActive(e, row.id, row.name)}
                              />
                            }
                            label={''}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={9} md={9} onClick={(e) => editModel(e, row.id)}>
                        <p className="registration-title">
                          {row.name}
                          <span className="icon-approval">
                            ({row.autoApprove ? 'Automatic approval' : 'Manual approval'})
                          </span>
                        </p>
                        <p className="registration-product-count">
                          <span className="registration-url">{row.invitationUrl}</span>
                        </p>
                      </Grid>
                      <Grid item xs={2} md={2} style={{ display: 'flex' }}>
                        <Tooltip
                          title={
                            showToolTip && showToolTip.id === row.id && showToolTip.show ? showToolTip.message : ''
                          }
                          arrow
                        >
                          <div className="copy-link" onClick={() => copyToClipBoard(row.invitationUrl, row.id)}>
                            Copy Link
                          </div>
                        </Tooltip>
                        <div className="close-card">
                          <img
                            className="close-x"
                            src={closeIcon}
                            alt="close-icon"
                            onClick={(e) => onClickDelete(e, row.id, row.name)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </>
            )
          })}
        </Grid>

        {inactiveInvitations.length > 0 && (
          <Grid container spacing={2} className={classes.gridTopMargin}>
            <Grid item xs={11}>
              <p className="select-label">
                Inactive links can’t be used by your staff. Toggle the switch next to an inactive link to activate it.
              </p>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default AutoInvitationForm
