import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PrivateLayout from './layout/main'
import PublicLayout from './layout/main/public-layout'
import Assigned from './pages/assigned/assigned'
import AssignParking from './pages/assigned/assigned-parking/assign-parking'
import ManageBay from './pages/assigned/manage-bay/manage-bay'
import ManageParkers from './pages/assigned/manage-parkers/manage-parkers'
import ViewEntitlement from './pages/assigned/view-entitlement/view-entitlement'
import Login from './pages/auth/login/login'
import ResetPassword from './pages/auth/reset-password/reset-password'
import ResetPasswordConfirm from './pages/auth/reset-password/reset-password-confirm'
import ResetPasswordEmail from './pages/auth/reset-password/reset-password-email'
import TenantSelector from './pages/auth/tenant-selector/tenant-selector'
import VerifyEmailConfirm from './pages/auth/verify-email/verify-email-confirm'
import GlobalError from './pages/error/global-error'
import RouteNotFound from './pages/error/route-not-found'
import Guest from './pages/guest'
import BookGuestParking from './pages/guest/book-guest-parking/book-guest-parking'
import GuestTransactions from './pages/guest/guest-transactions/guest-transactions'
import GuestTransactionDetail from './pages/guest/guest-transactions/guest-transactions-detail'
import HomePage from './pages/home/home'
import OnDemand from './pages/on-demand'
import BlockOutDates from './pages/on-demand/block-out-dates/block-out-dates'
import ProductDetail from './pages/on-demand/product-detail/product-detail'
import ProductsCapacities from './pages/on-demand/products-capacities/products-capacities'
import TransactionDetail from './pages/on-demand/transactions/transaction-detail'
import Transactions from './pages/on-demand/transactions/transactions'
import Settings from './pages/settings/index'
import ManageUsers from './pages/settings/users/manage-users'
import SelfRegistration from './pages/settings/users/self-registration/self-registration'
import Users from './pages/users'
import BulkUploads from './pages/users/bulk-uploads/bulk-uploads'
import {
  ROUTE_ASSIGNED,
  ROUTE_ASSIGNED_ASSIGN_PARKING,
  ROUTE_ASSIGNED_EDIT_ASSIGN_BAY,
  ROUTE_ASSIGNED_MANAGE_BAY,
  ROUTE_ASSIGNED_MANAGE_PARKERS,
  ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT,
  ROUTE_GUEST,
  ROUTE_GUEST_BOOK_GUEST_PARKING,
  ROUTE_GUEST_EDIT_GUEST_PARKING,
  ROUTE_GUEST_TRANSACTION,
  ROUTE_GUEST_TRANSACTION_DETAIL,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_ON_DEMAND,
  ROUTE_ON_DEMAND_BLOCK_OUT_DATES,
  ROUTE_ON_DEMAND_BOOK_PARKING,
  ROUTE_ON_DEMAND_CHANGE_BOOKING,
  ROUTE_ON_DEMAND_PRODUCTS_CAPACITIES,
  ROUTE_ON_DEMAND_PRODUCT_DETAIL,
  ROUTE_ON_DEMAND_REFUND_CONFIRMATION_DETAIL,
  ROUTE_ON_DEMAND_REFUND_TRANSACTION_DETAIL,
  ROUTE_ON_DEMAND_TRANSACTION,
  ROUTE_ON_DEMAND_TRANSACTION_DETAIL,
  ROUTE_OPERATIONS,
  ROUTE_OPERATIONS_ON_DEMAND_BOOKING_SUMMARY,
  ROUTE_OPERATIONS_CONTROL_CENTRE,
  ROUTE_OPERATIONS_FORCE_GATE_OPEN,
  ROUTE_RESET_PASSWORD,
  ROUTE_RESET_PASSWORD_CONFIRM,
  ROUTE_RESET_PASSWORD_EMAIL,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_SELF_REGISTRATION,
  ROUTE_TENANT_SELECTOR,
  ROUTE_USERS,
  ROUTE_USERS_BULK_UPLOADS,
  ROUTE_USERS_MANAGE_USERS,
  ROUTE_UTILISATION,
  ROUTE_UTILISATION_DASHBOARD,
  ROUTE_UTILISATION_REPORT,
  ROUTE_VERIFY_EMAIL_CONFIRM,
  ROUTE_OPERATIONS_ASSIGNED_BOOKING_SUMMARY,
  ROUTE_OPERATIONS_GUEST_BOOKING_SUMMARY,
} from './pages/util/routes'
import Utilisation from './pages/utilisation'
import Dashboard from './pages/utilisation/dashboard/dashboard'
import Report from './pages/utilisation/report/report'
import BookParking from './pages/on-demand/book-parking/book-parking'
import ChangeBooking from './pages/on-demand/book-parking/change-booking'
import RefundTransactionDetail from './pages/on-demand/transactions/refund-transaction-detail'
import RefundConfirmationDetail from './pages/on-demand/transactions/refund-confirmation-detail'
import ControlCentre from './pages/operations/control-centre'
import Operations from './pages/operations/operations'
import ForceGateOpen from './pages/operations/force-gate-open'
import OnDemandBookingSummary from './pages/operations/on-demand-booking-summary'
import AssignedBookingSummary from './pages/operations/assigned-booking-summary'
import GuestBookingSummary from './pages/operations/guest-booking-summary'

interface IProps {}

const MainNavigation: React.FC<IProps> = (props) => {
  return (
    <>
      <Router>
        <Routes>
          <Route path={ROUTE_LOGIN} element={<PublicLayout />}>
            <Route index element={<Login />} />
          </Route>

          <Route path={ROUTE_RESET_PASSWORD} element={<PublicLayout />}>
            <Route index element={<ResetPassword />} />
          </Route>

          <Route path={ROUTE_RESET_PASSWORD_EMAIL} element={<PublicLayout />}>
            <Route index element={<ResetPasswordEmail />} />
          </Route>

          <Route path={ROUTE_RESET_PASSWORD_CONFIRM} element={<PublicLayout />}>
            <Route index element={<ResetPasswordConfirm />} />
          </Route>

          <Route path={ROUTE_VERIFY_EMAIL_CONFIRM} element={<PublicLayout />}>
            <Route index element={<VerifyEmailConfirm />} />
          </Route>

          <Route path={ROUTE_SETTINGS} element={<PrivateLayout />}>
            <Route index element={<Settings />} />
            <Route path={ROUTE_SETTINGS_SELF_REGISTRATION} element={<SelfRegistration />} />
          </Route>

          <Route path={ROUTE_UTILISATION} element={<PrivateLayout />}>
            <Route index element={<Utilisation />} />
            <Route path={ROUTE_UTILISATION_DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTE_UTILISATION_REPORT} element={<Report />} />
          </Route>

          <Route path={ROUTE_OPERATIONS} element={<PrivateLayout />}>
            <Route index element={<Operations />} />
            <Route path={ROUTE_OPERATIONS_CONTROL_CENTRE} element={<ControlCentre />} />
            <Route path={ROUTE_OPERATIONS_ON_DEMAND_BOOKING_SUMMARY} element={<OnDemandBookingSummary />} />
            <Route path={ROUTE_OPERATIONS_ASSIGNED_BOOKING_SUMMARY} element={<AssignedBookingSummary />} />
            <Route path={ROUTE_OPERATIONS_GUEST_BOOKING_SUMMARY} element={<GuestBookingSummary />} />
            <Route path={ROUTE_OPERATIONS_FORCE_GATE_OPEN} element={<ForceGateOpen />} />
          </Route>

          <Route path={ROUTE_ON_DEMAND} element={<PrivateLayout />}>
            <Route index element={<OnDemand />} />
            <Route path={ROUTE_ON_DEMAND_BLOCK_OUT_DATES} element={<BlockOutDates />} />
            <Route path={ROUTE_ON_DEMAND_PRODUCTS_CAPACITIES} element={<ProductsCapacities />} />
            <Route path={ROUTE_ON_DEMAND_PRODUCT_DETAIL} element={<ProductDetail />} />
            <Route path={ROUTE_ON_DEMAND_TRANSACTION} element={<Transactions />} />
            <Route path={ROUTE_ON_DEMAND_TRANSACTION_DETAIL} element={<TransactionDetail />} />
            <Route path={ROUTE_ON_DEMAND_REFUND_TRANSACTION_DETAIL} element={<RefundTransactionDetail />} />
            <Route path={ROUTE_ON_DEMAND_REFUND_CONFIRMATION_DETAIL} element={<RefundConfirmationDetail />} />
            <Route path={ROUTE_ON_DEMAND_BOOK_PARKING} element={<BookParking />} />
            <Route path={ROUTE_ON_DEMAND_CHANGE_BOOKING} element={<ChangeBooking />} />
          </Route>

          <Route path={ROUTE_GUEST} element={<PrivateLayout />}>
            <Route index element={<Guest />} />
            <Route path={ROUTE_GUEST_BOOK_GUEST_PARKING} element={<BookGuestParking isEdit={false} />} />
            <Route path={ROUTE_GUEST_EDIT_GUEST_PARKING} element={<BookGuestParking isEdit={true} />} />
            <Route path={ROUTE_GUEST_TRANSACTION} element={<GuestTransactions />} />
            <Route path={ROUTE_GUEST_TRANSACTION_DETAIL} element={<GuestTransactionDetail />} />
          </Route>

          <Route path={ROUTE_USERS} element={<PrivateLayout />}>
            <Route index element={<Users />} />
            <Route path={ROUTE_USERS_MANAGE_USERS} element={<ManageUsers />} />
            <Route path={ROUTE_USERS_BULK_UPLOADS} element={<BulkUploads />} />
          </Route>

          <Route path={ROUTE_ASSIGNED} element={<PrivateLayout />}>
            <Route index element={<Assigned />} />
            <Route path={ROUTE_ASSIGNED_MANAGE_PARKERS} element={<ManageParkers />} />
            <Route path={ROUTE_ASSIGNED_ASSIGN_PARKING} element={<AssignParking />} />
            <Route path={ROUTE_ASSIGNED_MANAGE_BAY} element={<ManageBay />} />
            <Route path={ROUTE_ASSIGNED_MANAGE_PARKERS_VIEW_ENTITLEMENT} element={<ViewEntitlement />} />
            <Route path={ROUTE_ASSIGNED_EDIT_ASSIGN_BAY} element={<AssignParking />} />
          </Route>

          <Route path={ROUTE_HOME} element={<PrivateLayout />}>
            <Route index element={<HomePage />} />
          </Route>

          <Route path={ROUTE_TENANT_SELECTOR}>
            <Route index element={<TenantSelector />} />
          </Route>

          <Route path="/" element={<PrivateLayout />}>
            <Route index element={<HomePage />} />
          </Route>

          <Route path="/*" element={<PublicLayout />}>
            <Route index element={<RouteNotFound />} />
          </Route>

          <Route path="/error" element={<PublicLayout />}>
            <Route index element={<GlobalError />} />
          </Route>

          <Route path="/not-found" element={<PublicLayout />}>
            <Route index element={<RouteNotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  )
}

export default MainNavigation
