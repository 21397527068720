import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import backIcon from '../../../assets/svg/ws-ui-icon-arrow-back-grey.svg'
import { resetAggregate } from '../../../common/aggregate-actions'
import {
  AGGREGATE_AGREEMENTS_DETAIL,
  AGGREGATE_DEPARTMENTS_TEAMS,
  AGGREGATE_MEMBER,
} from '../../../common/aggregate.types'
import { chooseUrl, fetchAggregate, fetchAggregates } from '../../../common/axios-action'
import { TransactionStatus } from '../../../common/types'
import { formatNumberWithDecimalPlace, getAgreementStatusFormattedName } from '../../../common/utility'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { authorizationHeaderConfig } from '../../../config/axios/axios'
import { RootState } from '../../../store'
import {
  DEPARTMENTS_TEAMS_API_RESOURCE_PATH,
  DepartmentTeamListItemDto,
} from '../../../store/common/departments-teams/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { MemberForm, TENANT_MEMBER_API_RESOURCE_PATH } from '../../settings/users/active-users/types'
import {
  AGREEMENTS_DETAIL_API_RESOURCE_PATH,
  AgreementsDetailState,
  ORDER_REFUND_ADMIN_API_RESOURCE_PATH,
  PartialRefundItem,
  REFUND_FAILED_MESSAGE,
  RefundAmountType,
  getBookingCost,
} from './types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0px',
    maxWidth: '200px',
  },
}))

interface IProps {}
const RefundTransactionDetail: React.FC<IProps> = (props) => {
  const { state } = useLocation()
  const classes = useStyles()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)

  const [isAllRequestCompleted, setIsAllRequestCompleted] = useState<boolean>(false)
  const [isValidRefundAmount, setIsValidRefundAmount] = useState<boolean>(false)
  const [isProcessRefund, setIsProcessRefund] = useState<boolean>(false)

  const [refundAmountType, setRefundAmountType] = useState<string>('')
  const [partialRefundAmount, setPartialRefundAmount] = useState<string>('')
  const [specifyRefundAmount, setSpecifyRefundAmount] = useState<string>('')

  const [partialRefundList, setPartialRefundList] = useState<PartialRefundItem[]>([])

  const [newBookingOrderId, setNewBookingOrderId] = useState<string>('')

  const {
    agreementDetail,
    memberData,
    departmentTeamsList,
    isAgreementsDetailRequestCompleted,
    isManageUserRequestCompleted,
    isDepartmentTeamRequestCompleted,
    shuffleApiConfig,
    user,
  } = useSelector((state: RootState) => ({
    agreementDetail: state.agreementsDetailReducer.aggregate,
    isAgreementsDetailRequestCompleted: state.agreementsDetailReducer.updatedApplicationStatus,
    memberData: state.manageUserReducer.aggregate,
    isManageUserRequestCompleted: state.manageUserReducer.updatedApplicationStatus,
    departmentTeamsList: state.departmentsTeamsReducer.aggregates.values,
    isDepartmentTeamRequestCompleted: state.departmentsTeamsReducer.updatedApplicationStatus,
    shuffleApiConfig: state.appConfigReducer.shuffleApiConfig,
    user: state.authReducer.user,
  }))

  // Get agreement details or parking items details
  useEffect(() => {
    if (state) {
      if (currentOperatorId && currentOrganisationId) {
        const { orderId, newBookingOrderId }: any = state
        setNewBookingOrderId(newBookingOrderId)
        dispatch(
          fetchAggregate<AgreementsDetailState>(
            AGGREGATE_AGREEMENTS_DETAIL,
            AGREEMENTS_DETAIL_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ) + `/${orderId}`,
            null,
          ),
        )
      }
    } else {
      navigate('/on-demand/transactions')
    }
    // eslint-disable-next-line
  }, [dispatch, currentOperatorId, currentOrganisationId, navigate, state])

  // Get members
  useEffect(() => {
    if (agreementDetail && currentOperatorId && currentOrganisationId) {
      const memberId = agreementDetail.memberId
      if (memberId) {
        dispatch(
          fetchAggregate<MemberForm>(
            AGGREGATE_MEMBER,
            TENANT_MEMBER_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':carParkTenantId',
              currentOrganisationId,
            ),
            memberId,
          ),
        )
      }
    }
  }, [dispatch, currentOperatorId, currentOrganisationId, agreementDetail])

  // Get departments and teams
  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(
        fetchAggregates<DepartmentTeamListItemDto>(
          AGGREGATE_DEPARTMENTS_TEAMS,
          DEPARTMENTS_TEAMS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
  }, [dispatch, currentOperatorId, currentOrganisationId])

  // Manage loading
  useEffect(() => {
    if (isAgreementsDetailRequestCompleted && isManageUserRequestCompleted && isDepartmentTeamRequestCompleted) {
      setIsAllRequestCompleted(true)
    } else {
      setIsAllRequestCompleted(false)
    }
  }, [isAgreementsDetailRequestCompleted, isManageUserRequestCompleted, isDepartmentTeamRequestCompleted])

  useEffect(() => {
    if (agreementDetail) {
      let cost = getBookingCost(agreementDetail)
      if (cost > 0) {
        let itemList: PartialRefundItem[] = []
        let options = [10, 20, 30, 40, 50, 60, 70, 80, 90]
        options.forEach((per) => {
          let item: PartialRefundItem = {
            name: per + '%',
            value: ((cost * per) / 100).toFixed(2),
          }
          itemList.push(item)
        })
        setPartialRefundList(itemList)
      }
    }
    // eslint-disable-next-line
  }, [agreementDetail])

  // Check button validation
  useEffect(() => {
    let isValid: boolean = false
    if (agreementDetail && refundAmountType) {
      if (refundAmountType === RefundAmountType.Full) {
        isValid = true
      }
      if (refundAmountType === RefundAmountType.Partial) {
        if (partialRefundAmount) {
          let cost = getBookingCost(agreementDetail)
          let amount = parseFloat(partialRefundAmount)
          if (amount !== 0 && amount <= cost) {
            isValid = true
          }
        }
      }
      if (refundAmountType === RefundAmountType.Specify) {
        if (specifyRefundAmount) {
          let cost = getBookingCost(agreementDetail)
          let amount = parseFloat(specifyRefundAmount)
          if (amount !== 0 && amount <= cost) {
            isValid = true
          }
        }
      }
    }
    setIsValidRefundAmount(isValid)
    // eslint-disable-next-line
  }, [agreementDetail, refundAmountType, partialRefundAmount, specifyRefundAmount])

  // On unmount
  useEffect(() => {
    return () => {
      resetData()
    }
    // eslint-disable-next-line
  }, [])

  // User section
  const getDepartmentNameAndTeamName = (departmentId: string, teamId: string | null): string => {
    const department: DepartmentTeamListItemDto | undefined = departmentTeamsList.find(
      (dept: DepartmentTeamListItemDto) => dept.id === departmentId,
    )
    let name: string
    if (department) {
      if (teamId === null) {
        name = department.name
      } else {
        const team: any = department.teams.find((team) => team.id === teamId)
        if (team) {
          name = team.name
        } else {
          name = ''
        }
      }
      return name
    }
    return ''
  }

  // Parking section
  const getAgreementStayDuration = () => {
    if (agreementDetail) {
      let agreement = agreementDetail.stayAgreements[0]
      let startDateTime = format(Date.parse(agreement.singleStay.startDateTime), 'E dd MMMM hh:mmaaa')
      let finishDateTime = format(Date.parse(agreement.singleStay.finishDateTime), 'E dd MMMM hh:mmaaa')
      return startDateTime + ' - ' + finishDateTime
    } else {
      return ''
    }
  }

  // Refund Amount Section
  const onChangeRefundAmountType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundAmountType((event.target as HTMLInputElement).value)
    setPartialRefundAmount('')
    setSpecifyRefundAmount('')
  }

  const onChangeSpecifyRefundAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setSpecifyRefundAmount(value)
  }

  const getRefundAmountErrorMessage = (): string => {
    let message: string = ''
    if (agreementDetail && specifyRefundAmount) {
      let cost = getBookingCost(agreementDetail)
      let amount = parseFloat(specifyRefundAmount)
      if (amount === 0) {
        message = '$0.00 is not valid amount.'
      }
      if (amount > cost) {
        message = 'Refund amount must be equal to or less than booking cost.'
      }
    }
    return message
  }

  const onBack = () => {
    resetData()
    if (agreementDetail) {
      navigate('/on-demand/transactions/transaction-detail', {
        state: {
          orderId: newBookingOrderId ? newBookingOrderId : agreementDetail.orderId,
        },
      })
    }
  }

  const refundAdmin = async () => {
    setIsProcessRefund(true)
    let resourcePath = ORDER_REFUND_ADMIN_API_RESOURCE_PATH
    if (currentOperatorId && currentOrganisationId && agreementDetail) {
      let payloadAmount: number = 0
      if (refundAmountType === RefundAmountType.Full) {
        payloadAmount = getBookingCost(agreementDetail)
      }
      if (refundAmountType === RefundAmountType.Partial) {
        payloadAmount = parseFloat(partialRefundAmount)
      }
      if (refundAmountType === RefundAmountType.Specify) {
        payloadAmount = parseFloat(specifyRefundAmount)
      }
      resourcePath = resourcePath
        .replace(':operatorId', currentOperatorId)
        .replace(':carParkTenantId', currentOrganisationId)
        .replace(':memberId', agreementDetail.memberId)
        .replace(':orderId', agreementDetail.orderId)
      try {
        let authorization = user!.getIdToken()
        await authorization
          .then((token: any) => {
            let config = authorizationHeaderConfig(chooseUrl(shuffleApiConfig), token)
            config.timeout = 60000
            axios
              .post<any>(resourcePath, { amount: payloadAmount }, config)
              .then((response) => {
                if (response.data.transactionStatus === TransactionStatus.Failed) {
                  dispatch(setNotification(NotificationType.ERROR, [REFUND_FAILED_MESSAGE]))
                } else {
                  dispatch(setNotification(NotificationType.INFO, ['The booking has been refunded.']))
                }
                setIsProcessRefund(false)
                onBack()
              })
              .catch((error: any) => {
                console.error(error)
                onBack()
                setIsProcessRefund(false)
                dispatch(setNotification(NotificationType.ERROR, [REFUND_FAILED_MESSAGE]))
              })
          })
          .catch((error: any) => {
            console.error(error)
            onBack()
            setIsProcessRefund(false)
            dispatch(setNotification(NotificationType.ERROR, [REFUND_FAILED_MESSAGE]))
          })
      } catch (err) {
        console.error(err)
        onBack()
        setIsProcessRefund(false)
        dispatch(setNotification(NotificationType.ERROR, [REFUND_FAILED_MESSAGE]))
      }
    }
  }

  const resetData = () => {
    dispatch(resetAggregate<AgreementsDetailState>(AGGREGATE_AGREEMENTS_DETAIL))
    dispatch(resetAggregate<MemberForm>(AGGREGATE_MEMBER))
  }

  const onChangePartialRefundAmount = (event: any) => {
    setPartialRefundAmount(event.target.value as string)
  }

  return (
    <div className="page-content-wrapper ondemand-section">
      {/* Left side section */}
      <Container className="page-content-header-wrapper">
        <Grid container spacing={2} className="two-column-inner-wrapper" justifyContent="flex-start">
          <Grid item xs={3} className="left-col sidebar top-section">
            <Grid container spacing={0} className="responsive-title-wrapper">
              <Grid item xs={12}>
                <h2 className="on-demand-h2">
                  <span className="back-icon" onClick={onBack}>
                    <img className="icon-back-arrow" src={backIcon} alt="back" />
                    On-demand parking
                  </span>
                </h2>
              </Grid>
            </Grid>
            {agreementDetail && (
              <Grid container spacing={0} className="responsive-content-wrapper">
                <Grid item xs={12}>
                  <p style={{ marginBottom: '10px' }}>
                    Transaction ID: {agreementDetail.orderReference}
                    <br />
                    Booking status: {getAgreementStatusFormattedName(agreementDetail.stayAgreements[0].agreementStatus)}
                  </p>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      {/* Right side section */}
      <Container className="page-content-body-wrapper ondemand-section">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={9} className="right-col">
            {isAllRequestCompleted && agreementDetail ? (
              <>
                <Grid item xs={12} className="content-panel" style={{ minHeight: 410 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-10 padding-top-5">About this transaction</h3>
                    </Grid>
                  </Grid>
                  {/* User Section */}
                  <Grid container spacing={2} className="transaction-detail first-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>User</h5>
                        </Grid>
                        <Grid item xs={5}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{agreementDetail.memberName}</td>
                              </tr>
                              {memberData && (
                                <>
                                  <tr>
                                    <td>{memberData.email}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {memberData.departmentId
                                        ? getDepartmentNameAndTeamName(memberData.departmentId, null)
                                        : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {memberData.departmentId
                                        ? getDepartmentNameAndTeamName(memberData.departmentId, memberData.teamId)
                                        : ''}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Parking Section */}
                  <Grid container spacing={2} className="transaction-detail">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Parking</h5>
                        </Grid>
                        <Grid item xs={8}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>{agreementDetail.stayAgreements[0].facilityName}</td>
                              </tr>
                              <tr>
                                <td>{agreementDetail?.offer?.name}</td>
                              </tr>
                              <tr>
                                <td>{getAgreementStayDuration()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Cost Section */}
                  <Grid container spacing={2} className="transaction-detail last-item">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <h5>Cost</h5>
                        </Grid>
                        <Grid item xs={6}>
                          <table id="transaction-detail-table">
                            <tbody>
                              <tr>
                                <td>${formatNumberWithDecimalPlace(getBookingCost(agreementDetail), 2)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Refund Amount Section */}
                  <Grid container spacing={2} className="transaction-detail refund-type-section last-item">
                    <Grid item xs={12}>
                      <RadioGroup row value={refundAmountType} onChange={(event) => onChangeRefundAmountType(event)}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={8}>
                            <Grid container spacing={2}>
                              {/* Full */}
                              <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <FormControlLabel
                                  className="radio-group-label"
                                  value={RefundAmountType.Full}
                                  control={<Radio />}
                                  label="Full Refund"
                                />
                              </Grid>
                              {/* Specify */}
                              <Grid item xs={12} style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                  className="radio-group-label"
                                  value={RefundAmountType.Specify}
                                  control={<Radio />}
                                  label="Specify Refund Amount"
                                />
                                <NumericFormat
                                  value={specifyRefundAmount}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  disabled={refundAmountType !== RefundAmountType.Specify}
                                  customInput={TextField}
                                  onChange={(event) => onChangeSpecifyRefundAmount(event)}
                                  InputProps={{
                                    className: 'refundAmountInput',
                                    startAdornment: specifyRefundAmount ? (
                                      <InputAdornment position="start">$</InputAdornment>
                                    ) : null,
                                  }}
                                  placeholder="Refund $"
                                  inputProps={{
                                    sx: {
                                      '&::placeholder': {
                                        color: '#525252',
                                        opacity: 1,
                                      },
                                    },
                                  }}
                                  style={{ width: 160, marginLeft: 6 }}
                                />
                                <div className="refund-amount-error">{getRefundAmountErrorMessage()}</div>
                              </Grid>
                              {/* Partial */}
                              <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <FormControlLabel
                                  className="radio-group-label"
                                  value={RefundAmountType.Partial}
                                  control={<Radio />}
                                  label="Partial Refund"
                                />
                                <TextField
                                  select
                                  label={partialRefundAmount === '' ? 'Refund %' : ''}
                                  className="partial-refund-amount-select"
                                  value={partialRefundAmount}
                                  onChange={onChangePartialRefundAmount}
                                  InputLabelProps={{ shrink: false }}
                                  SelectProps={{
                                    MenuProps: {
                                      className: 'partial-refund-amount-select-menu',
                                    },
                                  }}
                                  margin="normal"
                                  disabled={refundAmountType !== RefundAmountType.Partial}
                                  variant="outlined"
                                  style={{ width: 161, marginLeft: 76 }}
                                >
                                  {partialRefundList.map((item) => {
                                    return (
                                      <MenuItem key={item.name} value={item.value}>
                                        {item.name}&nbsp;&nbsp;&nbsp;$
                                        {formatNumberWithDecimalPlace(parseFloat(item.value), 2)}
                                      </MenuItem>
                                    )
                                  })}
                                </TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Button Section */}
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-start">
                        <Button
                          className={classes.shuffleButton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={onBack}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className={classes.shuffleButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={!isValidRefundAmount}
                          onClick={refundAdmin}
                        >
                          {isProcessRefund ? (
                            <CircularProgress style={{ color: '#FFFFFF', width: 29, height: 29 }} />
                          ) : (
                            'Process Refund'
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                className="content-panel"
                style={{ minHeight: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <CircularProgress color="primary" size={35} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default RefundTransactionDetail
