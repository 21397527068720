import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MemberForm } from '../../pages/settings/users/active-users/types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { ErrorResponse, UpdateDeleteMode } from '../../common/types'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { ExistsAssignments } from '../../store/common/member-assignment/types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginBottom: '5px',
    borderRadius: '4px',
    letterSpacing: '0',
    padding: '7px 0',
  },
  shuffleLabel: {
    backgroundColor: '#ffffff',
  },
  gridTopMargin: {
    paddingTop: '17px',
  },
  gridSmlTopMargin: {
    paddingTop: '15px',
  },
  formModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    maxWidth: '440px',
    maxHeight: '400px',
    textAlign: 'center',
  },
  descLabel: {
    marginTop: '35px',
    color: '#ff0000 !important',
    fontWeight: '500',
    cursor: 'pointer',
  },
}))

interface IProps {
  display: boolean
  hideModel: () => void
  hideWhenDeleteSuccess: () => void
  member: MemberForm | null
  confirmMemberDelete: (acknowledge: boolean) => void
  existsAssignment: ExistsAssignments | null
  activeAssignment: ExistsAssignments | null
  acknowledgeDelete: boolean
  setAcknowledgeDelete: Dispatch<SetStateAction<boolean>>
  mode: UpdateDeleteMode
}

const DeleteModel: React.FC<IProps> = (props) => {
  const { display, hideModel, member, confirmMemberDelete, hideWhenDeleteSuccess, existsAssignment } = props

  const dispatch = useDispatch()

  const confirmDeleteWithAcknowledge = () => {
    confirmMemberDelete(true)
  }

  const confirmDeleteNone = () => {
    confirmMemberDelete(false)
  }

  const [displayName, setDisplayName] = useState<string | null>(null)

  const { errorOnDelete, deleteSuccess } = useSelector(
    (state: RootState) => ({
      errorOnDelete: state.manageUserReducer.error,
      deleteSuccess: state.manageUserReducer.deleted,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (member) {
      setDisplayName(member.firstName + ' ' + member.lastName)
    }
  }, [member])

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setNotification(NotificationType.INFO, [displayName + ' has been deleted']))
      hideWhenDeleteSuccess()
    }
  }, [deleteSuccess, hideModel, dispatch, displayName, hideWhenDeleteSuccess])

  useEffect(() => {
    if (errorOnDelete) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(errorOnDelete))
      if (errors.length > 0) {
        dispatch(
          setNotification(NotificationType.ERROR, [
            'Unexpected error when deleting ' + displayName + '. Please try again',
          ]),
        )
      }
    }
  }, [errorOnDelete, displayName, dispatch])

  const classes = useStyles()
  return (
    <div className={display ? 'modalOverlayBG' : 'modalOverlayBGHide'}>
      <Box className={classes.formModal}>
        <Container maxWidth="lg" disableGutters={true} className="form-modal-styles">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {existsAssignment?.exists && (
                <>
                  <h3 className="modal-h3 larger-text">
                    The Employee you are deleting has Assigned Parking or Bookings.
                  </h3>
                  <p>
                    Select <strong>Cancel</strong> to review, or <strong>Proceed</strong> to delete the Assigned Parking
                    or Bookings.
                  </p>
                </>
              )}
              {!existsAssignment?.exists && (
                <>
                  <h3 className="modal-h3 larger-text">
                    Are you sure you want to delete {displayName ? displayName : ''}?
                  </h3>
                  <p>
                    Once deleted, {displayName ? displayName : ''} won't be able to login unless they create a new
                    account.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '100px' }}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <Button
                  className={classes.shuffleButton}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={hideModel}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                {existsAssignment?.exists && (
                  <Button
                    className={classes.shuffleButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={confirmDeleteWithAcknowledge}
                  >
                    Proceed
                  </Button>
                )}

                {!existsAssignment?.exists && (
                  <Button
                    className={classes.shuffleButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={confirmDeleteNone}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default DeleteModel
