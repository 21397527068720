import { AggregateState } from '../../../common/types'
import { AggregateActionTypes } from '../../../common/aggregate.types'

export interface TenantSetting {
  globalAccess: boolean
  id: string
}

export type TenantSettingState = AggregateState<TenantSetting>
export type TenantSettingActionTypes = AggregateActionTypes<TenantSetting>

export const TENANT_SETTINGS_API_RESOURCE_PATH = '/operators/:operatorId/carpark-tenants/:carParkTenantId/settings'
