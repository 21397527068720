import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface StartAndEndBlockOut {
  startDate: Date | null
  startTime: Date | null
  endDate: Date | null
  endTime: Date | null
}
export interface StartAndEndBlockOutError {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}

export interface OrdersForAccessRestrictions {
  count: number
}

export type OrdersForAccessRestrictionsState = AggregateState<OrdersForAccessRestrictions>
export type OrdersForAccessRestrictionsActionTypes = AggregateActionTypes<OrdersForAccessRestrictions>

export const ORDERS_FOR_ACCESS_RESTRICTIONS_GET_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/orders-for-access-restrictions/:carParkTenantAccessRestrictionId/count'
export const ORDERS_FOR_ACCESS_RESTRICTIONS_POST_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/orders-for-access-restrictions/:carParkTenantAccessRestrictionId:cancel'

export const ORDERS_FOR_ACCESS_RESTRICTIONS_COUNT_POST_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/orders-for-access-restrictions/count'
