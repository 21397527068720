import React from 'react'

export type Order = 'asc' | 'desc'

const usePagination = (noOfRowsPerPage?: number) => {
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(noOfRowsPerPage ? noOfRowsPerPage : 10)
  const [sortDirection, setSortDirection] = React.useState<Order>('asc')
  const [sortBy, setSortBy] = React.useState<string>('code')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(1)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string | null | undefined) => {
    if (property) {
      const isAsc = sortBy === property && sortDirection === 'asc'
      setSortDirection(isAsc ? 'desc' : 'asc')
      setSortBy(property)
    }
  }

  return [
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
  ] as const
}
export default usePagination
