import { AggregateActionTypes } from '../../../common/aggregate.types'
import { AggregateState } from '../../../common/types'

export interface GateListItem {
  id: string
  clientRef: string
  name: string
  accessPointId: string
  accessPointName: string
}

export type GateListItemState = AggregateState<GateListItem>
export type GateListItemActionTypes = AggregateActionTypes<GateListItem>

export const TENANCY_GATE_LIST_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/carpark-tenancies/:carParkTenancyId/gates-list'
