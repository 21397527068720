import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios, { AxiosRequestConfig } from 'axios'
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AGGREGATE_TENANT_SETTINGS, AGGREGATE_TERRITORIES } from '../../../../common/aggregate.types'
import { fetchAggregate, fetchAggregates } from '../../../../common/axios-action'
import { selectCurrentOperatorId } from '../../../../config/app/reducers'
import { RootState } from '../../../../store'
import { selectCurrentOrganisationId } from '../../../auth/types'
import {
  Facility,
  TENANCIES_BY_TERRITORY_API_RESOURCE_PATH,
  TERRITORIES_API_RESOURCE_PATH,
  Territory,
  TerritoryAndFacility,
} from './types'
import { useAxiosConfig } from '../../../../hook/useAxiosConfig'
import { TENANT_SETTINGS_API_RESOURCE_PATH, TenantSetting } from '../../../../store/common/tenant-settings/types'
import { customSorting } from '../../../../common/utility'

interface IProps {
  setLocationSelection: Dispatch<SetStateAction<TerritoryAndFacility[]>>
  setIsLocationChange?: Dispatch<SetStateAction<boolean>>
  territoryIds: string[] | null
  facilityIds: string[] | null
  isAdd: boolean
}
const useStyles = makeStyles((theme) => ({
  locationSection: {
    width: '100%',
  },
  locationSectionDesc: {
    fontSize: '12px',
    color: 'gray !important',
    fontWeight: 500,
    paddingTop: '5px',
  },
  checkboxSection: {
    paddingTop: '20px',
  },
}))

const UserTerritoryAndFacility: React.FC<IProps> = ({
  setLocationSelection,
  setIsLocationChange,
  territoryIds,
  facilityIds,
  isAdd,
}: IProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  let [territoryAndFacilityList, setTerritoryAndFacilityList] = useState<TerritoryAndFacility[]>([])
  let [isLoading, setIsLoading] = useState<boolean>(true)
  let [isAllLocations, setAllLocations] = useState<boolean>(false)
  const [axiosConfig] = useAxiosConfig()

  const { territoryList, carParkTenantSetting } = useSelector((state: RootState) => ({
    territoryList: state.territoryReducer.aggregates.values,
    carParkTenantSetting: state.tenantSettingsReducer.aggregate,
  }))

  useEffect(() => {
    if (currentOperatorId && currentOrganisationId) {
      dispatch(fetchAggregates<Territory>(AGGREGATE_TERRITORIES, TERRITORIES_API_RESOURCE_PATH, null))
      dispatch(
        fetchAggregate<TenantSetting>(
          AGGREGATE_TENANT_SETTINGS,
          TENANT_SETTINGS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ),
          null,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOperatorId, currentOrganisationId])

  const getFacilities = (territory: Territory, config: AxiosRequestConfig) => {
    config.params = { territoryId: territory.id }
    let resourcePath = TENANCIES_BY_TERRITORY_API_RESOURCE_PATH
    return new Promise((resolve, reject) => {
      if (currentOperatorId && currentOrganisationId && territory.id) {
        resourcePath = resourcePath
          .replace(':operatorId', currentOperatorId)
          .replace(':carParkTenantId', currentOrganisationId)
        axios
          .get<any>(resourcePath, config)
          .then(function (response) {
            let tempTerritory: TerritoryAndFacility = {
              id: territory.id,
              name: territory.name,
              checked: false,
              disabled: false,
              facilities: [],
            }
            if (territoryIds && territoryIds.includes(territory.id)) {
              tempTerritory.checked = true
              tempTerritory.facilities = response.data.map((facility: Facility) => {
                return {
                  id: facility.id,
                  name: facility.name,
                  checked: true,
                  disabled: true,
                }
              })
            } else {
              tempTerritory.facilities = response.data.map((facility: Facility) => {
                return {
                  id: facility.id,
                  name: facility.name,
                  checked: facilityIds?.includes(facility.id) ? true : false,
                  disabled: false,
                }
              })
            }
            resolve(tempTerritory)
          })
          .catch(function (error) {
            console.debug(error)
          })
      }
    })
  }

  async function loadTerritoryAndFacility() {
    let territoryAndFacilityList: any[] = []
    territoryList.forEach((territory: Territory) => {
      let config: AxiosRequestConfig = { ...axiosConfig }
      territoryAndFacilityList.push(getFacilities(territory, config))
    })
    Promise.all(territoryAndFacilityList).then((territoryList) => {
      let validTerritory: TerritoryAndFacility[] = territoryList.filter(
        (territory: TerritoryAndFacility) => territory.facilities.length > 0,
      )
      if (!isAdd) {
        let selectedTerritory = validTerritory.filter((territory: TerritoryAndFacility) => territory.checked === true)
        if (validTerritory.length === selectedTerritory.length) {
          validTerritory.forEach((territory: TerritoryAndFacility) => {
            territory.checked = true
            territory.disabled = true
            territory.facilities.forEach((facility: Facility) => {
              facility.checked = true
              facility.disabled = true
            })
          })
          setAllLocations(true)
        }
      }
      setTerritoryAndFacilityList(validTerritory)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (territoryList && territoryList.length > 0 && axiosConfig) {
      setTerritoryAndFacilityList([])
      loadTerritoryAndFacility()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territoryList, axiosConfig])

  const handleChangeAllLocations = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!carParkTenantSetting?.globalAccess) {
      let isChecked = event.target.checked
      // Use deep copy to resolve reference issue
      let tempDeepCopy: TerritoryAndFacility[] = JSON.parse(JSON.stringify(territoryAndFacilityList))
      tempDeepCopy.forEach((territory: TerritoryAndFacility) => {
        territory.checked = isChecked
        territory.disabled = isChecked
        territory.facilities.forEach((facility: Facility) => {
          facility.checked = isChecked
          facility.disabled = isChecked
        })
      })
      setAllLocations(isChecked)
      setTerritoryAndFacilityList(tempDeepCopy)
      setLocationSelection(tempDeepCopy)
      if (!isAdd && setIsLocationChange) {
        setIsLocationChange(true)
      }
    }
  }
  const handleChangeTerritory = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!carParkTenantSetting?.globalAccess) {
      let isChecked = event.target.checked
      let territoryId = event.target.name
      // Use deep copy to resolve reference issue
      let tempDeepCopy: TerritoryAndFacility[] = JSON.parse(JSON.stringify(territoryAndFacilityList))
      let selectedTerritory: TerritoryAndFacility | undefined = tempDeepCopy.find(
        (territory: TerritoryAndFacility) => territory.id === territoryId,
      )
      if (selectedTerritory) {
        selectedTerritory.checked = isChecked
        selectedTerritory.facilities.forEach((facility: Facility) => {
          facility.checked = isChecked
          facility.disabled = isChecked
        })
      }
      setTerritoryAndFacilityList(tempDeepCopy)
      setLocationSelection(tempDeepCopy)
      if (!isAdd && setIsLocationChange) {
        setIsLocationChange(true)
      }
    }
  }
  const handleChangeFacility = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!carParkTenantSetting?.globalAccess) {
      let isChecked = event.target.checked
      let names = event.target.name.split('/')
      let territoryId = names[0]
      let facilityId = names[1]
      // Use deep copy to resolve reference issue
      let tempDeepCopy: TerritoryAndFacility[] = JSON.parse(JSON.stringify(territoryAndFacilityList))
      let selectedTerritory: TerritoryAndFacility | undefined = tempDeepCopy.find(
        (territory: TerritoryAndFacility) => territory.id === territoryId,
      )
      if (selectedTerritory) {
        let selectedFacility: Facility | undefined = selectedTerritory.facilities.find(
          (facility: Facility) => facility.id === facilityId,
        )
        if (selectedFacility) {
          selectedFacility.checked = isChecked
        }
      }
      setTerritoryAndFacilityList(tempDeepCopy)
      setLocationSelection(tempDeepCopy)
      if (!isAdd && setIsLocationChange) {
        setIsLocationChange(true)
      }
    }
  }

  return (
    <div className={classes.locationSection}>
      <h3>Locations available to this user</h3>
      <p className={classes.locationSectionDesc}>
        Choose the locations available for this user for assigned or on-demand parking.
      </p>
      {territoryList.length > 0 && !isLoading ? (
        <div className={classes.checkboxSection}>
          <FormGroup>
            <FormControlLabel
              className="territory-form-control-label bold"
              disabled={carParkTenantSetting?.globalAccess ? true : false}
              control={
                <Checkbox
                  checked={carParkTenantSetting?.globalAccess ? true : isAllLocations}
                  onChange={handleChangeAllLocations}
                />
              }
              label="All Locations"
            />
          </FormGroup>
          <div className="custom-divider"></div>
          {territoryAndFacilityList &&
            territoryAndFacilityList.length > 0 &&
            territoryAndFacilityList.sort(customSorting).map((territory: TerritoryAndFacility) => {
              return (
                <Fragment key={territory.id}>
                  <FormGroup>
                    <FormControlLabel
                      className="territory-form-control-label bold"
                      disabled={carParkTenantSetting?.globalAccess ? true : territory.disabled}
                      control={
                        <Checkbox
                          checked={carParkTenantSetting?.globalAccess ? true : territory.checked}
                          onChange={handleChangeTerritory}
                          name={territory.id}
                        />
                      }
                      label={territory.name}
                    />
                  </FormGroup>
                  <FormGroup style={{ flexDirection: 'row' }}>
                    {territory.facilities.sort(customSorting).map((facility: Facility, index: number) => {
                      return (
                        <FormControlLabel
                          key={facility.id}
                          className="territory-form-control-label light"
                          disabled={carParkTenantSetting?.globalAccess ? true : facility.disabled}
                          style={{ paddingLeft: index % 2 !== 0 ? '18px' : '0' }}
                          control={
                            <Checkbox
                              checked={carParkTenantSetting?.globalAccess ? true : facility.checked}
                              onChange={handleChangeFacility}
                              name={territory.id + '/' + facility.id}
                            />
                          }
                          label={facility.name}
                        />
                      )
                    })}
                  </FormGroup>
                  <div className="custom-divider"></div>
                </Fragment>
              )
            })}
        </div>
      ) : (
        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <CircularProgress color="primary" style={{ width: 30, height: 30 }} />
        </div>
      )}
    </div>
  )
}

export default UserTerritoryAndFacility
