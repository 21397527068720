import Grid from '@mui/material/Grid'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import { useEffect, useState } from 'react'
import { NameValueDTO } from '../../../common/types'
import { TenantOffer } from '../../../store/common/tenancies/types'
import { getPieChartOption } from '../../util/echarts'
import {
  AgreementAuthorityType,
  agreementAuthorityTypeForDailyCountAllocation,
  AuthorityTypeCountAllocation,
  colorList,
  DailyCountAllocationByAuthorityByOffer,
  DailyCountAllocationChartData,
} from './types'

interface IProps {
  dailyCountAllocationChartDetail: DailyCountAllocationByAuthorityByOffer | null
  tenantOffersList: TenantOffer[]
}
const MonthlySummary: React.FC<IProps> = ({ dailyCountAllocationChartDetail, tenantOffersList }: IProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const [dailyCountAllocationList, setDailyCountAllocationList] = useState<DailyCountAllocationChartData[]>([])

  useEffect(() => {
    let chartData: NameValueDTO[] = []
    let colorPalette: string[] = []
    if (dailyCountAllocationChartDetail) {
      let allocationList: DailyCountAllocationChartData[] = []
      dailyCountAllocationChartDetail.authorityTypeCountAllocations.forEach(
        (authority: AuthorityTypeCountAllocation, index: number) => {
          let authorityType: AgreementAuthorityType | undefined = agreementAuthorityTypeForDailyCountAllocation.find(
            (type: AgreementAuthorityType) => type.id === authority.agreementAuthorityType,
          )
          let tenantOffer: TenantOffer | undefined = tenantOffersList.find(
            (offer: TenantOffer) => offer.id === authority.offerId,
          )
          let randomColor = colorList[index]
          let tempAllocation: DailyCountAllocationChartData = {
            id: authority.agreementAuthorityType,
            name: authorityType ? authorityType.name : tenantOffer ? tenantOffer.name : '',
            color: randomColor,
            count: authority.count,
            percent: authority.percent,
          }
          allocationList.push(tempAllocation)
          chartData.push({
            name: authorityType ? authorityType.name : tenantOffer ? tenantOffer.name : '',
            value: authority.count,
          })
          colorPalette.push(randomColor)
        },
      )
      setDailyCountAllocationList(allocationList)
    } else {
      setDailyCountAllocationList([])
    }
    let option: EChartsOption = getPieChartOption(chartData, colorPalette)
    setChartOption(option)
  }, [dailyCountAllocationChartDetail, tenantOffersList])

  return (
    <Grid item xs={12} className="content-panel">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Monthly summary</h3>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} style={{ paddingTop: '57px' }}>
          {chartOption && <ReactEcharts option={chartOption} style={{ height: '200px', width: '200px' }} />}
        </Grid>
        <Grid item xs={8}>
          <table id="utilisation-summary" className="ondemand-data">
            <tbody>
              <tr>
                <th>Category</th>
                <th>Utilisation</th>
              </tr>
              {dailyCountAllocationList.length > 0 ? (
                dailyCountAllocationList.map((allocation: DailyCountAllocationChartData, index: number) => {
                  return (
                    <tr key={index.toString()}>
                      <td>
                        <span
                          className="parking-key"
                          style={{ backgroundColor: allocation.color, border: `1px solid ${allocation.color}` }}
                        ></span>
                        <strong>{allocation.name}</strong>
                      </td>
                      <td>{`${allocation.count} (${allocation.percent}%)`}</td>
                    </tr>
                  )
                })
              ) : (
                <p className="no-data-found">No data found for Facility</p>
              )}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MonthlySummary
