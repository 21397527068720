import { Grid } from '@mui/material'
import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { resetAggregate } from '../../../common/aggregate-actions'
import { AGGREGATE_MEMBER_LIST, AGGREGATE_VIEW_ENTITLEMENT } from '../../../common/aggregate.types'
import { customMethodAggregateRequestAction } from '../../../common/axios-action'
import { ErrorResponse, WeekDay, weekDays } from '../../../common/types'
import ConfirmationModel from '../../../components/model/ConfirmationModel'
import LoadingInDiv from '../../../components/ui/Loading/LoadingInDiv'
import { selectCurrentOperatorId } from '../../../config/app/reducers'
import { RootState } from '../../../store'
import { Member } from '../../../store/common/member/types'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectCurrentOrganisationId } from '../../auth/types'
import { ROUTE_ASSIGNED, ROUTE_ASSIGNED_EDIT_ASSIGN_BAY } from '../../util/routes'
import { getFormattedTimeRange } from '../../util/util'
import { ENTITLEMENTS_API_RESOURCE_PATH, Slots } from '../assigned-parking/types'
import { ViewEntitlementInterface } from '../view-entitlement/types'

interface IProps {
  isNextPage: boolean
  setIsNextPage: Dispatch<SetStateAction<boolean>>
  getBayAssignments: () => void
}

const BayUse: React.FC<IProps> = ({ isNextPage, setIsNextPage, getBayAssignments }: IProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>('')
  const [modalDescription, setModalDescription] = useState<string>('')
  const [selectedEntitlementDetail, setSelectedEntitlementDetail] = useState<ViewEntitlementInterface | null>(null)

  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentOrganisationId = useSelector(selectCurrentOrganisationId)
  const { entitlementList, entitlementError, isLoadingList, entitlementInserted } = useSelector((state: RootState) => ({
    entitlementList: state.viewEntitlementReducer.aggregates.values,
    entitlementError: state.viewEntitlementReducer.error,
    isLoadingList: state.viewEntitlementReducer.loadingList,
    entitlementInserted: state.viewEntitlementReducer.inserted,
  }))

  useEffect(() => {
    if (entitlementError) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(entitlementError))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError.message ? firstError.message.substring(0, 50) : firstError.code.substring(0, 50),
          ]),
        )
        setIsNextPage(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlementError])

  useEffect(() => {
    if (entitlementInserted && selectedEntitlementDetail) {
      getBayAssignments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlementInserted])

  const getFromAndToTime = (slots: Slots[], day: string) => {
    let selectedDay: Slots | undefined = slots.find((e: Slots) => e.dayOfWeek === day)
    if (selectedDay) {
      if (!selectedDay?.fromMinutes && !selectedDay?.toMinutes) {
        return <p className="day-usage-text">All day</p>
      } else {
        return (
          <>
            <p className="day-usage-text">
              {selectedDay.fromMinutes === 0
                ? getFormattedTimeRange(0)
                : selectedDay.fromMinutes
                ? getFormattedTimeRange(selectedDay.fromMinutes)
                : ''}
            </p>
            <p className="day-usage-text" style={{ paddingTop: '5px !important' }}>
              {selectedDay.toMinutes ? getFormattedTimeRange(selectedDay.toMinutes) : ''}
            </p>
          </>
        )
      }
    }
  }

  const editAssignBay = (entitlementDetail: ViewEntitlementInterface) => {
    dispatch(resetAggregate<ViewEntitlementInterface>(AGGREGATE_VIEW_ENTITLEMENT))
    dispatch(resetAggregate<Member>(AGGREGATE_MEMBER_LIST))
    navigate(`${ROUTE_ASSIGNED}/${ROUTE_ASSIGNED_EDIT_ASSIGN_BAY}`, {
      state: { isBayEdit: true, entitlement: entitlementDetail },
    })
  }

  const unassignBay = (entitlementDetail: ViewEntitlementInterface) => {
    let memberName = entitlementDetail.member.name
    const dialogBoxTitle = `Are you sure you want to unassign ${memberName}?`
    const dialogBoxDescription = `If ${memberName} is currently in the car park, they will be able to exit, but not enter again.`
    setModalTitle(dialogBoxTitle)
    setModalDescription(dialogBoxDescription)
    setSelectedEntitlementDetail(entitlementDetail)
    setModalOpen(true)
  }

  const confirmModel = () => {
    if (currentOperatorId && currentOrganisationId && selectedEntitlementDetail) {
      dispatch(
        customMethodAggregateRequestAction<ViewEntitlementInterface>(
          AGGREGATE_VIEW_ENTITLEMENT,
          ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':carParkTenantId',
            currentOrganisationId,
          ) + `/${selectedEntitlementDetail.id}:cancel`,
          {},
        ),
      )
    }
    setModalOpen(false)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Grid item xs={12} className={!isNextPage ? 'display-none' : 'content-panel'}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h3>Bay use</h3>
          </Grid>
        </Grid>
        {isLoadingList && <LoadingInDiv />}
        {entitlementList.map((entitlementDetail: ViewEntitlementInterface) => {
          return (
            <Grid container spacing={0} className="shared-usage-list-item" marginBottom="15px !important">
              <Grid item xs={12}>
                <div className="action-box">
                  <span className="action-option" onClick={() => editAssignBay(entitlementDetail)}>
                    Edit
                  </span>
                  <span className="action-option" onClick={() => unassignBay(entitlementDetail)}>
                    Unassign
                  </span>
                </div>
              </Grid>
              <Grid item xs={5}>
                <table id="bay-use-table">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="user-name">
                        {entitlementDetail.member.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Starts</td>
                      <td>
                        <strong>
                          {entitlementDetail?.stay.startDate
                            ? format(Date.parse(entitlementDetail?.stay.startDate), 'd MMM yyyy')
                            : 'Today'}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Expires</td>
                      <td>
                        <strong>
                          {entitlementDetail?.stay.finishDate
                            ? format(Date.parse(entitlementDetail?.stay.finishDate), 'd MMM yyyy')
                            : 'Never'}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={7}>
                <div className="day-usage-wrapper">
                  <ul className="day-usage-menu">
                    {entitlementDetail &&
                      entitlementDetail.stay &&
                      weekDays.map((day: WeekDay, index) => (
                        <li className="day-usage-item" key={index + 1}>
                          <i
                            className={
                              entitlementDetail.stay.slots.some((slot) => slot.dayOfWeek === day.key)
                                ? 'day-usage-icon enabled'
                                : 'day-usage-icon disabled'
                            }
                          >
                            {day.value}
                          </i>
                          {getFromAndToTime(entitlementDetail.stay.slots, day.key)}
                        </li>
                      ))}
                  </ul>
                </div>
              </Grid>
            </Grid>
          )
        })}
        {!isLoadingList && entitlementList.length === 0 && (
          <p style={{ marginTop: '16px' }}>There are no assignments for this bay.</p>
        )}
      </Grid>
      <ConfirmationModel
        display={modalOpen}
        cancelModel={closeModal}
        confirmModel={confirmModel}
        title={modalTitle}
        description={modalDescription}
        cancelButtonName="Cancel"
        confirmButtonName="Unassign bay"
      ></ConfirmationModel>
    </>
  )
}

export default BayUse
