import React, { useState } from 'react'
import { Field, FieldProps, Form } from 'react-final-form'
import { Grid } from '@mui/material'
import { TextField } from '../../../../../components/final-form/TextField'
import { ValidationErrors } from 'final-form'
import { Mode } from '../../../../../common/types'

export interface VehicleBlock {
  vehicle: string | null
}

interface IProps {
  name: string
  currentValues: string[]
  fieldProps?: FieldProps<string[], any>
}

const VehicleDetails: React.FC<IProps> = (props) => {
  const { name } = props

  const [mode, setMode] = useState<Mode>(Mode.Add)

  const [blockInitialValue, setBlocKInitialValue] = useState<VehicleBlock>({
    vehicle: null,
  })

  let resetForm: () => void

  const onResetForm = () => {
    if (resetForm != null) {
      resetForm()
    }
  }

  const onResetPaymentVehicleForm = () => {
    if (onResetForm != null) {
      setBlocKInitialValue({
        vehicle: null,
      })
      setMode(Mode.Add)
      resetForm()
    }
  }

  return (
    <Field<string[]>
      name={name}
      render={(fieldRenderProps) => {
        const { input } = fieldRenderProps

        const onSubmit = (values: VehicleBlock) => {
          const errors: ValidationErrors = {}
          let errorFound: boolean = false

          if (!values.vehicle) {
            errors.vehicle = 'Required'
            errorFound = true
          } else {
            let blockValue: string | null = values.vehicle

            if (input.value) {
              if (input.value.find((row) => row === blockValue)) {
                errors.vehicle = 'Already added'
                errorFound = true
              } else {
                input.onChange(input.value.concat([blockValue]))
              }
            } else {
              input.onChange([blockValue])
            }
          }

          if (errorFound) {
            return errors
          } else {
            onResetPaymentVehicleForm()
          }
        }

        return (
          <Form<VehicleBlock>
            onSubmit={onSubmit}
            initialValues={blockInitialValue}
            render={({ handleSubmit, form, initialValues, submitting, pristine, values }) => {
              resetForm = form.reset
              return (
                <form onSubmit={handleSubmit} id="vehicleForm">
                  <Grid container spacing={0} className="assigned-sub-section">
                    <Grid item xs={12}>
                      <h3 className="padding-bottom-20">Vehicles</h3>
                    </Grid>

                    <Grid item xs={12}>
                      <p className="padding-bottom-20 input-label">Add another vehicle</p>
                    </Grid>

                    <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                      <TextField
                        name="vehicle"
                        InputProps={{ className: 'shuffleInput' }}
                        InputLabelProps={{ className: 'shuffleLabel' }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required={true}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                      <div
                        className="add-vehicle-link"
                        onClick={handleSubmit}
                        style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: 10 }}
                      >
                        {mode === Mode.Edit ? 'Update vehicle' : 'Add vehicle'}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          />
        )
      }}
      {...props.fieldProps}
    />
  )
}

export default VehicleDetails
