export interface ExistsAssignments {
  exists: boolean
  message: string
}

export const CHECK_EXISTS_ASSIGNMENT_PAST_CONTRACT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:id:checkValidAgreementsPastContract'

export const CHECK_EXISTS_ASSIGNMENT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/members/:id:checkValidAgreements'
