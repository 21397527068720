import { AggregateActionTypes } from '../../common/aggregate.types'
import { AggregateState, EntryStatus } from '../../common/types'

/***********************  Guest Entitlement  ***********************/
export interface BayAssignment {
  entitlementBayId?: string
  bayReservedType: string
  facilityId: string
  facilityAllocationId: string | null
  facilityZoneId: string
  bayId: string | null
  fullBayNo: string | null
  entitlementBayVersion?: number
  facilityName?: string
  facilityAllocationName?: string
  facilityZoneName?: string
}
export interface Stay {
  startDateTime: string
  finishDateTime: string
  startInstant?: number
  finishInstant?: number
  durationMinutes?: number
}
export interface Contact {
  firstName: string
  lastName: string
  email: string
  mobile: string
  companyName: string
  country?: string
}

export interface GuestEntitlement {
  id: string | null
  reference: string | null
  version: string | null
  bayAssignment: BayAssignment | null
  timeZoneId: string | null
  stay: Stay
  contact: Contact | null
  agreementStatus?: string
  allocationId?: string
  entryStatus?: EntryStatus
}

export type GuestEntitlementState = AggregateState<GuestEntitlement>
export type GuestEntitlementActionTypes = AggregateActionTypes<GuestEntitlement>

export const GUEST_ENTITLEMENT_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/guest-entitlements'

export const GUEST_ENTITLEMENT_EXPORT_CSV_API_RESOURCE_PATH =
  '/operators/:operatorId/carpark-tenants/:carParkTenantId/guest-entitlements/export-csv'

/***********************  Guest Entitlement List  ***********************/
export interface GuestEntitlementsListItem {
  id: string
  reference: string
  parker: string
  facilityName: string
  startDateTime: string
  finishDateTime: string
  agreementStatus: string
}

export type GuestEntitlementsListItemState = AggregateState<GuestEntitlementsListItem>
export type GuestEntitlementsListItemActionTypes = AggregateActionTypes<GuestEntitlementsListItem>
