import { Action, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import { appConfigReducer } from '../config/app/reducers'
import {
  assignBayReducer,
  assignParkingBayReducer,
  verifyStayAgreementsReducer,
} from '../pages/assigned/assigned-parking/reducer'
import { bayUtilisationReportReducer, manageBayReducer } from '../pages/assigned/manage-bay/reducer'
import { assignedParkerReducer } from '../pages/assigned/manage-parkers/reducer'
import { viewEntitlementReducer } from '../pages/assigned/view-entitlement/reducer'
import { authReducer } from '../pages/auth/reducer'
import {
  guestEntitlementsItemReducer,
  guestEntitlementsListReducer,
  guestEntitlementsReducer,
} from '../pages/guest/reducer'
import {
  allocationByTypeReducer,
  allocationMemberCountReducer,
  dailyPeakAllocationReducer,
  peakAllocationReducer,
} from '../pages/home/reducer'
import { ordersForAccessRestrictionsReducer } from '../pages/on-demand/block-out-dates/reducer'
import { agreementsDetailReducer, orderParkingAgreementsStateReducer } from '../pages/on-demand/transactions/reducer'
import { memberEntitlementReducer } from '../pages/settings/users/active-users/active-entitlements/reducer'
import { memberParkingItemReducer } from '../pages/settings/users/active-users/recent-park-items/reducer'
import { manageUserReducer } from '../pages/settings/users/active-users/reducer'
import { applicationReducer } from '../pages/settings/users/applications/reducer'
import { territoryReducer } from '../pages/settings/users/locations/reducer'
import { autoInvitationReducer } from '../pages/settings/users/self-registration/auto-invitation/reducer'
import { emailDomainReducer } from '../pages/settings/users/self-registration/domains/reducer'
import { memberBulkUploadSummaryReducer } from '../pages/users/bulk-uploads/reducer'
import {
  dailyCountAllocationByAuthorityByOfferReducer,
  dailyFacilityAgreementPeakAllocationByAuthorityReducer,
  dailyMemberFinancialSummaryReducer,
} from '../pages/utilisation/dashboard/reducer'
import { accessInfoReducer } from './common/access-info/reducer'
import { accessRestrictionsReducer } from './common/access-restrictions/reducer'
import { brandingReducer } from './common/branding/reducer'
import { departmentsTeamsReducer } from './common/departments-teams/reducer'
import { departmentReducer } from './common/departments/reducer'
import { errorReducer } from './common/error/errorReducer'
import { gateListItemReducer } from './common/gates-list/reducer'
import { loadingReducer } from './common/loading/loadingReducer'
import { memberRelationReducer } from './common/member-relation/reducer'
import { memberReducer } from './common/member/reducer'
import { notificationsReducer } from './common/notifications/reducer'
import { offerFacilityReducer } from './common/offer-facilities/reducer'
import { parkingBasketReducer } from './common/parking-basket/reducer'
import { parkingOrderReducer } from './common/parking-order/reducer'
import { parkingQuoteReducer } from './common/parking-quote/reducer'
import { teamReducer } from './common/teams/reducer'
import {
  myListTenancyReducer,
  tenancyOffersReducer,
  tenancyReducer,
  tenantOffersReducer,
} from './common/tenancies/reducer'
import { tenantSelectorReducer } from './common/tenant-selector/reducer'
import { tenantSettingsReducer } from './common/tenant-settings/reducer'
import { transactionSummaryReducer } from './common/transaction-summary/reducer'
import { activeAgreementsReducer } from './common/active-agreements/reducer'

const loggerMiddleware = createLogger()

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

export const rootReducer = combineReducers({
  authReducer,
  appConfigReducer,
  errorReducer,
  loadingReducer,
  manageUserReducer,
  applicationReducer,
  teamReducer,
  departmentReducer,
  tenancyReducer,
  tenantSelectorReducer,
  tenancyOffersReducer,
  tenantOffersReducer,
  guestEntitlementsReducer,
  guestEntitlementsListReducer,
  guestEntitlementsItemReducer,
  offerFacilityReducer,
  orderParkingAgreementsStateReducer,
  departmentsTeamsReducer,
  memberParkingItemReducer,
  memberEntitlementReducer,
  memberBulkUploadSummaryReducer,
  notificationsReducer,
  autoInvitationReducer,
  emailDomainReducer,
  memberReducer,
  agreementsDetailReducer,
  accessRestrictionsReducer,
  ordersForAccessRestrictionsReducer,
  assignBayReducer,
  assignParkingBayReducer,
  assignedParkerReducer,
  verifyStayAgreementsReducer,
  viewEntitlementReducer,
  brandingReducer,
  accessInfoReducer,
  memberRelationReducer,
  parkingQuoteReducer,
  parkingBasketReducer,
  parkingOrderReducer,
  territoryReducer,
  myListTenancyReducer,
  tenantSettingsReducer,
  manageBayReducer,
  bayUtilisationReportReducer,
  allocationMemberCountReducer,
  allocationByTypeReducer,
  dailyPeakAllocationReducer,
  peakAllocationReducer,
  dailyFacilityAgreementPeakAllocationByAuthorityReducer,
  dailyCountAllocationByAuthorityByOfferReducer,
  dailyMemberFinancialSummaryReducer,
  gateListItemReducer,
  transactionSummaryReducer,
  activeAgreementsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<R> = ThunkAction<R, RootState, null, Action>

export default function configureStore(preloadedState: RootState | undefined) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)),
  )
  return store
}
