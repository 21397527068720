import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'

const Seo: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [title, setTitle] = useState<string>(t('common:seo.defaultTitle'))
  const [description, setDescription] = useState<string>(t('common:seo.defaultDescription'))

  useEffect(() => {
    if (t) {
      if (pathname) {
        setTitle(t(`common:seo.${pathname}.title`))
        setDescription(t(`common:seo.${pathname}.description`))
      } else {
        setTitle(t('common:seo.defaultTitle'))
        setDescription(t('common:seo.description'))
      }
    }
  }, [pathname, t])

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default Seo
