export interface ExistsAttribute {
  id: string | null
  value: string
}

export interface ExitsDto {
  exists: boolean
}

export const CHECK_NAME_EXITS_API_RESOURCE_PATH = ':checkNameExists'
export const CHECK_CLIENT_REF_EXITS_API_RESOURCE_PATH = ':checkClientRefExists'
export const CHECK_DOMAIN_EXITS_API_RESOURCE_PATH = ':checkDomainExists'
