export const PAGE_TITLE_LOGIN = 'Login'
export const PAGE_TITLE_HOME = 'Home'

export const SERVER_API_TIMEOUT = 30 * 1000
export const SERVER_FILE_TIMEOUT = 7 * 60 * 1000
export const SERVER_API_KEY_HEADER = 'x-api-key'
export const AUTHORIZATION_HEADER = 'Authorization'
export const BEARER_PREFIX = 'Bearer '

export const HEADER_X_TOTAL_COUNT = 'x-total-count'
export const HEADER_LINK_FORMAT = 'link'

export const ETAG_HEADER = 'etag'
export const IF_NONE_MATCH_HEADER = 'If-None-Match'

export const DATE_IS_INVALID = 'Date is invalid'
export const END_DATE_MUST_BE_LATER_THAN_START_DATE = 'End date must be later than start date'

export const LOCAL_STORAGE_SELECTED_TENANT_ID = 'selectedTenantId'
