import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'

const backendOptions = {
  loadPath: '/translations/{{lng}}/{{ns}}.json',
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(XHR)
  .init({
    ns: ['common', 'login', 'reset-password'],
    defaultNS: 'common',
    backend: backendOptions,
    lng: 'en',
    fallbackLng: 'en',
    nsSeparator: ':',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  })

export default i18next
