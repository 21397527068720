import { Box, Button, Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router'
import guestIcon from '../../assets/svg/ws-b2b-icon-consumer_cust.svg'
import transactionsIcon from '../../assets/svg/ws-b2b-icon-receipt-2.svg'
import { ROUTE_GUEST_BOOK_GUEST_PARKING, ROUTE_GUEST_TRANSACTION } from '../util/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    padding: '7px 22px',
  },
  loginModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    position: 'absolute',
    top: '194px',
    width: '367px',
    textAlign: 'center',
  },
}))

interface IProps {}

const Guest: React.FC<IProps> = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <>
      <div id="main" className="section-entry">
        <Container className="page-header">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <h1 className="settings-h1 centered-heading">Guest parking</h1>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container className="page-content">
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Box className="section-card">
              <div className="icon-box-wrapper">
                <img className="guest-book-guest" src={guestIcon} alt="Book for a guest" />
              </div>
              <h2>Book for a guest</h2>
              <p>Book a bay for a guest or visitor at your locations.</p>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTE_GUEST_BOOK_GUEST_PARKING)}
              >
                Book now
              </Button>
            </Box>

            <Box className="section-card">
              <div className="icon-box-wrapper">
                <img className="guest-transactions" src={transactionsIcon} alt="Transactions" />
              </div>
              <h2>Transactions</h2>
              <p>View and manage upcoming and past guest bookings.</p>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate(ROUTE_GUEST_TRANSACTION)}
              >
                View transactions
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Guest
