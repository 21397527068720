import { EChartsOption } from 'echarts-for-react'
import { BarChartSeriesData } from '../../common/echarts/types'
import { NameValueDTO } from '../../common/types'

export const getPieChartOption = (seriesData: NameValueDTO[], colorPalette: string[]) => {
  const option: EChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: function formatTooltip(params: any) {
        let formattedTooltip: string = ''
        formattedTooltip += `<div class="display-flex">
          <p style="color:#525252;font-size:12px;">${params.marker}${params.data.name}</p>
          <p style="color:#525252;font-size:12px;margin-left:10px;">${params.data.value}%</p>
          </div>`
        return formattedTooltip
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['42%', '95%'],
        label: {
          show: true,
          position: 'inside',
          color: 'white',
          formatter: '{c}',
        },
        color: colorPalette,
        data: seriesData,
        cursor: 'auto',
      },
    ],
  }
  return option
}

export const getBarChartOption = (xAxisData: NameValueDTO[], seriesData: BarChartSeriesData[], barWidth: string) => {
  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      formatter: function formatTooltip(params: any) {
        let formattedTooltip: string = ''
        params.forEach((item: any) => {
          formattedTooltip += `<div class="display-flex">
          <p style="color:#525252;font-size:12px;">${item.marker}${item.data.name}</p>
          <p style="color:#525252;font-size:12px;padding-left:10px;">${item.data.value}</p>
          </div>`
        })
        return formattedTooltip
      },
    },
    grid: {
      left: '0',
      right: '10%',
      bottom: '0',
      top: '6px',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          fontSize: 11,
          fontWeight: 'normal',
          fontFamily: 'Arial',
          color: '#525252',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        type: 'bar',
        barWidth: barWidth,
        data: seriesData,
        cursor: 'auto',
      },
    ],
  }
  return option
}
